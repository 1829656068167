import { useCallback, useEffect, useState } from "react";
import { Option } from "@introist/react-foundation/v2";
import { TOption } from "../../types";
import { isEmpty } from "lodash";

type UseSingleSelectOptionListArgs = {
  onChange: (senderId?: string) => void;
  onEnter?: (search: string) => void;
  initialOptions: TOption[];
  initialSelectedOption?: TOption;
};
export const useSingleSelectOptionList = ({
  initialOptions,
  initialSelectedOption,
  onChange,
  onEnter
}: UseSingleSelectOptionListArgs) => {
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    if (isEmpty(options) && !isEmpty(initialOptions)) {
      setOptions(initialOptions);
    }
  }, [initialOptions, options, setOptions]);

  const [selectedOption, setSelectedOption] = useState<TOption | null>(null);

  useEffect(() => {
    if (isEmpty(selectedOption) && !isEmpty(initialSelectedOption)) {
      setSelectedOption(options.find(({ key }) => key === initialSelectedOption?.key) || null);
    }
  }, [selectedOption, options, initialSelectedOption, setSelectedOption]);

  const handleOptionClick = useCallback(
    (option: Option) => {
      const selectedOption = options.find(o => o.key === option.key);

      if (selectedOption) {
        setSelectedOption(selectedOption);
        onChange(selectedOption.key);
      }
    },
    [options, setSelectedOption, onChange]
  );

  const handleRemoveOption = () => {
    setSelectedOption(null);
    onChange();
  };

  const handleAddOption = useCallback(
    (search: string) => {
      if (!onEnter) return;

      onEnter(search);

      setOptions(current => {
        const nextOptions = [
          ...current,
          {
            key: search,
            title: search,
            description: ""
          }
        ];

        const match = nextOptions.find(({ key }) => key === search);

        if (match) {
          setSelectedOption(match);
        }

        return nextOptions;
      });
    },
    [onEnter, setSelectedOption]
  );

  return {
    options,
    selectedOption,
    handleOptionClick,
    handleRemoveOption,
    handleAddOption: onEnter ? handleAddOption : undefined
  };
};
