import { debounce } from "lodash";
import { useState } from "react";

export const useDebouncedSearch = ({ initialSearch = "", debounceMs = 200 } = {}) => {
  const [query, setQuery] = useState(initialSearch);
  const [inputValue, setInputValue] = useState(initialSearch);

  const debouncedSetQuery = debounce(setQuery, debounceMs);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedSetQuery(value);
  };

  return { inputValue, handleInputChange, query };
};
