import { ReactNode } from "react";
import { Title } from "@introist/react-foundation/v2";

import {
  ApiTokenCredentialContainer,
  ApiClientCredentialContainer,
  OAuthCredentialContainer,
  DomainConfigurationContainer
} from ".";

import {
  AshbyConfigurationContainer,
  FreshServiceConfigurationContainer,
  ZendeskConfigurationContainer,
  WorkableConfigurationContainer,
  TrelloConfigurationContainer,
  TeamTailorConfigurationContainer,
  JiraConfigurationContainer
} from "../configurations";

const instructionsUrls: { [integrationId: string]: string } = {
  hibob: "https://apidocs.hibob.com/docs/getting-started",
  bamboohr: "https://documentation.bamboohr.com/docs#section-authentication",
  ashby: "https://www.notion.so/How-do-I-generate-an-API-key-fe0305612a3341b98bb2b8c768368a07"
};

export const buildCredentialsContainer = (integrationId: string): ReactNode => {
  if (
    [
      "hibob",
      "bamboohr",
      "asbhy",
      "freshservice",
      "tester",
      "workable",
      "greenhouse",
      "alexishr",
      "teamtailor",
      "jira"
    ].includes(integrationId)
  ) {
    return (
      <ApiTokenCredentialContainer
        integrationId={integrationId}
        instructionsUrl={instructionsUrls[integrationId]}
      />
    );
  }

  if (
    integrationId === "googleworkspace" ||
    integrationId === "googleforms" ||
    integrationId === "notion"
  ) {
    return <OAuthCredentialContainer integrationId={integrationId} />;
  }

  if (integrationId === "personio") {
    return (
      <ApiClientCredentialContainer
        integrationId={integrationId}
        instructionsUrl="https://developer.personio.de/docs/getting-started-with-the-personio-api"
      />
    );
  }

  if (integrationId === "zendesk") {
    return <OAuthCredentialContainer integrationId={integrationId} />;
  }

  if (integrationId === "trello") {
    return <OAuthCredentialContainer integrationId={integrationId} />;
  }

  return <NoCredentialsContainer />;
};

const NoCredentialsContainer = () => {
  return <Title>This integration does not required credentials</Title>;
};

export const buildConfigurationContainer = (integrationId: string): ReactNode => {
  if (integrationId === "ashby") {
    return <AshbyConfigurationContainer />;
  }
  if (integrationId === "freshservice") {
    return <FreshServiceConfigurationContainer integrationId={integrationId} />;
  }
  if (integrationId === "zendesk") {
    return <ZendeskConfigurationContainer integrationId={integrationId} />;
  }
  if (integrationId === "workable") {
    return <WorkableConfigurationContainer integrationId={integrationId} />;
  }
  if (integrationId === "teamtailor") {
    return <TeamTailorConfigurationContainer integrationId={integrationId} />;
  }
  if (integrationId === "bamboohr") {
    return (
      <DomainConfigurationContainer
        integrationId={integrationId}
        integrationDomainSuffix=".bamboohr.com"
      />
    );
  }
  if (integrationId === "jira") {
    return <JiraConfigurationContainer integrationId={integrationId} />;
  }
  if (integrationId === "trello") {
    return <TrelloConfigurationContainer integrationId={integrationId} />;
  }

  return <NoConfigurationContainer />;
};

const NoConfigurationContainer = () => {
  return <Title>No configurations available for integration</Title>;
};
