import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout, Select, Button, Field, useToast } from "@introist/react-foundation/v2";

import { updateIntegrationConfiguration, useIntegrationsApi } from "services/api/IntegrationsApi";

type TrelloConfiguration = {
  workspaceId: string;
};

type TrelloConfigurationContainerProps = {
  integrationId: string;
};

export const TrelloConfigurationContainer = ({
  integrationId
}: TrelloConfigurationContainerProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });
  const { data: integration } = findIntegration.query;

  const { data: workspaces } = integrationApi.loadData({
    integrationId,
    dataKey: "workspaces"
  }).query;

  const [workspaceId, setWorkspaceId] = useState("");

  useEffect(() => {
    setWorkspaceId((integration?.configuration as TrelloConfiguration)?.workspaceId ?? "");
  }, [integration]);

  const hasChanges = useMemo(() => {
    if (!integration) return false;
    return workspaceId !== (integration?.configuration as TrelloConfiguration)?.workspaceId;
  }, [integration, workspaceId]);

  const onSave = useCallback(async () => {
    await updateIntegrationConfiguration({ integrationId, configuration: { workspaceId } })
      .then(async () => {
        await findIntegration.refetch();
        toast.success("Configuration updated");
      })
      .catch(() => toast.error("Failed to update configuration"));
  }, [integrationId, workspaceId, findIntegration, toast]);

  return (
    <Layout.Group vertical lastChildToEnd gap="xLarge">
      <Field title="Workspace">
        <Select
          key="workspaces"
          style={{ width: "100%" }}
          attachToRef={false}
          placeholder="Select workspace"
          options={(workspaces ?? []).map((ws: any) => ({ key: ws.id, title: ws.displayName }))}
          value={workspaceId}
          onSelect={opt => setWorkspaceId(opt.key)}
        />
      </Field>

      <Button disabled={!hasChanges} onClickWithLoading={onSave}>
        Save
      </Button>
    </Layout.Group>
  );
};
