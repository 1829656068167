import { OptionList, Button, Popover, Title } from "@introist/react-foundation/v2";
import { useRef, useState } from "react";
import { dateFormats } from "./data";
import moment from "moment";
import { SelectGrid } from "../TimezoneSelect";

export const WorkspaceDateFormatSelect = ({
  dateFormat,
  onSelect
}: {
  dateFormat: string;
  onSelect: (dateFormat: string) => Promise<unknown>;
}) => {
  const refElement = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <SelectGrid>
      <Title>Workspace date format</Title>
      <Button
        active={open}
        ref={refElement}
        variant="blended"
        onClick={() => setOpen(!open)}
        loading={loading}
        startIcon="calendar"
      >
        {moment().format(dateFormat)}
      </Button>
      <Popover
        referenceElement={refElement.current}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <OptionList
          selected={dateFormat}
          options={dateFormats.map(dateFormat => ({
            key: dateFormat,
            title: moment().format(dateFormat),
            onClick: () => {
              setLoading(true);
              onSelect(dateFormat)
                .then(() => setOpen(false))
                .catch(() => {})
                .finally(() => setLoading(false));
            }
          }))}
          searchable
          autoFocus
          searchPlaceholder="Select format"
        />
      </Popover>
    </SelectGrid>
  );
};
