import {
  Button,
  Card,
  CircularLoader,
  Modal,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";

type ArchivedDatasetsModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ArchivedDatasetsModal = ({ open, onClose }: ArchivedDatasetsModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Archived Datasets"
      maxWidth={500}
      style={{ width: "500px" }}
    >
      {open && <ArchivedDatasets />}
    </Modal>
  );
};

const ArchivedDatasets = () => {
  const toast = useToast();

  const { data: datasets, refetch } = api.datasets.defs.list.useQuery(
    {
      archived: true
    },
    {
      refetchOnMount: "always"
    }
  );
  const apiUtils = api.useContext();

  const update = api.datasets.defs.update.useMutation();
  const refetchActive = apiUtils.datasets.defs.list.refetch;

  const unarchive = useCallback(
    async (datasetId: string) => {
      return update
        .mutateAsync({ id: datasetId, updates: { archived: false } })
        .then(async () => {
          await Promise.all([refetch(), refetchActive()]);
          toast.success("Dataset unarchived");
        })
        .catch(() => toast.error("Failed to unarchive dataset"));
    },
    [refetch, refetchActive, toast, update]
  );

  if (!datasets) return <CircularLoader fillParent />;

  return (
    <Stack vertical>
      {datasets.map(ds => (
        <Card>
          <Stack>
            <Title variant="bold">{ds.plural}</Title>
            <Title>{ds.key}</Title>

            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: "auto" }}
              onClickWithLoading={() => unarchive(ds.id)}
            >
              Unarchive
            </Button>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};
