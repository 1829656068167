import constate from "constate";
import { useState } from "react";

export const steps = ["choose-data-source", "connect-data-source"] as const;
type DataSourceConnectStep = typeof steps[number];

export const [DataSourceConnectionWizardContextProvider, useDataSourceConnectionWizardContext] =
  constate(() => {
    const [step, setStep] = useState<DataSourceConnectStep>("choose-data-source");
    const [dataSourceId, setDataSourceId] = useState<string | null>(null);

    const nextStep = () => {
      const currentStepIndex = steps.indexOf(step);
      if (currentStepIndex === steps.length - 1) {
        return;
      }
      setStep(steps[currentStepIndex + 1]);
    };

    return {
      step,
      dataSourceId,
      setDataSourceId,
      nextStep,
      setStep
    };
  });
