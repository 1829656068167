import { Input, InputProps } from "@introist/react-foundation/v2";

export type KeyInputProps = InputProps & {
  defaultValue?: string;
};

export const simplifyKeyValue = (value: string) => {
  return value.replace(/[^a-zA-Z]/g, "").toLowerCase();
};

export const KeyInput = ({ defaultValue, value, onChange, ...rest }: KeyInputProps) => {
  return (
    <Input
      value={value}
      onChange={(val, e) => onChange && onChange(simplifyKeyValue(val), e)}
      {...rest}
    />
  );
};
