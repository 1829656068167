import { useAttributes } from "hooks/use-attributes";
import { Block } from "components/atoms";
import { ParticipantCard } from "modules/workflows/components/ParticipantCard";
import { EmailMessageConfig } from "modules/workflows/routes/WorkflowEditor/StepEditor/Forms/schema";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useMemo } from "react";
import { useEmailSenderApi } from "services/api/Senders";
import { ReadOnlyEmailMessage } from "components/organisms";

type EmailMessageBlockProps = {
  originalMessage: EmailMessageConfig;
  message: EmailMessageConfig;
  title: string;
};

export const EmailMessageBlock = ({ originalMessage, message, title }: EmailMessageBlockProps) => {
  const senderApi = useEmailSenderApi();
  const { data: senders } = senderApi.list({ filterCalendarOnlySenders: true }).query;

  const attributes = useAttributes(false, ["email"]);

  const sender = useMemo(() => {
    return senders?.find(s => s.id === message.senderId);
  }, [senders, message.senderId]);

  const attributeReceiver = useMemo(() => {
    return attributes?.find(a => a.variable === originalMessage.to);
  }, [attributes, originalMessage.to]);

  return (
    <Block blended compact>
      <StepEditorAccordion title={title}>
        <StepEditorAccordion.Row label="From">
          <ParticipantCard
            title={sender?.name ?? message.senderId}
            description={sender?.email}
            readOnly
          />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="To">
          <ParticipantCard
            title={attributeReceiver?.name ?? message.to}
            description={attributeReceiver?.name ? message.to : undefined}
            readOnly
          />
        </StepEditorAccordion.Row>

        <ReadOnlyEmailMessage message={message.message} subject={message.subject} />
      </StepEditorAccordion>
    </Block>
  );
};
