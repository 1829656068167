import { useMemo } from "react";

export type FileType =
  | ".jpg"
  | ".jpeg"
  | ".png"
  | ".pdf"
  | ".doc"
  | ".docx"
  | ".pptx"
  | ".ppt"
  | ".pptm";

const MimeTypes = {
  ".jpg": "image/jpeg",
  ".jpeg": "image/jpeg",
  ".png": "image/png",
  ".pdf": "application/pdf",
  ".doc": "application/msword",
  ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ".pptx": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ".ppt": "application/vnd.ms-powerpoint",
  ".pptm": "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
};

type UseFileUploadRestrictionsArgs = {
  maxSize: number;
  acceptedFileTypes: FileType[];
  maxFiles?: number;
};

export const useFileUploadRestrictions = ({
  maxSize,
  acceptedFileTypes,
  maxFiles
}: UseFileUploadRestrictionsArgs) => {
  const accept = useMemo(() => {
    return acceptedFileTypes.reduce<{ [mimeType: string]: string[] }>((acc, fileType) => {
      const mimeType = MimeTypes[fileType];
      if (mimeType) {
        const existingFilesTypes = acc[mimeType] ?? [];

        return { ...acc, [mimeType]: [...existingFilesTypes, fileType] };
      } else {
        return acc;
      }
    }, {});
  }, [acceptedFileTypes]);

  const restrictions = useMemo(() => {
    const restrictions = [];
    restrictions.push(`Max file size ${maxSize / 1024 / 1024}MB`);

    if (acceptedFileTypes.length > 0) {
      restrictions.push(`Allowed extensions: ${acceptedFileTypes.join(", ")}`);
    }

    if (maxFiles) {
      restrictions.push(`Max number of files: ${maxFiles}`);
    }
    return restrictions;
  }, [maxSize, acceptedFileTypes, maxFiles]);

  return {
    restrictions,
    maxSize,
    accept
  };
};
