import { InputHTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
};

const RadioButton = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenRadio = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledRadio = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #ccc;
  position: relative;

  ${HiddenRadio}:checked + & {
    background-color: white;
    border: 2px solid var(--palette-primary-default);

    ::after {
      position: absolute;
      content: "";
      width: 10px;
      left: 2px;
      right: 0;
      top: 2px;
      bottom: 0;
      border-radius: 50%;
      height: 10px;
      background-color: var(--palette-primary-default);
      border
    }
  }
`;

export const Radio = ({ label, ...rest }: RadioProps) => {
  return (
    <RadioButton>
      <HiddenRadio type="radio" {...rest} />
      <StyledRadio />
      {label}
    </RadioButton>
  );
};
