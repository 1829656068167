import { animated, useSpring } from "@react-spring/web";
import styled, { css } from "styled-components";

type ProgressBarProps = {
  value: number;
  total: number;
  animate?: boolean;
  height?: number;
  backgroundColor?: string;
  rounded?: boolean;
  fillConfig?: {
    color?: string;
    endIndicator?: boolean;
  };
};

const StyledProgressBar = styled.div<{
  $height: number;
  $backgroundColor?: string;
  $rounded?: boolean;
}>`
  width: 100%;
  height: ${p => p.$height}px;
  border-radius: ${p => (p.$rounded ? `${p.$height}px` : 0)};
  background-color: ${p => p.$backgroundColor || "var(--palette-border-subdued)"};
  position: relative;
`;

const AnimatedFill = styled(animated.span)<{
  $backgroundColor?: string;
  $fillEndIndicator?: boolean;
}>`
  position: absolute;
  left: 0;
  height: inherit;
  border-radius: inherit;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "var(--palette-primary-default)"};

  ${({ $fillEndIndicator }) =>
    $fillEndIndicator &&
    css`
      ::after {
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: inherit;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

export const ProgressBar = ({
  value,
  total,
  height = 2,
  rounded,
  animate,
  backgroundColor,
  fillConfig,
  ...rest
}: ProgressBarProps) => {
  const percentage = (value / total) * 100;
  const fillSpring = useSpring({
    from: {
      width: "0%"
    },
    to: {
      width: `${percentage}%`
    },
    immediate: animate === false
  });

  return (
    <StyledProgressBar
      {...rest}
      $backgroundColor={backgroundColor}
      $height={height}
      $rounded={rounded}
    >
      <AnimatedFill
        style={fillSpring}
        $backgroundColor={fillConfig?.color}
        $fillEndIndicator={fillConfig?.endIndicator && percentage !== 0}
      />
    </StyledProgressBar>
  );
};
