import { Icon, Option, Select } from "@introist/react-foundation/v2";
import moment from "moment";
import { useMemo } from "react";

interface Props {
  year: string;
  setYear: (year: string) => void;
}

export const YearSelect = ({ year, setYear }: Props) => {
  const yearOptions = useMemo(() => {
    const countOfYears = 5;
    const years: Option[] = [];

    for (let i = 0; i < countOfYears; i++) {
      const year = moment()
        .add(i - 1, "y")
        .format("YYYY");
      years.push({ key: year, title: year });
    }
    return years;
  }, []);

  return (
    <Select
      size="small"
      element="button"
      variant="outlined"
      endAdornment={<Icon name="chevronDown" />}
      value={year}
      options={yearOptions}
      onSelect={opt => setYear(opt.key)}
    />
  );
};
