import {
  Card,
  Icon,
  IconName,
  Layout,
  Title,
  ICardProps,
  H4,
  Tooltip
} from "@introist/react-foundation/v2";
import { Radio } from "components/atoms";
import { FittedTitle } from "components/atoms";
import { ReactNode } from "react";
import { ComponentProps } from "react";
import styled, { css } from "styled-components";

type RadioCardProps = Pick<ComponentProps<"div">, "style" | "onClick"> & {
  active: boolean;
  title: string;
  disabled?: boolean;
  titleMaxLength?: number;
  startIcon?: IconName;
  startNode?: ReactNode;
  description?: string;
  spacing?: ICardProps["spacing"];
  children?: ReactNode;
  vertical?: boolean;
  info?: string;
};

const StyledRadioCard = styled(Card)<{ $active: boolean; $disabled?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--spacing-large);
  user-select: none;

  ${({ $active }) =>
    $active &&
    css`
      background-color: var(--palette-primary-ghosted);
      border-color: var(--palette-primary-default);
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      cursor: initial;
      .title {
        color: var(--palette-foreground-subdued);
        > * {
          color: inherit;
        }
      }
    `}
`;

const IconWrapper = styled.div`
  display: grid;

  > svg {
    fill: var(--palette-foreground-dimmed) !important;
  }
`;

const VerticalChildren = styled.div`
  padding-top: var(--spacing-medium);
`;

export const RadioCard = ({
  active,
  spacing,
  startIcon,
  startNode,
  title,
  titleMaxLength,
  vertical,
  disabled,
  children = undefined,
  description,
  onClick,
  info,
  ...rest
}: RadioCardProps) => {
  return (
    <StyledRadioCard
      {...rest}
      spacing={spacing}
      $active={active}
      onClick={onClick}
      $disabled={disabled}
    >
      <Layout.Group justifyContent="space-between">
        <Layout.Group vertical gap="xSmall">
          <Layout.Group className="title">
            {startIcon && <Icon name={startIcon} />}
            {startNode && startNode}

            <Layout.Group>
              {spacing === "xLarge" ? (
                <H4>{title}</H4>
              ) : (
                <>
                  {titleMaxLength ? (
                    <FittedTitle variant="bold" showTooltip maxLength={titleMaxLength}>
                      {title}
                    </FittedTitle>
                  ) : (
                    <Title variant="bold">{title}</Title>
                  )}
                </>
              )}
              {info && (
                <Tooltip tooltip={info}>
                  <IconWrapper>
                    <Icon name="infoFilled" />
                  </IconWrapper>
                </Tooltip>
              )}
            </Layout.Group>
          </Layout.Group>
        </Layout.Group>

        {description && <Title>{description}</Title>}
      </Layout.Group>
      <Layout.Group justifyContent="flex-end">
        {!vertical && children}
        <div
          // Prevents Radio's onChange event bubbling up to parent elements when this div is clicked.
          // Needed to avoid triggering parent click handlers unintentionally.
          style={{ width: 18, height: 18 }}
          onClick={e => e.preventDefault()}
        >
          <Radio checked={active} readOnly />
        </div>
      </Layout.Group>
      {vertical && <VerticalChildren>{children}</VerticalChildren>}
    </StyledRadioCard>
  );
};
