import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";

export const DoceboLearningPlanViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const executionData = step.executionData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Enrollments">
          <StepEditorAccordion.Row label="Learning Plan">
            <Stack>
              <Icon name="book" />
              <Title variant="bold">{executionData.learningPlanName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
