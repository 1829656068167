import { Stack, Title } from "@introist/react-foundation/v2";
import { ListView, UpsertModal } from "../../../foundation";
import { ListRow } from "../../../components/organisms";
import { useState } from "react";
import { CreateWorkflowForm } from "../../../modules/flows/components/CreateWorkflowForm";
import { useWorkflows } from "../../../modules/flows/hooks/useWorkflows";
import { TriggerForm } from "../../../modules/triggers/components/TriggerForm";
import { useTriggers } from "../../../modules/triggers";
import { IconPickerIcon } from "../../../components/organisms/IconPicker/IconPickerIcon";

export const OnboardingProgramView = () => {
  const { workflows, create } = useWorkflows({ module: "onboarding" });
  const { triggers, create: createTrigger } = useTriggers({ module: "onboarding" });

  const [createWorkflowOpen, setCreateWorkflowOpen] = useState(false);
  const [createTriggerOpen, setCreateTriggerOpen] = useState(false);

  return (
    <Stack vertical gap="xxLarge">
      <Stack vertical>
        <ListView.Header
          title="Triggers"
          count={triggers?.length}
          onCreate={() => setCreateTriggerOpen(true)}
        />

        <ListView.List
          data={triggers}
          renderRow={trigger => (
            <ListRow
              gridTemplateColumns="auto 1fr"
              clickable
              onClick={() => window.open(`/triggers/${trigger.id}`, "_blank")}
            >
              <IconPickerIcon icon="trigger" fallbackIcon="trigger" />
              <Title variant="bold">{trigger.name}</Title>
            </ListRow>
          )}
          empty={{
            title: "No onboarding triggers",
            message: "Create triggers to start onboarding automatically.",
            icon: "trigger"
          }}
        />
      </Stack>
      <Stack vertical>
        <ListView.Header
          title="Workflows"
          count={workflows?.length}
          onCreate={() => setCreateWorkflowOpen(true)}
        />
        <ListView.List
          data={workflows}
          renderRow={workflow => (
            <ListRow
              gridTemplateColumns="auto 1fr"
              clickable
              onClick={() => window.open(`/flows/edit/${workflow.id}`, "_blank")}
            >
              <IconPickerIcon icon="workflows" fallbackIcon="workflows" />
              <Title variant="bold">{workflow.title}</Title>
            </ListRow>
          )}
          empty={{
            title: "No workflow",
            message: "No onboarding automations (yet).",
            icon: "trigger"
          }}
        />
      </Stack>
      <UpsertModal
        open={createWorkflowOpen}
        onClose={() => setCreateWorkflowOpen(false)}
        entry={undefined}
        template={{
          module: "onboarding",
          targetDateAttribute: "startdate",
          description: "",
          folderId: null
        }}
        entryName="workflow"
        Form={CreateWorkflowForm}
        onCreate={entry =>
          create(entry).then(created => window.open(`/flows/edit/${created.id}`, "_blank"))
        }
        onUpdate={() => Promise.resolve()}
      />
      <UpsertModal
        open={createTriggerOpen}
        onClose={() => setCreateTriggerOpen(false)}
        entry={undefined}
        template={{
          module: "onboarding",
          description: ""
        }}
        entryName="trigger"
        Form={TriggerForm}
        onCreate={entry =>
          createTrigger(entry).then(created => window.open(`/triggers/${created.id}`, "_blank"))
        }
        onUpdate={() => Promise.resolve()}
      />
    </Stack>
  );
};
