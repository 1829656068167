import { api } from "../../../services/rpc/RpcProvider";
import { useCallback, useMemo } from "react";
import { useToast } from "@introist/react-foundation/v2";

export const useFieldCategories = () => {
  const toast = useToast();
  const { data: categories, refetch } = api.employees.v4.fields.categories.list.useQuery({});

  const categoryTitles = useMemo(() => {
    if (!categories) return [];
    return ["General", ...categories.map(category => category.title)];
  }, [categories]);

  const createOp = api.employees.v4.fields.categories.create.useMutation();
  const deleteOp = api.employees.v4.fields.categories.delete.useMutation();
  const reorderOp = api.employees.v4.fields.categories.reorder.useMutation();

  const create = useCallback(
    async (title: string) => {
      await createOp
        .mutateAsync({ title })
        .then(async () => {
          await refetch();
        })
        .catch(() => {
          toast.error("Failed to create category");
        });
    },
    [createOp, refetch, toast]
  );

  const remove = useCallback(
    async (id: string) => {
      await deleteOp
        .mutateAsync({ id })
        .then(async () => {
          await refetch();
        })
        .catch(() => {
          toast.error("Failed to delete category");
        });
    },
    [deleteOp, refetch, toast]
  );

  const reorder = useCallback(
    async (ids: string[]) => {
      await reorderOp
        .mutateAsync({ ordering: ids })
        .then(async () => {
          await refetch();
        })
        .catch(() => {
          toast.error("Failed to reorder categories");
        });
    },
    [reorderOp, refetch, toast]
  );

  return { categories, create, remove, reorder, categoryTitles };
};
