import { useEffect } from "react";
import { ProgressBar } from "components/molecules";
import { FileBase } from "./FileBase";
import { useUploadFile } from "../../hooks/useUploadFile";

type FileUploadItemProps = {
  file: File;
  onFileUploaded: (fileId: string) => unknown;
};

export const FileUploadItem = ({ file, onFileUploaded }: FileUploadItemProps) => {
  const { progress, uploadedFile } = useUploadFile(file);

  useEffect(() => {
    if (!!uploadedFile) onFileUploaded(uploadedFile.id);
  }, [uploadedFile, onFileUploaded]);

  return (
    <FileBase title={file.name}>
      <ProgressBar animate rounded value={progress} total={100} />
    </FileBase>
  );
};
