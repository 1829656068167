import { StepEditorAccordion } from "../../StepEditorAccordion";
import { Block } from "components/atoms";
import { api } from "services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import { Button, Select, Stack, Title } from "@introist/react-foundation/v2";
import { DataSourceIcon } from "../../../../../datasources/components/DataSourceIcon";
import { useEmployeeFields } from "../../../../../datasources/hooks/useEmployeeFields";
import { CreateAccountMappingModal } from "./CreateAccountMappingModal";

type CreateAccountStepEditorProps = {
  stepPayload: any;
  onChange: (field: string, value: any) => void;
  mode?: "workflow" | "journey";
};

const supportedDataSources = ["bamboohr", "drivesheet"];

export const CreateAccountStepEditor = ({
  stepPayload,
  onChange,
  mode = "workflow"
}: CreateAccountStepEditorProps) => {
  const { data: dataSources } = api.employees.dataSources.list.useQuery({});

  const employeeFields = useEmployeeFields();

  const [mappingOpen, setMappingOpen] = useState(false);

  const dataSourceOptions = useMemo(() => {
    return (dataSources || [])
      .filter(ds => supportedDataSources.includes(ds.sourceType))
      .map(ds => ({
        key: ds.id,
        title: ds.name,
        startAdornment: <DataSourceIcon size="small" dataSource={ds.sourceType} />
      }));
  }, [dataSources]);

  const selectedIcon = useMemo(() => {
    return dataSourceOptions.find(ds => ds.key === stepPayload.dataSourceId)?.startAdornment;
  }, [stepPayload.dataSourceId, dataSourceOptions]);

  return (
    <Block compact blended>
      <Stack vertical>
        <StepEditorAccordion title="Target" stepOrder={2}>
          <StepEditorAccordion.Row label="Target">
            <Select
              attachToRef={false}
              size="small"
              element="button"
              variant="blended"
              placeholder="Select target system"
              options={dataSourceOptions}
              value={stepPayload.dataSourceId}
              startAdornment={selectedIcon}
              onSelect={opt => onChange("dataSourceId", opt.key)}
              readonly={mode === "journey"}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
        <StepEditorAccordion title="Fields" stepOrder={3}>
          <Stack vertical>
            {stepPayload.fields.map((row: any) => (
              <Stack
                key={row.id}
                style={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 1fr 20px"
                }}
              >
                <Title
                  variant="bold"
                  style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {employeeFields.find((f: any) => f.variable === row.introistField)?.name ??
                    row.introistField}
                </Title>
                <Title style={{ whiteSpace: "pre", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {row.integrationField}
                </Title>
              </Stack>
            ))}
            <Button variant="blended" onClick={() => setMappingOpen(true)}>
              Edit mapping
            </Button>
          </Stack>
        </StepEditorAccordion>
        <StepEditorAccordion title="Options">
          <StepEditorAccordion.Row label="Save ID to">
            <Select
              searchable
              attachToRef={false}
              size="small"
              element="button"
              variant="blended"
              placeholder="Save ID to employee field"
              options={employeeFields.map((f: any) => ({ key: f.variable, title: f.name }))}
              value={stepPayload.saveIdToField}
              onSelect={opt => onChange("saveIdToField", opt.key)}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
        <CreateAccountMappingModal
          open={mappingOpen}
          onClose={() => setMappingOpen(false)}
          dataSourceId={stepPayload.dataSourceId}
          value={stepPayload.fields}
          onChange={fields => onChange("fields", fields)}
        />
      </Stack>
    </Block>
  );
};
