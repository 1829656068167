import {
  DateInput,
  Modal,
  Stack,
  TimePicker,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { FormFooter } from "../../datasources/components/FormFooter";
import { DateFormats } from "../../../utils/dates";
import { api } from "../../../services/rpc/RpcProvider";

type RescheduleStepModalProps = {
  stepId: string;
  open?: boolean;
  onClose: () => void;
  onRescheduled?: () => Promise<unknown>;
};

const RescheduleAutomation = ({ stepId, onClose, onRescheduled }: RescheduleStepModalProps) => {
  const toast = useToast();
  const [date, setDate] = useState<string | undefined>();
  const [time, setTime] = useState<string | undefined>();

  const reschedule = api.automations.steps.reschedule.useMutation();

  const onReschedule = useCallback(async () => {
    if (!date) {
      toast.error("Please select a date");
      return;
    }
    await reschedule
      .mutateAsync({ id: stepId, date, time })
      .then(async () => {
        onRescheduled && (await onRescheduled());
        toast.success("Step rescheduled");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to reschedule step");
      });
  }, [date, time, stepId, reschedule, toast, onClose, onRescheduled]);

  return (
    <Stack vertical>
      <Title>Select new date and time for this step</Title>
      <Stack>
        <DateInput
          value={date}
          onChange={setDate}
          placeholder="Select date"
          displayFormat={DateFormats.date.long}
        />
        <TimePicker time={time} onSelect={setTime} placeholder="Time" />
      </Stack>

      <FormFooter onCancel={onClose} onSubmit={onReschedule} submit="Reschedule" />
    </Stack>
  );
};

export const ReschedulStepModal = (props: RescheduleStepModalProps) => {
  return (
    <Modal open={!!props.open} onClose={props.onClose} title="Reschedule step">
      {props.open && <RescheduleAutomation {...props} />}
    </Modal>
  );
};
