import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Icon, IconName, Select, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { api } from "../../../../services/rpc/RpcProvider";
import { useMemo } from "react";

export const TeamsChannelMessageEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { data: options } = api.workflows.v4.steps.options.useQuery({
    stepType: form.data.stepType
  });

  const senders = useMemo(() => {
    if (!options || !options.senders) return [];

    return (options.senders as any[]).map(o => ({
      key: o.id,
      title: o.name,
      startAdornmentIcon: "person" as IconName
    }));
  }, [options]);

  const channels = useMemo(() => {
    const senderId = prefixedForm.get("senderId");

    if (!options) return [];
    if (!senderId) return [];

    const channels = options.senders.find((s: any) => s.id === senderId)?.channels;

    if (!channels) return [];
    return channels.map((c: any) => ({ key: c.id, title: `${c.name} - ${c.teamName}` }));
  }, [prefixedForm, options]);

  const messageError = prefixedForm.error("message");

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Channel" defaultOpen>
          <Stack vertical alignItems="stretch" gap="large">
            <StepEditorAccordion.Row label="From">
              <Select
                searchable
                element="button"
                variant="blended"
                options={senders}
                value={prefixedForm.get("senderId")}
                onSelect={opt => prefixedForm.set("senderId")(opt.key)}
                attachToRef={false}
                placeholder="Select sender"
                startAdornment={<Icon name="person" />}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.Row label="To">
              <Select
                searchable
                element="button"
                variant="blended"
                options={channels}
                value={prefixedForm.get("channelId")}
                onSelect={opt => prefixedForm.set("channelId")(opt.key)}
                attachToRef={false}
                placeholder="Select channel"
                startAdornment={<Icon name="hashtag" />}
              />
            </StepEditorAccordion.Row>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
              underline={false}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
