import { useTheme } from "@introist/react-foundation/v2";

type Props = {
  text: string;
  search: string;
};

export const HighlightText = ({ text, search }: Props) => {
  const { theme } = useTheme();
  if (!search) return <>{text}</>;

  const regex = new RegExp(`(${search})`, "gi");
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          style={
            part.toLowerCase() === search.toLowerCase()
              ? {
                  backgroundColor: theme.palette.primary.ghosted,
                  color: theme.palette.primary.default,
                  borderRadius: "2px"
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </>
  );
};
