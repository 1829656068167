import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { RadioCard } from "components/molecules";
import { useDataSourceConnectionWizardContext } from "../context";
import { StepFrame } from "../components";
import { DataSourceIcon } from "../../../components/DataSourceIcon";
import { DataSources } from "../../../datasources";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-large);
`;

export const AvailableDataSourcesPicker = () => {
  const navigate = useNavigate();
  const { nextStep } = useDataSourceConnectionWizardContext();
  const [dataSourceType, setDataSourceType] = useState<string>();

  return (
    <StepFrame
      title="Choose data source"
      order={1}
      nextButtonText="Next"
      nextButtonDisabled={!dataSourceType}
      onNextButtonClick={() => {
        nextStep();
        navigate(`#${dataSourceType}`, { relative: "path" });
      }}
    >
      <Grid>
        {DataSources.filter(_ => !_.hidden).map(({ key, title }) => (
          <RadioCard
            spacing="xLarge"
            key={key}
            startNode={<DataSourceIcon dataSource={key} />}
            active={dataSourceType === key}
            title={title}
            onClick={() => setDataSourceType(key)}
          />
        ))}
      </Grid>
    </StepFrame>
  );
};
