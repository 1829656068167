import { Outlet, useLocation } from "react-router-dom";
import { BreadcrumbPath, useAppMenuContext, useResponsive } from "@introist/react-foundation/v2";

import { TopBarRoute } from "components/templates";

const breadcrumbMap: Record<string, BreadcrumbPath> = {
  accounts: { key: "accounts", title: "Linked accounts" },
  integrations: { key: "integrations", title: "Integrations" },
  "employee-fields": { key: "employeeFields", title: "Employee fields" },
  sso: { key: "sso", title: "Single sign-on" },
  "data-sources": { key: "dataSources", title: "Data sources" },
  ai: { key: "ai", title: "AI" },
  rooms: { key: "rooms", title: "Meeting rooms" }
};

const getSubPageBreadcrumb = (pathname: string) => {
  for (const path in breadcrumbMap) {
    if (pathname.includes(path)) {
      return breadcrumbMap[path];
    }
  }
  return { key: "settings-workspace", title: "Workspace" };
};

const isNarrowContent = (pathname: string) => {
  return (
    pathname.includes("integrations") ||
    pathname.includes("employee-fields") ||
    pathname.includes("sso") ||
    pathname.includes("ai") ||
    pathname.includes("rooms")
  );
};

export const SettingsRoute = () => {
  const { toggleMenuCollapsed } = useAppMenuContext();
  const { isMobileLayout } = useResponsive();
  const { pathname } = useLocation();

  return (
    <TopBarRoute
      narrow={isNarrowContent(pathname)}
      navigationAction={isMobileLayout ? "menu" : undefined}
      onNavigationAction={isMobileLayout ? toggleMenuCollapsed : undefined}
      paths={[{ key: "settings", title: "Settings" }, getSubPageBreadcrumb(pathname)]}
    >
      {/* @ts-ignore */}
      <Outlet />
    </TopBarRoute>
  );
};
