import styled, { css } from "styled-components";
import { Title, Card, Layout, Tag } from "@introist/react-foundation/v2";
import { Integration } from "modules/integrations";

type IntegrationCardProps = {
  integration: Integration;
  onClick: (integrationId: string) => void;
  compact?: boolean;
};

const StyledIntegrationCard = styled(Card)`
  display: flex;
  user-select: none;

  > :first-child {
    flex: 1;
  }
`;

const iconStyle = css`
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--rounding-small);
`;


export const StyledIntegrationIcon = styled.img`
  ${iconStyle};
`;

export const IntegrationCard = ({ integration, onClick }: IntegrationCardProps) => {
  return (
    <StyledIntegrationCard hoverable onClick={() => onClick(integration.id)}>
      <Layout.Group justifyContent="space-between" alignSelf="stretch">
        <Layout.Group gap="medium">
          <StyledIntegrationIcon src={integration.icon} />
          <Title variant="bold">{integration.name}</Title>
        </Layout.Group>
        {integration.active && <Tag colorVariant="success">Connected</Tag>}
      </Layout.Group>
    </StyledIntegrationCard>
  );
};
