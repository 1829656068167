import { Button, Icon, Indicator, useTheme } from "@introist/react-foundation/v2";
import { useJourneyApi } from "../../../../../../services/api/journeys/JourneyApi";

interface Props {
  onOpenActiveEmployees: () => void;
  loading?: boolean;
  disabled?: boolean;
  journeysCount?: number;
  stepId: string;
  workflowId: string;
}

export const ActiveEmployeesButton = ({ onOpenActiveEmployees, stepId, workflowId }: Props) => {
  const { theme } = useTheme();
  const journeyApi = useJourneyApi();
  const {
    data: journeys,
    isFetching,
    isLoading
  } = journeyApi.listByWorkflowStep({ stepId: stepId }).query;

  return (
    <Button
      size="small"
      variant="outlined"
      startAdornment={
        journeys && journeys.length > 0 ? <Indicator color={theme.palette.success} /> : undefined
      }
      onClick={onOpenActiveEmployees}
      loading={isFetching || isLoading}
      disabled={!journeys || journeys.length === 0}
      endAdornment={<Icon name="persons" />}
    >
      {journeys ? `${journeys.length} ` : ""}
    </Button>
  );
};
