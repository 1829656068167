import { Button, Layout } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { SliderModalProps } from "./SliderModal";

type SliderModalSidebarProps = Pick<SliderModalProps, "slides"> & {
  jumpToSlide: (index: number) => void;
  currentSlide: number;
};

const StyledSliderModalSidebar = styled(Layout.Group)`
  padding: var(--spacing-xLarge) var(--spacing-large);
  background-color: var(--palette-surface-subdued);
  border-right: 1px solid var(--palette-border-subdued);
`;

const StyledButton = styled(Button)`
  > div {
    justify-content: flex-start;
  }
`;

export const SliderModalSidebar = ({
  slides,
  currentSlide,
  jumpToSlide,
  ...rest
}: SliderModalSidebarProps) => {
  return (
    <StyledSliderModalSidebar {...rest} vertical gap="none">
      {slides.map((slide, index) => (
        <StyledButton
          active={currentSlide === index}
          startIcon={slide.icon ? slide.icon : undefined}
          key={`slide-modal-sidebar-button-${index}-${slide.title}`}
          variant="blended"
          onClick={() => jumpToSlide(index)}
        >
          {slide.title}
        </StyledButton>
      ))}
    </StyledSliderModalSidebar>
  );
};
