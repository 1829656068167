import { useState } from "react";
import { Button, Icon, Layout } from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { useDebouncedSearch } from "hooks";
import { TextInput } from "components/atoms";
import { TabBar } from "components/molecules";
import { SearchWrapper } from "components/organisms";
import {
  ManagedEventAttendee,
  CompanyEventInstance,
  UnmanagedEventAttendee
} from "services/rpc/RpcProvider";

import {
  EventAttendeeListEmptyState,
  ManagedAttendeeListEmptyState
} from "./EventAttendeeListEmptyState";
import { CalendarEventAttendeeList, ManagedAttendeeList } from "./EventAttendeeInstanceList";
import {
  CompanyEventAttendeeHistoryModal,
  RescheduleCompanyEventAttendeeModal
} from "modules/events/containers";

type EventAttendeeListProps = {
  eventId: string;
  eventInstances: CompanyEventInstance[];
  managedAttendees: ManagedEventAttendee[];
  unmanagedAttendees: UnmanagedEventAttendee[];
  onEnrollEmployee: VoidFunction;
  onSyncEvent: VoidFunction;
  onImportAttendee: (attendee: UnmanagedEventAttendee, calendarEventId: string) => Promise<void>;
};

export const EventAttendeeList = ({
  eventId,
  managedAttendees,
  eventInstances,
  unmanagedAttendees,
  onEnrollEmployee,
  onSyncEvent,
  onImportAttendee,
  ...rest
}: EventAttendeeListProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { inputValue, handleInputChange, query } = useDebouncedSearch({ debounceMs: 20 });

  const [attendeeId, setAttendeeId] = useState<string>();
  const [attendeeHistoryModalOpen, setAttendeeHistoryModalOpen] = useState(false);

  const [tab, setTab] = useState<"Sessions" | "Attendees">("Sessions");

  return (
    <Layout.Group {...rest} vertical gap="large">
      <Layout.Group>
        <TabBar
          activeTab={tab}
          onTabChange={tab => setTab(tab as "Sessions" | "Attendees")}
          tabs={[
            {
              id: "Sessions",
              title: "Sessions"
            },
            {
              id: "Attendees",
              title: "Attendees"
            }
          ]}
        />
      </Layout.Group>
      <Layout.Group justifyContent="space-between">
        <Button startIcon="plus" variant="outlined" onClick={onEnrollEmployee}>
          New attendee
        </Button>
        <SearchWrapper>
          <TextInput
            value={inputValue}
            onChange={handleInputChange}
            size="small"
            placeholder="Search by name"
            endAdornment={<Icon subdued name="search" />}
            style={{
              height: 34
            }}
          />
        </SearchWrapper>
      </Layout.Group>

      <Layout.Group vertical gap="large" style={{ flex: 1 }}>
        {tab === "Attendees" && (
          <>
            {isEmpty(managedAttendees) ? (
              <ManagedAttendeeListEmptyState
                onEnrollEmployee={onEnrollEmployee}
                onAddFromCalendar={() => setTab("Sessions")}
              />
            ) : (
              <ManagedAttendeeList
                search={query}
                eventId={eventId}
                managedAttendees={managedAttendees}
                onShowAttendeeHistory={attendeeId => {
                  setAttendeeHistoryModalOpen(true);
                  setAttendeeId(attendeeId);
                }}
              />
            )}
          </>
        )}

        {tab === "Sessions" && (
          <>
            {isEmpty(eventInstances) ? (
              <EventAttendeeListEmptyState
                onEnrollEmployee={onEnrollEmployee}
                onSyncEvent={onSyncEvent}
              />
            ) : (
              eventInstances.map(instance => (
                <CalendarEventAttendeeList
                  search={query}
                  key={`event-attendee-list-instance-${instance.id}`}
                  instance={instance}
                  unmanagedAttendees={unmanagedAttendees.filter(
                    a => a.eventInstanceId === instance.id
                  )}
                  onShowAttendeeHistory={attendeeId => {
                    setAttendeeHistoryModalOpen(true);
                    setAttendeeId(attendeeId);
                  }}
                  onImportAttendee={attendee =>
                    onImportAttendee(attendee, instance.calendarEventId)
                  }
                  onCanceled={onSyncEvent}
                />
              ))
            )}
          </>
        )}
      </Layout.Group>
      <CompanyEventAttendeeHistoryModal
        attendeeId={attendeeId}
        open={!!attendeeId && attendeeHistoryModalOpen}
        onClose={() => {
          setAttendeeHistoryModalOpen(false);
          setTimeout(() => {
            setAttendeeId(undefined);
          }, 500);
        }}
      />
      <RescheduleCompanyEventAttendeeModal
        companyEventId={eventId}
        open={location.hash.includes("#reschedule")}
        onClose={() => navigate(`/events/${eventId}`, { replace: true })}
      />
    </Layout.Group>
  );
};
