import { useMemo } from "react";

import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { Icon, IconButton, Select, Stack, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-small);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);

    button {
      opacity: 1;
    }
  }
`;

export const AvatarBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: var(--rounding-small);
  background: var(--palette-purple-ghosted);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--palette-purple-default) !important;
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

const avatarOrAttribute = (value?: string) => {
  if (!value || value.startsWith("U"))
    return (
      <AvatarBackground>
        <Icon name="person" />
      </AvatarBackground>
    );
  return (
    <AvatarBackground>
      <Icon name="attribute" />
    </AvatarBackground>
  );
};

type SlackUsersSelectProps = {
  senderId?: string;
  value: string[];
  onChange: (newValue: string[]) => unknown;
  addButtonText?: string;
  error?: boolean;
};

export const SlackUsersSelect = ({
  senderId,
  value,
  onChange,
  addButtonText,
  error
}: SlackUsersSelectProps) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackUsers } = slackSenderApi.listUsers(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const { fields } = useEmployeeFields();

  const options = useMemo(() => {
    const fieldOptions = (fields ?? [])
      .filter(f => ["email", "employee"].includes(f.type))
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment: <Icon name={f.type === "email" ? "atTheRate" : "personSync"} />
      }));

    const userOptions = (slackUsers ?? []).map(user => ({
      key: user.id,
      title: user.realName,
      startAdornment: <Icon name="person" />
    }));

    return [...fieldOptions, ...userOptions];
  }, [fields, slackUsers]);

  return (
    <Stack vertical gap="xSmall">
      {value.map((path: string) => (
        <StyledCard>
          <Stack style={{ width: "100%" }}>
            {avatarOrAttribute(path)}
            <Title variant="bold">{options.find(o => o.key === path)?.title ?? path}</Title>
            <RemoveButton
              icon="crossSmall"
              onClick={() => {
                onChange(value.filter(v => v !== path));
              }}
              style={{ marginLeft: "auto" }}
            />
          </Stack>
        </StyledCard>
      ))}
      <div style={{ marginTop: "var(--spacing-medium)" }}>
        <Select
          searchable
          size="small"
          element="button"
          variant="blended"
          placeholder="Add receiver"
          startAdornment={<Icon name="personAdd" />}
          options={options}
          onSelect={opt => {
            onChange([...value, opt.key]);
          }}
          attachToRef={false}
        />
      </div>
    </Stack>
  );
};
