import styled from "styled-components";
import { BaseModal, CloseIconButton, H4, Layout, Title } from "@introist/react-foundation/v2";
import { MediumTag } from "components/atoms";
import { SyncCompanyEvent, SyncCompanyEventProps } from "./SyncCompanyEvent";

type Props = SyncCompanyEventProps & {
  open: boolean;
  onClose: VoidFunction;
};

const StyledModal = styled(BaseModal)`
  width: 768px;
  overflow: hidden;

  max-height: 1024px;
  height: calc(100vh - 6rem);

  > div {
    overflow: hidden;
  }
`;

export const SyncCompanyEventModal = ({
  onClose,
  companyEvent,
  onAttendeesAdded,
  ...rest
}: Props) => {
  return (
    <StyledModal {...rest} closeOnOutsideClick>
      <Layout.Group vertical gap="xLarge" style={{ height: "100%" }}>
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            <H4>Sync event</H4>
            <MediumTag backgroundColor="transparent" startIcon="calendar">
              {companyEvent.title}
            </MediumTag>
          </Layout.Group>
          <Layout.Group>
            <CloseIconButton onClick={onClose} />
          </Layout.Group>
        </Layout.Group>
        <Title>Synchronize and import attendee information from the calendar event</Title>
        <SyncCompanyEvent companyEvent={companyEvent} onAttendeesAdded={onAttendeesAdded} />
      </Layout.Group>
    </StyledModal>
  );
};
