import { useWorkspace } from "IdentityProvider";
import { useState, useEffect, Dispatch, SetStateAction } from "react";

const getStorageValue = (key: string, defaultValue: any, overwrite: boolean) => {
  const saved = localStorage.getItem(key);
  const initial = saved && JSON.parse(saved);

  if (overwrite) {
    return defaultValue;
  } else {
    return initial || defaultValue;
  }
};

export const useStoredState = <Value>(
  key: string,
  defaultValue: any,
  overwrite: boolean = false
): [Value, Dispatch<SetStateAction<Value>>] => {
  const [value, setValue] = useState<Value>(() => {
    return getStorageValue(key, defaultValue, overwrite);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const useStoredWorkspaceState = <Value>(
  key: string,
  defaultValue: any,
  overwrite: boolean = false
) => {
  const workspace = useWorkspace();
  const workspaceKey = `${key}.${workspace?.id}`;
  return useStoredState<Value>(workspaceKey, defaultValue, overwrite);
};
