import { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export type ListRowProps = Pick<ComponentProps<"div">, "onClick"> & {
  children: ReactNode;
  gridTemplateColumns: string;
  style?: any;
  to?: string;
  clickable?: boolean;
};

export const listRowGradientStyle = css`
  background: linear-gradient(to right, var(--palette-surface-subdued), transparent);
`;

type StyledListRowProps = {
  $gridTemplateColumns: string;
  $clickable?: boolean;
};

export const StyledListRow = styled.div<StyledListRowProps>`
  padding: var(--spacing-large) var(--spacing-xLarge);
  display: grid;
  align-items: center;
  border-bottom: 1px solid var(--palette-surface-default);
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
  grid-column-gap: var(--spacing-large);
  user-select: none;
  box-sizing: border-box;

  ${({ $clickable }) =>
    $clickable &&
    css`
      :hover {
        cursor: pointer;
        ${listRowGradientStyle};
      }
    `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ListRow = ({
  gridTemplateColumns,
  style,
  children,
  clickable,
  to,
  onClick,
  ...rest
}: ListRowProps) => {
  if (to) {
    return (
      <StyledLink {...rest} to={to}>
        <StyledListRow
          style={style}
          onClick={onClick}
          $clickable={!!onClick || clickable || !!to}
          $gridTemplateColumns={gridTemplateColumns}
          className="list-row"
        >
          {children}
        </StyledListRow>
      </StyledLink>
    );
  }
  return (
    <StyledListRow
      style={style}
      onClick={onClick}
      $clickable={!!onClick || clickable}
      $gridTemplateColumns={gridTemplateColumns}
      className="list-row"
      {...rest}
    >
      {children}
    </StyledListRow>
  );
};
