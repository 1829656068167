import { Stack, Card, Title, Button } from "@introist/react-foundation/v2";
import { ListRow } from "../../../components/organisms";
import { api } from "../../../services/rpc/RpcProvider";
import { useMemo } from "react";
import moment from "moment";
import { orderBy } from "lodash";
import { Avatar } from "../../../components/molecules";
import { ListView, ContentCard } from "../../../foundation";
import { formatDate } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";
import { useNavigate } from "react-router";

import { MonthlyNewHiresChart } from "../MonthlyNewHiresChart";
import { pendingPreconditionCount } from "../../../modules/automations/utils";
import {
  JourneyStepRow,
  StepOverview
} from "../../../modules/automations/views/UpcomingSteps/JourneyStepRow";

export const OnboardingDashboard = () => {
  const navigate = useNavigate();
  const { data: employees } = api.employees.v4.list.useQuery({ fields: ["startdate"] });

  const { data: steps } = api.journeys.steps.upcoming.useQuery({});

  const upcomingStartDateEmployees = useMemo(() => {
    if (!employees) return undefined;

    const filtered = employees.filter(employee => moment(employee.startdate).isAfter(moment()));
    return orderBy(filtered, e => e.startdate, "asc");
  }, [employees]);

  const upcomingSteps = useMemo(() => {
    return (steps ?? []).filter(s => pendingPreconditionCount(s as StepOverview) === 0);
  }, [steps]);

  return (
    <Stack vertical>
      <Stack style={{ display: "grid", gridTemplateColumns: "1fr 2fr", alignItems: "flex-start" }}>
        <ContentCard
          title="Upcoming start dates"
          icon="calendarForward"
          style={{ minHeight: "290px", minWidth: "0" }}
        >
          <ListView.LimitedList
            initialRowCount={10}
            empty={{
              title: "No upcoming start dates",
              message: "No employees with start date in the future.",
              icon: "calendarForward",
              border: false
            }}
            skeletonCount={3}
            data={upcomingStartDateEmployees}
            renderRow={e => (
              <ListRow
                key={e.employeeId}
                gridTemplateColumns="1fr auto"
                clickable
                onClick={c => {
                  const isBlank = () => {
                    if (c.ctrlKey || c.metaKey) return { target: "_blank" };
                  };

                  if (isBlank()) {
                    window.open(`/employees/${e.id}`, "_blank");
                  } else navigate(`/employees/${e.id}`);
                }}
              >
                <Stack>
                  <Avatar nameOrEmail={e.name ?? ""} />
                  <Title variant="bold">{e.name}</Title>
                </Stack>
                {e.startdate && (
                  <Title>{formatDate(e.startdate, DateFormats.date.withDay.long)}</Title>
                )}
              </ListRow>
            )}
          />
        </ContentCard>
        <ContentCard
          title="Upcoming actions"
          icon="workflows"
          style={{ minHeight: "290px", minWidth: "0" }}
        >
          <ListView.List
            empty={{
              title: "No upcoming actions",
              message: "No automation actions scheduled.",
              icon: "workflows",
              border: false
            }}
            skeletonCount={3}
            data={steps ? upcomingSteps.slice(0, 10) : undefined}
            renderRow={step => <JourneyStepRow key={step.id} step={step as StepOverview} />}
          />
          {(steps ?? []).length > 9 && (
            <Button variant="blended" onClick={() => window.open("/automations/upcoming")}>
              <Title>View all</Title>
            </Button>
          )}
        </ContentCard>
      </Stack>
      <Card>
        <Title variant="bold">Monthly new hires</Title>
        <MonthlyNewHiresChart />
      </Card>
    </Stack>
  );
};
