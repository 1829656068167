import "./HtmlEditor.scss";
import "emoji-mart/css/emoji-mart.css";
import "react-quill/dist/quill.snow.css";

import { Popover, OptionList, useTheme, Card, Icon, Input } from "@introist/react-foundation/v2";
import cx from "classnames";
import { Picker } from "emoji-mart";
import { BaseEmoji } from "emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index";
import Quill, { Delta } from "quill";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { useQuery } from "@tanstack/react-query";
import ReactQuill from "react-quill";

import { listMaterials } from "services/api/MaterialsApi";
import { ReactComponent as GifIcon } from "./GifIcon.svg";
import styles from "./HtmlEditor.module.scss";
import { AttributeListPopover } from "modules/workflows/routes/WorkflowEditor/StepEditor/AttributeSelector";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { useFeatureFlag } from "hooks";
import { config } from "Config";
import { PropertyBlock } from "./formats/Property";

Quill.register(PropertyBlock);

interface EditorConfigurations {
  disableGif?: boolean;
  disableImage?: boolean;
  disableMaterial?: boolean;
  disableAttributePersonalization?: boolean;
  disableFontSize?: boolean;
}

export interface HtmlEditorProps {
  defaultValue?: string;
  onChange?: (value: string) => unknown;
  editorConfigurations?: EditorConfigurations;
  readonly?: boolean;
  light?: boolean;
  className?: string;
  style?: CSSProperties;
  placeholder?: string;
}

export type Editor = {
  hasFocus: () => boolean;
  insertTextToCursorPosition: (text: string) => void;
  insertUserPropertyToCursorPosition: (property: EmployeeAttribute) => void;
  replaceText: (text: string) => void;
  replaceHTML: (html: string) => void;
};

const materialTypeIcon = {
  link: <Icon name="link" />,
  file: <Icon name="file" />,
  handbook: <Icon name="book" />
};

export const HtmlEditor = React.forwardRef<Editor, HtmlEditorProps>(
  (
    {
      defaultValue,
      onChange,
      editorConfigurations,
      readonly,
      light,
      className,
      style,
      placeholder
    }: HtmlEditorProps,
    ref
  ) => {
    const [toolbarName] = useState<string>(`toolbar-${Date.now()}`);
    const { theme } = useTheme();
    const editorRef = useRef<ReactQuill>(null);
    const [silentMode, setSilentMode] = useState(true);
    const [attributeSearch, setAttributeSearch] = useState<string>();

    const hasLegacy = useFeatureFlag("legacy").isEnabled;

    useEffect(() => {
      setTimeout(() => {
        setSilentMode(false);
      }, 50);
    }, []);

    const [emojiButtonRef, setEmojiButtonRef] = useState<HTMLElement | null>(null);
    const [emojiOpen, setEmojiOpen] = useState(false);

    const [giphyRef, setGiphyRef] = useState<HTMLElement | null>(null);
    const [giphyOpen, setGiphyOpen] = useState(false);

    const [materialRef, setMaterialRef] = useState<HTMLElement | null>(null);
    const [materialsOpen, setMaterialsOpen] = useState(false);

    const [attributeListRef, setAttributeListRef] = useState<HTMLElement | null>(null);
    const [attributeListOpen, setAttributeListOpen] = useState(false);

    const { data: materials } = useQuery(["materials"], listMaterials);

    useImperativeHandle(
      ref,
      () => ({
        hasFocus: () => {
          const htmlEditor = editorRef.current?.getEditor();
          const htmlSelector = htmlEditor?.getSelection();
          return htmlEditor !== null && htmlSelector !== null;
        },
        insertTextToCursorPosition: text => {
          const htmlEditor = editorRef.current?.getEditor();
          const htmlSelector = htmlEditor?.getSelection();
          if (htmlEditor && htmlSelector) {
            htmlEditor.insertText(htmlSelector.index, text);
          }
        },
        insertUserPropertyToCursorPosition: (property: EmployeeAttribute) => {
          const htmlEditor = editorRef.current?.getEditor();
          const htmlSelector = htmlEditor?.getSelection();

          if (htmlEditor && htmlSelector) {
            const endOfPropertyTag = htmlSelector.index + 1;
            htmlEditor.insertEmbed(htmlSelector.index, "property", {
              ...property,
              type: "userproperty"
            });
            htmlEditor.setSelection(endOfPropertyTag, 0);
          }
        },
        replaceText: (text: string) => {
          const htmlEditor = editorRef.current?.getEditor();
          htmlEditor?.setText(text);
        },
        replaceHTML: (html: string) => {
          const htmlEditor = editorRef.current?.getEditor();
          const delta = htmlEditor?.clipboard.convert(html);
          delta && htmlEditor?.setContents(delta);
        }
      }),
      []
    );

    const onEmojiClick = useCallback(() => {}, []);
    const onGiphyClick = useCallback(() => {}, []);
    const onMaterialClick = useCallback(() => {}, []);

    const modules = {
      toolbar: {
        container: `#${toolbarName}`,
        handlers: {
          emoji: onEmojiClick,
          giphy: onGiphyClick,
          materials: onMaterialClick
        }
      },
      clipboard: {
        matchVisual: false
      }
    };

    const enableFontSize = !editorConfigurations || !editorConfigurations.disableFontSize;
    const enableImageFormat = !editorConfigurations || !editorConfigurations.disableImage;
    const enableGifFormat = !editorConfigurations || !editorConfigurations.disableGif;
    const enableMaterialFormat = !editorConfigurations || !editorConfigurations.disableMaterial;
    const enableAttributePersonalization =
      !editorConfigurations || !editorConfigurations.disableAttributePersonalization;

    const activateAttributeSelection = () => {
      setAttributeSearch("");
      setAttributeListOpen(true);
    };

    const deactivateAttributeSelection = () => {
      setAttributeSearch(undefined);
      setAttributeListOpen(false);
    };

    const handleEditorDelta = (delta: Delta) => {
      if (!enableAttributePersonalization) return;
      const insertDelta = delta.ops?.find(_ => Object.keys(_).includes("insert"));
      const deleteDelta = delta.ops?.find(_ => Object.keys(_).includes("delete"));

      if (insertDelta) {
        const insertData = insertDelta.insert;
        if (insertData === "/") {
          activateAttributeSelection();
        } else {
          const currentAttributeSearch = attributeSearch ?? "";
          setAttributeSearch(currentAttributeSearch + insertData);
        }
      } else if (deleteDelta) {
        if (attributeSearch === "") {
          return deactivateAttributeSelection();
        }
        const currentAttributeSearch = attributeSearch ?? "";
        setAttributeSearch(currentAttributeSearch.slice(0, -1));
      } else {
        deactivateAttributeSelection();
      }
    };

    return defaultValue !== undefined ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "250px",
          maxHeight: "350px",
          ...style
        }}
      >
        <div ref={setAttributeListRef} id={toolbarName} className={readonly ? "readonly" : ""}>
          {enableFontSize && (
            <select className="ql-size" defaultValue="normal">
              <option value="small" />
              <option value="normal" />
              <option value="large" />
              <option value="huge" />
            </select>
          )}
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" style={{ marginRight: theme.spacing.medium }} />

          <button className="ql-list" value="ordered" />
          <button
            className="ql-list"
            value="bullet"
            style={{ marginRight: theme.spacing.medium }}
          />

          <button className="ql-link" />
          {enableImageFormat && <button className="ql-image" />}
          <button
            ref={setEmojiButtonRef}
            className="ql-emoji"
            onClick={() => setEmojiOpen(!emojiOpen)}
          >
            <EmojiIcon />
          </button>
          {enableGifFormat && (
            <button ref={setGiphyRef} className="ql-giphy" onClick={() => setGiphyOpen(!giphyOpen)}>
              <GifIcon />
            </button>
          )}
          {enableMaterialFormat && hasLegacy && (
            <button
              ref={setMaterialRef}
              className="ql-materials"
              onClick={() => setMaterialsOpen(!materialsOpen)}
            >
              <Icon name="book" style={{ marginRight: theme.spacing.small }} />
            </button>
          )}
          <button className="ql-clean" style={{ float: "right" }} />
        </div>

        <ReactQuill
          ref={editorRef}
          className={cx(styles.Quill, { readonly, light }, className)}
          theme="snow"
          placeholder={placeholder}
          onChange={(value, delta) => {
            handleEditorDelta(delta);
            const fixed = value
              .replace(/<p>/g, '<p style="margin: 0;">')
              .replace(/<ul>/g, '<ul style="margin: 0;">')
              .replace(/<li>/g, '<li style="margin: 0;">')
              .replace(/<ol>/g, '<ol style="margin: 0;">')
              .replace(/(<ul>.*?<\/ul>)(?!<\/p>)/g, "<p>$1</p>");

            !silentMode && onChange && onChange(fixed);
          }}
          onKeyDown={e => setAttributeListRef(e.target)}
          onKeyUp={event => {
            if (event.key === "Escape") {
              deactivateAttributeSelection();
            }
          }}
          modules={modules}
          defaultValue={defaultValue}
          readOnly={readonly}
        />
        <AttributeListPopover
          presearch={attributeSearch}
          searchable
          popoverRef={attributeListRef}
          open={attributeListOpen}
          setOpen={open => {
            if (open) activateAttributeSelection();
            else deactivateAttributeSelection();
          }}
          onAttributeSelected={property => {
            const htmlEditor = editorRef.current?.getEditor();
            const htmlSelector = htmlEditor?.getSelection(true);
            const deleteTextLength = (attributeSearch?.length ?? 0) + 1;
            if (htmlEditor && htmlSelector) {
              const currentSelectorIndex = htmlSelector.index - deleteTextLength;
              htmlEditor.deleteText(currentSelectorIndex, deleteTextLength);
              htmlEditor.insertEmbed(currentSelectorIndex, "property", {
                ...property,
                type: "userproperty"
              });
              htmlEditor.insertText(currentSelectorIndex + 1, " ");
              htmlEditor.setSelection(currentSelectorIndex + 2, 0);
            }
            setMaterialsOpen(false);
          }}
        />
        <Popover
          referenceElement={emojiButtonRef}
          open={emojiOpen}
          onClose={() => setEmojiOpen(false)}
          closeOnContentClick={false}
        >
          <Picker
            onSelect={emoji => {
              const htmlEditor = editorRef.current?.getEditor();
              const htmlSelector = htmlEditor?.getSelection(true);
              if (htmlEditor && htmlSelector) {
                htmlEditor.insertText(htmlSelector.index, (emoji as BaseEmoji).native);
              }

              setEmojiOpen(false);
            }}
          />
        </Popover>
        <Popover
          referenceElement={giphyRef}
          open={giphyOpen}
          onClose={() => setGiphyOpen(false)}
          closeOnContentClick={false}
        >
          <Card style={{ padding: 0 }}>
            <ReactGiphySearchbox
              apiKey={config.giphyApiKey}
              wrapperClassName="giphy-select"
              onSelect={(item: any) => {
                const htmlEditor = editorRef.current?.getEditor();
                const htmlSelector = htmlEditor?.getSelection(true);
                if (htmlEditor && htmlSelector) {
                  htmlEditor.insertEmbed(htmlSelector.index, "image", item.images.fixed_width.url);
                }
                setGiphyOpen(false);
              }}
            />
            <Input
              type="text"
              size="small"
              placeholder="Add custom gif by url"
              style={{ margin: theme.spacing.small }}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  const htmlEditor = editorRef.current?.getEditor();
                  const htmlSelector = htmlEditor?.getSelection(true);
                  if (htmlEditor && htmlSelector) {
                    htmlEditor.insertEmbed(htmlSelector.index, "image", event.currentTarget.value);
                    event.currentTarget.value = "";
                    setGiphyOpen(false);
                  }
                }
              }}
            />
          </Card>
        </Popover>
        <Popover
          referenceElement={materialRef}
          onClose={() => setMaterialsOpen(false)}
          open={materialsOpen}
        >
          <OptionList
            key="materials-list"
            onItemClick={option => {
              const material = materials?.find(m => m.id === option.key)!;
              const htmlEditor = editorRef.current?.getEditor();
              const htmlSelector = htmlEditor?.getSelection(true);
              if (htmlEditor && htmlSelector) {
                const endOfPropertyTag = htmlSelector.index + 1;
                htmlEditor.insertEmbed(htmlSelector.index, "property", {
                  id: material.id,
                  name: material.title,
                  variable: `material:${material.id}`,
                  type: "material"
                });
                htmlEditor.insertText(endOfPropertyTag, " ");
                htmlEditor.setSelection(endOfPropertyTag + 1, 0);
              }
              setMaterialsOpen(false);
            }}
            options={
              materials?.map(material => ({
                key: material.id,
                title: material.title,
                startAdornment: materialTypeIcon[material.materialType]
              })) || []
            }
          />
        </Popover>
      </div>
    ) : null;
  }
);

const EmojiIcon = () => (
  <svg viewBox="0 0 18 18">
    <circle className="ql-fill" cx="7" cy="7" r="1"></circle>
    <circle className="ql-fill" cx="11" cy="7" r="1"></circle>
    <path className="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path>
    <circle className="ql-stroke" cx="9" cy="9" r="6"></circle>
  </svg>
);
