import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { useCallback, useMemo } from "react";

type Params = RouterInput["employees"]["v4"]["fields"]["list"];

export type EmployeeFieldCreate = RouterInput["employees"]["v4"]["fields"]["create"];
export type EmployeeFieldUpdate = RouterInput["employees"]["v4"]["fields"]["update"];

export type EmployeeField = RouterOutput["employees"]["v4"]["fields"]["list"][0];

export const useEmployeeFields = (params?: Params) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: fields, refetch } = api.employees.v4.fields.list.useQuery(params ?? {});
  const { data: categories } = api.employees.v4.fields.categories.list.useQuery({});

  const createOp = api.employees.v4.fields.create.useMutation();
  const updateOp = api.employees.v4.fields.update.useMutation();

  const reorderOp = api.employees.v4.fields.reorder.useMutation();

  const enableUpdateTrackingOp = api.employees.v4.fields.createUpdatedAtField.useMutation();

  const create = useCallback(
    async (entry: EmployeeFieldCreate) => {
      return createOp.mutateAsync(entry).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (data: EmployeeFieldUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const enableUpdateTracking = useCallback(
    async (fieldKey: string) => {
      await enableUpdateTrackingOp
        .mutateAsync({ key: fieldKey })
        .then(async () => {
          await refetch();
          toast.success("Update tracking enabled");
        })
        .catch(() => {
          toast.error("Failed to enable update tracking");
        });
    },
    [enableUpdateTrackingOp, refetch, toast]
  );

  const archive = onConfirmAction(
    async id => {
      await updateOp.mutateAsync({ id, updates: { archived: true } });
      await refetch();
      toast.success("Field archived");
    },
    {
      icon: "trash",
      title: "Archive field",
      description: "Are you sure you want to archive this employee field?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  const reorder = useCallback(
    async (items: EmployeeField[]) => {
      await reorderOp.mutateAsync({ ordering: items.map(i => i.id) });
    },
    [reorderOp]
  );

  const fieldTitle = useCallback(
    (path: string): string => {
      if (path.includes(".")) {
        const [parent, child] = path.split(".");
        const parentField = fields?.find(f => f.key === parent);
        if (!parentField) return path;
        const childField = fields?.find(f => f.key === child);
        if (!childField) return path;
        return `${parentField.title} - ${childField.title}`;
      }

      if (path.includes("/")) {
        const [group, child] = path.split("/");
        const groupField = fields?.find(f => f.key === group);
        if (!groupField) return path;
        const childField = fields?.find(f => f.key === child);
        if (!childField) return path;
        return `${groupField.title} - ${childField.title}`;
      }

      return fields?.find(f => f.key === path)?.title ?? path;
    },
    [fields]
  );

  const fieldsWithFullTitle = useMemo(() => {
    if (!fields) return fields;
    return fields.map(f => ({ ...f, title: fieldTitle(f.key) }));
  }, [fields, fieldTitle]);

  return {
    fields: fieldsWithFullTitle,
    create,
    update,
    archive,
    reorder,
    refetch,
    enableUpdateTracking,
    fieldTitle,
    categories
  };
};

export type TrackableEmployeeField = EmployeeField & {
  updatedAtField?: EmployeeField;
};

export const groupUpdatedAtFields = (
  fields?: EmployeeField[]
): TrackableEmployeeField[] | undefined => {
  if (!fields) return undefined;
  const updatedAtFields: string[] = [];

  const trackableFields = fields.map(f => {
    const updatedAtField = fields.find(uf => uf.key === `${f.key}updatedat`);
    if (updatedAtField) {
      updatedAtFields.push(updatedAtField.key);
      return { ...f, updatedAtField };
    } else return f;
  });

  return trackableFields.filter(f => !updatedAtFields.includes(f.key));
};
