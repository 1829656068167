import {
  H4,
  Stack,
  IconButton,
  Title,
  Card,
  useTheme,
  Button
} from "@introist/react-foundation/v2";

import { StepTypeCategories, useStepTypes } from "../stepTypes";
import { Info } from "../../../foundation";
import { StepTypeIcon } from "./StepTypeIcon";
import { useWorkspace } from "../../../IdentityProvider";

export type StepTypeSelectorProps = {
  onSelect: (stepType: string) => void;
  onClose: () => unknown;
  title?: string;
};

export const StepTypeSelector = ({
  onSelect,
  onClose,
  title = "Create new step"
}: StepTypeSelectorProps) => {
  const { theme } = useTheme();
  const workspace = useWorkspace();
  const stepTypes = useStepTypes();

  return (
    <Stack vertical style={{ margin: theme.spacing.xLarge }}>
      <Stack justifyContent="space-between">
        <H4>{title}</H4>
        <IconButton icon="crossSmall" onClick={onClose} />
      </Stack>
      <Stack vertical gap="large">
        {StepTypeCategories.map(category => {
          const steps = stepTypes.filter(
            stepType => stepType.category === category && !stepType.hidden
          );

          if (steps.length === 0) return null;

          return (
            <Stack vertical gap="medium" key={category}>
              <Title small>{category.toUpperCase()}</Title>
              <Stack vertical gap="small">
                {steps.map(stepType => (
                  <Card key={stepType.stepType} onClick={() => onSelect(stepType.stepType)}>
                    <Stack>
                      {stepType.icon ?? <StepTypeIcon stepType={stepType.stepType} />}
                      <Title variant="bold">{stepType.title}</Title>
                      <Info message={stepType.description} />
                    </Stack>
                  </Card>
                ))}
              </Stack>
            </Stack>
          );
        })}
      </Stack>

      {workspace?.plan === "basic" && (
        <Card style={{ marginTop: "var(--spacing-xxLarge)" }}>
          <Stack vertical>
            <H4>Upgrade for more step types</H4>
            <Title>
              Collect data with Forms, manage attendance with Events, and many more automated
              actions.
            </Title>
            <Button
              variant="outlined"
              startIcon="calendar"
              onClick={() =>
                window.open("https://calendly.com/d/38s-j3r-68s/introist-demo", "_blank")
              }
              style={{ marginTop: "var(--spacing-large)" }}
            >
              Book a call
            </Button>
          </Stack>
        </Card>
      )}
    </Stack>
  );
};
