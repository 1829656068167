import React, { ComponentProps, useMemo } from "react";
import { Icon, useTheme, Title } from "@introist/react-foundation/v2";

import styles from "./IssueIndicator.module.scss";
import { JourneyStepAlertRecord } from "../../../issues/api/AlertApi";
import { issueReasonMapping } from "../../../issues/components/IssueItem";
import cx from "classnames";
import { TooltipTitle } from "components/atoms";

export type IssueIndicatorProps = ComponentProps<"div"> & {
  issues: JourneyStepAlertRecord[];
  showCount?: boolean;
  indicateNoIssues?: boolean;
  describeSingleIssue?: boolean;
};

export const IssueIndicator = ({
  issues,
  indicateNoIssues,
  describeSingleIssue,
  showCount,
  className,
  ...rest
}: IssueIndicatorProps) => {
  const { theme } = useTheme();

  const resolvedIssueDescription = useMemo(() => {
    if (issues.length === 1) {
      return describeSingleIssue ? issueReasonMapping[issues[0].alertType] : "1 issue";
    }
    return `${issues.length} issues`;
  }, [issues, describeSingleIssue]);

  return (
    <div className={cx(styles.Root, className)} {...rest}>
      {issues.length === 0 && indicateNoIssues && (
        <>
          <Icon name="check" color={theme.palette.success.default} />
          <Title color={theme.palette.success.default}>No issues</Title>
        </>
      )}
      {issues.length > 0 && (
        <TooltipTitle
          color={theme.palette.danger.default}
          tooltip="There are some issues needing attention"
        >
          <Icon name="warning" color={theme.palette.danger.default} />

          {showCount && resolvedIssueDescription}
        </TooltipTitle>
      )}
    </div>
  );
};
