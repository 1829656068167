import { Drawer } from "@introist/react-foundation/v2";
import { JourneyStepEditor } from "../../journeys/JourneyStepEditor/JourneyStepEditor";
import { api, JourneyStepV2 } from "services/rpc/RpcProvider";

type JourneyStepDrawerProps = {
  stepId?: string;
  open: boolean;
  onClose: () => void;
  enableJourneyNavigate?: boolean;
};

export const AutomationStepDrawer = ({
  stepId,
  open,
  onClose,
  enableJourneyNavigate
}: JourneyStepDrawerProps) => {
  const { data: journeyStep } = api.journeys.steps.find.useQuery(
    { stepId: stepId! },
    {
      enabled: !!stepId
    }
  );

  return (
    <Drawer open={open} onClose={onClose} position="right" width="540px">
      {journeyStep && (
        <JourneyStepEditor
          journeyStep={journeyStep as JourneyStepV2}
          onClose={onClose}
          enableJourneyNavigate={enableJourneyNavigate}
        />
      )}
    </Drawer>
  );
};
