import { forwardRef } from "react";
import styled from "styled-components";

import { Button, ButtonProps } from "@introist/react-foundation/v2";

type IconToggleButtonProps = Omit<ButtonProps, "variant">;

const StyledIconToggleButton = styled(Button)`
  :hover {
    background-color: transparent;
  }
`;

const IconToggleButton = forwardRef<HTMLButtonElement, IconToggleButtonProps>(
  ({ children, active, size, ...rest }, ref) => {
    return (
      <StyledIconToggleButton
        {...rest}
        ref={ref}
        size={size}
        active={active}
        variant={active ? "outlined" : "blended"}
      >
        {children}
      </StyledIconToggleButton>
    );
  }
);

export { IconToggleButton };
