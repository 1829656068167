import { Backdrop, CloseIconButton, H4, Layout } from "@introist/react-foundation/v2";
import { a, config, useTransition } from "@react-spring/web";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { FC, ReactNode, useRef } from "react";
import styled, { css } from "styled-components";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  children?: ReactNode;
  overflow?: "hidden" | "auto";
};

const StyledHeader = styled.header<{ $bottomBorder?: boolean }>`
  padding: var(--spacing-xLarge) var(--spacing-xxLarge);

  ${({ $bottomBorder }) =>
    $bottomBorder &&
    css`
      border-bottom: 1px solid var(--palette-border-subdued);
    `}
`;

type FullscreenModalHeaderProps = {
  title: ReactNode;
  startAdornment?: ReactNode;
  onClose?: VoidFunction;
  bottomBorder?: boolean;
};
const Header = ({ startAdornment, title, bottomBorder, onClose }: FullscreenModalHeaderProps) => {
  return (
    <StyledHeader $bottomBorder={bottomBorder}>
      <Layout.Group justifyContent="space-between">
        <Layout.Group gap="large">
          {startAdornment && startAdornment}
          <H4>{title}</H4>
        </Layout.Group>
        {onClose && <CloseIconButton onClick={onClose} />}
      </Layout.Group>
    </StyledHeader>
  );
};

const StyledFooter = styled.footer<{ $topBorder?: boolean }>`
  padding: var(--spacing-xLarge) var(--spacing-xxLarge);
  ${({ $topBorder }) =>
    $topBorder &&
    css`
      border-top: 1px solid var(--palette-border-subdued);
    `}
`;

type FullscreenModalFooterProps = {
  children?: ReactNode;
  topBorder?: boolean;
};
const Footer = ({ children, topBorder }: FullscreenModalFooterProps) => {
  return <StyledFooter $topBorder={topBorder}>{children}</StyledFooter>;
};

const StyledBackdrop = styled(Backdrop)`
  z-index: var(--elevation-mars);
`;

type Compound = {
  Header: typeof Header;
  Footer: typeof Footer;
  Default: typeof DefaultFullscreenModal;
};

// @ts-ignore
const AnimatedFullscreenModal = styled(a.div)`
  position: absolute;
  top: var(--spacing-xLarge);
  left: var(--spacing-xLarge);
  bottom: var(--spacing-xLarge);
  right: var(--spacing-xLarge);
  border-radius: var(--rounding-large);
  background-color: var(--palette-background-default);
  z-index: var(--elevation-jupiter);
  max-height: calc(100vh - 3rem);
  max-width: 1440px;
  overflow: hidden;
  margin: 0 auto;

  ${StyledFooter} {
  }
`;

const FullscreenModalComponent: FC<Props> & Compound = ({ open, onClose, children, ...rest }) => {
  const ref = useRef(null);
  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: "scale(0.95)"
    },
    enter: {
      opacity: 1,
      transform: "scale(1)"
    },
    leave: {
      opacity: 0,
      transform: "scale(0.95)"
    },
    config: { ...config.stiff, clamp: true, tension: 260 }
  });

  if (open) {
    disableBodyScroll(document.body);
  } else {
    clearAllBodyScrollLocks();
  }

  return transitions((style, tOpen) =>
    tOpen ? (
      <StyledBackdrop open onClick={onClose}>
        <AnimatedFullscreenModal {...rest} style={style} ref={ref}>
          {children}
        </AnimatedFullscreenModal>
      </StyledBackdrop>
    ) : null
  );
};

const StyledDefaultFullscreenModal = styled(FullscreenModalComponent)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  .content {
    overflow: hidden;
  }
`;
const DefaultFullscreenModal = ({
  children,
  header,
  footer,
  ...rest
}: Props & {
  children?: ReactNode;
  header: FullscreenModalHeaderProps;
  footer: FullscreenModalFooterProps;
}) => {
  return (
    <StyledDefaultFullscreenModal {...rest}>
      <Header {...header} />
      <div className="content">{children}</div>
      <Footer {...footer} />
    </StyledDefaultFullscreenModal>
  );
};

FullscreenModalComponent.Header = Header;
FullscreenModalComponent.Footer = Footer;
FullscreenModalComponent.Default = DefaultFullscreenModal;
export const FullscreenModal = FullscreenModalComponent;
