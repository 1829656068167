import {
  Button,
  Card,
  CircularLoader,
  Field,
  H4,
  Input,
  LabelledCheckbox,
  Layout,
  Modal,
  Select,
  Stack,
  TextArea,
  Title,
  Tooltip,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useEffect, useState } from "react";
import { api, RouterInput } from "../../../services/rpc/RpcProvider";
import { FormEditorProps, SimpleForm } from "../../../components/templates";
import { Link } from "../../../components/atoms";
import { RadioCard } from "../../../components/molecules";
import { useIdentity, useWorkspace } from "../../../IdentityProvider";
import styled from "styled-components";
import { PlanAwareRoute } from "../../../foundation/templates/PlanAwareRoute";

const webhookUrl =
  "https://hooks.slack.com/services/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";

type AiWorkspaceUpdate = RouterInput["ai"]["workspace"]["update"];
export const AiSettingsRoute = () => {
  const toast = useToast();
  const { onConfirmAction } = useActions();

  const [setupOpen, setSetupOpen] = useState(false);

  const { user } = useIdentity();
  const workspace = useWorkspace();
  const { data: aiWorkspace, refetch } = api.ai.workspace.find.useQuery({});

  const createOp = api.ai.workspace.create.useMutation();
  const updateOp = api.ai.workspace.update.useMutation();
  const removeOp = api.ai.workspace.remove.useMutation();

  const onCreate = useCallback(
    async (apiKey: string | null) => {
      if (apiKey !== null) {
        await createOp
          .mutateAsync({ apiKey: apiKey ?? undefined })
          .then(async () => {
            await refetch();
            toast.success("AI set up");
            setSetupOpen(false);
          })
          .catch(() => {
            toast.error("Failed to set up AI");
          });
      } else {
        const ws = workspace?.name;
        const usr = user?.email;

        if (!ws || !usr) throw new Error("Failed to send request");

        const text = `
        AI access request:
        Workspace: ${workspace?.name}
        User: ${user?.email}
        `;

        await fetch(webhookUrl, {
          method: "POST",
          body: JSON.stringify({ text })
        })
          .then(() => {
            toast.success("We got your request and will get back to you soon!");
            setSetupOpen(false);
          })
          .catch(() => {
            toast.error("Failed to send request");
          });
      }
    },
    [createOp, refetch, toast, user, workspace]
  );

  const onUpdate = useCallback(
    async (updates: AiWorkspaceUpdate) => {
      await updateOp
        .mutateAsync(updates!)
        .then(async () => {
          await refetch();
          toast.success("Settings saved");
        })
        .catch(() => {
          toast.error("Failed to save settings");
        });
    },
    [toast, updateOp, refetch]
  );

  const onRemove = onConfirmAction(
    async () => {
      await removeOp
        .mutateAsync({})
        .then(async () => {
          await refetch();
          toast.success("AI access revoked");
        })
        .catch(() => {
          toast.error("Failed to revoke AI access");
        });
    },
    {
      title: "Revoke AI access",
      description: "Are you sure you want to revoke AI access?",
      confirmTitle: "Revoke"
    }
  );

  return (
    <PlanAwareRoute title="Upgrade to use AI">
      <Stack vertical gap="xLarge">
        <Card spacing="xLarge">
          <Stack vertical gap="xxLarge">
            <Stack vertical gap="large">
              <Stack vertical gap="small">
                <Stack gap="small" justifyContent="space-between">
                  <H4>Introist AI</H4>
                </Stack>
                {!aiWorkspace && <Title>Use Introist AI to assist in your automation work.</Title>}
                {aiWorkspace && aiWorkspace.openaiAccount === "own" && (
                  <Title>Using your OpenAI account with API key {aiWorkspace.apiKey}</Title>
                )}
                {aiWorkspace && aiWorkspace.openaiAccount === "introist" && (
                  <Title>Using Introist hosted AI</Title>
                )}
              </Stack>
            </Stack>
            {aiWorkspace === undefined && <CircularLoader fillParent />}
            {aiWorkspace === null && (
              <Layout.Group vertical alignItems="center">
                <Button onClick={() => setSetupOpen(true)}>Enable AI</Button>
              </Layout.Group>
            )}

            {aiWorkspace && (
              <>
                <SimpleForm
                  defaultValue={aiWorkspace}
                  onSubmit={onUpdate}
                  Editor={AiConfigEditor}
                  footerSecondary={
                    !!aiWorkspace ? (
                      <Button variant="outlined" onClick={onRemove}>
                        Revoke
                      </Button>
                    ) : undefined
                  }
                />
              </>
            )}
          </Stack>
        </Card>
        <AiSetupModal open={setupOpen} onClose={() => setSetupOpen(false)} onSubmit={onCreate} />
      </Stack>
    </PlanAwareRoute>
  );
};

const AiSetupModal = ({
  open,
  onClose,
  onSubmit
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (apiKey: string | null) => Promise<void>;
}) => {
  const [mode, setMode] = useState<string | null>(null);
  const [step, setStep] = useState("mode");
  const [apiKey, setApiKey] = useState("");

  useEffect(() => {
    setMode(null);
    setStep("mode");
    setApiKey("");
  }, [open]);

  return (
    <Modal title="Setup Introist AI" open={open} onClose={onClose} style={{ width: "550px" }}>
      {step === "mode" && (
        <Stack vertical>
          <RadioCard
            active={mode === "introist"}
            title="Introist AI account"
            vertical
            onClick={() => setMode("introist")}
          >
            <Title>Use our hosted AI. You don't need to set up anything.</Title>
          </RadioCard>
          <RadioCard
            active={mode === "own"}
            title="Your OpenAI account"
            vertical
            onClick={() => setMode("own")}
          >
            <Title>Use your own OpenAI account by submitting an API key.</Title>
          </RadioCard>
          <Button onClick={() => setStep("details")} style={{ marginTop: "16px" }}>
            Next
          </Button>
        </Stack>
      )}
      {step === "details" && mode === "own" && (
        <Stack vertical>
          <Title>Use your own OpenAI platform account by submitting your API key. </Title>
          <Link
            href="https://docs.introist.com/knowledge-base/build/introist-ai/setting-up-openai-account"
            target="_blank"
          >
            Read how to get an API key.
          </Link>
          <Field title="OpenAI API Key">
            <Input
              type="password"
              placeholder="Enter your OpenAI API key"
              value={apiKey}
              onChange={setApiKey}
            />
          </Field>
          <Button onClickWithLoading={() => onSubmit(apiKey)} style={{ marginTop: "16px" }}>
            Save
          </Button>
        </Stack>
      )}
      {step === "details" && mode === "introist" && (
        <Stack vertical>
          <Title>
            You are setting up Introist AI using hosted plan. There is no cost for using the AI but
            your monthly usage will be limited.
          </Title>
          <Title>
            After you've sent the request, we will set up the AI and notify you to your email. This
            might take a few business days.
          </Title>

          <Button onClickWithLoading={() => onSubmit(null)} style={{ marginTop: "16px" }}>
            Request
          </Button>
        </Stack>
      )}
    </Modal>
  );
};

const StyledDescription = styled(Stack)`
  margin-left: 1.6rem;
  max-width: 500px;
`;

const AiConfigEditor = ({ value, update }: FormEditorProps<AiWorkspaceUpdate>) => {
  const { onAction } = useActions();
  const { mutateAsync: analyseTone } = api.ai.analyseStyle.useMutation();
  const analyzeWritingStyle = onAction(async () => {
    const { writingStyle } = await analyseTone({});
    update({ writingStyle });
  }, "Something went wrong. Try again later.");
  return (
    <Stack vertical gap="large">
      <Field title="Model">
        <Select
          size="small"
          style={{ width: "300px" }}
          options={[
            { key: "gpt-3.5-turbo-1106", title: "Cheaper (GPT 3.5 Turbo)" },
            { key: "gpt-4o", title: "Better (GPT 4o)" }
          ]}
          value={value.model}
          onSelect={opt => update({ model: opt.key as any })}
        />
      </Field>
      <Field title="Instructions">
        <TextArea
          rows={5}
          value={value.instructions ?? ""}
          onChange={instructions => update({ instructions })}
          placeholder="Give some instructions for AI. Consider how you would brief an assistant."
        />
      </Field>
      <Field title="Writing style">
        <TextArea
          rows={5}
          value={value.writingStyle ?? ""}
          onChange={writingStyle => update({ writingStyle })}
          placeholder="Provide detailed instructions for how you want the AI to write."
        />
      </Field>
      <Stack vertical gap="small">
        <Title>Use Introist AI to analyse your writing style from your workflow steps.</Title>
        <Stack gap="none">
          {!value.dataUsage && (
            <Tooltip tooltip="Enable data usage">
              <Button
                disabled
                size="small"
                variant="blended"
                onClickWithLoading={analyzeWritingStyle}
              >
                Analyse writing style
              </Button>
            </Tooltip>
          )}
          {!!value.dataUsage && (
            <Button size="small" variant="blended" onClickWithLoading={analyzeWritingStyle}>
              Analyse writing style
            </Button>
          )}
        </Stack>
      </Stack>
      <Field title="Data usage">
        <Stack vertical gap="xSmall">
          <LabelledCheckbox
            label="Allow sending workspace data to AI"
            checked={value.dataUsage ?? false}
            onChange={checked => update({ dataUsage: checked })}
          />

          <StyledDescription vertical>
            <Title>
              Enable sending workspace specific data such as workflow step content to AI as
              examples. Improves response quality but exposes your data to OpenAI.
            </Title>
          </StyledDescription>
        </Stack>
      </Field>
    </Stack>
  );
};
