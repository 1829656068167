import { Button, OptionList, Popover, Title } from "@introist/react-foundation/v2";
import { useRef, useState } from "react";
import styled from "styled-components";
import { listTimezones } from "utils/dates";

type TimezoneSelectProps = {
  selectedTimezone?: string;
  onSelect: (timezone: string) => Promise<unknown>;
};

export const SelectGrid = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 11rem auto;
`;

export const TimezoneSelect = ({ selectedTimezone, onSelect }: TimezoneSelectProps) => {
  const refElement = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <SelectGrid>
      <Title>Workspace timezone</Title>
      <Button
        ref={refElement}
        variant="blended"
        active={open}
        onClick={() => setOpen(!open)}
        loading={loading}
        startIcon="globe"
      >
        {selectedTimezone ?? "Select timezone"}
      </Button>
      <Popover
        referenceElement={refElement.current}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <OptionList
          options={listTimezones().map(timezone => ({
            key: timezone,
            onClick: () => {
              setLoading(true);
              onSelect(timezone)
                .then(() => setOpen(false))
                .catch(() => {})
                .finally(() => setLoading(false));
            }
          }))}
          searchable
          autoFocus
        />
      </Popover>
    </SelectGrid>
  );
};
