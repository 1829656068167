import { StepOverview, JourneyStepRow } from "./JourneyStepRow";
import { List, ListRow } from "../../../../components/organisms";
import { SkeletonLoader, Stack, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";

const SectionHeaderRow = styled(ListRow)`
  background-color: var(--palette-surface-dimmed);
  padding-right: 1.75rem;
`;

type StepListSection = {
  title: string;
  steps: StepOverview[];
};

type StepListProps = {
  sections: StepListSection[];
  onClick: (stepId: string) => void;
  loading?: boolean;
};

export const StepList = ({ sections, onClick, loading }: StepListProps) => {
  if (loading) {
    return (
      <Stack vertical gap="small">
        <SkeletonLoader height={48} />
        <SkeletonLoader height={48} />
        <SkeletonLoader height={48} />
        <SkeletonLoader height={48} />
        <SkeletonLoader fadeOut height={48} />
      </Stack>
    );
  }

  return (
    <List style={{ overflowY: "auto" }}>
      {sections.map(section => {
        if (section.steps.length === 0) return null;
        return (
          <>
            <SectionHeaderRow gridTemplateColumns="1fr">
              <Title variant="bold">{section.title}</Title>
            </SectionHeaderRow>
            {section.steps.map(step => (
              <JourneyStepRow key={step.id} step={step} onClick={() => onClick(step.id)} />
            ))}
          </>
        );
      })}
    </List>
  );
};
