import partition from "lodash.partition";
import { useMemo } from "react";
import { WorkflowStep } from "../../../hooks/useWorkflowSteps";
import { orderBy } from "lodash";
import { getIntroistDate, resolveTrigger } from "@introist/introist-commons/dist";

const getTrigger = (step: WorkflowStep) => {
  return (step.stepData as any).dateTrigger ?? step.trigger;
};

export const useWorkflowTimelineSteps = (steps: WorkflowStep[]) => {
  const orderedSteps = useMemo(() => {
    return orderBy(steps, step => {
      const trigger = getTrigger(step);

      const referenceDate = resolveTrigger(
        trigger,
        trigger.reference === "enroll" ? getIntroistDate(new Date()) : "2100-01-01"
      );

      const referenceTime = (step.stepData as any)?.fromTime || step.trigger.time || "08:00";
      return `${referenceDate} ${referenceTime}`;
    });
  }, [steps]);

  const [immediateSteps, otherSteps] = useMemo(
    () =>
      partition(orderedSteps, step => {
        const trigger = getTrigger(step);
        return trigger.reference === "enroll" || trigger.side === "immediately";
      }),
    [orderedSteps]
  );

  const [stepsBeforeTarget, stepsAfterTarget] = useMemo(
    () => partition(otherSteps, step => ["before", "immediately"].includes(getTrigger(step).side)),
    [otherSteps]
  );

  const [whenEnrolledSteps, afterEnrollSteps] = useMemo(
    () => partition(immediateSteps, step => getTrigger(step).side === "immediately"),
    [immediateSteps]
  );

  return {
    timelineSteps: {
      beforeTarget: stepsBeforeTarget,
      afterTarget: stepsAfterTarget,
      immediate: whenEnrolledSteps,
      afterEnroll: afterEnrollSteps
    },
    hasSteps:
      stepsAfterTarget.length > 0 ||
      stepsBeforeTarget.length > 0 ||
      whenEnrolledSteps.length > 0 ||
      afterEnrollSteps.length > 0
  };
};
