import {
  CircularLoader,
  IconButton,
  Layout,
  Tag,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import moment from "moment";

import { TimeRange } from "services/api/types";

import {
  addDays,
  DateFormats,
  IntroistDate,
  IntroistDateTime,
  IntroistDateTimeFormats,
  isSame
} from "utils/dates";

import styled, { css } from "styled-components";
import { CalendarAvailabilityEmptyState } from "./EmptyState";
import { useAvailabilitySearch } from "./use-availability-search";
import { useMemo } from "react";

export interface AvailabilityEventDetails {
  start: IntroistDateTime;
  end: IntroistDateTime;
  timezone: string;
  targetEmails: string[];
  hostId: string;
}

export interface AvailabilityCalendarProps {
  eventDetails: AvailabilityEventDetails;
  onSelectSlot: (slot: TimeRange) => unknown;
}

const Columns = styled.div<{ $scroll?: boolean }>`
  display: grid;
  align-items: center;
  text-align: center;
  grid-template-columns: 28px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 28px;
  grid-column-gap: var(--spacing-medium);
  width: 100%;
  ${({ $scroll }) =>
    !!$scroll &&
    css`
      overflow-x: scroll;
      align-items: flex-start;
    `}
`;

const StyledTag = styled(Tag)<{ $active?: boolean }>`
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid var(--palette-border-default);
  padding: var(--spacing-xSmall) var(--spacing-small);

  ${({ $active }) =>
    $active &&
    css`
      border-color: var(--palette-primary-default);
      > h5 {
        font-weight: 500;
        color: var(--palette-foreground-default);
      }
    `}
`;

const CalendarContainer = styled(Layout.Group)`
  height: 25rem;
  width: 55rem;
`;

export const AvailabilityCalendar = ({ eventDetails, onSelectSlot }: AvailabilityCalendarProps) => {
  const { theme } = useTheme();

  const { slots, isError, isLoading, range, next, previous } = useAvailabilitySearch(eventDetails);

  const buildDaySlots = (day: IntroistDate) => {
    const slotsForDay = slots?.filter(_ => _.start.date === day) || [];
    return (
      <Layout.Group vertical gap="small">
        {slotsForDay.map(slot => {
          const selected =
            isSame(slot.start, eventDetails.start) && isSame(slot.end, eventDetails.end);
          return (
            <StyledTag
              onClick={() => onSelectSlot(slot)}
              $active={selected}
            >{`${slot.start.time} - ${slot.end.time}`}</StyledTag>
          );
        })}
        {slotsForDay.length === 0 && (
          <Title color={theme.palette.foreground.dimmed}>No times</Title>
        )}
      </Layout.Group>
    );
  };

  const buildDayTitle = (day: IntroistDate) => {
    return (
      <Title variant="bold">
        {moment(day, IntroistDateTimeFormats.date).format(DateFormats.date.withDay.short)}
      </Title>
    );
  };

  const days = useMemo(() => {
    const days = [];
    let current = range.from;
    while (current <= range.to) {
      days.push(current);
      current = addDays(current, 1);
    }
    return days;
  }, [range]);

  const renderCalendarHeader = () => (
    <Columns>
      <div>
        <IconButton
          icon="chevronLeft"
          onClick={() => {
            previous();
          }}
        />
      </div>
      {days.map(buildDayTitle)}
      <div>
        <IconButton
          icon="chevronRight"
          onClick={() => {
            next();
          }}
        />
      </div>
    </Columns>
  );

  if (isError) {
    return <CalendarAvailabilityEmptyState />;
  }

  return (
    <CalendarContainer vertical gap="medium" alignItems="center">
      {renderCalendarHeader()}

      <Columns $scroll>
        {!isLoading && slots && (
          <>
            <div />
            {days.map(buildDaySlots)}
            <div />
          </>
        )}
        {isLoading && (
          <CircularLoader
            fillParent
            style={{
              gridColumn: "1/-1"
            }}
          />
        )}
      </Columns>
    </CalendarContainer>
  );
};
