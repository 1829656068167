import { ActionMenu, Icon, useActions, useTheme } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../services/rpc/RpcProvider";

export const EmployeeActions = ({ employeeId }: { employeeId: string }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { onConfirmAction } = useActions();

  const deleteOp = api.employees.v4.delete.useMutation({});

  const onDeleteEmployee = onConfirmAction(
    async () => {
      await deleteOp.mutateAsync({ id: employeeId });
      navigate("/employees");
    },
    {
      title: "Delete employee",
      description:
        "Are you sure you want to delete this employee?\nThis will not affect to any active workflows.",
      confirmTitle: "Delete"
    }
  );

  return (
    <ActionMenu
      options={[
        {
          key: "delete",
          startAdornment: <Icon name="trash" color={theme.palette.danger.default} />,
          title: "Delete employee",
          onClick: onDeleteEmployee,
          titleStyles: { color: theme.palette.danger.default }
        }
      ]}
    />
  );
};
