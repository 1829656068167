import { Modal, ModalProps } from "@introist/react-foundation/v2";
import { AddCompanyEvent, AddCompanyEventProps } from "./AddCompanyEvent";
import styled from "styled-components";

type AddEventModalProps = AddCompanyEventProps & Pick<ModalProps, "onClose" | "open"> & {};

const StyledModal = styled(Modal)`
  height: 80vh;
  overflow: hidden;
  width: 100%;
  max-height: 768px;

  > :first-child {
    display: flex;
    overflow: hidden;
    flex: 1;
  }

  > :first-child > :last-child {
    max-height: initial;
    overflow: hidden;
    display: grid;
  }
`;

const StyledAddCompanyEvent = styled(AddCompanyEvent)`
  padding-top: 1rem;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
`;

export const AddEventModal = ({ onCompanyEventCreated, ...rest }: AddEventModalProps) => {
  return (
    <StyledModal
      {...rest}
      onCloseWithOutsideClick={rest.onClose}
      maxWidth={992}
      title="Import event"
    >
      <StyledAddCompanyEvent onCompanyEventCreated={onCompanyEventCreated} />
    </StyledModal>
  );
};
