import {
  Accordion,
  Button,
  Card,
  CircularLoader,
  Field,
  H4,
  Icon,
  IconButton,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { FormResponse, useFormResponse, useFormResponseActions } from "../useFormResponses";
import { formatDateTime } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";
import { useFormFields } from "../useFormFields";
import { LinkEmployeeSelect } from "./LinkEmployeeSelect";
import { SelectorIcon } from "../../employees/fields/EmployeeFieldSelector";
import { useEmployeeFields } from "../../employees/hooks/useEmployeeFields";
import { FittedTitle } from "../../../components/atoms";
import { IconWithCount } from "../../../foundation/blocks/IconWithCount";

export const FormResponseViewer = ({
  responseId,
  onClose
}: {
  responseId: string;
  onClose?: () => unknown;
}) => {
  const { response } = useFormResponse(responseId);
  const { formFields } = useFormFields(response?.formId ?? "", { enabled: !!response });

  if (!response || !formFields) return <CircularLoader fillParent />;

  return (
    <Stack vertical style={{ padding: "var(--spacing-xLarge" }}>
      <Stack>
        <H4>{response.employee}</H4>
        {onClose && (
          <IconButton icon="crossSmall" onClick={onClose} style={{ marginLeft: "auto" }} />
        )}
      </Stack>
      <Title>Submitted at {formatDateTime(response.submittedAt!, DateFormats.datetime.long)}</Title>
      <Card>
        <Stack vertical>
          <Stack>
            <SelectorIcon icon="person" />
            <Title variant="bold">Linked to Employee</Title>
          </Stack>
          {!response.employeeId && (
            <Title>This response is not currently linked to any Employee.</Title>
          )}
          <LinkEmployeeSelect responseId={responseId} employeeId={response.employeeId} />

          {response.transformResult && (
            <TransformResultOverview responseId={responseId!} result={response.transformResult!} />
          )}
        </Stack>
      </Card>

      <H4>Responses</H4>
      <Stack vertical gap="large">
        {formFields.map(field => {
          const value = response.values[field.id];
          return (
            <Card>
              <Field key={field.id} title={field.title}>
                <Title variant={!!value ? "bold" : undefined}>{value ?? "-"}</Title>
              </Field>
            </Card>
          );
        })}
      </Stack>
    </Stack>
  );
};

const TransformResultOverview = ({
  responseId,
  result
}: {
  responseId: string;
  result: FormResponse["transformResult"];
}) => {
  const { theme } = useTheme();
  const { fields } = useEmployeeFields();

  const { transform } = useFormResponseActions();

  return (
    <div style={{ marginTop: "var(--spacing-medium)" }}>
      <Accordion
        blended
        defaultOpen={false}
        toggleOpenOnHeaderClick={true}
        title={
          <Stack>
            <SelectorIcon icon="arrowsLeftRight" />
            <Title variant="bold">Fields updated to Employee</Title>
            {result!.success && (
              <IconWithCount
                iconName="arrowsLeftRight"
                count={result!.transformLog.filter(f => f.targetValue).length}
                color={theme.palette.success.default}
              />
            )}
            {!result!.success && <ErrorWithIcon error={"Failed"} />}
          </Stack>
        }
      >
        <Stack vertical gap="small">
          {result!.transformLog
            .filter(f => f.targetValue)
            .map(f => (
              <Card style={{ padding: "var(--spacing-small) var(--spacing-large)" }}>
                <Stack vertical>
                  <Stack style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                    <Title>
                      {(fields ?? []).find(ef => ef.key === f.targetField)?.title ?? f.targetField}:
                    </Title>
                    <FittedTitle
                      maxLength={65}
                      variant="bold"
                      style={{ marginLeft: "auto" }}
                      showTooltip
                    >
                      {f.targetValue ?? "-"}
                    </FittedTitle>
                    {f.error && (
                      <>
                        <div />
                        <ErrorWithIcon error={f.error} />
                      </>
                    )}
                  </Stack>
                </Stack>
              </Card>
            ))}
          {!result!.success && (
            <Button
              variant="blended"
              onClickWithLoading={() => transform(responseId!)}
              startIcon="reconnect"
              style={{ marginLeft: "auto" }}
            >
              Retry
            </Button>
          )}
        </Stack>
      </Accordion>
    </div>
  );
};

const ErrorWithIcon = ({ error }: { error: string }) => {
  const { theme } = useTheme();
  return (
    <Stack>
      <Icon name="warning" color={theme.palette.danger.default} />
      <Title color={theme.palette.danger.default}>{error}</Title>
    </Stack>
  );
};
