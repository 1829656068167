import React, { useState, useEffect, useCallback, useRef } from "react";
import gsap, { Power4 } from "gsap";

const STROKE_WIDTH = 1;
const COUNT = 10;
const DURATIONS = [0.3, 0.8];

let TIME_LINE: any = null;

type ExplodingLinesProps = {
  size?: number;
  delay?: number;
  repeatDelay?: number;
  repeat?: number;
  color?: string;
  radius?: number;
};

export const ExplodingLines = ({
  size = 100,
  delay = 0,
  repeatDelay = 0,
  repeat = 0,
  radius: radiusProp = 20,
  color = "#18181c"
}: ExplodingLinesProps) => {
  const linesRefs = useRef<Array<React.RefObject<SVGLineElement>>>(
    Array.from({ length: COUNT }).map(() => React.createRef())
  );

  const [prevSize, setPrevSize] = useState(size);
  const [prevDelay, setPrevDelay] = useState(0);
  const [prevRepeatDelay, setPrevRepeatDelay] = useState(0);
  const [prevRepeat, setPrevRepeat] = useState(0);

  const center = size / 2;
  const strokeWidth = Math.ceil((size * STROKE_WIDTH) / 100);

  const explode = useCallback(() => {
    const ease = Power4.easeOut;
    const angle = (2 * Math.PI) / COUNT;
    const radius = (prevSize * radiusProp) / 100;
    const durations = DURATIONS;
    const center = prevSize / 2;
    const timelines: any[] = [];

    TIME_LINE = gsap.timeline({
      repeat: prevRepeat,
      delay: prevDelay,
      repeatDelay: prevRepeatDelay
    });

    linesRefs.current.forEach((ref, i) => {
      const timeline = gsap.timeline();
      const x = center + radius * Math.cos(i * angle);
      const y = center + radius * Math.sin(i * angle);
      const start = { x2: x, y2: y };
      const end = { x1: x, y1: y };

      timeline
        .fromTo(
          ref.current,
          durations[0],
          { attr: { x2: center, y2: center } },
          { attr: start, ease }
        )
        .fromTo(
          ref.current,
          durations[1],
          { attr: { x1: center, y1: center } },
          { attr: end, ease },
          `-=${durations[0]}`
        );

      timelines.push(timeline);
    });

    TIME_LINE.add(timelines);
  }, [prevSize, prevRepeat, prevDelay, radiusProp, prevRepeatDelay]);

  useEffect(() => {
    if (TIME_LINE) TIME_LINE.kill();
    explode();
  }, [explode]);

  useEffect(() => {
    setPrevSize(size);
    setPrevDelay(delay);
    setPrevRepeatDelay(repeatDelay || 0);
    setPrevRepeat(repeat);
  }, [size, delay, repeatDelay, repeat]);

  return (
    <svg width={size} height={size} style={{ position: "absolute" }}>
      <>
        {linesRefs.current.map((ref, i) => (
          <line
            x1={center}
            y1={center}
            x2={center}
            y2={center}
            ref={ref}
            key={i}
            strokeWidth={strokeWidth}
            stroke={color}
          />
        ))}
      </>
    </svg>
  );
};
