import { DataForm } from "@introist/react-foundation/v2";
import { useWorkflowStepEditor } from "../../workflows/routes/WorkflowEditor/StepEditor/hooks/useWorkflowStepEditor";
import { WorkflowStepTypes } from "../stepTypes";
import { useMemo } from "react";

export type StepEditorProps = {
  form: DataForm<any>;
  stepDataPath: string;
  targetDateTerm?: string;
};

export type ReadOnlyStepProps = {
  stepId: string;
};

export const useStepEditor = (props: StepEditorProps) => {
  const v1Editor = useWorkflowStepEditor({
    form: props.form,
    formPrefix: props.stepDataPath,
    targetDateTerm: props.targetDateTerm
  });

  const editor = useMemo(() => {
    const V2Editor = WorkflowStepTypes.find(st => st.stepType === props.form.data.stepType)?.Editor;

    if (V2Editor) return <V2Editor {...props} />;
    return v1Editor;
  }, [props, v1Editor]);

  return editor;
};
