import {
  Button,
  Card,
  Field,
  Icon,
  IconButton,
  Input,
  Modal,
  Stack,
  Title,
  useActions
} from "@introist/react-foundation/v2";
import { useFieldCategories } from "../hooks/useFieldCategories";
import { useState } from "react";
import { OrderableList } from "../../../foundation";

type CategoriesModalProps = {
  open: boolean;
  onClose: () => void;
};

export const CategoriesModal = ({ open, onClose }: CategoriesModalProps) => {
  const { categories, create, remove, reorder } = useFieldCategories();

  const [newCategoryOpen, setNewCategoryOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  return (
    <Modal
      title="Manage categories"
      open={open}
      onClose={onClose}
      primaryButton={{
        title: "Add category",
        variant: "outlined",
        size: "small",
        startIcon: "plus",
        onClick: () => setNewCategoryOpen(true)
      }}
    >
      <Stack vertical>
        <CategoryCard category="General" />
        <OrderableList
          data={categories ?? []}
          onReorder={items => reorder(items.map(({ id }) => id))}
          renderRow={category => (
            <CategoryCard
              key={category.id}
              category={category.title}
              onRemove={() => remove(category.id)}
              orderable
            />
          )}
        />
      </Stack>
      <Modal open={newCategoryOpen} onClose={() => setNewCategoryOpen(false)} title="New category">
        <Stack alignItems="flex-end">
          <Stack>
            <Field title="Category name">
              <Input
                size="small"
                style={{ width: "200px" }}
                autoFocus
                value={newCategory}
                onChange={setNewCategory}
              />
            </Field>
          </Stack>
          <Button
            onClickWithLoading={async () => {
              if (newCategory) {
                await create(newCategory);
                setNewCategoryOpen(false);
              }
            }}
          >
            Create
          </Button>
        </Stack>
      </Modal>
    </Modal>
  );
};

const CategoryCard = ({
  category,
  onRemove,
  orderable
}: {
  category: string;
  onRemove?: () => Promise<void>;
  orderable?: boolean;
}) => {
  const { onConfirmAction } = useActions();

  const remove = onConfirmAction(onRemove!, {
    title: "Remove category",
    description:
      "Are you sure you want to remove this category? All fields in the category will be moved to category General."
  });

  return (
    <Card style={{ width: "300px" }}>
      <Stack justifyContent="space-between">
        <Stack>
          <Icon name="grab" dimmed style={{ visibility: orderable ? "visible" : "hidden" }} />
          <Title variant="bold">{category}</Title>
        </Stack>
        {onRemove && <IconButton icon="trash" onClick={remove} dimmed />}
      </Stack>
    </Card>
  );
};
