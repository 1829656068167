import { Field, Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

type JiraConfig = {
  domain: string;
  username: string;
  apiKey: string;
};

export const JiraConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as JiraConfig) ?? {
    domain: "",
    username: "",
    apiKey: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <Field title="Domain">
        <TextInput
          placeholder="Domain"
          value={credentials.domain}
          onChange={domain =>
            onChange({
              credentials: { ...credentials, domain }
            })
          }
        />
      </Field>
      <Field title="User">
        <TextInput
          placeholder="Username"
          value={credentials.username}
          onChange={username =>
            onChange({
              credentials: { ...credentials, username }
            })
          }
        />
      </Field>
      <Field title="Api key">
        <TextInput
          placeholder="Api key"
          value={credentials.apiKey}
          type="password"
          onChange={apiKey =>
            onChange({
              credentials: { ...credentials, apiKey }
            })
          }
        />
      </Field>
    </Layout.Group>
  );
};
