import { Stack, Title } from "@introist/react-foundation/v2";
import { StepTypeIcon } from "../../../../workflows/components/steps";
import { ListRow } from "../../../../../components/organisms";
import styled from "styled-components";
import { formatDateTime } from "../../../../../utils/DatesUtils";
import { DateFormats } from "../../../../../utils/dates";
import { StepStatusBadge } from "../../../../flows/components/StepStatusBadge";
import { useMemo } from "react";
import { pendingPreconditionCount } from "../../../utils";

const TruncatingTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StepName = styled(TruncatingTitle)<{ $strokeThrough: boolean }>`
  text-decoration: ${({ $strokeThrough }) => ($strokeThrough ? "line-through" : "none")};
`;

export type StepOverview = {
  id: string;
  stepType: string;
  status: string;
  title: string;
  scheduledAt: string;
  pendingPreconditions?: string[];
  pendingPreconditionAttributes?: string[];
  employee?: {
    id: string;
    name: string;
    firstname: string;
    lastname: string;
  };
};

type StepRowProps = {
  step: StepOverview;
  onClick?: (stepId: string) => void;
  showStatus?: boolean;
};

export const JourneyStepRow = ({ onClick, step, showStatus = true }: StepRowProps) => {
  const extendedStatus = useMemo(() => {
    const pending = pendingPreconditionCount(step);

    if (step.status === "scheduled" && pending > 0) return "paused";

    return step.status;
  }, [step]);
  return (
    <ListRow
      gridTemplateColumns="100px 160px auto auto"
      onClick={onClick ? () => onClick(step.id) : undefined}
    >
      <Title small>{formatDateTime(step.scheduledAt, DateFormats.datetime.withDay.short)}</Title>
      <Stack>
        <TruncatingTitle>{step.employee?.name ?? "Deleted employee"}</TruncatingTitle>
      </Stack>
      <Stack>
        <StepTypeIcon compact variant="blended" stepType={step.stepType} />
        <StepName variant="bold" $strokeThrough={step.status === "canceled"}>
          {step.title ?? "Untitled step"}
        </StepName>
      </Stack>

      {showStatus && (
        <div style={{ justifySelf: "flex-end" }}>
          <StepStatusBadge status={extendedStatus} />
        </div>
      )}
    </ListRow>
  );
};
