import styled from "styled-components";
import {
  boldTitleFontStyle,
  titleFontStyle,
  TitleProps,
  Tooltip
} from "@introist/react-foundation/v2";
import { useLayoutEffect, useRef, useState } from "react";

const Elem = styled.div<{ variant?: string; color?: string }>`
  ${props => (props.variant === "bold" ? boldTitleFontStyle : titleFontStyle)};

  color: ${props => props.color};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

type CappedTitleProps = TitleProps & {
  showTooltip?: boolean;
};

export const CappedTitle = ({ showTooltip, ...rest }: CappedTitleProps) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      setIsOverflow(current.scrollWidth > current.clientWidth);
    }
  }, [ref, rest]);

  return (
    <Tooltip tooltip={isOverflow && showTooltip && rest.children ? rest.children.toString() : ""}>
      <Elem ref={ref} {...rest} />
    </Tooltip>
  );
};
