import styled from "styled-components";
import { ContentType } from "../schema";
import { Stack, Title } from "@introist/react-foundation/v2";

const Wrapper = styled(Stack)`
  padding-left: var(--spacing-medium);
  padding-right: var(--spacing-medium);
  cursor: pointer;
`;

interface GuideProps {
  contentType: ContentType;
  onClick?: () => void;
  isEnabled?: boolean;
}

const getGuideText = (isEnabled: boolean, contentType: ContentType) => {
  switch (contentType) {
    case ContentType.WorkflowSteps:
      if (!isEnabled) return `Activate AI to draft steps`;
      return "Draft steps with AI";
    case ContentType.EmailMessage:
    case ContentType.SlackMessage:
      if (!isEnabled) return "Activate AI to write message";
      return "Write message with AI";
    case ContentType.EmailSubject:
      if (!isEnabled) return "Activate AI to write subject";
      return "Write subject with AI";
    case ContentType.MeetingDescription:
      return "Write description with AI";
    default:
      if (!isEnabled) return "Activate AI to work with it";
      return "Work with AI";
  }
};

export const Guide = ({ contentType, onClick, isEnabled }: GuideProps) => {
  return (
    <Wrapper
      onClick={isEnabled ? () => onClick && onClick() : () => window.open("/settings/ai", "_blank")}
    >
      <Title>{getGuideText(!!isEnabled, contentType)}</Title>
    </Wrapper>
  );
};
