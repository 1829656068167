import { useCallback, useState } from "react";
import styled from "styled-components";
import { ActionMenu, H4, Layout, useToast } from "@introist/react-foundation/v2";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { HostCalendarIcon, MediumTag } from "components/atoms";
import { PanelLayout } from "components/templates";
import { useEmailSenders } from "hooks";
import { TopBarRoute } from "components/templates";
import { api, CompanyEvent } from "services/rpc/RpcProvider";

import { EventRouteParams } from "..";
import { InfoTooltip } from "foundation";
import { EventAttendeeList } from "../components";
import {
  AddCompanyEventEmployeeModal,
  CompanyEventSettings,
  LinkCompanyEventModal
} from "../containers";

import { useEventBreadcrumbs, useEventData } from "./hooks";
import { EditableHeading } from "components/molecules";
import { useCompanyEventActions } from "../hooks/useCompanyEventActions";

const StyledCompanyEventSettings = styled(CompanyEventSettings)`
  margin-bottom: -1.5rem;
`;

export const EventRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const { eventId } = useParams<Pick<EventRouteParams, "eventId">>();
  const { data: companyEvents, isLoading: companyEventsLoading } = api.companyEvents.list.useQuery(
    {}
  );

  const { data: recurringEvents, refetch: refetchLinkedEvents } =
    api.companyEvents.recurringEvents.useQuery({
      companyEventId: eventId!
    });

  const updateCompanyEvent = api.companyEvents.update.useMutation();
  const {
    data: event,
    isLoading: eventLoading,
    refetch: refetchCompanyEvent
  } = api.companyEvents.find.useQuery({ id: eventId || "" }, { enabled: !!eventId });
  const { senders: hosts, isLoading: sendersLoading } = useEmailSenders(false);

  const { eventInstances, eventAttendees, unmanagedAttendees, reload, reloadUnmanaged } =
    useEventData(eventId!);

  const actions = useCompanyEventActions(eventId!);

  const [addEmployeeModalOpen, setAddEmployeeModalOpen] = useState(false);

  const importAttendees = api.companyEvents.attendees.import.useMutation();

  const breadcrumbs = useEventBreadcrumbs(companyEvents);

  const loading = eventLoading || companyEventsLoading || sendersLoading;

  const host = hosts.find(host => host.id === event?.hostId);

  const updateTitle = useCallback(
    (title: string) => {
      return updateCompanyEvent
        .mutateAsync({
          id: eventId!,
          updates: { title }
        })
        .then(() => refetchCompanyEvent())
        .catch(() => toast.error("Failed to update event"));
    },
    [eventId, updateCompanyEvent, refetchCompanyEvent, toast]
  );

  if ((!loading && !event) || !eventId) {
    return <H4>Uh oh event not found</H4>;
  }

  return (
    <TopBarRoute withoutContentPadding paths={breadcrumbs} loading={loading}>
      <PanelLayout.View>
        <PanelLayout.Grid>
          <PanelLayout.Content>
            <EventAttendeeList
              eventId={eventId}
              managedAttendees={eventAttendees || []}
              eventInstances={eventInstances || []}
              unmanagedAttendees={unmanagedAttendees || []}
              onEnrollEmployee={() => setAddEmployeeModalOpen(true)}
              onImportAttendee={async (attendee, calendarEventId) => {
                return importAttendees
                  .mutateAsync({
                    companyEventId: eventId,
                    attendees: [{ email: attendee.email, calendarEventId }]
                  })
                  .then(async () => {
                    await Promise.all([reload(), reloadUnmanaged()]);
                    toast.success("Attendee imported");
                  })
                  .catch(() => {
                    toast.error("Failed to import attendee");
                  });
              }}
              onSyncEvent={() => reload()}
            />
          </PanelLayout.Content>
          <PanelLayout.Panel>
            <Layout.Group vertical gap="xLarge">
              <Layout.Group vertical gap="large">
                <Layout.Group justifyContent="space-between">
                  <EditableHeading
                    editable={true}
                    onSave={updateTitle}
                    initialValue={event?.title ?? ""}
                    dimmed
                  >
                    <H4>{event?.title}</H4>
                  </EditableHeading>
                  <ActionMenu options={actions} />
                </Layout.Group>
                {recurringEvents?.map((recurringEvent, idx) => (
                  <MediumTag
                    key={`event-tag-${recurringEvent.id}-${idx}`}
                    backgroundColor="transparent"
                    startAdornment={<HostCalendarIcon emailProvider={host?.description} />}
                  >
                    <Layout.Group>
                      {host?.name} {recurringEvent && `- ${recurringEvent.summary}`}
                      {recurringEvent && (
                        <InfoTooltip
                          tooltip={`${recurringEvent.recurrenceHuman} ${recurringEvent.time}`}
                        />
                      )}
                    </Layout.Group>
                  </MediumTag>
                ))}
              </Layout.Group>
              <PanelLayout.PanelDivider />
              <StyledCompanyEventSettings companyEventId={eventId} />
              <PanelLayout.PanelDivider />
            </Layout.Group>
          </PanelLayout.Panel>
        </PanelLayout.Grid>
      </PanelLayout.View>

      {event && (
        <>
          <AddCompanyEventEmployeeModal
            companyEvent={event as CompanyEvent}
            open={addEmployeeModalOpen}
            onClose={() => setAddEmployeeModalOpen(false)}
            onEnrolled={async () => {
              await reload();
              setAddEmployeeModalOpen(false);
            }}
          />
        </>
      )}
      <LinkCompanyEventModal
        companyEventId={eventId!}
        open={location.hash.includes("#link-event")}
        onClose={() => navigate(`/events/${eventId}`, { replace: true })}
        onEventLinked={() => {
          refetchCompanyEvent();
          refetchLinkedEvents();
          reload();
        }}
      />
    </TopBarRoute>
  );
};
