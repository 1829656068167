import {
  api,
  DatasetDef,
  DatasetDefCreate,
  DatasetDefUpdate
} from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";

export const useDatasetDefs = () => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: datasets, refetch } = api.datasets.defs.list.useQuery(
    {},
    {
      refetchOnMount: "always"
    }
  );

  const createOp = api.datasets.defs.create.useMutation();
  const updateOp = api.datasets.defs.update.useMutation();
  const archiveOp = api.datasets.defs.archive.useMutation();

  const create = useCallback(
    async (entry: Partial<DatasetDef>) => {
      return createOp.mutateAsync(entry as DatasetDefCreate).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (data: DatasetDefUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const archive = onConfirmAction(
    async id => {
      await archiveOp.mutateAsync({ id });
      await refetch();
      toast.success("Dataset archived");
    },
    {
      icon: "trash",
      title: "Archive dataset",
      description: "Are you sure you want to archive this dataset?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  return {
    datasets,
    create,
    update,
    archive
  };
};
