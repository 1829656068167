import { DataForm, Title, useTheme } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { EmployeeSelectEditor } from "../../workflows/routes/WorkflowEditor/StepEditor/EmployeeSelectEditor";
import { useMemo } from "react";
import { useEmployee } from "../../employees/hooks/useEmployees";

type EmployeeSelectJourneyStepEditorProps = {
  form: DataForm<any>;
};

const SelectedEmployee = ({ employeeId }: { employeeId?: string }) => {
  const { theme } = useTheme();

  const { employee } = useEmployee(
    { id: employeeId ?? "" },
    {
      enabled: !!employeeId
    }
  );

  return (
    <Title variant="bold" style={{ marginLeft: theme.spacing.large }}>
      {employee?.name}
    </Title>
  );
};

export const EmployeeSelectJourneyStepEditor = ({ form }: EmployeeSelectJourneyStepEditorProps) => {
  const editable = useMemo(() => {
    return ["draft", "scheduled", "failing"].includes(form.data.status);
  }, [form]);

  const resolveExtras = () => {
    if (editable) return null;

    return (
      <StepEditorAccordion.Row label="Selected">
        <SelectedEmployee employeeId={form.get("executionData.selectedEmployeeId")} />
      </StepEditorAccordion.Row>
    );
  };

  return (
    <EmployeeSelectEditor
      form={form}
      formPrefix="previewData."
      readOnly={!editable}
      extras={resolveExtras()}
    />
  );
};
