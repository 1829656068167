import { Layout, Field, Select } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
type PlanMillConfig = {
  name: string;
  clientId: string;
  clientSecret: string;
  instance: string;
};
export const PlanMillConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const planMillValue = (values?.credentials as PlanMillConfig) ?? {
    clientId: "",
    clientSecret: "",
    instance: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        label="Client ID"
        value={planMillValue.clientId}
        onChange={clientId =>
          onChange({
            credentials: { ...planMillValue, clientId }
          })
        }
      />
      <TextInput
        label="Client Secret"
        type="password"
        value={planMillValue.clientSecret}
        onChange={clientSecret =>
          onChange({
            credentials: { ...planMillValue, clientSecret }
          })
        }
      />
      <TextInput
        label="Instance"
        value={planMillValue.instance}
        onChange={instance =>
          onChange({
            credentials: { ...planMillValue, instance }
          })
        }
      />
      <Field title="Data category">
        <Select
          variant="outlined"
          style={{ width: "100%" }}
          options={[
            {
              key: "employees",
              title: "Employees"
            },
            {
              key: "vacations",
              title: "Vacations"
            },
            {
              key: "absences",
              title: "Absences"
            },
            {
              key: "monthlytimebalance",
              title: "Monthly Timebalance"
            }
          ]}
          value={values?.dataCategory ?? "employees"}
          onSelect={opt => onChange({ dataCategory: opt.key })}
        />
      </Field>
    </Layout.Group>
  );
};
