import { useEffect, useState } from "react";
import { CircularLoader } from "@introist/react-foundation/v2";
import useInterval from "use-interval";

import { useIntegrationsApi } from "services/api/IntegrationsApi";
import { OAuthCredentialForm } from "../../components";

type OAuthCredentialContainerProps = {
  integrationId: string;
};

export const OAuthCredentialContainer = ({
  integrationId,
  ...rest
}: OAuthCredentialContainerProps) => {
  const api = useIntegrationsApi();
  const findIntegration = api.find({ integrationId });

  const { data: integration } = findIntegration.query;
  const [polling, setPolling] = useState(false);

  useEffect(() => {
    if (integration?.credentialsConfigured) setPolling(false);
  }, [integration]);

  useInterval(() => {
    if (polling) findIntegration.refetch();
  }, 5000);

  const onStartConnect = () => {
    // We only start polling if credentials are not configured
    // as currently we have no way of knowing when they are updated
    setPolling(!integration!.credentialsConfigured);
  };

  if (!integration) {
    return <CircularLoader fillParent />;
  }

  return (
    <OAuthCredentialForm
      integrationId={integrationId}
      onConnectInitiated={onStartConnect}
      connecting={polling}
      credentialsConfigured={!!integration.credentialsConfigured}
      {...rest}
    />
  );
};
