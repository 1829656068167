import { Button, Card, H3, Icon, IconName, Stack, Title } from "@introist/react-foundation/v2";
import { useWorkspace } from "../../IdentityProvider";
import { ReactElement } from "react";

export const PlanAwareRoute = ({
  children,
  icon,
  title,
  description
}: {
  children: ReactElement;
  title: string;
  description?: string;
  icon?: IconName;
}) => {
  const workspace = useWorkspace();

  if (!workspace) return <div />;

  if (workspace?.plan === "basic") {
    return (
      <Stack vertical justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Card elevated>
          <Stack vertical alignItems="center" style={{ padding: "var(--spacing-xxLarge)" }}>
            {icon && <Icon name={icon} dimmed style={{ width: "32px", height: "32px" }} />}
            <H3>{title}</H3>
            {description && (
              <Title style={{ width: "300px", textAlign: "center" }}>{description}</Title>
            )}
            <Button
              variant="outlined"
              startIcon="calendar"
              onClick={() =>
                window.open("https://calendly.com/d/38s-j3r-68s/introist-demo", "_blank")
              }
              style={{ marginTop: "var(--spacing-large)" }}
            >
              Book a call
            </Button>
          </Stack>
        </Card>
      </Stack>
    );
  }

  return children;
};
