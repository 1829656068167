import { useEffect, useState } from "react";
import { Icon } from "@introist/react-foundation/v2";
import { EmployeeFieldSelectorPopover } from "../../modules/employees/fields/EmployeeFieldSelector";

export const FieldSelector = ({
  onSelect,
  forceOpen,
  preSearch
}: {
  onSelect: (path: string, title: string) => boolean;
  forceOpen?: boolean;
  preSearch?: string;
}) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (forceOpen !== undefined) setOpen(forceOpen);
  }, [forceOpen]);

  return (
    <>
      <button onClick={() => setOpen(!open)} ref={setPopoverRef}>
        <Icon name="attributeGroup" />
      </button>
      <EmployeeFieldSelectorPopover
        popoverRef={popoverRef}
        open={open}
        onSelect={(path, title) => {
          if (onSelect(path, title)) setOpen(false);
        }}
        setOpen={setOpen}
        preSearch={preSearch}
      />
    </>
  );
};
