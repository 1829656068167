export const dateFormats: string[] = [
  "DD.MM.YYYY",
  "MM.DD.YYYY",
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "MMMM Do YYYY",
  "Do MMMM YYYY",
  "MMMM Do, YYYY",
  "Do MMMM, YYYY",
  "dddd, MMMM Do YYYY",
  "dddd, Do MMMM YYYY"
];
