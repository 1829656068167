import { Icon } from "@introist/react-foundation/v2";
import { ReportsRoute } from "./ReportsRoute";

export const REPORTS_ROUTES = {
  reports: {
    key: "route-reports",
    title: "Reports",
    icon: <Icon name="files" />,
    path: "/reports",
    element: <ReportsRoute />
  },
  report: {
    key: "route-report",
    title: "Reports",
    icon: <Icon name="files" />,
    path: "/reports/:id",
    element: <ReportsRoute />
  }
};
