import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

export type Batch = RouterOutput["batches"]["find"];
export type BatchCreate = RouterInput["batches"]["create"];
export type BatchUpdate = RouterInput["batches"]["update"];

export type BatchEmployee = RouterOutput["batches"]["employees"]["list"][0];

export type BatchAction = RouterOutput["batches"]["actions"][0];

export const useBatches = () => {
  const { data: batches } = api.batches.list.useQuery({});

  return { batches };
};

export const useBatch = (id: string) => {
  const { data: batch, refetch: refetchBatch } = api.batches.find.useQuery({ id });
  const { data: employees, refetch: refetchEmployees } = api.batches.employees.list.useQuery({
    batchId: id
  });
  const { data: actions, refetch: refetchActions } = api.batches.actions.useQuery({ id });

  const refetch = useCallback(async () => {
    await Promise.all([refetchBatch(), refetchEmployees(), refetchActions()]);
  }, [refetchBatch, refetchEmployees, refetchActions]);

  return { batch, employees, actions, refetch };
};

export const useBatchActions = () => {
  const toast = useToast();

  const apiUtils = api.useContext();

  const listOp = apiUtils.batches.list;
  const findOp = apiUtils.batches.find;

  const listEmployeesOp = apiUtils.batches.employees.list;
  const listActionsOp = apiUtils.batches.actions;

  const createOp = api.batches.create.useMutation();
  const updateOp = api.batches.update.useMutation();
  const deleteOp = api.batches.delete.useMutation();
  const startOp = api.batches.start.useMutation();
  const retryOp = api.batches.retry.useMutation();
  const refreshOp = api.batches.refresh.useMutation();
  const cancelOp = api.batches.cancel.useMutation();

  const addEmployeesOp = api.batches.employees.add.useMutation();
  const deleteEmployeesOp = api.batches.employees.delete.useMutation();
  const cancelEmployeesOp = api.batches.employees.cancel.useMutation();

  const create = useCallback(
    async (req: BatchCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch();
          return created;
        })
        .catch(e => {
          toast.error("Failed to create Batch");
          throw e;
        });
    },
    [createOp, listOp, toast]
  );

  const update = useCallback(
    async (req: BatchUpdate) => {
      return updateOp
        .mutateAsync(req)
        .then(async updated => {
          await listOp.refetch();
          await findOp.refetch({ id: req.id });
          return updated;
        })
        .catch(e => {
          toast.error("Failed to create Batch");
          throw e;
        });
    },
    [updateOp, listOp, findOp, toast]
  );

  const remove = useCallback(
    async (id: string) => {
      await deleteOp
        .mutateAsync({ id })
        .then(async () => {
          await listOp.refetch();
          toast.success("Batch deleted");
        })
        .catch(() => {
          toast.error("Failed to delete Batch");
        });
    },
    [deleteOp, toast, listOp]
  );

  const start = useCallback(
    async (id: string) => {
      await startOp
        .mutateAsync({ id })
        .then(async () => {
          await findOp.refetch({ id });
          await listActionsOp.refetch({ id });
          toast.success("Batch started");
        })
        .catch(() => {
          toast.error("Failed to start batch");
        });
    },
    [startOp, findOp, listActionsOp, toast]
  );

  const retry = useCallback(
    async (id: string) => {
      await retryOp
        .mutateAsync({ id })
        .then(async () => {
          await findOp.refetch({ id });
          await listActionsOp.refetch({ id });
          toast.success("Batch retry initiated");
        })
        .catch(() => {
          toast.error("Failed to start retry");
        });
    },
    [retryOp, findOp, listActionsOp, toast]
  );

  const refresh = useCallback(
    async (id: string) => {
      await refreshOp
        .mutateAsync({ id })
        .then(async () => {
          await findOp.refetch({ id });
          toast.success("Batch refresh started");
        })
        .catch(() => {
          toast.error("Failed to start batch refresh");
        });
    },
    [refreshOp, findOp, toast]
  );

  const cancel = useCallback(
    async (id: string) => {
      await cancelOp
        .mutateAsync({ id })
        .then(async () => {
          await findOp.refetch({ id });
          await listActionsOp.refetch({ id });
          toast.success("Batch cancel started");
        })
        .catch(() => {
          toast.error("Failed to initiate batch cancel");
        });
    },
    [cancelOp, findOp, listActionsOp, toast]
  );

  const addEmployees = useCallback(
    async (batchId: string, ids?: string[], groupId?: string) => {
      await addEmployeesOp
        .mutateAsync({ batchId, employeeIds: ids, groupId: groupId })
        .then(async () => {
          await listEmployeesOp.refetch({ batchId });
          toast.success(`Employees added`);
        })
        .catch(() => {
          toast.error("Failed to add employees");
        });
    },
    [addEmployeesOp, listEmployeesOp, toast]
  );

  const removeEmployees = useCallback(
    async (batchId: string, ids: string[]) => {
      await deleteEmployeesOp
        .mutateAsync({ batchId, ids })
        .then(async () => {
          await listEmployeesOp.refetch({ batchId });
          toast.success(`Employee${ids.length > 1 ? "s" : ""} removed`);
        })
        .catch(() => {
          toast.error(`Failed to remove employee${ids.length > 1 ? "s" : ""}`);
        });
    },
    [deleteEmployeesOp, listEmployeesOp, toast]
  );

  const cancelEmployees = useCallback(
    async (batchId: string, ids: string[]) => {
      await cancelEmployeesOp
        .mutateAsync({ batchId, ids })
        .then(async () => {
          await findOp.refetch({ id: batchId });
          await listActionsOp.refetch({ id: batchId });
          toast.success(`Initiated canceling employee${ids.length > 1 ? "s" : ""}`);
        })
        .catch(() => {
          toast.error(`Failed to cancel employee${ids.length > 1 ? "s" : ""}`);
        });
    },
    [cancelEmployeesOp, findOp, listActionsOp, toast]
  );

  return {
    create,
    update,
    remove,
    start,
    retry,
    refresh,
    cancel,
    addEmployees,
    removeEmployees,
    cancelEmployees
  };
};
