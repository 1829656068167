import { api } from "../../../../services/rpc/RpcProvider";
import { useCallback } from "react";

export const useEventData = (eventId: string) => {
  const { data: eventInstances, refetch: refetchEventInstances } =
    api.companyEvents.instances.list.useQuery(
      { companyEventId: eventId || "", includeCancelled: true },
      { enabled: !!eventId }
    );

  const { data: eventAttendees, refetch: refetchEventAttendees } =
    api.companyEvents.attendees.list.useQuery(
      { companyEventId: eventId || "" },
      { enabled: !!eventId }
    );
  const { data: unmanagedAttendees, refetch: refetchUnmanagedAttendees } =
    api.companyEvents.attendees.listUnmanaged.useQuery(
      { companyEventId: eventId || "" },
      { enabled: !!eventId }
    );

  const reload = useCallback(async () => {
    // We do not reload unmanaged attendees to limit the number of requests to the calendar provider
    await Promise.all([refetchEventInstances(), refetchEventAttendees()]);
  }, [refetchEventAttendees, refetchEventInstances]);

  const reloadUnmanaged = useCallback(async () => {
    await refetchUnmanagedAttendees();
  }, [refetchUnmanagedAttendees]);

  return { eventInstances, eventAttendees, unmanagedAttendees, reload, reloadUnmanaged };
};
