import { ComponentProps } from "react";
import { Icon, Layout, Title, Tooltip } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { Check } from "components/atoms";

type Props = Pick<ComponentProps<"ul">, "style"> & {
  onItemClick: (id: string) => void;
  selectedItems: string[];
  items: {
    id: string;
    title: string;
    tooltip?: string;
  }[];
  size?: "medium" | "large";
};

const StyledChecklist = styled.ul<{ $size: Props["size"] }>`
  box-sizing: border-box;
  border-radius: var(--rounding-medium);
  border: 1px solid var(--palette-border-subdued);
  margin: 0;
  padding: 0;

  > div {
    overflow: hidden;
    user-select: none;
    min-height: 2.5rem;
    padding: ${p =>
      p.$size === "medium"
        ? "0 var(--spacing-large)"
        : "var(--spacing-small) var(--spacing-large)"};
    cursor: pointer;

    :hover {
      background-color: var(--palette-surface-subdued);
    }
  }

  > :not(:last-child) {
    border-bottom: 1px solid var(--palette-border-subdued);
  }
`;

export const CheckList = ({
  items,
  selectedItems,
  onItemClick,
  size = "medium",
  ...rest
}: Props) => {
  return (
    <StyledChecklist {...rest} $size={size}>
      {items.map((item, index) => (
        <Layout.Group
          justifyContent="space-between"
          key={`check-list-item-${item.id}-${index}`}
          onClick={() => onItemClick(item.id)}
        >
          <Layout.Group gap="xSmall">
            <Title variant="bold">{item.title}</Title>
            {item.tooltip && (
              <Tooltip tooltip={item.tooltip} style={{ maxWidth: "45ch" }}>
                <Icon dimmed name="infoFilled" />
              </Tooltip>
            )}
          </Layout.Group>
          <Check animate checked={selectedItems.includes(item.id)} />
        </Layout.Group>
      ))}
    </StyledChecklist>
  );
};
