import styled from "styled-components";

export const List = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: white;
  border-radius: var(--rounding-medium);
  overflow: hidden;
  border: 1px solid var(--palette-border-subdued);
  box-sizing: border-box;

  > :last-child {
    border-bottom: none;
    > .list-row {
      border-bottom: none;
    }
  }
`;
