import {
  Button,
  Card,
  Icon,
  SkeletonLoader,
  Stack,
  Title,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { InfoTooltip, ListView, useSearch } from "../../../../foundation";
import { List, ListRow, NoResults } from "../../../../components/organisms";
import { api } from "../../../../services/rpc/RpcProvider";
import { useCallback } from "react";

export const RoomsRoute = () => {
  const toast = useToast();
  const { onConfirmAction } = useActions();

  const { data: provider } = api.workspaces.rooms.provider.find.useQuery({});
  const { data: rooms } = api.workspaces.rooms.list.useQuery({}, { enabled: !!provider });

  const connectProviderOp = api.workspaces.rooms.provider.connect.useMutation();
  const refreshRoomsOp = api.workspaces.rooms.refresh.useMutation();
  const revokeOp = api.workspaces.rooms.revoke.useMutation();

  const { search, setSearch, filtered } = useSearch(rooms, { fields: ["title"] });
  const noResults = !!search && filtered?.length === 0;

  const startConnect = useCallback(
    async (provider: "google" | "outlook") => {
      await connectProviderOp
        .mutateAsync({ provider })
        .then(res => {
          window.location.href = res.url;
        })
        .catch(() => {
          toast.error("Failed to connect");
        });
    },
    [connectProviderOp, toast]
  );

  const revokeAccess = onConfirmAction(
    async () => {
      await revokeOp
        .mutateAsync({})
        .then(() => {
          toast.success("Access revoked");
          window.location.reload();
        })
        .catch(() => {
          toast.error("Failed to revoke access");
        });
    },
    {
      title: "Revoke access",
      description:
        "Are you sure you want to revoke access to the account for loading meeting rooms?"
    }
  );

  return (
    <Stack vertical gap="xLarge">
      <Card spacing="xLarge">
        <Stack vertical gap="xxLarge">
          <Stack vertical gap="large">
            <ListView.Header
              title="Meeting rooms"
              iconName="office"
              actions={
                provider
                  ? [
                      {
                        key: "refresh",
                        title: "Refresh rooms",
                        startAdornmentIcon: "reconnect",
                        onClick: async () => {
                          await refreshRoomsOp
                            .mutateAsync({})
                            .then(() => {
                              toast.success("Meeting rooms updated");
                            })
                            .catch(() => {
                              toast.error("Failed to update meeting rooms");
                            });
                        }
                      },
                      {
                        key: "reconnect",
                        title: "Reconnect account",
                        startAdornmentIcon: "link",
                        onClick: () => startConnect(provider.provider)
                      },
                      {
                        key: "revoke",
                        title: "Revoke access",
                        startAdornmentIcon: "crossCircle",
                        onClick: revokeAccess
                      }
                    ]
                  : undefined
              }
              search={
                provider
                  ? {
                      search,
                      setSearch
                    }
                  : undefined
              }
            />
            {rooms && provider && (
              <List>
                {noResults && <NoResults />}
                {(filtered ?? []).map(room => (
                  <ListRow key={room.id} gridTemplateColumns="40px 1fr">
                    <Icon name="office" />
                    <Stack>
                      <Title variant="bold">{room.title}</Title>
                      <InfoTooltip tooltip={room.email} />
                    </Stack>
                  </ListRow>
                ))}
              </List>
            )}
            {rooms === undefined && provider && (
              <div>
                <Stack vertical gap="small">
                  <SkeletonLoader height={48} />
                  <SkeletonLoader height={48} />
                  <SkeletonLoader height={48} />
                  <SkeletonLoader height={48} />
                  <SkeletonLoader fadeOut height={48} />
                </Stack>
              </div>
            )}
            {provider === null && (
              <Stack vertical alignItems="center" justifyContent="center">
                <Title>Connect account to book meeting rooms in Workflows</Title>
                <Button
                  variant="outlined"
                  startAdornment={<Icon name="google" />}
                  style={{ width: "250px" }}
                  onClick={() => startConnect("google")}
                >
                  Connect Google Workspace
                </Button>
                <Button
                  variant="outlined"
                  startAdornment={<Icon name="outlook" />}
                  style={{ width: "250px" }}
                  onClick={() => startConnect("outlook")}
                >
                  Connect Outlook
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
