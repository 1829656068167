import {
  Layout,
  CircularLoader,
  Title,
  Select,
  useToast,
  Button
} from "@introist/react-foundation/v2";
import { api } from "../../../../services/rpc/RpcProvider";
import styled from "styled-components";
import { useAttributes } from "../../../../hooks/use-attributes";
import { useEffect, useState } from "react";

const FieldGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: var(--spacing-medium);
`;

export const HrisFieldMapper = () => {
  const toast = useToast();

  const { data: fields } = api.hris.fields.useQuery({});
  const { data: mappings } = api.hris.mappings.find.useQuery({});

  const updateMapping = api.hris.mappings.update.useMutation();

  const [editMapping, setEditMapping] = useState<any | undefined>();

  useEffect(() => {
    setEditMapping(mappings);
  }, [mappings]);

  const attributes = useAttributes(true);

  const attributeOptions = attributes.map(attr => ({
    key: attr.variable,
    title: attr.name
  }));

  return (
    <Layout.Group vertical style={{ width: "700px" }}>
      {!fields && <CircularLoader fillParent />}
      {fields && (
        <FieldGrid>
          <Title variant="bold">HRIS field</Title>
          <Title>Introist attribute</Title>
          {fields.map(field => (
            <>
              <Layout.Group key={field.jsonPath}>
                <Title variant="bold" key={field.name + "name"}>
                  {field.name}
                </Title>
                <Title small>{field.jsonPath}</Title>
              </Layout.Group>
              <Select
                searchable
                variant="blended"
                size="small"
                placeholder="-"
                options={attributeOptions}
                value={
                  editMapping?.find((m: any) => m.integrationKey === field.jsonPath)?.introistKey
                }
                onSelect={opt => {
                  if (editMapping.some((m: any) => m.integrationKey === field.jsonPath)) {
                    setEditMapping(
                      editMapping.map((m: any) =>
                        m.integrationKey === field.jsonPath ? { ...m, introistKey: opt.key } : m
                      )
                    );
                  } else {
                    setEditMapping([
                      ...editMapping,
                      { integrationKey: field.jsonPath, introistKey: opt.key }
                    ]);
                  }
                }}
              />
            </>
          ))}
        </FieldGrid>
      )}
      <div>
        <Button
          style={{ marginLeft: "auto" }}
          onClickWithLoading={() => {
            return updateMapping
              .mutateAsync(editMapping)
              .then(() => toast.success("Mapping updated"))
              .catch(() => toast.error("Failed to update mapping"));
          }}
        >
          Save changes
        </Button>
      </div>
    </Layout.Group>
  );
};
