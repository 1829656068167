import { Button, Stack, Title, useToast } from "@introist/react-foundation/v2";
import { ToggleSettingCard } from "components/organisms";
import { useCallback, useEffect, useState } from "react";
import { RouterInput, Trigger, api } from "services/rpc/RpcProvider";
import { RelativeDateInlineEditor } from "../../../../components/molecules";

type Props = {
  trigger: Trigger;
};

type TriggerUpdate = RouterInput["triggers"]["update"]["updates"];

const useUpdateTriggerSettings = () => {
  const toast = useToast();
  const apiUtils = api.useContext();
  const updateTriggerApi = api.triggers.update.useMutation({});
  const updateSettings = async ({
    triggerId,
    updates,
    onError,
    onSuccess
  }: {
    triggerId: string;
    updates: TriggerUpdate;
    onError?: VoidFunction;
    onSuccess?: (trigger: Trigger) => void;
  }) => {
    await updateTriggerApi.mutateAsync(
      { id: triggerId, updates },
      {
        onError: () => {
          toast.error("Failed to update settings");
          onError?.();
        },
        onSuccess: t => {
          apiUtils.triggers.find.invalidate({ id: triggerId });
          toast.success("Settings updated");
          onSuccess?.(t);
        }
      }
    );
  };

  return {
    updateSettings
  };
};

const TriggerCooldownConfig = ({ trigger }: Props) => {
  const toast = useToast();
  const { updateSettings } = useUpdateTriggerSettings();

  const apiUtils = api.useContext();

  const [changedValue, setChangedValue] = useState<string | undefined>();

  useEffect(() => {
    setChangedValue(undefined);
  }, [trigger]);

  const saveChanged = useCallback(() => {
    return updateSettings({
      triggerId: trigger.id,
      updates: { cooldownPeriod: changedValue }
    })
      .then(async () => {
        await apiUtils.triggers.find.refetch({ id: trigger.id });
        toast.success("Setting updated");
      })
      .catch(() => toast.error("Failed to update setting"));
  }, [apiUtils, trigger.id, updateSettings, toast, changedValue]);

  return (
    <ToggleSettingCard
      description="Select if the trigger can start again for same employee."
      title="Start again"
      active={!!trigger.cooldownPeriod}
      onToggleActive={active => {
        if (active) {
          updateSettings({
            triggerId: trigger.id,
            updates: { cooldownPeriod: "-1m" }
          })
            .then(() => toast.success("Setting activated"))
            .catch(() => toast.error("Failed to activate setting"));
        } else {
          updateSettings({
            triggerId: trigger.id,
            updates: { cooldownPeriod: null }
          })
            .then(() => toast.success("Setting deactivated"))
            .catch(() => toast.error("Failed to deactivate setting"));
        }
      }}
    >
      {trigger.cooldownPeriod && (
        <Stack vertical>
          <Stack justifyContent="space-between">
            <Title>At most every</Title>
            <RelativeDateInlineEditor
              hidden={{ side: true }}
              onChange={val => {
                setChangedValue(val!);
              }}
              variant="outlined"
              value={changedValue ?? trigger.cooldownPeriod}
            />
          </Stack>
          {changedValue && <Button onClickWithLoading={saveChanged}>Save</Button>}
        </Stack>
      )}
    </ToggleSettingCard>
  );
};

export const TriggerConfig = ({ trigger, ...rest }: Props) => {
  const [completeByRule, setCompleteByRule] = useState(trigger.completeByRule);
  const [startWorkflowAsDraft, setStartWorkflowAsDraft] = useState(trigger.startAsDraft);
  const { updateSettings } = useUpdateTriggerSettings();

  return (
    <Stack {...rest} vertical>
      <Stack>
        <Title variant="bold">Settings</Title>
      </Stack>
      <ToggleSettingCard
        description="Start triggered workflow as draft."
        title="Start as draft"
        active={startWorkflowAsDraft}
        onToggleActive={active => {
          setStartWorkflowAsDraft(active);
          updateSettings({
            triggerId: trigger.id,
            updates: { startAsDraft: active },
            onError: () => setStartWorkflowAsDraft(trigger.startAsDraft)
          });
        }}
      />
      <ToggleSettingCard
        description="Complete workflow if rule does not match anymore."
        title="Complete by rule"
        active={completeByRule}
        onToggleActive={active => {
          setCompleteByRule(active);
          updateSettings({
            triggerId: trigger.id,
            updates: { completeByRule: active },
            onError: () => setCompleteByRule(trigger.completeByRule)
          });
        }}
      />

      <TriggerCooldownConfig trigger={trigger} />
    </Stack>
  );
};
