import { DateInput, TimePicker } from "@introist/react-foundation/v2";
import { DateFormats, IntroistDateTime } from "utils/dates";
import styles from "./DateTimePicker.module.scss";

interface Props {
  dateTime: IntroistDateTime;
  onChange: (dateTime: IntroistDateTime) => unknown;
}

export const DateTimePicker = ({ dateTime, onChange }: Props) => {
  return (
    <div className={styles.DateTimePicker}>
      <DateInput
        displayFormat={DateFormats.date.long}
        value={dateTime.date}
        onChange={value => {
          onChange({
            ...dateTime,
            date: value!
          });
        }}
        style={{ width: "120px" }}
        clearable={false}
      />

      <TimePicker
        time={dateTime.time}
        onSelect={time => {
          onChange({ ...dateTime, time });
        }}
        scrollToSelected
      />
    </div>
  );
};
