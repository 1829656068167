import {
  Card,
  useColorVariant,
  Toggle,
  Title,
  Stack,
  useToast
} from "@introist/react-foundation/v2";
import { useState } from "react";
import { Trigger, api } from "services/rpc/RpcProvider";
import styled from "styled-components";

type Props = {
  trigger: Trigger;
};

const StyledTriggerStatusCard = styled(Card)<{
  $backgroundColor?: string;
  $foregroundColor?: string;
}>`
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "var(--palette-surface-dimmed)"};
  border-color: ${({ $foregroundColor }) => $foregroundColor};
  box-shadow: ${p => (p.$backgroundColor ? `0 0 0 3px ${p.$backgroundColor}` : null)};
  transition: box-shadow 180ms;
`;

export const TriggerStatusCard = ({ trigger, ...rest }: Props) => {
  const toast = useToast();
  const [active, setActive] = useState(trigger.active);
  const color = useColorVariant("success");

  const updateTriggerApi = api.triggers.update.useMutation();

  const toggleActive = async (active: boolean) => {
    setActive(active);
    await updateTriggerApi.mutateAsync(
      {
        id: trigger.id,
        updates: {
          active
        }
      },
      {
        onError: () => {
          setActive(trigger.active);
          toast.error(`Failed to ${active ? "activate" : "deactivate"} trigger status`);
        },
        onSuccess: () => toast.success(`Trigger ${active ? "activated" : "deactivated"}`)
      }
    );
  };

  return (
    <StyledTriggerStatusCard
      {...rest}
      $backgroundColor={active ? color.background : undefined}
      $foregroundColor={active ? color.foreground : undefined}
    >
      <Stack vertical gap="xSmall">
        <Stack justifyContent="space-between">
          <Title variant="bold">Active</Title>
          <Toggle small checked={active} onChange={toggleActive} />
        </Stack>
        <Title>Trigger is {active ? "active" : "not active"}</Title>
      </Stack>
    </StyledTriggerStatusCard>
  );
};
