import { CompanyEventSettingKey } from "services/rpc/RpcProvider";

export const getEventSettingsMetadata = (
  eventSetting: CompanyEventSettingKey
): {
  title: string;
  description: string;
} => {
  switch (eventSetting) {
    case "MaxAttendees":
      return {
        title: "Maximum attendees",
        description: "Limit the number of attendees that can be invited to a session"
      };
    case "RescheduleAttendeeOnDecline":
      return {
        title: "Reschedule on decline",
        description: "Automatically move attendee to next session if they decline"
      };
    case "RescheduleAttendeeOnWaiting":
      return {
        title: "Reschedule no-replys",
        description:
          "If attendee does not answer the invitation, automatically move them to the next session"
      };
    case "Presenters":
      return {
        title: "Invite presenters",
        description: "Automatically invite presenters to sessions with attendees"
      };
    case "CancelOnPresenterDecline":
      return {
        title: "Cancel if presenters decline",
        description:
          "Cancel session if presenter(s) declines invitation and move all attendees to the next session"
      };

    case "RescheduleOnWeekendOrHoliday":
      return {
        title: "Reschedule on weekend or holiday",
        description:
          "Automatically reschedule sessions that would be on weekend or on public holiday"
      };
    case "BookMeetingRoom":
      return {
        title: "Book a meeting room",
        description: "Book a room that has enough space for all attendees"
      };

    case "RemindAttendeeOnWaiting":
      return {
        title: "Remind no-replys",
        description:
          "Send automatic reminders to attendees who haven't replied to their invitations"
      };
    case "VerifyAttendance":
      return {
        title: "Reschedule no-shows",
        description:
          "If an attendee does not participate in the session, automatically move them to the next one"
      };

    default:
      return {
        title: "Unknown",
        description: "Unknown"
      };
  }
};
