import styled from "styled-components";
import { Field } from "@introist/react-foundation/v2";

import { CopyButton } from "../../atoms/CopyButton";
import { TextInput } from "../../atoms/TextInput";

type CopyableReadOnlyInputProps = {
  value: string;
  label?: string;
  disabled?: boolean;
  onCopyMessage?: string;
};

const StyledCopyableReadOnlyInput = styled.div`
  background: var(--palette-surface-subdued);
  border-radius: var(--rounding-medium);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0.5rem;
  gap: 0.5rem;
  align-items: center;

  && {
    > div > div > div {
      padding: 0 !important;

      input {
        padding: 0;
      }
    }
  }
`;

export const CopyableReadOnlyInput = ({
  value,
  label,
  disabled,
  onCopyMessage,
  ...rest
}: CopyableReadOnlyInputProps) => {
  return (
    <Field title={label}>
      <StyledCopyableReadOnlyInput {...rest}>
        <TextInput readOnly value={value} />
        <CopyButton
          disabled={disabled}
          variant="outlined"
          textToCopy={value}
          successMessage={onCopyMessage}
        >
          Copy
        </CopyButton>
      </StyledCopyableReadOnlyInput>
    </Field>
  );
};
