import { api } from "../../../services/rpc/RpcProvider";

export const useEmployeeFields = (fieldTypes?: string[]) => {
  const { data: fields } = api.employees.fields.list.useQuery({ prefixGrouped: true });

  if (fieldTypes) {
    return fields?.filter(field => fieldTypes.includes(field.type)) || [];
  }
  return fields || [];
};
