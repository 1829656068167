import { getJson, postJson } from "../ApiBase";
import { getTimeZone } from "../../../utils/DatesUtils";

const getBasePath = async (workflowId: string) => {
  return "/v3/workflows";
};

export interface TestStepRequest {
  workflowId: string;
  stepId: string;
}

export const testStep = async ({ workflowId, stepId }: TestStepRequest) => {
  const currentTimezone = getTimeZone();
  const basePath = await getBasePath(workflowId);

  if (basePath.startsWith("/v3")) {
    return postJson(
      `${basePath}/${workflowId}/steps/${stepId}/test?timezone=${currentTimezone}`,
      {}
    );
  } else {
    return getJson(`${basePath}/${workflowId}/steps/${stepId}/test?timezone=${currentTimezone}`);
  }
};
