import { ProductTag } from "@introist/react-foundation/v2";
import styled from "styled-components";

export const StyledConditionCardSeparator = styled.span`
  position: relative;
  display: block;
  margin-left: 2rem;
  height: 40px;
  width: 1px;
  background-color: var(--palette-foreground-dimmed);

  > span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledProductTag = styled(ProductTag)`
  background-color: var(--palette-foreground-default);
`;
export const ConditionCardSeparator = ({ mode }: { mode: "and" | "or" }) => {
  return (
    <StyledConditionCardSeparator>
      {mode === "and" ? <StyledProductTag title="and" /> : <StyledProductTag title="or" />}
    </StyledConditionCardSeparator>
  );
};
