import { useEffect } from "react";
import styled, { css } from "styled-components";
import { Backdrop, Icon, Stack, Title } from "@introist/react-foundation/v2";
import { a, config, useSpring, useTransition } from "@react-spring/web";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import chroma from "chroma-js";
import useMeasure from "react-use-measure";

import { HighlightText } from "components/atoms";
import { useCommander } from "./hooks";
import { CommanderCommand } from ".";
import { groupBy } from "lodash";

export type CommanderProps = {
  commands: CommanderCommand[];
  searchPlaceholder?: string;
};

const HEADER_HEIGHT = 56;
const ITEM_HEIGHT = 44;

const StyledBackdrop = styled(Backdrop)`
  z-index: var(--elevation-mars);
  background-color: transparent;
`;

const AnimatedCommander = styled(a.div)`
  position: fixed;
  left: 50%;
  top: 25%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 32rem;
  border-radius: var(--rounding-medium);
  z-index: 2;

  * {
    box-sizing: border-box;
  }
`;

const AnimatedBackground = styled(a.div)`
  position: absolute;
  inset: 0;
  background: white;
  border-radius: var(--rounding-medium);
  overflow: hidden;
  pointer-events: none;
`;

const Header = styled.header`
  position: relative;
  z-index: 1;
`;

const Input = styled.input`
  background: transparent;
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  padding: 0 var(--spacing-large);
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  border: none;
  font-size: 1.25rem;

  ::placeholder {
    color: var(--palette-foreground-subdued);
  }

  :focus {
    outline: none;
  }
`;

const CommandList = styled.ul<{ $maxHeight: number }>`
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: ${({ $maxHeight }) => $maxHeight}px;
  pointer-events: all;
  overflow-y: scroll;
  z-index: 1;
`;

const Command = styled.li<{ $highlight?: boolean }>`
  display: grid;
  align-items: center;
  height: ${ITEM_HEIGHT}px;
  padding: 0 var(--spacing-large);
  position: relative;
  cursor: pointer;

  ${({ $highlight }) =>
    $highlight &&
    css`
      color: var(--palette-primary-default);
      background-color: var(--palette-primary-ghosted);

      ::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 3px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        height: 1rem;
        background-color: var(--palette-primary-default);
      }
    `}
`;

const Category = styled.div`
  padding-top: 1rem;
  > .category-title {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    > h5 {
      color: var(--palette-foreground-subdued);
    }
  }
  border-bottom: 1px solid var(--palette-border-subdued);
`;

const AnimatedLine = styled(a.span)`
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--palette-border-subdued);
  height: 1px;
`;

const SHADOW = {
  open: `${chroma("#0C0A14").alpha(0.22).hex()} 0px 1px 8px -1px, ${chroma("#0C0A14").alpha(
    0.22
  )} 0px 32px 64px -9px`,
  closed: `${chroma("#0C0A14").alpha(0).hex()} 0px 0px 0px -1px, ${chroma("#3e6de5").alpha(
    0
  )} 0px 1px 1px 4px`
};

export const Commander = ({ commands, searchPlaceholder, ...rest }: CommanderProps) => {
  const {
    commanderRef,
    open,
    inputProps,
    debouncedSearchValue,
    filteredCommands,
    getCommandProps,
    listProps
  } = useCommander(commands);

  const groupedCommands = groupBy(filteredCommands, "category");

  const hasAvailableCommands = Object.values(groupedCommands).some(commands => commands.length > 0);

  const transitions = useTransition(open, {
    from: {
      transform: `translate(-50%, 1rem) scale(0.9)`,
      opacity: 0,
      boxShadow: SHADOW.closed
    },
    enter: {
      transform: `translate(-50%, 0rem) scale(1)`,
      opacity: 1,
      boxShadow: SHADOW.open
    },
    leave: {
      transform: `translate(-50%, 1rem) scale(0.9)`,
      opacity: 0,
      boxShadow: SHADOW.closed,
      immediate: true
    },
    config: { ...config.stiff, clamp: true }
  });

  const [listHeightRef, { height: listHeight }] = useMeasure();
  const maxHeight = Object.values(groupedCommands).some(commands => commands.length > 5)
    ? 5.5 * ITEM_HEIGHT
    : listHeight;

  const backgroundSpring = useSpring({
    height: hasAvailableCommands ? maxHeight + HEADER_HEIGHT : HEADER_HEIGHT,
    transformOrigin: "top",
    boxShadow: open ? SHADOW.open : SHADOW.closed,
    config: { clamp: true }
  });

  const lineSpring = useSpring({
    transform: hasAvailableCommands ? "scaleX(1)" : "scaleX(0)",
    config: { ...config.stiff, clamp: true }
  });

  const renderCommands = () => {
    return Object.entries(groupedCommands).reduce<{ elements: JSX.Element[]; count: number }>(
      (acc, [category, commands]) => {
        const categoryElements = commands.map((c, idx) => {
          const commandFlatIndex = acc.count + idx; // Calculate the "flat" index for this command
          return (
            <Command {...getCommandProps(c.id, commandFlatIndex)} key={c.id}>
              <Title variant="bold">
                <HighlightText search={debouncedSearchValue} text={c.name} />
              </Title>
            </Command>
          );
        });
        // Append the category title and its commands to the elements array
        acc.elements.push(
          <Category key={category}>
            <Stack gap="small" className="category-title">
              {commands[0].categoryIcon && <Icon dimmed name={commands[0].categoryIcon} />}
              <Title small variant="bold">
                {category}
              </Title>
            </Stack>
            {categoryElements}
          </Category>
        );

        // Update the count to include the commands processed in this category
        acc.count += commands.length;

        return acc; // Return the updated accumulator
      },
      { elements: [], count: 0 } // Initial accumulator value
    ).elements; // Extract the elements array for rendering
  };

  useEffect(() => {
    if (open) {
      commanderRef.current &&
        disableBodyScroll(commanderRef.current, { reserveScrollBarGap: true });
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open, commanderRef]);

  return transitions((style, tOpen) =>
    tOpen ? (
      <StyledBackdrop open>
        <AnimatedCommander {...rest} style={style} ref={commanderRef}>
          <Header>
            <Input {...inputProps} />
            <AnimatedLine style={lineSpring} />
          </Header>
          <AnimatedBackground style={backgroundSpring}>
            <CommandList
              {...listProps}
              $maxHeight={maxHeight}
              style={{
                top: HEADER_HEIGHT
              }}
            >
              <div ref={listHeightRef}>{renderCommands()}</div>
            </CommandList>
          </AnimatedBackground>
        </AnimatedCommander>
      </StyledBackdrop>
    ) : null
  );
};
