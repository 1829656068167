import { Button, Modal, ModalProps, Stack, useToast } from "@introist/react-foundation/v2";
import { ComponentType, useCallback, useState } from "react";
import { capitalize } from "lodash";

export interface UpsertModalProps<T> extends ModalProps {
  open: boolean;
  entry?: T;
  template?: Partial<T>;
  onCreate: (entry: Partial<T>) => Promise<unknown>;
  onUpdate: (entry: T) => Promise<unknown>;
  onClose: () => void;
  Form: ComponentType<UpsertFormProps<T>>;
  entryName: string;
}

export type UpsertFormProps<T> = {
  value: T;
  onChange: (value: T) => void;
  isEdit: boolean;
};

export const UpsertModal = <T,>(props: UpsertModalProps<T>) => {
  const { open, onClose, entry, entryName, ...rest } = props;

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      title={entry ? `Edit ${entryName ?? ""}` : `Create ${entryName ?? ""}`}
      maxContentHeight="70vh"
      {...rest}
    >
      {open && <UpsertModalContent {...props} />}
    </Modal>
  );
};

export const UpsertModalContent = <T,>({
  onClose,
  entry,
  template,
  entryName,
  Form,
  onCreate,
  onUpdate
}: UpsertModalProps<T>) => {
  const toast = useToast();
  const [value, setValue] = useState<T>(entry ?? ((template ?? {}) as T));

  const submit = useCallback(async () => {
    const submit = entry ? onUpdate(value) : onCreate(value);
    return submit
      .then(() => {
        toast.success(`${capitalize(entryName)} ${entry ? "updated" : "created"}`);
        onClose();
      })
      .catch(() =>
        toast.error(`Failed to ${entry ? "update" : "create"} ${capitalize(entryName)}`)
      );
  }, [entry, onCreate, onClose, onUpdate, toast, value, entryName]);

  return (
    <Stack vertical gap="xLarge">
      {/*
        // @ts-ignore */}
      <Form value={value} onChange={setValue} isEdit={!!entry} />
      <Stack
        justifyContent="flex-end"
        gap="small"
        style={{
          position: "sticky",
          bottom: 0,
          background: "var(--palette-background-default)",
          paddingTop: "var(--spacing-large)"
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClickWithLoading={submit}>{entry ? "Save" : "Create"}</Button>
      </Stack>
    </Stack>
  );
};
