import { useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Card, Layout, Option, Title, Modal, Tag, ActionMenu } from "@introist/react-foundation/v2";
import { HrisDetails } from "services/rpc/RpcProvider";
import { HrisFieldMapper } from "../../containers/HrisFieldMapper";

type ConnectedHrisCardProps = {
  hris: HrisDetails;
  showConnected?: boolean;
  actions?: Option[];
  enableMapping?: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledConnectedHrisCard = styled(Card)`
  border-color: var(--palette-success-default);
  position: relative;
  z-index: 2;
`;

const cardRippleKeyframes = keyframes`
  0% {
    opacity: 1;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
  }
  40% {
    opacity: 0;
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  100% {
    opacity: 0;
  }
`;

const Ripple = styled.div`
  position: absolute;
  top: 0rem;
  right: 0rem;
  bottom: 0rem;
  left: 0rem;
  border-radius: inherit;
  background-color: var(--palette-success-dimmed);
  transform-origin: center;
  animation: ${cardRippleKeyframes} 2000ms infinite linear;
  animation-delay: 1000ms;
  z-index: 1;
  border-radius: 8px;
  opacity: 0;
`;

export const ConnectedHrisCard = ({
  hris,
  showConnected,
  actions,
  enableMapping
}: ConnectedHrisCardProps) => {
  const [mappingOpen, setMappingOpen] = useState(false);

  const finalActions = useMemo(() => {
    let allActions = actions || [];

    if (enableMapping) {
      allActions = [
        {
          key: "mapping",
          title: "Edit mapping",
          onClick: () => setMappingOpen(true)
        },
        ...allActions
      ];
    }

    return allActions;
  }, [enableMapping, actions]);

  return (
    <Wrapper>
      <StyledConnectedHrisCard>
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            {hris.icon && <img width="20px" height="20px" src={hris.icon} alt={hris.name} />}
            <Title variant="bold">{hris.name}</Title>
          </Layout.Group>
          <Layout.Group>
            {showConnected && <Tag colorVariant="success">Connected</Tag>}
            {finalActions.length > 0 && <ActionMenu options={finalActions} />}
          </Layout.Group>
        </Layout.Group>
        <Modal title="HRIS mapping" open={mappingOpen} onClose={() => setMappingOpen(false)}>
          {mappingOpen && <HrisFieldMapper />}
        </Modal>
      </StyledConnectedHrisCard>
      <Ripple />
    </Wrapper>
  );
};
