import { Icon } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

type Props = {
  size?: "medium" | "large";
  disabled?: boolean;
  ghosted?: boolean;
};

export const AttributeIconBackground = styled.div<{
  $size: "medium" | "large";
  $ghosted?: boolean;
  $disabled?: boolean;
}>`
  width: ${({ $size }) => ($size === "medium" ? 20 : 28)}px;
  height: ${({ $size }) => ($size === "medium" ? 20 : 28)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ $size }) =>
    $size === "medium" ? "var(--rounding-small)" : "var(--rounding-medium)"};

  ${({ $ghosted, $disabled }) => {
    if ($disabled) {
      return css`
        background-color: var(--palette-foreground-ghosted);
        > svg {
          fill: var(--palette-foreground-subdued) !important;
        }
      `;
    }

    if ($ghosted) {
      return css`
        background-color: var(--palette-purple-ghosted);
        > svg {
          fill: var(--palette-purple-default) !important;
        }
      `;
    }

    return css`
      background-color: var(--palette-purple-default);
      > svg {
        fill: white !important;
      }
    `;
  }}
`;

export const AttributeIcon = ({ size = "medium", ghosted, disabled, ...rest }: Props) => {
  return (
    <AttributeIconBackground {...rest} $size={size} $ghosted={ghosted} $disabled={disabled}>
      <Icon name="attribute" style={{ width: size === "medium" ? 10 : 16 }} />
    </AttributeIconBackground>
  );
};
