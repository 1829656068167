import styled from "styled-components";

import { stringifyTrigger } from "utils/Text";
import { WorkflowStep } from "../hooks/useWorkflowSteps";
import { useStepType } from "../stepTypes";
import {
  Card,
  Icon,
  IconButton,
  Layout,
  Stack,
  Tag,
  Title,
  Tooltip
} from "@introist/react-foundation/v2";
import { FittedTitle } from "../../../components/atoms";
import { StepTypeTag } from "./StepTypeTag";
import { isAllDay } from "../../workflows/components/PopupTimeRangePicker/v2";

const getStepTimes = (step: WorkflowStep): string => {
  if (step.trigger.side === "immediately") return "";
  if (step.stepType === "event") {
    const stepData = (step as any).stepData;

    if (isAllDay({ start: stepData.fromTime, end: stepData.toTime })) return "all day";
    return `${stepData.fromTime} - ${stepData.toTime}`;
  }
  return step.trigger.time!;
};

const HoverableContext = styled(Stack)`
  &:hover > button {
    display: block !important;
  }
`;

const Dot = styled.span`
  display: block;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 100%;
  background-color: var(--palette-foreground-subdued);
`;

const BlockIcon = styled(Icon)`
  display: block;
`;

export type WorkflowStepCardProps = {
  step: WorkflowStep;
  onClick: (stepId: string) => void;
  targetDateTerm?: string;
  onRemove?: () => unknown;
};

const StyledWorkflowStepCard = styled(Card)<{ active: boolean }>`
  box-shadow: none;
  position: relative;
  flex: 1;
  transition-property: transform, box-shadow;
  transition-duration: 140ms;
  transition-timing-function: ease;
  width: 100%;

  * {
    user-select: none;
  }

  :hover {
    transform: translateY(-1px);
    box-shadow: rgba(14, 24, 29, 0.1) 0px 8px 19px -2px, rgba(14, 24, 29, 0.3) 0px 4px 12px -8px;
  }
  ${({ active }) => {
    return !active && `background-color: var(--palette-surface-subdued)`;
  }}
`;

export const WorkflowStepCard = ({
  step,
  onClick,
  targetDateTerm,
  onRemove
}: WorkflowStepCardProps) => {
  const hasPreconditions =
    (step.preconditionSteps && step.preconditionSteps.length > 0) ||
    (step.preconditionAttributes && step.preconditionAttributes?.length > 0);

  const hasAttachments = step.attachments && step.attachments.length > 0;

  const hasConditions = !!step.condition;

  return (
    <GenericWorkflowStepCard
      onClick={() => onClick(step.id)}
      active={step.active}
      stepType={step.stepType}
      eventType={(step.stepData as any)?.eventType}
      stepTitle={step.stepTitle}
      stepTriggerText={
        targetDateTerm &&
        stringifyTrigger(
          (step.stepData as any).dateTrigger ?? step.trigger,
          true,
          false,
          targetDateTerm
        )
      }
      stepTimes={getStepTimes(step)}
      hasPreconditions={hasPreconditions}
      hasConditions={hasConditions}
      hasAttachments={hasAttachments}
      tag={
        <Tag colorVariant={step.active ? "success" : "outlined"}>
          {step.active ? "Active" : "Draft"}
        </Tag>
      }
      onRemove={onRemove}
    />
  );
};

interface GenericWorkflowStepCardProps {
  onClick?: () => void;
  active: boolean;
  stepType: string;
  stepTriggerText?: string;
  stepTimes?: string;
  eventType?: string;
  stepTitle: string;
  hasPreconditions?: boolean;
  hasConditions?: boolean;
  hasAttachments?: boolean;
  tag?: any;
  onRemove?: () => unknown;
}

export const GenericWorkflowStepCard = ({
  onClick,
  active,
  stepType,
  eventType,
  stepTitle,
  stepTriggerText,
  stepTimes,
  hasPreconditions,
  hasConditions,
  hasAttachments,
  tag,
  onRemove
}: GenericWorkflowStepCardProps) => {
  const recognizedStepType = useStepType(stepType, eventType);
  return (
    <StyledWorkflowStepCard onClick={onClick} active={active}>
      <HoverableContext>
        <Stack
          vertical
          alignItems="stretch"
          style={{
            gap: "0.5rem",
            flex: 1
          }}
        >
          <Layout.Group gap="small" justifyContent="space-between">
            <StepTypeTag stepType={recognizedStepType} />

            <Layout.Group>
              {hasPreconditions && (
                <Tooltip tooltip="This step has preconditions">
                  <BlockIcon name="targetBefore" style={{ rotate: "90deg" }} />
                </Tooltip>
              )}
              {hasConditions && (
                <Tooltip tooltip="Conditional step">
                  <BlockIcon name="condition" />
                </Tooltip>
              )}
              {hasAttachments && (
                <Tooltip tooltip="Includes attachments">
                  <BlockIcon name="paperclip" />
                </Tooltip>
              )}
            </Layout.Group>
          </Layout.Group>
          <Stack vertical gap="none">
            <FittedTitle variant="bold" maxLength={85}>
              {stepTitle}
            </FittedTitle>
          </Stack>
          {stepTriggerText && (
            <Layout.Group justifyContent="space-between">
              <Layout.Group gap="small">
                <FittedTitle showTooltip maxLength={32}>
                  {stepTriggerText}
                </FittedTitle>
                {!!stepTimes && <Dot />}
                <Title>{stepTimes}</Title>
              </Layout.Group>

              {tag}
            </Layout.Group>
          )}
        </Stack>
        {onRemove && (
          <IconButton
            icon="crossSmall"
            style={{ display: "none" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRemove();
            }}
          />
        )}
      </HoverableContext>
    </StyledWorkflowStepCard>
  );
};
