import {
  Icon,
  IconName,
  TTagColorVariant,
  Tag,
  useColorVariant
} from "@introist/react-foundation/v2";
import { EventAttendeeStatus } from "services/api/types";

import { AvatarBackground } from "modules/workflows/components/ParticipantCard";
import { useMemo } from "react";

export interface AttendeeStatusAvatarProps {
  status: EventAttendeeStatus;
}

const getAttendeeStatusToColorVariant = (
  attendeeStatus?: EventAttendeeStatus,
  isImportatGuest?: boolean
): TTagColorVariant => {
  if (attendeeStatus === "accepted") return "success";
  if (attendeeStatus === "declined") return isImportatGuest ? "danger" : "surface";
  if (attendeeStatus === "waiting") return "surface";
  if (attendeeStatus === "tentative") return "warning";

  return "surface";
};

const useEventAttendeeStatusProps = (status: EventAttendeeStatus, isImportantGuest?: boolean) => {
  const colorVariant = getAttendeeStatusToColorVariant(status, isImportantGuest);
  const color = useColorVariant(colorVariant);

  const attendeeStatusProps = useMemo(() => {
    switch (status) {
      case "accepted":
        return {
          color,
          colorVariant,
          text: "Attending",
          icon: "checkCircle" as IconName
        };
      case "declined":
        return {
          color,
          colorVariant,
          text: "Declined",
          icon: "crossCircle" as IconName
        };
      case "tentative":
        return {
          color,
          colorVariant,
          text: "Maybe",
          icon: "questionMark" as IconName
        };
      default:
      case "waiting":
        return {
          color,
          colorVariant,
          text: "Pending",
          icon: "progress" as IconName
        };
    }
  }, [status, color, colorVariant]);

  return {
    attendeeStatusProps
  };
};

export const AttendeeStatusTag = ({ status }: AttendeeStatusAvatarProps) => {
  const { attendeeStatusProps } = useEventAttendeeStatusProps(status);

  return <Tag colorVariant={attendeeStatusProps.colorVariant}>{attendeeStatusProps.text}</Tag>;
};

export const AttendeeStatusAvatar = ({ status }: AttendeeStatusAvatarProps) => {
  const { attendeeStatusProps } = useEventAttendeeStatusProps(status);

  return (
    <AvatarBackground background={attendeeStatusProps.color.background}>
      <Icon name={attendeeStatusProps.icon} color={attendeeStatusProps.color.foreground} />
    </AvatarBackground>
  );
};
