import queryString from "query-string";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { appRoutes } from "../../AppRoutes";
import { config } from "../../Config";
import { getIDToken } from "../firebase";
import { getSelectedWorkspace } from "../workspace";

export class ServiceError extends Error {
  public code: number;
  public key?: string;
  public data?: any;

  constructor(message: string, code: number, key?: string, data?: any) {
    super(message);
    this.code = code;
    this.key = key;
    this.data = data;
  }
}

export const getBaseUrl = ({
  apiUrl,
  overwriteApiUrl
}: {
  apiUrl?: string;
  overwriteApiUrl?: string;
}) => overwriteApiUrl || apiUrl || config.apiUrl;

export const handleResponse = async (res: Response): Promise<any> => {
  if (res.ok) {
    try {
      return await res.json();
    } catch {
      return Promise.resolve();
    }
  }

  if (res.status === 401) {
    window.location.href = appRoutes.login.path;
  }

  const responseText = await res.text();
  let error: ServiceError;
  try {
    const parsedText = JSON.parse(responseText);
    error = new ServiceError(parsedText.message, res.status, parsedText.key, parsedText.data);
  } catch (e) {
    error = new ServiceError(responseText, res.status);
  }
  return Promise.reject(error);
};

export const authHeaders = async () => {
  const token = await getIDToken();
  const headers = {
    "content-type": "application/json",
    authorization: `Bearer ${token}`
  };

  const workspace = getSelectedWorkspace();
  if (workspace) {
    return {
      ...headers,
      "x-introist-workspace": workspace
    };
  }
  return headers;
};

export const sendRaw = async (path: string, method = "get", body?: object) => {
  const headers = await authHeaders();

  return fetch(`${config.apiUrl}${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  });
};

export const sendJson = async <T>(
  path: string,
  body?: object,
  method: string = "post",
  apiUrl?: string,
  overwriteApiUrl?: string
): Promise<T> => {
  const headers = await authHeaders();

  return fetch(`${getBaseUrl({ apiUrl, overwriteApiUrl })}${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined
  }).then(handleResponse);
};

export function getJson<T>(path: string, apiUrl?: string, overwriteApiUrl?: string): Promise<T> {
  return sendJson(path, undefined, "get", apiUrl, overwriteApiUrl);
}

export function patchJson<T>(path: string, body: object, apiUrl?: string): Promise<T> {
  return sendJson(path, body, "PATCH", apiUrl);
}

export function postJson<T>(
  path: string,
  body: object,
  apiUrl?: string,
  overwriteApiUrl?: string
): Promise<T> {
  return sendJson(path, body, "post", apiUrl, overwriteApiUrl);
}

export function putJson<T>(path: string, body: object, apiUrl?: string): Promise<T> {
  return sendJson(path, body, "put", apiUrl);
}

export function deleteJson<T>(path: string): Promise<T> {
  return sendJson(path, undefined, "delete");
}

export const buildQuery = (data: { [index: string]: string | number | undefined }): string => {
  const query = queryString.stringify(data);
  return `?${query}`;
};

export const invalidateQuery =
  <Params>(keySet: string[]) =>
  () => {
    const queryClient = useQueryClient();
    return (params: Params) => queryClient.invalidateQueries([...keySet, params]);
  };

export const generateQuery =
  <T>(keySet: string[], apiFunc: () => Promise<T>, defaultOptions?: UseQueryOptions<T>) =>
  (options?: UseQueryOptions<T>) => {
    const queryClient = useQueryClient();
    return {
      query: useQuery<T>(keySet, apiFunc, { ...defaultOptions, ...options }),
      invalidate: () => queryClient.invalidateQueries(keySet),
      refetch: () => queryClient.refetchQueries(keySet)
    };
  };

export const generateQueryWithParameters =
  <Params, Response>(
    keySet: string[],
    apiFunc: (params: Params) => Promise<Response>,
    defaultOptions?: UseQueryOptions<Response>
  ) =>
  (params: Params, options?: UseQueryOptions<Response>) => {
    const queryClient = useQueryClient();
    const finalKeys = [...keySet, params];
    return {
      query: useQuery<Response>(finalKeys, () => apiFunc(params), {
        ...defaultOptions,
        ...options
      }),
      invalidate: () => queryClient.invalidateQueries(keySet),
      refetch: () => queryClient.refetchQueries(keySet),
      keySet: finalKeys
    };
  };
export const generateMutation =
  <Mutation, Response>(
    apiFunc: (object: Mutation) => Promise<Response>,
    defaultOptions?: UseQueryOptions<Response>
  ) =>
  (options?: UseQueryOptions<Response>) => {
    return useMutation<Response, ServiceError, Mutation>((object: Mutation) => apiFunc(object), {
      ...defaultOptions,
      ...options
    } as any);
  };

export interface DeleteSuccessResponse {
  archived: boolean;
}
