import styled from "styled-components";
import {
  IconButton,
  Layout,
  IntroistLoader,
  H4,
  Accordion,
  Title
} from "@introist/react-foundation/v2";

import { useIntegrationsApi } from "services/api/IntegrationsApi";
import {
  buildConfigurationContainer,
  buildCredentialsContainer
} from "../integration-container-factory";
import { IntegrationFieldsContainer } from "../IntegrationFieldsContainer";

type IntegrationEditorProps = {
  integrationId: string;
  onClose: () => void;
  onBack?: () => void;
};

const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
`;

export const IntegrationEditor = ({
  integrationId,
  onClose,
  onBack,
  ...rest
}: IntegrationEditorProps) => {
  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });

  const { data: integration } = findIntegration.query;

  if (!integration) return <IntroistLoader fillParent />;

  return (
    <Layout.Group {...rest} vertical gap="large">
      <Header>
        <Layout.Group lastChildToEnd>
          {onBack && <IconButton icon="arrowLeft" variant="blended" onClick={onBack} />}
          <H4>{integration.name}</H4>
          <IconButton icon="crossLarge" variant="blended" onClick={onClose} />
        </Layout.Group>
        <Title>{integration.description}</Title>
      </Header>
      <Accordion compact title="Credentials">
        {buildCredentialsContainer(integrationId)}
      </Accordion>
      <Accordion compact title="Configuration">
        {buildConfigurationContainer(integrationId)}
      </Accordion>
      {integration.active && <IntegrationFieldsContainer integrationId={integrationId} />}
    </Layout.Group>
  );
};
