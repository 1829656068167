import { Stack, Title, useToast, Select } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
import { PreviewBuilderProps } from "../datasources";
import { FileUpload } from "../../workflows/components/FileUpload";
import { FormFooter } from "../components/FormFooter";
import { useEffect, useState } from "react";

import csv from "csvtojson";

export const CsvUploadConnectForm = ({ values, onChange }: ConnectFormProps) => {
  return (
    <Stack vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
    </Stack>
  );
};

export const CsvUploadPreviewBuilder = ({ onPreview, onCancel }: PreviewBuilderProps) => {
  const toast = useToast();
  const [delimiter, setDelimiter] = useState(",");

  const [file, setFile] = useState<File | undefined>();
  const [data, setData] = useState<any[] | undefined>();

  useEffect(() => {
    setData(undefined);
    if (file) {
      if (file.type !== "text/csv") {
        toast.error("Invalid file format");
      } else {
        file
          .text()
          .then(text => csv({ delimiter }).fromString(text))
          .then((data: any) => setData(data))
          .catch(() => {
            toast.error("Failed to parse CSV file");
          });
      }
    }
  }, [file, toast, delimiter]);

  return (
    <Stack vertical>
      <Title>Upload CSV file to preview sync</Title>
      <FileUpload
        title={file?.name}
        onFileUploaded={files => {
          setFile(files[0]);
        }}
      />
      <Stack vertical>
        <Title>Delimiter</Title>
        <Select
          options={[{ key: "," }, { key: ";" }]}
          value={delimiter}
          onSelect={o => {
            setDelimiter(o.key);
          }}
        />
      </Stack>
      {data && <Title>Detected {data.length} lines in file</Title>}
      <FormFooter
        onCancel={onCancel}
        onSubmit={() => onPreview(data)}
        submit="Preview"
        submitDisabled={!data}
      />
    </Stack>
  );
};
