import { ReactNode, useState } from "react";
import { Button, Stack } from "@introist/react-foundation/v2";

export type FormEditorProps<T> = {
  value: T;
  update: (update: Partial<T>) => void;
};

type SimpleFormProps<T> = {
  defaultValue: T;
  onSubmit: (updates: Partial<T>) => Promise<void>;
  Editor: (props: FormEditorProps<T>) => JSX.Element;
  footerSecondary?: ReactNode;
};

export const SimpleForm = <T,>({
  defaultValue,
  onSubmit,
  Editor,
  footerSecondary
}: SimpleFormProps<T>) => {
  const [updates, setUpdates] = useState<Partial<T>>({});

  return (
    <Stack vertical gap="xxLarge">
      <Editor
        value={{ ...defaultValue, ...updates }}
        update={update => setUpdates({ ...updates, ...update })}
      />
      <Stack justifyContent="space-between">
        {footerSecondary ?? <div />}
        <Button
          onClickWithLoading={() => onSubmit(updates)}
          disabled={!updates || Object.keys(updates).length === 0}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
