import { useCallback, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CircularLoader,
  H4,
  IconButton,
  InfoCard,
  Layout,
  TextButton,
  Title,
  Tooltip,
  useToast
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { CalendarEvent, CalendarEventList, CompanyEvent, api } from "services/rpc/RpcProvider";
import { HostCalendarIcon, MediumTag } from "components/atoms";
import { appRoutes } from "AppRoutes";

import { AvailableEventList } from "./components";

export type AddCompanyEventProps = {
  onCompanyEventCreated: (companyEvent: CompanyEvent) => void;
};

const StyledAddCompanyEvent = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const ListCard = styled(Card)`
  border: none;
  background-color: var(--palette-surface-subdued);
`;

const Grid = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: 22rem 1fr;
  grid-column-gap: var(--spacing-xLarge);
`;

const CalendarTag = styled(MediumTag)`
  border-width: 2px;

  :hover {
    background-color: var(--palette-surface-subdued);
  }
`;

export const AddCompanyEvent = ({ onCompanyEventCreated, ...rest }: AddCompanyEventProps) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedCalendars, setSelectedCalendars] = useState<CalendarEventList["calendars"]>([]);

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>();
  const [reportingOpen, setReportingOpen] = useState(false);
  const { data, isLoading } = api.companyEvents.calendars.listEvents.useQuery({});

  const calendars = data?.calendars || [];
  const events = data?.events || [];

  const { mutateAsync } = api.companyEvents.create.useMutation({});

  const addCompanyEvent = useCallback(
    async (hostId: string, recurringEventId: string) => {
      await mutateAsync(
        {
          hostId,
          recurringEventId
        },
        {
          onSuccess: event => {
            toast.success(`Event ${event.title} added`);
            onCompanyEventCreated(event as CompanyEvent);
          },
          onError: () => toast.error("Failed to add event")
        }
      );
    },
    [mutateAsync, onCompanyEventCreated, toast]
  );

  const isSelectedCalendar = useCallback(
    (calendar: CalendarEventList["calendars"][0]) => {
      return selectedCalendars.some(
        c => c.name === calendar.name && c.provider === calendar.provider
      );
    },
    [selectedCalendars]
  );

  const handleSelectCalendar = useCallback(
    (calendar: CalendarEventList["calendars"][0]) => {
      if (isSelectedCalendar(calendar)) {
        setSelectedCalendars(selectedCalendars.filter(_ => _.name !== calendar.name));
      } else {
        setSelectedCalendars(current => [
          ...current,
          { name: calendar.name, provider: calendar.provider }
        ]);
      }
    },
    [selectedCalendars, setSelectedCalendars, isSelectedCalendar]
  );

  if (isLoading) return <CircularLoader fillParent />;

  return (
    <StyledAddCompanyEvent {...rest}>
      <Grid>
        <ListCard>
          <Layout.Group vertical gap="large">
            <Layout.Group justifyContent="space-between">
              <Title variant="bold">Connected calendars</Title>
              <Tooltip tooltip="Connect calendar">
                <IconButton
                  icon="plus"
                  variant="outlined"
                  onClick={() => navigate(appRoutes.connectStart.path)}
                />
              </Tooltip>
            </Layout.Group>
            <Layout.Group vertical gap="small">
              {isEmpty(calendars) && (
                <InfoCard icon="calendarCross" colorVariant="primary" title="No calendars">
                  <Layout.Group vertical style={{ marginTop: 8 }}>
                    <Title>Connect calendar to Introist to see the events here</Title>
                    <Button
                      startIcon="plus"
                      size="small"
                      onClick={() => navigate(appRoutes.connectStart.path)}
                    >
                      Connect calendar
                    </Button>
                  </Layout.Group>
                </InfoCard>
              )}

              {calendars &&
                calendars.map(c => (
                  <CalendarTag
                    $active={isSelectedCalendar(c)}
                    onClick={() => handleSelectCalendar(c)}
                    backgroundColor="white"
                    startAdornment={<HostCalendarIcon emailProvider={c.provider} />}
                  >
                    <Title variant="bold">{c.name}</Title>
                  </CalendarTag>
                ))}
            </Layout.Group>
          </Layout.Group>
        </ListCard>
        <Layout.Group
          vertical
          gap="large"
          style={{
            overflowY: "hidden"
          }}
        >
          <H4>Select recurring event you want to import</H4>
          <AvailableEventList
            reportingOpen={reportingOpen}
            hasConnectedCalendars={!!calendars.length}
            selectedEventId={selectedEvent?.recurringEventId}
            events={events}
            selectedCalendars={selectedCalendars}
            onSelectEvent={setSelectedEvent}
            onReportButtonClick={() => setReportingOpen(true)}
            onCancelReport={() => setReportingOpen(false)}
          />
        </Layout.Group>
      </Grid>
      <Layout.Group justifyContent="space-between" style={{ paddingTop: 24 }}>
        <Layout.Group gap="xSmall">
          <Title>Can't find the event you're looking for?</Title>
          <TextButton colorVariant="primary" onClick={() => setReportingOpen(true)}>
            Report here
          </TextButton>
        </Layout.Group>
        <Button
          disabled={!selectedEvent}
          onClickWithLoading={async () => {
            if (!selectedEvent) return;

            await addCompanyEvent(selectedEvent.hostId, selectedEvent.recurringEventId);
          }}
        >
          Import
        </Button>
      </Layout.Group>
    </StyledAddCompanyEvent>
  );
};
