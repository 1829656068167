import { useAttributes } from "../../../../../../hooks";
import { useMemo } from "react";
import { Card, Icon, IconButton, Layout, Select, Title } from "@introist/react-foundation/v2";
import { BackgroundCard } from "../ScheduleBlock/components/PreconditionStepsEditor";

type PreconditionAttributesSettingProps = {
  value?: string[];
  onChange: (value: string[]) => void;
  readOnly?: boolean;
};

export const PreconditionAttributesSetting = ({
  value,
  onChange,
  readOnly
}: PreconditionAttributesSettingProps) => {
  const attributes = useAttributes(false);

  const options = useMemo(() => {
    return (attributes ?? []).map(a => ({ key: a.variable, title: a.name }));
  }, [attributes]);

  const renderValue = (attribute: string) => {
    const title = options.find(o => o.key === attribute)?.title ?? attribute;

    return (
      <Layout.Group key={attribute} alignItems="center">
        <Icon name="attribute" />
        <Title variant="bold">{title}</Title>
        {!readOnly && (
          <IconButton
            icon="crossSmall"
            onClick={() => {
              const newValue = (value ?? []).filter(v => v !== attribute);
              onChange(newValue);
            }}
          />
        )}
      </Layout.Group>
    );
  };

  const content = (
    <Layout.Group vertical gap="large">
      <Layout.Group vertical gap="xSmall">
        <Layout.Group>
          <Icon subdued name="targetBefore" style={{ rotate: "90deg" }} />
          <Title variant="bold">Precondition attributes</Title>
        </Layout.Group>
        <Title style={{ marginLeft: 28 }}>
          Postpone step execution until the specified attributes have value
        </Title>
      </Layout.Group>
      <Layout.Group vertical style={{ marginLeft: 28 }}>
        {(value ?? []).map(renderValue)}
      </Layout.Group>
      <Layout.Group>
        {!readOnly && (
          <Select
            searchable
            element="button"
            placeholder="Add precondition attribute"
            startAdornment={<Icon name="plusSmall" />}
            variant="blended"
            size="small"
            options={options}
            onSelect={opt => onChange([...(value ?? []), opt.key])}
          />
        )}
      </Layout.Group>
    </Layout.Group>
  );

  if (readOnly) return <BackgroundCard>{content}</BackgroundCard>;
  return <Card>{content}</Card>;
};
