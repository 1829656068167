import { FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Card,
  H4,
  IPopoverProps,
  Icon,
  IconName,
  Layout,
  Popover,
  Title
} from "@introist/react-foundation/v2";
import { animated, useTransition } from "@react-spring/web";

type TourCardStepper = {
  totalAmountOfCards: number;
  cardIndex: number;
};

type Props = Pick<IPopoverProps, "referenceElement" | "placement"> & {
  open: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  onContinue?: () => void;
  continueButtonText?: string;
  closeButtonText?: string;
  maxWidth?: number;
  stepper?: TourCardStepper;
};

type Compound = {
  IconTitle: typeof IconTitle;
};

const TooltipPointer = styled.span<{ $placement: IPopoverProps["placement"] }>`
  display: block;
  position: absolute;
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: inherit;
  border-radius: 1px;

  // Note: The order of the transforms matter
  ${({ $placement }) => {
    switch ($placement) {
      case "bottom-start":
        return css`
          top: -0.25rem;
          left: 1rem;
          transform: rotateZ(45deg);
        `;
      case "left":
        return css`
          top: 50%;
          transform: rotateZ(45deg) translateY(-50%);
          transform-origin: top;
          left: auto;
          right: -0.25rem;
        `;
      case "left-start":
        return css`
          top: 1rem;
          transform: rotateZ(45deg);
          left: auto;
          right: -0.25rem;
        `;
      case "right":
        return css`
          top: 50%;
          transform: rotateZ(45deg) translateY(-50%);
          transform-origin: top;
          left: -0.25rem;
        `;
      case "top":
        return css`
          transform-origin: center;
          bottom: -0.25rem;
          transform: translateX(-50%) rotate(45deg);
        `;
      case "bottom":
      default:
        return css`
          top: -0.25rem;
          transform-origin: center;
          transform: translateX(-50%) rotate(45deg);
        `;
    }
  }}
`;

const StyledTourCard = styled(Card)`
  position: relative;
  border: none;

  && {
    border: none;
    padding: var(--spacing-large);
  }

  b {
    font-weight: 500;
    color: var(--palette-foreground-default);
  }
`;

const Buttons = styled(Layout.Group)`
  margin-top: var(--spacing-large);
  background-color: var(--palette-surface-subdued);
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1rem;
  padding: var(--spacing-large);
  border-bottom-left-radius: var(--rounding-medium);
  border-bottom-right-radius: var(--rounding-medium);
`;

const Stepper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Step = styled.span<{ $active: boolean }>`
  display: block;
  width: 1.25rem;
  height: 3px;
  border-radius: 3px;
  background-color: ${({ $active }) =>
    $active ? "var(--palette-primary-default)" : "var(--palette-foreground-dimmed)"};
`;

export const TourCardTitleIcon = styled.span<{ $backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: var(--rounding-medium);
`;

const IconTitle = ({
  icon,
  color,
  children,
  ...rest
}: {
  icon: IconName;
  color: string;
  children: ReactNode;
}) => {
  return (
    <Layout.Group {...rest}>
      <TourCardTitleIcon $backgroundColor={color}>
        <Icon name={icon} color="white" />
      </TourCardTitleIcon>
      <H4>{children}</H4>
    </Layout.Group>
  );
};

export const TourCardComponent: FC<Props> & Compound = ({
  referenceElement,
  open,
  title,
  children,
  maxWidth = 30,
  continueButtonText = "Continue",
  closeButtonText = "Close",
  stepper,
  placement = "left",
  onClose,
  onContinue,
  ...rest
}) => {
  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: "translateY(1rem)"
    },
    enter: {
      opacity: 1,
      transform: "translateY(0rem)"
    },
    leave: {
      opacity: 0,
      transform: "translateY(1rem)"
    },
    delay: 300
  });

  return transitions((style, tOpen) =>
    tOpen ? (
      <Popover
        open={tOpen}
        attachToRef={false}
        referenceElement={referenceElement}
        onClose={onClose ? onClose : () => {}}
        placement={placement}
        style={{ maxWidth: maxWidth * 16, width: "100%" }}
      >
        <animated.div style={{ ...style, padding: 8 }}>
          <StyledTourCard elevated>
            <TooltipPointer $placement={placement} />

            <Layout.Group vertical gap="small">
              {title}
              <Title>{children}</Title>
              {(onClose || onContinue) && (
                <Buttons justifyContent={!onContinue ? "flex-end" : "space-between"}>
                  {onClose && (
                    <Button variant={!onContinue ? "filled" : "blended"} onClick={onClose}>
                      {closeButtonText}
                    </Button>
                  )}
                  {stepper && (
                    <Stepper>
                      {Array.from({ length: stepper.totalAmountOfCards }).map((_, index) => (
                        <Step
                          key={index}
                          $active={
                            index === stepper.cardIndex ||
                            index === stepper.cardIndex - 1 ||
                            index === stepper.cardIndex - 2
                          }
                        ></Step>
                      ))}
                    </Stepper>
                  )}
                  {onContinue && <Button onClick={onContinue}>{continueButtonText}</Button>}
                </Buttons>
              )}
            </Layout.Group>
          </StyledTourCard>
        </animated.div>
      </Popover>
    ) : null
  );
};

TourCardComponent.IconTitle = IconTitle;
export const TourCard = TourCardComponent;
