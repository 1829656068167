import { Card, ICardProps, Stack, Title, IconName, Icon } from "@introist/react-foundation/v2";
import { ReactNode } from "react";

export type ContentCardProps = ICardProps & {
  title: string;
  icon?: IconName | ReactNode;
};

export const ContentCard = ({ title, children, icon, ...rest }: ContentCardProps) => {
  return (
    <Card {...rest}>
      <Stack vertical gap="xLarge">
        <Stack>
          {icon && typeof icon === "string" ? <Icon name={icon as IconName} /> : icon}
          {title && <Title variant="bold">{title}</Title>}
        </Stack>
        {children}
      </Stack>
    </Card>
  );
};
