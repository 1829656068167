import styled from "styled-components";
import { Button, IconButton, Stack, Tag, Title } from "@introist/react-foundation/v2";
import { Skeletons } from "components/molecules";
import { ResultPreviewBaseProps } from "./ResultPreview";
import { GenericWorkflowStepCard } from "modules/flows/components/WorkflowStepCard";
import { stringifyStepDate } from "utils/Text";
import { WorkflowStepAction } from "services/rpc/RpcProvider";

const Italic = styled(Title)`
  font-style: italic;
`;
interface StepBuilderResultPreviewProps extends ResultPreviewBaseProps {
  preview: WorkflowStepAction | undefined;
}
export const StepBuilderResultPreview = ({
  preview,
  confirm,
  close,
  loading,
  onChange
}: StepBuilderResultPreviewProps) => {
  return (
    <Stack vertical>
      <Stack justifyContent="space-between">
        <IconButton icon="crossSmall" size="small" variant="blended" onClick={close} />
        <Italic>AI response may not be accurate</Italic>
      </Stack>
      {!loading && preview && (
        <Stack>
          <GenericWorkflowStepCard
            stepTitle={preview.data.title!}
            active
            stepType={preview.data.stepType!}
            stepTriggerText={stringifyStepDate(
              preview.data.timing!.side,
              preview.data.timing!.reference,
              preview.data.timing!.amount,
              preview.data.timing!.unit,
              preview.data.timing!.time
            )}
            tag={<Tag startIcon={"plusSmall"}>Add</Tag>}
          />
        </Stack>
      )}
      {loading && (
        <Stack vertical gap="small">
          <Skeletons.Block height={114} />
        </Stack>
      )}

      <Stack justifyContent="flex-end">
        <Button
          size="small"
          variant="blended"
          onClick={preview ? () => confirm(preview) : undefined}
          startIcon="glow"
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
