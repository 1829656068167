import { UpsertFormProps } from "../../../../foundation";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";
import {
  Field,
  Input,
  Select,
  TextArea,
  Stack,
  LabelledCheckbox
} from "@introist/react-foundation/v2";
import { BatchCreator } from "../../useBatchCreators";
import { useEmployeeGroups } from "../../../employees/hooks/useEmployeeGroups";
import { CronBuilder } from "./CronBuilder";

export const BatchCreatorForm = ({ value, onChange, isEdit }: UpsertFormProps<BatchCreator>) => {
  const { workflows } = useWorkflows({});
  const { groups } = useEmployeeGroups();

  return (
    <Stack vertical style={{ width: "400px" }}>
      <Field title="Title">
        <Input
          value={value.title}
          onChange={title => onChange({ ...value, title })}
          autoFocus={!isEdit}
        />
      </Field>
      <Field title="Description">
        <TextArea
          value={value.description ?? ""}
          onChange={description => onChange({ ...value, description })}
        />
      </Field>

      <Field title="Workflow">
        <Select
          searchable
          style={{ width: "100%" }}
          options={(workflows ?? []).map(wf => ({ key: wf.id, title: wf.title }))}
          onSelect={opt => onChange({ ...value, workflowId: opt.key })}
          value={value.workflowId}
        />
      </Field>

      <Field title="Employee Group">
        <Select
          searchable
          style={{ width: "100%" }}
          options={(groups ?? []).map(g => ({ key: g.id, title: g.title }))}
          onSelect={opt => onChange({ ...value, employeeGroupId: opt.key })}
          value={value.employeeGroupId}
        />
      </Field>

      <Field title="Create">
        <CronBuilder value={value.cron} onChange={cron => onChange({ ...value, cron })} />
      </Field>

      <LabelledCheckbox
        label="Start batch when created"
        checked={value.start}
        onChange={start => onChange({ ...value, start })}
      />
    </Stack>
  );
};
