import { CircularLoader, Modal, Select } from "@introist/react-foundation/v2";
import { useEffect, useState } from "react";
import { useBatchActions } from "../../useBatches";
import { useEmployeeGroups } from "../../../employees/hooks/useEmployeeGroups";

export const AddGroupModal = ({
  batchId,
  open,
  onClose
}: {
  batchId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { groups } = useEmployeeGroups({});
  const { addEmployees } = useBatchActions();

  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    setSelected("");
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add employees from group"
      style={{ width: "400px" }}
      primaryButton={{
        title: "Add employees",
        disabled: !selected,
        onClickWithLoading: () => addEmployees(batchId, undefined, selected).then(onClose)
      }}
      secondaryButton={{
        title: "Cancel",
        onClick: onClose
      }}
    >
      {!groups && <CircularLoader />}
      {groups && (
        <Select
          placeholder="Select group"
          searchable
          options={(groups ?? []).map(g => ({ key: g.id, title: g.title }))}
          value={selected}
          onSelect={opt => setSelected(opt.key)}
          style={{ width: "100%" }}
        />
      )}
    </Modal>
  );
};
