import { useEffect } from "react";

export const useMouseMoveListener = (onMove: () => void) => {
  useEffect(() => {
    const listenMouseMove = () => {
      onMove();
    };

    document.addEventListener("mousemove", listenMouseMove);

    return () => document.removeEventListener("mousemove", listenMouseMove);
  }, [onMove]);
};
