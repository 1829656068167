import { DataForm } from "@introist/react-foundation/v2";

import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { EmailFormEditorV2 } from "modules/workflows/routes/WorkflowEditor/StepEditor/EmailFormEditorV2";
import { EmailFormResponsesBlock } from "./components/EmailFormResponsesBlock";
import { EmailMessageBlock } from "../../components/EmailMessageBlock";
import { WorkflowEditorBlock } from "modules/workflows/blocks";

type Props = {
  form: DataForm<JourneyStepV2>;
  readOnly?: boolean;
};

export const EmailFormJourneyStepEditor = ({ form, readOnly }: Props) => {
  if (readOnly) return <ReadOnlyJourneyEditor step={form.data} form={form} />;
  return <EmailFormEditorV2 form={form} formPrefix="previewData." />;
};

type ReadOnlyProps = {
  step: JourneyStepV2;
  form: DataForm<JourneyStepV2>;
};

const ReadOnlyJourneyEditor = ({ step, form }: ReadOnlyProps) => {
  return (
    <>
      <EmailFormResponsesBlock formId={step.journeyData?.resolvedForm.id} />
      <EmailMessageBlock
        title="Form introductory email"
        originalMessage={step.previewData?.message}
        message={step.journeyData?.resolvedMessage}
      />
      <WorkflowEditorBlock.Attachments form={form} readOnly />
    </>
  );
};
