import { CSSProperties, FC, ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  Accordion,
  IAccordionProps,
  Title,
  useResponsive,
  Layout
} from "@introist/react-foundation/v2";

type StepEditorAccordionProps = IAccordionProps & {
  defaultOpen?: boolean;
  stepOrder?: number | string;
  highlight?: boolean;
};

type Compound = {
  Row: typeof Row;
  MultiOptionRow: typeof MultiOptionRow;
};

const StyledRow = styled.div<{ $isMobileLayout: boolean }>`
  display: grid;
  grid-template-columns: 6rem 1fr;
  grid-column-gap: 0.5rem;
  align-items: center;
  user-select: none;

  ${({ $isMobileLayout }) =>
    $isMobileLayout &&
    css`
      grid-template-columns: auto;
    `}
`;

const Row = ({
  label,
  children,
  ...rest
}: {
  label: string;
  children: ReactNode;
  style?: CSSProperties;
}) => {
  const { isMobileLayout } = useResponsive();
  return (
    <StyledRow {...rest} $isMobileLayout={isMobileLayout}>
      <Title>{label}</Title>
      <div>{children}</div>
    </StyledRow>
  );
};

const MultiOptionRow = styled(Row)`
  align-items: flex-start;

  > h5:first-of-type {
    align-self: flex-start;
    padding-top: var(--spacing-small);
  }

  > div {
    flex: 1;
    display: block;
  }
`;

const Content = styled(Layout.Group).attrs({ vertical: true, gap: "xLarge" })`
  padding-top: var(--spacing-small);
`;

const StepOrder = styled(Title)`
  color: var(--palette-foreground-dimmed);
`;

const StyledStepEditorAccordion = styled(Accordion)<{ $highlight?: boolean }>`
  display: grid !important;
  justify-content: stretch !important;

  && {
    transition-property: border-color, box-shadow;
  }

  header button {
    border: none;
  }

  ${({ $highlight }) =>
    $highlight &&
    css`
      border-color: var(--palette-primary-default);
      box-shadow: 0 0 0 4px var(--palette-primary-ghosted);
    `}
`;

const StepEditorAccordionComponent: FC<StepEditorAccordionProps> & Compound = ({
  children,
  stepOrder,
  title,
  highlight,
  ...rest
}) => {
  return (
    <StyledStepEditorAccordion
      {...rest}
      toggleOpenOnHeaderClick
      compact
      $highlight={highlight}
      title={
        stepOrder ? (
          <Layout.Group gap="medium">
            <StepOrder>{stepOrder}</StepOrder>
            {title}
          </Layout.Group>
        ) : (
          title
        )
      }
    >
      <Content>{children}</Content>
    </StyledStepEditorAccordion>
  );
};

StepEditorAccordionComponent.Row = Row;
StepEditorAccordionComponent.MultiOptionRow = MultiOptionRow;
export const StepEditorAccordion = StepEditorAccordionComponent;
