import styled from "styled-components";
import { IconButton, Stack } from "@introist/react-foundation/v2";
import { FittedTitle } from "../../../../components/atoms";

import { isEmail } from "../../../../utils/string-templater";
import { Avatar } from "../../../../components/molecules";
import { FilledIcon } from "./FilledIcon";

const StyledCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-large);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);

    button {
      opacity: 1;
    }
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

const avatarOrAttribute = (value?: string) => {
  if (!value) return <FilledIcon name="person" />;
  if (isEmail(value)) return <Avatar nameOrEmail={value} />;
  return <FilledIcon name="attribute" />;
};

export const EmailAddressCard = ({ value, onRemove }: { value: string; onRemove?: () => void }) => {
  return (
    <StyledCard>
      <Stack style={{ width: "100%" }} gap="small">
        {avatarOrAttribute(value)}
        <Stack vertical>
          <FittedTitle variant="bold" maxLength={40}>
            {value}
          </FittedTitle>
        </Stack>
        {onRemove && (
          <RemoveButton icon="crossSmall" onClick={onRemove} style={{ marginLeft: "auto" }} />
        )}
      </Stack>
    </StyledCard>
  );
};
