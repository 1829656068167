import { Modal, ModalProps, Layout, Button, useToast } from "@introist/react-foundation/v2";
import { completeCredentialConnection, useIntegrationsApi } from "services/api/IntegrationsApi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HrisCard } from "./HrisCard";
import styled from "styled-components";
import { Integration } from "../../../integrations";
import { useStackOne } from "./config/useStackOne";
import { SympaHRConfig, SympaHRConfigFields } from "./config/sympahr";
import { ApiTokenConfig, ApiTokenConfigFields } from "./config/ApiToken";
import { ApiClientConfig, ApiClientConfigFields } from "./config/ApiClient";
import { HiBobConfig, HiBobConfigFields } from "./config/hibob";

type ConnectHrisModalProps = ModalProps & {
  onConnected: () => void;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-medium);
`;

export const ConnectHrisModal = ({ onConnected, ...props }: ConnectHrisModalProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();
  const { data: integrations } = integrationApi.list().query;

  const { startConnecting } = useStackOne();

  const [selectedHris, setSelectedHris] = useState<Integration | null>(null);
  const [config, setConfig] = useState({});

  const onConnect = useCallback(() => {
    return completeCredentialConnection({
      integrationId: selectedHris!.id,
      credentials: config
    })
      .then(async () => {
        toast.success(`${selectedHris!.name} connected`);
        await onConnected();
      })
      .catch(() => toast.error("Failed to connect HRIS"));
  }, [selectedHris, config, toast, onConnected]);

  useEffect(() => {
    if (props.open) setSelectedHris(null);
  }, [props.open]);

  const availableHris = useMemo(() => {
    return (integrations ?? []).filter(integration => integration.categories.includes("HRIS"));
  }, [integrations]);

  const configContainer = useMemo(() => {
    if (!selectedHris) return null;

    if (["alexishr"].includes(selectedHris.id)) {
      return <ApiTokenConfigFields config={config as ApiTokenConfig} onChange={setConfig} />;
    }
    if (selectedHris.id === "hibob") {
      return <HiBobConfigFields config={config as HiBobConfig} onChange={setConfig} />;
    }
    if (["personio"].includes(selectedHris.id)) {
      return <ApiClientConfigFields config={config as ApiClientConfig} onChange={setConfig} />;
    }
    if (selectedHris.id === "sympahr") {
      return <SympaHRConfigFields config={config as SympaHRConfig} onChange={setConfig} />;
    }
  }, [selectedHris, config]);

  const selectHris = useCallback(
    (hris: Integration) => {
      setSelectedHris(hris);
      if (hris.stackoneProviderId) {
        startConnecting(hris, () => setSelectedHris(null), onConnected);
      }
    },
    [startConnecting, onConnected]
  );

  const renderHrisList = () => {
    return (
      <Grid>
        {availableHris.map(hris => (
          <HrisCard
            hris={hris}
            onClick={() => selectHris(hris)}
            loading={selectedHris?.id === hris.id}
          />
        ))}
      </Grid>
    );
  };

  const renderHrisConfig = () => {
    return (
      <Layout.Group vertical gap="xLarge">
        <HrisCard hris={selectedHris!} hoverable={false} />
        {configContainer}
        <Layout.Group justifyContent="space-between">
          <Button variant="outlined" onClick={() => setSelectedHris(null)}>
            Back
          </Button>
          <Button startIcon="arrowsLeftRight" onClickWithLoading={onConnect}>
            Connect
          </Button>
        </Layout.Group>
      </Layout.Group>
    );
  };

  const selectRender = () => {
    if (!selectedHris || selectedHris?.stackoneProviderId) return renderHrisList();
    return renderHrisConfig();
  };

  return (
    <Modal title="Connect HRIS" {...props}>
      {selectRender()}
    </Modal>
  );
};
