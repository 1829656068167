import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

type GoogleFormsConfig = {
  formId: string;
};
export const GoogleFormsConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as GoogleFormsConfig) || {
    formId: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.formId}
        label="Google Form ID"
        onChange={formId =>
          onChange({
            credentials: {
              ...credentials,
              formId
            }
          })
        }
      />
    </Layout.Group>
  );
};
