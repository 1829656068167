import { useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";
import { GroupedEmployeeField, api } from "services/rpc/RpcProvider";

export const useArchiveEmployeeField = (onFieldArchived?: VoidFunction) => {
  const toast = useToast();
  const { mutateAsync: updateField } = api.employees.fields.update.useMutation({});
  const onArchiveField = useCallback(
    async ({ field, archive }: { field: GroupedEmployeeField; archive: boolean }) => {
      await updateField(
        {
          fieldId: field.id,
          updates: {
            hidden: archive
          }
        },
        {
          onSuccess: () => {
            archive ? toast.success("Field archived") : toast.success("Field unarchived");
            onFieldArchived?.();
          },
          onError: () => {
            toast.error("Failed to archive field");
          }
        }
      );
    },
    [toast, updateField, onFieldArchived]
  );

  return {
    onArchiveField
  };
};
