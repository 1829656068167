import { Integration } from "../../../../integrations";
import { api } from "../../../../../services/rpc/RpcProvider";
import { useStackOneHub } from "@stackone/react-hub";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

export const useStackOne = () => {
  const toast = useToast();
  const { startConnect } = useStackOneHub();

  const loadToken = api.hris.stackOne.start.useMutation();
  const connectIntegration = api.hris.stackOne.complete.useMutation();

  const startConnecting = useCallback(
    (
      integration: Integration,
      onClose: () => void,
      onConnected: (stackOneAccountId: string) => void
    ) => {
      loadToken
        .mutateAsync({ providerId: integration.stackoneProviderId })
        .then(({ token }) =>
          startConnect({
            sessionToken: token,
            onClose,
            onCancel: onClose,
            onSuccess: async a => {
              await connectIntegration.mutateAsync({
                integrationId: integration.id,
                stackoneAccountId: a.id
              });
              onConnected(a.id);
            }
          })
        )
        .catch(() => toast.error(`Failed to start connecting ${integration.name}`));
    },
    [loadToken, toast, startConnect, connectIntegration]
  );

  return { startConnecting, isLoading: loadToken.isLoading };
};
