import { useCallback, useEffect, useMemo, useState } from "react";
import { Field, Title, Input, Button, useToast } from "@introist/react-foundation/v2";
import { updateIntegrationConfiguration, useIntegrationsApi } from "services/api/IntegrationsApi";

type WorkableConfiguration = {
  domain: string;
};

type WorkableConfigurationContainerProps = {
  integrationId: string;
};

export const WorkableConfigurationContainer = ({
  integrationId
}: WorkableConfigurationContainerProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });
  const { data: integration } = findIntegration.query;

  const [domain, setDomain] = useState("");

  useEffect(() => {
    setDomain((integration?.configuration as WorkableConfiguration)?.domain ?? "");
  }, [integration]);

  const domainChanged = useMemo(() => {
    if (!integration) return false;
    return domain !== (integration?.configuration as WorkableConfiguration)?.domain;
  }, [integration, domain]);

  const onSave = useCallback(async () => {
    await updateIntegrationConfiguration({ integrationId, configuration: { domain } })
      .then(async () => {
        await findIntegration.refetch();
        toast.success("Configuration updated");
      })
      .catch(() => toast.error("Failed to update configuration"));
  }, [integrationId, domain, findIntegration, toast]);

  return (
    <div>
      <Field title="Workable domain">
        <Input
          type="text"
          value={domain}
          onChange={newVal => {
            setDomain(newVal);
          }}
          endAdornment={<Title>.workable.com</Title>}
        />
      </Field>
      <Button disabled={!domainChanged} onClickWithLoading={onSave}>
        Save changes
      </Button>
    </div>
  );
};
