import { Icon } from "@introist/react-foundation/v2";
import { TriggerRoute, TriggersListRoute } from "./routes";

export * from "./routes";
export * from "./hooks/useTriggers";

export const TRIGGER_ROUTES = {
  triggers: {
    key: "route-triggers",
    title: "Triggers",
    icon: <Icon name="trigger" />,
    path: "/triggers",
    element: <TriggersListRoute />
  },
  triggerFolder: {
    key: "route-triggers",
    title: "Triggers",
    path: "/triggers/folder/:folderId",
    element: <TriggersListRoute />
  },
  trigger: {
    key: "route-trigger",
    title: "Trigger",
    path: "/triggers/:triggerId",
    element: <TriggerRoute />
  }
};
