import { EmployeeField } from "../../../hooks/useEmployeeFields";
import { Employee } from "../../../hooks/useEmployees";
import { formatDate } from "../../../../../utils/DatesUtils";
import { DateFormats } from "../../../../../utils/dates";
import { Stack, Title } from "@introist/react-foundation/v2";
import { Avatar } from "../../../../../components/molecules";
import { Automation } from "../../../../automations/hooks/useAutomations";
import { EmployeeAutomationSelector } from "../components/EmployeeAutomationSelector";

export const formatColumns = (
  columnNames: string[],
  employeeFields: EmployeeField[],
  automations: Automation[]
) => {
  return columnNames
    .filter(columnKey => columnKey !== "id")
    .map(columnKey => {
      const employeeField = employeeFields?.find(f => f.key === columnKey);

      if (employeeField?.type === "date") {
        return {
          key: columnKey,
          title: employeeField?.title || columnKey,
          sortable: true,
          render: ({ [columnKey]: value }: Employee) => {
            const formattedDate = formatDate(value ?? "", DateFormats.date.withMonth.long);
            return <Title>{formattedDate === "Invalid date" ? "" : formattedDate}</Title>;
          }
        };
      }

      if (columnKey === "name") {
        return {
          key: columnKey,
          title: employeeField?.title || columnKey,
          sortable: true,
          render: ({ name }: Employee) => {
            return (
              <Stack gap="small">
                <Avatar nameOrEmail={name ?? ""} />
                <Title variant="bold">{name}</Title>
              </Stack>
            );
          }
        };
      }

      if (columnKey === "automations") {
        return {
          key: columnKey,
          title: "Active automations",
          render: ({ id }: Employee) => {
            const employeeAutomations = automations.filter(a => a.employeeId === id);

            return (
              <Stack>
                {employeeAutomations.length > 0 && (
                  <EmployeeAutomationSelector automations={employeeAutomations} />
                )}
              </Stack>
            );
          }
        };
      }

      return {
        key: columnKey,
        title: employeeField?.title || columnKey,
        sortable: true
      };
    });
};
