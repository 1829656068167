import { TopBarRoute } from "components/templates";
import { HolidayList } from "../containers/HolidayCalendar";

import { useDefaultCountry } from "hooks";

export const HolidayCalendarRoute = () => {
  const defaultCountry = useDefaultCountry();

  return (
    <TopBarRoute paths={[{ key: "holidays", title: "Holidays" }]}>
      {defaultCountry && <HolidayList defaultCountry={defaultCountry} />}
    </TopBarRoute>
  );
};
