import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { IconButton, Select, Stack, Toggle, useTheme } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";

import { useWorkflows } from "../../hooks/useWorkflows";
import { useMemo } from "react";

export const WorkflowTriggerStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, stepDataPath);

  const { workflows } = useWorkflows({});

  const workflow = useMemo(() => {
    return workflows?.find(_ => _.id === prefixedForm.get("workflowId"));
  }, [workflows, prefixedForm]);

  return (
    <Block blended compact>
      <StepEditorAccordion title="Workflow details" stepOrder={2}>
        <StepEditorAccordion.Row label="Workflow">
          <Stack justifyContent="flex-start">
            <Select
              attachToRef={false}
              element="button"
              variant="blended"
              searchable
              searchPlaceholder="Find workflow"
              placeholder="Select workflow to trigger"
              value={prefixedForm.get("workflowId")}
              options={workflows?.map(wf => ({ key: wf.id, title: wf.title })) || []}
              onSelect={option => prefixedForm.set("workflowId")(option.key)}
              error={prefixedForm.isError("workflowId")}
            />
            {workflow && (
              <IconButton
                size="large"
                icon="moveTo"
                dimmed
                style={{ marginLeft: "auto" }}
                onClick={() => window.open(`/flows/edit/${workflow.id}`, "_blank")}
              />
            )}
          </Stack>
        </StepEditorAccordion.Row>

        <StepEditorAccordion.Row label="Start as draft">
          <Toggle
            style={{ marginLeft: theme.spacing.medium }}
            checked={prefixedForm.get("startAsDraft")}
            onChange={prefixedForm.set("startAsDraft")}
          />
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
