import styled from "styled-components";

export const SmoothCard = styled.div`
  background-color: var(--palette-background-default);
  padding: var(--spacing-xxLarge);
  border-radius: var(--rounding-medium);
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
  align-items: center;
  justify-content: center;
  min-height: 400px;
  min-width: 500px;
  max-width: 680px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    padding: 32px;
    min-width: 90vw;
  }
`;
