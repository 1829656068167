import { FittedTitle } from "components/atoms";
import {
  Select,
  Icon,
  useTheme,
  Title,
  IconButton,
  Card,
  Layout,
  Button
} from "@introist/react-foundation/v2";
import { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { IconToggleButton } from "../../../../../components/IconToggleButton";
import { StepTypeIcon } from "../../../../../components/steps/StepTypeIcon/StepTypeIcon";
import { getWorkflowStepTypeWithEventType } from "../../../../../types";
import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";
import { useWorkflowSteps, WorkflowStep } from "../../../../../../flows/hooks/useWorkflowSteps";

type PreconditionStepsEditorProps = {
  stepId?: string;
  preconditionSteps: string[];
  onChange: (preconditionSteps: string[]) => void;
  readOnly?: boolean;
  workflowId: string;
};

export const BackgroundCard = styled(Card)<{ $withoutBorder?: boolean }>`
  width: 100%;
  background: var(--palette-surface-subdued);

  ${({ $withoutBorder }) => $withoutBorder && `border-color: transparent;`}
`;

const PreconditionsTitle = ({ active }: { active: boolean }) => {
  const { theme } = useTheme();
  return (
    <Layout.Group>
      <Icon
        name="targetBefore"
        color={active ? theme.palette.primary.default : theme.palette.foreground.subdued}
        style={{ rotate: "90deg" }}
      />
      <Title variant="bold">Preconditions</Title>
    </Layout.Group>
  );
};

const ReadOnlyPreconditions = ({
  steps,
  preconditionSteps
}: {
  steps: WorkflowStep[];
  preconditionSteps: string[];
}) => {
  const active = preconditionSteps?.length > 0;
  return (
    <BackgroundCard>
      <Layout.Group vertical>
        <PreconditionsTitle active={active} />
        {active && (
          <>
            {preconditionSteps.map(preconditionStepId => (
              <PreconditionStepRow
                contentWidth
                key={`precondition-step-${preconditionStepId}-read-only`}
                steps={steps ?? []}
                preconditionStepId={preconditionStepId}
              />
            ))}
            <Title>needs to be completed before this step</Title>
          </>
        )}
        {!active && <Title>This step doesn't have any preconditions</Title>}
      </Layout.Group>
    </BackgroundCard>
  );
};

export const PreconditionStepsEditor = ({
  stepId,
  preconditionSteps,
  readOnly,
  workflowId,
  onChange
}: PreconditionStepsEditorProps) => {
  const { theme } = useTheme();

  const { steps } = useWorkflowSteps({ workflowId });

  const [popupOpen, setPopupOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [stepToAdd, setStepToAdd] = useState<string | undefined>();

  const stepOptions = useMemo(() => {
    if (!steps) return [];
    return steps
      .filter(step => !preconditionSteps.includes(step.id!))
      .filter(step => step.id !== stepId)
      .map(step => ({
        key: step.id!,
        title: step.stepTitle ?? "Untitled step",
        startAdornment: (
          <StepTypeIcon stepType={getWorkflowStepTypeWithEventType(step)} variant="blended" />
        )
      }));
  }, [steps, stepId, preconditionSteps]);

  const isActive = preconditionSteps?.length > 0;

  if (readOnly) {
    return <ReadOnlyPreconditions steps={steps || []} preconditionSteps={preconditionSteps} />;
  }

  return (
    <Card>
      <Layout.Group vertical>
        <Layout.Group vertical gap="xSmall">
          <PreconditionsTitle active={isActive} />
          <Title style={{ marginLeft: 28 }}>
            Make sure this step doesn't start until other steps are completed
          </Title>
        </Layout.Group>
        {isActive && (
          <>
            <BackgroundCard $withoutBorder>
              <Layout.Group vertical gap="xSmall">
                {preconditionSteps.map(preconditionStepId => (
                  <PreconditionStepRow
                    key={`precondition-step-${preconditionStepId}`}
                    steps={steps ?? []}
                    preconditionStepId={preconditionStepId}
                    onRemove={() => {
                      onChange(preconditionSteps.filter(id => id !== preconditionStepId));
                    }}
                  />
                ))}
                <Title>needs to be completed before this step</Title>
              </Layout.Group>
            </BackgroundCard>
          </>
        )}
        <div>
          <IconToggleButton
            ref={buttonRef}
            startIcon="plusSmall"
            active={popupOpen}
            onClick={() => setPopupOpen(!popupOpen)}
          >
            Add precondition
          </IconToggleButton>
        </div>
      </Layout.Group>
      <CardPopover
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
          setStepToAdd(undefined);
        }}
        referenceElement={buttonRef.current}
        widthPreset="ultraWide"
      >
        <Layout.Group vertical gap="xLarge">
          <Title variant="bold">Add precondition</Title>
          <Select
            options={stepOptions}
            value={stepToAdd}
            onSelect={opt => setStepToAdd(opt.key)}
            placeholder="Select precondition step"
            style={{ width: "350px" }}
            sameWidthWithReference
          />
          <Layout.Group>
            <Icon
              name="targetBefore"
              color={theme.palette.primary.default}
              style={{ rotate: "90deg" }}
            />
            <Title color={theme.palette.foreground.default}>needs to be completed before</Title>
          </Layout.Group>
          <Card style={{ borderColor: theme.palette.border.default }}>
            <Layout.Group justifyContent="space-between">
              <Title color={theme.palette.foreground.default}>This step</Title>
              <Icon name="lock" color={theme.palette.foreground.dimmed} />
            </Layout.Group>
          </Card>
          <Button
            disabled={!stepToAdd}
            onClick={() => {
              onChange([...preconditionSteps, stepToAdd!]);
              setPopupOpen(false);
              setStepToAdd(undefined);
            }}
            style={{ marginLeft: "auto" }}
          >
            Add
          </Button>
        </Layout.Group>
      </CardPopover>
    </Card>
  );
};

type RowProps = {
  steps: WorkflowStep[];
  preconditionStepId: string;
  contentWidth?: boolean;
  onRemove?: () => void;
};

const StyledStepTypeIcon = styled(StepTypeIcon)`
  width: 16px;
  height: 16px;
`;

const PreconditionStepRow = ({ steps, contentWidth, preconditionStepId, onRemove }: RowProps) => {
  const { theme } = useTheme();

  const preconditionStep = steps.find(step => step.id === preconditionStepId);

  return (
    <Layout.Group gap="xSmall">
      <Card
        style={{
          padding: `${theme.spacing.small} ${theme.spacing.medium}`,
          flex: contentWidth ? "initial" : 1
        }}
      >
        <Layout.Group justifyContent="space-between">
          <Layout.Group gap="small">
            {preconditionStep && (
              <StyledStepTypeIcon
                stepType={getWorkflowStepTypeWithEventType(preconditionStep)}
                variant="blended"
              />
            )}
            <FittedTitle maxLength={100} showTooltip color={theme.palette.foreground.default}>
              {preconditionStep?.stepTitle ?? "Untitled step"}
            </FittedTitle>
          </Layout.Group>
          {onRemove && <IconButton icon="trash" size="small" onClick={onRemove} />}
        </Layout.Group>
      </Card>
    </Layout.Group>
  );
};
