import { ComponentProps, FC } from "react";
import styled from "styled-components";
import { Icon } from "@introist/react-foundation/v2";
import { animated } from "@react-spring/web";

import { useCrossLineSprings } from "./hooks/useCrossLineSprings";
import { HorizontalCrossLine } from "./components/HorizontalCrossLine";
import { AnimatedCrossIcon } from "../AnimatedCrossLine";

export type CrossLineProps = Pick<ComponentProps<"div">, "style"> & {
  length: number;
  animate?: boolean;
  animationDelayMs?: number;
  crossBackgroundColor?: string;
  thickness?: number;
};

type Compound = {
  Horizontal: typeof HorizontalCrossLine;
};

export const StyledCrossLine = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const AnimatedLine = styled(animated.span)`
  display: block;
  width: 1px;
  transform-origin: top;
  height: inherit;
  background-color: var(--palette-border-subdued);
`;

const CrossLineComponent: FC<CrossLineProps> & Compound = ({
  length,
  animate = true,
  animationDelayMs,
  thickness = 1,
  crossBackgroundColor,
  ...rest
}) => {
  const { verticalLineSpring, crossSpring } = useCrossLineSprings(animationDelayMs, !animate);

  return (
    <StyledCrossLine {...rest} style={{ height: `${length}rem` }}>
      <AnimatedCrossIcon style={crossSpring} $backgroundColor={crossBackgroundColor}>
        <Icon danger name="crossSmall" />
      </AnimatedCrossIcon>
      <AnimatedLine style={{ ...verticalLineSpring, width: thickness }} />
    </StyledCrossLine>
  );
};

CrossLineComponent.Horizontal = HorizontalCrossLine;
export const CrossLine = CrossLineComponent;
