import { Icon, IconName, Stack, Title, Tooltip } from "@introist/react-foundation/v2";

export const IconWithCount = ({
  iconName,
  count,
  hideIfZero = false,
  tooltip,
  color
}: {
  iconName: IconName;
  count: number;
  hideIfZero?: boolean;
  tooltip?: string;
  color?: string;
}) => {
  if (hideIfZero && count === 0) {
    return null;
  }

  const icon = (
    <Stack gap="small">
      <Icon name={iconName} color={color} />
      <Title color={color}>{count}</Title>
    </Stack>
  );

  if (tooltip) {
    return <Tooltip tooltip={tooltip}>{icon}</Tooltip>;
  }
  return icon;
};
