import { MediumTag } from "./MediumTag";
import { StepTypeIcon, getStepDefaultTitle } from "modules/workflows/components/steps";
import styled, { css } from "styled-components";
import { Tooltip } from "@introist/react-foundation/v2";
import { useStepType } from "../../modules/flows/stepTypes";

type Props = {
  stepType: string;
  compact?: boolean;
};

const StyledStepTypeTag = styled(MediumTag)<{ $compact?: boolean }>`
  ${({ $compact }) =>
    $compact &&
    css`
      padding: 0.25rem;
      h5 {
        display: none;
      }
    `}
`;

export const StepTypeTag = ({ stepType, compact = false, ...rest }: Props) => {
  const title = getStepDefaultTitle(stepType);

  const wfStepType = useStepType(stepType);

  const renderTag = () => (
    <StyledStepTypeTag
      {...rest}
      backgroundColor="transparent"
      startAdornment={
        wfStepType?.icon ?? <StepTypeIcon compact variant="blended" stepType={stepType} />
      }
      $compact={compact}
    >
      {title}
    </StyledStepTypeTag>
  );

  return compact ? (
    <Tooltip tooltip={title} {...rest}>
      {renderTag()}
    </Tooltip>
  ) : (
    renderTag()
  );
};
