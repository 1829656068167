import { Stack, Tag, Title } from "@introist/react-foundation/v2";

import { BatchEmployee } from "../useBatches";
import { AutomationProgress } from "../../automations/components/AutomationProgress";

export const BatchEmployeeStatusTag = ({ employee }: { employee: BatchEmployee }) => {
  if (!employee.resolution && !employee.automationId) return <Title>No automation yet</Title>;

  const resolveContent = () => {
    if (employee.resolution === "completed") {
      return <Tag colorVariant="success">Completed</Tag>;
    }
    if (employee.resolution === "canceled") {
      return <Tag colorVariant="surface">Canceled</Tag>;
    }

    return <AutomationProgress stats={employee.automationStats!} />;
  };

  return (
    <Stack
      onClick={
        employee.automationId
          ? () => window.open(`/automations/${employee.automationId}`, "_blank")
          : undefined
      }
      style={{ cursor: employee.automationId ? "pointer" : undefined }}
    >
      {resolveContent()}
    </Stack>
  );
};
