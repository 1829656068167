import { useState } from "react";

export const useFiles = () => {
  const [files, setFiles] = useState<File[]>([]);
  return {
    files,
    addFiles: (newFiles: File[]) => {
      setFiles([...files, ...newFiles]);
    },

    removeFile: (file: File) => {
      setFiles(files.filter(f => f !== file));
    }
  };
};
