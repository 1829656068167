import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import { FieldMapping, FieldMappingProps } from "./index";

export const ReadOnlyFieldMapping = ({
  sourceFields,
  targetFields = [],
  value,
  showHeader = true
}: Pick<FieldMappingProps, "sourceFields" | "targetFields" | "value" | "showHeader">) => {
  return (
    <Stack vertical>
      {showHeader && (
        <Stack
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 20px 1fr"
          }}
        >
          <Title style={{ textAlign: "center" }}>Source field</Title>
          <Icon name="arrowRight" />
          <Title style={{ textAlign: "center" }}>Target field</Title>
          <div />
        </Stack>
      )}
      {value.map((field: FieldMapping) => (
        <Stack
          key={field.id}
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 20px 1fr"
          }}
        >
          <Title
            variant="bold"
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center"
            }}
          >
            {sourceFields.find((f: any) => f.key === field.sourceField)?.title ?? field.sourceField}
          </Title>
          <Icon name="arrowRight" />
          <Title
            variant="bold"
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center"
            }}
          >
            {targetFields.find((f: any) => f.key === field.targetField)?.title ?? field.targetField}
          </Title>
        </Stack>
      ))}
    </Stack>
  );
};
