import styled from "styled-components";
import { Button, Icon, Title, useTheme } from "@introist/react-foundation/v2";
import { ListEmptyState } from "components/organisms";

type Props = {
  onEnrollEmployee: VoidFunction;
  onSyncEvent: VoidFunction;
};

const LargeCalendarIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
`;

export const EventAttendeeListEmptyState = ({ onSyncEvent, onEnrollEmployee }: Props) => (
  <ListEmptyState>
    <ListEmptyState.Image>
      <LargeCalendarIcon dimmed name="calendar" />
    </ListEmptyState.Image>
    <ListEmptyState.Title>Sync existing event information</ListEmptyState.Title>
    <Title style={{ textAlign: "center" }}>
      Synchronize existing event information, including sessions and the list of attendees
    </Title>
    <ListEmptyState.Buttons>
      <Button startIcon="reconnect" onClick={onSyncEvent}>
        Sync event
      </Button>
      <Button startIcon="plus" variant="outlined" onClick={onEnrollEmployee}>
        Enroll attendee
      </Button>
    </ListEmptyState.Buttons>
  </ListEmptyState>
);

export const ManagedAttendeeListEmptyState = ({
  onAddFromCalendar,
  onEnrollEmployee
}: {
  onEnrollEmployee: VoidFunction;
  onAddFromCalendar: VoidFunction;
}) => {
  const { theme } = useTheme();
  return (
    <ListEmptyState>
      <ListEmptyState.Image>
        <LargeCalendarIcon dimmed name="personSync" color={theme.palette.foreground.dimmed} />
      </ListEmptyState.Image>
      <ListEmptyState.Title>No managed attendees</ListEmptyState.Title>
      <Title style={{ textAlign: "center" }}>
        Import from sessions or start adding new attendees
      </Title>
      <ListEmptyState.Buttons>
        <Button onClick={onAddFromCalendar}>Import from event</Button>
        <Button variant="outlined" onClick={onEnrollEmployee}>
          Add new attendee
        </Button>
      </ListEmptyState.Buttons>
    </ListEmptyState>
  );
};
