import { Button, H1, P, useResponsive, useTheme } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { appRoutes } from "../../AppRoutes";
import { logout } from "../../services/firebase";

export const NoAccountRoute = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { isDesktopLayout } = useResponsive();

  const handleClick = () => {
    window.location.href = "https://introist.typeform.com/to/FvLwCHch";
  };

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        maxWidth: "650px",
        margin: `0 auto`,
        boxSizing: "border-box",
        padding: theme.spacing.xLarge
      }}
    >
      <H1>Welcome to Introist!</H1>
      <P style={{ marginTop: theme.spacing.xLarge }}>
        It seems that you don't have an account yet.
      </P>
      <P
        style={{
          color: theme.palette.foreground.dimmed,
          marginTop: theme.spacing.large,
          lineHeight: "20px"
        }}
      >
        If you followed an invite from a colleague, unfortunately that just didn't work. <br />
        Give them a ping or summon us from the chat.
      </P>
      <P
        style={{
          color: theme.palette.foreground.dimmed,
          marginTop: theme.spacing.large,
          lineHeight: "20px"
        }}
      >
        On the other hand, if you're just exploring if Introist could work for you, we would be
        thrilled to help you get started.
      </P>
      <div
        style={{
          display: "grid",
          gap: "1rem",
          gridAutoFlow: isDesktopLayout ? "column" : "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: theme.spacing.xLarge,
          width: "100%",
          gridTemplateColumns: isDesktopLayout ? "minmax(100px, 300px) auto" : undefined,
          gridTemplateRows: isDesktopLayout ? "1fr 1fr" : "1fr 1fr"
        }}
      >
        <Button onClick={handleClick}>Let us know about you</Button>
        <Button
          variant="blended"
          onClickWithLoading={() => logout().then(() => navigate(appRoutes.login.path))}
        >
          Use another account
        </Button>
      </div>
    </div>
  );
};
