import { Icon } from "@introist/react-foundation/v2";
import { WorkflowEditorRoute, WorkflowsRoute } from "./routes";
import { Routes } from "../../AppRoutes";
import { RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";

export * from "./routes";

export const WORKFLOWS_ROUTES: Routes = {
  flows: {
    key: "route-flows",
    title: "Workflows",
    icon: <Icon name="workflows" />,
    path: "/flows",
    element: <WorkflowsRoute />
  },
  nestedWorkflowFolders: {
    key: "route-flows-nested",
    title: "Nested Folders",
    path: "/flows/folder/:folderId",
    element: <WorkflowsRoute />
  },
  workflowEditor: {
    key: "route-flows-edit-flow",
    title: "Edit",
    path: "/flows/edit/:workflowId",
    element: <WorkflowEditorRoute />
  },
  workflowEditorStepEditor: {
    key: "route-flows-edit-flow-edit-step",
    title: "Edit Step",
    path: "/flows/edit/:workflowId/:stepId",
    element: <WorkflowEditorRoute />
  }
};

export type Workflow = RouterOutput["workflows"]["v4"]["list"][0];
export type TargetDateChangeMode = RouterOutput["workflows"]["v4"]["find"]["targetDateChangeMode"];

export type WorkflowCreate = RouterInput["workflows"]["v4"]["create"];
export type WorkflowUpdate = RouterInput["workflows"]["v4"]["update"];

export type WorkflowFolder = RouterOutput["workflows"]["v4"]["folders"]["find"];
