import { Card, CircularLoader, Layout, Stack } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import styled from "styled-components";

const StyledPanel = styled(Card)<{ $maxHeight?: number }>`
  padding: var(--spacing-medium) var(--spacing-large);
  background-color: var(--palette-background-default);
  border-left: 1px solid var(--palette-border-subdued);
  box-sizing: border-box;
  overflow: hidden;
  max-width: 25rem;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: calc(100vh - 2 * 0.75rem);

  :hover {
    overflow-y: auto;
  }
`;

const Panel = ({
  children,
  maxHeight,
  loading,
  loader
}: {
  children?: ReactNode;
  maxHeight?: number;
  loading?: boolean;
  loader?: ReactNode;
}) => (
  <StyledPanel $maxHeight={maxHeight}>
    {loading && <>{loader || <CircularLoader fillParent colorVariant="primary" />}</>}
    {!loading && children}
  </StyledPanel>
);

const View = styled(Stack).attrs({ vertical: true, gap: "medium" })`
  flex: 1;
  overflow: hidden;
  background-color: var(--palette-surface-subdued);
  padding: 0.75rem;
  padding-left: 0;
  height: 100vh;
  box-sizing: border-box;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 25rem;
  flex: 1;
`;

const Content = styled(Card)`
  margin: 0 auto;
  width: 100%;
  padding: var(--spacing-large);
  padding-top: var(--spacing-medium);
  box-sizing: border-box;
  overflow: hidden;
  scrollbar-gutter: stable;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: calc(100vh - 3 * 0.75rem - 20px);
  height: calc(100vh - 2 * 0.75rem);

  :hover {
    overflow-y: auto;
  }
`;

const PanelDivider = styled(Layout.Divider)`
  /* margin-left: calc(var(--spacing-xxLarge) * -1);
  margin-right: calc(var(--spacing-xxLarge) * -1); */
  /* width: calc(100% + (var(--spacing-xxLarge) * 2)); */
  background-color: var(--palette-border-dimmed);
`;

export const PanelLayoutV2 = {
  View,
  Panel,
  Content,
  Grid,
  PanelDivider
};
