import { DataForm, Field } from "@introist/react-foundation/v2";

import { TextInput } from "components/atoms";
import { Block } from "components/atoms";
import { WorkflowStep } from "services/api/WorkflowApi";
import { prefixForm } from "utils/FormUtils";

import { StepEditorAccordion } from "../../StepEditorAccordion";

type WebhookStepEditorV2Props = {
  form: DataForm<WorkflowStep>;
  formPrefix: string;
};

export const WebhookStepEditorV2 = ({ form, formPrefix }: WebhookStepEditorV2Props) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const { get, set, isError } = prefixedForm;
  return (
    <Block compact blended>
      <StepEditorAccordion title="Action details" stepOrder={2}>
        <Field title="Hook URL">
          <TextInput
            size="small"
            placeholder="https://www.example.com"
            value={get("hookUrl")}
            onChange={set("hookUrl")}
            error={isError("hookUrl")}
          />
        </Field>
        <Field title="API Key">
          <TextInput
            size="small"
            placeholder="Your API Key"
            value={get("apiKey")}
            onChange={set("apiKey")}
            error={isError("apiKey")}
            type="password"
          />
        </Field>
      </StepEditorAccordion>
    </Block>
  );
};
