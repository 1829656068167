import styled from "styled-components";
import { useWorkflowTimelineSteps } from "./hooks";

import { SkeletonLoader, Stack } from "@introist/react-foundation/v2";
import { TriggerMode } from "services/api/workflows/StepDataBuilder";
import { WorkflowTimelineStepGroup } from "./WorkflowTimelineStepGroup";
import { TargetDateCard } from "./TargetDateCard";
import { Workflow } from "../../index";
import { WorkflowStep } from "../../hooks/useWorkflowSteps";
import { WorkflowStepAssistant } from "./WorkflowStepAssistant";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { useMemo } from "react";

type Props = {
  workflow: Workflow;
  steps: WorkflowStep[];
  onAddStep: (triggerMode: TriggerMode) => void;
  onStepClick: (stepId: string) => void;
};

const StyledWorkflowTimeline = styled.div`
  padding: var(--spacing-xxLarge) var(--spacing-large);
  display: flex;
  justify-content: center;
  background-color: var(--palette-surface-subdued);
  border-radius: var(--rounding-medium);
  background-image: radial-gradient(circle, var(--palette-surface-hovered) 1px, transparent 1px),
    radial-gradient(circle, var(--palette-surface-hovered) 1px, transparent 1px);
  background-position: 0 0, 16px 16px;
  background-size: 16px 16px;
  overflow-y: auto;
  position: relative;
`;

const TimelineSteps = styled(Stack)`
  width: 432px;
  height: fit-content;
  position: relative;
`;

const StepSeparator = styled.span`
  position: absolute;
  top: 0rem;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  background-color: var(--palette-border-default);
  width: 1px;
`;

export const WorkflowTimeline = ({ steps, onAddStep, onStepClick, workflow, ...rest }: Props) => {
  const { fields } = useEmployeeFields();

  const { timelineSteps } = useWorkflowTimelineSteps(steps);

  const immediate = workflow.targetDateAttribute === "immediately";

  const formattedTargetDate = useMemo(() => {
    const field = (fields ?? []).find(f => f.key === workflow.targetDateAttribute);

    return field?.title?.toLowerCase() ?? "target date";
  }, [fields, workflow]);

  return (
    <StyledWorkflowTimeline {...rest} className="timeline">
      <WorkflowStepAssistant workflowId={workflow.id} />

      <TimelineSteps vertical gap="xxLarge">
        <StepSeparator />
        {(immediate || timelineSteps.immediate.length > 0) && (
          <WorkflowTimelineStepGroup
            title="Immediate"
            steps={timelineSteps.immediate}
            formattedTargetDate={formattedTargetDate}
            onAddStep={() => onAddStep("after-enroll")}
            onStepClick={onStepClick}
          />
        )}
        <WorkflowTimelineStepGroup
          title="After enroll"
          steps={timelineSteps.afterEnroll}
          formattedTargetDate={formattedTargetDate}
          onAddStep={() => onAddStep("after-enroll")}
          onStepClick={onStepClick}
        />
        {!immediate && (
          <WorkflowTimelineStepGroup
            title={`Before ${formattedTargetDate}`}
            steps={timelineSteps.beforeTarget}
            formattedTargetDate={formattedTargetDate}
            onAddStep={() => onAddStep("before-targetdate")}
            onStepClick={onStepClick}
          />
        )}
        {!immediate && (
          <div style={{ zIndex: 1 }}>
            <TargetDateCard
              loading={!!fields}
              targetDate={formattedTargetDate}
              workflowId={workflow.id}
            />
          </div>
        )}
        {!immediate && (
          <WorkflowTimelineStepGroup
            title={`After ${formattedTargetDate}`}
            steps={timelineSteps.afterTarget}
            formattedTargetDate={formattedTargetDate}
            onAddStep={() => onAddStep("after-targetdate")}
            onStepClick={onStepClick}
          />
        )}
      </TimelineSteps>
    </StyledWorkflowTimeline>
  );
};

export const WorkflowTimelineSkeletonLoader = () => (
  <StyledWorkflowTimeline>
    <TimelineSteps>
      <Stack vertical>
        <SkeletonLoader height={114} width={400} />
        <SkeletonLoader height={114} width={400} />
        <SkeletonLoader height={114} width={400} />
        <SkeletonLoader fadeOut height={114} width={400} />
      </Stack>
    </TimelineSteps>
  </StyledWorkflowTimeline>
);
