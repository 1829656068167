import { ComponentProps, ReactNode } from "react";
import styled, { css } from "styled-components";

export type RoundTagProps = Pick<
  ComponentProps<"button">,
  "onClick" | "onMouseEnter" | "onMouseLeave"
> & {
  active?: boolean;
  children?: ReactNode;
  readOnly?: boolean;
  startAdornment?: ReactNode;
  textVariant?: "bold";
};

export const roundTagStyles = css`
  display: flex;
  align-items: center;
  gap: var(--spacing-xSmall);
  height: 1.625rem;
  border-radius: 1.625rem;
  padding-right: 10px;
  padding-left: 10px;
  background-color: var(--palette-background-primary);
  border: 1px solid var(--palette-border-subdued);
  font-family: var(--typography-font-text);
`;

export const roundTagHoverStyles = css`
  cursor: pointer;

  :hover {
    background-color: var(--palette-surface-dimmed);
  }
`;

const StyledRoundTag = styled.button<{ $readOnly?: boolean }>`
  ${roundTagStyles};
  ${roundTagHoverStyles};

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      cursor: default;
      background-color: var(--palette-surface-subdued);
      color: var(--palette-foreground-subdued);

      * {
        color: inherit;
      }

      svg {
        fill: var(--palette-foreground-subdued);
      }

      :hover {
        background-color: var(--palette-surface-subdued);
      }
    `}
`;

export const RoundTag = ({
  active,
  readOnly,
  startAdornment,
  children,
  ...rest
}: RoundTagProps) => {
  return (
    <StyledRoundTag
      {...rest}
      $readOnly={readOnly}
      style={{ paddingLeft: startAdornment ? 8 : undefined }}
    >
      {startAdornment && startAdornment}
      {children}
    </StyledRoundTag>
  );
};
