import {
  LabelledCheckbox,
  Modal,
  Title,
  Layout,
  Button,
  useToast,
  TextArea
} from "@introist/react-foundation/v2";
import { DateFormats } from "../../../utils/dates";
import { formatDate } from "../../../utils/DatesUtils";
import { useCallback, useState } from "react";
import { api } from "../../../services/rpc/RpcProvider";

type CancelSessionModalProps = {
  instanceId: string;
  instanceDate: string;
  open: boolean;
  onClose: () => void;
  onCanceled: () => void;
};

export const CancelSessionModal = ({
  instanceId,
  instanceDate,
  open,
  onClose,
  onCanceled
}: CancelSessionModalProps) => {
  const toast = useToast();

  const [cancelReason, setCancelReason] = useState("");
  const [deleteCalendarEvent, setDeleteCalendarEvent] = useState(true);

  const cancelSession = api.companyEvents.instances.cancel.useMutation();

  const onCancel = useCallback(async () => {
    await cancelSession
      .mutateAsync({ instanceId, deleteCalendarEvent, cancelReason })
      .then(() => {
        onCanceled();
        onClose();
      })
      .catch(() => {
        toast.error("Failed to cancel session");
      });
  }, [cancelSession, instanceId, onCanceled, deleteCalendarEvent, onClose, toast, cancelReason]);

  return (
    <Modal open={open} onClose={onClose} title="Cancel session" style={{ maxWidth: "400px" }}>
      <Layout.Group vertical gap="xxLarge">
        <Title>
          You are cancelling session on {formatDate(instanceDate, DateFormats.date.long)}. This will
          reschedule all attendees automatically.
        </Title>
        <LabelledCheckbox
          label="Delete event from calendar"
          checked={deleteCalendarEvent}
          onChange={setDeleteCalendarEvent}
        />
        <TextArea
          value={cancelReason}
          onChange={setCancelReason}
          placeholder="Reason for cancellation (optional)"
        />
        <Layout.Group gap="large" justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            Go back
          </Button>
          <Button onClickWithLoading={onCancel}>Cancel session</Button>
        </Layout.Group>
      </Layout.Group>
    </Modal>
  );
};
