import { useMemo, useRef } from "react";
import { Layout, Field, InputHandle } from "@introist/react-foundation/v2";

import { Editor, HtmlEditor, HtmlEditorPowerUps } from "components/organisms";
import { Block, TextInput } from "components/atoms";

import { EmailSender } from "services/api/Senders";

import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { EmailMessageConfig } from "modules/workflows/routes/WorkflowEditor/StepEditor/Forms/schema";
import { EmailAddressSingleSelect } from "modules/workflows/components/EmailAddressSingleSelect";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

type EmailMessageBuilderProps = {
  title?: string;
  value: EmailMessageConfig;
  onChanged: (value: EmailMessageConfig) => void;
  senders: EmailSender[];
  attributes: EmployeeAttribute[];
  hasError?: {
    senderId?: boolean;
    to?: boolean;
    subject?: boolean;
  };
};

export const EmailMessageBuilderV2 = ({
  value,
  title,
  onChanged,
  senders,
  attributes,
  hasError
}: EmailMessageBuilderProps) => {
  const messageRef = useRef<Editor>(null);
  const subjectRef = useRef<InputHandle>(null);

  const addVariable = (variable: EmployeeAttribute) => {
    const definedVariable = `{{${variable.variable}}}`;

    if (messageRef.current !== null && messageRef.current.hasFocus()) {
      messageRef.current.insertUserPropertyToCursorPosition(variable);
      messageRef.current.insertTextToCursorPosition(" ");
    }
    if (subjectRef.current !== null && subjectRef.current.hasFocus()) {
      subjectRef.current.insertTextToCursorPosition(definedVariable);
      subjectRef.current.insertTextToCursorPosition(" ");
    }
  };

  const senderOptions = useMemo(
    () =>
      senders.map(sender => ({
        key: sender.id,
        title: sender.name,
        description: sender.email
      })),
    [senders]
  );

  const initialSelectedSenderOption = useMemo(() => {
    if (!value.senderId) return;

    return senderOptions.find(({ key }) => key === value.senderId);
  }, [value.senderId, senderOptions]);

  const [receiverOptions, initialSelectedReceiverOption] = useMemo(() => {
    const attributeReceivers = attributes.map(attr => ({
      key: attr.variable,
      title: attr.name
    }));

    if (!value.to) {
      return [attributeReceivers, undefined];
    } else {
      const attributeReceiver = attributeReceivers.find(({ key }) => key === value.to);
      if (attributeReceiver) {
        return [attributeReceivers, attributeReceiver];
      } else {
        const staticReceiver = { key: value.to, title: value.to };
        return [[...attributeReceivers, staticReceiver], staticReceiver];
      }
    }
  }, [attributes, value.to]);

  const showAccordionError = hasError?.senderId || hasError?.subject || hasError?.to;

  return (
    <Block blended compact>
      <StepEditorAccordion
        title={title}
        defaultOpen={false}
        stepOrder={3}
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Layout.Group vertical gap="xLarge">
          <Layout.Group vertical>
            <StepEditorAccordion.Row label="From">
              {senderOptions.length > 0 && (
                <EmailAddressSingleSelect
                  initialOptions={senderOptions}
                  initialSelectedOption={initialSelectedSenderOption}
                  error={hasError?.senderId ? "Required" : undefined}
                  onChange={senderId => {
                    onChanged({ ...value, senderId: senderId || "" });
                  }}
                />
              )}
            </StepEditorAccordion.Row>
            <StepEditorAccordion.Row label="To">
              {receiverOptions.length > 0 && (
                <EmailAddressSingleSelect
                  initialOptions={receiverOptions}
                  initialSelectedOption={initialSelectedReceiverOption}
                  error={hasError?.to ? "Required" : undefined}
                  onChange={receiverId => {
                    onChanged({ ...value, to: receiverId || "" });
                  }}
                  onEnter={receiverId => {
                    onChanged({ ...value, to: receiverId || "" });
                  }}
                />
              )}
            </StepEditorAccordion.Row>
          </Layout.Group>
          <Field title="Subject">
            <TextInput
              inputHandleRef={subjectRef}
              size="small"
              placeholder="Email subject"
              value={value.subject}
              onChange={subject => onChanged({ ...value, subject })}
              error={!!hasError?.subject}
            />
          </Field>
          <Field title="Message">
            <HtmlEditor
              ref={messageRef}
              defaultValue={value.message}
              onChange={message => onChanged({ ...value, message })}
              editorConfigurations={{ disableMaterial: true }}
            />
          </Field>
          <HtmlEditorPowerUps onAttributeSelected={addVariable} />
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
