import { useMemo } from "react";
import { Card, H4, Layout } from "@introist/react-foundation/v2";
import styled from "styled-components";

type Props = {
  asCard?: boolean;
};

const StyledCard = styled(Card)`
  background-color: var(--palette-surface-subdued);
`;

export const GenericError = ({ asCard, ...rest }: Props) => {
  const content = useMemo(() => {
    return (
      <Layout.Group vertical alignItems="center">
        <div>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.09716 1.61591C4.51753 1.61591 1.61566 4.51778 1.61566 8.09741C1.61566 11.6771 4.51753 14.5789 8.09716 14.5789C11.6768 14.5789 14.5787 11.6771 14.5787 8.09741C14.5787 4.51778 11.6768 1.61591 8.09716 1.61591ZM0.097168 8.09741C0.097168 3.67913 3.67889 0.0974121 8.09716 0.0974121C12.5154 0.0974121 16.0972 3.67913 16.0972 8.09741C16.0972 12.5157 12.5154 16.0974 8.09716 16.0974C3.67889 16.0974 0.097168 12.5157 0.097168 8.09741Z"
              fill="black"
            />
            <path
              d="M5.89267 6.32165C6.36647 6.32165 6.75057 5.93755 6.75057 5.46374C6.75057 4.98992 6.36647 4.60582 5.89267 4.60582C5.41886 4.60582 5.03476 4.98992 5.03476 5.46374C5.03476 5.93755 5.41886 6.32165 5.89267 6.32165Z"
              fill="black"
            />
            <path
              d="M10.3023 6.32165C10.7762 6.32165 11.1603 5.93755 11.1603 5.46374C11.1603 4.98992 10.7762 4.60582 10.3023 4.60582C9.82853 4.60582 9.44444 4.98992 9.44444 5.46374C9.44444 5.93755 9.82853 6.32165 10.3023 6.32165Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9972 11.5034C10.5779 11.5034 10.2379 11.1635 10.2379 10.7441C10.2379 9.55917 9.28242 8.60362 8.09746 8.60362C6.91248 8.60362 5.95698 9.55917 5.95698 10.7441C5.95698 11.1635 5.61704 11.5034 5.19774 11.5034C4.77843 11.5034 4.43849 11.1635 4.43849 10.7441C4.43849 8.72055 6.07385 7.08512 8.09746 7.08512C10.1211 7.08512 11.7564 8.72055 11.7564 10.7441C11.7564 11.1635 11.4165 11.5034 10.9972 11.5034Z"
              fill="black"
            />
          </svg>
        </div>
        <H4>Something went wrong</H4>
      </Layout.Group>
    );
  }, []);

  if (asCard) {
    return (
      <StyledCard variant="blended" spacing="xLarge">
        {content}
      </StyledCard>
    );
  }

  return content;
};
