import { Card, Stack, Title, Tooltip } from "@introist/react-foundation/v2";
import { Empty, ListView, OrderableList, useSearch } from "../../../../foundation";
import { useFormFieldActions, useFormFields } from "../../useFormFields";
import { FormFieldIcon } from "./FormFieldIcon";

interface FormFieldListProps {
  formId: string;
  onCreateField: () => unknown;
  onOpenField: (id: string) => unknown;
}

export const FormFieldList = ({ formId, onCreateField, onOpenField }: FormFieldListProps) => {
  const { formFields } = useFormFields(formId);
  const { reorder } = useFormFieldActions();

  const { filtered, search, setSearch } = useSearch(formFields, {
    fields: ["title", "description"]
  });

  return (
    <Stack vertical>
      <ListView.Header
        title="Fields"
        count={filtered?.length}
        onCreate={onCreateField}
        search={{ search, setSearch }}
      />
      {formFields && formFields.length === 0 && (
        <Empty icon="list" title="No Fields" message="Create new Field or try a different search" />
      )}
      <OrderableList
        onReorder={fields =>
          reorder(
            formId,
            fields.map(f => f.id)
          )
        }
        data={filtered}
        renderRow={field => (
          <Card onClick={() => onOpenField(field.id)}>
            <Stack>
              <FormFieldIcon fieldType={field.type} />
              <Title variant="bold">{field.title}</Title>
              {field.required && (
                <Tooltip tooltip="This field is required">
                  <Title>*</Title>
                </Tooltip>
              )}
            </Stack>
          </Card>
        )}
      />
    </Stack>
  );
};
