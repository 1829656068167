import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";

export const SlackChannelMessageViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Channel">
          <StepEditorAccordion.Row label="From">
            <Stack>
              {resolvedData.isBot ? <Icon name="robot" /> : <Icon name="person" />}
              <Title variant="bold">{resolvedData.resolvedSenderName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <Stack>
              <Icon name="hashtag" />
              <Title variant="bold">{resolvedData.channel}</Title>
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage!.html} />
      </Block>
    </>
  );
};
