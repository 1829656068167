import { useCallback } from "react";
import styled from "styled-components";
import { FileRejection, useDropzone } from "react-dropzone";
import { Icon, IconName, Layout, Title, useToast } from "@introist/react-foundation/v2";

import { FileType, useFileUploadRestrictions } from "./hooks/useFileUploadRestrictions";
import { Dot } from "components/atoms";

type IFileUploadProps = {
  icon?: IconName;
  title?: string;
  restrictions?: string[];
  onFileUploaded: (files: File[]) => unknown;
  maxSize?: number;
  maxFiles?: number;
  disabled?: boolean;
  acceptedFileTypes?: FileType[];
};

const Root = styled(Layout.Group)<{ $disabled?: boolean }>`
  border: 1px dashed var(--palette-border-subdued);
  padding: var(--spacing-large) var(--spacing-xLarge);
  background: var(--palette-surface-subdued);
  border-radius: var(--rounding-medium);
  user-select: none;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
`;

export const FileUpload = ({
  icon,
  title,
  onFileUploaded,
  maxSize = 5 * 1024 * 1024,
  maxFiles = 5,
  disabled,
  restrictions,
  acceptedFileTypes = []
}: IFileUploadProps) => {
  const toast = useToast();
  const { accept } = useFileUploadRestrictions({ maxSize, acceptedFileTypes, maxFiles });

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles && rejectedFiles.length > 0) {
        toast.error(
          `Failed to upload files: ${rejectedFiles.map(file => file.file.name).join(", ")}`
        );
      }
      if (acceptedFiles && acceptedFiles.length > 0) {
        onFileUploaded(acceptedFiles);
      }
    },
    [toast, onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    accept,
    disabled
  });

  return (
    <Root
      {...getRootProps()}
      vertical
      justifyContent="center"
      alignItems="center"
      $disabled={disabled}
    >
      <input {...getInputProps()} />

      <Layout.Group>
        <Icon name={icon || "upload"} />
        {title && <Title variant="bold">{title}</Title>}
      </Layout.Group>

      {restrictions && restrictions?.length > 0 && (
        <Layout.Group gap="small" vertical justifyContent="center" alignItems="center">
          {restrictions.map(restriction => (
            <Layout.Group key={`file-upload-restriction--${restriction}`} gap="small">
              <Dot />
              <Title>{restriction}</Title>
            </Layout.Group>
          ))}
        </Layout.Group>
      )}
    </Root>
  );
};
