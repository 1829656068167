import { ComponentProps } from "react";

import { ReactComponent as Logo } from "./dimmed-introist-logo.svg";
import styled from "styled-components";

export const ViewFrame = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background-color: var(--palette-surface-dimmed);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xxLarge);
  box-sizing: border-box;
`;

const PoweredBy = styled.div`
  margin-top: var(--spacing-xxLarge);
  color: var(--palette-foreground-dimmed);
  font-size: 13px;
  font-family: var(--typography-font-text);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-medium);
`;

export interface GlobalViewProps extends ComponentProps<"div"> {}

export const GlobalView = ({ children }: GlobalViewProps) => {
  return (
    <ViewFrame>
      <Content>
        {children}
        <PoweredBy>
          Powered by <Logo />
        </PoweredBy>
      </Content>
    </ViewFrame>
  );
};
