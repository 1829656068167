import { postJson } from "../../services/api/ApiBase";

export interface PublicAccountConnectDetails {
  authorizationUri: string;
  workspace: { name: string; logo: string };
}

export const startPublicAccountConnect = (
  workspaceToken: string,
  provider: string
): Promise<PublicAccountConnectDetails> => {
  return postJson("/connect/start", { workspaceToken, provider });
};

export const completePublicAccountConnect = (code: string, state: string) => {
  return postJson("/connect/complete", { code, state });
};

export interface SubAccountCreate {
  name: string;
  email: string;
}

export const saveSubAccount = (accountId: string, subAccount: SubAccountCreate) => {
  return postJson(`/connectedaccounts/${accountId}/subaccount`, subAccount);
};
