import { useMemo } from "react";
import countryTz from "countries-and-timezones";
import { api } from "services/rpc/RpcProvider";

export const useDefaultCountry = () => {
  const { data: workspace } = api.workspaces.find.useQuery({});
  const defaultCountry = useMemo(() => {
    if (!workspace) return null;
    const timezone = countryTz.getCountriesForTimezone(workspace.defaultTimezone);
    if (timezone.length > 0) return timezone[0].id;
    return "FI";
  }, [workspace]);

  return defaultCountry;
};
