import { Card, Icon, IconButton, Layout, Title } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import styled from "styled-components";

type FileBaseProps = {
  title: string;
  children?: ReactNode;
  onRemove?: () => void;
  onClick?: () => void;
};

const StyledFileBase = styled(Card)`
  padding: var(--spacing-small);
`;

const StyledIconButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  && {
    padding: 0 !important;
  }

  > :first-child {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
  }
`;

export const FileBase = ({ title, children, onRemove, onClick, ...rest }: FileBaseProps) => {
  return (
    <StyledFileBase onClick={onClick}>
      <Layout.Group {...rest} justifyContent="space-between">
        <Layout.Group gap="medium" style={{ flex: 1 }}>
          <Icon name="paperclip" />
          <Title>{title}</Title>

          {children}
        </Layout.Group>
        {onRemove && (
          <StyledIconButton
            icon="crossSmall"
            onClick={event => {
              event.stopPropagation();
              onRemove();
            }}
          />
        )}
      </Layout.Group>
    </StyledFileBase>
  );
};
