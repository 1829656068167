import { WorkflowStepAction } from "services/rpc/RpcProvider";
import { z } from "zod";

const EmailMessageResultSchema = z.object({
  message: z.string()
});

export type EmailMessageResult = z.infer<typeof EmailMessageResultSchema>;

export type AIAssistantResult = EmailMessageResult | WorkflowStepAction;

export enum ContentType {
  EmailMessage = "Email Message",
  EmailSubject = "Email Subject",
  SlackMessage = "Slack Message",
  WorkflowSteps = "Workflow Steps",
  MeetingDescription = "Meeting Description"
}
