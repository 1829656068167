import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const GoogleDriveCsvConnectForm = ({ values, onChange }: ConnectFormProps) => {
  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.fileId}
        label="Google Drive CSV File ID"
        onChange={fileId =>
          onChange({
            credentials: {
              fileId
            }
          })
        }
      />
    </Layout.Group>
  );
};
