import { compact } from "lodash";
import { ActionMenu, useActions } from "@introist/react-foundation/v2";
import { Batch, useBatchActions } from "../../useBatches";
import { BatchForm } from "./BatchForm";
import { UpsertModal } from "../../../../foundation";
import { useState } from "react";
import { useNavigate } from "react-router";

export const BatchActionMenu = ({ batch }: { batch: Batch }) => {
  const { onConfirmAction } = useActions();
  const navigate = useNavigate();

  const [editOpen, setEditOpen] = useState(false);

  const { start, refresh, update, remove, cancel } = useBatchActions();

  return (
    <>
      <ActionMenu
        options={compact([
          batch.stage === "draft"
            ? {
                key: "start",
                title: "Start batch",
                startAdornmentIcon: "playCircle",
                onClick: () => start(batch.id)
              }
            : null,
          batch.stage === "active" && {
            key: "refresh",
            title: "Refresh batch",
            startAdornmentIcon: "reconnect",
            onClick: () => refresh(batch.id)
          },
          {
            key: "modify",
            title: "Edit batch",
            startAdornmentIcon: "pencil",
            onClick: () => setEditOpen(true)
          },
          batch.stage === "active" && {
            key: "cancel",
            title: "Cancel batch",
            startAdornmentIcon: "crossCircle",
            onClick: onConfirmAction(
              async () => {
                await cancel(batch.id);
              },
              {
                title: "Cancel batch",
                description:
                  "This will cancel all remaining automations and can not be undone. Are you sure to cancel the batch?"
              }
            )
          },
          batch.stage === "draft"
            ? {
                key: "delete",
                title: "Delete batch",
                startAdornmentIcon: "trash",
                onClick: onConfirmAction(
                  async () => {
                    await remove(batch.id);
                    navigate("/batches", { replace: true });
                  },
                  {
                    title: "Delete Batch",
                    description: "Do you really want to delete this Batch? This can not be undone."
                  }
                )
              }
            : null
        ])}
      />
      <UpsertModal
        open={editOpen}
        entry={batch}
        onClose={() => setEditOpen(false)}
        onCreate={async () => {
          throw new Error("Not implemented");
        }}
        onUpdate={async data => {
          await update({ id: batch.id, update: data });
        }}
        Form={BatchForm}
        entryName="batch"
      />
    </>
  );
};
