import {
  ActionMenu,
  Button,
  Card,
  ConfirmationModal,
  H4,
  Icon,
  IconButton,
  Option,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { AttributeIcon } from "../../../../components/atoms";
import { api, DataSourceMappingEntry } from "../../../../services/rpc/RpcProvider";
import { useCallback, useMemo, useState } from "react";
import { MappingEditorModal } from "./MappingEditorForm";
import { useEmployeeFields } from "../../hooks/useEmployeeFields";
import { TransformEditorModal } from "./TransformEditor";
import { DataSourceFieldsModal } from "../../containers/DataSourceFieldsModal";

type DataSourceMappingViewProps = {
  dataSourceId: string;
};

export const DataSourceMappingView = ({ dataSourceId }: DataSourceMappingViewProps) => {
  const toast = useToast();

  const { data: mappings, refetch: refetchMappings } =
    api.employees.dataSources.mappings.list.useQuery({ dataSourceId });

  const remove = api.employees.dataSources.mappings.delete.useMutation();

  const [editMapping, setEditMapping] = useState<string | undefined>();
  const [editTransform, setEditTransform] = useState<string | undefined>();
  const [removeMapping, setRemoveMapping] = useState<string | undefined>();

  const [fieldsOpen, setFieldsOpen] = useState(false);

  const onRemove = useCallback(async () => {
    await remove.mutateAsync({ id: removeMapping! }).then(async () => {
      await refetchMappings();
      toast.success("Mapping removed");
      setRemoveMapping(undefined);
    });
  }, [removeMapping, refetchMappings, remove, toast]);

  const header = (
    <Stack justifyContent="space-between">
      <Stack>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setEditMapping("new")}
          startIcon="plus"
        >
          Add mapping
        </Button>
        <Button variant="blended" size="small" onClick={() => setFieldsOpen(true)}>
          Show fields
        </Button>
      </Stack>
    </Stack>
  );

  if (!mappings) return null;

  return (
    <Stack vertical>
      <Card>
        <Stack vertical gap="xLarge">
          <Stack vertical gap="small">
            <Stack gap="small">
              <AttributeIcon ghosted />
              <H4>Data mapping</H4>
            </Stack>
          </Stack>
          {header}
          <Stack vertical>
            {mappings.map(mapping => (
              <DataSourceMappingCard
                key={mapping.id}
                mapping={mapping}
                onEdit={() => setEditMapping(mapping.id)}
                onEditTransform={() => setEditTransform(mapping.id)}
                onRemove={() => setRemoveMapping(mapping.id)}
              />
            ))}
            {mappings.length === 0 && (
              <Title style={{ alignSelf: "center", margin: "2rem 0" }}>
                No fields mapped from data source
              </Title>
            )}
          </Stack>
        </Stack>
      </Card>

      <ConfirmationModal
        show={!!removeMapping}
        onClose={() => setRemoveMapping(undefined)}
        onCancel={() => setRemoveMapping(undefined)}
        onConfirm={onRemove}
        config={{
          title: "Remove mapping",
          description: "Are you sure you want to remove this mapping?",
          confirmTitle: "Remove",
          cancelTitle: "Cancel"
        }}
      />

      <MappingEditorModal
        dataSourceId={dataSourceId}
        defaultValue={mappings.find(m => m.id === editMapping)}
        open={!!editMapping}
        onClose={() => setEditMapping(undefined)}
      />
      {editTransform && (
        <TransformEditorModal
          dataSourceId={dataSourceId}
          mappingEntryId={editTransform}
          defaultValue={mappings.find(m => m.id === editTransform)!.transform ?? undefined}
          open={!!editTransform}
          onClose={() => setEditTransform(undefined)}
        />
      )}
      <DataSourceFieldsModal
        dataSourceId={dataSourceId}
        open={fieldsOpen}
        onClose={() => setFieldsOpen(false)}
      />
    </Stack>
  );
};

const DataSourceMappingCard = ({
  mapping,
  onRemove,
  onEdit,
  onEditTransform
}: {
  mapping: DataSourceMappingEntry;
  onEdit: () => void;
  onEditTransform: () => void;
  onRemove: () => void;
}) => {
  const fields = useEmployeeFields();

  const actions = useMemo(() => {
    const actions: Option[] = [];

    if (!mapping.transform) {
      actions.push({
        key: "transform",
        title: "Add transformation",
        startAdornmentIcon: "workflowEditor",
        onClick: onEditTransform
      });
    }

    actions.push({
      key: "edit",
      title: "Edit mapping",
      startAdornmentIcon: "pencil",
      onClick: onEdit
    });
    actions.push({
      key: "delete",
      title: "Remove mapping",
      startAdornmentIcon: "trash",
      onClick: onRemove
    });

    return actions;
  }, [mapping, onRemove, onEdit, onEditTransform]);

  return (
    <Card>
      <Stack style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr auto" }}>
        <Title>{mapping.sourceField}</Title>
        <Stack>
          <Icon name="arrowRight" />
          {mapping.transform && (
            <>
              <IconButton size="large" icon="workflowEditor" onClick={onEditTransform} />
              <Icon name="arrowRight" />
            </>
          )}
        </Stack>
        <Title variant="bold" style={{ justifySelf: "flex-end" }}>
          {fields.find(f => f.variable === mapping.targetField)?.name ?? mapping.targetField}
        </Title>
        <ActionMenu options={actions} />
      </Stack>
    </Card>
  );
};
