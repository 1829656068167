import { useCallback, useEffect, useState } from "react";
import { Button, H4, Input, Layout, Title, useToast } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/introist-white.svg";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Microsoft } from "../assets/microsoft.svg";
import { loginWithProvider } from "../services/firebase";
import styles from "./Login.module.scss";
import styled from "styled-components";
import { clearSelectedWorkspace } from "../services/workspace";
import { loadSsoInfo, startSso } from "../services/api/UserApi";

const GoogleButton = styled(Button)`
  display: flex;
  align-items: center;
`;
export const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [loginWithSSO, setLoginWithSSO] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    clearSelectedWorkspace();
    setLoginWithSSO(window.location.hash === "#sso");
  }, []);

  const loginWithPlatform = async (providerName: "google" | "microsoft") => {
    try {
      const user = await loginWithProvider(providerName).catch(error => alert(error.message));
      if (!user) return;
      navigate("/");
    } catch (e) {
      toast.error("Login failed");
    }
  };

  const initiateSSO = useCallback(async () => {
    setLoading(true);
    try {
      const info = await loadSsoInfo(email);
      startSso(info.tenant);
    } catch (e) {
      setError(true);
      setLoading(false);
      toast.error("Failed to start SSO login");
    }
  }, [email, toast]);

  return (
    <div className={styles.LoginView}>
      <div className={styles.LoginContainer}>
        <div className={styles.LoginCard}>
          <div className={styles.CardEffect} />
          <div className={styles.LogoContainer}>
            <Logo style={{ width: "120px", height: "100%" }} />
          </div>

          {!loginWithSSO && (
            <>
              <GoogleButton
                size="large"
                variant="outlined"
                className={styles.GoogleButton}
                onClick={() => loginWithPlatform("google")}
                startAdornment={<Google style={{ marginRight: -8 }} />}
              >
                Sign in with Google
              </GoogleButton>
              <Button
                size="large"
                variant="outlined"
                className={styles.GoogleButton}
                startAdornment={<Microsoft style={{ marginRight: 8 }} />}
                onClick={() => loginWithPlatform("microsoft")}
              >
                Sign in with Microsoft
              </Button>

              <Title>or</Title>

              <Button
                size="large"
                variant="outlined"
                className={styles.GoogleButton}
                onClick={() => setLoginWithSSO(true)}
              >
                Sign in with SSO
              </Button>
            </>
          )}

          {loginWithSSO && (
            <Layout.Group vertical gap="xLarge">
              <H4>Sign in with SSO</H4>

              <Input
                autoFocus
                value={email}
                onChange={val => {
                  setEmail(val);
                  setError(false);
                }}
                onKeyUp={e => {
                  if (e.key === "Enter") initiateSSO();
                }}
                error={error}
                placeholder="Your email"
                style={{ width: "200px" }}
              />
              <Layout.Group vertical>
                <Button loading={loading} onClick={initiateSSO}>
                  Start
                </Button>
                <Button variant="blended" onClick={() => setLoginWithSSO(false)}>
                  Back
                </Button>
              </Layout.Group>
            </Layout.Group>
          )}
        </div>
      </div>
    </div>
  );
};
