export interface AppConfig {
  baseDomain: string;
  appBaseUrl: string;
  apiUrl: string;
  handbookApiUrl: string;
  editorUrl: string;
  authLinkUrl: string;
  giphyApiKey: string;
  hotjarId?: number;
  ga4Id?: string;
  authSsoUrl: string;
}

const apiUrl: { [key: string]: string } = {
  prod: "https://api.gcp.introist.com",
  test: "https://api.test.gcp.introist.com",
  dev: "http://localhost:3100"
};

const editorUrl = {
  prod: "https://editor.introist.com",
  test: "https://editor.test.introist.com",
  dev: "http://localhost:3002"
};

export const isDev = !process.env.REACT_APP_ENV;
export const isProduction = process.env.REACT_APP_ENV === "production";
export const devEnv: string | undefined = process.env.REACT_APP_DEV_ENV;

export const envConfig: {
  [index: string]: AppConfig;
} = {
  development: {
    baseDomain: "localhost",
    appBaseUrl: "http://localhost:3000",
    authLinkUrl: "http://localhost:3000/auth/link",
    apiUrl: isDev && devEnv ? apiUrl[devEnv] : apiUrl.test,
    giphyApiKey: "bAgwR0XLVI34pPo2imOCsEkDCS52MBT3",
    handbookApiUrl: "https://handbooks.test.introist.com",
    editorUrl: editorUrl.dev,
    authSsoUrl: `${apiUrl.dev}/v1/sso/`
  },
  test: {
    baseDomain: "test.introist.com",
    appBaseUrl: "https://app.test.introist.com",
    authLinkUrl: "https://app.test.introist.com/auth/link",
    apiUrl: apiUrl.test,
    giphyApiKey: "bAgwR0XLVI34pPo2imOCsEkDCS52MBT3",
    handbookApiUrl: "https://handbooks.test.introist.com",
    editorUrl: editorUrl.test,
    authSsoUrl: `https://auth.test.introist.com/v1/sso/`
  },
  staging: {
    baseDomain: "introist.com",
    appBaseUrl: "https://app.staging.introist.com",
    authLinkUrl: "https://app.staging.introist.com/auth/link",
    apiUrl: apiUrl.prod,
    giphyApiKey: "bAgwR0XLVI34pPo2imOCsEkDCS52MBT3",
    ga4Id: "G-RWEHDZRWRS",
    handbookApiUrl: "https://handbooks.introist.com",
    editorUrl: editorUrl.prod,
    authSsoUrl: `https://auth.introist.com/v1/sso/`
  },
  production: {
    baseDomain: "introist.com",
    appBaseUrl: "https://app.introist.com",
    authLinkUrl: "https://app.introist.com/auth/link",
    apiUrl: apiUrl.prod,
    giphyApiKey: "bAgwR0XLVI34pPo2imOCsEkDCS52MBT3",
    ga4Id: "G-RWEHDZRWRS",
    handbookApiUrl: "https://handbooks.introist.com",
    editorUrl: editorUrl.prod,
    authSsoUrl: `https://auth.introist.com/v1/sso/`
  }
};

export const config = envConfig[process.env.REACT_APP_ENV || "development"];

export const envFirebaseConfig: { [index: string]: object } = {
  development: {
    apiKey: "AIzaSyDw2lDs98MEBKbTzI9CnEM8ZxX2MXZM_wY",
    authDomain: "introist-test.firebaseapp.com",
    databaseURL: "https://introist-test.firebaseio.com",
    projectId: "introist-test",
    storageBucket: "introist-test.appspot.com",
    messagingSenderId: "490240879278",
    appId: "1:490240879278:web:7f689f22b2ffd3039d5b3f"
  },
  test: {
    apiKey: "AIzaSyDw2lDs98MEBKbTzI9CnEM8ZxX2MXZM_wY",
    authDomain: "introist-test.firebaseapp.com",
    databaseURL: "https://introist-test.firebaseio.com",
    projectId: "introist-test",
    storageBucket: "introist-test.appspot.com",
    messagingSenderId: "490240879278",
    appId: "1:490240879278:web:7f689f22b2ffd3039d5b3f"
  },
  staging: {
    apiKey: "AIzaSyAfju57hc1NifQLWU_IkFQJE_pm9EgnnsY",
    authDomain: "app.introist.com",
    databaseURL: "https://introist-prod.firebaseio.com",
    projectId: "introist-prod",
    storageBucket: "introist-prod.appspot.com",
    messagingSenderId: "455245726481",
    appId: "1:455245726481:web:6b75984456cc6b964342b0"
  },
  production: {
    apiKey: "AIzaSyAfju57hc1NifQLWU_IkFQJE_pm9EgnnsY",
    authDomain: "app.introist.com",
    databaseURL: "https://introist-prod.firebaseio.com",
    projectId: "introist-prod",
    storageBucket: "introist-prod.appspot.com",
    messagingSenderId: "455245726481",
    appId: "1:455245726481:web:6b75984456cc6b964342b0"
  }
};

export const firebaseConfig = envFirebaseConfig[process.env.REACT_APP_ENV || "development"];
