import { TopBarRoute } from "../../components/templates";
import { useReport, useReports } from "./useReports";
import { useEffect, useMemo } from "react";
import {
  Select,
  Stack,
  Card,
  Title,
  H3,
  Table,
  TableCard,
  Tooltip,
  Field
} from "@introist/react-foundation/v2";
import { useNavigate, useParams } from "react-router";
import { SearchInput, Skeletons } from "../../components/molecules";
import { Empty, useSearch } from "../../foundation";
import { ExportData } from "../employees/containers/ExportData";
import styled from "styled-components";

const FullTable = styled(Table)`
  td {
    max-width: initial !important;
  }
`;

const EllipsisTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReportsRoute = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { reports } = useReports();

  const { report, error } = useReport(id ?? "", { enabled: !!id });

  const { search, setSearch, filtered } = useSearch(report?.table ?? [], {
    fields: report?.table && report?.table.length > 0 ? Object.keys(report.table[0]) : []
  });

  useEffect(() => {
    if (!id && reports && reports.length > 0) {
      navigate(`/reports/${reports[0].id}`);
    }
  }, [id, reports, navigate]);

  const tableColumns = useMemo(() => {
    if (!report) return null;
    if (!report.table) return null;
    if (report.table.length === 0) return null;

    return Object.keys(report.table[0]).map(title => ({ key: title, title, sortable: true }));
  }, [report]);

  return (
    <TopBarRoute title="Reports" medium>
      {!reports && <Skeletons.List />}
      {reports && (
        <Stack vertical gap="xLarge">
          <Stack justifyContent="space-between">
            <Field title="Report">
              <Select
                placeholder="Select report"
                options={(reports ?? []).map(r => ({ key: r.id, title: r.title }))}
                value={id}
                onSelect={opt => navigate(`/reports/${opt.key}`)}
              />
            </Field>
            {report && report.table && (
              <ExportData
                data={report.table}
                title="Export report"
                tooltip="Export report data to Excel"
                fileName={report.title + ".xls"}
              />
            )}
          </Stack>

          {error && (
            <Empty
              title="Failed to load report"
              icon="crossCircle"
              message="Ask support to check the report"
            />
          )}
          {report && report.metrics && (
            <Stack>
              {Object.entries(report.metrics).map(([title, value]) => (
                <Card key={title}>
                  <Stack
                    vertical
                    alignItems="center"
                    style={{ maxWidth: "250px", minWidth: "150px" }}
                  >
                    <Tooltip tooltip={title}>
                      <EllipsisTitle>{title}</EllipsisTitle>
                    </Tooltip>

                    <H3>{value as string}</H3>
                  </Stack>
                </Card>
              ))}
            </Stack>
          )}
          {tableColumns && (
            <>
              <SearchInput
                value={search}
                onChange={setSearch}
                style={{ width: "300px", marginLeft: "auto" }}
              />
              <TableCard style={{ overflowX: "auto" }}>
                <FullTable columns={tableColumns} data={filtered} />
              </TableCard>
            </>
          )}
        </Stack>
      )}
    </TopBarRoute>
  );
};
