import { ComponentProps, useMemo } from "react";
import { Title, useTheme } from "@introist/react-foundation/v2";
import { WorkspaceOverview } from "../../services/api/UserApi";

type Props = ComponentProps<"div"> & {
  workspace?: WorkspaceOverview;
};

export const OrganisationLogo = ({ workspace, style, ...rest }: Props) => {
  const { theme } = useTheme();

  const { logo, name } = useMemo(() => {
    return {
      logo: workspace?.logo ?? "",
      name: workspace?.name ?? ""
    };
  }, [workspace]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: !!logo ? `url(${logo})` : undefined,
        backgroundColor: !logo ? theme.palette.primary.default : undefined,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style
      }}
      {...rest}
    >
      {!logo && (
        <Title variant="bold" color={theme.palette.background.default}>
          {name.slice(0, 1)}
        </Title>
      )}
    </div>
  );
};
