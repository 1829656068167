import { StepTypeIcon } from "modules/workflows/components/steps";
import styled, { css } from "styled-components";
import { Tooltip } from "@introist/react-foundation/v2";
import { WorkflowStepType } from "../stepTypes";
import { MediumTag } from "../../../components/atoms";

type Props = {
  stepType: WorkflowStepType;
  compact?: boolean;
};

const StyledStepTypeTag = styled(MediumTag)<{ $compact?: boolean }>`
  ${({ $compact }) =>
    $compact &&
    css`
      padding: 0.25rem;
      h5 {
        display: none;
      }
    `}
`;

export const StepTypeTag = ({ stepType, compact = false, ...rest }: Props) => {
  const renderTag = () => (
    <StyledStepTypeTag
      {...rest}
      backgroundColor="transparent"
      startAdornment={
        stepType?.icon ?? <StepTypeIcon compact variant="blended" stepType={stepType.stepType} />
      }
      $compact={compact}
    >
      {stepType.title}
    </StyledStepTypeTag>
  );

  return compact ? (
    <Tooltip tooltip={stepType.title} {...rest}>
      {renderTag()}
    </Tooltip>
  ) : (
    renderTag()
  );
};
