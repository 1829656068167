import { useIdentity } from "../IdentityProvider";
import { api } from "../services/rpc/RpcProvider";
import { useMemo } from "react";

export const useFeatureFlag = (featureName: string) => {
  const { user } = useIdentity();

  const { data: flags } = api.app.featureFlags.list.useQuery({});

  const allFlags = useMemo(() => {
    return [...(user?.featureFlags ?? []), ...(flags ?? [])];
  }, [flags, user]);

  return {
    isEnabled: allFlags.includes(featureName)
  };
};
