import { Drawer, IconButton, Stack, useTheme } from "@introist/react-foundation/v2";
import { EmployeeProfileForm } from "./EmployeeProfileForm";
import { EmployeeHeader } from "./EmployeeHeader";
import { useState } from "react";
import { Employee, useEmployee } from "../../hooks/useEmployees";
import { get, isEmpty } from "lodash";
import { UnsavedChanges } from "../../../../components/molecules";
import { useNavigate } from "react-router";
import { EmployeeProfile } from "./EmployeeProfile";

export type EmployeeProfileDrawerProps = {
  open?: boolean;
  onClose: () => void;
};

export const NewEmployeeDrawer = ({ open, onClose }: EmployeeProfileDrawerProps) => {
  return (
    <Drawer open={open} onClose={onClose} width="650px" position="right" lockScroll>
      {open && <NewEmployee onClose={onClose} />}
    </Drawer>
  );
};

const NewEmployee = ({ onClose }: EmployeeProfileDrawerProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const { create } = useEmployee();

  const [employee, setEmployee] = useState<Employee>({});
  const [errors, setErrors] = useState<any[]>([]);

  return (
    <Stack vertical style={{ padding: theme.spacing.large }}>
      <Stack justifyContent="space-between">
        <EmployeeHeader employee={employee} />
        <IconButton icon="crossSmall" onClick={onClose} />
      </Stack>
      <EmployeeProfileForm
        employee={employee}
        onChange={(field, value) => {
          setEmployee({ ...employee, [field]: value });
          setErrors(errors.filter(e => e.attribute !== field));
        }}
        errors={errors}
      />
      <UnsavedChanges
        show={!isEmpty(employee)}
        saveTitle="Create"
        onSave={() =>
          create(employee)
            .then(res => navigate(`/employees/${res.id}`))
            .catch(err => {
              setErrors(get(err, "data.introistError.data"));
            })
        }
        style={{ position: "sticky", bottom: "1rem", left: "calc(50% - 125px)", width: "250px" }}
      />
    </Stack>
  );
};

export const EmployeeProfileDrawer = ({
  id,
  open,
  onClose
}: EmployeeProfileDrawerProps & { id: string }) => {
  return (
    <Drawer open={open} onClose={onClose} width="650px" position="right" lockScroll>
      {open && <EmployeeProfile id={id} />}
    </Drawer>
  );
};
