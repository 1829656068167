import { useCallback, useEffect, useState } from "react";
import { CircularLoader, Title } from "@introist/react-foundation/v2";
import queryString from "query-string";
import { useLocation, useParams } from "react-router";

import { loadConnectUrl } from "./api";
import { completeCredentialConnection } from "services/api/IntegrationsApi";

import styles from "./ConnectCredentialsRoute.module.scss";

type ConnectCredentialsRouteProps = {
  mode: "initiate" | "complete";
};

export const ConnectCredentialsRoute = ({ mode }: ConnectCredentialsRouteProps) => {
  const { search } = useLocation();
  const { integrationId } = useParams();

  const [status, setStatus] = useState("loading");

  const handleInitiateCredentialsConnection = useCallback(() => {
    const redirectUrl = window.location.href.replace("initiate", "connect");

    if (!integrationId) return;

    loadConnectUrl(integrationId, redirectUrl)
      .then(res => window.location.assign(res.url))
      .catch(() => setStatus("failure"));
  }, [integrationId]);

  const handleCompleteCredentialsConnection = useCallback(() => {
    const { code } = queryString.parse(search);
    const credentials = {
      hash: window.location.hash,
      authorizationCode: code as string,
      redirectUrl: window.location.href.split("?")[0]
    };

    if (!integrationId) return;

    completeCredentialConnection({ integrationId, credentials })
      .then(() => {
        setStatus("success");
        window.close();
      })
      .catch(() => setStatus("failure"));
  }, [integrationId, search]);

  useEffect(() => {
    if (mode === "initiate") handleInitiateCredentialsConnection();
    else handleCompleteCredentialsConnection();
  }, [handleInitiateCredentialsConnection, handleCompleteCredentialsConnection, mode]);

  const getStatusText = () => {
    if (status === "loading") return "Setting up credentials";
    if (status === "success") return "Credentials configured! You can close this window.";
    if (status === "failure") return "Configuration failed! Close this window and try again.";
  };

  return (
    <div className={styles.Root}>
      <CircularLoader />
      <Title>{getStatusText()}</Title>
    </div>
  );
};
