import { Option, OptionList, Popover, useResponsive } from "@introist/react-foundation/v2";
import { WEEKDAY_MAP } from "modules/notifications/NotificationSettingsRoute/components";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { useRef, useState } from "react";

type DayPickerProps = {
  value: string;
  onChange: ({ amount }: { amount: number }) => void;
};

const DAY_PICKER_OPTIONS: Option[] = Object.keys(WEEKDAY_MAP).map(key => ({
  key: (Number(key) - 1).toString(),
  title: WEEKDAY_MAP[Number(key)].long
}));

export const DayPicker = ({ value, onChange, ...rest }: DayPickerProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { isMobileLayout } = useResponsive();

  const selectedOption = DAY_PICKER_OPTIONS.find(option => option.key === value);

  return (
    <div {...rest}>
      <IconToggleButton
        startIcon="calendar"
        active={open}
        ref={buttonRef}
        onClick={() => setOpen(!open)}
      >
        {selectedOption?.title}
      </IconToggleButton>
      <Popover
        open={open}
        referenceElement={buttonRef.current}
        placement={isMobileLayout ? "bottom-start" : "left-start"}
        sameWidthWithReference={isMobileLayout}
        onClose={() => setOpen(false)}
      >
        <OptionList
          selected={selectedOption?.key}
          options={DAY_PICKER_OPTIONS}
          onItemClick={({ key }) => {
            onChange({ amount: Number(key) });
            setOpen(false);
          }}
          style={{
            width: 192
          }}
        />
      </Popover>
    </div>
  );
};
