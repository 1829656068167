import { Button, IconButton, Modal, Stack, Title, useToast } from "@introist/react-foundation/v2";
import { api } from "../../../../../services/rpc/RpcProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { parseRelativeDate } from "@introist/introist-commons/dist";
import { stringifyUnit } from "../../../../../components/organisms";
import { RelativeDateInlineEditor } from "../../../../../components/molecules";
import { FormFooter } from "../../../../datasources/components/FormFooter";

export const AutomationAutoRemovalConfig = () => {
  const toast = useToast();
  const { data: workspace, refetch } = api.workspaces.find.useQuery({});

  const [open, setOpen] = useState(false);
  const [period, setPeriod] = useState<string | null>(null);

  const update = api.workspaces.update.useMutation();

  const autoRemoval = useMemo(() => {
    if (!workspace || !workspace.automationAutoRemovalPeriod) return null;
    return parseRelativeDate(workspace.automationAutoRemovalPeriod);
  }, [workspace]);

  const onSave = useCallback(async () => {
    await update
      .mutateAsync({
        automationAutoRemovalPeriod: period
      })
      .then(async () => {
        await refetch();
        toast.success("Auto-removal setting saved");
        setOpen(false);
      })
      .catch(() => {
        toast.error("Failed to save auto-removal setting");
      });
  }, [update, period, toast, refetch]);

  useEffect(() => {
    if (workspace) {
      setPeriod(workspace.automationAutoRemovalPeriod);
    }
  }, [workspace]);

  if (!workspace) return null;

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        {autoRemoval
          ? `Remove ${autoRemoval.amount} ${stringifyUnit(autoRemoval.unit!)} after completion`
          : "No auto-removal"}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)} title="Automation auto-removal">
        <Stack vertical style={{ width: "450px" }}>
          <Title>
            Set automations to be completely removed after a specified time once they are completed.
          </Title>
          {period && (
            <Stack>
              <Title variant="bold">Remove</Title>
              <RelativeDateInlineEditor
                variant="outlined"
                onChange={setPeriod}
                value={period!}
                hidden={{ side: true }}
              />
              <Title variant="bold">after completion</Title>
              <IconButton icon="crossSmall" onClick={() => setPeriod(null)} />
            </Stack>
          )}
          {!period && (
            <Stack>
              <Title variant="bold">No auto-removal</Title>
              <Button size="small" variant="outlined" onClick={() => setPeriod("-12m")}>
                Set up
              </Button>
            </Stack>
          )}
          <FormFooter
            onCancel={() => {
              setOpen(false);
            }}
            onSubmit={onSave}
          />
        </Stack>
      </Modal>
    </>
  );
};
