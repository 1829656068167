import React, { ComponentProps, ReactNode, useMemo } from "react";
import {
  Title,
  IconButton,
  Icon,
  useTheme,
  Select,
  Option,
  IconName,
  Tooltip,
  CloseIconButton
} from "@introist/react-foundation/v2";

import styles from "./EditablePersonList.module.scss";
import cx from "classnames";
import { uniq } from "lodash";
import { randomColor } from "./randomcolor";
import { FittedTitle } from "components/atoms";

export interface PersonValue extends Omit<Option, "title"> {
  title: string;
  description?: string;
  optional?: boolean;
}

export interface EditablePersonListProps extends Omit<ComponentProps<"div">, "onChange"> {
  options: PersonValue[];
  value: string[];
  onChange: (newValue: string[]) => unknown;
  sideAction?: ReactNode;
  addIcon?: IconName;
  placeholder?: string;
  searchPlaceholder?: string;
  error?: boolean;
  readonly?: boolean;
  itemMaxLength?: number;
  multiSelect?: boolean;
  selectActions?: Option[];
  getAvatar?: (value: string) => JSX.Element | undefined;
  disabled?: boolean;
  importantPersons?: string[];
  onChangeImportantPersons?: (important: string[]) => void;
  searchable?: boolean;
}

export const EditablePersonList = ({
  options,
  value,
  className,
  onChange,
  sideAction,
  placeholder,
  searchPlaceholder,
  searchable = true,
  addIcon = "plus",
  readonly,
  itemMaxLength = 30,
  error,
  multiSelect = true,
  selectActions,
  getAvatar,
  disabled,
  importantPersons,
  onChangeImportantPersons,
  ...rest
}: EditablePersonListProps) => {
  const { theme } = useTheme();

  const onAdd = (key: string) => {
    onChange(uniq([...value, key]));
  };

  const onRemove = (key: string) => {
    const changed = value.filter(v => v !== key);
    onChange(changed);
  };

  const renderSelect = (inline: boolean) => (
    <Select
      className={styles.ButtonSelect}
      element={"button"}
      variant="blended"
      size="small"
      searchable={searchable}
      buttonColor={inline ? theme.palette.border : undefined}
      startAdornment={<Icon name={inline ? "plus" : addIcon} />}
      showAdornment={false}
      options={options}
      onEnter={onAdd}
      onSelect={option => {
        const currentValue = value.find(v => v === option.key);
        if (currentValue) onRemove(option.key);
        else onAdd(option.key);
      }}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      error={error}
      attachToRef={false}
      value={value}
      multiSelect={multiSelect}
      actions={selectActions}
      disabled={disabled}
    />
  );

  const renderSideActionOrEmpty = (renderSideAction: boolean, renderEmpty: boolean) => {
    if (!renderEmpty) return undefined;
    if (readonly) return <div />;
    return <div>{renderSideAction && sideAction}</div>;
  };

  return (
    <div className={cx(styles.PersonList, className)} {...rest}>
      {value.map((val, index) => {
        const valueOption = options.find(o => o.key === val) || { key: val, title: val };
        return [
          <PersonListItem
            key={valueOption.key}
            value={valueOption}
            maxLength={itemMaxLength}
            onRemove={!readonly ? () => onRemove(val) : undefined}
            avatar={getAvatar && getAvatar(val)}
            isImportant={importantPersons ? importantPersons.includes(val) : undefined}
            isImportantClicked={
              !readonly && onChangeImportantPersons
                ? () => {
                    if (importantPersons?.includes(val)) {
                      onChangeImportantPersons(importantPersons.filter(p => p !== val));
                    } else {
                      onChangeImportantPersons([...(importantPersons || []), val]);
                    }
                  }
                : undefined
            }
          />,
          index < value.length - 1 ? <div /> : undefined,
          renderSideActionOrEmpty(index === 0, index < value.length - 1)
        ];
      })}
      {!readonly &&
        (multiSelect || value.length === 0 ? (
          renderSelect(value.length > 0)
        ) : (
          <div style={{ width: "28px" }} />
        ))}
      {value.length === 0 && <div />}
      {value.length < 2 && renderSideActionOrEmpty(true, true)}
    </div>
  );
};

interface PersonListItemProps {
  value: PersonValue;
  onRemove?: () => unknown;
  maxLength: number;
  avatar?: JSX.Element;
  isImportant?: boolean;
  isImportantClicked?: () => void;
  error?: boolean;
}

export const PersonListItem = ({
  value,
  onRemove,
  maxLength,
  avatar,
  isImportant,
  isImportantClicked,
  error
}: PersonListItemProps) => {
  const { theme } = useTheme();
  const color = error ? theme.palette.danger.default : randomColor(value.title);

  const resolvedTooltip = useMemo(() => {
    if (isImportant) return "This guest is marked as important";
    if (!!isImportantClicked) return "Mark this guest as important";
    return "";
  }, [isImportant, isImportantClicked]);

  return (
    <div className={styles.PersonListItem}>
      <div className={styles.Indicator} style={{ background: avatar ? "none" : color }}>
        {avatar ? (
          avatar
        ) : (
          <Title color="white" variant="bold">
            {value.title.substring(0, 1).toUpperCase()}
          </Title>
        )}
      </div>

      <div>
        <FittedTitle
          maxLength={maxLength}
          variant="bold"
          showTooltip
          color={error ? theme.palette.danger.default : undefined}
        >
          {value.title}
        </FittedTitle>
        <FittedTitle
          small
          showTooltip
          maxLength={maxLength}
          color={error ? theme.palette.danger.default : undefined}
        >
          {value.description || ""}
        </FittedTitle>
      </div>
      {(isImportant === true || !!isImportantClicked) && (
        <div style={{ marginLeft: "auto" }}>
          <Tooltip tooltip={resolvedTooltip}>
            <IconButton
              disabled={!isImportantClicked}
              icon="personCheck"
              onClick={isImportantClicked}
            />
          </Tooltip>
        </div>
      )}
      {onRemove && (
        <CloseIconButton
          onClick={onRemove}
          style={{ marginLeft: isImportant !== undefined ? undefined : "auto" }}
        />
      )}
    </div>
  );
};
