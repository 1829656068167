import { useState } from "react";
import { Icon } from "@introist/react-foundation/v2";

import { VariablePopover } from "./VariablePopover";

export type Variable = { key: string; title: string };

export interface VariableSelectorProps {
  variables: Variable[];
  onSelected: (variable: Variable) => boolean | void;
}

export const VariableSelector = ({ variables, onSelected }: VariableSelectorProps) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <button onClick={() => setOpen(!open)} ref={setPopoverRef}>
        <Icon name="attribute" />
      </button>
      <VariablePopover
        variables={variables}
        popoverRef={popoverRef}
        open={open}
        onSelected={variable => {
          if (onSelected(variable) === true) {
            setOpen(false);
          }
        }}
        setOpen={setOpen}
        searchable
      />
    </>
  );
};
