import styled from "styled-components";
import { ActionMenu, Card, Icon, Option, Stack, Tag, Title } from "@introist/react-foundation/v2";
import { capitalize } from "lodash";
import { ComputedEmployeeField, GroupedEmployeeField } from "services/rpc/RpcProvider";
import { FittedTitle, IntegrationIconTag } from "components/atoms";
import { InfoTooltip } from "../../../../../../foundation";

type Props = {
  field: GroupedEmployeeField;
  computedField?: ComputedEmployeeField;
  actions?: Option[];
  readOnly?: boolean;
  disableSort?: boolean;
  transformActions?: Option[];
};

const StyledFieldRow = styled.div`
  padding: var(--spacing-xSmall) 0;

  > div {
    padding: var(--spacing-medium);
  }
`;

const REQUIRED_FIELDS = ["name", "firstname", "lastname", "emailwork"];

export const EmployeeFieldRow = ({
  field,
  actions = [],
  disableSort,
  readOnly,
  transformActions,
  ...rest
}: Props) => {
  const isRequired = REQUIRED_FIELDS.includes(field.variable);
  const filteredActions = actions.filter(a =>
    !isRequired ? a : a.key !== "archive-employee-field"
  );

  return (
    <StyledFieldRow {...rest}>
      <Card>
        <Stack justifyContent="space-between">
          <Stack gap="small">
            {!disableSort && <Icon subdued name="grab" />}
            <Tag>{capitalize(field.type)}</Tag>

            <Stack gap="xSmall">
              <FittedTitle maxLength={64} showTooltip variant="bold">
                {field.name}
              </FittedTitle>
              <Title small>{field.variable}</Title>
            </Stack>
            <Stack gap="xSmall">
              {field.source !== "custom" && <IntegrationIconTag integrationId={field.source} />}
              {field.sourcePath && <InfoTooltip tooltip={`Source path: ${field.sourcePath}`} />}
            </Stack>
          </Stack>
          <Stack gap="small">
            {(readOnly || isRequired) && <Icon dimmed name="lock" />}
            {!!transformActions && <ActionMenu icon="workflowEditor" options={transformActions} />}
            {filteredActions.length > 0 && <ActionMenu options={filteredActions} />}
          </Stack>
        </Stack>
      </Card>
    </StyledFieldRow>
  );
};
