import { Button, Stack } from "@introist/react-foundation/v2";
import { useState } from "react";
import { DataTransformEditorModal } from "./foundation/datatransform/DataTransformEditor";

export const Playground = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<any>([
    { id: "1", sourceField: "source", targetField: "target" },
    { id: "2", sourceField: "first", code: "test", targetField: "some" },
    { id: "3", code: "test", targetField: "another" }
  ]);

  return (
    <Stack vertical justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <DataTransformEditorModal
        title="Edit Data Transform"
        open={open}
        onClose={() => setOpen(false)}
        onSave={async spec => {
          setValue(spec);
        }}
        initialSpec={value}
        sourceFields={[{ key: "source1" }, { key: "source2" }]}
        targetFields={[{ key: "target1" }, { key: "target2" }]}
      />
      <Button onClick={() => setOpen(true)}>Open data transform</Button>
    </Stack>
  );
};
