import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  IconButton,
  Stack,
  TableActionBar,
  boldTitleFontStyle
} from "@introist/react-foundation/v2";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import useMeasure from "react-use-measure";
import { useNavigate } from "react-router";
import moment from "moment";

import { useHolidays } from "modules/holiday-calendar/hooks/useHolidays";
import { api } from "services/rpc/RpcProvider";
import { useDefaultCountry } from "hooks";

import { useCalendarEvents } from "../hooks/use-calendar-events";

const CURRENT_YEAR = moment().format("YYYY");

// CSS variables for the calendar are overridden in the global styles
// src/index.css
const CalendarWrapper = styled.div`
  font-family: "Poppins", sans-serif;
  flex: 1;
  display: grid;
  grid-template-rows: auto 1fr;

  // TableActionBar
  > :first-child {
    padding-bottom: 1rem;
    box-sizing: border-box;
  }

  .fc .fc-daygrid-day-number {
    padding-top: var(--spacing-small);
    padding-right: var(--spacing-medium);
  }

  .fc-scrollgrid {
    border-top-left-radius: var(--rounding-medium);
    border-radius: var(--rounding-medium);
  }

  .fc .fc-scrollgrid-section table {
    th:last-of-type {
      border-top-right-radius: var(--rounding-medium);
    }
  }

  .fc .fc-scrollgrid-section-liquid > td {
    border-bottom-right-radius: var(--rounding-medium);
    border-bottom-left-radius: var(--rounding-medium);
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: var(--spacing-large);
  }

  .fc-toolbar-title {
    font-weight: 500;
    font-size: var(--typography-size-h4);
    line-height: 1.5rem;
    letter-spacing: 0.01em;
    margin: 0;
    font-family: var(--typography-font-heading);
  }

  .fc .fc-daygrid-day {
    background: var(--palette-background-default);
  }

  // Current day
  .fc .fc-daygrid-day.fc-day-today {
    background-color: var(--palette-primary-ghosted);
  }

  // Day name header
  .fc .fc-col-header-cell-cushion {
    ${boldTitleFontStyle};
  }

  .fc-event {
    margin: 4px 8px !important;
    padding: 0.125rem;
    font-weight: 500;
    box-shadow: none;

    ::before,
    ::after {
      background-color: transparent;
    }

    :active {
      opacity: 0.7;
    }

    &.clickable {
      cursor: pointer;
    }
  }

  .fc-today-button {
    text-transform: capitalize;
  }
`;

const Calendar = ({ defaultCountry }: { defaultCountry: string }) => {
  const calendarRef = useRef<FullCalendar>(null);
  const navigate = useNavigate();
  const [currentMonthYear, setCurrentMonthYear] = useState("");
  const { holidays } = useHolidays(defaultCountry, CURRENT_YEAR);
  const [measureRef] = useMeasure();

  const employees = api.employees.v4.list.useQuery({ fields: ["startdate"] });

  const { employeesToEvents, holidaysToEvents } = useCalendarEvents();

  const updateCurrentMonthYear = useCallback(() => {
    if (!calendarRef.current) return;
    const calendarApi = calendarRef.current.getApi();
    const date = calendarApi.getDate();
    const formattedDate = date.toLocaleString("default", { month: "long", year: "numeric" });
    setCurrentMonthYear(formattedDate);
  }, [calendarRef]);

  const handleTodayClick = () => {
    calendarRef.current?.getApi().today();
    updateCurrentMonthYear();
  };

  const handleNextMonthClick = () => {
    calendarRef.current?.getApi().next();
    updateCurrentMonthYear();
  };

  const handlePreviousMonthClick = () => {
    calendarRef.current?.getApi().prev();
    updateCurrentMonthYear();
  };

  useEffect(() => {
    if (currentMonthYear) return;
    if (!calendarRef.current) return;
    updateCurrentMonthYear();
  }, [currentMonthYear, updateCurrentMonthYear]);

  return (
    <CalendarWrapper>
      <TableActionBar
        title={currentMonthYear}
        endActions={
          <Stack>
            <Button size="small" variant="outlined" onClick={handleTodayClick}>
              Today
            </Button>
            <Stack gap="xSmall">
              <IconButton
                size="large"
                icon="chevronLeft"
                variant="filled"
                onClick={handlePreviousMonthClick}
              />
              <IconButton
                size="large"
                icon="chevronRight"
                variant="filled"
                onClick={handleNextMonthClick}
              />
            </Stack>
          </Stack>
        }
      />

      <div ref={measureRef}>
        <FullCalendar
          height="70vh"
          headerToolbar={false}
          ref={calendarRef}
          weekends={false}
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={[...holidaysToEvents(holidays), ...employeesToEvents(employees.data)]}
          eventClick={e => {
            if (
              e.event.extendedProps.type === "employee-start-date" &&
              e.event.extendedProps.employeeId
            ) {
              navigate(`/employees/${e.event.extendedProps.employeeId}`);
            }
          }}
        />
      </div>
    </CalendarWrapper>
  );
};

export const OnboardingCalendar = () => {
  const defaultCountry = useDefaultCountry();

  return defaultCountry ? <Calendar defaultCountry={defaultCountry} /> : null;
};
