import { Button, H4, InputV2, Stack, TextArea, useToast } from "@introist/react-foundation/v2";
import { useState } from "react";
import { Trigger, api } from "services/rpc/RpcProvider";

type Props = {
  trigger: Trigger;
  onClose: VoidFunction;
};

export const TriggerSettings = ({ trigger, onClose, ...rest }: Props) => {
  const toast = useToast();
  const updateTriggerApi = api.triggers.update.useMutation({});

  const [values, setValues] = useState({
    name: trigger.name,
    icon: trigger.icon,
    color: trigger.color,
    description: trigger.description
  });

  // dumb type errors it works trust me
  // @ts-expect-error
  const hasChanges = Object.keys(values).some(key => values[key] !== trigger[key]);

  const updateTrigger = async () => {
    await updateTriggerApi.mutateAsync(
      { id: trigger.id, updates: values },
      {
        onError: () => toast.error("Failed to update trigger"),
        onSuccess: () => toast.success("Trigger updated")
      }
    );
  };

  return (
    <Stack {...rest} vertical gap="xLarge">
      <Stack justifyContent="space-between">
        <H4>Trigger settings</H4>
        <Stack gap="small">
          <Button variant="blended" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="small"
            disabled={!hasChanges || updateTriggerApi.isLoading}
            onClickWithLoading={updateTrigger}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Stack alignItems="stretch" vertical>
        <Stack vertical style={{ flex: 1 }}>
          <InputV2
            placeholder="Name"
            value={values.name}
            onChange={e => setValues(current => ({ ...current, name: e.target.value }))}
          />
          <TextArea
            placeholder="Description"
            value={values.description || ""}
            onChange={v => setValues(current => ({ ...current, description: v }))}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
