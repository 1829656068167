import { useFieldCategories } from "../hooks/useFieldCategories";
import { TabBar } from "../../../components/molecules";
import { Select } from "@introist/react-foundation/v2";

export const FieldCategorySelector = ({
  value,
  onChange
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { categoryTitles } = useFieldCategories();

  if (categoryTitles.length <= 4) {
    return <TabBar tabs={categoryTitles} activeTab={value} onTabChange={onChange} />;
  }

  return (
    <Select
      size="small"
      options={categoryTitles.map(c => ({ key: c }))}
      value={value}
      onSelect={opt => onChange(opt.key)}
    />
  );
};
