import { Layout, Title, Button, Icon, useTheme } from "@introist/react-foundation/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";
import { InputHTMLAttributes, ReactNode, useRef, useState } from "react";

import styled from "styled-components";

type FallbackDirectionEditorProps = {
  fallbackDirection: string;
  onChange: (fallbackDirection: string) => unknown;
};

const RadioButton = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenRadio = styled.input`
  position: absolute;
  opacity: 0;
`;

const StyledRadio = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #ccc;
  position: relative;

  ${HiddenRadio}:checked + & {
    background-color: white;
    border: 2px solid var(--palette-primary-default);

    ::after {
      position: absolute;
      content: "";
      width: 10px;
      left: 2px;
      right: 0;
      top: 2px;
      bottom: 0;
      border-radius: 50%;
      height: 10px;
      background-color: var(--palette-primary-default);
    }
  }
`;

const StyledButton = styled(Button)`
  .radio {
    margin-left: auto;
  }
`;

type RadioProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
};

export const Radio = ({ label, className, ...rest }: RadioProps) => {
  return (
    <RadioButton className={className}>
      <HiddenRadio type="radio" {...rest} />
      <StyledRadio />
      {label}
    </RadioButton>
  );
};

export const FallbackDirectionEditor = ({
  fallbackDirection,
  onChange
}: FallbackDirectionEditorProps) => {
  const { theme } = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <Layout.Group>
      <IconToggleButton
        active={open}
        ref={buttonRef}
        endAdornment={
          <Icon
            name={fallbackDirection === "backward" ? "calendarBackward" : "calendarForward"}
            color={theme.palette.foreground.subdued}
          />
        }
        onClick={() => setOpen(!open)}
      >
        <Title style={{ fontWeight: 500 }}>
          {fallbackDirection === "backward" ? "or earlier" : "or later"}
        </Title>
      </IconToggleButton>
      <CardPopover
        open={open}
        onClose={() => setOpen(false)}
        referenceElement={buttonRef.current}
        widthPreset="wide"
      >
        <Layout.Group vertical>
          <Title variant="bold">Set fallback</Title>
          <Title>
            If the preferred date is a holiday or not permitted, choose how Introist reschedules
          </Title>
          <Layout.Group vertical>
            <StyledButton
              variant="outlined"
              startIcon="calendarBackward"
              endAdornment={<Radio className="radio" checked={fallbackDirection === "backward"} />}
              onClick={() => {
                onChange("backward");
                setOpen(false);
              }}
            >
              Earlier
            </StyledButton>
            <StyledButton
              variant="outlined"
              startIcon="calendarForward"
              endAdornment={<Radio className="radio" checked={fallbackDirection === "forward"} />}
              onClick={() => {
                onChange("forward");
                setOpen(false);
              }}
            >
              Later
            </StyledButton>
          </Layout.Group>
        </Layout.Group>
      </CardPopover>
    </Layout.Group>
  );
};
