import { useCallback, useEffect, useState } from "react";
import { Option } from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";

import { TOption } from "../../types";

type UseMultiSelectOptionList = {
  initialOptions: TOption[];
  initialSelectedOptions?: TOption[];
  onChange: (selectedKeys: string[]) => void;
  onEnter?: (search: string) => void;
};

export const useMultiSelectOptionList = ({
  initialOptions,
  initialSelectedOptions,
  onChange,
  onEnter
}: UseMultiSelectOptionList) => {
  const [options, setOptions] = useState(initialOptions);

  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  useEffect(() => {
    if (isEmpty(options) && !isEmpty(initialOptions)) {
      setOptions(initialOptions);
    }
  }, [initialOptions, options, setOptions]);

  useEffect(() => {
    if (!initialSelectedOptions) return;
    setSelectedOptions(initialSelectedOptions);
  }, [initialSelectedOptions, setSelectedOptions]);

  const updateSelectedOptions = useCallback(
    (nextSelectedOptions: TOption[]) => {
      setSelectedOptions(nextSelectedOptions);
      const keys = nextSelectedOptions.map(({ key }) => key);

      onChange(keys);
    },
    [setSelectedOptions, onChange]
  );

  const handleOptionClick = useCallback(
    (option: Option) => {
      const person = options.find(o => o.key === option.key);

      if (person) {
        const isSelected = selectedOptions.find(selected => selected.key === person.key);

        // Remove
        if (isSelected) {
          const nextSelectedOptions = selectedOptions.filter(({ key }) => key !== person.key);
          updateSelectedOptions(nextSelectedOptions);
          return;
        }

        // Add
        const nextSelectedOptions = [...selectedOptions, person];
        updateSelectedOptions(nextSelectedOptions);
      }
    },
    [options, selectedOptions, updateSelectedOptions]
  );

  const handleRemoveOption = useCallback(
    (id: string) => {
      const removable = selectedOptions.find(({ key }) => key === id);
      if (!removable) return;

      const nextSelectedOptions = selectedOptions.filter(({ key }) => key !== removable.key);
      updateSelectedOptions(nextSelectedOptions);
    },
    [selectedOptions, updateSelectedOptions]
  );

  const handleAddOption = useCallback(
    (search: string) => {
      if (!onEnter) return;

      setOptions(current => {
        const nextOptions = [
          ...current,
          {
            key: search,
            title: search,
            description: ""
          }
        ];

        const match = nextOptions.find(({ key }) => key === search);

        if (match) {
          const nextSelectedOptions = [...selectedOptions, match];
          updateSelectedOptions(nextSelectedOptions);
        }

        return nextOptions;
      });
    },
    [onEnter, selectedOptions, setOptions, updateSelectedOptions]
  );

  return {
    options,
    selectedOptions,
    handleOptionClick,
    handleRemoveOption,
    handleAddOption: onEnter ? handleAddOption : undefined
  };
};
