import { Stack, Title } from "@introist/react-foundation/v2";

import { useNavigate } from "react-router-dom";
import { ListRow } from "components/organisms";
import { ListView } from "../../../foundation";
import { Automation } from "../hooks/useAutomations";
import { Avatar } from "../../../components/molecules";
import { AutomationProgress } from "./AutomationProgress";
import styled from "styled-components";

type AutomationListProps = {
  automations?: Automation[];
};

const NoOverflowTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AutomationList = ({ automations }: AutomationListProps) => {
  const navigate = useNavigate();

  return (
    <ListView.List
      data={automations}
      renderRow={j => (
        <ListRow
          gridTemplateColumns="25px 200px 1fr 265px"
          onClick={() => navigate(`/automations/${j.id}`)}
        >
          <Avatar nameOrEmail={j.employeeName ?? "Deleted employee"} />
          <NoOverflowTitle>{j.employeeName ?? "Deleted employee"}</NoOverflowTitle>
          <Title variant="bold">{j.title}</Title>
          <Stack justifyContent="flex-end">
            <AutomationProgress stats={j.stats} />
          </Stack>
        </ListRow>
      )}
    />
  );
};
