const matcherFor = (key: string) => new RegExp(`\\{\\{\\s*${key}\\s*\\}\\}`, "g");

const tagMatcherFor = (key: string) =>
  new RegExp(`<tag.{1,130}data-property-variable=\\"${key}\\".*?>(.*?)</tag>`, "g");

export const emailMatcher = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const uuidMatcher =
  /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$/;
export const emailOrUuidMatcher =
  /(^[^\s@]+@[^\s@]+\.[^\s@]+$)|(^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}$)/;
export const timeMatcher = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
export const anyMatcher = /.*/;
export const introistDateMatcher = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const fillStringTemplate = (
  template: string,
  substitutions: { [index: string]: string }
) => {
  if (!template) return "";
  return Object.keys(substitutions).reduce((filled, substitutionKey) => {
    return filled
      .replace(matcherFor(substitutionKey), substitutions[substitutionKey])
      .replace(tagMatcherFor(substitutionKey), substitutions[substitutionKey]);
  }, template);
};

export const isUuid = (str: string) => str.match(uuidMatcher);
export const isEmail = (str: string) => str.match(emailMatcher);

const materialMatchRegex =
  "material:[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}";

export const findMaterials = (message: string): string[] => {
  const matcher = new RegExp(materialMatchRegex, "gm");
  const matches = message.match(matcher) || [];
  return matches.map(match => match.replace(/material:/gm, ""));
};
