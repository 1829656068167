import { useEffect, useRef, useState } from "react";
import { EmployeeField } from "services/rpc/RpcProvider";
import { Button, ModalV2, SkeletonLoader, Stack } from "@introist/react-foundation/v2";
import { useComputedField } from "../hooks";
import { CodeEditor } from "../../../../../components/templates/CodeEditor";
import { useEmployees } from "../../../../employees/hooks/useEmployees";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  isNewTransform: boolean;
  field?: EmployeeField;
  refetchComputedFields: VoidFunction;
};

export const UpdateFieldTransform = ({
  field,
  isNewTransform,
  refetchComputedFields,
  ...rest
}: Omit<Props, "open" | "onClose">) => {
  const [code, setCode] = useState<string>();
  const codeSetRef = useRef(false);

  const { computedField, test, updateTransform, updateLoading } = useComputedField(field?.variable);

  const { employees } = useEmployees({});

  const dataLoading = computedField.isLoading || employees === undefined;
  const changed = code !== computedField?.data?.code;

  const employeeOptions =
    (employees ?? [])?.map(employee => ({ title: employee.name!, key: employee.id! })) || [];

  const handleSave = async () => {
    if (!code || !field?.variable) return;
    await updateTransform({
      code,
      fieldVariable: field.variable,
      onSuccess: () => {
        refetchComputedFields();
      }
    });
  };

  useEffect(() => {
    if (computedField.isLoading || !computedField.data || codeSetRef.current) {
      return;
    }
    setCode(computedField.data.code);
    codeSetRef.current = true;
  }, [computedField.isLoading, computedField.data, code, setCode]);

  return (
    <Stack {...rest} vertical gap="xLarge">
      {dataLoading ? (
        <SkeletonLoader height={200} />
      ) : (
        <>
          {code && (
            <CodeEditor
              value={code}
              onChange={code => setCode(code)}
              testDataOptions={employeeOptions}
              onTest={employeeId => test({ code, employeeId: employeeId! })}
            />
          )}
        </>
      )}
      <Stack justifyContent="flex-end">
        <Button
          disabled={dataLoading || !changed || updateLoading}
          onClickWithLoading={handleSave}
          style={{ marginBottom: 1 }}
        >
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export const UpdateFieldTransformModal = ({
  open,
  onClose,
  isNewTransform,
  field,
  refetchComputedFields,
  ...rest
}: Props) => {
  return (
    <ModalV2
      {...rest}
      open={open}
      onClose={onClose}
      title={`${isNewTransform ? "Add" : "Edit"} transform`}
      maxWidth={1024}
    >
      <UpdateFieldTransform
        isNewTransform={isNewTransform}
        field={field}
        refetchComputedFields={refetchComputedFields}
      />
    </ModalV2>
  );
};
