import { ReactNode } from "react";
import styled from "styled-components";
import { Icon, Title, Layout } from "@introist/react-foundation/v2";
import Lottie from "lottie-react";

import bunnyLottie from "./info-bunny.json";
import useMeasure from "react-use-measure";

type InfoBunnyCardProps = {
  title?: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  bunny?: unknown;
};

const StyledInfoBunnyCard = styled.article<{ $bunnyWidth: number }>`
  display: grid;
  grid-template-columns: auto ${({ $bunnyWidth }) => $bunnyWidth + 24}px;
  padding: 0 var(--spacing-large);
  border: 1px solid var(--palette-primary-dimmed);
  background-color: var(--palette-primary-ghosted);
  border-radius: var(--rounding-medium);
  position: relative;

  > :first-child {
    padding: var(--spacing-medium) 0;
  }

  :hover {
    > button {
      opacity: 0.6;

      :hover {
        opacity: 1;
      }
    }
  }
`;

const BunnyWrapper = styled.div`
  position: absolute;
  right: var(--spacing-large);
  bottom: -5.5px;
`;

const Bunny = styled.div`
  align-self: flex-end;
  position: relative;

  > svg {
    position: absolute;
    bottom: -1px;
    left: 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  right: var(--spacing-medium);
  top: var(--spacing-medium);
`;

export const MagicBunny = ({ width }: { width?: number }) => {
  return (
    <Bunny style={{ width }}>
      <Lottie animationData={bunnyLottie} />
    </Bunny>
  );
};

export const InfoBunnyCard = ({
  title,
  children,
  bunny = bunnyLottie,
  onClose,
  ...rest
}: InfoBunnyCardProps) => {
  const [measureRef, { width }] = useMeasure();
  return (
    <StyledInfoBunnyCard {...rest} $bunnyWidth={width}>
      <Layout.Group vertical gap="xSmall">
        {title && <Title variant="bold">{title}</Title>}
        <Title>{children}</Title>
      </Layout.Group>

      <BunnyWrapper ref={measureRef}>
        <Bunny>
          <Lottie animationData={bunny} />
          <svg
            width="18"
            height="15"
            viewBox="0 0 18 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.43 1C3.88 1 1 3.88 1 7.43C1 10.98 3.88 13.86 7.43 13.86C10.98 13.86 13.86 10.98 13.86 7.43C13.86 7.38 13.86 7.33 13.86 7.28H16.3C16.3 3.81 13.48 1 10.02 1H7.44H7.43Z"
              fill="white"
              stroke="#0E181C"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Bunny>
      </BunnyWrapper>
      {onClose && (
        <CloseButton onClick={onClose}>
          <Icon name="crossSmall" />
        </CloseButton>
      )}
    </StyledInfoBunnyCard>
  );
};
