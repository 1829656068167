import { DataForm, Layout } from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { WorkflowStep } from "services/api/WorkflowApi";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { prefixForm } from "utils/FormUtils";

import { SlackSenderSelectV2 } from "./components/SlackSenderSelectV2";
import { SlackUserSelectV2 } from "./components/SlackUserSelectV2";
import { SlackChannelSelectV2 } from "./components/SlackChannelSelectV2";

type SenderAndRecipientsBlockProps = {
  form: DataForm<WorkflowStep>;
  formPrefix: string;
  readOnly?: boolean;
  journeyAttributes?: { [key: string]: string };
};

export const SenderAndRecipientsBlockV2 = ({
  form,
  formPrefix,
  readOnly,
  journeyAttributes
}: SenderAndRecipientsBlockProps) => {
  const prefixedForm = prefixForm(form, formPrefix);

  const getArray = (formKey: string): any[] => {
    const value = prefixedForm.get(formKey);
    if (!value) return [];
    if (Array.isArray(value)) return value;

    return value.startsWith("[") ? JSON.parse(value) : [value];
  };

  const senderId = prefixedForm.get("senderId");

  const updateReceivers = (receivers: string[], type: "channel" | "user") => {
    const current = getArray("recipients");
    const untouched = current.filter(opt => opt.key !== type);

    const formattedReceivers = receivers.map(value => ({ value, key: type }));

    prefixedForm.set("recipients")([...untouched, ...formattedReceivers]);
  };

  const getValue = (type: "channel" | "user") =>
    getArray("recipients")
      .filter(_ => _.key === type)
      .map(_ => _.value);

  const showAccordionError =
    prefixedForm.isError("senderId") ||
    prefixedForm.isError("recipients") ||
    prefixedForm.isError("recipients");

  return (
    <Block blended compact>
      <StepEditorAccordion
        title="Participants"
        stepOrder={2}
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Layout.Group vertical alignItems="stretch" gap="large">
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={senderId}
              onSelectSender={prefixedForm.set("senderId")}
              error={prefixedForm.isError("senderId")}
              readOnly={readOnly}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="To">
            <SlackUserSelectV2
              senderId={senderId}
              error={prefixedForm.isError("recipients")}
              initialSelected={getValue("user")}
              onSelect={receivers => updateReceivers(receivers, "user")}
              readOnly={!senderId || readOnly}
              journeyAttributes={journeyAttributes}
            />
          </StepEditorAccordion.MultiOptionRow>
          <StepEditorAccordion.MultiOptionRow label="Channels">
            <SlackChannelSelectV2
              senderId={senderId}
              onSelect={receivers => updateReceivers(receivers, "channel")}
              error={prefixedForm.isError("recipients")}
              initialSelected={getValue("channel")}
              readOnly={readOnly}
              onAddPrivateChannel={channel => {
                const currentChannels = getValue("channel");

                const nextChannels = [...currentChannels, channel];
                updateReceivers(nextChannels, "channel");
              }}
            />
          </StepEditorAccordion.MultiOptionRow>
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
