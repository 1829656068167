import {
  api,
  DataSourceTestResult,
  SyncTestEmployeeData
} from "../../../../services/rpc/RpcProvider";
import { Icon, InfoCard, Layout, Select, Title } from "@introist/react-foundation/v2";
import { useEffect, useMemo, useState } from "react";
import ReactJson from "@microlink/react-json-view";

export const DataSourceRawData = ({
  result,
  selectedEmployeeSourceId,
  onSelectEmployee
}: {
  result: DataSourceTestResult;
  selectedEmployeeSourceId?: string;
  onSelectEmployee: (sourceIdentifier: string) => void;
}) => {
  const { data: employees } = api.employees.v4.list.useQuery({});

  const [selectedEmployeeData, setSelectedEmployeeData] = useState<
    SyncTestEmployeeData | undefined
  >();

  const [error, setError] = useState(false);

  const { mutateAsync: loadEmployeeData } = api.employees.dataSources.testEmployee.useMutation();

  useEffect(() => {
    if (!selectedEmployeeSourceId) return;
    loadEmployeeData({
      testReportId: result.id,
      employeeSourceIdentifier: selectedEmployeeSourceId
    })
      .then(setSelectedEmployeeData)
      .catch(() => setError(true));
  }, [selectedEmployeeSourceId, result, loadEmployeeData]);
  const employeeOptions = useMemo(() => {
    return result.matching
      .filter(m => !!m.identifierValue)
      .map(m => {
        const employee = employees?.find(e => e.id === m.introistEmployeeId);
        return {
          key: m.identifierValue!,
          title: `${m.identifierValue} - ${employee ? employee.name : ""}`
        };
      })
      .sort();
  }, [result, employees]);

  const renderContent = () => {
    if (!selectedEmployeeData) {
      return <Title>Select an employee to view data</Title>;
    }

    return (
      //@ts-ignore
      <ReactJson
        src={selectedEmployeeData}
        enableClipboard={false}
        displayDataTypes={false}
        displayObjectSize={false}
        iconStyle="square"
        name={false}
      />
    );
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <Select
        attachToRef={false}
        searchable
        size="small"
        value={selectedEmployeeSourceId}
        options={employeeOptions}
        onSelect={opt => {
          onSelectEmployee(opt.key);
        }}
        placeholder="Search employee"
        style={{ width: "100%" }}
        startAdornment={<Icon name="search" />}
      />
      {error && (
        <InfoCard colorVariant="danger" title="Failed to load employee data" icon="crossCircle" />
      )}
      {!error && selectedEmployeeSourceId && renderContent()}
    </Layout.Group>
  );
};
