export const EmailFormStepSchemaFields = {
  form: {
    title: {
      type: "string",
      required: true
    },
    description: {
      type: "string",
      required: true
    },
    fields: {
      type: "array",
      each: "string",
      required: true
    }
  },
  message: {
    senderId: {
      type: "string",
      required: true
    },
    to: {
      type: "string",
      required: true
    },
    subject: {
      type: "string",
      required: true
    },
    message: {
      type: "string",
      required: true
    }
  }
};
