import { CreateWorkspaceOnboardingRoute } from "./routes";

export * from "./routes";

export const ONBOARDING_ROUTES = {
  onboarding: {
    key: "onboarding",
    path: "/onboarding",
    fullscreen: true,
    public: true,
    element: <CreateWorkspaceOnboardingRoute />
  }
};
