import { DataForm } from "@introist/react-foundation/v2";

import { SlackMessageBlock } from "./blocks";

type SlackStepEditorProps = {
  form: DataForm<any>;
  formPrefix: string;
  readOnly?: boolean;
  journeyAttributes?: { [key: string]: string };
};

export const SlackStepEditorV2 = ({
  form,
  formPrefix,
  readOnly,
  journeyAttributes
}: SlackStepEditorProps) => {
  return (
    <>
      <SlackMessageBlock.SenderAndRecipients
        form={form}
        formPrefix={formPrefix}
        readOnly={readOnly}
        journeyAttributes={journeyAttributes}
      />
      <SlackMessageBlock.MessageEditor form={form} formPrefix={formPrefix} readOnly={readOnly} />
    </>
  );
};
