import { CircularLoader } from "@introist/react-foundation/v2";

import { completeCredentialConnection, useIntegrationsApi } from "services/api/IntegrationsApi";
import { ApiTokenCredentialForm } from "../../components";

type ApiTokenCredentialContainerProps = {
  integrationId: string;
  instructionsUrl?: string;
};

export const ApiTokenCredentialContainer = ({
  integrationId,
  ...rest
}: ApiTokenCredentialContainerProps) => {
  const api = useIntegrationsApi();
  const findIntegration = api.find({ integrationId });

  const { data: integration, refetch } = findIntegration.query;

  const onSave = async (token: string) => {
    const credentials = { apiToken: token };
    await completeCredentialConnection({ integrationId, credentials });
    await refetch();
  };

  if (!integration) {
    return <CircularLoader fillParent />;
  }

  return (
    <ApiTokenCredentialForm
      onSaveToken={onSave}
      credentialsConfigured={!!integration.credentialsConfigured}
      {...rest}
    />
  );
};
