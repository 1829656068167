import { ListView, TopBarRoute } from "components/templates";
import { UpsertModal, useSearch } from "../../../foundation";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { useNavigate } from "react-router";
import { Button, Stack, Title } from "@introist/react-foundation/v2";
import { useState } from "react";
import { NudgeCreate, useNudgeActions, useNudges } from "../useNudges";
import { NudgeForm } from "../NudgeForm";

export const NudgesListRoute = () => {
  const navigate = useNavigate();

  const { nudges } = useNudges();

  const { create } = useNudgeActions();

  const { filtered, search, setSearch } = useSearch(nudges, { fields: ["name"] });

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <TopBarRoute
      paths={[
        {
          key: "root",
          title: "Nudges",
          onClick: () => navigate("/nudges")
        }
      ]}
      medium
    >
      <Stack vertical gap="xLarge">
        <ListView.Header
          title="Nudges"
          count={nudges?.length}
          search={{ search, setSearch }}
          onCreate={() => setCreateOpen(true)}
        />
        <ListView.List
          data={filtered}
          empty={
            nudges && nudges.length === 0
              ? {
                  icon: "targetAfter",
                  title: "When something happens, then run action",
                  message:
                    "It has never been easier to set up small but important automations. With nudges you can run action whenever something important happens.",
                  action: <Button onClick={() => setCreateOpen(true)}>Create Nudge</Button>
                }
              : { icon: "targetAfter" }
          }
          renderRow={nudge => (
            <ListRow
              gridTemplateColumns="auto"
              clickable
              onClick={() => navigate(`/nudges/${nudge.id}`)}
            >
              <Stack>
                <IconPickerIcon icon="targetAfter" fallbackIcon="targetAfter" />
                <Title variant="bold">{nudge.name}</Title>
              </Stack>
            </ListRow>
          )}
        />
      </Stack>
      <UpsertModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreate={async entry => {
          await create(entry as NudgeCreate).then(created => navigate(`/nudges/${created.id}`));
        }}
        onUpdate={() => {
          throw new Error("Not implemented");
        }}
        Form={NudgeForm}
        entryName="nudge"
      />
    </TopBarRoute>
  );
};
