import { Expression } from "@introist/introist-commons/dist";
import {
  Button,
  Card,
  H3,
  IconButton,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import {
  FullscreenModal,
  RecursiveExpressionEditor,
  useEmployeeFieldsToExpressionFields
} from "components/organisms";
import { expressionToConditionGroup } from "components/organisms/RecursiveExpressionEditor";
import { RuleViewer } from "components/organisms/RecursiveExpressionEditor/components/RuleViewer/RuleViewer";
import { isEmpty } from "lodash";
import { useState } from "react";
import { Trigger, api } from "services/rpc/RpcProvider";
import styled from "styled-components";

type Props = {
  trigger: Trigger;
};

const StyledEmptyTriggerCard = styled(Card)`
  background-color: var(--palette-surface-subdued);
`;
const EmptyTriggerCard = ({
  loading,
  onCreateRule
}: {
  loading?: boolean;
  onCreateRule: VoidFunction;
}) => {
  return (
    <StyledEmptyTriggerCard variant="blended" spacing="xLarge">
      <Stack justifyContent="center" style={{ flex: 1 }}>
        {loading ? (
          <Button size="small" disabled style={{ visibility: "hidden" }}></Button>
        ) : (
          <>
            <Button startIcon="plus" size="small" onClick={onCreateRule}>
              Create
            </Button>
          </>
        )}
      </Stack>
    </StyledEmptyTriggerCard>
  );
};

const isAndConditionEmpty = (expression: Expression): boolean => {
  const and = "and" in expression && Array.isArray(expression.and) && expression.and.length === 0;
  return and;
};

const isOrConditionEmpty = (expression: Expression): boolean => {
  const or = "or" in expression && Array.isArray(expression.or) && expression.or.length === 0;
  return or;
};

const isEmptyExpression = (expression: Expression) => {
  return isAndConditionEmpty(expression) || isOrConditionEmpty(expression);
};

export const TriggerRules = ({ trigger, ...rest }: Props) => {
  const toast = useToast();

  const emptyExpression = isEmpty(trigger.triggerRule) || isEmptyExpression(trigger.triggerRule);

  const [edit, setEdit] = useState(false);
  const [expression, setExpression] = useState<Expression>(
    !emptyExpression ? trigger.triggerRule : { and: [] }
  );

  const { expressionFields, isLoading } = useEmployeeFieldsToExpressionFields();

  const conditionGroup = expressionToConditionGroup(expression);

  const apiUtils = api.useContext();
  const updateTriggerApi = api.triggers.update.useMutation({});

  const updateTriggerRule = async (triggerRule: Expression) => {
    await updateTriggerApi.mutateAsync(
      {
        id: trigger.id,
        updates: {
          triggerRule
        }
      },
      {
        onError: () => toast.error("Failed to update trigger rule"),
        onSuccess: () => {
          setEdit(false);
          apiUtils.triggers.find.invalidate({ id: trigger.id });
          apiUtils.triggers.preview.invalidate({ id: trigger.id });
          toast.success("Trigger rule updated");
        }
      }
    );
  };

  return (
    <Stack {...rest} vertical gap="small">
      <Stack justifyContent="space-between">
        <Title variant="bold">Rules</Title>
        <IconButton dimmed size="large" icon="pencil" onClick={() => setEdit(true)} />
      </Stack>
      {emptyExpression && (
        <EmptyTriggerCard
          loading={isLoading}
          onCreateRule={() => {
            setEdit(true);
          }}
        />
      )}
      {!emptyExpression && (
        <>
          <RuleViewer
            conditionGroup={conditionGroup}
            fields={expressionFields}
            collapsedCount={3}
            isLoading={isLoading}
          />
        </>
      )}
      <FullscreenModal.Default
        header={{
          title: !emptyExpression ? "Edit trigger rules" : "Create trigger rules",
          bottomBorder: true,
          onClose: () => setEdit(false)
        }}
        footer={{
          children: (
            <Stack justifyContent="flex-end">
              <Button variant="outlined" onClick={() => setEdit(false)}>
                Cancel
              </Button>
              <Button
                disabled={isEmpty(expression) || updateTriggerApi.isLoading}
                onClickWithLoading={async () => {
                  if (isEmptyExpression(expression)) return;
                  await updateTriggerRule(expression);
                }}
              >
                Save
              </Button>
            </Stack>
          ),
          topBorder: true
        }}
        open={edit}
        onClose={() => setEdit(false)}
      >
        <RecursiveExpressionEditor
          expression={expression}
          fields={expressionFields}
          onChange={setExpression}
          config={{
            attributeListTitle: "Create rule based on...",
            placeholderConditionCardText: "Create rule based on...",
            placeholderConditionCardDescription: (
              <Stack gap="small">
                <span>Start by selecting the employee field on the right</span>
                <H3>👉</H3>
              </Stack>
            )
          }}
        />
      </FullscreenModal.Default>
    </Stack>
  );
};
