import { useMemo, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { H4, Icon, Input, Layout, Stack } from "@introist/react-foundation/v2";
import useMeasure from "react-use-measure";
import { FittedTitle } from "../../atoms";
import { AttributeAvatar } from "../../molecules";
import { ExpressionField } from "../RecursiveExpressionEditor";

type AttributeListProps = {
  title?: string;
  searchable?: boolean;
  fields: ExpressionField[];
  searchPlaceholder?: string;
  maxHeight?: number;
  onFieldClick: (field: ExpressionField) => void;
};

const List = styled(Layout.Group).attrs({ as: "ul" })`
  margin: 0;
  padding: 0;
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;

const AttributeButton = styled.button`
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: flex-start;
  grid-column-gap: var(--spacing-medium);
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: var(--spacing-small) var(--spacing-small);
  border-radius: var(--rounding-small);
  border: 1px solid transparent;

  :hover {
    border: 1px solid var(--palette-border-subdued);
  }
`;

export const useEmployeeAttributeSearch = (employeeAttributes: ExpressionField[]) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSetQuery = debounce(setQuery, 300);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedSetQuery(value);
  };

  const filteredEmployeeAttributes = useMemo(() => {
    if (!query) return employeeAttributes;

    return employeeAttributes.filter(attribute => {
      return attribute.name.toLowerCase().includes(query.toLowerCase());
    });
  }, [query, employeeAttributes]);

  return { inputValue, handleInputChange, filteredEmployeeAttributes };
};

export const AttributeList = ({
  title,
  searchable,
  fields,
  searchPlaceholder = "Search employee field",
  maxHeight: maxHeightProp,
  onFieldClick,
  ...rest
}: AttributeListProps) => {
  const [headerHeightRef, { height: headerHeight }] = useMeasure();
  const { inputValue, filteredEmployeeAttributes, handleInputChange } =
    useEmployeeAttributeSearch(fields);

  const maxHeight = useMemo(() => {
    return maxHeightProp ? maxHeightProp - (headerHeight + 18) : undefined;
  }, [maxHeightProp, headerHeight]);

  return (
    <Stack {...rest} vertical>
      <Stack vertical ref={headerHeightRef}>
        {title && <H4>{title}</H4>}
        {searchable && (
          <Input
            value={inputValue}
            onChange={handleInputChange}
            endAdornment={<Icon name="search" />}
            size="small"
            placeholder={searchPlaceholder}
          />
        )}
      </Stack>
      <List vertical gap="none" style={{ maxHeight }}>
        {filteredEmployeeAttributes.map((attribute, idx) => (
          <div key={`trigger-editor-attribute-${attribute.variable}-${idx}`}>
            <AttributeButton onClick={() => onFieldClick(attribute)}>
              <AttributeAvatar />
              <FittedTitle showTooltip maxLength={40} variant="bold">
                {attribute.name}
              </FittedTitle>
            </AttributeButton>
            {
              // is last
              idx === filteredEmployeeAttributes.length - 1 && (
                <div style={{ padding: 8 }}>
                  <span style={{ opacity: 0 }}>empty</span>
                </div>
              )
            }
          </div>
        ))}
      </List>
    </Stack>
  );
};
