import { AutomationsRoute } from "./AutomationsRoute";
import { AutomationRoute } from "./AutomationRoute/AutomationRoute";

export const AUTOMATION_ROUTES = {
  automations: {
    key: "automations",
    path: "/automations",
    element: <AutomationsRoute />
  },
  automation: {
    key: "automation",
    path: "/automations/:automationId",
    element: <AutomationRoute />
  },
  automationStep: {
    key: "automation-step",
    path: "/automations/:automationId/steps/:stepId",
    element: <AutomationRoute />
  }
};
