import { Button, Title, Layout, CircularLoader } from "@introist/react-foundation/v2";

import { openCenterPopup } from "utils/WindowUtils";

import { CredentialsConfiguredInfoCard } from "../CredentialsConfiguredInfoCard";

type OAuthCredentialFormProps = {
  credentialsConfigured: boolean;
  integrationId: string;
  onConnectInitiated?: () => unknown;
  connecting?: boolean;
};

export const OAuthCredentialForm = ({
  credentialsConfigured,
  integrationId,
  onConnectInitiated,
  connecting,
  ...rest
}: OAuthCredentialFormProps) => {
  const onConnect = () => {
    const url = `${window.location.protocol}//${window.location.host}/integrations/${integrationId}/initiate`;
    openCenterPopup(url);
    onConnectInitiated && onConnectInitiated();
  };

  return (
    <Layout.Group vertical {...rest}>
      {credentialsConfigured && <CredentialsConfiguredInfoCard />}

      <Layout.Group justifyContent="space-between">
        {connecting && (
          <Layout.Group>
            <CircularLoader size="small" />
            <Title>Looking for credentials</Title>
          </Layout.Group>
        )}
        <Button
          disabled={connecting}
          variant={credentialsConfigured ? "outlined" : "filled"}
          onClick={onConnect}
          style={{ marginLeft: "auto" }}
        >
          {credentialsConfigured ? "Reconnect account" : "Connect account"}
        </Button>
      </Layout.Group>
    </Layout.Group>
  );
};
