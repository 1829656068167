import { DataForm, InfoCard } from "@introist/react-foundation/v2";
import { FileUpload } from "../../components/FileUpload";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { AttachmentList } from "./components/AttachmentList";

import { useFiles } from "./hooks/useFiles";
import { FileType } from "modules/workflows/components/FileUpload/hooks/useFileUploadRestrictions";

interface IAttachmentsBlockProps {
  readOnly?: boolean;
  form: DataForm<any>;
}

const ACCEPTED_FILE_TYPES: FileType[] = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".doc",
  ".docx",
  ".pptx",
  ".pptm",
  ".ppt"
];
const MAX_NUMBER_OF_FILES = 2;
const MAX_FILE_SIZE_BYTES = 9.5 * 1024 * 1024; // 8MB to support larger fiels such as presentations

export const AttachmentsBlock = ({ form, readOnly, ...rest }: IAttachmentsBlockProps) => {
  const { files, addFiles, removeFile } = useFiles();

  const maxSizeInMb = Math.floor(MAX_FILE_SIZE_BYTES / 1000000);

  return (
    <Block blended compact {...rest}>
      <StepEditorAccordion title="Attachments" description="(optional)" defaultOpen={false}>
        {!readOnly && (
          <>
            <InfoCard
              colorVariant="primary"
              title={`Allowed extensions: ${ACCEPTED_FILE_TYPES.join(" ")}`}
            />
            <FileUpload
              disabled={form.get("attachments").length >= MAX_NUMBER_OF_FILES}
              onFileUploaded={addFiles}
              maxFiles={MAX_NUMBER_OF_FILES}
              maxSize={MAX_FILE_SIZE_BYTES}
              acceptedFileTypes={ACCEPTED_FILE_TYPES}
              restrictions={[`Max ${MAX_NUMBER_OF_FILES} files`, `Max file size ${maxSizeInMb}MB`]}
            />
          </>
        )}
        <AttachmentList
          readOnly={readOnly}
          files={files}
          attachments={form.get("attachments")}
          onUpdate={(file, fileId) => {
            form.set("attachments")([...form.get("attachments"), fileId]);
            removeFile(file);
          }}
          onRemove={fileId => {
            form.set("attachments")(form.get("attachments").filter((id: string) => id !== fileId));
          }}
        />
      </StepEditorAccordion>
    </Block>
  );
};
