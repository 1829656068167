import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, IconButton, Stack, Title, Tooltip, useTheme } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";

export const TrelloBoardStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { theme } = useTheme();
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const { addedMembers, name, url } = step.executionData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Board">
          <StepEditorAccordion.Row label="Name">
            <Stack>
              <Title variant="bold">{name}</Title>
              {url && (
                <IconButton dimmed icon="moveTo" onClick={() => window.open(url, "_blank")} />
              )}
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="Members">
            <Stack vertical>
              {addedMembers.map((m: any) => (
                <Stack>
                  <Title variant="bold">{m.email}</Title>
                  {m.success ? (
                    <Icon name="check" color={theme.palette.success.default} />
                  ) : (
                    <Tooltip tooltip="Failed to add member to board">
                      <Icon name="warning" color={theme.palette.danger.default} />
                    </Tooltip>
                  )}
                </Stack>
              ))}
            </Stack>
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
