import { Field, Input, Stack, TextArea } from "@introist/react-foundation/v2";
import { UpsertFormProps } from "../../foundation";
import { Form } from "./useForms";

export const FormForm = ({ value, onChange, isEdit }: UpsertFormProps<Form>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} autoFocus />
      </Field>

      <Field title="Description">
        <TextArea
          value={value.description ?? ""}
          onChange={description => onChange({ ...value, description })}
        />
      </Field>
    </Stack>
  );
};
