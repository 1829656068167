import { DataForm } from "@introist/react-foundation/v2";

import { CompanyEventsContextProvider } from "modules/events/context";
import { AttendToEventEditorBlock } from "modules/workflows/routes/WorkflowEditor/StepEditor/AttendToEventEditor";

import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { prefixForm } from "utils/FormUtils";
import { AttendanceBlock } from "./components/AttendanceBlock";

type Props = {
  form: DataForm<JourneyStepV2>;
  formPrefix: string;
  readOnly?: boolean;
};

export const AttendToEventJourneyEditor = ({ form, formPrefix, readOnly }: Props) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const executionData = form.get("executionData");

  if (!readOnly) {
    return (
      <CompanyEventsContextProvider>
        <AttendToEventEditorBlock
          companyEventId={prefixedForm.get("companyEventId")}
          onChangeCompanyEventId={prefixedForm.set("companyEventId")}
          after={prefixedForm.get("after")}
          onChangeAfter={prefixedForm.set("after")}
          before={prefixedForm.get("before")}
          onChangeBefore={prefixedForm.set("before")}
          targetDateTerm="target date"
          attendeeAttribute={prefixedForm.get("attendeeAttribute")}
          onChangeAttendeeAttribute={prefixedForm.set("attendeeAttribute")}
        />
      </CompanyEventsContextProvider>
    );
  }

  return (
    <AttendanceBlock attendeeId={executionData.attendeeId} journeyId={form.get("journeyId")} />
  );
};
