import { useMemo } from "react";
import { Icon, Tag } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

import alexishr from "assets/integrationIcons/alexishr.svg";
import ashby from "assets/integrationIcons/ashby.svg";
import bamboohr from "assets/integrationIcons/bamboohr.svg";
import hibob from "assets/integrationIcons/hibob.svg";
import breezy from "assets/integrationIcons/breezyhr.svg";
import freshservice from "assets/integrationIcons/freshservice.svg";
import googleforms from "assets/integrationIcons/googleforms.svg";
import greenhouse from "assets/integrationIcons/greenhouse.svg";
import gusto from "assets/integrationIcons/gusto.svg";
import humaans from "assets/integrationIcons/humaans.svg";
import lever from "assets/integrationIcons/lever.svg";
import personio from "assets/integrationIcons/personio.svg";
import sage from "assets/integrationIcons/sagehr.svg";
import sympahr from "assets/integrationIcons/sympahr.svg";
import teamtailor from "assets/integrationIcons/teamtailor.svg";
import workable from "assets/integrationIcons/workable.svg";
import workday from "assets/integrationIcons/workday.svg";

import { useIntegrations } from "hooks";
import { capitalize } from "lodash";

type Props = {
  integrationId: string;
  size?: "small" | "medium" | "large";
};

const StyledIntegrationIcon = styled.div<{ $size: Props["size"] }>`
  overflow: hidden;
  border-radius: var(--rounding-medium);

  ${({ $size }) => {
    switch ($size) {
      case "small":
        return css`
          border-radius: var(--rounding-small);
          width: 1rem;
          height: 1rem;
        `;
      case "large":
        return css`
          width: 3rem;
          height: 3rem;
        `;
      default:
      case "medium":
        return css`
          width: 1.5rem;
          height: 1.5rem;
        `;
    }
  }}

  > img {
    width: 100%;
    object-fit: cover;
  }
`;

export const IntegrationIcon = ({ integrationId, size = "medium", ...rest }: Props) => {
  const iconUrl = useMemo(() => {
    switch (integrationId) {
      case "alexishr":
        return alexishr;
      case "ashby":
        return ashby;
      case "bamboohr":
        return bamboohr;
      case "hibob":
        return hibob;
      case "breezy":
        return breezy;
      case "freshservice":
        return freshservice;
      case "googleforms":
        return googleforms;
      case "greenhouse":
        return greenhouse;
      case "gusto":
        return gusto;
      case "humaans":
        return humaans;
      case "lever":
        return lever;
      case "personio":
        return personio;
      case "sage":
        return sage;
      case "sympahr":
        return sympahr;
      case "teamtailor":
        return teamtailor;
      case "workable":
        return workable;
      case "workday":
        return workday;
      default:
        return null;
    }
  }, [integrationId]);

  return (
    <StyledIntegrationIcon {...rest} $size={size}>
      {iconUrl ? <img src={iconUrl} alt="integration icon" /> : <Icon name="questionMark" />}
    </StyledIntegrationIcon>
  );
};

const StyledIntegrationIconTag = styled(Tag)<{ $hasIcon?: boolean; $ghosted?: boolean }>`
  padding-left: ${p => (p.$hasIcon ? 0.125 : 0.375)}rem;
  ${({ $ghosted }) =>
    $ghosted &&
    css`
      border: 0;
    `}
`;

export const IntegrationIconTag = ({
  integrationId,
  ghosted
}: {
  integrationId: string;
  ghosted?: boolean;
}) => {
  const { getIntegration } = useIntegrations();
  const integration = getIntegration(integrationId);

  return (
    <StyledIntegrationIconTag
      colorVariant="outlined"
      startAdornment={
        integrationId !== "custom" ? (
          <IntegrationIcon size="small" integrationId={integrationId} />
        ) : undefined
      }
      $ghosted={ghosted}
      $hasIcon={integrationId !== "custom"}
    >
      {integration?.name || capitalize(integrationId)}
    </StyledIntegrationIconTag>
  );
};
