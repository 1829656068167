import { IntroistDateTime } from "@introist/introist-commons/dist";
import moment from "moment";
import { JourneyStepStatusV2, JourneyStepV2 } from "services/rpc/RpcProvider";
import { introistDateTimeToMoment } from "utils/dates";

const ACTIVE_JOURNEY_STEP_STATUSES: JourneyStepStatusV2[] = ["scheduled", "delivered", "failing"];

export const isActiveJourneyStep = (step: JourneyStepV2) => {
  return ACTIVE_JOURNEY_STEP_STATUSES.includes(step.status);
};

export const isResendableJourneyStep = (step: JourneyStepV2) => {
  const resendableStatuses: JourneyStepStatusV2[] = ["delivered"];
  const resendableStepTypes = ["slackquestion"];

  if (!resendableStepTypes.includes(step.stepType)) return false;
  if (resendableStatuses.includes(step.status)) return true;

  if (step.status === "failing" && step.deliveredAt) return true;

  return false;
};

export const isExecutableJourneyStep = (step: JourneyStepV2) => {
  if (step.deliveredAt) return false;

  const executableStatuses: JourneyStepStatusV2[] = ["scheduled", "failing"];
  return executableStatuses.includes(step.status) && step.stepType !== "event";
};

export const isUpdatableJourneyStep = (step: JourneyStepV2) => {
  const updatableStatuses: JourneyStepStatusV2[] = ["scheduled", "failing", "draft"];
  return updatableStatuses.includes(step.status);
};

export const isCompletableJourneyStep = (step: JourneyStepV2) => {
  if (!step.deliveredAt) return false;
  if (!["delivered", "failing"].includes(step.status)) return false;

  return ["slackqm", "meeting"].includes(step.stepType);
};

export const isScheduledJourneyStep = (
  status: JourneyStepStatusV2,
  executedAt?: IntroistDateTime
): boolean => {
  if (status !== "scheduled" && status !== "delivered") return false;

  if (!executedAt) return true;

  const executionMoment = introistDateTimeToMoment(executedAt); // Assuming you've defined this function
  return moment().isBefore(executionMoment);
};
