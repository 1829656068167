import { EmployeeFieldsRoute } from "./fields/EmployeeFieldsRoute";
import { EmployeesRoute } from "./routes/EmployeesRoute";
import { Icon } from "@introist/react-foundation/v2";
import { EmployeeRoute } from "./routes/EmployeeRoute";

export * from "./routes/EmployeeRoute";
export * from "./routes/EmployeesRoute";
export * from "./containers";

export const EMPLOYEE_ROUTES = {
  employees: {
    key: "employees",
    path: "/employees",
    element: <EmployeesRoute />,
    title: "Employees",
    icon: <Icon name="persons" />
  },
  createEmployee: {
    key: "employeesSection",
    path: "/employees/new",
    element: <EmployeesRoute section="new" />
  },
  importEmployee: {
    key: "employeesSection",
    path: "/employees/import",
    element: <EmployeesRoute section="import" />
  },
  employeeGroups: {
    key: "employeeGroups",
    path: "/employees/groups",
    element: <EmployeesRoute section="groups" />
  },
  employeeGroup: {
    key: "employeeGroup",
    path: "/employees/groups/:groupId",
    element: <EmployeesRoute section="groups" />
  },
  employee: {
    key: "employee",
    path: "/employees/:employeeId",
    element: <EmployeeRoute />
  },
  employeeSection: {
    key: "employee-section",
    path: "/employees/:employeeId/:section",
    element: <EmployeeRoute />
  },
  employeeFields: {
    key: "employee-fields",
    path: "/data/fields",
    title: "Employee Fields",
    element: <EmployeeFieldsRoute />
  }
};
