import { AttachmentItem } from "./AttachmentItem";
import { FileBase } from "./FileBase";
import { FileUploadItem } from "./FileUploadItem";

type FileProps = {
  readOnly?: boolean;
  attachmentId?: string;
  file?: File;
  onUploaded?: (fileId: string) => unknown;
  onRemove: (fileId: string) => unknown;
};

export const FileItem = ({ attachmentId, file, readOnly, onUploaded, onRemove }: FileProps) => {
  if (attachmentId)
    return <AttachmentItem id={attachmentId} readOnly={readOnly} onRemove={onRemove} />;
  if (file)
    return (
      <FileUploadItem
        file={file}
        onFileUploaded={fileId => {
          onUploaded && onUploaded(fileId);
        }}
      />
    );
  return <FileBase title="NOT FOUND" />;
};
