import { Employee, useEmployee } from "../../hooks/useEmployees";
import { Loading } from "../../../../foundation";
import { Skeletons, UnsavedChanges } from "../../../../components/molecules";
import { Stack } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { get, isEmpty } from "lodash";
import { EmployeeActions } from "./EmployeeActions";
import { EmployeeHeader } from "./EmployeeHeader";
import { EmployeeProfileForm } from "./EmployeeProfileForm";

export const EmployeeProfile = ({ id }: { id: string }) => {
  const { employee, update } = useEmployee({ id });

  const [changes, setChanges] = useState<Record<string, unknown>>({});
  const [errors, setErrors] = useState<any[] | undefined>();

  const data = useMemo(() => {
    if (!employee) return undefined;
    return { ...employee, ...changes } as Employee;
  }, [employee, changes]);

  return (
    <Stack vertical gap="xxLarge">
      {employee && (
        <Stack justifyContent="space-between">
          <EmployeeHeader employee={employee} />
          <EmployeeActions employeeId={employee.id!} />
        </Stack>
      )}
      {!employee && <Skeletons.Block height={150} />}
      <Loading data={!!employee} loading={<Skeletons.List rowCount={10} rowHeight={80} />}>
        <Stack vertical gap="none">
          <EmployeeProfileForm
            employee={data!}
            onChange={(field, value) => {
              setChanges({ ...changes, [field]: value });
              setErrors(errors ? errors.filter(e => e.attribute !== field) : undefined);
            }}
          />
          <UnsavedChanges
            show={!isEmpty(changes)}
            onSave={() =>
              update({ id: id!, updates: changes })
                .then(() => setChanges({}))
                .catch(err => setErrors(get(err, "data.introistError.data")))
            }
            style={{
              position: "fixed",
              bottom: "1rem",
              left: "calc(50% - 125px)",
              width: "250px"
            }}
          />
        </Stack>
      </Loading>
    </Stack>
  );
};
