import { useColorVariant } from "@introist/react-foundation/v2";
import { forwardRef } from "react";
import styled, { css, keyframes } from "styled-components";

type Props = {
  animateIn?: boolean;
  colorVariant?: "success" | "primary" | "danger";
};

export const rippleKeyframes = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1) ;
    opacity: 0;
  }
`;

const scaleInKeyframes = keyframes`
 0% {
  opacity: 1;
  transform: scale(0);
 }
 100% {
  opacity: 1;
  transform: scale(1);
 }
`;

const StyledAnimatedRippleDot = styled.span<{ $color: string; $animateIn?: boolean }>`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: relative;

  ::before {
    content: "";
    position: relative;
    display: block;
    border-radius: inherit;
    width: inherit;
    height: inherit;
    background-color: ${p => p.$color};
    z-index: 2;
  }

  ::after {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${p => p.$color};
    transform-origin: center;
    animation: ${rippleKeyframes} 2s infinite linear;
    opacity: 0;
    transform: scale(0);
    z-index: 1;
  }

  ${p =>
    p.$animateIn &&
    css`
      opacity: 0;
      animation-name: ${scaleInKeyframes};
      animation-duration: 180ms;
      animation-delay: 280ms;
      animation-fill-mode: forwards;
    `}
`;

export const AnimatedRippleDot = forwardRef<HTMLSpanElement, Props>(
  ({ animateIn = false, colorVariant = "success", ...rest }, ref) => {
    const color = useColorVariant(colorVariant);

    return (
      <StyledAnimatedRippleDot
        ref={ref}
        {...rest}
        $color={color.foreground}
        $animateIn={animateIn}
      />
    );
  }
);
