import { useActions, useToast } from "@introist/react-foundation/v2";
import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";

export type Params = RouterInput["workflows"]["v4"]["steps"]["list"];

export type WorkflowStep = RouterOutput["workflows"]["v4"]["steps"]["find"];

export const useWorkflowStepOps = (stepId?: string) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();
  const apiUtils = api.useContext();

  const createOp = api.workflows.v4.steps.create.useMutation();
  const updateOp = api.workflows.v4.steps.update.useMutation();
  const archiveOp = api.workflows.v4.steps.archive.useMutation();

  const activateOp = api.workflows.v4.steps.activate.useMutation();
  const deactivateOp = api.workflows.v4.steps.deactivate.useMutation();

  const create = useCallback(
    async entry => {
      return createOp.mutateAsync(entry).then(async res => {
        await apiUtils.workflows.v4.steps.list.refetch({ workflowId: res.workflowId });
        toast.success("Step saved");
        return res;
      });
    },
    [createOp, apiUtils.workflows.v4.steps.list, toast]
  );

  const update = useCallback(
    async data => {
      return updateOp.mutateAsync(data).then(async res => {
        await apiUtils.workflows.v4.steps.list.refetch({ workflowId: res.workflowId });
        await apiUtils.workflows.v4.steps.find.invalidate({ stepId: data.id! });
        toast.success("Step updated");
        return res;
      });
    },
    [updateOp, apiUtils.workflows.v4.steps.list, apiUtils.workflows.v4.steps.find, toast]
  );

  const archive = onConfirmAction(
    async (id, onArchived?: () => Promise<void>) => {
      await archiveOp.mutateAsync({ id });
      await apiUtils.workflows.v4.steps.list.refetch();
      await apiUtils.workflows.v4.steps.find.invalidate({ stepId: id });
      onArchived && (await onArchived());
      toast.success("Step archived");
    },
    {
      icon: "archive",
      title: "Archive step",
      description: "Are you sure you want to archive this step?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  const activate = useCallback(async () => {
    try {
      await activateOp.mutateAsync({ id: stepId! });
      await apiUtils.workflows.v4.steps.list.refetch();
      await apiUtils.workflows.v4.steps.find.invalidate({ stepId: stepId! });
      toast.success("Step activated");
    } catch (e) {
      toast.error("Failed to activate step");
    }
  }, [
    activateOp,
    apiUtils.workflows.v4.steps.list,
    apiUtils.workflows.v4.steps.find,
    stepId,
    toast
  ]);

  const deactivate = useCallback(async () => {
    try {
      await deactivateOp.mutateAsync({ id: stepId! });
      await apiUtils.workflows.v4.steps.list.refetch();
      await apiUtils.workflows.v4.steps.find.invalidate({ stepId: stepId! });
      toast.success("Step deactivated");
    } catch (e) {
      toast.error("Failed to deactivate step");
    }
  }, [
    apiUtils.workflows.v4.steps.list,
    apiUtils.workflows.v4.steps.find,
    stepId,
    deactivateOp,
    toast
  ]);

  return { create, update, archive, activate, deactivate };
};

export const useWorkflowSteps = (params?: Params) => {
  const { data: steps, refetch } = api.workflows.v4.steps.list.useQuery(params!, {
    enabled: !!params,
    refetchOnMount: "always"
  });

  const ops = useWorkflowStepOps();

  return {
    steps,
    refetch,
    ...ops
  };
};

export const useWorkflowStep = (stepId?: string) => {
  const { data: step } = api.workflows.v4.steps.find.useQuery(
    { stepId: stepId! },
    {
      enabled: !!stepId,
      refetchOnMount: "always"
    }
  );
  const ops = useWorkflowStepOps(stepId);

  return {
    step,
    ...ops
  };
};
