import { DataForm } from "@introist/react-foundation/v2";
import { prefixForm } from "utils/FormUtils";
import { TaskContentBlock } from "../blocks/TaskContentBlock";
import { SlackMessageBuilder } from "./SlackMessageBuilder";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

type SlackTaskEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
};

export const SlackTaskEditor = ({ form, formPrefix = "stepData." }: SlackTaskEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);

  return (
    <>
      <TaskContentBlock
        value={prefixedForm.get(`task`)}
        onChanged={prefixedForm.set("task")}
        accordionTitlePrefix="2"
      />
      <SlackMessageBuilder
        value={prefixedForm.get("message")}
        onChanged={prefixedForm.set("message")}
      />
    </>
  );
};
