import { ToggleSettingCard } from "components/organisms";
import { getEventSettingsMetadata } from "../util";
import { EventSettingProps } from ".";
import { useMemo, useState } from "react";
import { useUpdateCompanyEventSetting } from "../hooks";
import { Icon, IconButton, Layout, Select, Title, useToast } from "@introist/react-foundation/v2";
import { isEmail } from "../../../../../utils/string-templater";
import { useEmployees } from "../../../../employees/hooks/useEmployees";

export const PresentersSetting = ({ readOnly, setting }: EventSettingProps) => {
  const toast = useToast();

  const metadata = getEventSettingsMetadata(setting.setting);
  const [active, setActive] = useState(setting.active);
  const { updateEventSetting } = useUpdateCompanyEventSetting({
    setting,
    config: { enabled: !readOnly }
  });

  const { employees } = useEmployees({ fields: ["emailwork"] });

  const presenters = useMemo(() => {
    if (setting.setting === "Presenters") {
      return setting.conf.presenters;
    }
    return [];
  }, [setting]);

  const presenterOptions = useMemo(() => {
    return (employees ?? [])
      .filter(e => !!e.emailwork)
      .map(employee => ({
        key: employee.emailwork!,
        title: employee.name!
      }));
  }, [employees]);

  return (
    <ToggleSettingCard
      autoCollapse
      readOnly={readOnly}
      key={setting.setting}
      title={metadata.title}
      description={metadata.description}
      active={active}
      onToggleActive={active => {
        setActive(active);
        updateEventSetting({ active }, () => setActive(setting.active));
      }}
    >
      <Layout.Group vertical>
        {presenters.map(presenter => (
          <Layout.Group key={presenter}>
            <Title variant="bold">{presenter}</Title>
            <IconButton
              icon="crossSmall"
              onClick={() => {
                updateEventSetting({
                  conf: { presenters: presenters.filter(p => p !== presenter) }
                });
              }}
            />
          </Layout.Group>
        ))}
        <Select
          attachToRef={false}
          element="button"
          variant="blended"
          size="small"
          placeholder="Add presenter"
          startAdornment={<Icon name="plus" />}
          searchable
          searchPlaceholder="Search or add email"
          options={presenterOptions}
          onEnter={email => {
            if (isEmail(email)) {
              updateEventSetting({ conf: { presenters: [...presenters, email] } });
            } else {
              toast.warning("Invalid email");
            }
          }}
          onSelect={opt => {
            updateEventSetting({ conf: { presenters: [...presenters, opt.key] } });
          }}
        />
      </Layout.Group>
    </ToggleSettingCard>
  );
};
