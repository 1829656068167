import { ActionMenu, IconButton, Layout, Option } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { ReactNode } from "react";

export interface HeaderBlockProps {
  onClose: () => unknown;
  actions?: Option[];
  left?: ReactNode;
  onOpenJourney?: () => void;
}

export const StepHeaderBlock = ({ left, onClose, actions, onOpenJourney }: HeaderBlockProps) => {
  return (
    <Block blended compact>
      <Layout.Group vertical>
        <Layout.Group justifyContent="space-between" style={{ padding: "6px 0" }}>
          {left}
          <Layout.Group>
            {onOpenJourney && (
              <IconButton
                size="large"
                variant="blended"
                dimmed
                onClick={onOpenJourney}
                icon="moveTo"
              />
            )}
            {actions && actions.length > 0 && <ActionMenu options={actions} />}
            <IconButton size="large" variant="blended" onClick={onClose} icon="crossLarge" />
          </Layout.Group>
        </Layout.Group>
        <Layout.Divider />
      </Layout.Group>
    </Block>
  );
};
