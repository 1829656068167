import {
  Button,
  Card,
  CircularLoader,
  Modal,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { orderBy } from "lodash";
import { useCallback } from "react";

type ArchivedFieldsModalProps = {
  open: boolean;
  onClose: () => void;
  datasetId: string;
};

export const ArchivedFieldsModal = ({ open, onClose, datasetId }: ArchivedFieldsModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Archived Fields"
      maxWidth={500}
      style={{ width: "500px" }}
    >
      {open && <ArchivedFields datasetId={datasetId} />}
    </Modal>
  );
};

const ArchivedFields = ({ datasetId }: { datasetId: string }) => {
  const toast = useToast();

  const { data: fields, refetch } = api.datasets.fields.list.useQuery(
    {
      datasetId,
      archived: true
    },
    {
      refetchOnMount: "always"
    }
  );
  const apiUtils = api.useContext();

  const update = api.datasets.fields.update.useMutation();
  const refetchActive = apiUtils.datasets.fields.list.refetch;

  const unarchive = useCallback(
    async (fieldId: string) => {
      return update
        .mutateAsync({ id: fieldId, updates: { archived: false } })
        .then(async () => {
          await Promise.all([refetch(), refetchActive({ datasetId })]);
          toast.success("Field unarchived");
        })
        .catch(() => toast.error("Failed to unarchive field"));
    },
    [datasetId, refetch, refetchActive, toast, update]
  );

  if (!fields) return <CircularLoader fillParent />;

  return (
    <Stack vertical>
      {orderBy(fields ?? [], "title").map(field => (
        <Card>
          <Stack>
            <Title variant="bold">{field.title}</Title>
            <Title>{field.key}</Title>

            <Button
              size="small"
              variant="outlined"
              style={{ marginLeft: "auto" }}
              onClickWithLoading={() => unarchive(field.id)}
            >
              Unarchive
            </Button>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};
