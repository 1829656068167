import {
  generateMutation,
  generateQuery,
  generateQueryWithParameters,
  getJson,
  postJson,
  putJson
} from "./ApiBase";
import { Integration } from "../../modules/integrations";

const list = (): Promise<Integration[]> => {
  return getJson("/v1/integrations");
};

interface ActivationRequest {
  integrationId: string;
}

const activate = ({ integrationId }: ActivationRequest): Promise<string> => {
  return postJson(`/v1/integrations/${integrationId}`, {});
};

interface UpdateConfigurationRequest {
  integrationId: string;
  configuration: object;
}

export const updateIntegrationConfiguration = ({
  integrationId,
  configuration
}: UpdateConfigurationRequest) => {
  return putJson(`/v1/integrations/${integrationId}/configuration`, configuration);
};

export interface FindIntegrationRequest {
  integrationId: string;
}

export interface LoadOptionsRequest {
  integrationId: string;
}

export interface IntegrationOptionsResponse {
  integrationId: string;
  options: any;
}

export interface IntegrationField {
  workspaceId: string;
  integrationId: string;
  fieldId: string;
  title: string;
  type: string;
}

export const loadIntegrationOptions = ({ integrationId }: LoadOptionsRequest) => {
  return getJson<IntegrationOptionsResponse>(`/v1/integrations/${integrationId}/options`).then(
    res => res.options
  );
};

export interface LoadIntegrationDataRequest {
  integrationId: string;
  dataKey: string;
}

export const loadIntegrationData = ({ integrationId, dataKey }: LoadIntegrationDataRequest) => {
  return getJson(`/v1/integrations/${integrationId}/data/${dataKey}`);
};

export const findIntegration = ({
  integrationId
}: FindIntegrationRequest): Promise<Integration> => {
  return getJson(`/v1/integrations/${integrationId}`);
};

export const listFields = ({
  integrationId
}: FindIntegrationRequest): Promise<IntegrationField[]> => {
  return getJson(`/v1/integrations/${integrationId}/fields`);
};

export const updateIntegrationFields = ({
  integrationId
}: FindIntegrationRequest): Promise<void> => {
  return postJson(`/v1/integrations/${integrationId}/fields`, {});
};

export const useIntegrationsApi = () => ({
  list: generateQuery<Integration[]>(["integrations"], list),
  find: generateQueryWithParameters<FindIntegrationRequest, Integration>(
    ["integration"],
    findIntegration
  ),
  activate: generateMutation<ActivationRequest, string>(activate),
  listFields: generateQueryWithParameters<FindIntegrationRequest, IntegrationField[]>(
    ["integration", "fields"],
    listFields
  ),
  updateFields: generateMutation<FindIntegrationRequest, void>(updateIntegrationFields),
  loadOptions: generateQueryWithParameters<LoadOptionsRequest, any>(
    ["integration", "options"],
    loadIntegrationOptions
  ),
  loadData: generateQueryWithParameters<LoadIntegrationDataRequest, any>(
    ["integration", "data"],
    loadIntegrationData
  )
});

interface CompleteCredentialsRequest {
  integrationId: string;
  credentials: object;
}

export const completeCredentialConnection = ({
  integrationId,
  credentials
}: CompleteCredentialsRequest) => {
  return putJson(`/v1/integrations/${integrationId}/credentials`, credentials);
};
