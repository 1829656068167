import { useMemo, useState } from "react";
import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { EmailAddressMultiSelect } from "modules/workflows/components/EmailAddressMultiSelect";
import { Button, Modal, Layout } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";

type SlackChannelSelectProps = {
  senderId?: string;
  initialSelected: string[];
  error?: boolean;
  onSelect: (newValue: string[]) => void;
  onAddPrivateChannel: (privateChannel: string) => void;
  readOnly?: boolean;
};

export const SlackChannelSelectV2 = ({
  senderId,
  initialSelected,
  onSelect,
  onAddPrivateChannel,
  readOnly
}: SlackChannelSelectProps) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackChannels, isLoading } = slackSenderApi.listChannel(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const options = useMemo(() => {
    if (isLoading) return [];

    return (slackChannels ?? []).map(channel => ({
      key: channel,
      title: `#${channel}`,
      description: "channel"
    }));
  }, [isLoading, slackChannels]);

  const initialSelectedOptions = useMemo(() => {
    const privateChannels = initialSelected.filter(
      id => !options.find(option => option.key === id)
    );

    const privateChannelOptions = privateChannels.map(channel => ({
      key: channel,
      title: `#${channel}`,
      description: "privateChannel"
    }));

    const channelOptions = options.filter(option => initialSelected.find(id => id === option.key));

    return [...channelOptions, ...privateChannelOptions];
  }, [initialSelected, options]);

  const [privateChannelModalOpen, setPrivateChannelModalOpen] = useState(false);
  const [privateChannel, setPrivateChannel] = useState("");

  return (
    <>
      <EmailAddressMultiSelect
        actions={[
          {
            key: "add",
            title: "Select private channel",
            startAdornmentIcon: "lock",
            onClick: () => {
              setPrivateChannelModalOpen(true);
            }
          }
        ]}
        width={300}
        readOnly={!senderId || readOnly}
        initialOptions={options}
        initialSelectedOptions={initialSelectedOptions}
        onChange={onSelect}
        addButtonText="Add channel"
        addButtonReadOnlyTooltip="Start by selecting a sender"
      />
      <Modal
        icon="lock"
        title="Select a private channel"
        description="Introist is unable to display the list of private channels. Therefore, it's crucial that
        you accurately type the channel name."
        open={privateChannelModalOpen}
        onClose={() => setPrivateChannelModalOpen(false)}
        onCloseWithOutsideClick={() => setPrivateChannelModalOpen(false)}
        backdropElevation="jupiter"
        maxWidth={512}
      >
        <Layout.Group vertical lastChildToEnd gap="xxLarge" style={{ marginTop: "1.5rem" }}>
          <TextInput label="Private channel" onChange={setPrivateChannel} autoFocus />
          <Button
            disabled={!privateChannel}
            onClick={() => {
              setPrivateChannelModalOpen(false);
              onAddPrivateChannel(privateChannel);
            }}
          >
            Add private channel
          </Button>
        </Layout.Group>
      </Modal>
    </>
  );
};
