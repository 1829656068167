import { useSearchParams } from "react-router-dom";
import { api } from "../../../services/rpc/RpcProvider";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { CircularLoader, useToast } from "@introist/react-foundation/v2";

export const DataSourceOAuthRoute = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const mounted = useRef(false);

  const authorize = api.employees.dataSources.oauth.useMutation();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      const code = search.get("code");
      const state = search.get("state");

      if (code && state) {
        authorize
          .mutateAsync({ code, id: state })
          .then(() => {
            toast.success("Data source connected!");
          })
          .catch(() => {
            toast.error("Failed to connect!");
          })
          .finally(() => {
            navigate("/datasources");
          });
      } else {
        toast.error("Failed to connect!");
        navigate("/datasources");
      }
    }
  }, [search, authorize, navigate, toast]);

  return (
    <div>
      <CircularLoader fillParent />
    </div>
  );
};
