import { ReactNode } from "react";
import styled from "styled-components";
import {
  CircularLoader,
  Drawer,
  DrawerProps,
  H4,
  IconButton,
  Layout
} from "@introist/react-foundation/v2";

export type HeaderDrawerProps = Omit<DrawerProps, "onClose"> & {
  startAdornment?: ReactNode;
  title: ReactNode;
  loading?: boolean;
  headerContent?: ReactNode;
  children?: ReactNode;
  onClose: VoidFunction;
};

const StyledHeaderDrawer = styled(Drawer)`
  width: 520px;
  box-sizing: border-box;
  padding: var(--spacing-xLarge);
`;

export const HeaderDrawer = ({
  startAdornment,
  title,
  headerContent,
  loading,
  position = "right",
  children,
  onClose,
  ...rest
}: HeaderDrawerProps) => {
  return (
    <StyledHeaderDrawer {...rest} onClose={onClose} position={position}>
      {loading && <CircularLoader colorVariant="primary" fillParent style={{ flex: 1 }} />}
      <Layout.Group vertical gap="xxLarge">
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            {startAdornment}
            <H4>{title}</H4>
          </Layout.Group>
          <Layout.Group>
            {headerContent}
            <IconButton icon="crossSmall" onClick={onClose} />
          </Layout.Group>
        </Layout.Group>
        <Layout.Group vertical>{children}</Layout.Group>
      </Layout.Group>
    </StyledHeaderDrawer>
  );
};
