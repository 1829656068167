import { DataForm, Select, LabelledCheckbox } from "@introist/react-foundation/v2";

import { WorkflowStep } from "services/api/WorkflowApi";
import { Block } from "components/atoms";
import { prefixForm } from "utils/FormUtils";

import { StepEditorAccordion } from "../../StepEditorAccordion";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { useWorkflows } from "../../../../../flows/hooks/useWorkflows";

type WorkflowTriggerStepEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
  readOnly?: boolean;
};

export const WorkflowTriggerStepEditorV3 = ({
  form,
  formPrefix,
  readOnly
}: WorkflowTriggerStepEditorProps) => {

  const prefixedForm = prefixForm(form, formPrefix);

  const { workflows } = useWorkflows({});

  return (
    <Block blended compact>
      <StepEditorAccordion title="Workflow details" stepOrder={2}>
        <StepEditorAccordion.Row label="Workflow">
          <Select
            attachToRef={false}
            element="button"
            variant="blended"
            searchable
            searchPlaceholder="Find workflow"
            placeholder="Select workflow to trigger"
            value={prefixedForm.get("workflowId")}
            options={workflows?.map(wf => ({ key: wf.id, title: wf.title })) || []}
            onSelect={option => prefixedForm.set("workflowId")(option.key)}
            error={prefixedForm.isError("workflowId")}
            readonly={readOnly}
          />
        </StepEditorAccordion.Row>

        {!readOnly && (
          <LabelledCheckbox
            label="Start as draft"
            checked={prefixedForm.get("startAsDraft")}
            onChange={prefixedForm.set("startAsDraft")}
            readonly={readOnly}
          />
        )}
      </StepEditorAccordion>
    </Block>
  );
};
