import { Icon, IconName } from "@introist/react-foundation/v2";
import styled from "styled-components";

type Props = {
  icon?: string | null;
  fallbackIcon: IconName;
  color?: string | null;
  blended?: boolean;
  small?: boolean;
};

const StyledWorkflowIcon = styled.div<{ $blended?: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => (p.$blended ? null : "2rem")};
  height: ${p => (p.$blended ? null : "2rem")};
  background-color: var(--palette-background-default);
  border: ${p => (p.$blended ? null : "1px solid var(--palette-border-subdued)")};
  border-radius: var(--rounding-medium);
  position: relative;
  background-color: var(--palette-surface-dimmed);
`;

export const IconPickerIcon = ({ icon, color, fallbackIcon, blended, ...rest }: Props) => {
  return (
    <StyledWorkflowIcon {...rest} $blended={blended}>
      <Icon dimmed name={fallbackIcon} />
    </StyledWorkflowIcon>
  );
};
