import styled from "styled-components";
import { Icon, IconName } from "@introist/react-foundation/v2";

const Background = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: var(--rounding-small);
  background: var(--palette-purple-ghosted);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--palette-purple-default) !important;
  }
`;

export const FilledIcon = ({ name }: { name: IconName }) => (
  <Background>
    <Icon name={name} />
  </Background>
);
