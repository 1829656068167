import { useMemo, useRef, useState } from "react";
import { Icon, Option, useResponsive, useTheme, Layout } from "@introist/react-foundation/v2";
import { ButtonRow, ClickBox } from "modules/workflows/components/EmailAddressMultiSelect";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { AvatarBackground, ParticipantCard } from "modules/workflows/components/ParticipantCard";
import { PortalOptionListMultiSelect } from "modules/workflows/components/PortalOptionList";
import { useEmailSenderApi } from "services/api/Senders";
import { GoogleCustomerIdModal } from "modules/workflows/containers/GoogleCustomerIdModal";

type RoomSelectProps = {
  hostId?: string;
  selectedRooms: string[];
  onChange: (rooms: string[]) => void;
  error?: boolean;
  readOnly?: boolean;
};

export const RoomSelect = ({
  hostId,
  selectedRooms,
  onChange,
  readOnly,
  ...rest
}: RoomSelectProps) => {
  const { theme } = useTheme();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { isMobileLayout } = useResponsive();

  const [shouldSetUp, setShouldSetUp] = useState(false);
  const [setupOpen, setSetupOpen] = useState(false);
  const [optionListOpen, setOptionListOpen] = useState(false);

  const emailSenderApi = useEmailSenderApi();
  const listRooms = emailSenderApi.listRooms(
    { hostId: hostId! },
    {
      retry: false,
      enabled: !!hostId,
      onError: (err: any) => {
        if (err.key === "no_customer_id") setShouldSetUp(true);
      }
    }
  );
  const { data: rooms } = listRooms.query;

  const selectActions = useMemo(() => {
    const actions = [];
    if (shouldSetUp) {
      actions.push({
        key: "setup_customer",
        title: "Set up Rooms",
        onClick: () => setSetupOpen(true)
      });
    }
    return actions;
  }, [shouldSetUp]);

  const roomOptions = rooms?.map(room => ({ key: room.email, title: room.name })) || [];
  const selectedRoomOptions = roomOptions.filter(room => selectedRooms.includes(room.key));

  const handleOptionClick = (option: Option) => {
    let nextSelectedRooms: string[];

    if (selectedRooms.includes(option.key)) {
      nextSelectedRooms = selectedRooms.filter(room => room !== option.key);
    } else {
      nextSelectedRooms = selectedRooms.concat(option.key);
    }

    onChange(nextSelectedRooms);
  };

  return (
    <>
      <Layout.Group {...rest} vertical gap="small" ref={anchorRef}>
        {selectedRoomOptions.map(selectedRoom => (
          <ParticipantCard
            readOnly={readOnly}
            icon={
              <AvatarBackground background={theme.palette.warning.ghosted}>
                <Icon
                  name="door"
                  color={theme.palette.warning.default}
                  style={{
                    width: 14
                  }}
                />
              </AvatarBackground>
            }
            key={selectedRoom.key}
            onClick={() => setOptionListOpen(!optionListOpen)}
            title={selectedRoom.title}
            onRemove={() => {
              onChange(selectedRooms.filter(room => room !== selectedRoom.key));
            }}
          />
        ))}
        {!readOnly && (
          <ButtonRow>
            <IconToggleButton
              startIcon="plus"
              active={!!optionListOpen}
              onClick={() => setOptionListOpen(!optionListOpen)}
            >
              Add
            </IconToggleButton>

            <ClickBox onClick={() => setOptionListOpen(false)} />
          </ButtonRow>
        )}
        <PortalOptionListMultiSelect
          actions={selectActions}
          open={optionListOpen}
          referenceElement={anchorRef.current}
          selectedKeys={selectedRooms}
          options={roomOptions}
          onOptionClick={handleOptionClick}
          onClose={() => setOptionListOpen(false)}
          placement={isMobileLayout ? "bottom-start" : "left-start"}
          sameWidthWithReference={isMobileLayout}
        />
      </Layout.Group>
      <GoogleCustomerIdModal
        open={setupOpen}
        onClose={() => setSetupOpen(false)}
        onUpdated={() => {
          setSetupOpen(false);
          listRooms.refetch();
        }}
      />
    </>
  );
};
