import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { SlackChannelSelect } from "./SlackChannelSelect";

export const SlackChannelMessageEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const messageError = prefixedForm.error("message");

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Channel" defaultOpen>
          <Stack vertical alignItems="stretch" gap="large">
            <StepEditorAccordion.Row label="From">
              <SlackSenderSelectV2
                senderId={prefixedForm.get("senderId")}
                onSelectSender={prefixedForm.set("senderId")}
                error={prefixedForm.isError("senderId")}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.Row label="Channel">
              <SlackChannelSelect
                senderId={prefixedForm.get("senderId")}
                value={prefixedForm.get("channel")}
                onChanged={prefixedForm.set("channel")}
                error={prefixedForm.isError("channel")}
              />
            </StepEditorAccordion.Row>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
              underline={false}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
