import { api, JourneyStepV2 } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Card, SkeletonLoader, Stack, Title } from "@introist/react-foundation/v2";
import { WorkflowIcon } from "modules/workflows";
import { JourneyStepStatusIcon } from "modules/journeys/JourneyStepTable";
import { FittedTitle } from "components/atoms";
import { getCombinedFormattedDate } from "utils/DatesUtils";
import { DateFormats } from "utils/dates";
import { Automation } from "../hooks/useAutomations";
import { Skeletons } from "../../../components/molecules";
import { Empty } from "../../../foundation";

type Props = {
  automations?: Automation[];
};

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledJourneyStep = styled.div`
  border-radius: var(--rounding-small);
  padding: var(--spacing-xSmall) var(--spacing-small);
  border: 1px solid #e7e9eb;
  box-sizing: border-box;
`;

const JourneyStep = ({ step }: { step: JourneyStepV2 }) => {
  const formattedDateTime = getCombinedFormattedDate({
    start: step.start,
    formats: {
      dateTimeFormat: DateFormats.date.withMonth.short
    }
  });

  return (
    <StyledJourneyStep>
      <Stack justifyContent="space-between" gap="small">
        <Stack gap="small">
          <JourneyStepStatusIcon status={step.status} />
          <FittedTitle maxLength={64} showTooltip>
            {step.title}
          </FittedTitle>
        </Stack>
        <Stack gap="small" style={{ flexShrink: 0 }}>
          <Title small>{formattedDateTime}</Title>
        </Stack>
      </Stack>
    </StyledJourneyStep>
  );
};

const StyledCard = styled(Card)`
  transition-property: transform, box-shadow;
  transition-duration: 140ms;
  transition-timing-function: ease;

  * {
    user-select: none;
  }

  :hover {
    transform: translateY(-1px);
    box-shadow: rgba(14, 24, 29, 0.1) 0px 8px 19px -2px, rgba(14, 24, 29, 0.3) 0px 4px 12px -8px;
  }
`;

const AutomationCard = ({ automation }: { automation: Automation }) => {
  const { data: journeySteps, isLoading: journeyStepsLoading } = api.journeys.steps.list.useQuery(
    { journeyId: automation.id! },
    {
      enabled: !!automation.id
    }
  );

  const activeSteps = journeySteps?.filter(s => s.status !== "canceled") || [];
  const noActiveSteps = activeSteps.length === 0;

  return (
    <StyledLink to={`/automations/${automation.id}`}>
      <StyledCard>
        <Stack vertical>
          <Stack justifyContent="space-between">
            <Stack gap="small">
              <WorkflowIcon blended />
              <FittedTitle showTooltip maxLength={30} variant="bold">
                {automation.title}
              </FittedTitle>
            </Stack>
            <Title small style={{ marginRight: "4px" }}>
              {activeSteps.length} steps
            </Title>
          </Stack>
          {journeyStepsLoading && (
            <Stack vertical gap="small">
              <SkeletonLoader height={30} />
              <SkeletonLoader height={30} />
              <SkeletonLoader fadeOut height={30} />
            </Stack>
          )}
          {!journeyStepsLoading && activeSteps.length > 0 && (
            <Stack vertical gap="small">
              {activeSteps.map(s => (
                <JourneyStep
                  key={`employee-workflows-grid-journey-step-${s.id}`}
                  // @ts-ignore
                  step={s}
                />
              ))}
            </Stack>
          )}
          {!journeyStepsLoading && noActiveSteps && <Title>No active steps</Title>}
        </Stack>
      </StyledCard>
    </StyledLink>
  );
};

export const AutomationGrid = ({ automations }: Props) => {
  if (!automations) {
    return <Skeletons.Grid />;
  }

  if (automations.length === 0) {
    return <Empty />;
  }

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2, 1200: 3 }}>
      <Masonry gutter="1rem">
        {automations.map((a, idx) => (
          <AutomationCard key={a.id} automation={a} />
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};
