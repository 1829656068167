import styled, { css } from "styled-components";
import {
  Title,
  Icon,
  useTheme,
  TTagColorVariant,
  useColorVariant
} from "@introist/react-foundation/v2";

type AvatarSize = "medium" | "large" | "huge";

export type AvatarProps = {
  size?: AvatarSize;
  nameOrEmail?: string;
  colorVariant?: TTagColorVariant;
  round?: boolean;
};

export const StyledAvatar = styled.div<{
  $size?: AvatarSize;
  $backgroundColor?: string;
  $round?: boolean;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "var(--palette-primary-ghosted)"};

  ${({ $size }) => {
    if ($size === "large") {
      return css`
        width: 1.5rem;
        height: 1.5rem;
        border-radius: var(--rounding-medium);
      `;
    }

    if ($size === "huge") {
      return css`
        width: 2.5rem;
        height: 2.5rem;
        border-radius: var(--rounding-medium);
      `;
    }

    return css`
      width: 1.25rem;
      height: 1.25rem;
      border-radius: var(--rounding-small);
    `;
  }}

  ${({ $round }) =>
    $round &&
    css`
      border-radius: 100%;
    `}
`;

const StyledTitle = styled(Title)<{ $color?: string; $size?: AvatarSize }>`
  font-size: ${({ $size }) => ($size === "huge" ? 14 : 11)}px;
  color: ${({ $color }) => $color || "var(--palette-primary-default)"};
`;

export const getInitials = (fullName: string): string => {
  const namesArray = fullName.trim().split(/\s+/);

  if (namesArray.length > 1) {
    return (namesArray[0][0] + namesArray[namesArray.length - 1][0]).toUpperCase();
  } else if (namesArray.length === 1 && namesArray[0] !== "") {
    return namesArray[0][0].toUpperCase();
  } else {
    return "";
  }
};

export const AttributeAvatar = ({ size }: Pick<AvatarProps, "size">) => {
  const { theme } = useTheme();

  return (
    <StyledAvatar $size={size} $backgroundColor={theme.palette.purple.ghosted}>
      <Icon
        name="attribute"
        color={theme.palette.purple.default}
        style={{
          width: size === "large" ? 16 : 12
        }}
      />
    </StyledAvatar>
  );
};

export const Avatar = ({
  nameOrEmail,
  size = "medium",
  colorVariant = "primary",
  round,
  ...rest
}: AvatarProps) => {
  const color = useColorVariant(colorVariant);
  return (
    <StyledAvatar {...rest} $size={size} $backgroundColor={color.background} $round={round}>
      {nameOrEmail ? (
        <StyledTitle variant="bold" $size={size} $color={color.foreground}>
          {getInitials(nameOrEmail)}
        </StyledTitle>
      ) : (
        <Icon name="smile" color={color.foreground} />
      )}
    </StyledAvatar>
  );
};
