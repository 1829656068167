import { useState, ComponentProps } from "react";
import cx from "classnames";
import { Button, Popover, OptionList } from "@introist/react-foundation/v2";

import {
  EmployeeAttribute,
  useEmployeeAttributeApi
} from "modules/employees/api/EmployeeAttributeApi";

import styles from "./AttributeSelector.module.scss";

export interface AttributeSelectorProps extends ComponentProps<"div"> {
  onAttributeSelected: (attribute: EmployeeAttribute) => unknown;
}

export const AttributeSelector = ({
  onAttributeSelected,
  className,
  ...rest
}: AttributeSelectorProps) => {
  const [popoverRef, setPopoverRef] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <div ref={setPopoverRef} className={cx(styles.Root, className)} {...rest}>
      <Button variant="outlined" startIcon="plus" onClick={e => setOpen(true)}>
        Add attribute
      </Button>
      <AttributeListPopover
        popoverRef={popoverRef}
        open={open}
        onAttributeSelected={onAttributeSelected}
        setOpen={setOpen}
      />
    </div>
  );
};

interface AttributeListPopoverProps {
  popoverRef: HTMLElement | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onAttributeSelected: (attribute: EmployeeAttribute) => void;
  searchable?: boolean;
  presearch?: string;
}

export const AttributeListPopover = ({
  popoverRef,
  open,
  setOpen,
  onAttributeSelected,
  searchable,
  presearch
}: AttributeListPopoverProps) => {
  const attributeApi = useEmployeeAttributeApi();
  const { data: attributes } = attributeApi.list({ includeHidden: true }).query;
  const onSelectAttr = (attr: EmployeeAttribute) => {
    onAttributeSelected(attr);
    setOpen(false);
  };
  return (
    <Popover referenceElement={popoverRef} open={open} onClose={() => setOpen(false)}>
      <OptionList
        searchable={searchable}
        options={
          attributes
            ?.filter(attr => {
              if (!presearch) return true;
              const lowerCasedName = attr.name.toLowerCase();
              const lowerCasedVariable = attr.variable.toLowerCase();
              const lowerCasedPresearchWords = presearch.toLowerCase().split(" ");
              return lowerCasedPresearchWords.every((word: string) => {
                return lowerCasedName.includes(word) || lowerCasedVariable.includes(word);
              });
            })
            .map(attr => ({
              key: attr.variable,
              title: attr.name,
              onClick: () => onSelectAttr(attr)
            })) || []
        }
      />
    </Popover>
  );
};
