import styled, { css, keyframes } from "styled-components";
import { animated, config, useSprings } from "@react-spring/web";
import { useColorVariant } from "@introist/react-foundation/v2";

type Props = {
  vertical?: boolean;
  width?: number;
  disableEnterAnimation?: boolean;
  enterAnimationDelayMs?: number;
  reverse?: boolean;
  small?: boolean;
  dotGap?: "xSmall" | "small";
  colorVariant?: "primary" | "success" | "foreground";
};

const StyledAnimatedDots = styled.div<{ $vertical?: boolean; $small: boolean }>`
  display: grid;
  ${({ $vertical, $small }) => {
    if ($vertical) {
      return css`
        grid-row-gap: ${$small ? "var(--spacing-xSmall)" : "var(--spacing-small)"};
      `;
    } else {
      return css`
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: ${$small ? "var(--spacing-xSmall)" : "var(--spacing-small)"};
      `;
    }
  }}

  ${({ $small }) =>
    $small &&
    css`
      .animated-dot {
        width: 4px;
        height: 4px;
      }
    `}
`;

const pulseKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.2);
  }
  50%, 100% {
    opacity: 0.2;
    transform: scale(1);
  }
`;

const AnimatedDot = styled(animated.div)<{ $backgroundColor?: string }>`
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "var(--palette-success-default)"};
  animation: ${pulseKeyframes} 1000ms infinite ease-out alternate;
`;

export const AnimatedDots = ({
  disableEnterAnimation,
  enterAnimationDelayMs = 0,
  vertical,
  small = false,
  reverse = false,
  colorVariant = "success",
  ...rest
}: Props) => {
  const color = useColorVariant(colorVariant);
  const springs = useSprings(3, index => ({
    from: {
      opacity: 0,
      transform: "scale(0)"
    },
    to: {
      opacity: 1,
      transform: "scale(1)"
    },
    delay: index * 80 + enterAnimationDelayMs,
    config: config.stiff
  }))[0];

  return (
    <StyledAnimatedDots {...rest} $vertical={vertical} $small={small}>
      <animated.span style={reverse ? springs[2] : springs[0]}>
        <AnimatedDot
          className="animated-dot"
          style={!reverse ? {} : { animationDelay: "500ms" }}
          $backgroundColor={color.foreground}
        />
      </animated.span>
      <animated.span style={springs[1]}>
        <AnimatedDot
          className="animated-dot"
          style={{ animationDelay: "250ms" }}
          $backgroundColor={color.foreground}
        />
      </animated.span>
      <animated.span style={reverse ? springs[0] : springs[2]}>
        <AnimatedDot
          className="animated-dot"
          style={!reverse ? { animationDelay: "500ms" } : {}}
          $backgroundColor={color.foreground}
        />
      </animated.span>
    </StyledAnimatedDots>
  );
};
