import { Select, Title, Stack } from "@introist/react-foundation/v2";
import { useIntegrationsApi } from "services/api/IntegrationsApi";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Block } from "components/atoms";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";
import { prefixForm } from "../../../../utils/FormUtils";
import { EmailAddressList } from "../email/EmailAddressList";
import { StepEditorProps } from "../useStepEditor";

export const TrelloBoardStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const integrationApi = useIntegrationsApi();
  const { data: options } = integrationApi.loadOptions({ integrationId: "trello" }).query;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Board" stepOrder={2}>
          <Stack vertical alignItems="stretch" gap="large">
            <Title>
              Set the name for the new board and select which board is used as a template.
            </Title>
            <StepEditorAccordion.Row label="Name">
              <FieldAwareInput
                size="small"
                variant="outlined"
                placeholder="Name"
                value={prefixedForm.get("name")}
                onChange={prefixedForm.set("name")}
                error={prefixedForm.isError("name")}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.Row label="Template">
              <Select
                searchable
                style={{ width: "100%" }}
                size="small"
                placeholder="Template"
                options={(options ?? []).map((opt: any) => ({ key: opt.id, title: opt.name }))}
                value={prefixedForm.get("templateBoardId")}
                onSelect={opt => {
                  prefixedForm.set("templateBoardId")(opt.key);
                }}
                attachToRef={false}
              />
            </StepEditorAccordion.Row>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Members" stepOrder={3}>
          <Stack vertical alignItems="stretch" gap="large">
            <Title>Invite members to the created board.</Title>
            <StepEditorAccordion.MultiOptionRow label="Members">
              <EmailAddressList
                value={prefixedForm.get("members")}
                onChange={prefixedForm.set("members")}
                placeholder="Add member"
              />
            </StepEditorAccordion.MultiOptionRow>
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
