import { Icon, IconButton, Option, Stack, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";

const ListItem = styled(Stack)`
  display: flex;
  align-items: center;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-small);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);
    cursor: pointer;

    button {
      opacity: 1;
    }
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

export const InlineOptionList = ({
  value,
  onChange
}: {
  value: Option[];
  onChange?: (value: Option[]) => void;
}) => (
  <Stack vertical gap="xSmall">
    {value.map(item => (
      <ListItem gap="small">
        {item.startAdornment}
        {item.startAdornmentIcon && <Icon name={item.startAdornmentIcon} />}
        <Title variant="bold">{item.title ?? item.key}</Title>
        {onChange && (
          <RemoveButton
            onClick={() => onChange(value.filter(i => i.key !== item.key))}
            icon="crossSmall"
            style={{ marginLeft: "auto" }}
          />
        )}
      </ListItem>
    ))}
  </Stack>
);
