import { useCallback, useMemo } from "react";
import { DataForm, Select, Icon, Layout, useTheme, Title } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { SlackSenderSelectV2 } from "./SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { prefixForm } from "utils/FormUtils";
import { SlackUserSelectV2 } from "./SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackUserSelectV2";
import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { AvatarBackground } from "modules/workflows/components/ParticipantCard";

type SlackInviteToChannelEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
  readOnly?: boolean;
  journeyAttributes?: { [index: string]: string };
  isJourneyEditor?: boolean;
};

export const SlackInviteToChannelEditor = ({
  form,
  formPrefix,
  readOnly,
  journeyAttributes,
  isJourneyEditor
}: SlackInviteToChannelEditorProps) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, formPrefix ?? "stepData.");

  const selectedSenderId = useMemo(() => {
    return prefixedForm.get("slackSenderId");
  }, [prefixedForm]);

  const slackSenderApi = useSlackSenderApi();
  const { data: slackChannels } = slackSenderApi.listChannel(
    { senderId: selectedSenderId },
    { enabled: !!selectedSenderId }
  ).query;

  const getArray = useCallback(
    (key: string) => {
      const value = prefixedForm.get(key);
      return Array.isArray(value) ? value : [];
    },
    [prefixedForm]
  );

  return (
    <Block blended compact>
      <StepEditorAccordion title="Invitations" stepOrder={2}>
        <Layout.Group vertical alignItems="stretch" gap="large">
          <StepEditorAccordion.Row label="Inviter">
            <SlackSenderSelectV2
              senderId={prefixedForm.get("slackSenderId")}
              onSelectSender={prefixedForm.set("slackSenderId")}
              placeholder="Select inviter"
              readOnly={readOnly}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            {readOnly && (
              <Layout.Group
                style={{
                  paddingLeft: theme.spacing.small
                }}
              >
                <AvatarBackground background={theme.palette.primary.ghosted}>
                  <Icon name="hashtag" color={theme.palette.primary.default} />
                </AvatarBackground>
                <Title variant="bold">{prefixedForm.get("channelName")}</Title>
              </Layout.Group>
            )}
            {!readOnly && (
              <Select
                element="button"
                variant="blended"
                searchable
                searchPlaceholder="Select or type private channel"
                startAdornment={<Icon name="hashtag" />}
                options={(slackChannels ?? []).map(c => ({
                  key: c,
                  startAdornment: <Icon name="hashtag" />
                }))}
                value={prefixedForm.get("channelName")}
                onSelect={opt => prefixedForm.set("channelName")(opt.key)}
                onEnter={search => prefixedForm.set("channelName")(search)}
                placeholder="Select channel"
                attachToRef={false}
              />
            )}
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="Users">
            <SlackUserSelectV2
              isJourneyEditor={isJourneyEditor}
              readOnly={readOnly}
              journeyAttributes={journeyAttributes}
              senderId={prefixedForm.get("slackSenderId")}
              error={prefixedForm.isError("usersToInvite")}
              initialSelected={getArray("usersToInvite")}
              onSelect={prefixedForm.set("usersToInvite")}
              addButtonText="Add users to invite"
            />
          </StepEditorAccordion.MultiOptionRow>
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
