import { MultiOptionList, Option, OptionListProps, Popover } from "@introist/react-foundation/v2";
import { IBaseOptionListProps } from "../../types";

type PortalOptionListMultiSelectProps = IBaseOptionListProps &
  Pick<OptionListProps, "actions"> & {
    selectedKeys?: string[];
    options: Option[];
    onOptionClick: (option: Option) => void;
    width?: number;
  } & {
    backdrop?: boolean;
  };

export const PortalOptionListMultiSelect = ({
  options,
  selectedKeys,
  actions,
  width,
  backdrop,
  onEnter,
  onOptionClick,
  ...rest
}: PortalOptionListMultiSelectProps) => {
  return (
    <Popover
      {...rest}
      backdrop={backdrop}
      style={{
        width: width || 240
      }}
    >
      <MultiOptionList
        searchable
        highlightSearchMatch
        searchPlaceholder="Search"
        selected={selectedKeys}
        options={options}
        actions={actions}
        onItemClick={onOptionClick}
        onEnter={onEnter}
      />
    </Popover>
  );
};
