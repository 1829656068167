import { useState, useEffect } from "react";
import { findAvailability } from "services/api/AvailabilityApi";
import { subtractDays, addDays, getDifference, IntroistDateTimeFormats } from "utils/dates";
import { AvailabilityEventDetails } from "./AvailabilityCalendar";
import { TimeRange } from "services/api/types";
import moment from "moment";

export const useAvailabilitySearch = (eventDetails: AvailabilityEventDetails) => {
  const [range, setRange] = useState<{ from: string; to: string }>({
    from: moment(eventDetails.start.date).startOf("isoWeek").format(IntroistDateTimeFormats.date),
    to: moment(eventDetails.start.date).endOf("isoWeek").format(IntroistDateTimeFormats.date)
  });

  const [slots, setSlots] = useState<TimeRange[] | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    findAvailability({
      timezone: eventDetails.timezone,
      targetEmails: eventDetails.targetEmails,
      hostId: eventDetails.hostId,

      eventLengthMinutes: getDifference(eventDetails.end, eventDetails.start, "minutes"),
      ...range
    })
      .then(res => {
        setSlots(res);
        setIsError(false);
      })
      .catch(() => {
        setSlots(undefined);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [eventDetails, range]);

  const next = () => {
    setRange({ from: addDays(range.from, 7), to: addDays(range.to, 7) });
  };

  const previous = () => {
    setRange({ from: subtractDays(range.from, 7), to: subtractDays(range.to, 7) });
  };

  return { slots, isError, isLoading, range, next, previous };
};
