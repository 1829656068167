import styled, { css } from "styled-components";
import { TabCardTab } from "./TabCard";
import { ReactNode, useEffect, useState } from "react";
import { Title } from "@introist/react-foundation/v2";

type Props = {
  currentTab?: string;
  tabs: TabCardTab[];
  children?: ReactNode;
  onChange: (tab: string) => void;
};

const StyledTabView = styled.div``;

const Header = styled.header`
  display: flex;
  border-bottom: 1px solid var(--palette-border-subdued);
  margin-bottom: 1rem;
`;

const StyledTab = styled.div<{ $active: boolean; $isFirst: boolean }>`
  padding: var(--spacing-small) var(--spacing-large);
  cursor: pointer;
  position: relative;
  border-top-right-radius: var(--rounding-medium);
  border-top-left-radius: var(--rounding-medium);

  ${({ $active }) => {
    if ($active) {
      return css`
        ::after {
          content: "";
          height: 2px;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1.5px;
          border-radius: 2px;
          width: 100%;
          background-color: var(--palette-primary-default);
        }
      `;
    } else {
      return css`
        h5 {
          color: var(--palette-foreground-subdued);
        }
        :hover {
          background-color: var(--palette-surface-subdued);
        }
      `;
    }
  }}
`;

const Tab = ({
  active,
  tab,
  isFirst,
  onClick
}: {
  active: boolean;
  tab: TabCardTab;
  isFirst: boolean;
  onClick: (id: string) => void;
}) => {
  return (
    <StyledTab onClick={() => onClick(tab.id)} $active={active} $isFirst={isFirst}>
      <Title variant="bold">{tab.title || tab.id}</Title>
    </StyledTab>
  );
};

export const TabView = ({ currentTab, tabs, children, onChange, ...rest }: Props) => {
  const [activeTab, setActiveTab] = useState<string>(currentTab || tabs[0].id);

  useEffect(() => {
    if (!currentTab) return;
    if (currentTab === activeTab) return;

    setActiveTab(currentTab);
  }, [activeTab, currentTab, setActiveTab]);

  return (
    <StyledTabView {...rest}>
      <Header>
        {tabs.map((tab, idx) => (
          <Tab
            key={`tab-card-tab-${tab.id}`}
            tab={tab}
            isFirst={idx === 0}
            active={tab.id === activeTab}
            onClick={id => {
              setActiveTab(id);
              onChange(id);
            }}
          />
        ))}
      </Header>
      {children}
    </StyledTabView>
  );
};
