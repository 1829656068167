import { DataForm, Select } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { prefixForm } from "../../../../../../utils/FormUtils";
import { ReactNode, useMemo } from "react";
import { api } from "../../../../../../services/rpc/RpcProvider";

type EmployeeSelectEditorProps = {
  form: DataForm<any>;
  formPrefix: string;
  readOnly?: boolean;
  extras?: ReactNode;
};
export const EmployeeSelectEditor = ({
  form,
  formPrefix,
  readOnly,
  extras
}: EmployeeSelectEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);

  const { data: employeeGroups } = api.employees.groups.list.useQuery({});

  const { data: attributes } = api.employees.fields.list.useQuery({ group: true });

  const sourceOptions = useMemo(() => {
    return (employeeGroups ?? []).map(group => ({ key: group.id, title: group.title }));
  }, [employeeGroups]);

  const targetOptions = useMemo(() => {
    const groupAttributes = (attributes ?? []).filter(attr => attr.type === "group");
    return groupAttributes.map(attr => ({ key: attr.variable, title: attr.name }));
  }, [attributes]);

  return (
    <Block blended compact>
      <StepEditorAccordion title="Assignment" defaultOpen={true} stepOrder={2}>
        <StepEditorAccordion.Row label="Source">
          <Select
            element="button"
            variant="blended"
            placeholder="Select source employee group"
            options={sourceOptions}
            value={prefixedForm.get("sourceGroupId")}
            onSelect={opt => prefixedForm.set("sourceGroupId")(opt.key)}
            attachToRef={false}
            readonly={readOnly}
          />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Target">
          <Select
            element="button"
            variant="blended"
            placeholder="Select attribute group"
            options={targetOptions}
            value={prefixedForm.get("targetGroupAttribute")}
            onSelect={opt => prefixedForm.set("targetGroupAttribute")(opt.key)}
            attachToRef={false}
            readonly={readOnly}
          />
        </StepEditorAccordion.Row>
        {extras}
      </StepEditorAccordion>
    </Block>
  );
};
