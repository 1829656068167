import { Field, Modal, Select, Stack, useToast } from "@introist/react-foundation/v2";
import { RelativeDateInlineEditor } from "../../../../components/molecules";
import { FormFooter } from "../../../datasources/components/FormFooter";
import { Nudge } from "../../useNudges";
import { api } from "../../../../services/rpc/RpcProvider";
import { useCallback, useMemo, useState } from "react";
import { TriggerUpdate } from "../../../triggers";
import { useEmployeeGroups } from "../../../employees/hooks/useEmployeeGroups";

export const NudgeSettingsModal = ({
  nudge,
  open,
  onClose
}: {
  nudge: Nudge;
  open: boolean;
  onClose: () => unknown;
}) => {
  const toast = useToast();
  const { groups } = useEmployeeGroups();

  const { data: trigger, refetch: refetchTrigger } = api.triggers.find.useQuery(
    { id: nudge?.triggerId ?? "" },
    {
      enabled: !!nudge
    }
  );
  const updateTrigger = api.triggers.update.useMutation();

  const [updates, setUpdates] = useState<TriggerUpdate["updates"]>({});

  const groupOptions = useMemo(() => {
    if (!groups) return [];
    return [
      { key: "all", title: "All employees" },
      ...groups.map(g => ({ key: g.id, title: g.title }))
    ];
  }, [groups]);

  const save = useCallback(async () => {
    const finalUpdates = {
      ...updates,
      employeeGroupId: updates.employeeGroupId === "all" ? null : updates.employeeGroupId
    };

    await updateTrigger
      .mutateAsync({ id: nudge!.triggerId, updates: finalUpdates })
      .then(async () => {
        await refetchTrigger();
        toast.success("Settings saved");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to update settings");
      });
  }, [nudge, toast, updateTrigger, refetchTrigger, updates, onClose]);

  return (
    <Modal title="Edit settings" open={open} onClose={onClose} style={{ width: "350px" }}>
      <Stack vertical>
        <Field title="Limit to employees in">
          <Select
            size="small"
            options={groupOptions}
            style={{ width: "100%" }}
            value={updates?.employeeGroupId ?? trigger?.employeeGroupId ?? "all"}
            onSelect={opt => setUpdates({ ...updates, employeeGroupId: opt.key })}
          />
        </Field>
        {!trigger?.triggerEvent && (
          <Field title="Send at most every">
            <RelativeDateInlineEditor
              variant="outlined"
              value={updates?.cooldownPeriod ?? trigger?.cooldownPeriod ?? undefined}
              onChange={cooldownPeriod => setUpdates({ ...updates, cooldownPeriod })}
              hidden={{ side: true }}
            />
          </Field>
        )}
      </Stack>
      <FormFooter onCancel={onClose} onSubmit={save} />
    </Modal>
  );
};
