const colors = [
  "#3FADA9",
  "#4E7EE9",
  "#8859CD",
  "#F0C330",
  "#E47E31",
  "#E54D42",
  "#96A5A6",
  "#A39EFB",
  "#C2476A",
  "#3C3E96",
  "#289EF9",
  "#E64793",
  "#574D8E",
  "#63A4BB",
  "#42749D",
  "#283E74",
  "#FD9F4E",
  "#FD6D83",
  "#FB7375"
];

const numberFromText = (text: string): number => {
  const charCodes = text
    .split("")
    .map(char => char.charCodeAt(0))
    .join("");
  return parseInt(charCodes, 10);
};

export const randomColor = (seed: string) => {
  const seedNum = numberFromText(seed);

  return colors[seedNum % colors.length];
};
