import { TopBarRoute } from "../../components/templates";
import { NotificationSettingsRoute } from "./NotificationSettingsRoute";

export const NotificationsRoute = () => {
  return (
    <TopBarRoute paths={[{ key: "notifications", title: "Notifications" }]}>
      <NotificationSettingsRoute />
    </TopBarRoute>
  );
};
