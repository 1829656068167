import { generateQueryWithParameters, getJson, postJson, putJson } from "./ApiBase";

export interface AuthorizationResponse {
  redirectUrl: string;
}

export interface EmailSender {
  id: string;
  name: string;
  email: string;
  emailProvider: "google" | "smtp" | "outlook";
  calendarId: string;
}

export interface Room {
  id: string;
  name: string;
  email: string;
}

export const getAuthorizationLink = (provider: string): Promise<AuthorizationResponse> => {
  return getJson(`/v1/emailsenders/authorize?provider=${provider}`);
};

export interface ListEmailSendersRequest {
  filterCalendarOnlySenders?: boolean;
  filterEmailOnlySenders?: boolean;
}

const getEmailSenders = async ({
  filterCalendarOnlySenders,
  filterEmailOnlySenders
}: ListEmailSendersRequest): Promise<EmailSender[]> => {
  let senders = await getJson<EmailSender[]>(`/v1/emailsenders`);

  if (filterEmailOnlySenders) {
    senders = senders.filter(s => s.emailProvider !== "smtp");
  }
  if (filterCalendarOnlySenders) {
    senders = senders.filter(s => s.calendarId === "primary");
  }

  return senders;
};

export const createEmailSender = (
  provider: string,
  authorizationCode: string
): Promise<unknown> => {
  return postJson(`/v1/emailsenders`, { provider, authorizationCode });
};

export const updateEmailSender = (id: string, update: { name: string }): Promise<unknown> => {
  return putJson(`/v1/emailsenders/${id}`, update);
};

export interface ListEmailSenderRoomsRequest {
  hostId: string;
}

export const listEmailSenderRooms = async ({
  hostId
}: ListEmailSenderRoomsRequest): Promise<Room[]> => {
  return getJson(`/v1/emailsenders/${hostId}/rooms`);
};

export interface Calendar {
  id: string;
  name: string;
}

export interface ListEmailSenderCalendarsRequest {
  hostId: string;
}

export const listEmailSenderCalendars = async ({
  hostId
}: ListEmailSenderCalendarsRequest): Promise<Calendar[]> => {
  return getJson(`/v1/emailsenders/${hostId}/calendars`);
};

export interface EventSearchRequest {
  hostId: string;
  search: string;
}

export interface SearchedEvent {
  id: string;
  summary: string;
  start: string;
  end: string;
  isRecurring: boolean;
  recurringEventId: string;
}

export interface RecurringEvent {
  id: string;
  originalId: string;
  summary: string;
  description: string;
  recurrence: string[];
}

export const searchRecurringEventsForSender = async ({
  hostId,
  search
}: EventSearchRequest): Promise<RecurringEvent[]> => {
  return postJson(`/v1/emailsenders/${hostId}/search/recurring`, { search });
};

export const useEmailSenderApi = () => ({
  list: generateQueryWithParameters<ListEmailSendersRequest, EmailSender[]>(
    ["emailsenders"],
    getEmailSenders
  ),
  listRooms: generateQueryWithParameters<ListEmailSenderRoomsRequest, Room[]>(
    ["emailsenders", "rooms"],
    listEmailSenderRooms
  ),
  listCalendars: generateQueryWithParameters<ListEmailSenderCalendarsRequest, Calendar[]>(
    ["emailsenders", "calendars"],
    listEmailSenderCalendars
  )
});
