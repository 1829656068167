import { Icon, Option, useTheme, useToast } from "@introist/react-foundation/v2";
import { CompanyEventInstanceAttendee, api } from "services/rpc/RpcProvider";
import { useEventData } from "../../routes/hooks/use-event-data";
import { useNavigate } from "react-router-dom";
import { useRescheduleCompanyEventAttendee } from "modules/events/hooks";

export const useEventAttendeeActions = (companyEventId: string) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { mutateAsync: cancel } = api.companyEvents.attendees.cancel.useMutation({});
  const { rescheduleAttendee, reinviteAttendee } =
    useRescheduleCompanyEventAttendee(companyEventId);

  const { reload } = useEventData(companyEventId);

  const getEventAttendeeActions = (
    attendee: CompanyEventInstanceAttendee,
    onShowAttendeeHistory: (attendeeId: string) => void
  ): Option[] => {
    const options: Option[] = [
      {
        key: "event-attendee-list-row-action-show-history",
        startAdornment: <Icon name="history" />,
        title: "Show history",
        onClick: () => onShowAttendeeHistory(attendee.id)
      }
    ];

    if (attendee.status === "active") {
      options.push({
        key: "event-attendee-list-row-action-auto-rechedule",
        startAdornmentIcon: "calendarForward",
        title: "Reschedule automatically",
        onClick: async () => await rescheduleAttendee(attendee.id)
      });
      options.push({
        key: "event-attendee-list-row-action-manual-rechedule",
        startAdornmentIcon: "calendarForward",
        title: "Reschedule manually",
        onClick: () =>
          navigate(`#reschedule-attendee=${attendee.id}`, {
            relative: "path"
          })
      });
      options.push({
        key: "reinvite",
        startAdornment: <Icon name="email" />,
        title: "Resend invitation",
        onClick: async () => reinviteAttendee(attendee.id)
      });
      options.push({
        key: "event-attendee-list-row-action-remove-attendee",
        startAdornment: <Icon name="crossCircle" color={theme.palette.danger.default} />,
        title: "Remove from event",
        titleStyles: {
          color: theme.palette.danger.default
        },
        onClick: async () => {
          await cancel(
            {
              attendeeId: attendee.id
            },
            {
              onSuccess: () => {
                toast.success("Event attendee removed");
                reload();
              },
              onError: () => toast.error("Failed to remove event attendee")
            }
          );
        }
      });
    }

    return options;
  };

  return {
    getEventAttendeeActions
  };
};
