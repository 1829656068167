import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Block } from "../../../../components/atoms";
import { useStepOptions } from "../useStepOptions";
import { prefixForm } from "../../../../utils/FormUtils";
import { useDataSources } from "../../../datasources/hooks/useDataSources";
import { useMemo } from "react";
import { Field, Option, Select, TextArea } from "@introist/react-foundation/v2";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";

export const JiraIssueStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { dataSources } = useDataSources();

  const { options } = useStepOptions(form.data.stepType, {
    datasourceId: prefixedForm.get("datasourceId")
  });

  const jiraSourceOptions = useMemo(() => {
    if (!dataSources) return [];
    return dataSources
      .filter(ds => ds.sourceType === "jira")
      .map(s => ({ key: s.id, title: s.name }));
  }, [dataSources]);

  const projectOptions: Option[] = useMemo(() => {
    if (!options || !options.projects) return [];
    return options.projects.map((p: any) => ({ key: p.id, title: p.name }));
  }, [options]);

  const issueTypeOptions = useMemo(() => {
    if (!options || !options.issueTypes) return [];
    return options.issueTypes.map((p: any) => ({ key: p.id, title: p.name }));
  }, [options]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion
          title="Target"
          defaultOpen={!form.data.id}
          description={projectOptions.find(o => o.key === prefixedForm.get("projectId"))?.title}
        >
          <StepEditorAccordion.Row label="Integration">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={jiraSourceOptions}
              value={prefixedForm.get("datasourceId")}
              onSelect={opt => prefixedForm.set("datasourceId")(opt.key)}
              placeholder="Select integration"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Project">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={projectOptions}
              value={prefixedForm.get("projectId")}
              onSelect={opt => prefixedForm.set("projectId")(opt.key)}
              placeholder="Select project"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Issue type">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={issueTypeOptions}
              value={prefixedForm.get("issueTypeId")}
              onSelect={opt => prefixedForm.set("issueTypeId")(opt.key)}
              placeholder="Select issue type"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Issue">
          <Field title="Summary">
            <FieldAwareInput
              value={prefixedForm.get("summary")}
              onChange={prefixedForm.set("summary")}
              error={prefixedForm.isError("summary")}
              placeholder="Issue summary"
              size="small"
            />
          </Field>
          <Field title="Description">
            <TextArea
              value={prefixedForm.get("description")}
              onChange={prefixedForm.set("description")}
              rows={10}
              variant="blended"
              style={{ background: "var(--palette-surface-subdued)" }}
            />
          </Field>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
