import { BreadcrumbPath } from "@introist/react-foundation/v2";
import { appRoutes } from "AppRoutes";
import { EventRouteParams } from "modules/events";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CompanyEvent } from "services/rpc/RpcProvider";

export const useEventBreadcrumbs = (companyEvents?: CompanyEvent[]) => {
  const { eventId } = useParams<EventRouteParams>();
  const navigate = useNavigate();

  const event = companyEvents?.find(e => e.id === eventId);

  const breadcrumbs = useMemo(() => {
    const crumbs: BreadcrumbPath[] = [];
    crumbs.push({
      key: "events-root-breadcrumb",
      title: appRoutes.events.title!,
      onClick: () => navigate(appRoutes.events.path)
    });

    if (event) {
      crumbs.push({
        key: "events-event-breadcrumb",
        title: event.title,
        onClick: () => navigate(appRoutes.event.path.replace(":eventId", event.id))
      });
    }

    return crumbs;
  }, [event, navigate]);

  return breadcrumbs;
};
