import { Layout } from "@introist/react-foundation/v2";
import { CopyableReadOnlyInput } from "components/molecules";
import { useIntegrationsApi } from "services/api/IntegrationsApi";

type AshbyConfiguration = {
  secret?: string;
  webhookUrl?: string;
};

export const AshbyConfigurationContainer = ({ ...rest }) => {
  const api = useIntegrationsApi();
  const findIntegration = api.find({ integrationId: "ashby" });

  const { data: integration } = findIntegration.query;

  const configuration = integration?.configuration as AshbyConfiguration;

  return (
    <Layout.Group {...rest} vertical gap="xLarge">
      <CopyableReadOnlyInput
        label="Webhook URL"
        disabled={!configuration}
        value={configuration?.webhookUrl || "Not configured"}
        onCopyMessage="Webhook URL copied"
      />

      <CopyableReadOnlyInput
        label="Webhook secret"
        disabled={!configuration}
        value={configuration?.secret || "Not configured"}
        onCopyMessage="Webhook secret copied"
      />
    </Layout.Group>
  );
};
