import moment, { Moment } from "moment";

import { IntroistDateTime, IntroistTime } from "../services/api/WorkflowApi";
import { formatIntroistDateTime, DateFormats } from "./dates";

export const asShortIsoDate = (date: string | Date | Moment) => moment(date).format("YYYY-MM-DD");

export const getTimeZone = (): string | undefined => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    return undefined;
  }
};

export const convertToMoment = (dateTime: IntroistDateTime) => {
  const [hours, minutes] = getHoursAndMinutes(dateTime.time);
  return moment(dateTime.date).hours(hours).minutes(minutes).tz(dateTime.timezone, true);
};

const getHoursAndMinutes = (time: IntroistTime) => {
  const [hours, minutes] = time.split(":");
  return [parseInt(hours, 10), parseInt(minutes, 10)];
};

export const compareIntroistDateTimes = (a: IntroistDateTime, b: IntroistDateTime) => {
  const dateA = convertToMoment(a).valueOf();
  const dateB = convertToMoment(b).valueOf();
  if (dateA === dateB) return 0;
  return dateA < dateB ? -1 : 1;
};

export const isSame = (a: IntroistDateTime, b: IntroistDateTime) =>
  compareIntroistDateTimes(a, b) === 0;

export const convertTimeToDate = (time: string) => {
  const [hours, minutes] = time.split(":");
  return moment().hours(parseInt(hours, 10)).minutes(parseInt(minutes, 10));
};

export const getFormattedDateForEvent1 = (
  start: IntroistDateTime,
  end: IntroistDateTime,
  formats?: {
    dateTimeFormat?: DateFormat;
  }
) => {
  const formattedStart = formatIntroistDateTime(
    start,
    formats?.dateTimeFormat || DateFormats.datetime.withDay.long
  );
  const isSameDay = start.date === end.date;
  const formattedEnd = isSameDay
    ? `-${formatIntroistDateTime(end, DateFormats.time)}`
    : ` - ${formatIntroistDateTime(end, formats?.dateTimeFormat || DateFormats.datetime.long)}`;
  return `${formattedStart}${formattedEnd}`;
};

type CombinedFormatArgs = {
  start: IntroistDateTime;
  end?: IntroistDateTime;
  formats?: {
    dateTimeFormat?: DateFormat;
    dateFormat?: DateFormat;
  };
};

export const getCombinedFormattedDate = ({
  start,
  end,
  formats: incomingFormats
}: CombinedFormatArgs): string => {
  const formats = {
    dateTimeFormat: DateFormats.datetime.withMonth.long,
    dateFormat: DateFormats.date.withMonth.long,
    ...incomingFormats
  };

  // If no end is provided, just return the formatted start
  if (!end) {
    return formatIntroistDateTime(start, formats.dateTimeFormat);
  }

  // All day check
  if (start.time === "00:00" && end.time === "00:00") {
    return `${formatIntroistDateTime(start, DateFormats.date.long)} all day`;
  }

  // Format the start datetime
  const formattedStart = formatIntroistDateTime(
    start,
    formats?.dateTimeFormat || DateFormats.datetime.withDay.long
  );

  // Check if the event spans within the same day or multiple days
  const isSameDay = start.date === end.date;
  const formattedEnd = isSameDay
    ? formatIntroistDateTime(end, DateFormats.time)
    : formatIntroistDateTime(end, formats?.dateTimeFormat || DateFormats.datetime.long);

  return `${formattedStart} - ${formattedEnd}`;
};

export const formatDate = (date: string, format?: string) => {
  return moment(date).format(format || DateFormats.date.long);
};

export const formatDateTime = (datetime: string, format?: string) => {
  return moment(datetime).format(format || DateFormats.datetime.long);
};

type DateFormat = string;

export const getFormattedDate = (date: IntroistDateTime, format?: string) => {
  return convertToMoment(date).format(format || DateFormats.datetime.long);
};
