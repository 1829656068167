import styled from "styled-components";
import { CloseIconButton, H4, Layout, Modal, ModalProps } from "@introist/react-foundation/v2";
import { AddCompanyEventEmployee, AddCompanyEventEmployeeProps } from "./AddCompanyEventEmployee";
import { MediumTag } from "components/atoms";

type AddCompanyEventEmployeeModalProps = AddCompanyEventEmployeeProps &
  Pick<ModalProps, "onClose" | "open"> & {};

const StyledModal = styled(Modal)`
  width: 100%;
  > :first-child > :last-child {
    max-height: initial;
    overflow: visible;
  }
`;

export const AddCompanyEventEmployeeModal = ({
  open,
  onClose,
  companyEvent,
  onEnrolled,
  ...rest
}: AddCompanyEventEmployeeModalProps) => {
  return (
    <StyledModal
      {...rest}
      open={open}
      hideHeader
      onCloseWithOutsideClick={onClose}
      onClose={onClose}
      maxWidth={640}
    >
      <Layout.Group vertical gap="xLarge">
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            <H4>Enroll attendee to</H4>
            <MediumTag colorVariant="outlined" startIcon="calendarCheck">
              {companyEvent.title}
            </MediumTag>
          </Layout.Group>
          <CloseIconButton onClick={onClose} />
        </Layout.Group>
        <AddCompanyEventEmployee companyEvent={companyEvent} onEnrolled={onEnrolled} />
      </Layout.Group>
    </StyledModal>
  );
};
