import { Layout, Title } from "@introist/react-foundation/v2";
import { useStoredWorkspaceState } from "hooks/use-storage-state";
import { useEffect } from "react";
import { StageIcon } from "./StageIcon";
import { Automation } from "../hooks/useAutomations";
import { StatusButton } from "../../../foundation";

interface Props {
  setFilter: (filter: string[]) => void;
  automations?: Automation[];
}

export const AutomationStatusFilter = ({ setFilter, automations }: Props) => {
  const [automationFilter, setAutomationFilter] = useStoredWorkspaceState<string[]>(
    "intr.empl.automations.stages",
    ["draft", "active", "ended"]
  );

  useEffect(() => {
    setFilter(automationFilter);
  }, [automationFilter, setFilter]);

  const toggleFilter = (filters: string[]) => {
    const shouldRemove = filters.some(f => automationFilter.includes(f));
    if (shouldRemove) {
      setAutomationFilter(automationFilter.filter(f => !filters.includes(f)));
    } else {
      setAutomationFilter([...automationFilter, ...filters]);
    }
  };

  return (
    <Layout.Group>
      <StatusButton
        gap="small"
        onClick={() => toggleFilter(["draft"])}
        $active={automationFilter.includes("draft")}
      >
        <StageIcon active={automationFilter.includes("draft")} stage="draft" />
        {automations ? (
          <Title variant="bold">{automations.filter(a => a.stage === "draft").length}</Title>
        ) : undefined}
        <Title>Draft</Title>
      </StatusButton>
      <StatusButton
        gap="small"
        onClick={() => toggleFilter(["active"])}
        $active={automationFilter.includes("active")}
      >
        <StageIcon active={automationFilter.includes("active")} stage="active" />
        {automations ? (
          <Title variant="bold">{automations.filter(a => a.stage === "active").length}</Title>
        ) : undefined}
        <Title>In progress</Title>
      </StatusButton>
      <StatusButton
        gap="small"
        onClick={() => toggleFilter(["ended"])}
        $active={automationFilter.includes("ended")}
      >
        <StageIcon active={automationFilter.includes("ended")} stage="ended" />
        {automations ? (
          <Title variant="bold">{automations.filter(a => a.stage === "ended").length}</Title>
        ) : undefined}
        <Title>Ended</Title>
      </StatusButton>
    </Layout.Group>
  );
};
