import { useRef, useState } from "react";
import styled from "styled-components";
import {
  ActionMenu,
  CircularLoader,
  Icon,
  Layout,
  Option,
  OptionList,
  Popover,
  Tag,
  Title,
  useTheme
} from "@introist/react-foundation/v2";

import { AvatarBackground } from "modules/settings/components/AvatarBackground";

import { TUserRole, WorkspaceUser } from "services/rpc/RpcProvider";

import { capitalizeFirstLetter } from "utils/Text";
import { List, ListRow } from "components/organisms";

type UserListProps = {
  users: WorkspaceUser[];
  loading: boolean;
  readOnly?: boolean;
  onRemoveUser: (userId: string) => Promise<unknown>;
  onChangeRole: (userId: string, role: TUserRole) => unknown;
};

const Empty = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const RolePopoverButton = styled(Layout.Group)<{ $readOnly?: boolean }>`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: ${({ $readOnly }) => ($readOnly ? "default" : "pointer")};
`;

const ROLE_SELECT_OPTIONS: Option[] = [
  {
    key: "admin" as TUserRole,
    title: "Admin"
  },
  {
    key: "owner" as TUserRole,
    title: "Owner"
  },
  {
    key: "user" as TUserRole,
    title: "User"
  }
];

const RoleSelect = ({
  user,
  readOnly,
  onChange
}: {
  user: WorkspaceUser;
  onChange: (role: TUserRole) => void;
  readOnly?: boolean;
}) => {
  const { theme } = useTheme();

  const rolePopoverAnchor = useRef<HTMLDivElement | null>(null);
  const [rolePopoverOpen, setRolePopoverOpen] = useState(false);

  return (
    <RolePopoverButton
      gap="small"
      ref={rolePopoverAnchor}
      onClick={() => {
        !readOnly && setRolePopoverOpen(!rolePopoverOpen);
      }}
      $readOnly={readOnly}
    >
      <Title>{capitalizeFirstLetter(user.role)}</Title>
      {!readOnly && <Icon name="chevronDown" color={theme.palette.foreground.subdued} />}

      <Popover
        placement="bottom-end"
        open={rolePopoverOpen}
        referenceElement={rolePopoverAnchor.current}
        onClose={() => setRolePopoverOpen(false)}
        style={{ width: 172 }}
      >
        <OptionList
          options={ROLE_SELECT_OPTIONS}
          selected={user.role}
          onItemClick={nextSelected => onChange(nextSelected.key as TUserRole)}
        />
      </Popover>
    </RolePopoverButton>
  );
};

export const UserList = ({
  users,
  loading,
  readOnly,
  onRemoveUser,
  onChangeRole,
  ...rest
}: UserListProps) => {
  const { theme } = useTheme();

  if (loading) {
    return (
      <Empty>
        <CircularLoader fillParent />
      </Empty>
    );
  }

  return (
    <List {...rest}>
      {users.map((user, index) => (
        <ListRow key={`workspace-member-list-item-${index}`} gridTemplateColumns="2fr 1fr 1fr">
          <Layout.Group>
            <AvatarBackground $subdued={!user.active}>
              <Title variant="bold">{user.email[0].toUpperCase()}</Title>
            </AvatarBackground>
            <Title>{user.email}</Title>
          </Layout.Group>
          <Layout.Group justifyContent="flex-start">
            <RoleSelect
              user={user}
              onChange={role => onChangeRole(user.userId, role)}
              readOnly={readOnly}
            />
          </Layout.Group>
          <Layout.Group justifyContent="flex-end" gap="xLarge">
            {!user.active && <Tag>Pending</Tag>}
            {!readOnly && (
              <ActionMenu
                attachToRef={false}
                options={[
                  {
                    key: "Remove user",
                    startAdornment: <Icon name="trash" color={theme.palette.danger.default} />,
                    titleStyles: {
                      color: theme.palette.danger.default
                    },
                    onClick: () => onRemoveUser(user.userId)
                  }
                ]}
              />
            )}
          </Layout.Group>
        </ListRow>
      ))}
    </List>
  );
};
