import { useState } from "react";
import { Button, Popover, Card, TimeRangePicker, TimeRange } from "@introist/react-foundation/v2";
import { TimeRangePickerProps } from "@introist/react-foundation/v2/02-blocks/TimePicker/TimeRangePicker";

export interface PopupTimeRangePickerProps extends TimeRangePickerProps {}

export const stringifyRange = (range: TimeRange) => {
  return `${range.start} - ${range.end}`;
};

export const PopupTimeRangePicker = ({ value, ...props }: PopupTimeRangePickerProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  return (
    <div ref={setRef}>
      <Button variant="blended" startIcon="time" onClick={() => setOpen(true)}>
        {stringifyRange(value)}
      </Button>
      <Popover
        referenceElement={ref}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
        attachToRef
      >
        <Card elevated>
          <TimeRangePicker variant="blended" value={value} {...props} />
        </Card>
      </Popover>
    </div>
  );
};
