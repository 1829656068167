import { StatusButton } from "../../../../components/atoms";
import { Stack } from "@introist/react-foundation/v2";

export const AutomationStepStatuses = ({ steps }: { steps?: any[] }) => {
  return (
    <Stack gap="small">
      <StatusButton
        status="scheduled"
        count={(steps ?? []).filter(s => ["scheduled", "delivered"].includes(s.status)).length}
        active
        onClick={() => {}}
      />
      <StatusButton
        status="failing"
        active
        count={(steps ?? []).filter(s => ["failing"].includes(s.status)).length}
        onClick={() => {}}
      />
      <StatusButton
        status="completed"
        active
        count={(steps ?? []).filter(s => ["completed"].includes(s.status)).length}
        onClick={() => {}}
      />
      <StatusButton
        status="canceled"
        active
        count={(steps ?? []).filter(s => ["canceled"].includes(s.status)).length}
        onClick={() => {}}
      />
    </Stack>
  );
};
