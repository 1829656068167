import { UnmanagedEventAttendee } from "../../../../services/rpc/RpcProvider";
import { Button, Layout, Title } from "@introist/react-foundation/v2";
import { EventAttendeeAttendanceStatusTag } from "../EventAttendeeAttendanceStatusTag";
import { Avatar } from "components/molecules";
import { HighlightText } from "components/atoms";
import { StyledListRow } from "components/organisms";

export const UnmanagedAttendeeListRow = ({
  attendee,
  search,
  onAdd
}: {
  attendee: UnmanagedEventAttendee;
  search?: string;
  onAdd: () => Promise<void>;
}) => {
  return (
    <StyledListRow $gridTemplateColumns="1fr 1fr" style={{ marginTop: "-1px" }}>
      <Layout.Group gap="large">
        <Avatar size="large" nameOrEmail={attendee.email} colorVariant="surface" />
        <Layout.Group vertical gap="xSmall" alignItems="flex-start">
          <Title>
            {search ? <HighlightText search={search} text={attendee.email} /> : attendee.email}
          </Title>
        </Layout.Group>
      </Layout.Group>
      <Layout.Group justifyContent="flex-end" gap="xLarge">
        <EventAttendeeAttendanceStatusTag status="unmanaged" />
        <Button variant="outlined" size="small" onClickWithLoading={onAdd}>
          Import
        </Button>
      </Layout.Group>
    </StyledListRow>
  );
};
