import { StepTypeWithEventTypeV2 } from "services/rpc/RpcProvider";
import { WorkflowStepTypes } from "../../../flows/stepTypes";

export type StepTypeCategory = "messaging" | "scheduling" | "task-management" | "productivity";

type StepCategory = "messaging" | "scheduling" | "task-management" | "productivity";

type StepWithMetadata = {
  stepType: StepTypeWithEventTypeV2;
  title: string;
  description: string;
  category: StepCategory;
  readMoreLink?: string;
};

export const STEP_TYPE_CATEGORY_TITLE_MAP: Record<StepTypeCategory, string> = {
  messaging: "Messaging",
  scheduling: "Scheduling",
  "task-management": "Task management",
  productivity: "Productivity"
};

// Be mindful of the order as the steps aren't sorted when displayed in StepTypeSelector
export const STEP_METADATA_MAP: {
  [key: string]: StepWithMetadata;
} = {
  email: {
    stepType: "email",
    title: "Email message",
    description: "Send an email to an employee or third party.",
    category: "messaging"
  },
  emailtask: {
    stepType: "emailtask",
    title: "Email task",
    description: "Distribute tasks via email and monitor their progress",
    category: "task-management"
  },
  slack: {
    stepType: "slack",
    title: "Slack message",
    description: "Send a message to either a channel or through direct messaging",
    category: "messaging"
  },
  slacktask: {
    stepType: "slacktask",
    title: "Slack task",
    description: "Send task via Slack and track completion",
    category: "task-management"
  },
  slackquestion: {
    stepType: "slackquestion",
    title: "Slack question",
    description: "Send question via Slack and utilise the answer",
    category: "task-management"
  },
  "individual-event": {
    stepType: "individual-event",
    title: "Meeting",
    description: "Set up a one-time calendar event",
    readMoreLink: "https://help.introist.com/setting-up-different-type-of-calendar-events",
    category: "scheduling"
  },
  "common-event": {
    stepType: "common-event",
    title: "Event",
    description: "Invite participants to attend a shared event.",
    readMoreLink: "https://help.introist.com/setting-up-different-type-of-calendar-events",
    category: "scheduling"
  },
  event: {
    stepType: "common-event",
    title: "Event",
    description: "Invite participants to attend a shared event.",
    readMoreLink: "https://help.introist.com/setting-up-different-type-of-calendar-events",
    category: "scheduling"
  },
  invite: {
    stepType: "invite",
    title: "Invite to recurring event",
    description: "Invite employee to recurring event",
    category: "scheduling"
  },
  enroll: {
    stepType: "enroll",
    title: "Start another workflow",
    description: "Automatically start another workflow.",
    category: "productivity"
  },
  emailform: {
    stepType: "emailform",
    title: "Email form",
    description: "Gather information using a form distributed via email",
    category: "productivity"
  },
  slackchannelinvite: {
    stepType: "slackchannelinvite",
    title: "Invite to Slack channel",
    description: "Automatically invite members to Slack channel",
    category: "productivity"
  },
  createaccount: {
    stepType: "createaccount",
    title: "Create account",
    description: "Automatically create account to your data sources",
    category: "productivity"
  },
  trelloboard: {
    stepType: "trelloboard",
    title: "Create Trello board",
    description: "Create a board to Trello from template",
    category: "productivity"
  },
  webhook: {
    stepType: "webhook",
    title: "Webhook action",
    description: "Add custom actions to your workflow by sending a webhook to any URL",
    category: "productivity"
  },
  freshserviceonboarding: {
    stepType: "freshserviceonboarding",
    title: "Start Freshservice Onboarding",
    description: "Automatically create Freshservice account",
    category: "productivity"
  },
  eventattend: {
    stepType: "eventattend",
    title: "Attend to event",
    description: "Attend to event",
    category: "scheduling"
  },
  employeeselect: {
    stepType: "employeeselect",
    title: "Assign an employee",
    description: "Assign related employee such as manager or buddy",
    category: "productivity"
  },
  workflowtrigger: {
    stepType: "workflowtrigger",
    title: "Start another workflow",
    description: "Automatically start another workflow.",
    category: "productivity"
  }
} as const;

export const getStepDefaultTitle = (stepType: string) => {
  const typeDef = WorkflowStepTypes.find(st => st.stepType === stepType);
  if (typeDef) return typeDef.title;

  const step = STEP_METADATA_MAP[stepType];

  if (!step) return "Unknown";
  return step.title;
};

export const getTitleForStepType = (stepType: string) => {
  const newStepType = WorkflowStepTypes.find(st => st.stepType === stepType);
  return newStepType?.title ?? STEP_METADATA_MAP[stepType]?.title ?? "Unknown step type";
};
