import { DataForm, Layout } from "@introist/react-foundation/v2";
import { EmailAddressSingleSelect } from "modules/workflows/components/EmailAddressSingleSelect";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useEmailSenderApi } from "services/api/Senders";
import { WorkflowStep } from "services/api/WorkflowApi";
import { PrefixedForm, prefixForm } from "utils/FormUtils";
import {
  EmployeeAttribute,
  useEmployeeAttributeApi
} from "modules/employees/api/EmployeeAttributeApi";

import { GuestsSelect } from "../../components/GuestsSelect";
import { RoomSelect } from "../../components/RoomSelect";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { EventAttendeeStatus } from "services/api/types";
import { isEmail } from "utils/string-templater";
import { JourneyEventStepEditorProps } from "../../EventStepEditor";
import { formatDate } from "utils/DatesUtils";
import { DateFormats } from "utils/dates";

type EventParticipantsBlockProps = JourneyEventStepEditorProps & {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
  readOnly?: boolean;
};

const getArrayValue = (prefixedForm: PrefixedForm, key: string): string[] => {
  const value = prefixedForm.get(key);

  if (!value) return [];
  if (Array.isArray(value)) return value;

  return value.startsWith("[") ? JSON.parse(value) : [value];
};

const isRoom = (email: string) => email.endsWith("resource.calendar.google.com");

export const EventParticipantsBlockV2 = ({
  form,
  readOnly,
  formPrefix,
  attendees,
  isJourneyEditor,
  journeyAttributes,
  ...rest
}: EventParticipantsBlockProps) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const participants = getArrayValue(prefixedForm, "participants");
  const rooms = participants.filter(participant => isRoom(participant));
  const guests = participants.filter(participant => !isRoom(participant));
  const importantGuestIds = getArrayValue(prefixedForm, "importantParticipants");

  // Host
  const emailSenderApi = useEmailSenderApi();
  const { data: hosts } = emailSenderApi.list({ filterEmailOnlySenders: true }, {}).query;

  const hostOptions = hosts?.map(host => ({
    key: host.id,
    title: host.name,
    description: host.email
  }));

  // Guests
  const attributeApi = useEmployeeAttributeApi();
  const { data: employeeAttributes } = attributeApi.list({ includeHidden: false }).query;
  const emailEmployeeAttributes = employeeAttributes?.filter(
    attribute => attribute.type === "email"
  );

  const resolveDescription = (prop: EmployeeAttribute) => {
    const pendingParticipants = form.get("pendingParticipants") || [];

    const pendingUntil = form.get("pendingUntil");
    const pendingUntilFormatted = pendingUntil && formatDate(pendingUntil, DateFormats.date.short);
    const resolvedParticipant = journeyAttributes ? journeyAttributes[prop.variable] : undefined;

    if (!pendingUntil && !resolvedParticipant) return undefined;

    const isPending = !!resolvedParticipant && pendingParticipants.includes(resolvedParticipant);

    const description = `${resolvedParticipant}${
      isPending ? ` - invited ${pendingUntilFormatted}` : ""
    }`;

    return description;
  };

  const employeeAttributeOptions =
    emailEmployeeAttributes?.map(attribute => ({
      key: attribute.variable,
      title: attribute.name,
      description: isJourneyEditor ? resolveDescription(attribute) : undefined
    })) || [];

  const guestOptions = employeeAttributeOptions.filter(option => !isRoom(option.key));

  const optionKeys = guestOptions.map(option => option.key);
  const customGuestKeys = guests.filter(guest => !optionKeys.includes(guest));
  const customGuests = customGuestKeys.map(value => ({
    key: value,
    title: value,
    description: value
  }));
  const allGuestOptions = [...guestOptions, ...customGuests];

  const selectedGuestOptions = allGuestOptions.filter(option => guests.includes(option.key));

  const resolveAttendeeStatus = (value: string): EventAttendeeStatus | undefined => {
    const resolveEmailValue = (value: string) => {
      if (!value) return undefined;
      if (isEmail(value)) return value;
      return journeyAttributes ? journeyAttributes[value] : undefined;
    };

    const emailValue = resolveEmailValue(value);

    if (!emailValue) return undefined;

    const attendee = attendees ? attendees.find(a => a?.email === emailValue) : undefined;

    const status = attendee?.status || "waiting";
    return status;
  };

  return (
    <StepEditorAccordion {...rest} title="Participants">
      <Layout.Group vertical gap="large">
        <StepEditorAccordion.Row label="Host">
          <EmailAddressSingleSelect
            readOnly={readOnly}
            initialSelectedOption={hostOptions?.find(
              option => option.key === prefixedForm.get("hostId")
            )}
            initialOptions={hostOptions || []}
            onChange={prefixedForm.set("hostId")}
          />
        </StepEditorAccordion.Row>

        <StepEditorAccordion.MultiOptionRow label="Guests">
          <GuestsSelect
            readOnly={readOnly}
            isJourneyEditor={isJourneyEditor}
            initialOptions={allGuestOptions || []}
            initialSelectedOptions={selectedGuestOptions}
            importantGuests={importantGuestIds}
            getAttendeeStatus={isJourneyEditor ? resolveAttendeeStatus : undefined}
            onChange={prefixedForm.set("participants")}
            onImportantGuestsChange={prefixedForm.set("importantParticipants")}
          />
        </StepEditorAccordion.MultiOptionRow>
        <div style={{ display: rooms.length === 0 && readOnly ? "none" : "initial" }}>
          <StepEditorAccordion.MultiOptionRow label="Location">
            <RoomSelect
              readOnly={readOnly}
              hostId={prefixedForm.get("hostId")}
              selectedRooms={rooms}
              onChange={nextRooms => prefixedForm.set("participants")([...guests, ...nextRooms])}
            />
          </StepEditorAccordion.MultiOptionRow>
        </div>
      </Layout.Group>
    </StepEditorAccordion>
  );
};
