import styled from "styled-components";
import { Layout } from "@introist/react-foundation/v2";
import { FileItem } from "../File/File";

type AttachmentListProps = {
  files?: File[];
  readOnly?: boolean;
  attachments?: string[];
  onUpdate: (file: File, fileId: string) => unknown;
  onRemove: (fileId: string) => unknown;
};

const StyledAttachmentList = styled(Layout.Group)``;

export const AttachmentList = ({
  files,
  readOnly,
  attachments,
  onUpdate,
  onRemove,
  ...rest
}: AttachmentListProps) => {
  return (
    <StyledAttachmentList {...rest} vertical gap="xSmall">
      {!!attachments &&
        attachments?.map(attachmentId => (
          <FileItem
            readOnly={readOnly}
            key={attachmentId}
            attachmentId={attachmentId}
            onRemove={onRemove}
          />
        ))}
      {!!files &&
        files?.map(file => (
          <FileItem
            file={file}
            onUploaded={fileId => {
              onUpdate(file, fileId);
            }}
            onRemove={onRemove}
          />
        ))}
    </StyledAttachmentList>
  );
};
