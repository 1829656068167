import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout, Title, Button, useToast } from "@introist/react-foundation/v2";

import { updateIntegrationConfiguration, useIntegrationsApi } from "services/api/IntegrationsApi";
import { TextInput } from "components/atoms";

type FreshServiceConfiguration = {
  domain: string;
};

type ZendeskConfigurationContainerProps = {
  integrationId: string;
};

export const ZendeskConfigurationContainer = ({
  integrationId
}: ZendeskConfigurationContainerProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });
  const { data: integration } = findIntegration.query;

  const [domain, setDomain] = useState("");

  useEffect(() => {
    setDomain((integration?.configuration as FreshServiceConfiguration)?.domain ?? "");
  }, [integration]);

  const domainChanged = useMemo(() => {
    if (!integration) return false;
    return domain !== (integration?.configuration as FreshServiceConfiguration)?.domain;
  }, [integration, domain]);

  const onSave = useCallback(async () => {
    await updateIntegrationConfiguration({ integrationId, configuration: { domain } })
      .then(async () => {
        await findIntegration.refetch();
        toast.success("Configuration updated");
      })
      .catch(() => toast.error("Failed to update configuration"));
  }, [integrationId, domain, findIntegration, toast]);

  return (
    <Layout.Group vertical lastChildToEnd gap="xLarge">
      <TextInput
        label="Zendesk domain"
        type="text"
        value={domain}
        onChange={newVal => {
          setDomain(newVal);
        }}
        endAdornment={<Title>.zendesk.com</Title>}
      />
      <Button disabled={!domainChanged} onClickWithLoading={onSave}>
        Save
      </Button>
    </Layout.Group>
  );
};
