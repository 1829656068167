import { Icon } from "@introist/react-foundation/v2";
import { CategorisedOption } from "components/organisms";
import { useIntegrations } from "hooks";
import { capitalize } from "lodash";
import { GroupedEmployeeField } from "services/rpc/RpcProvider";

export const EMPLOYEE_FIELDS_FILTER_CATEGORIES = [
  { title: "Type", key: "type", startAdornment: <Icon name="attribute" /> },
  { title: "Source", key: "source", startAdornment: <Icon name="personAdd" /> }
];

const EMPLOYEE_FIELD_TYPE_FILTER_OPTIONS = [
  { key: "text", title: "Text", category: "type" },
  { key: "date", title: "Date", category: "type" },
  { key: "select", title: "Select", category: "type" },
  { key: "email", title: "Email", category: "type" }
] as const;

export const useEmployeeFieldFilterOptions = (fields: GroupedEmployeeField[]) => {
  const { getIntegration } = useIntegrations();
  const uniqueSources = Array.from(new Set(fields.map(f => f.source)));

  const sourceOptions = uniqueSources.map(s => ({
    key: s,
    title: getIntegration(s)?.name || capitalize(s),
    category: "source"
  }));

  return [...EMPLOYEE_FIELD_TYPE_FILTER_OPTIONS, ...sourceOptions];
};

export const filterEmployeeFields = (
  fields: GroupedEmployeeField[],
  filters: CategorisedOption[],
  search?: string
) => {
  let filteredFields = fields;

  filteredFields = filterByType(filteredFields, filters);

  filteredFields = filterBySource(filteredFields, filters);

  filteredFields = search
    ? filteredFields.filter(
        f =>
          f.name.toLowerCase().includes(search?.toLowerCase() || "") ||
          f.variable.toLowerCase().includes(search?.toLowerCase() || "")
      )
    : filteredFields;

  return filteredFields;
};

const filterByType = (fields: GroupedEmployeeField[], filters: CategorisedOption[]) => {
  const typeFilters = selectedFilters(filters, "type");
  if (typeFilters.length === 0) return fields;

  return fields.filter(f => {
    return typeFilters.includes(f.type);
  });
};

const filterBySource = (fields: GroupedEmployeeField[], filters: CategorisedOption[]) => {
  const sourceFilters = selectedFilters(filters, "source");
  if (sourceFilters.length === 0) return fields;

  return fields.filter(f => {
    return sourceFilters.includes(f.source);
  });
};

const selectedFilters = (filters: CategorisedOption[], category: string) => {
  return filters.filter(f => f.category === category).map(f => f.key);
};
