import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";

export type ListParams = RouterInput["employees"]["v4"]["groups"]["list"];
export type Employee = RouterOutput["employees"]["v4"]["list"][0];

export type FindParams = RouterInput["employees"]["v4"]["groups"]["find"];
export type EmployeeGroup = RouterOutput["employees"]["v4"]["groups"]["list"][0];

export type EmployeeGroupCreate = RouterInput["employees"]["v4"]["groups"]["create"];
export type EmployeeGroupUpdate = RouterInput["employees"]["v4"]["groups"]["update"];

export const useEmployeeGroups = (params?: ListParams) => {
  const toast = useToast();

  const { data: groups, refetch } = api.employees.v4.groups.list.useQuery(params, {
    refetchOnMount: "always"
  });

  const createOp = api.employees.v4.groups.create.useMutation();

  const create = useCallback(
    async (entry: EmployeeGroupCreate) => {
      return createOp
        .mutateAsync(entry)
        .then(async res => {
          await refetch();
          return res;
        })
        .catch(() => {
          toast.error("Failed to create employee group");
        });
    },
    [createOp, refetch, toast]
  );

  return {
    groups,
    create
  };
};

export const useEmployeeGroup = (params: FindParams) => {
  const toast = useToast();
  const { onConfirmAction } = useActions();
  const { data: group, refetch } = api.employees.v4.groups.find.useQuery(params);

  const apiUtils = api.useContext();

  const updateOp = api.employees.v4.groups.update.useMutation();

  const update = async (update: EmployeeGroupUpdate, rethrow?: boolean) => {
    return updateOp
      .mutateAsync(update)
      .then(async () => {
        await refetch();
        await apiUtils.employees.v4.groups.list.refetch();
        toast.success("Employee group updated");
      })
      .catch(e => {
        toast.error("Failed to update Employee group");
        if (rethrow) throw e;
      });
  };

  const archive = onConfirmAction(
    async (onSuccess?: () => Promise<unknown>) => {
      return updateOp
        .mutateAsync({ groupId: params.groupId, updates: { archived: true } })
        .then(async () => {
          await apiUtils.employees.v4.groups.list.refetch();
          onSuccess && (await onSuccess());
          toast.success("Employee group archived");
        })
        .catch(() => {
          toast.error("Failed to archive Employee group");
        });
    },
    {
      icon: "archive",
      title: "Archive employee group",
      description: "Are you sure you want to archive this employee group?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  return { group, update, archive };
};
