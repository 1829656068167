import { useSlackSenderApi } from "../../../../services/api/SlackSenderApi";
import { useMemo, useState } from "react";
import { Button, Icon, Layout, Modal, Option, Select } from "@introist/react-foundation/v2";
import { TextInput } from "../../../../components/atoms";

export const SlackChannelSelect = ({
  senderId,
  value,
  onChanged,
  error
}: {
  senderId: string;
  value: string;
  onChanged: (channel: string) => void;
  error?: boolean;
}) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackChannels, isLoading } = slackSenderApi.listChannel(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const [privateChannelModalOpen, setPrivateChannelModalOpen] = useState(false);
  const [privateChannel, setPrivateChannel] = useState("");

  const options = useMemo(() => {
    if (isLoading) return [];

    const options = [];

    options.push({
      key: "add",
      title: "Select private channel",
      startAdornmentIcon: "lock",
      onClick: (e: any) => {
        setPrivateChannelModalOpen(true);
        e.stopPropagation();
        e.preventDefault();
      }
    });

    for (const channel of slackChannels ?? []) {
      options.push({
        key: channel,
        title: channel
      });
    }

    return options as Option[];
  }, [isLoading, slackChannels]);

  return (
    <>
      <Select
        element="button"
        variant="blended"
        placeholder="Select channel"
        startAdornment={!value ? <Icon name="plusSmall" danger={error} /> : <Icon name="hashtag" />}
        options={options}
        value={value}
        attachToRef={false}
        searchable
        onSelect={opt => onChanged(opt.key)}
        error={error}
      />
      <Modal
        icon="lock"
        title="Select a private channel"
        description="Introist does not list private channels for privacy reasons. Type the exact name of the private channel the sender has access to."
        open={privateChannelModalOpen}
        onClose={() => setPrivateChannelModalOpen(false)}
        onCloseWithOutsideClick={() => setPrivateChannelModalOpen(false)}
        backdropElevation="jupiter"
        maxWidth={512}
      >
        <Layout.Group vertical lastChildToEnd gap="xxLarge" style={{ marginTop: "1.5rem" }}>
          <TextInput label="Private channel" onChange={setPrivateChannel} autoFocus />
          <Button
            disabled={!privateChannel}
            onClick={() => {
              onChanged(privateChannel);
              setPrivateChannelModalOpen(false);
            }}
          >
            Add private channel
          </Button>
        </Layout.Group>
      </Modal>
    </>
  );
};
