import { useCallback, useState } from "react";
import { useToast } from "@introist/react-foundation/v2";
import { get } from "lodash";

export const useObjectForm = <T extends { [index: string]: any }>(
  defaultValue?: Partial<T>,
  defaultChanges?: Partial<T>,
  options?: {
    submitErrorMessage?: string;
  }
) => {
  const toast = useToast();

  const [changes, setChanges] = useState<Partial<T>>(defaultChanges ?? {});
  const [errors, setErrors] = useState<{ [index: string]: string }>({});

  const onChange = useCallback(
    (field: string) => (value: any) => {
      setChanges({ ...changes, [field]: value });
      delete errors[field];
      setErrors(errors);
    },
    [changes, setChanges, errors]
  );

  const reset = useCallback(() => {
    setChanges({});
    setErrors({});
  }, [setChanges, setErrors]);

  const submit = (handler: (changes: Partial<T>) => Promise<unknown>, resetOnSuccess = true) => {
    return async () => {
      await handler(changes)
        .then(() => {
          if (resetOnSuccess) {
            reset();
          }
        })
        .catch(e => {
          const validationErrors = get(e, "data.validationErrors");
          if (validationErrors) {
            const errors = (validationErrors as any[]).reduce(
              (errors: object, next: any) => ({ ...errors, [next.path]: next.message }),
              {}
            );
            setErrors(errors);
          }

          toast.error(options?.submitErrorMessage ?? "Failed to save changes");
          throw e;
        });
    };
  };

  return {
    onChange,
    changes,
    hasChanges: Object.keys(changes).length > 0,
    value: { ...(defaultValue ?? {}), ...changes } as Partial<T>,
    reset,
    submit,
    errors
  };
};
