import { memo, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Checkbox,
  Icon,
  IconName,
  Layout,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router";
import { postJson } from "../../../services/api/ApiBase";
import { animated, useSpring, config, useSprings } from "@react-spring/web";
import { FullsreenView } from "components/templates";
import { ProgressBar } from "components/molecules";
import { AnimatedBlobH2, FittedTitle, TextInput } from "components/atoms";
import { setSelectedWorkspace } from "../../../services/workspace";

const Grid = styled.div`
  flex: 1;
  display: grid;
  align-items: center;
  box-sizing: border-box;
  grid-template-columns: 18rem 1fr;
  grid-gap: 16vw;

  @media (min-width: 1200px) {
    grid-template-columns: 25rem 1fr;
    grid-gap: 18vw;
  }

  @media (min-width: 1600px) {
    grid-gap: 10vw;
  }
`;

const WorkspaceForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 3rem;
  background-color: white;
  position: relative;
  z-index: 1;
`;

const StyledMockNavIllustration = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  border-radius: var(--rounding-large);
  background-color: white;
  height: inherit;
  width: 100%;
  position: relative;

  .divider {
    top: 104px;
    position: absolute;
  }
`;

const RightCol = styled.div`
  height: 100%;
  padding-top: var(--spacing-xxLarge);

  @media (min-width: 1600px) {
    padding-top: 16rem;
  }
`;

const HorizontalFade = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, white 100%);
`;

const HorizontalFadeSmall = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 65%, white 100%);
`;

const VerticalFade = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  bottom: 0rem;
  right: 0rem;
  z-index: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 30%, white 100%);
`;

const VerticalFadeSmall = styled.div`
  position: absolute;
  top: 0;
  left: 45%;
  bottom: 0rem;
  right: 0rem;
  z-index: 1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 80%, white 100%);
`;

const MockNav = styled.div`
  user-select: none;
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  flex-direction: column;
  padding: var(--spacing-xLarge);
  height: 100%;
  border-top-left-radius: var(--rounding-large);
  box-sizing: border-box;
  border-right: 1px solid var(--palette-border-subdued);
  background-color: var(--palette-surface-subdued);

  > :first-child {
    margin-bottom: 6rem;
  }
`;

const WorkspaceName = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  width: 16rem;
  box-sizing: border-box;
  padding: 0 var(--spacing-large);
  border-radius: 10px;
  box-shadow: 0 0 0 0px var(--palette-border-dimmed);
  border: 1px solid var(--palette-border-subdued);
  min-height: 54px;
  user-select: none;
  margin-top: 4px;
  transition-property: box-shadow, border-color;
  transition-duration: 160ms;
  transition-timing-function: ease;

  ${({ $active }) =>
    $active &&
    css`
      box-shadow: 0 0 0 3px var(--palette-primary-dimmed);
      border: 2px solid var(--palette-primary-default);
    `}

  > h5 {
    font-size: 18px;
  }
`;

const StyledMockNavItem = styled(Layout.Group)<{ $highlight?: boolean }>`
  ${({ $highlight }) =>
    $highlight &&
    css`
      background-color: vi;
    `}
`;

const MockNavItem = ({
  icon,
  title,
  highlight
}: {
  icon: IconName;
  title: string;
  highlight?: boolean;
}) => {
  return (
    <StyledMockNavItem $highlight={highlight}>
      <Icon name={icon} />
      <Title variant="bold">{title}</Title>
    </StyledMockNavItem>
  );
};

const AnimatedMockNavIllustration = animated(StyledMockNavIllustration);

const MockNavIllustration = ({ workspaceName }: { workspaceName: string }) => {
  const appearSpring = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(16px)"
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)"
    },
    delay: 200,
    config: { ...config.slow, clamp: true }
  });

  const shadowSpring = useSpring({
    from: {
      boxShadow: "-0px -0px 81px -20px rgba(50, 52, 93, 0), 0 30px 60px -30px rgba(0, 0, 0, 0.0)"
    },
    to: {
      boxShadow:
        "-20px -27px 81px -20px rgba(50, 52, 93, 0.19), 0 30px 60px -30px rgba(0, 0, 0, 0.3)"
    },
    delay: 250,
    config: { ...config.slow, clamp: true }
  });

  const [itemSprings] = useSprings(6, i => ({
    from: {
      opacity: 0,
      transform: "translateX(-16px)"
    },
    to: {
      opacity: 1,
      transform: "translateX(0px)"
    },
    delay: 60 * i + 300
  }));

  return (
    <AnimatedMockNavIllustration style={{ ...appearSpring, ...shadowSpring }}>
      <MockNav>
        <WorkspaceName $active={workspaceName.length > 0}>
          <FittedTitle variant="bold" maxLength={22}>
            {workspaceName}
          </FittedTitle>
        </WorkspaceName>
        <Layout.Group vertical gap="xLarge">
          <animated.div style={itemSprings[0]}>
            <MockNavItem icon="persons" title="Employees" />
          </animated.div>
          <animated.div style={itemSprings[1]}>
            <MockNavItem icon="condition" title="Automations" />
          </animated.div>
          <Layout.Divider />

          <animated.div style={itemSprings[2]}>
            <Layout.Group>
              <MockNavItem icon="trigger" title="Triggers" />
            </Layout.Group>
          </animated.div>

          <animated.div style={itemSprings[3]}>
            <MockNavItem icon="workflows" title="Workflows" />
          </animated.div>
          <Layout.Divider />
          <animated.div style={itemSprings[4]}>
            <MockNavItem icon="events" title="Events" />
          </animated.div>
          <Layout.Divider />
          <animated.div style={itemSprings[5]}>
            <MockNavItem icon="settings" title="Settings" />
          </animated.div>
        </Layout.Group>
      </MockNav>
      <div style={{ overflow: "hidden", position: "relative" }}>
        <Layout.Divider className="divider" />
      </div>
    </AnimatedMockNavIllustration>
  );
};

const MemoizedAnimatedBlobH2 = memo(AnimatedBlobH2);

const TermsAndConditions = styled(Layout.Group)`
  cursor: pointer;
  width: fit-content;
  user-select: none;
  margin-top: var(--spacing-large);

  a {
    font-weight: 500;
    color: var(--palette-foreground-subdued);
    text-decoration: none;

    :hover {
      color: var(--palette-foreground-default);
    }
  }
`;

const useKeyDown = (cb: (key: string) => void) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      cb(event.key);
    },
    [cb]
  );

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      handleKeyDown(event);
    };

    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, [handleKeyDown]);
};

export const CreateWorkspaceOnboardingRoute = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [name, setName] = useState("");
  const [terms, setTerms] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const createDisabled = !name || !terms;
  useKeyDown(key => key === "Enter" && !createDisabled && createWorkspace());

  const createWorkspace = useCallback(async () => {
    await postJson<{ workspaceId: string }>("/onboarding/create", {
      workspaceName: name,
      terms
    })
      .then(({ workspaceId }) => {
        setSelectedWorkspace(workspaceId);
        navigate("/", { replace: true });
      })
      .catch(() => toast.error("Failed to create workspace"));
  }, [name, terms, navigate, toast]);

  const appearSpring = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    delay: 300
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInputFocus(true);
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [setInputFocus]);

  return (
    <FullsreenView
      progressBar={
        <ProgressBar
          animate
          height={6}
          value={name ? 1 : 0}
          total={2}
          backgroundColor="transparent"
        />
      }
    >
      <VerticalFade />
      <VerticalFadeSmall />
      <HorizontalFade />
      <HorizontalFadeSmall />
      <Grid>
        <WorkspaceForm>
          <Layout.Group vertical gap="xLarge">
            <Layout.Group vertical gap="none">
              <MemoizedAnimatedBlobH2 delay={300}>Create a workspace to</MemoizedAnimatedBlobH2>
              <MemoizedAnimatedBlobH2 delay={400}>get started</MemoizedAnimatedBlobH2>
            </Layout.Group>
          </Layout.Group>
          <animated.div style={appearSpring}>
            <TextInput
              id="workspace-name"
              autoFocus={inputFocus}
              value={name}
              onChange={setName}
              placeholder="My workspace"
              style={{ background: "#F2F3F5" }}
            />
            <TermsAndConditions onClick={() => setTerms(!terms)}>
              <Checkbox checked={terms} onChange={() => {}} id="terms" />
              <Title>
                I agree to{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.introist.com/terms-and-conditions"
                  onClick={e => e.stopPropagation()}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.introist.com/privacy-policy-attend"
                  onClick={e => e.stopPropagation()}
                >
                  Privacy Policy
                </a>
              </Title>
            </TermsAndConditions>
          </animated.div>
          <animated.div style={{ ...appearSpring, width: "100%" }}>
            <Button
              id="create-workspace"
              size="large"
              onClickWithLoading={createWorkspace}
              disabled={createDisabled}
              style={{ width: "100%" }}
            >
              Create workspace
            </Button>
          </animated.div>
        </WorkspaceForm>
        <RightCol>
          <MockNavIllustration workspaceName={name} />
        </RightCol>
      </Grid>
    </FullsreenView>
  );
};
