import { useCallback, useState } from "react";
import { H4, SplitButton, Stack, Title, useToast } from "@introist/react-foundation/v2";
import { api } from "../../../../services/rpc/RpcProvider";
import { TabBar } from "../../../../components/molecules";
import { DataSourceTestOverview } from "./DataSourceTestOverview";
import { DataSourceFinalData } from "./DataSourceFinalData";
import { DataSourceRawData } from "./DataSourceRawData";

type DataSourceTestProps = {
  dataSourceId: string;
};

export const DataSourceTest = ({ dataSourceId }: DataSourceTestProps) => {
  const toast = useToast();

  const { data: testReport, refetch: refetchReport } =
    api.employees.dataSources.testReport.useQuery({ dataSourceId });

  const runDataSourceTest = api.employees.dataSources.test.useMutation();

  const [tab, setTab] = useState("overview");

  const [selectedEmployeeSourceId, setSelectedEmployeeSourceId] = useState<string | undefined>();

  const runTest = useCallback(
    async ({ noCache, fullData }: { noCache?: boolean; fullData?: boolean }) => {
      await runDataSourceTest
        .mutateAsync({ dataSourceId, noCache, fullData })
        .then(async () => {
          await refetchReport();
          toast.success("Test data updated");
        })
        .catch(() => {
          toast.error("Failed to run data source test");
        });
    },
    [dataSourceId, runDataSourceTest, toast, refetchReport]
  );

  return (
    <Stack vertical>
      <Stack justifyContent="space-between" style={{ height: "40px" }}>
        <H4>Data source test</H4>
        {testReport && (
          <SplitButton
            variant="outlined"
            size="small"
            options={[
              {
                key: "sample",
                title: "Retest with sample",
                onClickWithLoading: () => runTest({ noCache: true })
              },
              {
                key: "full",
                title: "Retest with full",
                onClickWithLoading: () => runTest({ noCache: true, fullData: true })
              }
            ]}
          />
        )}
      </Stack>
      {testReport && (
        <TabBar
          activeTab={tab}
          onTabChange={tab => setTab(tab)}
          tabs={[
            { id: "overview", title: "Overview" },
            { id: "final", title: "Final" },
            { id: "raw", title: "Raw" }
          ]}
        />
      )}
      {!testReport && (
        <Stack vertical alignItems="center">
          <Title>Get data from data source</Title>
          <SplitButton
            options={[
              {
                key: "sample",
                title: "Run sample",
                onClickWithLoading: () => runTest({ fullData: false })
              },
              {
                key: "full",
                title: "Run full",
                onClickWithLoading: () => runTest({ fullData: true })
              }
            ]}
          />
        </Stack>
      )}
      {testReport && (
        <>
          {tab === "overview" && <DataSourceTestOverview result={testReport} />}
          {tab === "final" && (
            <DataSourceFinalData
              result={testReport}
              selectedEmployeeSourceId={selectedEmployeeSourceId}
              onSelectEmployee={setSelectedEmployeeSourceId}
            />
          )}
          {tab === "raw" && (
            <DataSourceRawData
              result={testReport}
              selectedEmployeeSourceId={selectedEmployeeSourceId}
              onSelectEmployee={setSelectedEmployeeSourceId}
            />
          )}
        </>
      )}
    </Stack>
  );
};
