import { Layout } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "../../../StepEditorAccordion";
import { SlackSenderSelectV2 } from "../../SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { Block } from "components/atoms";
import { SlackUsersSelect } from "modules/flows/step-editors/slack/SlackUsersSelect";
import { MessageEditor } from "components/editors/MessageEditor";

interface SlackMessageSpec {
  senderId: string;
  channels: string[];
  users: string[];
  message: string;
}

type Props = {
  value: SlackMessageSpec;
  onChanged: (value: SlackMessageSpec) => void;
  title?: string;
  stepOrder?: number;
};

export const SlackMessageBuilder = ({ value, onChanged, title, stepOrder }: Props) => {
  return (
    <Block blended compact>
      <StepEditorAccordion title={title ?? "Message details"} stepOrder={stepOrder ?? 3}>
        <Layout.Group vertical gap="xLarge">
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={value.senderId}
              onSelectSender={senderId => onChanged({ ...value, senderId: senderId! })}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="To">
            <SlackUsersSelect
              senderId={value.senderId}
              onChange={users => onChanged({ ...value, users })}
              value={value.users || []}
            />
          </StepEditorAccordion.MultiOptionRow>
          <MessageEditor
            message={value.message}
            onChange={message => onChanged({ ...value, message })}
          />
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
