import { useCallback, useLayoutEffect } from "react";

type Key = {
  key: string;
  isCommand?: boolean;
  disabled?: boolean;
  callback: (event?: KeyboardEvent) => void;
};

export const useKeyDownListener = (keys: Key[]) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const keyPressed = keys.find(key => key.key.toLowerCase() === event.key.toLowerCase());

      if (!keyPressed) return;

      const { key, isCommand, disabled, callback } = keyPressed;

      if (disabled) return;

      if (isCommand) {
        if (
          (event.metaKey || event.ctrlKey) &&
          event.shiftKey &&
          event.key.toLowerCase() === key.toLowerCase()
        ) {
          event.preventDefault();
          callback(event);
        }
      } else {
        if (event.key.toLowerCase() === key.toLowerCase()) {
          callback(event);
        }
      }
    },
    [keys]
  );

  useLayoutEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
};
