import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Button,
  IntroistLoader,
  Layout,
  TextButton,
  Title,
  useToast,
  useTheme
} from "@introist/react-foundation/v2";
import { AccountCalendar, api } from "services/rpc/RpcProvider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";

import { FullsreenView } from "components/templates";
import { CheckList, ProgressBar } from "components/molecules";
import { AnimatedBlobH2 } from "components/atoms";
import { CalendarConnectionStatus } from "./components";
import { ConnectCalendarButton } from "./StartCalendarConnectRoute";
import { appRoutes } from "AppRoutes";
import { config } from "../../../../Config";

const Content = styled(Layout.Group)`
  flex: 1;
  width: 25rem;
  margin: 0 auto;

  > :first-child {
    margin-bottom: 1rem;
  }
`;

export const CompleteCalendarConnectRoute = () => {
  const { provider } = useParams();
  const { theme } = useTheme();
  const toast = useToast();

  const navigate = useNavigate();
  const { search } = useLocation();

  const mounted = useRef(false);

  const [accountId, setAccountId] = useState<string | undefined>();
  const [calendars, setCalendars] = useState<AccountCalendar[] | undefined>([
    { id: "1", name: "Team Meetings" },
    { id: "2", name: "Project Deadlines" },
    { id: "3", name: "Client Appointments" },
    { id: "4", name: "Personal Events" },
    { id: "5", name: "HR Holidays" },
    { id: "6", name: "Marketing Campaigns" },
    { id: "7", name: "IT Maintenance" },
    { id: "8", name: "Executive Board" }
  ]);

  const [calendarIds, setCalendarIds] = useState<string[]>([]);
  const saveDisabled = !calendarIds.length;

  const authorizeAccount = api.companyEvents.connect.authorize.useMutation();
  const submitCalendars = api.companyEvents.connect.complete.useMutation();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      const searchParams = new URLSearchParams(search);
      const code = searchParams.get("code");

      authorizeAccount
        .mutateAsync({
          provider: provider as "google" | "outlook",
          code: code ?? "",
          redirectUrl: `${config.appBaseUrl}/events/calendars/connect/complete/${provider}`
        })
        .then(res => {
          setAccountId(res.accountId);
          setCalendars(res.calendars);
        })
        .catch(() => {
          toast.error("Failed to connect account");
        });
    }
  }, [provider, toast, search, navigate, authorizeAccount]);

  const appearSpring = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    delay: 300
  });

  const selectCalendars = useCallback(async () => {
    await submitCalendars
      .mutateAsync({
        accountId: accountId!,
        calendarIds
      })
      .then(() => navigate("/events"))
      .catch(() => {
        toast.error("Failed to select calendars");
      });
  }, [accountId, calendarIds, toast, navigate, submitCalendars]);

  const toggleCalendarId = (id: string) => {
    if (calendarIds.includes(id)) {
      setCalendarIds(calendarIds.filter(c => c !== id));
    } else {
      setCalendarIds([...calendarIds, id]);
    }
  };

  const skip = () => {
    navigate(appRoutes.events.path);
  };

  if (!calendars || authorizeAccount.isLoading) {
    return (
      <div>
        <IntroistLoader globalCenter />
      </div>
    );
  }

  return (
    <FullsreenView
      overflow="auto"
      progressBar={
        <ProgressBar
          animate
          height={6}
          value={4}
          total={4}
          backgroundColor="transparent"
          fillConfig={{
            color: !accountId ? theme.palette.danger.default : theme.palette.primary.default
          }}
        />
      }
    >
      <Content vertical justifyContent="center" alignItems="stretch" gap="xxLarge">
        <Layout.Group justifyContent="center">
          <CalendarConnectionStatus success={!!accountId} emailProvider={provider!} />
        </Layout.Group>

        {!accountId && (
          <>
            <Layout.Group vertical gap="large">
              <Layout.Group justifyContent="center">
                <AnimatedBlobH2 blobColor="danger">Connection failed</AnimatedBlobH2>
              </Layout.Group>
              <animated.div style={appearSpring}>
                <Title style={{ textAlign: "center" }}>
                  We were unable to connect account. Please try again or skip for now{" "}
                </Title>
              </animated.div>

              <animated.div style={{ ...appearSpring, width: "100%", marginTop: 32 }}>
                <ConnectCalendarButton provider={provider as "google" | "outlook"} />
              </animated.div>
              <animated.div style={{ ...appearSpring, width: "100%" }}>
                <Layout.Group justifyContent="center">
                  <TextButton onClick={skip}>Skip for now</TextButton>
                </Layout.Group>
              </animated.div>
            </Layout.Group>
          </>
        )}
        {accountId && (
          <>
            <Layout.Group vertical gap="large">
              <Layout.Group justifyContent="center">
                <AnimatedBlobH2 delay={300}>Select calendars</AnimatedBlobH2>
              </Layout.Group>
              <animated.div style={appearSpring}>
                <Title style={{ textAlign: "center" }}>
                  Choose calendars that include the events you want to use
                </Title>
              </animated.div>
            </Layout.Group>
            {calendars && (
              <CheckList
                selectedItems={calendarIds}
                onItemClick={toggleCalendarId}
                items={calendars.map(c => ({ id: c.id, title: c.name }))}
              />
            )}

            <Layout.Group vertical gap="large">
              <animated.div style={{ ...appearSpring, width: "100%" }}>
                <Button
                  disabled={saveDisabled}
                  onClickWithLoading={() => selectCalendars()}
                  style={{ width: "100%" }}
                >
                  Done!
                </Button>
              </animated.div>
              <animated.div style={{ ...appearSpring, width: "100%" }}>
                <Layout.Group justifyContent="center">
                  <TextButton onClick={skip}>Skip for now</TextButton>
                </Layout.Group>
              </animated.div>
            </Layout.Group>
          </>
        )}
      </Content>
    </FullsreenView>
  );
};
