import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Block } from "../../../../components/atoms";
import { useStepOptions } from "../useStepOptions";
import { prefixForm } from "../../../../utils/FormUtils";
import { useDataSources } from "../../../datasources/hooks/useDataSources";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Option, Popover, Select, Stack, Title } from "@introist/react-foundation/v2";
import { EmailAddressList } from "../email/EmailAddressList";
import { FieldAwareInput } from "modules/employees/fields/FieldAwareInput";
import { useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";

export interface TemplateSigner {
  recipientId: string;
  recipientType: string;
  roleName: string;
}

export const DocuSignSendTemplateStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { dataSources } = useDataSources();

  const { options } = useStepOptions(form.data.stepType, {
    datasourceId: prefixedForm.get("datasourceId")
  });

  const docuSignSourceOptions = useMemo(() => {
    if (!dataSources) return [];
    return dataSources
      .filter(ds => ds.sourceType === "docusign")
      .map(s => ({ key: s.id, title: s.name }));
  }, [dataSources]);

  const templateOptions: Option[] = useMemo(() => {
    if (!options || !options.templates) return [];
    return options.templates.map((p: any) => ({ key: p.templateId, title: p.name }));
  }, [options]);

  const templateSigners: TemplateSigner[] = useMemo(() => {
    if (!options || !options.templates) return undefined;
    const templateData = options.templates.find(
      (_: any) => _.templateId === prefixedForm.get("templateId")
    );
    if (!templateData) return undefined;
    return templateData.recipients.signers;
  }, [options, prefixedForm]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Template" defaultOpen={!form.data.id}>
          <StepEditorAccordion.Row label="Account">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={docuSignSourceOptions}
              value={prefixedForm.get("datasourceId")}
              onSelect={opt => prefixedForm.set("datasourceId")(opt.key)}
              placeholder="Select DocuSign Platform"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Template">
            <Select
              size="small"
              element="button"
              variant="blended"
              style={{ width: "100%" }}
              options={templateOptions}
              value={prefixedForm.get("templateId")}
              onSelect={opt => prefixedForm.set("templateId")(opt.key)}
              placeholder="Select template"
              attachToRef={false}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      {!!templateSigners && templateSigners.length > 0 && (
        <Signers
          templateSigners={templateSigners}
          templateRoles={
            prefixedForm.get("templateRoles") !== "" ? prefixedForm.get("templateRoles") : []
          }
          onChange={prefixedForm.set("templateRoles")}
        />
      )}
    </>
  );
};

interface TemplateRole {
  email: string;
  name: string;
  roleName: string;
}

const Signers = ({
  templateSigners,
  templateRoles,
  onChange
}: {
  templateSigners: TemplateSigner[];
  templateRoles: TemplateRole[];
  onChange: (roles: TemplateRole[]) => void;
}) => {
  const handleOnChange = (roleName: string, name: string, email: string) => {
    const otherRoles = templateRoles.filter(_ => _.roleName !== roleName);
    onChange([...otherRoles, { roleName, name, email }]);
  };

  const resolveSignerValue = (roleName: string) => {
    const role = templateRoles?.find(tr => tr.roleName === roleName);
    if (!role) return undefined;
    return { name: role.name, email: role.email };
  };
  return (
    <Block blended compact>
      <StepEditorAccordion title="Signers">
        {templateSigners.map(_ => (
          <StepEditorAccordion.Row key={`signer-${_.recipientId}`} label={_.roleName}>
            <Signer
              value={resolveSignerValue(_.roleName)}
              onChange={value => {
                handleOnChange(_.roleName, value.name, value.email);
              }}
            />
          </StepEditorAccordion.Row>
        ))}
      </StepEditorAccordion>
    </Block>
  );
};

const Signer = ({
  value,
  onChange
}: {
  value?: { name: string; email: string };
  onChange: (value: { name: string; email: string }) => void;
}) => {
  const { fields } = useEmployeeFields();

  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [innerValue, setInnerValue] = useState<{ name?: string; email?: string }>(value ?? {});

  useEffect(() => {
    setInnerValue(value ?? {});
  }, [value]);

  const startIcon = useMemo(() => {
    if (!value) return "plus";
    return "person";
  }, [value]);

  const buttonText = useMemo(() => {
    if (!value) return "Add signer";
    const name = value.name;
    const email = fields?.find(_ => _.key === value.email)?.title ?? value.email;
    return `${name} (${email})`;
  }, [value, fields]);

  return (
    <>
      <Button
        ref={setRef}
        startIcon={startIcon}
        variant="blended"
        onClick={() => setOpen(true)}
        size="small"
      >
        {buttonText}
      </Button>
      <Popover
        referenceElement={ref}
        attachToRef={false}
        onClose={() => {
          /* do not close to prevent unwanted closes*/
        }}
        open={open}
        closeOnContentClick={false}
        closeOnEscPress={true}
      >
        <Card>
          <Stack vertical>
            <Stack>
              <Title>Name</Title>
              <FieldAwareInput
                value={innerValue?.name}
                onChange={name => {
                  setInnerValue({ ...innerValue, name });
                }}
                error={false}
                placeholder={"Name"}
                size="small"
              />
            </Stack>
            <Stack>
              <Title>Email</Title>
              <EmailAddressList
                value={innerValue?.email ? [innerValue.email] : []}
                onChange={email => {
                  console.log(email);
                  setInnerValue({
                    ...innerValue,
                    email: email[0]
                  });
                }}
                placeholder={"Email"}
                single
              />
            </Stack>
            <Stack justifyContent="flex-end">
              <Button
                size="small"
                variant="outlined"
                disabled={!innerValue.name || !innerValue.email}
                onClick={() => {
                  onChange({ name: innerValue.name!, email: innerValue.email! });
                  setOpen(false);
                }}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Popover>
    </>
  );
};
