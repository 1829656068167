import { EmployeeFieldForm } from "./EmployeeFieldForm";
import { UpsertModal } from "../../../foundation";
import { EmployeeField, EmployeeFieldCreate, useEmployeeFields } from "../hooks/useEmployeeFields";

export const EmployeeFieldCreateModal = ({
  open,
  onClose,
  onCreated
}: {
  open: boolean;
  onClose: () => void;
  onCreated: (field: EmployeeField) => void;
}) => {
  const { create } = useEmployeeFields();

  return (
    <UpsertModal
      open={open}
      onCreate={async entry => {
        await create(entry as EmployeeFieldCreate).then(onCreated);
      }}
      onUpdate={() => {
        throw new Error("Update not supported");
      }}
      onClose={onClose}
      Form={EmployeeFieldForm}
      entryName="field"
      maxContentHeight="90vh"
    />
  );
};
