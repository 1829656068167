import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Card,
  CircularLoader,
  Icon,
  IconButton,
  IconTag,
  InfoCard,
  Layout,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import {
  findMatchingEvents,
  MatchingEventsRequest,
  MatchingEvent
} from "services/api/AvailabilityApi";
import { JourneyStepV2, api } from "services/rpc/RpcProvider";
import {
  DateFormats,
  formatIntroistDateTime,
  IntroistDate,
  IntroistDateTimeFormats,
  introistDateToMoment
} from "../../../../utils/dates";

import { NoResults } from "components/organisms";
import { Check } from "components/atoms";

import { EventEditor, InnerTime } from "../RescheduleModal";

interface Props {
  timezone: string;
  journeyId: string;
  stepId: string;
  initialMonth: IntroistDate;
  onSelect: (matchingEvent: MatchingEvent) => unknown;
  selected?: MatchingEvent;
  eventSummary?: string;
  hostId?: string;
  selfEventId?: string;
  allowCreate?: boolean;
  onCreateNewEvent?: () => void;
  onInnerTimesChange: (innerTime: InnerTime) => void;
  timeframe: InnerTime;
}

export const addMonths = (date: IntroistDate, months: number) =>
  introistDateToMoment(date).add(months, "months").format(IntroistDateTimeFormats.date);

const ScrollWrapper = styled.div`
  height: 18rem;
  overflow: auto;
  width: 100%;
  border-radius: var(--rounding-medium);
  padding: 0.5rem;
  box-sizing: border-box;
  > div {
    height: 100%;
  }
`;

const StyledNoResults = styled(NoResults)`
  box-sizing: border-box;
`;

const EventCard = styled(Card)<{ $active: boolean }>`
  ${({ $active }) =>
    $active &&
    css`
      border-color: var(--palette-primary-default);
      background-color: var(--palette-primary-ghosted);
    `}
`;

export const MatchingEventCalendar = ({
  timezone,
  journeyId,
  initialMonth,
  onSelect,
  stepId,
  selected,
  eventSummary,
  allowCreate,
  hostId,
  selfEventId,
  timeframe,
  onInnerTimesChange
}: Props) => {
  const { theme } = useTheme();
  const [matchingEvents, setEvents] = useState<MatchingEvent[]>([]);
  const [searchParams, setSearchParams] = useState<MatchingEventsRequest>();
  const [loading, setLoading] = useState<boolean>();

  const { data: step } = api.journeys.steps.find.useQuery({ stepId });

  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    const to = introistDateToMoment(initialMonth)
      .endOf("months")
      .format(IntroistDateTimeFormats.date);
    const from = introistDateToMoment(initialMonth)
      .startOf("months")
      .format(IntroistDateTimeFormats.date);
    setSearchParams({ timezone, workflowStepId: journeyId, to, from, eventSummary, hostId });
  }, [timezone, journeyId, initialMonth, eventSummary, hostId]);

  useEffect(() => {
    if (!searchParams) return;
    setLoading(true);
    findMatchingEvents(searchParams)
      .then(events => (selfEventId ? events.filter(e => e.eventId !== selfEventId) : events))
      .then(setEvents)
      .finally(() => setLoading(false));
  }, [searchParams, selfEventId]);

  const modifyRange = (months: number) => {
    if (!searchParams) return;

    const to = introistDateToMoment(searchParams?.from)
      .add(months, "months")
      .endOf("months")
      .format(IntroistDateTimeFormats.date);

    const from = introistDateToMoment(searchParams?.from)
      .add(months, "months")
      .startOf("months")
      .format(IntroistDateTimeFormats.date);

    setSearchParams({ ...searchParams, to, from });
  };

  const monthYear = searchParams && introistDateToMoment(searchParams.from).format("MMM YYYY");

  const isSelected = (event: MatchingEvent) => {
    if (!selected) return false;
    return selected.eventId === event.eventId && selected.calendarEventId === event.calendarEventId;
  };

  const showNoResults = !loading && matchingEvents.length === 0 && !showEditor;

  return (
    <Layout.Group vertical>
      {!showEditor && (
        <Layout.Group justifyContent="center">
          <IconButton icon="chevronLeft" onClick={() => modifyRange(-1)} />
          <Title variant="bold" style={{ minWidth: 64, textAlign: "center" }}>
            {monthYear}
          </Title>
          <IconButton icon="chevronRight" onClick={() => modifyRange(1)} />
        </Layout.Group>
      )}
      <ScrollWrapper>
        {loading && !showEditor && <CircularLoader size="small" fillParent />}
        {showNoResults && (
          <StyledNoResults
            icon="calendarCross"
            title={<Title variant="bold">Couldn't find matching events</Title>}
            message=""
            children={
              allowCreate && (
                <Button variant="outlined" onClick={() => setShowEditor(true)}>
                  Create new event
                </Button>
              )
            }
          />
        )}
        {showEditor && step && (
          <Layout.Group vertical gap="xxLarge">
            <InfoCard
              title="Be aware that when you create a new shared event, other employees will have the ability to join it as well."
              colorVariant="primary"
            />

            <Layout.Group justifyContent="center" alignItems="center">
              <Layout.Group vertical alignItems="center">
                <IconTag inverted name="calendarAdd" color={theme.palette.success} />
                <Title variant="bold">Create new event</Title>
                <EventEditor
                  step={step as JourneyStepV2}
                  timeframe={timeframe}
                  onInnerTimesChange={onInnerTimesChange}
                />
              </Layout.Group>
            </Layout.Group>
          </Layout.Group>
        )}
        {matchingEvents.length > 0 && !loading && !showEditor && (
          <Layout.Group vertical gap="small">
            {matchingEvents.map(e => (
              <MatchingEventCard
                key={`matching-event-list-item-${e.eventId}`}
                matchingEvent={e}
                selected={isSelected(e)}
                onSelect={onSelect}
              />
            ))}
          </Layout.Group>
        )}
      </ScrollWrapper>
    </Layout.Group>
  );
};

const MatchingEventCard = ({
  matchingEvent,
  selected,
  onSelect
}: {
  matchingEvent: MatchingEvent;
  selected: boolean;
  onSelect: (matchingEvent: MatchingEvent) => void;
}) => {
  const { theme } = useTheme();
  return (
    <EventCard
      key={`matching-event-list-item-${matchingEvent.eventId}`}
      onClick={() => onSelect(matchingEvent)}
      $active={selected}
    >
      <Layout.Group justifyContent="space-between">
        <Layout.Group vertical gap="small">
          <Layout.Group>
            <Icon name="calendar" color={theme.palette.foreground.subdued} />
            <Title variant="bold">
              {formatIntroistDateTime(matchingEvent.start, DateFormats.date.withDay.short)}
            </Title>
          </Layout.Group>
          <Layout.Group>
            <Icon name="time" color={theme.palette.foreground.subdued} />
            <Title>{`${formatIntroistDateTime(
              matchingEvent.start,
              DateFormats.time
            )} - ${formatIntroistDateTime(matchingEvent.end, DateFormats.time)}`}</Title>
          </Layout.Group>
        </Layout.Group>
        <Check checked={selected} />
      </Layout.Group>
    </EventCard>
  );
};
