import { ComponentProps, CSSProperties, useEffect, useMemo, useState } from "react";
import { IconButton, Icon, Stack } from "@introist/react-foundation/v2";

import { TextInput } from "../../atoms/TextInput";

type Props = Omit<ComponentProps<"div">, "onChange"> & {
  editable?: boolean;
  onSave: (newValue: string) => unknown;
  onCancel?: () => unknown;
  initialValue: string;
  inputStyles?: CSSProperties;
  dimmed?: boolean;
  forceEditing?: boolean;
};

export const EditableHeading = ({
  children,
  editable,
  onSave,
  onCancel,
  initialValue,
  inputStyles,
  dimmed,
  forceEditing
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(initialValue ?? "");

  useEffect(() => {
    setEditedValue(initialValue);
  }, [initialValue]);

  const save = () => {
    return Promise.resolve(onSave(editedValue)).then(() => setEditing(false));
  };

  const isEditing = useMemo(() => editing || !!forceEditing, [editing, forceEditing]);

  return (
    <Stack gap="small">
      {!isEditing && children}
      {isEditing && (
        <TextInput
          variant="blended"
          size="small"
          value={editedValue}
          onChange={setEditedValue}
          autoFocus
          style={inputStyles}
        />
      )}
      {editable && !isEditing && (
        <IconButton
          size="small"
          icon={<Icon name="pencil" dimmed={dimmed} />}
          onClick={() => setEditing(true)}
          dimmed
        />
      )}
      {isEditing && (
        <>
          <IconButton icon={<Icon name="check" />} onClickWithLoading={save} />
          <IconButton
            icon={<Icon name="crossSmall" />}
            onClick={() => {
              setEditing(false);
              setEditedValue(initialValue ?? "");
              onCancel && onCancel();
            }}
          />
        </>
      )}
    </Stack>
  );
};
