import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Modal,
  useActions,
  useTheme,
  Title,
  Layout,
  Icon,
  H4,
  DateInput
} from "@introist/react-foundation/v2";

import { MediumTag } from "components/atoms";
import { DateTimeRangePicker } from "components/molecules";
import { MatchingEvent } from "services/api/AvailabilityApi";
import { api } from "services/rpc/RpcProvider";
import { useJourneyStepApi } from "services/api/journeys/JourneyStepApi";
import { DateFormats, IntroistDateTime } from "utils/dates";

import { CalendarEventAvailabilityCalendar } from "./AvailabilityCalendar";

type Props = {
  open: boolean;
  onClose: () => unknown;
  start: IntroistDateTime;
  end?: IntroistDateTime;
  stepId: string;
  journeyId: string;
  onRescheduled: () => Promise<unknown>;
  title?: string;
  description?: string;
  eventSummary?: string;
};

const Container = styled(Layout.Group)`
  margin-top: var(--spacing-xLarge);
`;

export const RescheduleMeetingModal = ({
  open,
  onClose,
  start,
  stepId,
  journeyId,
  onRescheduled,
  end,
  eventSummary
}: Props) => {
  const { onAction } = useActions();
  const { theme } = useTheme();

  const [showEditor, setShowEditor] = useState<boolean>(false);

  const { data: step } = api.journeys.steps.find.useQuery({ stepId });

  const journeyStepApi = useJourneyStepApi();
  const reschedule = journeyStepApi.reschedule();

  const [innerTimes, setInnerTimes] = useState<{ start: IntroistDateTime; end?: IntroistDateTime }>(
    {
      start,
      end
    }
  );
  const [matchingEvent, setMatchingEvent] = useState<MatchingEvent | undefined>();

  useEffect(() => {
    setInnerTimes(times => ({ ...times, start }));
  }, [start, setInnerTimes]);

  useEffect(() => {
    setInnerTimes(times => ({ ...times, end }));
  }, [end, setInnerTimes]);

  const isAllDayEvent = useMemo(() => {
    return innerTimes.start.time === innerTimes.end?.time && step?.stepType === "event";
  }, [innerTimes, step?.stepType]);

  const handleSubmit = onAction(async () => {
    const createRequest = () => {
      if (matchingEvent?.eventId) {
        return {
          eventId: matchingEvent.eventId
        };
      } else if (matchingEvent?.calendarEventId) {
        return {
          calendarEventId: matchingEvent.calendarEventId
        };
      } else {
        return innerTimes;
      }
    };
    await reschedule.mutateAsync({
      stepId,
      journeyId,
      ...createRequest()
    });
    await onRescheduled();
  }, "Cannot reschedule the step");

  const description = showEditor
    ? "Choose new time for the meeting"
    : "The displayed items represent available slots in the participants' schedules";

  return (
    <Modal
      open={open}
      title="Reschedule meeting"
      onClose={onClose}
      description={description}
      primaryButton={{ title: "Reschedule", onClickWithLoading: handleSubmit, size: "small" }}
      secondaryButton={{
        title: showEditor ? "Availabilities" : "Add new time",
        startAdornment: <Icon name={showEditor ? "calendarCheck" : "time"} />,
        onClick: () => setShowEditor(!showEditor),
        size: "small"
      }}
    >
      <Container vertical justifyContent="center" alignItems="center" gap="large">
        <MediumTag colorVariant="outlined">
          <Layout.Group>
            <Icon name="calendar" color={theme.palette.purple.default} />
            <Title variant="bold">{eventSummary}</Title>
          </Layout.Group>
        </MediumTag>
        {!showEditor && !isAllDayEvent && (
          <CalendarEventAvailabilityCalendar
            start={innerTimes.start}
            end={innerTimes.end!}
            calendarEventId={stepId}
            timezone={innerTimes.start.timezone}
            onSelectSlot={slot => setInnerTimes(slot)}
          />
        )}
        {(showEditor || isAllDayEvent) && (
          <Layout.Group vertical justifyContent="center" alignItems="center">
            <H4>Add new time</H4>
            {isAllDayEvent ? (
              <DateInput
                value={innerTimes.start.date}
                displayFormat={DateFormats.date.long}
                onChange={date => {
                  if (!date) return;
                  setInnerTimes({
                    start: { ...innerTimes.start, date },
                    end: { ...innerTimes.end!, date }
                  });
                }}
              />
            ) : (
              <DateTimeRangePicker
                start={innerTimes.start}
                end={innerTimes.end!}
                onChange={(start, end) => {
                  setInnerTimes({ start, end });
                  setMatchingEvent(undefined);
                }}
              />
            )}
          </Layout.Group>
        )}
      </Container>
    </Modal>
  );
};
