import { useMemo } from "react";
import styled from "styled-components";
import { Input, Select, IconButton } from "@introist/react-foundation/v2";
import { RelativeDateFieldValue } from "@introist/introist-commons/dist/object-filter";

import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";

import {
  buildAttributeTypeComparatorOptions,
  Comparator,
  Condition,
  ConditionWithId
} from "./types";
import { RelativeDateEditor } from "./RelativeDateEditor/RelativeDateEditor";
import { isExistsComparison } from "./comparators";

type ConditionRowProps = {
  condition: ConditionWithId;
  attributes: EmployeeAttribute[];
  onChanged: (cond: Condition) => void;
  onRemove?: (id: string) => void;
  readonly?: boolean;
};

const StyledConditionRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 12rem 1fr auto;
  grid-column-gap: var(--spacing-large);
`;

const RemoveButton = styled(IconButton).attrs({ icon: "trash" })`
  align-self: center;
`;

export const ConditionRow = ({
  condition,
  attributes,
  onChanged,
  onRemove,
  readonly
}: ConditionRowProps) => {
  const selectedAttribute = useMemo(() => {
    return attributes.find(a => a.variable === condition.attribute);
  }, [attributes, condition.attribute]);

  const comparator = useMemo(() => {
    const attributeComparatorOptions = buildAttributeTypeComparatorOptions(
      selectedAttribute?.type ?? ""
    );

    const validComparator = !!attributeComparatorOptions.find(
      option => option.key === condition.comparator
    );

    if (validComparator) {
      return condition.comparator;
    } else {
      return attributeComparatorOptions[0].key;
    }
  }, [condition.comparator, selectedAttribute?.type]);

  const valueInput = useMemo(() => {
    if (selectedAttribute?.type === "select" && selectedAttribute?.data?.options) {
      return (
        <Select
          attachToRef={false}
          size="small"
          options={selectedAttribute.data.options.map((o: string) => ({ key: o }))}
          value={condition.value as string}
          readonly={readonly}
          onSelect={value => onChanged({ ...condition, value: value.key })}
        />
      );
    }

    if (selectedAttribute?.type === "date") {
      return (
        <RelativeDateEditor
          value={condition.value as RelativeDateFieldValue}
          onChange={value => onChanged({ ...condition, value })}
        />
      );
    }

    return (
      <Input
        size="small"
        value={condition.value.toString()}
        readonly={readonly}
        onChange={value => onChanged({ ...condition, value })}
      />
    );
  }, [condition, onChanged, readonly, selectedAttribute?.data?.options, selectedAttribute?.type]);

  return (
    <StyledConditionRow>
      <Select
        attachToRef={false}
        size="small"
        style={{ width: "initial" }}
        options={attributes.map(a => ({ key: a.variable, title: a.name }))}
        placeholder="Attribute"
        value={condition.attribute}
        readonly={readonly}
        onSelect={opt => {
          const attribute = attributes.find(a => a.variable === opt.key);
          const comparator = buildAttributeTypeComparatorOptions(attribute?.type ?? "text")[0].key;

          onChanged({
            ...condition,
            attribute: opt.key,
            comparator: comparator as Comparator,
            value: ""
          });
        }}
        searchable
      />
      <Select
        attachToRef={false}
        size="small"
        sameWidthWithReference
        options={buildAttributeTypeComparatorOptions(selectedAttribute?.type ?? "")}
        value={comparator}
        readonly={readonly}
        onSelect={opt => onChanged({ ...condition, comparator: opt.key as Comparator })}
      />
      {!isExistsComparison(comparator) ? valueInput : <div />}
      {onRemove && !readonly && <RemoveButton onClick={() => onRemove(condition.id)} />}
    </StyledConditionRow>
  );
};
