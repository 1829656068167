import {
  Select,
  Title,
  Icon,
  DataForm,
  Button,
  LabelledCheckbox,
  useTheme
} from "@introist/react-foundation/v2";
import { Block } from "components/atoms";

import editorStyles from "../WorkflowStepEditor.module.scss";
import { useEmailSenderApi } from "../../../../../../services/api/Senders";
import { useMemo, useState } from "react";
import { prefixForm } from "../../../../../../utils/FormUtils";
import { SearchEventModal } from "./SearchEventModal";
import sortBy from "lodash.sortby";
import { useEmployeeAttributeApi } from "../../../../../employees/api/EmployeeAttributeApi";
import { EditablePersonList } from "components/organisms";

type Props = {
  form: DataForm<any>;
  formPrefix: string;
  readonly?: boolean;
  mode?: "workflow" | "journey";
};

export const InviteStepEditor = ({ form, formPrefix, readonly, mode = "workflow" }: Props) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, formPrefix);

  const emailSenderApi = useEmailSenderApi();
  const { data: senders } = emailSenderApi.list({ filterEmailOnlySenders: true }).query;

  const attributeApi = useEmployeeAttributeApi();
  const { data: properties } = attributeApi.list({ includeHidden: false }).query;

  const senderOptions = useMemo(() => {
    return (senders ?? []).map(sender => ({ key: sender.id, title: sender.name }));
  }, [senders]);

  const [searchOpen, setSearchOpen] = useState(false);

  const selectedEvent = useMemo(() => {
    if (prefixedForm.get("eventId") !== "") return prefixedForm.get("eventId");
    return undefined;
  }, [prefixedForm]);

  const getDatePropertyOptions = () => {
    if (!properties) return [];

    const options = properties
      .filter(prop => prop.type === "date")
      .map(prop => ({
        key: prop.variable,
        title: prop.name
      }));
    return sortBy(options, "title");
  };

  const getEmailPropertyOptions = () => {
    if (!properties) return [];

    const asArray = (val: any) => {
      if (val instanceof Array) return val;
      return [];
    };

    const resolvedAttendees = asArray(form.get("journeyData.resolvedAttendees"));

    const options = properties
      .filter(prop => prop.type === "email")
      .map(prop => ({
        key: prop.variable,
        title: prop.name,
        description: resolvedAttendees.find((a: any) => a.attribute === prop.variable)?.email
      }));
    return sortBy(options, "title");
  };

  return (
    <>
      <Block>
        <Title style={{ marginBottom: theme.spacing.large }}>
          Choose the event you'd like to invite people to. By default, they'll be invited to all
          future occurrences. Need to tailor the invites? Select a dynamic attribute like 'start
          date,' and Introist will send invites for occurrences happening after that specific
          attribute's resolved date.
        </Title>
        <div className={editorStyles.DrawerGrid}>
          <Title>Calendar</Title>
          <Select
            size="small"
            variant="blended"
            element="button"
            options={senderOptions}
            value={prefixedForm.get("hostId")}
            onSelect={opt => {
              form.setMany({
                [`${formPrefix}hostId`]: opt.key,
                [`${formPrefix}eventId`]: undefined,
                [`${formPrefix}eventTitle`]: undefined
              });
            }}
            startAdornment={<Icon name="calendar" />}
            placeholder="Select calendar"
            readonly={mode === "journey" || readonly}
          />
          <Title>Event</Title>
          <div>
            <Button
              variant="blended"
              startIcon={selectedEvent ? "progress" : "search"}
              disabled={!prefixedForm.get("hostId") || mode === "journey" || readonly}
              onClick={() => setSearchOpen(true)}
            >
              {selectedEvent ? prefixedForm.get("eventTitle") : "Find event"}
            </Button>
          </div>
          <Title>Invite after</Title>
          <Select
            size="small"
            variant="blended"
            element="button"
            options={getDatePropertyOptions()}
            placeholder="Select date"
            readonly={mode === "journey" || readonly}
            value={prefixedForm.get("afterDate")}
            onSelect={opt => {
              prefixedForm.set(`afterDate`)(opt.key);
            }}
            startAdornment={<Icon name="calendarCheck" />}
          />
          <div />
          <LabelledCheckbox
            checked={prefixedForm.get("nextOnly")}
            onChange={checked => prefixedForm.set("nextOnly")(checked)}
            label="Invite to the next occurrence only"
            style={{
              marginLeft: theme.spacing.medium,
              marginTop: theme.spacing.small,
              marginBottom: theme.spacing.small
            }}
            readonly={readonly}
          />
        </div>
        <SearchEventModal
          open={searchOpen}
          hostId={prefixedForm.get("hostId")}
          onEventSelected={event => {
            form.setMany({
              [`${formPrefix}eventId`]: event.originalId,
              [`${formPrefix}eventTitle`]: event.summary
            });
            setSearchOpen(false);
          }}
          onClose={() => setSearchOpen(false)}
        />
      </Block>
      <Block>
        <Title style={{ marginBottom: theme.spacing.large }}>
          Select who will be invited to the selected event.
        </Title>
        <div className={editorStyles.DrawerGrid}>
          <Title style={{ justifySelf: "flex-start" }}>Guests</Title>
          <EditablePersonList
            placeholder="Add guests"
            addIcon="persons"
            searchPlaceholder="Search or add with enter"
            options={getEmailPropertyOptions()}
            value={prefixedForm.get("attendees") !== "" ? prefixedForm.get("attendees") : []}
            onChange={prefixedForm.set("attendees")}
            error={prefixedForm.isError("attendees")}
            disabled={!prefixedForm.get("hostId")}
            readonly={readonly}
          />
        </div>
      </Block>
    </>
  );
};
