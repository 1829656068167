import { ListView, TopBarRoute } from "components/templates";
import { UpsertModal, useSearch } from "../../../foundation";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { useNavigate } from "react-router";
import { Button, Stack, Title } from "@introist/react-foundation/v2";
import { useState } from "react";
import { FormCreate, useFormActions, useForms } from "../useForms";
import { FormForm } from "../FormForm";

export const FormsListRoute = () => {
  const navigate = useNavigate();

  const { forms } = useForms();
  const { create } = useFormActions();

  const { filtered, search, setSearch } = useSearch(forms, { fields: ["title"] });

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <TopBarRoute
      paths={[
        {
          key: "root",
          title: "Forms",
          onClick: () => navigate("/forms")
        }
      ]}
      medium
    >
      <Stack vertical gap="xLarge">
        <ListView.Header
          title="Forms"
          count={forms?.length}
          search={{ search, setSearch }}
          onCreate={() => setCreateOpen(true)}
        />
        <ListView.List
          data={filtered}
          empty={
            forms && forms.length === 0
              ? {
                  icon: "form",
                  title: "Collect data using Forms",
                  message:
                    "Build forms, send to employees and add responses directly to Employee's profile!",
                  action: <Button onClick={() => setCreateOpen(true)}>Create Form</Button>
                }
              : { icon: "form" }
          }
          renderRow={form => (
            <ListRow
              gridTemplateColumns="3fr 1fr auto auto"
              clickable
              onClick={() => navigate(`/forms/${form.id}`)}
            >
              <Stack>
                <IconPickerIcon icon="form" fallbackIcon="form" />
                <Title variant="bold">{form.title}</Title>
              </Stack>
            </ListRow>
          )}
        />
      </Stack>
      <UpsertModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreate={async entry => {
          await create(entry as FormCreate).then(created => navigate(`/forms/${created.id}`));
        }}
        onUpdate={() => {
          throw new Error("Not implemented");
        }}
        Form={FormForm}
        entryName="Form"
      />
    </TopBarRoute>
  );
};
