import { ErrorMessage, Field, Layout } from "@introist/react-foundation/v2";

import { Block, TextInput } from "components/atoms";
import { HtmlEditor } from "components/organisms";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { OrderableAttributeList } from "modules/workflows/components/OrderableAttributeList";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { FormConfig } from "../../schema";

type FormBuilderProps = {
  value: FormConfig;
  onChanged: (form: FormConfig) => void;
  attributes: EmployeeAttribute[];
  hasError: {
    title?: boolean;
    description?: boolean;
  };
};

export const FormBuilderV2 = ({ value, onChanged, attributes, hasError }: FormBuilderProps) => {
  const showAccordionError = hasError.description || hasError.title;

  return (
    <Block compact blended>
      <StepEditorAccordion
        title="Form content"
        stepOrder={2}
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Layout.Group vertical gap="xLarge">
          <TextInput
            title="Form title"
            type="text"
            size="small"
            placeholder="e.g. Order your tools"
            value={value.title}
            onChange={title => onChanged({ ...value, title })}
            error={hasError?.title}
          />
          <Field title="Form description">
            <HtmlEditor
              style={{ minHeight: 172, maxHeight: 224 }}
              placeholder="You can write instructions to fill the form. This will we shown above the fields."
              defaultValue={value.description ?? ""}
              onChange={description => onChanged({ ...value, description })}
              editorConfigurations={{ disableMaterial: true, disableFontSize: true }}
            />
            {hasError?.description && (
              <ErrorMessage
                style={{
                  marginTop: "0.25rem"
                }}
              >
                Required
              </ErrorMessage>
            )}
          </Field>
          <Field title="Form fields">
            <OrderableAttributeList
              value={value.fields || []}
              onChange={fields => onChanged({ ...value, fields })}
              attributes={attributes}
              enableAdd
            />
          </Field>
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
