import styled from "styled-components";
import { Title } from "@introist/react-foundation/v2";

const Container = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 6px;
  background: #1a9cfc;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InitialsIcon = ({ initials }: { initials: string }) => {
  return (
    <Container>
      <Title variant="bold" color="white" style={{ fontSize: "11px" }}>
        {initials}
      </Title>
    </Container>
  );
};

export const EmployeeInitialsIcon = ({ employee }: { employee: any }) => (
  <InitialsIcon initials={`${employee.firstname.charAt(0)}${employee.lastname.charAt(0)}`} />
);
