import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block, FittedTitle, TextInput } from "../../../../components/atoms";
import { MeetingAttendees } from "./MeetingAttendees";
import { Card, Field, Stack, Title } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import styled from "styled-components";
import { HtmlEditor } from "../../../../components/organisms";
import { formatDateTime } from "../../../../utils/DatesUtils";
import { get } from "lodash";
import { Avatar } from "../../../../components/molecules";

const StyledHTMLEditor = styled(HtmlEditor)`
  .ql-container {
    background-color: var(--palette-surface-subdued) !important;
  }
`;

export const MeetingStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const {
    resolvedHost,
    resolvedAttendees,
    resolvedTitle,
    resolvedDescription,
    resolvedMeetingRooms
  } = step.resolvedData as any;

  const resolveSettingState = (setting: string) => {
    const value = step.resolvedData![setting];
    if (value === true) return "Yes";
    if (value === false) return "No";
    return "Calendar default";
  };

  return (
    <>
      <Block blended compact>
        <MeetingAttendees host={resolvedHost} attendees={resolvedAttendees} />
      </Block>
      <Block blended compact>
        <StepEditorAccordion
          title="Location"
          defaultOpen={resolvedMeetingRooms.length > 0}
          description={resolvedMeetingRooms.length === 0 ? "No meeting room booked" : ""}
        >
          <StepEditorAccordion.MultiOptionRow label="Meeting room">
            <Stack vertical style={{ padding: "8px 0" }}>
              {resolvedMeetingRooms.map((room: any) => (
                <Stack>
                  <Avatar nameOrEmail={room?.title ?? room.email} />
                  <FittedTitle maxLength={30} variant="bold" showTooltip>
                    {room?.title ?? room.email}
                  </FittedTitle>
                </Stack>
              ))}
            </Stack>
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Meeting details">
          <Stack vertical>
            <TextInput readOnly value={resolvedTitle} label="Title" size="small" />
            <Field title="Description">
              <StyledHTMLEditor defaultValue={resolvedDescription} readonly={true} />
            </Field>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Settings" defaultOpen={false}>
          <Stack vertical>
            <Stack>
              <Title style={{ width: "300px" }}>When important attendee declines</Title>
              <Title variant="bold">
                {get(step, "resolvedData.attendeeDeclineAction") === "fail" && "Mark failing"}
                {get(step, "resolvedData.attendeeDeclineAction") === "none" && "Do nothing"}
              </Title>
            </Stack>
            <Stack>
              <Title style={{ width: "300px" }}>Keep content in sync</Title>
              <Title variant="bold">
                {get(step, "resolvedData.syncContent") === true ? "Yes" : "No"}
              </Title>
            </Stack>
            <Stack>
              <Title style={{ width: "300px" }}>Keep attendees in sync</Title>
              <Title variant="bold">
                {get(step, "resolvedData.syncAttendees") === true ? "Yes" : "No"}
              </Title>
            </Stack>
            <Stack>
              <Title style={{ width: "300px" }}>Guests can see other guests</Title>
              <Title variant="bold">{resolveSettingState("guestsCanSeeGuestList")}</Title>
            </Stack>
            <Stack>
              <Title style={{ width: "300px" }}>Guests can invite others</Title>
              <Title variant="bold">{resolveSettingState("guestsCanInviteOthers")}</Title>
            </Stack>
            <Stack>
              <Title style={{ width: "300px" }}>Guests can modify event</Title>
              <Title variant="bold">{resolveSettingState("guestsCanModifyEvent")}</Title>
            </Stack>
          </Stack>
          <Stack>
            <Title style={{ width: "300px" }}>Private Event</Title>
            <Title variant="bold">
              {get(step, "resolvedData.privateEvent") === true ? "Yes" : "No"}
            </Title>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <Card>
          {get(step, "executionData.updatedAt") && (
            <Title>
              Synced with calendar {formatDateTime(get(step, "executionData.updatedAt")!)}
            </Title>
          )}
        </Card>
      </Block>
    </>
  );
};
