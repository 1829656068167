import { Icon, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import moment from "moment";

type TargetDateIndicatorProps = {
  targetDate: string;
};

export const TargetDateIndicator = ({ targetDate }: TargetDateIndicatorProps) => {
  const { theme } = useTheme();

  return (
    <Stack>
      <Icon name="calendarTarget" color={theme.palette.foreground.subdued} />
      <Title variant="bold">{moment(targetDate).format("MMMM D, YYYY")}</Title>
    </Stack>
  );
};
