import { useMemo, useState } from "react";

export type SearchOptions = {
  fields: string[];
};

export const useSearch = <T extends { [index: string]: any }>(
  data?: T[],
  options?: SearchOptions
) => {
  const [search, setSearch] = useState("");

  const filtered = useMemo(() => {
    if (!data) return undefined;
    if (!search) return data;

    return data.filter(item => {
      for (const field of options?.fields || []) {
        if (String(item[field]).toLowerCase().includes(search.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }, [data, search, options?.fields]);

  return {
    filtered,
    search,
    setSearch
  };
};
