import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, useToast } from "@introist/react-foundation/v2";
import { useLocation } from "react-router-dom";
import { ConnectDataSourceInput, api } from "services/rpc/RpcProvider";

import { getDataSourceSpec } from "../../../datasources";

import { StepFrame } from "../components";
import { useNavigate } from "react-router";

type Props = {
  selectedDataSource?: string;
  values: Partial<ConnectDataSourceInput>;
  onChange: (values: Partial<ConnectDataSourceInput>) => void;
};

export type ConnectFormProps = Pick<Props, "values" | "onChange">;

const useConnectDataSource = () => {
  const toast = useToast();
  const { refetch: refetchDataSources } = api.employees.dataSources.list.useQuery({});
  const { mutateAsync, isLoading } = api.employees.dataSources.create.useMutation();

  const connectDataSource = useCallback(
    async (input: Partial<ConnectDataSourceInput>) => {
      const name = input?.name;
      const sourceType = input?.sourceType;
      const dataCategory = input?.dataCategory ?? "employees";

      if (!name || !sourceType) {
        return null; // Return null or appropriate value when there's no input
      }

      return mutateAsync(
        {
          ...input,
          name,
          sourceType,
          dataCategory
        },
        {
          onSuccess: () => {
            refetchDataSources();
          },
          onError: () => toast.error("Failed to connect data source")
        }
      );
    },
    [mutateAsync, refetchDataSources, toast]
  );

  return {
    connectDataSource,
    connectDataSourceLoading: isLoading
  };
};

export const DataSourceConnectionForm = ({ ...rest }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dataSourceType = location.hash.trim().replace("#", "") as string;
  const { connectDataSource } = useConnectDataSource();

  const [values, setValues] = useState<Partial<ConnectDataSourceInput>>({});

  const spec = useMemo(() => {
    return getDataSourceSpec(dataSourceType);
  }, [dataSourceType]);

  const initValues = useCallback(
    (dataSourceType: string) => {
      setValues({
        sourceType: dataSourceType,
        name: spec.title,
        credentials: {}
      });
    },
    [setValues, spec]
  );

  const getForm = () => {
    const props = {
      values,
      onChange: (nextValues: Partial<ConnectDataSourceInput>) =>
        setValues(current => ({ ...current, ...nextValues }))
    };

    return <spec.ConnectForm {...props} />;
  };

  useEffect(() => {
    if (!dataSourceType) return;
    if (values.name && values.sourceType) return;

    initValues(dataSourceType);
  }, [initValues, values, dataSourceType]);

  return (
    <StepFrame
      title={`Connect to ${spec.title}`}
      order={2}
      nextButtonText="Connect"
      onNextButtonClick={async () => {
        await connectDataSource(values);
        navigate("/datasources");
      }}
    >
      <Card>{getForm()}</Card>
    </StepFrame>
  );
};
