import { Field, Input, Stack } from "@introist/react-foundation/v2";
import { Nudge } from "./useNudges";
import { UpsertFormProps } from "../../foundation";

export const NudgeForm = ({ value, onChange }: UpsertFormProps<Nudge>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
    </Stack>
  );
};
