import { ComparatorOptions } from "components/organisms/ExpressionEditor";
import { ExpressionFieldType } from "components/organisms/RecursiveExpressionEditor/RecursiveExpressionEditor";
import _ from "lodash";

export const parseConditionComparator = (comparator: string, fieldType: ExpressionFieldType) => {
  const [dateComparators, universalComparators] = _.partition(
    ComparatorOptions,
    c => c.category === "date"
  );
  const dateComparator = dateComparators.find(_ => _.key === comparator);
  const universalComparator = universalComparators.find(_ => _.key === comparator);
  if (fieldType === "date") {
    return dateComparator || universalComparator;
  } else {
    return universalComparator;
  }
};
