import { Layout } from "@introist/react-foundation/v2";
import { Instructions } from "../Instructions";
import { TextInput } from "components/atoms";

export type HiBobConfig = {
  id: string;
  token: string;
};

type HiBobConfigFieldsProps = {
  config: HiBobConfig;
  onChange: (config: object) => void;
};

export const HiBobConfigFields = ({ config, onChange }: HiBobConfigFieldsProps) => {
  return (
    <Layout.Group vertical>
      <Instructions
        title={"How to set up HiBob Service User"}
        url="https://apidocs.hibob.com/docs/api-service-users"
      />
      <TextInput
        type="text"
        label="Service User ID"
        placeholder="Enter your Bob Service User ID"
        value={config.id}
        onChange={id => onChange({ ...config, id })}
      />
      <TextInput
        type="text"
        label="Service User Token"
        placeholder="Enter your Bob Service User Token"
        value={config.token}
        onChange={token => onChange({ ...config, token })}
      />
    </Layout.Group>
  );
};
