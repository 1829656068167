import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  IntroistLoader,
  Modal,
  Stack,
  Title,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useEffect, useRef, useState } from "react";
import { api } from "../../../../services/rpc/RpcProvider";

export const RoomProviderConnectRoute = () => {
  const { theme } = useTheme();
  const { provider } = useParams();
  const toast = useToast();

  const navigate = useNavigate();
  const { search } = useLocation();

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const connectCallbackOp = api.workspaces.rooms.provider.callback.useMutation();
  const refreshRoomsOp = api.workspaces.rooms.refresh.useMutation();

  const mounted = useRef(false);

  const connect = useCallback(
    async (customerId?: string) => {
      const searchParams = new URLSearchParams(search);
      const code = searchParams.get("code");

      await connectCallbackOp
        .mutateAsync({
          provider: provider! as "google" | "outlook",
          code: code!,
          details: customerId
        })
        .then(async () => {
          try {
            await refreshRoomsOp.mutateAsync({});
          } catch (e) {}
          toast.success("Room provider connected");
        })
        .catch(() => toast.error("Failed to connect account"))
        .finally(() => navigate("/settings/rooms"));
    },
    [connectCallbackOp, refreshRoomsOp, toast, navigate, provider, search]
  );

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      if (provider === "google") setDetailsOpen(true);
      else connect();
    }
  }, [provider, connect]);

  return (
    <div>
      <IntroistLoader globalCenter />
      <Modal
        title="Workspace details"
        open={detailsOpen}
        onClose={() => {
          setDetailsOpen(false);
          navigate("/settings/rooms", { replace: true });
        }}
        style={{ width: "400px" }}
      >
        <Stack vertical>
          <Title>Fill in your Google Workspace customer ID.</Title>
          <Button
            variant="blended"
            onClick={() => {
              window.open(
                "https://apps.google.com/supportwidget/articlehome?hl=en&article_url=https%3A%2F%2Fsupport.google.com%2Fa%2Fanswer%2F10070793%3Fhl%3Den&assistant_id=generic-unu&product_context=10070793&product_name=UnuFlow&trigger_context=a",
                "_blank"
              );
            }}
          >
            <Title color={theme.palette.primary.default}>How to find my customer ID?</Title>
          </Button>

          <Input
            type="text"
            placeholder="Customer ID"
            value={customerId}
            onChange={setCustomerId}
          />
          <Button
            onClickWithLoading={async () => {
              if (customerId !== "") {
                await connect(customerId);
              }
            }}
          >
            Connect
          </Button>
        </Stack>
      </Modal>
    </div>
  );
};
