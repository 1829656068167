import uniq from "lodash.uniq";
import { ReadOnlyStepProps, StepEditorProps } from "./step-editors/useStepEditor";
import { GoogleSheetRowStepEditor } from "./step-editors/googlesheetrow/GoogleSheetRowStepEditor";
import { IntroistFeatherIcon } from "../../components/atoms/IntroistFeatherIcon";
import { CompletedGoogleSheetRow } from "./step-editors/googlesheetrow/CompletedGoogleSheetRow";
import { EmailStepEditor } from "./step-editors/email/EmailStepEditor";
import { EmailStepViewer } from "./step-editors/email/EmailStepViewer";
import { DoceboIcon, DocuSignIcon, GoodyIcon, JiraIcon, SlackIcon, TeamsIcon } from "../../assets";
import { SlackChannelMessageEditor } from "./step-editors/slack/SlackChannelMessageEditor";
import { SlackChannelMessageViewer } from "./step-editors/slack/SlackChannelMessageViewer";
import { SlackDirectMessageEditor } from "./step-editors/slack/SlackDirectMessageEditor";
import { SlackDirectMessageViewer } from "./step-editors/slack/SlackDirectMessageViewer";
import { SlackQuestionMessageEditor } from "./step-editors/slack/SlackQuestionMessageEditor";
import { SlackQuestionMessageViewer } from "./step-editors/slack/SlackQuestionMessageViewer";
import { Icon } from "@introist/react-foundation/v2";
import { WorkflowTriggerStepEditor } from "./step-editors/workflowtrigger/WorkflowTriggerStepEditor";
import { WorkflowTriggerStepViewer } from "./step-editors/workflowtrigger/WorkflowTriggerStepViewer";
import { MeetingStepEditor } from "./step-editors/meeting/MeetingStepEditor";
import { MeetingStepViewer } from "./step-editors/meeting/MeetingStepViewer";
import { useWorkspace } from "../../IdentityProvider";
import { DoceboLearningPlanEditor } from "./step-editors/docebo/DoceboLearningPlanEditor";
import { DoceboLearningPlanViewer } from "./step-editors/docebo/DoceboLearningPlanViewer";
import { TrelloBoardStepEditor } from "./step-editors/trello/TrelloBoardStepEditor";
import { TrelloBoardStepViewer } from "./step-editors/trello/TrelloBoardStepViewer";
import { SlackFormMessageEditor } from "./step-editors/slack/SlackFormMessageEditor";
import { SlackFormnMessageViewer } from "./step-editors/slack/SlackFormMessageViewer";
import { EmailFormStepEditor } from "./step-editors/email/EmailFormStepEditor";
import { EmailFormStepViewer } from "./step-editors/email/EmailFormStepViewer";
import { TeamsChannelMessageEditor } from "./step-editors/teams/TeamsChannelMessageEditor";
import { TeamsChannelMessageViewer } from "./step-editors/teams/TeamsChannelMessageViewer";
import { TeamsChatMessageEditor } from "./step-editors/teams/TeamsChatMessageEditor";
import { TeamsChatMessageViewer } from "./step-editors/teams/TeamsChatMessageViewer";
import { GoodySendGiftEditor } from "./step-editors/goody/GoodySendGiftEditor";
import { GoodySendGiftViewer } from "./step-editors/goody/GoodySendGiftViewer";
import { useFeatureFlag } from "hooks";
import { JiraIssueStepEditor } from "./step-editors/jira/JiraIssueStepEditor";
import { JiraIssueStepViewer } from "./step-editors/jira/JiraIssueStepViewer";
import { DocuSignSendTemplateStepEditor } from "./step-editors/docusign/DocuSignSendTemplateStepEditor";

export type WorkflowStepType = {
  stepType: string;
  title: string;
  description: string;
  category: string;
  Editor?: (props: StepEditorProps) => JSX.Element;
  Viewer?: (props: ReadOnlyStepProps) => JSX.Element;
  template?: any;
  icon?: JSX.Element;
  hidden?: boolean;
  statusDescription?: { [index: string]: string };
  scheduleDescription?: { [index: string]: string };
  basicPlan?: boolean;
  featureFlag?: string;
};

export const WorkflowStepTypes: WorkflowStepType[] = [
  {
    stepType: "email",
    title: "Email Message",
    description: "Send an email to an employee or third party.",
    category: "messaging",
    Editor: EmailStepEditor,
    Viewer: EmailStepViewer,
    basicPlan: true
  },
  {
    stepType: "slackcm",
    title: "Slack Channel Message",
    description: "Send a message to a Slack channel",
    category: "messaging",
    icon: <SlackIcon />,
    Editor: SlackChannelMessageEditor,
    Viewer: SlackChannelMessageViewer,
    basicPlan: true
  },
  {
    stepType: "slackdm",
    title: "Slack Direct Message",
    description: "Send a message to user or group of users in Slack",
    category: "messaging",
    icon: <SlackIcon />,
    Editor: SlackDirectMessageEditor,
    Viewer: SlackDirectMessageViewer,
    template: {
      users: []
    },
    basicPlan: true
  },
  {
    stepType: "slackqm",
    title: "Slack Question Message",
    description: "Fill employee information by asking a person to fill it in Slack",
    category: "messaging",
    icon: <SlackIcon />,
    Editor: SlackQuestionMessageEditor,
    Viewer: SlackQuestionMessageViewer,
    template: {
      users: []
    },
    basicPlan: true
  },
  {
    stepType: "slackform",
    title: "Slack Form",
    description: "Send a Slack Form",
    category: "forms",
    icon: <SlackIcon />,
    Editor: SlackFormMessageEditor,
    Viewer: SlackFormnMessageViewer,
    template: {
      users: []
    },
    basicPlan: true
  },
  {
    stepType: "emailformv2",
    title: "Email Form",
    description: "Send a Form via Email",
    category: "forms",
    icon: <Icon name="email" />,
    Editor: EmailFormStepEditor,
    Viewer: EmailFormStepViewer,
    template: {
      message:
        "Please make sure to include the {{link-to-form}} tag in your message. This tag will automatically be replaced with the appropriate form link when the email is sent."
    }
  },
  {
    stepType: "teamscm",
    title: "Teams Channel Message",
    description: "Send a message to a Teams channel",
    category: "messaging",
    icon: <TeamsIcon />,
    Editor: TeamsChannelMessageEditor,
    Viewer: TeamsChannelMessageViewer
  },
  {
    stepType: "teamsdm",
    title: "Teams Chat Message",
    description: "Send a message to user or group of users in Teams",
    category: "messaging",
    icon: <TeamsIcon />,
    Editor: TeamsChatMessageEditor,
    Viewer: TeamsChatMessageViewer,
    template: {
      users: []
    }
  },
  {
    stepType: "goody",
    title: "Send Gift with Goody",
    description: "Send a gift through Goody.",
    category: "Engagement and Recognition",
    icon: <GoodyIcon />,
    Editor: GoodySendGiftEditor,
    Viewer: GoodySendGiftViewer,
    featureFlag: "goody"
  },
  {
    stepType: "meeting",
    title: "Calendar Meeting",
    description: "Set up a one-time calendar event",
    category: "scheduling",
    //hidden: true,
    statusDescription: {
      scheduled: "Created",
      delivered: "Scheduled"
    },
    scheduleDescription: {
      scheduled: "Send invites at",
      delivered: "Meeting time"
    },
    Editor: MeetingStepEditor,
    Viewer: MeetingStepViewer,
    template: {
      attendees: [],
      dateTrigger: {
        amount: 5,
        period: "days",
        side: "after",
        reference: "enroll"
      },
      fromTime: "10:00",
      toTime: "11:00",
      syncContent: true,
      syncAttendees: true,
      attendeeDeclineAction: "fail",
      meetingRoomType: "none",
      meetingRooms: []
    },
    basicPlan: true
  },
  {
    stepType: "event",
    title: "Calendar event",
    description: "Calendar meeting or event",
    category: "scheduling",
    hidden: true,
    statusDescription: {
      scheduled: "Created",
      delivered: "Scheduled"
    }
  },
  {
    stepType: "common-event",
    title: "Calendar event",
    description: "Calendar meeting or event",
    category: "scheduling",
    hidden: true,
    statusDescription: {
      scheduled: "Created",
      delivered: "Scheduled"
    }
  },
  {
    stepType: "individual-event",
    title: "Meeting",
    description: "Set up a one-time calendar event",
    category: "scheduling",
    hidden: true,
    statusDescription: {
      scheduled: "Created",
      delivered: "Scheduled"
    }
  },
  {
    stepType: "invite",
    title: "Invite to recurring event",
    description: "Invite employee to recurring event",
    category: "scheduling"
  },
  {
    stepType: "eventattend",
    title: "Attend to event",
    description: "Attend to event",
    category: "scheduling",
    statusDescription: {
      scheduled: "Invited",
      delivered: "Attends"
    }
  },
  {
    stepType: "workflowtrigger",
    title: "Launch an automation",
    description: "Automatically start another workflow.",
    category: "productivity",
    icon: <Icon name="trigger" />,
    Editor: WorkflowTriggerStepEditor,
    Viewer: WorkflowTriggerStepViewer,
    basicPlan: true
  },
  {
    stepType: "slack",
    title: "Slack message",
    description: "Send a message to either a channel or through direct messaging",
    category: "messaging",
    hidden: true
  },
  {
    stepType: "slacktask",
    title: "Slack task",
    description: "Send task via Slack and track completion",
    category: "tasks"
  },
  {
    stepType: "emailtask",
    title: "Email task",
    description: "Distribute tasks via email and monitor their progress",
    category: "tasks"
  },
  {
    stepType: "docebo-lp-enroll",
    title: "Enroll to Learning Plan",
    description: "Enroll an employee to a Learning Plan in Docebo",
    category: "lms",
    icon: <DoceboIcon />,
    Editor: DoceboLearningPlanEditor,
    Viewer: DoceboLearningPlanViewer,
    featureFlag: "docebo"
  },
  {
    stepType: "slackquestion",
    title: "Slack question",
    description: "Send question via Slack and utilise the answer",
    category: "data",
    hidden: true
  },
  {
    stepType: "emailform",
    title: "Email form",
    description: "Gather information using a form distributed via email",
    category: "data",
    hidden: true
  },
  {
    stepType: "enroll",
    title: "Start another workflow",
    description: "Automatically start another workflow.",
    category: "productivity",
    hidden: true
  },
  {
    stepType: "createaccount",
    title: "Create account",
    description: "Automatically create account to your data sources",
    category: "productivity"
  },
  {
    stepType: "trelloboard",
    title: "Create Trello board",
    description: "Create a board to Trello from template",
    category: "productivity",
    Editor: TrelloBoardStepEditor,
    Viewer: TrelloBoardStepViewer,
    template: {
      members: []
    }
  },
  {
    stepType: "employeeselect",
    title: "Assign an employee",
    description: "Assign related employee such as manager or buddy",
    category: "productivity"
  },
  {
    stepType: "googlesheetrow",
    title: "Add Sheet row",
    description: "Add a row to a Google Sheet",
    category: "productivity",
    Editor: GoogleSheetRowStepEditor,
    Viewer: CompletedGoogleSheetRow,
    icon: <IntroistFeatherIcon icon="table" size={16} color="primary" />
  },
  {
    stepType: "jira",
    title: "Jira Issue",
    description: "Create an issue to Jira",
    category: "productivity",
    icon: <JiraIcon />,
    Editor: JiraIssueStepEditor,
    Viewer: JiraIssueStepViewer
  },
  {
    stepType: "webhook",
    title: "Webhook",
    description: "Custom webhook action",
    category: "productivity",
    hidden: true
  },
  {
    stepType: "docusignsendtemplate",
    title: "DocuSign eSign",
    description: "Send a document to be signed based on template",
    category: "productivity",
    icon: <DocuSignIcon />,
    Editor: DocuSignSendTemplateStepEditor,
    featureFlag: "docusign"
  }
];

export const StepTypeCategories = uniq(WorkflowStepTypes.map(stepType => stepType.category));

export const useStepType = (stepType: string, eventType?: string): WorkflowStepType => {
  const newStepType = WorkflowStepTypes.find(type => type.stepType === stepType);
  if (!newStepType) {
    throw new Error(`Step type ${stepType} not found`);
  }

  if (newStepType.stepType === "event") {
    if (eventType === "common") newStepType.title = "Event";
    if (eventType === "individual") newStepType.title = "Meeting";
  }

  return newStepType;
};

export const useStepTypes = (): WorkflowStepType[] => {
  const workspace = useWorkspace();
  const doceboFlag = useFeatureFlag("docebo");
  const goodyFlag = useFeatureFlag("goody");
  const hasFeatureFlagActivated = (flagName: string) => {
    if (flagName === "docebo") return doceboFlag.isEnabled;
    if (flagName === "goody") return goodyFlag.isEnabled;
    return false;
  };
  if (workspace?.plan === "basic") return WorkflowStepTypes.filter(stepType => stepType.basicPlan);
  return WorkflowStepTypes.filter(_ =>
    !!_.featureFlag ? hasFeatureFlagActivated(_.featureFlag) : true
  );
};
