import { Button, IconName, Icon, Layout } from "@introist/react-foundation/v2";
import { Dot } from "components/atoms";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

interface TabProps {
  active?: boolean;
  title?: ReactNode;
  onClick?: () => unknown;
  iconName?: IconName;
  dotted?: boolean;
}

const StyledTab = styled(Button)<{ $active?: boolean; $iconOnly?: boolean }>`
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none;

  > div {
    justify-content: center;
    h5 {
      color: var(--palette-foreground-subdued) !important;
    }

    svg {
      opacity: 0.3;
    }
  }

  ${({ $active }) =>
    $active &&
    css`
      > div {
        h5 {
          color: var(--palette-foreground-default) !important;
        }

        svg {
          opacity: 1;
        }
      }
    `}

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      min-height: auto;
      padding: 0.125rem;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const StyledDot = styled(Dot)`
  width: 0.375rem;
  height: 0.375rem;
  background-color: var(--palette-primary-default);
`;

export const Tab = ({ active, onClick, dotted, title, iconName }: TabProps) => {
  return (
    <StyledTab
      $active={active}
      $iconOnly={!title && !!iconName}
      size="small"
      variant={active ? "outlined" : "blended"}
      startAdornment={iconName && <Icon name={iconName} />}
      onClick={onClick}
    >
      {(title || dotted) && (
        <Layout.Group gap="small">
          {title && <span>{title}</span>}
          {dotted && <StyledDot />}
        </Layout.Group>
      )}
    </StyledTab>
  );
};
