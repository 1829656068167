import { useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Card, DateInput, Layout, Title, useToast } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";
import { CompanyEvent, api } from "services/rpc/RpcProvider";
import { DateFormats } from "utils/dates";
import moment from "moment";
import { capitalize } from "lodash";

export type AddCompanyEventEmployeeProps = {
  companyEvent: CompanyEvent;
  onEnrolled?: VoidFunction;
};

const EmployeeFieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-xLarge);
`;

export const AddCompanyEventEmployee = ({
  companyEvent,
  onEnrolled,
  ...rest
}: AddCompanyEventEmployeeProps) => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [attendEarliest, setAttendEarliest] = useState<string | undefined>();
  const [attendLatest, setAttendLatest] = useState<string | undefined>();

  const saveEnabled = email && name;

  const { mutateAsync, isLoading } = api.companyEvents.enroll.useMutation({});

  const guessName = useCallback(() => {
    if (name !== "") return;

    const emailSplit = email.split("@");
    if (emailSplit.length > 1) {
      const name = emailSplit[0];
      const nameSplit = name.split(".");
      if (nameSplit.length === 2) {
        setName(`${capitalize(nameSplit[0])} ${capitalize(nameSplit[1])}`);
      }
      if (nameSplit.length === 1) {
        setName(capitalize(nameSplit[0]));
      }
    }
  }, [email, name]);

  const enrollEmployee = useCallback(async () => {
    if (!email || !name) {
      toast.error("Please fill out all fields");
      return;
    }

    await mutateAsync(
      {
        companyEventId: companyEvent.id,
        attendee: {
          email,
          name,
          attendEarliest: attendEarliest ? moment(attendEarliest).format("YYYY-MM-DD") : undefined,
          latest: attendLatest ? moment(attendLatest).endOf("day").toDate() : null
        }
      },
      {
        onSuccess: () => {
          onEnrolled && onEnrolled();
          setName("");
          setEmail("");
          toast.success("Employee enrolled");
        },
        onError: error => {
          if (error.message === "No occurrences found") {
            toast.error("No sessions found. Choose another date");
            return;
          }
          toast.error("Failed to enroll attendee");
        }
      }
    );
  }, [email, name, attendEarliest, companyEvent, mutateAsync, toast, onEnrolled, attendLatest]);

  return (
    <Layout.Group {...rest} vertical gap="xLarge">
      <Card>
        <Layout.Group vertical gap="xLarge">
          <Title variant="bold">Attendee</Title>
          <EmployeeFieldsGrid>
            <TextInput
              value={email}
              onChange={setEmail}
              label="Email address"
              placeholder="first.last@company.com"
              size="small"
              autoFocus
              onBlur={() => guessName()}
            />
            <TextInput
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Jane Doe"
              size="small"
            />
          </EmployeeFieldsGrid>
        </Layout.Group>
      </Card>
      <Layout.Group justifyContent="flex-end" gap="large">
        <Layout.Group>
          <Title variant="bold">Attend earliest</Title>
        </Layout.Group>
        <DateInput
          displayFormat={DateFormats.date.long}
          size="small"
          placeholder="Today"
          value={attendEarliest}
          onChange={setAttendEarliest}
          style={{ maxWidth: 128 }}
        />
      </Layout.Group>
      <Layout.Group justifyContent="flex-end" gap="large">
        <Layout.Group>
          <Title variant="bold">Attend latest</Title>
        </Layout.Group>
        <DateInput
          displayFormat={DateFormats.date.long}
          size="small"
          placeholder=""
          value={attendLatest}
          onChange={setAttendLatest}
          style={{ maxWidth: 128 }}
        />
      </Layout.Group>
      <Layout.Group justifyContent="flex-end">
        <Button disabled={!saveEnabled} onClick={enrollEmployee} loading={isLoading}>
          Enroll
        </Button>
      </Layout.Group>
    </Layout.Group>
  );
};
