import { StepEditorProps } from "../useStepEditor";

import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { Icon, Input, Select } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useEmployeeFields } from "modules/employees/hooks/useEmployeeFields";
import { useMemo } from "react";
import { MessageEditor } from "components/editors/MessageEditor";
import { GoodyProductsOptions } from "./GoodyProducts";

export const GoodySendGiftEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { fields } = useEmployeeFields();
  const receiverOptions = useMemo(() => {
    const fieldOptions = (fields ?? [])
      .filter(f => ["employee"].includes(f.type))
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment: <Icon name={"personSync"} />
      }));

    return [
      { key: "id", title: "Employee", startAdornment: <Icon name={"personSync"} /> },
      ...fieldOptions
    ];
  }, [fields]);

  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Gift Details" defaultOpen={true}>
          <StepEditorAccordion.Row label="Recipient">
            <Select
              searchable
              size="small"
              element="button"
              variant="blended"
              placeholder="Add receiver"
              startAdornment={
                !!prefixedForm.get("recipient") ? (
                  <Icon name={"personSync"} />
                ) : (
                  <Icon name="personAdd" />
                )
              }
              options={receiverOptions}
              onSelect={opt => {
                prefixedForm.set("recipient")(opt.key);
              }}
              attachToRef={false}
              value={prefixedForm.get("recipient")}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Product">
            <Select
              searchable
              size="small"
              element="button"
              variant="blended"
              placeholder="Add product"
              startAdornment={<Icon name="workflowEditor" />}
              options={GoodyProductsOptions}
              onSelect={opt => {
                prefixedForm.set("productId")(opt.key);
              }}
              attachToRef={false}
              value={prefixedForm.get("productId")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen={true}>
          <StepEditorAccordion.Row label="Sender name">
            <Input
              placeholder="Your name"
              size="small"
              variant="blended"
              value={prefixedForm.get("fromName")}
              onChange={prefixedForm.set("fromName")}
            />
          </StepEditorAccordion.Row>

          <MessageEditor
            message={prefixedForm.get("message")}
            onChange={prefixedForm.set("message")}
            underline={false}
          />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
