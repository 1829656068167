import { useRef } from "react";

import { DataForm, Field, InputHandle, Layout } from "@introist/react-foundation/v2";
import { Editor, ReadOnlyEmailMessage } from "components/organisms";
import { prefixForm } from "utils/FormUtils";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { Block, TextInput } from "components/atoms";
import { WorkflowStep } from "services/api/WorkflowApi";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { HtmlContentEditor, HtmlEditorPowerUps } from "components/organisms";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

type EventContentBlockProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
  readonly?: boolean;
  title?: string;
};

export const EventMessageBlock = ({
  form,
  formPrefix,
  readonly,
  title
}: EventContentBlockProps) => {
  const messageRef = useRef<Editor>(null);
  const subjectRef = useRef<InputHandle>(null);

  const prefixedForm = prefixForm(form, formPrefix);

  const addVariable = (variable: EmployeeAttribute) => {
    const definedVariable = `{{${variable.variable}}}`;

    if (messageRef.current !== null && messageRef.current.hasFocus()) {
      messageRef.current.insertUserPropertyToCursorPosition(variable);
      messageRef.current.insertTextToCursorPosition(" ");
    }
    if (subjectRef.current !== null && subjectRef.current.hasFocus()) {
      subjectRef.current.insertTextToCursorPosition(definedVariable);
      subjectRef.current.insertTextToCursorPosition(" ");
    }
  };

  const isIndividualEvent = () => prefixedForm.get("eventType") === "individual";

  return (
    <Block compact blended>
      <StepEditorAccordion title={title ?? "Event details"}>
        {readonly && (
          <ReadOnlyEmailMessage
            subject={prefixedForm.get("title")}
            message={prefixedForm.get("description")}
          />
        )}
        {!readonly && (
          <Layout.Group vertical gap="xLarge">
            <Field title="Title">
              <TextInput
                inputHandleRef={subjectRef}
                size="small"
                placeholder="Event title"
                value={prefixedForm.get("title")}
                onChange={prefixedForm.set("title")}
                error={prefixedForm.isError("title")}
              />
            </Field>
            <Field title="Description">
              <HtmlContentEditor
                ref={messageRef}
                editorConfigurations={{
                  disableGif: true,
                  disableImage: true,
                  disableMaterial: !isIndividualEvent(),
                  disableAttributePersonalization: !isIndividualEvent()
                }}
                defaultValue={prefixedForm.get("description")}
                onChange={prefixedForm.set("description")}
                onGenerated={text => messageRef.current?.replaceHTML(text)}
                channel="calendar event agenda"
              />
            </Field>
            <HtmlEditorPowerUps
              disableAttributeSelection={!isIndividualEvent()}
              onAttributeSelected={addVariable}
              readonly={readonly}
            />
          </Layout.Group>
        )}
      </StepEditorAccordion>
    </Block>
  );
};
