import { ToggleSettingCard } from "components/organisms";
import { getEventSettingsMetadata } from "../util";
import { EventSettingProps } from ".";
import { useState } from "react";
import { useUpdateCompanyEventSetting } from "../hooks";
import { Select } from "@introist/react-foundation/v2";

export const CancelOnPresenterDecline = ({ readOnly, setting }: EventSettingProps) => {
  const metadata = getEventSettingsMetadata(setting.setting);
  const [active, setActive] = useState(setting.active);
  //@ts-ignore
  const [mode, setMode] = useState(setting.conf.mode);

  const { updateEventSetting } = useUpdateCompanyEventSetting({
    setting,
    config: { enabled: !readOnly }
  });

  return (
    <ToggleSettingCard
      autoCollapse
      readOnly={readOnly}
      key={setting.setting}
      title={metadata.title}
      description={metadata.description}
      active={active}
      onToggleActive={active => {
        setActive(active);
        updateEventSetting({ active }, () => setActive(setting.active));
      }}
    >
      <Select
        size="small"
        value={mode}
        style={{ width: "100%" }}
        options={[
          { key: "all", title: "Cancel if all presenters decline" },
          { key: "any", title: "Cancel if any presenter declines" }
        ]}
        onSelect={opt => {
          setMode(opt.key);
          updateEventSetting({ conf: { mode: opt.key } });
        }}
      />
    </ToggleSettingCard>
  );
};
