import {
  deleteJson,
  DeleteSuccessResponse,
  generateMutation,
  generateQuery,
  generateQueryWithParameters,
  getJson,
  postJson,
  putJson
} from "./ApiBase";

export interface SlackSender {
  id: string;
  slackUserId: string;
  name: string;
  archived: boolean;
  iconUrl?: string;
  isBot: boolean;
  ownerId?: string;
}

export interface SlackUser {
  id: string;
  name: string;
  realName: string;
}

const BASE_URL = "/v1/senders/slack";

const listSlackSenders = (): Promise<SlackSender[]> => {
  return getJson(BASE_URL);
};

interface ListSlackAssetRequest {
  senderId: string;
}

const listChannels = ({ senderId }: ListSlackAssetRequest): Promise<string[]> => {
  return getJson(`/v1/senders/slack/channels?senderId=${senderId}`);
};

const listSlackUsers = ({ senderId }: ListSlackAssetRequest): Promise<SlackUser[]> => {
  return getJson(`/v1/senders/slack/users?senderId=${senderId}`);
};

export interface FindSlackBotRequest {
  id: string;
}

export const findSlackSender = ({ id }: FindSlackBotRequest): Promise<SlackSender> => {
  return getJson(`/v1/senders/slack/${id}`);
};

export interface CreateBotSenderRequest {
  name: string;
  iconUrl: string;
  ownerId: string;
}

export const createBotSender = (data: CreateBotSenderRequest): Promise<SlackSender> => {
  return postJson("/v1/senders/slack/bot", data);
};

export interface UpdateBotSenderRequest {
  senderId: string;
  update: {
    name?: string;
    iconUrl?: string;
    ownerId?: string;
  };
}

export const updateSlackSender = ({
  senderId,
  update
}: UpdateBotSenderRequest): Promise<SlackSender> => {
  return putJson(`/v1/senders/slack/${senderId}`, update);
};

interface DeleteRequest {
  id: string;
}

const deleteSlackSender = ({ id }: DeleteRequest): Promise<DeleteSuccessResponse> => {
  return deleteJson(`${BASE_URL}/${id}`);
};

export const useSlackSenderApi = () => ({
  delete: generateMutation<DeleteRequest, DeleteSuccessResponse>(deleteSlackSender),
  list: generateQuery<SlackSender[]>(["slackSenders"], listSlackSenders),
  find: generateQueryWithParameters(["slackSenders"], findSlackSender),
  createBot: generateMutation<CreateBotSenderRequest, SlackSender>(createBotSender),
  updateSender: generateMutation<UpdateBotSenderRequest, SlackSender>(updateSlackSender),
  listChannel: generateQueryWithParameters<ListSlackAssetRequest, string[]>(
    ["slackChannels"],
    listChannels,
    { retry: false }
  ),
  listUsers: generateQueryWithParameters<ListSlackAssetRequest, SlackUser[]>(
    ["slackUsers"],
    listSlackUsers,
    { retry: false }
  )
});
