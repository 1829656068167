import { Select, Icon } from "@introist/react-foundation/v2";
import { api } from "services/rpc/RpcProvider";

interface Props {
  country?: string;
  setCountry: (country: string) => void;
}

export const CountrySelect = ({ country, setCountry }: Props) => {
  const { data: areaCodes } = api.holidayCalendar.areaCodes.useQuery({});
  const countries = (areaCodes ?? []).map(c => ({ key: c.code, title: c.name }));
  return (
    <Select
      size="small"
      element="button"
      variant="outlined"
      endAdornment={<Icon name="chevronDown" />}
      placeholder="Country"
      searchable
      options={countries}
      value={country}
      onSelect={o => setCountry(o.key)}
    />
  );
};
