import { Card, H4, Layout, Stack, TextButton, Title } from "@introist/react-foundation/v2";
import { ComparisonModeSelect } from "components/organisms/ExpressionEditor";
import { isEmpty } from "lodash";
import { RuleViewer } from "./RuleViewer/RuleViewer";
import { ExpressionField, RescursiveExperssionEditorConfig } from "../RecursiveExpressionEditor";
import { useExpressionEditorContext } from "../context";
import { ReactNode } from "react";

interface ConditionViewerProps {
  hideComparisonMode?: boolean;
  fields: ExpressionField[];
  conditionLimit?: number;
  config?: RescursiveExperssionEditorConfig;
}

const ConditionTreeEmptyState = ({
  title = "Include when..",
  description
}: {
  title?: ReactNode;
  description?: ReactNode;
}) => (
  <Card style={{ userSelect: "none" }}>
    <Layout.Group vertical gap="xSmall">
      <H4>{title}</H4>

      {description && <Title>{description}</Title>}
    </Layout.Group>
  </Card>
);

export const ConditionViewer = ({
  config,
  hideComparisonMode,
  fields,
  conditionLimit
}: ConditionViewerProps) => {
  const {
    conditionGroup,
    selectedConditionId,
    changeComparisonMode,
    selectCondition,
    setSelectedConditionId,
    deleteConditionById
  } = useExpressionEditorContext();

  const showAddAnotherRuleButton =
    !isEmpty(conditionGroup.conditions) &&
    !!selectedConditionId &&
    conditionGroup.conditions.length < (conditionLimit || Infinity);

  return (
    <Stack vertical gap="large">
      {!hideComparisonMode && (
        <ComparisonModeSelect
          startText={config?.comparisonModeStartText || "Include if"}
          mode={conditionGroup.mode}
          onChange={changeComparisonMode}
        />
      )}
      {isEmpty(conditionGroup.conditions) && <ConditionTreeEmptyState />}
      <RuleViewer
        selectedConditionId={selectedConditionId}
        fields={fields}
        conditionGroup={conditionGroup}
        selectCondition={selectCondition}
        deleteCondition={deleteConditionById}
      />
      {showAddAnotherRuleButton && (
        <TextButton
          startIcon="plusSmall"
          colorVariant="primary"
          onClick={() => setSelectedConditionId(undefined)}
        >
          Add another rule
        </TextButton>
      )}
    </Stack>
  );
};
