import styled from "styled-components";
import { EmailMessageResult } from "../schema";
import { Button, IconButton, Stack, Title } from "@introist/react-foundation/v2";
import { Skeletons } from "components/molecules";
import { HtmlEditor } from "components/organisms";
import { ResultPreviewBaseProps } from "./ResultPreview";

const Italic = styled(Title)`
  font-style: italic;
`;
interface MessagePreviewProps extends ResultPreviewBaseProps {
  preview: EmailMessageResult | undefined;
}
export const MessageResponsePreview = ({
  preview,
  confirm,
  close,
  loading,
  onChange
}: MessagePreviewProps) => {
  return (
    <Stack vertical>
      <Stack justifyContent="space-between">
        <IconButton icon="crossSmall" size="small" variant="blended" onClick={close} />
        <Italic>AI response may not be accurate</Italic>
      </Stack>
      {!loading && (
        <HtmlEditor
          defaultValue={preview?.message}
          onChange={message => onChange({ ...preview, message })}
          style={{ maxWidth: "500px" }}
        />
      )}
      {loading && (
        <Stack vertical gap="small">
          <Skeletons.Block height={40} width={424} />
          <Skeletons.Block height={200} width={424} />
        </Stack>
      )}

      <Stack justifyContent="flex-end">
        <Button
          size="small"
          variant="blended"
          onClick={preview ? () => confirm(preview) : undefined}
          startIcon="glow"
        >
          Confirm
        </Button>
      </Stack>
    </Stack>
  );
};
