export const InviteStepSchemaFields = {
  hostId: {
    type: "string",
    required: true
  },
  eventId: {
    type: "string",
    required: true
  },
  eventTitle: {
    type: "string",
    required: true
  },
  inviteToAll: {
    type: "boolean",
    required: false
  },
  attendees: {
    type: "array",
    each: "string",
    required: true
  }
};
