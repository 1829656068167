import { ListView, TopBarRoute } from "components/templates";
import { UpsertModal, useSearch } from "../../../foundation";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { useNavigate } from "react-router";
import { Button, Icon, Select, Stack, Tag, Title, useTheme } from "@introist/react-foundation/v2";
import { useState } from "react";
import { BatchCreate, useBatchActions, useBatches } from "../useBatches";
import { BatchForm } from "./batch/BatchForm";
import { StageFilter } from "../components/StageFilter";
import { orderBy } from "lodash";
import { useWorkflows } from "../../flows/hooks/useWorkflows";
import { IconWithCount } from "../../../foundation/blocks/IconWithCount";
import { BatchCreatorCreate, useBatchCreatorActions } from "../useBatchCreators";
import { BatchCreatorsList } from "../components/creators/CreatorsList";
import { BatchCreatorForm } from "../components/creators/CreatorForm";
import { DEFAULT_CRON } from "../components/creators/CronBuilder";

export const BatchesListRoute = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { batches } = useBatches();
  const { workflows } = useWorkflows({});

  const { create } = useBatchActions();
  const { create: createCreator } = useBatchCreatorActions();

  const [stages, setStages] = useState<string[]>(["draft", "active"]);

  const { filtered, search, setSearch } = useSearch(
    batches?.filter(b => stages.includes(b.stage)),
    { fields: ["title"] }
  );

  const [createOpen, setCreateOpen] = useState(false);
  const [creatorCreateOpen, setCreatorCreateOpen] = useState(false);

  return (
    <TopBarRoute
      paths={[
        {
          key: "root",
          title: "Batches",
          onClick: () => navigate("/batches")
        }
      ]}
      medium
    >
      <Stack vertical gap="xLarge">
        <BatchCreatorsList />
        <ListView.Header
          title="Batches"
          count={batches?.length}
          search={{ search, setSearch }}
          create={
            <Select
              element="button"
              variant="outlined"
              size="small"
              startAdornment={<Icon name="plus" />}
              endAdornment={<Icon name="chevronDown" />}
              placeholder="New"
              options={[
                {
                  key: "batch",
                  title: "Batch",
                  startAdornmentIcon: "duplicate",
                  onClick: () => setCreateOpen(true)
                },
                {
                  key: "creator",
                  title: "Batch Creator",
                  startAdornmentIcon: "featureRoutines",
                  onClick: () => setCreatorCreateOpen(true)
                }
              ]}
            />
          }
          right={<StageFilter value={stages} onChange={setStages} batches={batches} />}
        />
        <ListView.List
          data={filtered ? orderBy(filtered, b => b.createdAt, "desc") : filtered}
          empty={
            batches && batches.length === 0
              ? {
                  icon: "duplicate",
                  title: "Run workflows for groups of employees",
                  message:
                    "Select a workflow, build a list of employees, and run the workflow for each of the employees with full progress visibility. Perfect for mass data collection and information sharing at scale.",
                  action: <Button onClick={() => setCreateOpen(true)}>Create Batch</Button>
                }
              : { icon: "duplicate" }
          }
          renderRow={batch => (
            <ListRow
              gridTemplateColumns="1fr auto 30px auto"
              clickable
              onClick={() => navigate(`/batches/${batch.id}`)}
            >
              <Stack>
                <IconPickerIcon icon="duplicate" fallbackIcon="duplicate" />
                <Title variant="bold">{batch.title}</Title>
                <Stack>
                  <Icon name="workflows" dimmed />
                  <Title>{workflows?.find(wf => wf.id === batch.workflowId)?.title}</Title>
                </Stack>
              </Stack>
              <Stack>
                <IconWithCount
                  iconName="warning"
                  count={batch.stats?.error ?? 0}
                  hideIfZero
                  color={theme.palette.danger.default}
                />
              </Stack>

              <Stack>
                {batch.stage === "draft" && (
                  <IconWithCount iconName="person" count={batch.stats?.active ?? 0} hideIfZero />
                )}
                {batch.stage === "active" && (
                  <IconWithCount
                    iconName="personSync"
                    count={batch.stats?.active ?? 0}
                    hideIfZero
                  />
                )}
                {batch.stage === "completed" && (
                  <IconWithCount
                    iconName="personCheck"
                    count={batch.stats?.completed ?? 0}
                    hideIfZero
                  />
                )}
              </Stack>

              <Stack style={{ marginLeft: "auto" }}>
                {batch.stage === "draft" && <Tag colorVariant="surface">Draft</Tag>}
                {batch.stage === "active" && <Tag colorVariant="primary">Active</Tag>}
                {batch.stage === "completed" && <Tag colorVariant="success">Completed</Tag>}
              </Stack>
            </ListRow>
          )}
        />
      </Stack>
      <UpsertModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreate={async entry => {
          await create(entry as BatchCreate).then(created => navigate(`/batches/${created.id}`));
        }}
        onUpdate={() => {
          throw new Error("Not implemented");
        }}
        Form={BatchForm}
        entryName="batch"
      />
      <UpsertModal
        open={creatorCreateOpen}
        template={{
          description: "",
          cron: DEFAULT_CRON
        }}
        onClose={() => setCreatorCreateOpen(false)}
        onCreate={async entry => {
          await createCreator(entry as BatchCreatorCreate).then(() => setCreatorCreateOpen(false));
        }}
        onUpdate={() => {
          throw new Error("Not implemented");
        }}
        Form={BatchCreatorForm}
        entryName="Batch Creator"
      />
    </TopBarRoute>
  );
};
