import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const SeveraConnectForm = ({ values, onChange }: ConnectFormProps) => {
  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.clientId}
        label="Client ID"
        onChange={clientId => {
          onChange({
            credentials: {
              ...((values?.credentials as object) ?? {}),
              clientId
            }
          });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.clientSecret}
        label="Client secret"
        onChange={clientSecret =>
          onChange({
            credentials: {
              ...((values?.credentials as object) ?? {}),
              clientSecret
            }
          })
        }
      />
    </Layout.Group>
  );
};
