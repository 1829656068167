import { Children, FC, ReactNode } from "react";
import styled from "styled-components";
import { H4 } from "@introist/react-foundation/v2";
import { animated, useSprings, config } from "@react-spring/web";

type Props = {
  children: ReactNode;
  animate?: boolean;
  alignChildren?: "center" | "left";
  maxWidthRem?: number;
  padding?: "large" | "xLarge" | "xxLarge";
};

type Compound = {
  Image: typeof Image;
  Title: typeof CenteredH4;
  Buttons: typeof Buttons;
};

const Wrapper = styled.div<{ $padding?: Props["padding"] }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  ${({ $padding }) => {
    switch ($padding) {
      case "xLarge":
        return "padding: var(--spacing-xLarge);";
      case "xxLarge":
        return "padding: var(--spacing-xxLarge);";
      case "large":
      default:
        return "padding: var(--spacing-large);";
    }
  }}
`;

const StyledListEmptyState = styled.div<{ $maxWidth?: number }>`
  max-width: ${({ $maxWidth }) => $maxWidth || 27}rem;
  user-select: none;
`;

const Image = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-large);
`;

const CenteredH4 = styled(H4)`
  text-align: center;
  margin-bottom: var(--spacing-large);
`;

const Buttons = styled.div<{ $stretch?: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-small);
  width: ${p => (p.$stretch ? "14rem" : "auto")};
  margin: 0 auto;
  margin-top: var(--spacing-xxLarge);

  > button {
    width: ${p => (p.$stretch ? "100%" : "fit-content")};
    margin: 0 auto;
    > div {
      justify-content: center;
    }
  }
`;

const ListEmptyStateComponent: FC<Props> & Compound = ({
  children,
  alignChildren,
  animate,
  padding = "large",
  maxWidthRem,
  ...rest
}) => {
  const childrenArray = Children.toArray(children);

  const springs = useSprings(childrenArray.length, index => ({
    from: {
      opacity: 0,
      transform: "translateY(0.5rem)"
    },
    to: {
      opacity: 1,
      transform: "translateY(0rem)",
      textAlign: alignChildren ? alignChildren : "intial"
    },
    delay: !animate ? 0 : index === 0 ? 200 : index * 60 + 200,
    config: { ...config.stiff, clamp: true },
    immediate: !animate
  }))[0];

  return (
    <Wrapper {...rest} $padding={padding}>
      <StyledListEmptyState $maxWidth={maxWidthRem}>
        {childrenArray.map((child, index) => (
          <animated.div key={`empty-list-state-item-${index}`} style={springs[index]}>
            {child}
          </animated.div>
        ))}
      </StyledListEmptyState>
    </Wrapper>
  );
};

ListEmptyStateComponent.Image = Image;
ListEmptyStateComponent.Title = CenteredH4;
ListEmptyStateComponent.Buttons = Buttons;

export const ListEmptyState = ListEmptyStateComponent;
