import { timeMatcher } from "../../../utils/string-templater";
import { emailOrEmployeeAttributeSchemaField } from "./schema-helpers";

const dateMatcher = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export const EventStepSchemaFields = () => ({
  hostId: {
    type: "string",
    required: true
  },
  eventType: {
    type: "string",
    required: true,
    enum: ["individual", "common"]
  },
  title: {
    type: "string",
    required: true
  },
  description: {
    type: "string"
  },
  fromTime: {
    type: "string",
    required: true,
    use: {
      validateInput: (value: string) => {
        if (timeMatcher.test(value)) {
          return true;
        }
        if (dateMatcher.test(value)) {
          return true;
        }
        return false;
      }
    }
  },
  toTime: {
    type: "string",
    required: true,
    use: {
      validateInput: (value: string) => {
        if (timeMatcher.test(value)) {
          return true;
        }
        if (dateMatcher.test(value)) {
          return true;
        }
        return false;
      }
    }
  },
  participants: {
    type: Array,
    each: emailOrEmployeeAttributeSchemaField(true)
  },
  private: {
    type: Boolean
  },
  useAvailability: {
    type: Boolean
  },
  sendInvitesAt: {
    type: "string",
    required: false
  }
});
