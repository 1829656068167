import { useCallback, useRef, useState } from "react";
import { Expression } from "@introist/introist-commons/dist";
import constate from "constate";
import {
  ConditionGroupBase,
  ConditionGroupLevel2,
  conditionGroupToExpression,
  expressionToConditionGroup
} from "./types";
import { ComparisonMode, ConditionWithId, ExpressionField } from "components/organisms";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";

export const [ExpressionEditorContextProvider, useExpressionEditorContext] = constate(
  ({
    expression,
    onChange
  }: {
    onChange: (expression: Expression) => void;
    expression?: Expression;
  }) => {
    const [conditionGroup, setConditionGroup] = useState(
      expressionToConditionGroup(expression || { and: [] })
    );

    const [selectedConditionId, setSelectedConditionId] = useState<string>();

    const rollbackConditionRef = useRef<ConditionWithId>();
    const isNewConditionRef = useRef(false);

    const getConditionOrGroupById = useCallback(
      (id: string): ConditionWithId | ConditionGroupLevel2 | null => {
        for (const condition of conditionGroup.conditions) {
          if ("id" in condition && condition.id === id) {
            return condition;
          }
        }
        return null;
      },
      [conditionGroup]
    );

    const updateConditionById = useCallback(
      (id: string, updatedFields: Partial<ConditionWithId>) => {
        const updatedConditionGroup = produce(conditionGroup, draft => {
          const findAndUpdateCondition = (conditions: any[]) => {
            for (let i = 0; i < conditions.length; i++) {
              const condition = conditions[i];
              if ("id" in condition && condition.id === id) {
                const updatedCondition = { ...condition, ...updatedFields };
                Object.assign(condition, updatedCondition);
                return true;
              }
              if ("conditions" in condition) {
                if (findAndUpdateCondition(condition.conditions)) {
                  return true;
                }
              }
            }
            return false;
          };

          findAndUpdateCondition(draft.conditions as any);
        });

        setConditionGroup(updatedConditionGroup);
        const expression = conditionGroupToExpression(updatedConditionGroup);
        onChange(expression);
      },
      [conditionGroup, setConditionGroup, onChange]
    );

    const deleteConditionById = useCallback(
      (id: string, groupId?: string) => {
        const updatedConditionGroup = produce(conditionGroup, draft => {
          const deleteCondition = (conditions: any[]) => {
            for (let i = 0; i < conditions.length; i++) {
              const condition = conditions[i];
              if ("conditions" in condition) {
                if (deleteCondition(condition.conditions)) {
                  if (!groupId) setSelectedConditionId(undefined);
                  return true;
                }
              }

              if ("id" in condition && condition.id === id) {
                conditions.splice(i, 1);

                if (groupId) {
                  // Handle group case
                  const parentGroup = draft.conditions.find(c => c.id === groupId);
                  if (
                    parentGroup &&
                    "conditions" in parentGroup &&
                    (parentGroup as any).conditions.length === 0
                  ) {
                    draft.conditions = draft.conditions.filter(c => c.id !== groupId);
                    setSelectedConditionId(undefined);
                  }
                } else {
                  // Handle singular rule case
                  setSelectedConditionId(undefined);
                }
                return true;
              }
            }
            return false;
          };

          deleteCondition(draft.conditions as any);
        });

        setConditionGroup(updatedConditionGroup);
        const expression = conditionGroupToExpression(updatedConditionGroup);
        onChange(expression);
      },
      [conditionGroup, setSelectedConditionId, onChange]
    );

    // Add a condition to an existing condition
    const addCondition = useCallback(
      (id: string, condition: ConditionWithId | ConditionGroupLevel2) => {
        const updatedConditionGroup = produce(conditionGroup, draft => {
          const addToGroup = (conditions: any[]) => {
            for (let i = 0; i < conditions.length; i++) {
              const condition = conditions[i];

              // If the condition is a group, recurse
              if ("conditions" in condition && condition.id === id) {
                const baseCondition = condition.conditions[0];

                // Create new condition with empty value
                const newCondition: ConditionWithId = {
                  id: uuidv4(),
                  attribute: baseCondition.attribute,
                  comparator: baseCondition.comparator,
                  value: ""
                };

                condition.conditions.push(newCondition);
                return true;
              }
            }
            return false;
          };

          if ("conditions" in condition) {
            addToGroup(draft.conditions as any);
          } else {
            // Find single condition with id
            const singleCondition = conditionGroup.conditions.find(c => c.id === id);

            if (!singleCondition) {
              return;
            }

            // Create new condition group with 2 conditions: the original condition and a new empty condition
            const newConditionGroup: ConditionGroupBase = {
              mode: "or",
              id: singleCondition.id, // Assign the id of the original condition to keep the card selected
              conditions: [
                { ...singleCondition, id: uuidv4() },
                {
                  id: uuidv4(),
                  attribute: singleCondition.attribute,
                  comparator: singleCondition.comparator,
                  value: ""
                }
              ]
            };

            // Replace the original condition with the new condition group
            draft.conditions = draft.conditions.map(c =>
              c.id === singleCondition.id ? newConditionGroup : c
            ) as any;
          }
        });

        setConditionGroup(updatedConditionGroup);
        const expression = conditionGroupToExpression(updatedConditionGroup);
        onChange(expression);
      },
      [conditionGroup, onChange, setConditionGroup]
    );

    const changeComparisonMode = useCallback(
      (mode: ComparisonMode) => {
        const nextConditionGroup = { ...conditionGroup, mode };
        setConditionGroup(nextConditionGroup);
        const expression = conditionGroupToExpression(nextConditionGroup);
        onChange(expression);
      },
      [conditionGroup, setConditionGroup, onChange]
    );

    const selectCondition = useCallback(
      (id: string) => {
        setSelectedConditionId(id);
        rollbackConditionRef.current = getConditionOrGroupById(id) as ConditionWithId;
      },
      [setSelectedConditionId, getConditionOrGroupById]
    );

    const createCondition = useCallback(
      (field: ExpressionField) => {
        isNewConditionRef.current = true;
        const updatedConditionGroup = produce(conditionGroup, draft => {
          const newCondition: ConditionWithId = {
            id: uuidv4(),
            attribute: field.variable,
            comparator: "eq",
            value: ""
          };

          selectCondition(newCondition.id);
          draft.conditions.push(newCondition);
        });

        setConditionGroup(updatedConditionGroup);
        onChange(conditionGroupToExpression(updatedConditionGroup));
      },
      [conditionGroup, selectCondition, setConditionGroup, onChange]
    );

    const replaceConditionById = useCallback(
      (id: string, newCondition: ConditionWithId, groupId?: string) => {
        const updatedConditionGroup = produce(conditionGroup, draft => {
          const replaceCondition = (conditions: any[]) => {
            for (let i = 0; i < conditions.length; i++) {
              const condition = conditions[i];
              if ("conditions" in condition) {
                replaceCondition(condition.conditions);
              }

              if ("id" in condition && condition.id === id) {
                conditions[i] = { ...newCondition, id }; // Replace the condition
                return true;
              }
            }
            return false;
          };

          if (groupId) {
            const group = draft.conditions.find(c => c.id === groupId);
            if (group && "conditions" in group) {
              replaceCondition((group as unknown as ConditionGroupBase).conditions);
            }
          } else {
            replaceCondition(draft.conditions as ConditionWithId[]);
          }
        });

        setConditionGroup(updatedConditionGroup);
        const expression = conditionGroupToExpression(updatedConditionGroup);
        onChange(expression);
      },
      [conditionGroup, onChange]
    );

    const cancelConditionEdit = useCallback(
      (id: string) => {
        if (isNewConditionRef.current) {
          deleteConditionById(id);
          return;
        }

        replaceConditionById(id, rollbackConditionRef.current as ConditionWithId);
        setSelectedConditionId(undefined);
      },
      [
        rollbackConditionRef,
        isNewConditionRef,
        deleteConditionById,
        replaceConditionById,
        setSelectedConditionId
      ]
    );

    return {
      expression,
      conditionGroup,
      selectedConditionId,
      setSelectedConditionId,
      addCondition,
      createCondition,
      getConditionOrGroupById,
      updateConditionById,
      deleteConditionById,
      cancelConditionEdit,
      selectCondition,
      changeComparisonMode
    };
  }
);
