import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Layout, Title, useToast } from "@introist/react-foundation/v2";
import useMeasure from "react-use-measure";
import { EmployeeSelectList } from "./EmployeeSelectList";
import { CompanyEvent, CompanyEventPreviewImportEmployee, api } from "services/rpc/RpcProvider";
import { formatDate, formatDateTime } from "utils/DatesUtils";

export type SyncCompanyEventProps = {
  companyEvent: CompanyEvent;
  onAttendeesAdded?: VoidFunction;
};

const StyledSyncCompanyEvent = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const SyncCompanyEvent = ({
  companyEvent,
  onAttendeesAdded,
  ...rest
}: SyncCompanyEventProps) => {
  const toast = useToast();
  const { mutateAsync: getPreviewAttendees, isLoading: previewLoading } =
    api.companyEvents.previewImport.useMutation({});
  const { mutateAsync: importAttendees, isLoading: addLoading } =
    api.companyEvents.attendees.import.useMutation({});
  const [previewAttendees, setPreviewAttendees] = useState<CompanyEventPreviewImportEmployee[]>();

  const [measureRef, { height }] = useMeasure();

  useEffect(() => {
    if (previewAttendees !== undefined) return;
    getPreviewAttendees({ companyEventId: companyEvent.id }).then(setPreviewAttendees);
  }, [previewAttendees, companyEvent, setPreviewAttendees, getPreviewAttendees]);

  const sortedAttendees = useMemo(() => {
    return previewAttendees?.sort((a, b) => {
      const aDate = new Date(a.eventInstance.startTime);
      const bDate = new Date(b.eventInstance.startTime);
      return aDate.getTime() - bDate.getTime();
    });
  }, [previewAttendees]);

  const addAttendees = useCallback(
    async (attendees: CompanyEventPreviewImportEmployee[]) => {
      await importAttendees(
        {
          companyEventId: companyEvent.id,
          attendees: attendees.map(a => ({
            email: a.email!,
            name: a.name,
            calendarEventId: a.eventInstance.calendarEventId
          }))
        },
        {
          onSuccess: () => {
            getPreviewAttendees({ companyEventId: companyEvent.id }).then(setPreviewAttendees);
            onAttendeesAdded?.();

            toast.success(
              attendees.length === 1 ? `Attendee added` : `${attendees.length} attendees added`
            );
          },
          onError: () =>
            toast.error(
              attendees.length === 1
                ? "Failed to add attendee"
                : `Failed to add ${attendees.length} attendees`
            )
        }
      );
    },
    [importAttendees, getPreviewAttendees, onAttendeesAdded, toast, companyEvent]
  );

  return (
    <StyledSyncCompanyEvent {...rest} ref={measureRef}>
      <EmployeeSelectList
        searchActive
        animate
        loading={previewLoading}
        stackNameAndEmail
        onAdd={selected => {
          const emails = selected.map(s => s.email!);
          const attendees = previewAttendees?.filter(a => emails.includes(a.email!));

          if (!attendees) return;
          addAttendees(attendees);
        }}
        addLoading={addLoading}
        maxHeight={height}
        addButtonText="Add to Introist"
        employees={
          sortedAttendees
            ? sortedAttendees.map(e => ({
                employeeId: `${e.email}-${e.eventInstance.calendarEventId}`,
                name: e.name,
                email: e.email,
                hrisId: null,
                rowEndItems: (
                  <Layout.Group vertical gap="xSmall">
                    <Title variant="bold">
                      {formatDate(e.eventInstance.startTime, "MMM D, YYYY")}
                    </Title>
                    <Title>
                      {formatDateTime(e.eventInstance.startTime, "HH:mm")} -{" "}
                      {formatDateTime(e.eventInstance.endTime, "HH:mm")}
                    </Title>
                  </Layout.Group>
                )
              }))
            : []
        }
      />
    </StyledSyncCompanyEvent>
  );
};
