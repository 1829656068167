import { useFeatureFlag } from "../../../hooks";
import { listMaterials, MaterialOverview } from "../../../services/api/MaterialsApi";
import { useEffect, useState } from "react";

export const useMaterials = () => {
  const hasMaterials = useFeatureFlag("legacy").isEnabled;

  const [materials, setMaterials] = useState<MaterialOverview[] | undefined>(undefined);

  useEffect(() => {
    if (hasMaterials) {
      listMaterials().then(materials => setMaterials(materials));
    }
  }, [hasMaterials]);

  return { materials };
};
