import { useEffect, useRef } from "react";
import { IntroistLoader, useToast } from "@introist/react-foundation/v2";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { completeUserSlackConnect, getUserSlackConnectUrl } from "../../services/api/UserApi";

export const RETURN_PATH_KEY = "intr.user.sl.url";

export const ConnectUserSlackAccountRoute = () => {
  const { provider } = useParams();
  const { state } = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      if (state?.returnPath) {
        sessionStorage.setItem(RETURN_PATH_KEY, state.returnPath);
      }

      getUserSlackConnectUrl()
        .then(url => {
          window.location.replace(url);
        })
        .catch(() => {
          toast.error("Failed to connect Slack");
          navigate(-1);
        });
    }
  }, [provider, navigate, toast, state]);

  return (
    <div>
      <IntroistLoader globalCenter />
    </div>
  );
};

export const CompleteConnectUserSlackAccountRoute = () => {
  const { provider } = useParams();
  const toast = useToast();

  const navigate = useNavigate();
  const { search } = useLocation();

  const mounted = useRef(false);

  const returnPath = sessionStorage.getItem(RETURN_PATH_KEY);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      const searchParams = new URLSearchParams(search);
      const code = searchParams.get("code");

      completeUserSlackConnect(code ?? "", window.location.origin + window.location.pathname)
        .then(() => {
          toast.success("Slack connected");
          navigate(returnPath ?? "/");
        })
        .catch(() => {
          toast.error("Failed to connect Slack");
          navigate(returnPath ?? "/");
        })
        .finally(() => {
          sessionStorage.removeItem(RETURN_PATH_KEY);
        });
    }
  }, [provider, toast, search, navigate, returnPath]);

  return (
    <div>
      <IntroistLoader globalCenter />
    </div>
  );
};
