import { api } from "../../services/rpc/RpcProvider";
import { useMemo } from "react";
import moment from "moment";

import "chart.js/auto";

import { Bar } from "react-chartjs-2";

const useMonthlyNewHireData = () => {
  const { data: employees } = api.employees.v4.list.useQuery({ fields: ["startdate"] });

  const monthlyNewHires = useMemo(() => {
    const from = moment().startOf("month").subtract(8, "months");

    const monthlyNewHires: { month: string; newHires: any[] }[] = [];

    while (from <= moment().add(3, "month").startOf("month")) {
      const month = from.format("YYYY-MM");
      const newHires = (employees ?? []).filter(
        employee => moment(employee.startdate).format("YYYY-MM") === month
      );

      monthlyNewHires.push({ month, newHires });
      from.add(1, "month");
    }

    return monthlyNewHires;
  }, [employees]);

  return { monthlyNewHires };
};

export const MonthlyNewHiresChart = () => {
  const { monthlyNewHires } = useMonthlyNewHireData();

  const labels = monthlyNewHires.map(({ month }) => moment(month).format("MMM YYYY"));
  const data = monthlyNewHires.map(({ newHires }) => newHires.length);

  const colors = monthlyNewHires.map(({ month }) =>
    moment(month).isSame(moment(), "month") ? "#105EFB" : "#BCD2FE"
  );

  return (
    <Bar
      height={50}
      data={{
        labels,
        datasets: [{ backgroundColor: colors, data }]
      }}
      options={{
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            max: Math.max(...data) + 1,
            beginAtZero: true,
            display: false
          }
        }
      }}
    />
  );
};
