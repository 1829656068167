import { Card, H3, Layout, TextButton, Title } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  icon: ReactNode;
  helpCenterLink?: string;
};

const StyledFeatureHeader = styled(Card)`
  border: none;
`;

export const FeatureHeader = ({
  icon,
  title,
  description,
  helpCenterLink,
  children,
  ...rest
}: Props) => {
  return (
    <StyledFeatureHeader {...rest} elevated spacing="xLarge">
      <Layout.Group gap="xxLarge">
        <div>{icon}</div>
        <Layout.Group vertical gap="large">
          <Layout.Group vertical gap="xSmall">
            <H3>{title}</H3>
            {(description || helpCenterLink) && (
              <Layout.Group>
                <Title>{description}</Title>
                {helpCenterLink && <TextButton colorVariant="primary">Learn more</TextButton>}
              </Layout.Group>
            )}
          </Layout.Group>
          <Layout.Group gap="large">{children}</Layout.Group>
        </Layout.Group>
      </Layout.Group>
    </StyledFeatureHeader>
  );
};
