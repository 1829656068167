import { ReactNode, useEffect, useRef } from "react";
import { Card, Icon, Layout, Title, Toggle, Tooltip } from "@introist/react-foundation/v2";
import { animated, useSpring } from "@react-spring/web";
import useMeasure from "react-use-measure";
import styled from "styled-components";

type ToggleSettingCardProps = {
  active: boolean;
  onToggleActive: (active: boolean) => void;
  title: string;
  description: string;
  children?: ReactNode;
  autoCollapse?: boolean;
  readOnly?: boolean;
};

const StyledAccordionCard = styled(Card)`
  overflow: hidden;
  padding: 0;

  > header {
    padding: var(--spacing-large);
  }
`;

const AnimatedContent = styled(animated.section)`
  overflow: hidden;
  > div {
    padding-left: var(--spacing-large);
    padding-right: var(--spacing-large);
    padding-bottom: var(--spacing-large);
  }
`;

export const IconWrapper = styled.div`
  display: grid;

  > svg {
    fill: var(--palette-foreground-dimmed) !important;
  }
`;

export const ToggleSettingCard = ({
  title,
  description,
  active,
  onToggleActive,
  autoCollapse,
  children
}: ToggleSettingCardProps) => {
  const immediateRef = useRef(true);
  const [contentRef, { height: contentHeight }] = useMeasure();

  const heightSpring = useSpring({
    height: active ? contentHeight : 0,
    opacity: active ? 1 : 0,
    config: { clamp: true, tension: 200 },
    immediate: immediateRef.current
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      immediateRef.current = false;
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  if (autoCollapse) {
    return (
      <StyledAccordionCard>
        <header>
          <Layout.Group justifyContent="space-between" gap="large">
            <Layout.Group gap="xSmall">
              <Title variant="bold">{title}</Title>
              <Tooltip tooltip={description} style={{ maxWidth: 248 }}>
                <IconWrapper>
                  <Icon name="infoFilled" />
                </IconWrapper>
              </Tooltip>
            </Layout.Group>
            <Toggle small checked={active} onChange={onToggleActive} />
          </Layout.Group>
        </header>
        <AnimatedContent style={heightSpring}>
          <Layout.Group vertical ref={contentRef}>
            {children}
          </Layout.Group>
        </AnimatedContent>
      </StyledAccordionCard>
    );
  }

  return (
    <Card>
      <Layout.Group vertical gap="large">
        <Layout.Group justifyContent="space-between">
          <Title variant="bold">{title}</Title>
          <Toggle small checked={active} onChange={onToggleActive} />
        </Layout.Group>
        <Title>{description}</Title>
        {children}
      </Layout.Group>
    </Card>
  );
};
