import { useWorkflows } from "../../flows/hooks/useWorkflows";
import { useCallback, useMemo, useState } from "react";
import {
  Field,
  LabelledCheckbox,
  Modal,
  Select,
  Stack,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { FormFooter } from "../../datasources/components/FormFooter";
import { createJourney } from "../../../services/api/journeys/JourneyApi";

type StartAutomationProps = {
  employeeId?: string;
  module?: string;
  onStart: (employeeId: string, journeyId: string) => Promise<void>;
  onClose: () => void;
};

export const StartAutomation = ({ module, employeeId, onStart, onClose }: StartAutomationProps) => {
  const toast = useToast();
  const { workflows } = useWorkflows({ module });
  const { data: employees } = api.employees.v4.list.useQuery({});

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string | undefined>(employeeId);
  const [workflowId, setWorkflowId] = useState<string | undefined>();
  const [draft, setDraft] = useState(false);

  const workflowOptions = useMemo(() => {
    if (!workflows) return [];
    return workflows.map(workflow => ({
      key: workflow.id,
      title: workflow.title
    }));
  }, [workflows]);

  const employeeOptions = useMemo(() => {
    if (!employees) return [];
    return employees.map(employee => ({
      key: employee.id!,
      title: employee.name!
    }));
  }, [employees]);

  const start = useCallback(async () => {
    const req = {
      employeeId: selectedEmployeeId!,
      workflowId: workflowId!,
      draft
    };

    await createJourney(req)
      .then(async res => {
        await onStart(selectedEmployeeId!, res.id);
        toast.success("Automation started");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to start automation");
      });
  }, [workflowId, draft, onStart, onClose, selectedEmployeeId, toast]);

  return (
    <Stack vertical>
      <Field title="Employee">
        <Select
          searchable
          style={{ width: "100%" }}
          placeholder="Select employee"
          options={employeeOptions}
          value={selectedEmployeeId}
          readOnly={!!employeeId}
          disabled={!!employeeId}
          onSelect={opt => setSelectedEmployeeId(opt.key)}
        />
      </Field>
      <Field title="Workflow">
        <Select
          searchable
          style={{ width: "100%" }}
          placeholder="Select workflow"
          options={workflowOptions}
          value={workflowId}
          onSelect={opt => setWorkflowId(opt.key)}
        />
      </Field>
      <LabelledCheckbox
        label="Start as draft"
        checked={draft}
        onChange={setDraft}
        style={{ marginTop: "12px" }}
      />
      <FormFooter
        onCancel={onClose}
        onSubmit={start}
        submit="Start"
        submitDisabled={!selectedEmployeeId || !workflowId}
      />
    </Stack>
  );
};

export const StartAutomationModal = ({
  open,
  onClose,
  ...props
}: StartAutomationProps & { open: boolean; onClose: () => void }) => (
  <Modal open={open} onClose={onClose} title="Start automation" style={{ width: "400px" }}>
    {open && <StartAutomation {...props} onClose={onClose} />}
  </Modal>
);
