import { config, useSpring } from "@react-spring/web";

export const useCrossLineSprings = (animationDelayMs = 260) => {
  const crossSpring = useSpring({
    from: {
      transform: "scale(0) translate(-50%, -50%)"
    },
    to: {
      transform: "scale(1) translate(-50%, -50%)"
    },
    config: { ...config.stiff, clamp: true },
    delay: animationDelayMs * 1.25
  });

  const horizontalLineSpring = useSpring({
    from: {
      transform: "scaleX(0)"
    },
    to: {
      transform: "scaleX(1)"
    },
    delay: animationDelayMs,
    config: { ...config.stiff, clamp: true }
  });

  const verticalLineSpring = useSpring({
    from: {
      transform: "scaleY(0)"
    },
    to: {
      transform: "scaleY(1)"
    },
    delay: animationDelayMs,
    config: { ...config.stiff, clamp: true }
  });

  return {
    crossSpring,
    horizontalLineSpring,
    verticalLineSpring
  };
};
