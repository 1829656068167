import Quill from "quill";
import { v4 as uuid } from "uuid";

const Embed = Quill.import("blots/embed");

export class FieldEmbedBlock extends Embed {
  static blotName = "field";
  static tagName = "field";

  static get ATTRIBUTES() {
    return ["data-property-field", "data-property-title", "readonly", "contenteditable", "class"];
  }

  static formats(domNode: any) {
    return this.ATTRIBUTES.reduce(function (formats: any, attribute: any) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static value(domNode: any) {
    return { ...this.formats(domNode) };
  }

  static create(value: { "data-property-field": string; "data-property-title": string }) {
    const node = super.create(uuid());

    const field = value["data-property-field"];
    const title = value["data-property-title"];

    node.setAttribute("data-property-field", field);
    node.setAttribute("data-property-title", title);
    node.setAttribute("readonly", true);
    node.setAttribute("contenteditable", false);
    node.size = Math.max(1, (title?.length ?? 2) + 2);
    node.innerHTML = title;
    return node;
  }
}
