import { useState } from "react";
import { BaseModal, Button, H4, Layout, useToast } from "@introist/react-foundation/v2";
import { GroupedEmployeeField, api } from "services/rpc/RpcProvider";

import { FieldNameInput, FieldTypeSelect, SelectFieldOptions } from "../components";

type Props = {
  field: GroupedEmployeeField | null;
  onUpdated: VoidFunction;
  onClose: VoidFunction;
};

export const UpdateEmployeeField = ({ field, onUpdated, onClose, ...rest }: Props) => {
  const toast = useToast();
  const [name, setName] = useState(field?.name || "");
  const [selectOptions, setSelectOptions] = useState<string[]>(field?.options || []);

  const { mutateAsync } = api.employees.fields.update.useMutation({});
  const isSelect = field?.type === "select";
  const isGroup = field?.type === "group";
  const hasChanges = name !== field?.name;

  return (
    <Layout.Group vertical {...rest} lastChildToEnd gap="xLarge">
      <FieldNameInput value={name} onChange={setName} />
      {!isGroup && <FieldTypeSelect value={field?.type} />}
      {isSelect && <SelectFieldOptions options={selectOptions} onChange={setSelectOptions} />}

      <Button
        disabled={!name || !hasChanges}
        onClickWithLoading={async () => {
          if (!field) {
            return;
          }

          await mutateAsync(
            { fieldId: field?.id, updates: { name, options: selectOptions || undefined } },
            {
              onSuccess: () => {
                onUpdated();
                toast.success("Field updated");
              },
              onError: () => toast.error("Failed to update field")
            }
          );
        }}
      >
        Save
      </Button>
    </Layout.Group>
  );
};

export const UpdateEmployeeFieldModal = ({
  open,
  field,
  title,
  onClose,
  ...rest
}: Props & {
  open: boolean;
  title?: string;
  onClose: VoidFunction;
}) => {
  const isGroup = field?.type === "group";
  return (
    <BaseModal
      open={open}
      header={<H4>{title || (isGroup ? "Edit employee field group" : "Edit employee field")}</H4>}
      onClose={onClose}
      style={{ width: "100%", maxWidth: 640 }}
      closeOnOutsideClick
    >
      <UpdateEmployeeField field={field} onClose={onClose} {...rest} />
    </BaseModal>
  );
};
