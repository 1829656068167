import { generateMutation, generateQueryWithParameters, getJson, postJson } from "./ApiBase";

export interface FormField {
  id: string;
  title: string;
  description: string;
  type: "text" | "email" | "date" | "longtext" | "number";
  options: string[];
  required: boolean;
}

export interface Form {
  title: string;
  description: string;
  fields: FormField[];
}

export const getFormResponse = ({ responseId }: { responseId: string }): Promise<Form> => {
  return getJson(`/form/${responseId}`);
};

export const submitFormResponse = ({
  responseId,
  submission
}: {
  responseId: string;
  submission: { [key: string]: string | undefined };
}): Promise<any> => {
  return postJson(`/form/${responseId}`, submission);
};

export const usePublicFormApi = () => ({
  find: generateQueryWithParameters<{ responseId: string }, Form>(
    ["formresponse"],
    getFormResponse
  ),
  submit: generateMutation(submitFormResponse)
});
