import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Layout } from "@introist/react-foundation/v2";
import { animated, config, useSpring, useChain, useSpringRef } from "@react-spring/web";
import { ReactComponent as IntroistLogo } from "assets/introist-symbol.svg";
import { AnimatedDots, CrossLine, HostCalendarIcon } from "components/atoms";

type Props = {
  success?: boolean;
  emailProvider?: string;
};

const lerp = (start: number, end: number, alpha: number): number => {
  return (1 - alpha) * start + alpha * end;
};

const StyledCalendarConnectionStatus = styled.div``;

const BUBBLE_SIZE_PX = 5 * 16;

const Bubble = styled.div<{ $success?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${BUBBLE_SIZE_PX}px;
  width: ${BUBBLE_SIZE_PX}px;
  border-radius: 50%;
  border: 1px solid transparent;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 4px 16px rgba(0, 0, 0, 0.08);
  background-color: var(--palette-background-default);
  transition-property: border-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: relative;
  z-index: 5;

  > :first-child {
    width: 2rem;
    height: 2rem;
  }

  ${({ $success }) => {
    if ($success === undefined) return;

    if ($success === true) {
      return css`
        border-color: var(--palette-primary-default);
        box-shadow: 0px 0px 2px rgba(0, 153, 255, 0.24), 0px 4px 16px rgba(0, 153, 255, 0.15);
      `;
    }
    if ($success === false) {
      return css`
        border-color: var(--palette-danger-default);
        box-shadow: 0px 0px 2px #ea4335, 0px 4px 16px rgba(234, 67, 53, 0.15);
      `;
    }
  }}

  ${({ $success }) => $success && css``}
`;

export const CalendarConnectionStatus = ({
  success: successProp,
  emailProvider,
  ...rest
}: Props) => {
  const xStart = 228 / 2 - 82 / 2;

  const scaleSpringRef = useSpringRef();

  const [success, setSuccess] = useState<boolean>();

  const scaleSpring = useSpring({
    ref: scaleSpringRef,
    from: {
      opacity: 0,
      transform: "scale(0.7)"
    },
    to: {
      opacity: 1,
      transform: "scale(1)"
    },
    config: { ...config.stiff, clamp: true },
    delay: 200
  });

  const pSpringRef = useSpringRef();
  const { p } = useSpring({
    ref: pSpringRef,
    from: {
      p: 0
    },
    to: {
      p: 1
    }
  });

  useChain([scaleSpringRef, pSpringRef]);

  useEffect(() => {
    if (successProp === undefined) return;

    const timeout = setTimeout(() => {
      setSuccess(successProp);
    }, 750);

    return () => {
      clearTimeout(timeout);
    };
  }, [successProp, setSuccess]);

  return (
    <StyledCalendarConnectionStatus {...rest}>
      {successProp === undefined && (
        <animated.div style={scaleSpring}>
          <Bubble>
            <IntroistLogo />
          </Bubble>
        </animated.div>
      )}

      {(successProp === true || successProp === false) && (
        <Layout.Group gap="none">
          <animated.div
            style={{
              position: "relative",
              zIndex: 2,
              transform: p.to(p => `translateX(${lerp(xStart, 0, p)}px)`)
            }}
          >
            <animated.div style={scaleSpring}>
              <Bubble $success={success} style={{ zIndex: 1, position: "relative" }}>
                <HostCalendarIcon emailProvider={emailProvider} />
              </Bubble>
            </animated.div>
          </animated.div>
          {successProp === true ? (
            <Layout.Group
              justifyContent="center"
              style={{ width: 64, padding: "0px 10px", boxSizing: "border-box" }}
            >
              <AnimatedDots colorVariant="primary" enterAnimationDelayMs={800} />
            </Layout.Group>
          ) : (
            <CrossLine.Horizontal
              animate
              animationDelayMs={800}
              length={4}
              from="left"
              thickness={2}
              crossBackgroundColor="white"
              style={{ zIndex: -1 }}
            />
          )}
          <animated.div
            style={{
              position: "relative",
              zIndex: 2,
              transform: p.to(p => `translateX(-${lerp(xStart, 0, p)}px)`)
            }}
          >
            <animated.div style={{ ...scaleSpring }}>
              <Bubble>
                <IntroistLogo />
              </Bubble>
            </animated.div>
          </animated.div>
        </Layout.Group>
      )}
    </StyledCalendarConnectionStatus>
  );
};
