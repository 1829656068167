import { ReactNode, useCallback } from "react";
import styled, { css } from "styled-components";
import { Card, Icon, Select, Title, Layout, useTheme } from "@introist/react-foundation/v2";
import { Expression } from "@introist/introist-commons/dist/object-filter";

import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { ConditionRow } from "./ConditionRow";

import {
  ComparisonMode,
  ComparisonModeOptions,
  ConditionGroup,
  conditionGroupToExpression
} from "./types";

import { useExpressionEditor } from "./hooks/use-expression-editor";

type ExpressionEditorProps = {
  expression: Expression;
  attributes: EmployeeAttribute[];
  onChanged: (expression: Expression) => void;
  onRemoved?: () => Promise<void>;
  readonly?: boolean;
  removeIfEmpty?: boolean;
  headers?: ReactNode;
  blended?: boolean;
  immediateOnChange?: boolean;
  prefix?: ReactNode;
  hideAllRulesApplier?: boolean;
};

const StyledExpressionEditor = styled(Card)<{ $blended?: boolean }>`
  ${({ $blended }) =>
    $blended &&
    css`
      && {
        padding: 0;
        border: none;
      }
    `}
`;

const PrimaryTextButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const Conditions = styled(Layout.Group)`
  position: relative;
`;

const EmptyConditionsCard = styled(Card)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 39.4px; // Exact height of ConditionRow

  && {
    border: none;
    background: var(--palette-surface-dimmed);
  }
`;

export const ExpressionEditor = ({
  expression: initialExpression,
  attributes,
  onRemoved,
  onChanged,
  removeIfEmpty,
  readonly,
  hideAllRulesApplier,
  blended = true,
  prefix,
  ...rest
}: ExpressionEditorProps) => {
  const { theme } = useTheme();

  const handleChange = useCallback(
    async (changed: ConditionGroup) => {
      if (changed.conditions.length === 0 && removeIfEmpty && onRemoved) {
        await onRemoved();
      } else onChanged(conditionGroupToExpression(changed));
    },
    [onChanged, onRemoved, removeIfEmpty]
  );

  const { conditionGroup, onUpdateCondition, onModeChange, onCreateCondition, onDeleteCondition } =
    useExpressionEditor({
      initialExpression,
      onChange: handleChange
    });

  return (
    <StyledExpressionEditor {...rest} $blended={blended}>
      <Layout.Group vertical gap="xLarge">
        {!hideAllRulesApplier && (
          <Layout.Group>
            {prefix}
            <Select
              size="small"
              style={{ width: "5.5rem" }}
              sameWidthWithReference
              options={ComparisonModeOptions}
              value={conditionGroup.mode}
              readonly={readonly}
              onSelect={option => {
                onModeChange(option.key as ComparisonMode);
              }}
            />
            <Title>of the rules match</Title>
          </Layout.Group>
        )}
        <Conditions vertical alignSelf="stretch" gap="large">
          {conditionGroup.conditions.length > 0 ? (
            conditionGroup.conditions.map((cond, index) => (
              <ConditionRow
                key={index}
                condition={cond}
                attributes={attributes}
                readonly={readonly}
                onRemove={id => {
                  onDeleteCondition(id);
                }}
                onChanged={condition => {
                  onUpdateCondition(condition);
                }}
              />
            ))
          ) : (
            <EmptyConditionsCard>
              <Title variant="bold">No rules set</Title>
            </EmptyConditionsCard>
          )}
          {!readonly && (
            <PrimaryTextButton onClick={() => onCreateCondition()}>
              <Icon name="plusSmall" color={theme.palette.primary.default} />
              <Title variant="bold" color={theme.palette.primary.default}>
                Add new rule
              </Title>
            </PrimaryTextButton>
          )}
        </Conditions>
      </Layout.Group>
    </StyledExpressionEditor>
  );
};
