import { useState } from "react";
import styled from "styled-components";

import { Block } from "components/atoms";
import { TabBar } from "components/molecules";

type Tab = "General" | "Advanced";

type TabBarBlockProps = {
  showAdvancedDot?: boolean;
  onChange: (currentTab: Tab) => void;
};

const StyledBlock = styled(Block)`
  padding-bottom: var(--spacing-large) !important;
`;

export const TabBarBlock = ({ onChange, showAdvancedDot, ...rest }: TabBarBlockProps) => {
  const [activeTab, setActiveTab] = useState("General");

  return (
    <StyledBlock blended compact {...rest}>
      <TabBar
        dottedTabs={showAdvancedDot ? ["Advanced"] : []}
        tabs={["General", "Advanced"]}
        activeTab={activeTab}
        onTabChange={tab => {
          setActiveTab(tab);
          onChange(tab as Tab);
        }}
      />
    </StyledBlock>
  );
};
