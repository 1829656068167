import { StepTypeV2 } from "services/rpc/RpcProvider";

// const isEve2nt = stepType === "event";
// const isCommonEvent = isEvent && form.get("stepData.eventType") === "common";

export const isStepEvent = (stepType: StepTypeV2) => stepType === "event";

// const getEventTimeRange = (fromTime: string, endTime: string) => {
//     return {
//       start: form.get("stepData.fromTime"),
//       end: form.get("stepData.toTime")
//     };
//   };
