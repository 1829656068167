import { useState } from "react";
import styled from "styled-components";
import { Card, Icon, Layout, Title, useTheme, Button, Stack } from "@introist/react-foundation/v2";

import {
  EmployeeAttribute,
  useEmployeeAttributeApi
} from "modules/employees/api/EmployeeAttributeApi";

import {
  ConditionEditorModalV2,
  ConditionMode
} from "../../../../routes/WorkflowEditor/StepEditor/ConditionEditorModal/v2";
import { ExistingRulesList } from "./components/ExistingRulesList";
import { Expression } from "@introist/introist-commons/dist/object-filter";
import { BackgroundCard } from "modules/workflows/routes/WorkflowEditor/StepEditor/ScheduleBlock/components/PreconditionStepsEditor";

type ConditionalStepSettingProps = {
  condition?: Expression;
  readOnly?: boolean;
  conditionMode?: ConditionMode;
  onDelete: () => void | unknown;
  onConditionChanged: (condition: Expression, conditionMode: string) => void | unknown;
};

const ConditionalStepTitle = ({ active }: { active: boolean }) => {
  const { theme } = useTheme();
  return (
    <Layout.Group>
      <Icon
        name="condition"
        color={active ? theme.palette.primary.default : theme.palette.foreground.subdued}
      />
      <Title variant="bold">Conditional step</Title>
    </Layout.Group>
  );
};

const StyledReadOnlyExistingRulesList = styled(ExistingRulesList)`
  && {
    padding: 0;
  }
`;

const ReadOnlyConditionalStep = ({
  condition,
  conditionMode,
  employeeAttributes
}: {
  condition?: Expression;
  conditionMode?: ConditionMode;
  employeeAttributes: EmployeeAttribute[];
}) => {
  return (
    <BackgroundCard>
      <Layout.Group vertical>
        <ConditionalStepTitle active={!!condition} />
        {condition ? (
          <StyledReadOnlyExistingRulesList
            readOnly
            expression={condition || { and: [] }}
            employeeAttributes={employeeAttributes}
            createTitle={isAnd => {
              const prefix =
                conditionMode === "executeif" ? "Execute step only if" : "Cancel step if";
              const suffix = isAnd ? "all of the rules match" : "any of the rules match";

              return `${prefix} ${suffix}`;
            }}
          />
        ) : (
          <Title>This step doesn't have any conditions</Title>
        )}
      </Layout.Group>
    </BackgroundCard>
  );
};

export const ConditionalStepSetting = ({
  condition,
  conditionMode = "executeif",
  onDelete,
  onConditionChanged,
  readOnly,
  ...rest
}: ConditionalStepSettingProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const hasConditions = !!condition;

  const attributeApi = useEmployeeAttributeApi();
  const { data: employeeAttributes } = attributeApi.list({ includeHidden: false }).query;

  if (readOnly) {
    return (
      <ReadOnlyConditionalStep
        condition={condition}
        conditionMode={conditionMode}
        employeeAttributes={employeeAttributes || []}
      />
    );
  }

  return (
    <Card>
      <Layout.Group {...rest} vertical gap="large">
        <Layout.Group vertical gap="xSmall">
          <ConditionalStepTitle active={hasConditions} />
          <Title style={{ marginLeft: 28 }}>
            Conditional steps execute only when specified criteria are met
          </Title>
        </Layout.Group>

        {hasConditions && !!employeeAttributes ? (
          <ExistingRulesList
            expression={condition || { and: [] }}
            employeeAttributes={employeeAttributes}
            onEdit={() => setModalOpen(true)}
            onDelete={onDelete}
            createTitle={isAnd => {
              const prefix =
                conditionMode === "executeif" ? "Execute step only if" : "Cancel step if";
              const suffix = isAnd ? "all of the rules match" : "any of the rules match";

              return `${prefix} ${suffix}`;
            }}
          />
        ) : (
          <Stack>
            <Button
              size="small"
              disabled={readOnly}
              variant="blended"
              startIcon="plusSmall"
              onClick={() => setModalOpen(true)}
            >
              Add rules
            </Button>
          </Stack>
        )}
      </Layout.Group>

      <ConditionEditorModalV2
        condition={condition || { and: [] }}
        onChanged={(condition, mode) => {
          onConditionChanged(condition, mode);
          setModalOpen(false);
        }}
        conditionMode={conditionMode}
        onRemoved={onDelete}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Card>
  );
};
