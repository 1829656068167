import { CircularLoader } from "@introist/react-foundation/v2";

import { completeCredentialConnection, useIntegrationsApi } from "services/api/IntegrationsApi";
import { ApiClientCredentialForm } from "../../components";

type ApiClientCredentialContainerProps = {
  integrationId: string;
  instructionsUrl?: string;
};

export const ApiClientCredentialContainer = ({
  integrationId,
  ...rest
}: ApiClientCredentialContainerProps) => {
  const api = useIntegrationsApi();
  const findIntegration = api.find({ integrationId });

  const { data: integration } = findIntegration.query;

  const onSave = async (clientId: string, clientSecret: string) => {
    const credentials = { clientId, clientSecret };
    return completeCredentialConnection({ integrationId, credentials });
  };

  if (!integration) {
    return <CircularLoader fillParent />;
  }

  return (
    <ApiClientCredentialForm
      onSaveClientCredentials={onSave}
      credentialsConfigured={!!integration.credentialsConfigured}
      {...rest}
    />
  );
};
