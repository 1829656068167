import {
  ComponentProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useOutsideClick } from "@introist/react-foundation/v2";
import { CommanderCommand } from "..";
import { useDebouncedSearch, useMouseMoveListener } from "hooks";
import { useKeyDownListener } from "./use-key-down-listener";

export const getIndexById = (id: string | null, options: CommanderCommand[]): number => {
  return options.findIndex(x => x.id === id);
};

export const isLastIndex = (index: number, listLength: number) => {
  return index === listLength - 1;
};

export const isFirstIndex = (index: number) => {
  return index === 0;
};

export const scrollToElement = (
  element: HTMLElement | null,
  parent: HTMLElement | null,
  direction: "down" | "up"
) => {
  if (!element || !parent) {
    return;
  }

  const elementBounds = element.getBoundingClientRect();
  const elementScroll = element.offsetTop + elementBounds.height;
  const parentScroll = parent.scrollTop;
  const parentBounds = parent.getBoundingClientRect();

  if (direction === "down") {
    if (parentScroll + parentBounds.height < elementScroll) {
      element.scrollIntoView(false);
      return;
    }
  } else {
    if (element.offsetTop < parentScroll) {
      element.scrollIntoView(true);
    }
  }
};

export const useCommander = (commands: CommanderCommand[]) => {
  const [open, setOpen] = useState(false);
  const [focusId, setFocusId] = useState<string | null>(null);
  const [mouseDisabled, setMouseDisabled] = useState(false);

  const commanderRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const commandRefs = useRef<Array<HTMLLIElement | null>>([]);

  const {
    inputValue: searchInputValue,
    query: debouncedSearchValue,
    handleInputChange
  } = useDebouncedSearch();

  const filteredCommands = useMemo(() => {
    const filtered = commands.filter(
      cmd => cmd.name.toLowerCase().indexOf(searchInputValue.toLowerCase()) > -1
    );

    return filtered;
  }, [searchInputValue, commands]);

  const updateInputValueAndFocus = useCallback(
    (value: string) => {
      handleInputChange(value);
      inputRef.current?.focus();
    },
    [handleInputChange]
  );

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setFocusId(null);
    updateInputValueAndFocus("");
  };

  const onMouseMove = useCallback(() => {
    setMouseDisabled(false);
  }, [setMouseDisabled]);

  const onMouseEnter = (commandId: string) => {
    if (mouseDisabled) return;
    setFocusId(commandId);
  };

  const onMouseLeave = () => {
    if (mouseDisabled) return;
    setFocusId(null);
  };

  const focusItem = (idx: number, direction: "down" | "up") => {
    setFocusId(filteredCommands[idx].id);
    scrollToElement(commandRefs.current[idx], listRef.current, direction);
  };

  const onArrowDown = () => {
    setMouseDisabled(true);
    inputRef.current?.blur();

    const currentFocusIndex = getIndexById(focusId, filteredCommands);

    if (currentFocusIndex === -1) {
      focusItem(0, "down");
      return;
    }

    if (isLastIndex(currentFocusIndex, filteredCommands.length)) {
      focusItem(0, "up");
      return;
    }

    const nextFocusIndex = currentFocusIndex + 1;

    focusItem(nextFocusIndex, "down");
  };

  const onArrowUp = () => {
    setMouseDisabled(true);

    const currentFocusIndex = getIndexById(focusId, filteredCommands);

    if (isFirstIndex(currentFocusIndex) || currentFocusIndex === -1) {
      const lastIdx = filteredCommands.length - 1;
      setFocusId(filteredCommands[lastIdx].id);
      scrollToElement(commandRefs.current[lastIdx], listRef.current, "down");
      return;
    }

    const nextFocusIndex = currentFocusIndex - 1;

    setFocusId(filteredCommands[nextFocusIndex].id);
    scrollToElement(commandRefs.current[nextFocusIndex], listRef.current, "up");
  };

  const onEnter = () => {
    if (!open) return;
    if (filteredCommands.length === 1) {
      filteredCommands[0].action(filteredCommands[0].id);

      onClose();
      return;
    }
    const command = commands.find(c => c.id === focusId);

    if (!command) return;

    command.action(command.id);
    onClose();
  };

  useEffect(() => {
    if (!open) return;
    if (debouncedSearchValue && filteredCommands.length === 1) {
      setFocusId(filteredCommands[0].id);
    }
  }, [open, debouncedSearchValue, filteredCommands, setFocusId]);

  const inputProps: Omit<ComponentProps<"input">, "ref"> & {
    ref: typeof inputRef;
  } = {
    ref: inputRef,
    type: "text",
    value: searchInputValue,
    placeholder: "Jump to",
    onChange: event => handleInputChange(event.target.value)
  };

  const listProps: Omit<ComponentProps<"ul">, "ref"> & {
    ref: typeof listRef;
  } = {
    role: "listbox",
    ref: listRef,
    tabIndex: -1,
    "aria-expanded": open,
    "aria-multiselectable": false,
    "aria-activedescendant": getIndexById(focusId, commands).toString()
  };

  const getCommandProps = (id: string, idx: number) => {
    return {
      "aria-selected": focusId === id,
      $highlight: id === focusId,
      key: id,
      ref: (ref: HTMLLIElement) => (commandRefs.current[idx] = ref),
      onMouseEnter: () => onMouseEnter(id),
      onMouseLeave: onMouseLeave,
      onClick: () => {
        commands.find(cmd => cmd.id === id)?.action(id);
        onClose();
      }
    };
  };

  const onTab = () => {
    if (!open) return;
    // if input has focus
    if (document.activeElement === inputRef.current) {
      if (focusId) {
        // if focusId is set, focus the command
        const idx = getIndexById(focusId, filteredCommands);
        focusItem(idx, "down");
      } else {
        // otherwise focus the first command
        focusItem(0, "down");
      }

      return;
    }

    // if list has focus
    if (focusId) {
      // if focusId is set, focus the input
      inputRef.current?.focus();
      return;
    }
  };

  const onBackspace = () => {
    inputRef.current?.focus();
  };

  //
  // Listeners
  useMouseMoveListener(onMouseMove);
  useKeyDownListener([
    {
      key: "K",
      isCommand: true,
      callback: onOpen
    },
    {
      key: "Escape",
      disabled: !open,
      callback: onClose
    },
    {
      key: "ArrowDown",
      disabled: !open,
      callback: onArrowDown
    },
    {
      key: "ArrowUp",
      disabled: !open,
      callback: onArrowUp
    },
    {
      key: "Enter",
      disabled: !open,
      callback: onEnter
    },
    {
      key: "Backspace",
      disabled: !open,
      callback: onBackspace
    },
    {
      key: "Tab",
      disabled: !open,
      callback: event => {
        event?.preventDefault();
        onTab();
      }
    }
  ]);

  useLayoutEffect(() => {
    if (!inputRef.current) {
      return;
    }

    if (!open) {
      inputRef.current.blur();
      return;
    }

    inputRef.current.focus();
  }, [inputRef, open]);

  useOutsideClick([commanderRef.current], () => {
    onClose();
  });

  useEffect(() => {
    if (!open) return;

    const handleGlobalKeyPress = (event: KeyboardEvent) => {
      if (event.key.length === 1 && !event.ctrlKey && !event.metaKey) {
        if (document.activeElement !== inputRef.current && open) {
          updateInputValueAndFocus(event.key);
        }
      }
    };

    window.addEventListener("keypress", handleGlobalKeyPress);

    return () => {
      window.removeEventListener("keypress", handleGlobalKeyPress);
    };
  }, [open, updateInputValueAndFocus]);

  return {
    open,
    commanderRef,
    focusId,
    filteredCommands,
    commandRefs,
    onMouseEnter,
    updateInputValueAndFocus,
    onMouseLeave,
    getCommandProps,
    inputProps,
    debouncedSearchValue,
    listProps
  };
};
