import { useRef, useState } from "react";
import { ErrorMessage, useResponsive, Layout } from "@introist/react-foundation/v2";
import { ButtonRow, ClickBox } from "modules/workflows/components/EmailAddressMultiSelect";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { ParticipantCard } from "modules/workflows/components/ParticipantCard";
import {
  PortalOptionListMultiSelect,
  useMultiSelectOptionList
} from "modules/workflows/components/PortalOptionList";
import { TOption } from "modules/workflows/components/PortalOptionList/types";
import { ImportantGuestButton } from "./components/ImportantGuestButton";
import { EventAttendeeStatus } from "services/api/types";
import { JourneyEventStepEditorProps } from "../../EventStepEditor";

type GuestsSelectProps = Pick<JourneyEventStepEditorProps, "isJourneyEditor"> & {
  initialOptions: TOption[];
  initialSelectedOptions?: TOption[];
  error?: string;
  width?: number;
  readOnly?: boolean;
  onChange: (selectedIds: string[]) => void;
  getAttendeeStatus?: (id: string) => EventAttendeeStatus | undefined;
  importantGuests: string[];
  onImportantGuestsChange: (selectedIds: string[]) => void;
};

export const GuestsSelect = ({
  initialOptions,
  initialSelectedOptions,
  importantGuests,
  error,
  width,
  readOnly,
  isJourneyEditor,
  getAttendeeStatus,
  onChange,
  onImportantGuestsChange,
  ...rest
}: GuestsSelectProps) => {
  const [optionListOpen, setOptionListOpen] = useState(false);

  const { isMobileLayout } = useResponsive();

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const { options, selectedOptions, handleAddOption, handleOptionClick, handleRemoveOption } =
    useMultiSelectOptionList({
      initialOptions,
      initialSelectedOptions,
      onChange: selectedIds => {
        onChange(selectedIds);
      },
      // this should be boolean 'enableAddOptions' etc, as onEnter doesn't do anything in the hook
      onEnter: () => {}
    });

  return (
    <Layout.Group {...rest} vertical gap="small" ref={anchorRef} style={{ flex: 1 }}>
      {selectedOptions.length > 0 &&
        selectedOptions.map(selectedOption => {
          const isImportantGuest = importantGuests.includes(selectedOption.key);

          return (
            <ParticipantCard
              attendeeStatus={getAttendeeStatus ? getAttendeeStatus(selectedOption.key) : undefined}
              readOnly={readOnly}
              key={selectedOption.key}
              isJourneyEditor={isJourneyEditor}
              isImportantGuest={isImportantGuest}
              onClick={() => setOptionListOpen(!optionListOpen)}
              title={selectedOption.title}
              description={selectedOption.description}
              endAdornment={
                readOnly ? null : (
                  <ImportantGuestButton
                    selected={isImportantGuest}
                    onClick={event => {
                      event.stopPropagation();
                      let nextImportantGuestIds: string[];

                      if (importantGuests.includes(selectedOption.key)) {
                        nextImportantGuestIds = importantGuests.filter(
                          c => c !== selectedOption.key
                        );
                      } else {
                        nextImportantGuestIds = importantGuests.concat(selectedOption.key);
                      }

                      onImportantGuestsChange(nextImportantGuestIds);
                    }}
                  />
                )
              }
              onRemove={() => {
                handleRemoveOption(selectedOption.key);
                optionListOpen && setOptionListOpen(false);
              }}
            />
          );
        })}
      {!readOnly && (
        <ButtonRow>
          <IconToggleButton
            startIcon="plus"
            active={!!optionListOpen}
            destructive={!!error}
            onClick={() => setOptionListOpen(!optionListOpen)}
          >
            Add
          </IconToggleButton>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ClickBox onClick={() => setOptionListOpen(false)} />
        </ButtonRow>
      )}
      <PortalOptionListMultiSelect
        open={optionListOpen}
        referenceElement={anchorRef.current}
        options={options}
        width={width}
        onOptionClick={handleOptionClick}
        selectedKeys={selectedOptions.map(({ key }) => key)}
        onEnter={handleAddOption}
        onClose={() => setOptionListOpen(false)}
        placement={isMobileLayout ? "bottom-start" : "left-start"}
        sameWidthWithReference={isMobileLayout}
      />
    </Layout.Group>
  );
};
