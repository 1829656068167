import { useMemo, useState } from "react";
import { Card, H4, SkeletonLoader, Stack } from "@introist/react-foundation/v2";
import { EmployeeField, GroupedEmployeeField, api } from "services/rpc/RpcProvider";
import {
  EmployeeFieldActions,
  EmployeeFieldList,
  EmployeeFieldTransformActions
} from "./components";
import {
  CreateCustomEmployeeFieldGroupModal,
  CreateCustomEmployeeFieldModal,
  UpdateEmployeeFieldModal,
  UpdateFieldTransformModal
} from "./containers";
import { useArchiveEmployeeField, useComputedField } from "./hooks";
import { AttributeIcon } from "components/atoms";
import { EmployeeFieldListHeader } from "./components/EmployeeFieldList/EmployeeFieldListHeader";
import { CategorisedOption } from "components/organisms";
import {
  EMPLOYEE_FIELDS_FILTER_CATEGORIES,
  filterEmployeeFields,
  useEmployeeFieldFilterOptions
} from "./components/EmployeeFieldList/filtering";
import { useAddUpdatedAtEmployeeField } from "./hooks/use-add-updated-at";

export type EmployeeFieldGroup = GroupedEmployeeField & { subFields: EmployeeField[] };

export const EmployeeFieldsSettingsRoute = () => {
  const hrisDetails = api.hris.details.useQuery({});
  const computedFields = api.employees.fields.computed.list.useQuery({});

  const [group, setGroup] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const [search, setSearch] = useState<string>();

  const [filters, setFilters] = useState<CategorisedOption[]>([]);

  const [fieldToEdit, setFieldToEdit] = useState<GroupedEmployeeField | null>(null);
  const [editFieldOpen, setEditFieldOpen] = useState(false);
  const [createFieldOpen, setCreateFieldOpen] = useState(false);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [updateTransformOpen, setUpdateTransformOpen] = useState(false);
  const [isNewTransform, setIsNewTransform] = useState(false);
  const [computedFieldToEdit, setComputedFieldToEdit] = useState<GroupedEmployeeField>();
  const { remove } = useComputedField();

  const fields = api.employees.fields.list.useQuery({
    group: true,
    includeHidden: false
  });

  const filterOptions = useEmployeeFieldFilterOptions(fields.data || []);

  const filteredFields = useMemo(() => {
    return filterEmployeeFields(fields.data || [], filters, search);
  }, [filters, fields.data, search]);

  const { onArchiveField } = useArchiveEmployeeField(fields.refetch);

  const { onAddUpdatedAt } = useAddUpdatedAtEmployeeField(() =>
    Promise.all([fields.refetch, computedFields.refetch])
  );

  const fieldActions: EmployeeFieldActions = {
    onAddTransform: (field: GroupedEmployeeField) => {
      setComputedFieldToEdit(field);
      setUpdateTransformOpen(true);
      setIsNewTransform(true);
    },
    onArchiveField: field => onArchiveField({ field, archive: true }),
    onEditField: field => {
      setFieldToEdit(field);
      setEditFieldOpen(true);
    },
    onAddSubField: group => {
      setCreateFieldOpen(true);
      setGroup(group);
    },
    onAddUpdatedAt: field => onAddUpdatedAt({ field })
  };

  const fieldTransformActions: EmployeeFieldTransformActions = {
    onEditTransform: (field: GroupedEmployeeField) => {
      isNewTransform && setIsNewTransform(false);
      setUpdateTransformOpen(true);
      setComputedFieldToEdit(field);
    },
    onDeleteTransform: (field: GroupedEmployeeField) =>
      remove({ fieldVariable: field.variable, onSuccess: computedFields.refetch })
  };

  const newFieldActions = {
    onCreateField: () => setCreateFieldOpen(true),
    onCreateGroup: () => setCreateGroupOpen(true)
  };

  const loading = hrisDetails.isLoading || fields.isLoading || computedFields.isLoading;

  if (loading) {
    return (
      <Stack vertical gap="large">
        <Card>
          <Stack vertical>
            <Stack vertical gap="small">
              <Stack gap="small">
                <SkeletonLoader element="h4" />
              </Stack>
            </Stack>
            <Stack justifyContent="space-between">
              <Stack gap="small">
                <SkeletonLoader element="buttonSmall" width={80} />
                <SkeletonLoader element="buttonSmall" width={80} />
              </Stack>
              <SkeletonLoader height={30} width={224} />
            </Stack>
            <SkeletonLoader height={22} width={162} />
            <Stack vertical gap="small">
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader fadeOut height={48} />
            </Stack>
          </Stack>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack vertical>
      <Card>
        <Stack vertical>
          <Stack vertical gap="small">
            <Stack gap="small">
              <AttributeIcon ghosted />
              <H4>Employee Fields</H4>
            </Stack>
          </Stack>
          <EmployeeFieldList
            hris={hrisDetails.data}
            computedFields={computedFields.data || []}
            employeeFields={filteredFields}
            emptyState={<div />}
            header={
              <EmployeeFieldListHeader
                {...newFieldActions}
                filter={{
                  filters,
                  onFilterChange: setFilters,
                  options: filterOptions,
                  categories: EMPLOYEE_FIELDS_FILTER_CATEGORIES
                }}
                search={{
                  search,
                  onSearchChange: setSearch
                }}
              />
            }
            {...fieldActions}
            {...fieldTransformActions}
          />
        </Stack>
      </Card>

      <>
        <UpdateFieldTransformModal
          open={updateTransformOpen}
          onClose={() => {
            setUpdateTransformOpen(false);
            setIsNewTransform(false);
            setComputedFieldToEdit(undefined);
          }}
          isNewTransform={isNewTransform}
          field={computedFieldToEdit}
          refetchComputedFields={computedFields.refetch}
        />
        <CreateCustomEmployeeFieldModal
          open={createFieldOpen}
          group={group}
          onClose={() => {
            setCreateFieldOpen(false);
            setTimeout(() => {
              setGroup(null);
            }, 1000);
          }}
          onCreated={() => {
            fields.refetch();
            setCreateFieldOpen(false);
            setTimeout(() => {
              setGroup(null);
            }, 1000);
          }}
        />

        <CreateCustomEmployeeFieldGroupModal
          open={createGroupOpen}
          onClose={() => setCreateGroupOpen(false)}
          onCreated={({ name, id }) => {
            fields.refetch();
            setCreateGroupOpen(false);
            setGroup({ name, id });
            setCreateFieldOpen(true);
          }}
        />

        <UpdateEmployeeFieldModal
          field={fieldToEdit}
          open={!!fieldToEdit && editFieldOpen}
          onClose={() => {
            setEditFieldOpen(false);
            setTimeout(() => {
              setFieldToEdit(null);
            }, 1000);
          }}
          onUpdated={() => {
            fields.refetch();
            setEditFieldOpen(false);
            setTimeout(() => {
              setFieldToEdit(null);
            }, 1000);
          }}
        />
      </>
    </Stack>
  );
};
