import { ReactNode } from "react";
import styled from "styled-components";
import { Icon, Layout, Title } from "@introist/react-foundation/v2";
import { CompanyEventInstanceAttendeeHistoryEntry, api } from "services/rpc/RpcProvider";
import { formatDate, formatDateTime } from "utils/DatesUtils";
import { Dot } from "components/atoms";

export type CompanyEventAttendeeHistoryProps = {
  attendeeId: string;
};

const StyledTitle = styled(Title)`
  b {
    color: var(--palette-foreground-default);
    font-weight: 500;
  }
`;

const DangerIcon = styled(Icon)`
  fill: var(--palette-danger-default);
`;

const Divider = styled(Layout.Divider)`
  width: 1px;
  height: 1rem;
  position: absolute;
  left: 7px;
  top: calc(100% + 4px);
`;

const HISTORY_ICON_MAP: Record<CompanyEventInstanceAttendeeHistoryEntry["action"], ReactNode> = {
  accepted: <Icon subdued name="calendarCheck" />,
  canceled: <Icon subdued name="calendarCross" />,
  completed: <Icon subdued name="calendarCheck" />,
  declined: <DangerIcon name="calendarCross" />,
  moved: <Icon subdued name="calendarForward" />,
  invited: <Icon subdued name="calendar" />
};

export const CompanyEventAttendeeHistory = ({
  attendeeId,
  ...rest
}: CompanyEventAttendeeHistoryProps) => {
  const { data: attendeeHistory } = api.companyEvents.attendees.history.useQuery({ attendeeId });

  const getTitle = (entry: CompanyEventInstanceAttendeeHistoryEntry) => {
    switch (entry.action) {
      case "accepted":
        return (
          <StyledTitle>
            <b>Accepted</b> invitation to{" "}
            <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );
      case "completed":
        return (
          <StyledTitle>
            <b>Attended</b> to session{" "}
            <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );
      case "canceled":
        return (
          <StyledTitle>
            <b>Removed</b> from session{" "}
            <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );
      case "declined":
        return (
          <StyledTitle>
            <b>Declined</b> invite to session{" "}
            <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );
      case "moved":
        return (
          <StyledTitle>
            <b>Moved</b> to session <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );
      case "invited":
        return (
          <StyledTitle>
            <b>Invited</b> to session{" "}
            <b>{formatDate(entry.eventInstanceStartTime, "MMM D, YYYY")}</b>
          </StyledTitle>
        );

      default:
        return <Title>Unknown action</Title>;
    }
  };

  return (
    <Layout.Group vertical gap="xLarge" {...rest}>
      {attendeeHistory?.map((entry, index) => (
        <Layout.Group
          key={`attendee-history-entry-${entry.timestamp}`}
          gap="xLarge"
          style={{ position: "relative" }}
        >
          {HISTORY_ICON_MAP[entry.action]}
          <Layout.Group gap="large">
            <Title>{getTitle(entry)}</Title>
            <Dot />
            <Title>{formatDateTime(entry.timestamp)}</Title>
          </Layout.Group>
          {attendeeHistory.length - 1 !== index && <Divider />}
        </Layout.Group>
      ))}
    </Layout.Group>
  );
};
