import React from "react";
import { TitleProps, Title, Tooltip } from "@introist/react-foundation/v2";

export interface TooltipTitleProps extends TitleProps {
  tooltip?: string;
}

export const TooltipTitle = ({ tooltip, ...rest }: TooltipTitleProps) => {
  const titleComp = <Title {...rest} />;

  if (tooltip) {
    return <Tooltip tooltip={tooltip}>{titleComp}</Tooltip>;
  } else return titleComp;
};
