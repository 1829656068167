import styled from "styled-components";
import { Select, Title, Layout, Icon } from "@introist/react-foundation/v2";

import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";
import { EmployeeField } from "services/rpc/RpcProvider";

const StyledCardPopover = styled(CardPopover)`
  max-width: 23.5rem;

  // Card
  > :first-child > div {
    padding: var(--spacing-xLarge);
  }
`;

type Props = {
  open: boolean;
  referenceElement: HTMLElement | null;
  setOpen: (open: boolean) => void;
  targetDate?: string;
  onSelect: (targetDate: string) => void;
  loading?: boolean;
  setImmediate?: (immediate: boolean) => void;
  employeeFields?: EmployeeField[];
};

export const TargetDateSelectPopover = ({
  open,
  referenceElement,
  setOpen,
  targetDate,
  onSelect,
  setImmediate,
  employeeFields
}: Props) => {
  const options =
    (employeeFields || [])
      .filter(attr => attr.type === "date")
      .map(attr => ({ key: attr.variable, title: attr.name })) || [];

  return (
    <StyledCardPopover
      widthPreset="ultraWide"
      open={open}
      referenceElement={referenceElement}
      onClose={() => setOpen(false)}
      placement="bottom-start"
    >
      <Layout.Group
        vertical
        gap="xLarge"
        ref={ref => {
          !!ref && setImmediate && setImmediate(false);
        }}
      >
        <Layout.Group vertical gap="xSmall">
          <Layout.Group gap="small">
            <Icon name="calendarTarget" />
            <Title variant="bold">Target date attribute</Title>
          </Layout.Group>
          <Title>
            Choose the Employee Field that will be used as the target date for this Workflow
          </Title>
        </Layout.Group>

        <Select
          size="small"
          options={options}
          value={targetDate}
          onSelect={option => {
            onSelect(option.key);
            setOpen(false);
          }}
        />
      </Layout.Group>
    </StyledCardPopover>
  );
};
