import React, { useState } from "react";

import {
  BaseModal,
  Button,
  Input,
  Layout,
  Select,
  Title,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { createMaterial, MaterialType } from "services/api/MaterialsApi";
import styled from "styled-components";

interface Props {
  show: boolean;
  onClose: (update?: boolean, materialId?: string) => unknown;
}

const MinWidthModal = styled(BaseModal)`
  min-width: 30rem;
`;

export const CreateModal = ({ show, onClose }: Props) => {
  const { onAction } = useActions();
  const toast = useToast();
  const [materialType, setType] = useState<MaterialType>("handbook");
  const [title, setTitle] = useState<string>();
  const [data, setData] = useState<string>();
  const [file, setFile] = useState<File>();

  const doCreate = onAction(async () => {
    if (!title || !materialType) throw new Error("Title and type are required");
    const materialId = await createMaterial({
      title,
      data,
      materialType
    });
    toast.success("Material created");
    setType("handbook");
    setTitle(undefined);
    setData(undefined);
    setFile(undefined);
    await onClose(true, materialType === "handbook" ? materialId : undefined);
  });

  const buttonDisabled =
    materialType === "link"
      ? !title || !data
      : materialType === "handbook"
      ? !title
      : !title || !file;

  return (
    <MinWidthModal open={show} onClose={onClose} title="Create new material">
      <Layout.Group vertical>
        <Layout.Group vertical>
          <Layout.Group vertical>
            <Title variant="bold">Material type</Title>
            <Select
              options={[{ key: "handbook", title: "Handbook", startAdornmentIcon: "book" }]}
              onSelect={option => setType(option.key as MaterialType)}
              value={materialType}
            />
          </Layout.Group>
          {materialType && (
            <Layout.Group vertical>
              <Title variant="bold">Add name</Title>
              <Input size="small" value={title} onChange={setTitle} />
            </Layout.Group>
          )}
          {materialType === "link" && (
            <Layout.Group vertical>
              <Title variant="bold">Add link URL</Title>
              <Input size="small" value={data} onChange={setData} />
            </Layout.Group>
          )}
          {materialType === "file" && (
            <Layout.Group vertical>
              <Title variant="bold">Add file</Title>
            </Layout.Group>
          )}
        </Layout.Group>
        <Layout.Group justifyContent="flex-end">
          {materialType && (
            <Button disabled={buttonDisabled} onClickWithLoading={doCreate}>
              Create new material
            </Button>
          )}
        </Layout.Group>
      </Layout.Group>
    </MinWidthModal>
  );
};
