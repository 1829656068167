import { CircularLoader, Layout } from "@introist/react-foundation/v2";
import { TimeRange } from "../../../../services/api/types";
import { IntroistDateTime } from "../../../../utils/dates";
import { AvailabilityCalendar } from "./AvailabilityCalendar";

import { api } from "services/rpc/RpcProvider";
import { CalendarAvailabilityEmptyState } from "./EmptyState";

export interface CalendarEventAvailabilityCalendarProps {
  calendarEventId: string;
  start: IntroistDateTime;
  end: IntroistDateTime;
  timezone: string;
  onSelectSlot: (slot: TimeRange) => unknown;
}

export const CalendarEventAvailabilityCalendar = ({
  calendarEventId,
  start,
  end,
  timezone,
  onSelectSlot
}: CalendarEventAvailabilityCalendarProps) => {
  const { data: calendarEvent, isLoading } = api.journeys.steps.find.useQuery({
    stepId: calendarEventId
  });

  const content = (calendarEvent as any)?.journeyData;

  if (isLoading) return <CircularLoader />;
  if (!content) return <CalendarAvailabilityEmptyState />;

  return (
    <Layout.Group vertical>
      <AvailabilityCalendar
        eventDetails={{
          start,
          end,
          timezone,
          targetEmails: content.attendees?.map((_: any) => _.email) || [],
          hostId: content.hostId
        }}
        onSelectSlot={onSelectSlot}
      />
    </Layout.Group>
  );
};
