import { H2, TTagColorVariant, useColorVariant } from "@introist/react-foundation/v2";
import { animated, useSpring } from "@react-spring/web";
import { ReactNode } from "react";
import styled, { keyframes } from "styled-components";

export type AnimatedBlobH2Props = {
  delay?: number;
  children: ReactNode;
  blobColor?: TTagColorVariant;
  backgroundColor?: string;
};

const StyledH2 = styled(H2)`
  line-height: 1.3;
  font-size: 36px;
  position: relative;

  > span {
    display: block;
    overflow: hidden;

    span {
      display: block;
    }
  }
`;

const blobKeyframes = keyframes`
    0% {
        border-radius: 28% 72% 59% 41% / 52% 65% 35% 48%;
        transform: translateX(50px) scale(0);
    }
    10% {
        border-radius: 72% 28% 49% 51% / 66% 48% 52% 34%;
    }
    20% {
        border-radius: 50% 50% 32% 68% / 29% 39% 61% 71%;
    }
    30% {
        border-radius: 71% 29% 55% 45% / 30% 65% 35% 70%;
    }
    40% {
        border-radius: 61% 39% 54% 46% / 27% 30% 70% 73%;
    }
    50% {
        border-radius: 41% 59% 68% 32% / 59% 31% 69% 41%;
        transform: translateX(0px) scale(1.5);
    }
    60% {
        border-radius: 29% 71% 31% 69% / 71% 53% 47% 29%;
    }
    70% {
        border-radius: 41% 59% 63% 37% / 72% 63% 37% 28%;
    }
    80% {
        border-radius: 53% 47% 39% 61% / 40% 32% 68% 60%;
    }
    90% {
        border-radius: 27% 73% 68% 32% / 41% 52% 48% 59%;
    }
    100% {
        border-radius: 64% 36% 35% 65% / 63% 71% 29% 37%;
        transform: translateX(-50px) scale(0);
    }
`;

const StyledAnimatedBlobH2 = styled.div<{ $backgroundColor?: string }>`
  position: relative;
  user-select: none;

  > ${StyledH2} {
    overflow: hidden;
    background: ${p => p.$backgroundColor || "white"};
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    .animated {
      width: 100%;
      position: relative;
    }
  }
`;

const Blob = styled.div`
  mix-blend-mode: lighten;
  transform: scale(0);
  animation: ${blobKeyframes} 10s ease-in-out infinite alternate;
`;

const Blob1 = styled(Blob)`
  position: absolute;
  top: 0px;
  background: var(--palette-primary-default);
  width: 120px;
  height: 60px;
  left: 10px;
`;

const Blob2 = styled(Blob)`
  position: absolute;
  top: 0px;
  background: var(--palette-primary-default);
  width: 120px;
  height: 60px;
  left: 80px;
  animation-delay: 1000ms;
  animation-direction: alternate-reverse;
`;

export const AnimatedBlobH2 = ({
  children,
  delay,
  blobColor = "primary",
  backgroundColor,
  ...rest
}: AnimatedBlobH2Props) => {
  const { foreground } = useColorVariant(blobColor);
  const spring = useSpring({
    from: {
      y: "100%"
    },
    to: {
      y: "0%"
    },
    delay
  });

  const rand = Math.random();

  return (
    <StyledAnimatedBlobH2 {...rest} $backgroundColor={backgroundColor}>
      <StyledH2>
        <span>
          <animated.span style={spring} className="animated">
            {children}
          </animated.span>
        </span>

        {rand > 0.5 ? (
          <Blob1 style={{ backgroundColor: foreground }} />
        ) : (
          <Blob2 style={{ backgroundColor: foreground }} />
        )}
      </StyledH2>
    </StyledAnimatedBlobH2>
  );
};
