import { useWorkflowStepOps, WorkflowStep } from "../../../../../flows/hooks/useWorkflowSteps";

export const useSaveWorkflowStep = () => {
  const { create, update } = useWorkflowStepOps();

  return (workflowId: string, stepData: WorkflowStep) => {
    const step = stepData as any;

    const commit = () => {
      if (stepData.id) return update({ id: stepData.id!, update: step });
      else return create({ workflowId, ...step });
    };

    return commit().catch(err => {
      if (err.data?.introistError) return Promise.reject(err.data?.introistError?.data);
      if (err.data?.validationErrors) return Promise.reject(err.data.validationErrors);
      return Promise.reject(err);
    });
  };
};
