import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
type DriveSheetConfig = {
  fileId: string;
  sheetName: string;
};
export const GoogleDriveSheetConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as DriveSheetConfig) || {
    fileId: "",
    sheetName: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.fileId}
        label="Google Drive File ID"
        onChange={fileId =>
          onChange({
            credentials: {
              ...credentials,
              fileId
            }
          })
        }
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.sheetName}
        label="Sheet name"
        onChange={sheetName =>
          onChange({
            credentials: {
              ...credentials,
              sheetName
            }
          })
        }
      />
    </Layout.Group>
  );
};
