import { Card, Stack } from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { api } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { fillInstructionsTemplate } from "./instructionTemplates";
import { ResultPreview } from "./ResultPreview/ResultPreview";
import { AIAssistantResult, ContentType } from "./schema";
import { InstructionInput } from "./InstructionInput/InstructionInput";

interface AIAssistantProps {
  operation: string;
  context?: object;
  contentType: ContentType;
  template: string;
  onSubmit: (submission: AIAssistantResult) => void;
  previewPlacement?: "top" | "bottom";
}

const StyledStack = styled(Stack)`
  position: relative;
  width: 100%;
`;

const PopoverCard = styled(Card)<{ placement: "top" | "bottom" }>`
  position: absolute;
  ${({ placement }) => (placement === "bottom" ? "top: 40px;" : "bottom: 40px;")}
  width: 100%;
`;

export const AIAssistant = ({
  operation,
  template,
  onSubmit,
  contentType,
  previewPlacement = "top",
  context
}: AIAssistantProps) => {
  const { mutateAsync: generate, isLoading } = api.ai.generate.useMutation({});
  const [instructions, setInstructions] = useState<string>("");
  const [showInput, setShowInput] = useState<boolean>(false);
  const [error, setError] = useState<{ message?: string; code: number } | undefined>(undefined);
  const [result, setResult] = useState<AIAssistantResult | undefined>();
  const [threadId, setThreadId] = useState<string | undefined>(undefined);

  const generateResponse = useCallback(async () => {
    const instructionsMessage = fillInstructionsTemplate(
      operation,
      (result as any)?.message ?? template,
      contentType,
      instructions
    );
    try {
      setError(undefined);
      const response = await generate({
        operation,
        instructions: instructionsMessage,
        threadId,
        context
      });
      const result = response.result as any as AIAssistantResult;
      if (!result) {
        throw new Error("Failed to generate. Try again.");
      }
      setResult(result);
      setThreadId(response.threadId);
    } catch (e: any) {
      setError({ message: e?.message, code: e?.data?.introistError?.code ?? e?.data?.httpStatus });
    }
  }, [template, instructions, operation, generate, contentType, result, threadId, context]);

  const clear = () => {
    setResult(undefined);
    setInstructions("");
    setShowInput(false);
  };

  return (
    <StyledStack vertical>
      {result && (
        <PopoverCard elevated placement={previewPlacement}>
          <ResultPreview
            contentType={contentType}
            preview={result}
            close={clear}
            confirm={(submission: AIAssistantResult) => {
              onSubmit(submission);
              clear();
            }}
            onChange={setResult}
            loading={isLoading}
          />
        </PopoverCard>
      )}
      <InstructionInput
        contentType={contentType}
        onSubmit={generateResponse}
        value={instructions}
        onChange={setInstructions}
        showInput={showInput}
        setShowInput={setShowInput}
        error={error}
        clearError={() => setError(undefined)}
        isLoading={isLoading}
        isElevated={!!result}
      />
    </StyledStack>
  );
};
