import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const DoceboConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as object) ?? {
    clientId: "",
    clientSecret: "",
    platformUrl: "",
    username: "",
    password: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={credentials.platformUrl}
        label="Docebo Platform Url"
        onChange={platformUrl => {
          onChange({ credentials: { ...credentials, platformUrl } });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.clientId}
        label="Client ID"
        onChange={clientId => {
          onChange({ credentials: { ...credentials, clientId } });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.clientSecret}
        label="Client Secret"
        onChange={clientSecret => {
          onChange({ credentials: { ...credentials, clientSecret } });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.username}
        label="Webhook username"
        onChange={username => {
          onChange({ credentials: { ...credentials, username } });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.password}
        label="Webhook password"
        onChange={password => {
          onChange({ credentials: { ...credentials, password } });
        }}
      />
    </Layout.Group>
  );
};
