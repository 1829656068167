import styled from "styled-components";
import { Accordion, Layout } from "@introist/react-foundation/v2";
import { api } from "services/rpc/RpcProvider";
import { useGroupedCompanyCompanyEventSettings, useCompanyEventSetting } from "./hooks";
import { PanelLayout } from "components/templates";
import { Fragment } from "react";

type Props = { companyEventId: string };

const StyledCompanyEventSettings = styled(Layout.Group)`
  > div:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const CompanyEventSettings = ({ companyEventId, ...rest }: Props) => {
  const { data: settings } = api.companyEvents.settings.list.useQuery({ companyEventId });

  const groupedSettings = useGroupedCompanyCompanyEventSettings(settings ?? []);
  const { getEventSetting } = useCompanyEventSetting();

  return (
    <StyledCompanyEventSettings {...rest} vertical gap="none">
      {Object.entries(groupedSettings).map(([category, settings], index, array) => (
        <Fragment key={`event-setting-category-${category}-${index}`}>
          <div>
            <Accordion blended toggleOpenOnHeaderClick title={category}>
              <Layout.Group vertical gap="large">
                {settings.map(getEventSetting)}
              </Layout.Group>
            </Accordion>
          </div>
          {index < array.length - 1 && <PanelLayout.PanelDivider />}{" "}
          {/* Divider between categories */}
        </Fragment>
      ))}
    </StyledCompanyEventSettings>
  );
};
