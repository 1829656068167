import { H4, Icon, IconName, Stack } from "@introist/react-foundation/v2";
import { ReactNode } from "react";

interface ConditionHeaderProps {
  topContent?: ReactNode;
  titleStartIcon?: IconName;
  title?: ReactNode;
  description?: ReactNode | string;
}

export const ConditionHeader = ({
  topContent,
  titleStartIcon,
  title,
  description
}: ConditionHeaderProps) => {
  const showHeader = !!topContent || !!titleStartIcon || !!title || !!description;
  if (!showHeader) return null;
  return (
    <Stack vertical>
      {topContent && topContent}

      {(titleStartIcon || title) && (
        <Stack gap="small">
          {titleStartIcon && <Icon name={titleStartIcon} />}
          {title && <H4>{title}</H4>}
        </Stack>
      )}
      {description && <>{typeof description === "string" ? description : description}</>}
    </Stack>
  );
};
