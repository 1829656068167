import {
  Select,
  DateInput,
  TextArea,
  Input,
  Icon,
  Stack,
  useTheme,
  Title,
  Tooltip
} from "@introist/react-foundation/v2";
import { FormField } from "services/api/PublicFormApi";

export const Field = ({
  field,
  value,
  onChange,
  error
}: {
  field: FormField;
  value?: string;
  onChange: (value?: string) => any;
  error?: string;
}) => {
  const { theme } = useTheme();
  const renderInput = () => {
    if (field.options) {
      return (
        <Select
          options={field.options.map(o => ({ key: o }))}
          size="small"
          style={{ width: "100%" }}
          value={value}
          onSelect={_ => onChange(_.key)}
          error={!!error}
        />
      );
    }

    if (field.type === "date") {
      return <DateInput onChange={onChange} size="small" value={value} error={!!error} />;
    }

    if (field.type === "longtext") {
      return <TextArea value={value} onChange={onChange} error={!!error} />;
    }

    if (field.type === "number") {
      return (
        <Input
          type="number"
          onChange={onChange}
          value={value}
          size="small"
          endAdornment={<Icon name="urgencyMid" />}
          error={!!error}
        />
      );
    }

    if (field.type === "email") {
      return (
        <Input
          type="email"
          onChange={onChange}
          value={value}
          size="small"
          endAdornment={<Icon name="atTheRate" />}
          error={!!error}
        />
      );
    }

    return (
      <Input
        size="small"
        onChange={onChange}
        value={value}
        endAdornment={<Icon name="description" />}
        error={!!error}
      />
    );
  };
  return (
    <Stack vertical gap="small">
      <Stack vertical gap="xSmall">
        <Stack gap="small">
          <Title variant="bold">{field.title}</Title>
          {field.required && (
            <Tooltip tooltip="This field is required">
              <Title>*</Title>
            </Tooltip>
          )}
          <Stack gap="small" style={{ marginLeft: "auto" }}>
            {error && <Icon name="warning" color={theme.palette.danger.default}></Icon>}
            <Title color={theme.palette.danger.default}>{error}</Title>
          </Stack>
        </Stack>

        {field.description && <Title>{field.description}</Title>}
      </Stack>

      {renderInput()}
    </Stack>
  );
};
