import { IconPickerIcon } from "../../../../components/organisms";
import { EditableHeading } from "../../../../components/molecules";
import { H3, Icon, IconButton, Stack } from "@introist/react-foundation/v2";
import { Nudge, useNudgeActions } from "../../useNudges";
import { useState } from "react";

export const NudgeHeading = ({ nudge }: { nudge: Nudge }) => {
  const { update } = useNudgeActions();

  const [showNameEdit, setShowNameEdit] = useState(false);
  const [editName, setEditName] = useState(false);

  return (
    <Stack onMouseEnter={() => setShowNameEdit(true)} onMouseLeave={() => setShowNameEdit(false)}>
      <IconPickerIcon icon="targetAfter" fallbackIcon="targetAfter" />
      <EditableHeading
        initialValue={nudge.name}
        editable={false}
        dimmed
        onSave={async name => {
          await update({ id: nudge.id, update: { name } });
          setEditName(false);
        }}
        onCancel={() => setEditName(false)}
        forceEditing={editName}
        inputStyles={{ width: "400px" }}
      >
        <H3>{nudge.name}</H3>
      </EditableHeading>
      {!editName && (
        <IconButton
          size="small"
          style={{ display: showNameEdit ? "block" : "none" }}
          icon={<Icon name="pencil" dimmed />}
          onClick={() => setEditName(true)}
        />
      )}
    </Stack>
  );
};
