import styled, { keyframes } from "styled-components";
import eventsIllustration from "assets/attend.svg";

const sparkle2Keyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(0px) rotateZ(0deg) scale(0);
  }
  60% {
    opacity: 1;
    transform: translateY(-4px) rotateZ(90deg) scale(1);
  }
  100% {
    opacity: 0;
    transform:  translateY(-8px) rotateZ(180deg) scale(0);
  }
`;

const StyledEventsIllustration = styled.div`
  position: relative;
  .sparkle {
    display: flex;
    position: absolute;
    top: 27px;
    left: 22px;
    z-index: 1;

    > svg {
      opacity: 0;
      transform-origin: center;
      animation: ${sparkle2Keyframes} 3s infinite;
      animation-timing-function: linear;
    }
  }

  .sparkle-2 {
    display: flex;
    position: absolute;
    top: 66px;
    left: 59px;
    z-index: 1;

    > svg {
      opacity: 0;
      transform-origin: center;
      animation: ${sparkle2Keyframes} 3s infinite;
      animation-timing-function: linear;
      animation-delay: 1s;
    }
  }s
`;

export const EventsIllustration = () => {
  return (
    <StyledEventsIllustration>
      <div className="sparkle">
        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.9"
            d="M2.55526 0.263766C2.74746 -0.0879221 3.25255 -0.0879221 3.44475 0.263766L4.18362 1.61474C4.23028 1.69983 4.30026 1.76976 4.38545 1.81632L5.73642 2.55519C6.08786 2.74754 6.08786 3.25269 5.73642 3.44504L4.38545 4.18386C4.30026 4.23042 4.23028 4.3004 4.18362 4.38549L3.44475 5.73641C3.25255 6.08815 2.74746 6.08815 2.55526 5.73641L1.81638 4.38549C1.76973 4.3004 1.69974 4.23042 1.61455 4.18391L0.263576 3.44504C-0.0878587 3.25269 -0.0878587 2.74754 0.263576 2.55519L1.61455 1.81632C1.69974 1.76976 1.76973 1.69983 1.81638 1.61474L2.55526 0.263766Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="sparkle-2">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.9"
            d="M7.27552 0.827743C8.03412 0.605543 8.73842 1.30994 8.51622 2.06854L7.84042 4.37584C7.78672 4.55934 7.78672 4.75444 7.84042 4.93794L8.51622 7.24524C8.73842 8.00384 8.03412 8.70824 7.27552 8.48604L4.96822 7.81024C4.78472 7.75644 4.58952 7.75644 4.40602 7.81024L2.09872 8.48604C1.34012 8.70824 0.635821 8.00384 0.858021 7.24524L1.53382 4.93794C1.58752 4.75444 1.58752 4.55934 1.53382 4.37584L0.858021 2.06854C0.635821 1.30994 1.34012 0.605543 2.09872 0.827743L4.40602 1.50354C4.58952 1.55734 4.78472 1.55734 4.96822 1.50354L7.27552 0.827743Z"
            fill="white"
          />
        </svg>
      </div>
      <img style={{ transform: "translateY(12px)" }} src={eventsIllustration} alt="Events" />
    </StyledEventsIllustration>
  );
};
