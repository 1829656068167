import { ComponentProps, ReactNode, useMemo, useState } from "react";
import { Input } from "@introist/react-foundation/v2";

import {
  relativeDateFieldSchema,
  RelativeDateFieldValue
} from "@introist/introist-commons/dist/object-filter";
import { parseRelativeDate } from "@introist/introist-commons/dist";
import { RelativeDateEditorPopover } from "components/molecules";

export interface RelativeDateEditorProps extends Omit<ComponentProps<"div">, "onChange"> {
  value?: RelativeDateFieldValue;
  children?: ReactNode;
  onChange: (value: RelativeDateFieldValue) => void;
  targetDateTerm?: string;
}

export const extractRelativeDateInfo = (value?: RelativeDateFieldValue) => {
  if (value) {
    if (relativeDateFieldSchema.safeParse(value).success) {
      return parseRelativeDate(value.reldate);
    }
  }

  return { side: "+", amount: 1, unit: "M" };
};

export const getStringifiedRelativeDateValue = (
  value?: RelativeDateFieldValue,
  targetDateTerm?: string
) => {
  const { amount, unit, side } = extractRelativeDateInfo(value);
  const resolvedTerm = targetDateTerm ?? "today";
  if (amount === 0) return resolvedTerm ?? "today";
  return `${amount} ${stringifyUnit(unit)} ${
    side === "+" ? `after ${resolvedTerm}` : `before ${resolvedTerm}`
  }`;
};

export const RelativeDateEditor = ({
  value,
  children,
  onChange,
  style,
  targetDateTerm,
  ...rest
}: RelativeDateEditorProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const stringifiedValue = useMemo(
    () => getStringifiedRelativeDateValue(value, targetDateTerm),
    [value, targetDateTerm]
  );

  return (
    <div ref={setRef} style={{ maxWidth: "100%", ...style }} {...rest}>
      <div onClick={() => setOpen(!open)}>
        {children || <Input size="small" value={stringifiedValue} />}
      </div>
      <RelativeDateEditorPopover
        open={open}
        setOpen={setOpen}
        onChange={onChange}
        value={value}
        popoverRef={ref}
      />
    </div>
  );
};

export const stringifyUnit = (unit: string) => {
  if (unit === "d") return "days";
  if (unit === "w") return "weeks";
  if (unit === "M") return "months";
  return "???";
};
