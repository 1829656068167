import { Title, Icon, useTheme, Layout, Tag, Stack, Tooltip } from "@introist/react-foundation/v2";
import { DateFormats, IntroistDateTime } from "../../../../utils/dates";

import { formatDate, formatDateTime } from "utils/DatesUtils";
import { useFeatureFlag } from "hooks";
import { JourneyStepV2, StepTypesV2 } from "services/rpc/RpcProvider";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ExistingRulesList } from "modules/workflows/blocks/StepSettingsBlock/settings/ConditionalStepSetting/components/ExistingRulesList";
import { useAttributes } from "hooks/use-attributes";
import { Block } from "components/atoms";
import { safeIntroistDateTimeToMoment } from "@introist/introist-commons/dist";
import { WorkflowStepTypes } from "../../../flows/stepTypes";
import { useMemo } from "react";

export interface JourneyStepTriggerBlockProps {
  start: IntroistDateTime;
  end?: IntroistDateTime;
  allDay?: boolean;
  conditionalStep?: {
    condition: JourneyStepV2["condition"];
    conditionMode: JourneyStepV2["conditionMode"];
  };
  journeyStepId: string;
  stepType: string;
  scheduledAt: string;
  deliveredAt?: string;
  status: string;
  cutoffDays: number;
}

export const JourneyStepTriggerBlock = ({
  start,
  end,
  allDay,
  conditionalStep,
  stepType,
  scheduledAt,
  deliveredAt,
  status,
  cutoffDays
}: JourneyStepTriggerBlockProps) => {
  const { theme } = useTheme();

  const { isEnabled: hasConditionalStepFlag } = useFeatureFlag("conditional-step");

  const attributes = useAttributes();

  const resolveNextActionBlock = () => {
    if (["canceled", "completed"].includes(status)) return null;

    if (status === "failing" && !deliveredAt) {
      return <Title>Next attempt {formatDateTime(scheduledAt, DateFormats.datetime.long)}</Title>;
    }

    if (stepType === StepTypesV2.Enum.event && !deliveredAt) {
      return (
        <Title>Invitations will be sent {formatDate(scheduledAt, DateFormats.datetime.long)}</Title>
      );
    }

    if (!deliveredAt && !safeIntroistDateTimeToMoment(start).isSame(scheduledAt, "minute")) {
      return <Title>Next attempt {formatDateTime(scheduledAt, DateFormats.datetime.long)}</Title>;
    }

    return null;
  };

  const resolveTitle = () => {
    const wfStepType = WorkflowStepTypes.find(type => type.stepType === stepType);

    const realStatus = status === "failing" ? (!!deliveredAt ? "delivered" : "scheduled") : status;

    if (
      wfStepType &&
      wfStepType.scheduleDescription &&
      wfStepType.scheduleDescription[realStatus]
    ) {
      return wfStepType.scheduleDescription[realStatus];
    }

    if ([StepTypesV2.Enum.eventattend].includes(stepType)) {
      return "Send Invite on";
    }

    return "Schedule";
  };

  const cutoffDate = useMemo(() => {
    return formatDate(
      safeIntroistDateTimeToMoment(start).add(cutoffDays, "days").toISOString(),
      DateFormats.date.long
    );
  }, [cutoffDays, start]);

  return (
    <Block blended compact>
      <StepEditorAccordion title={resolveTitle()}>
        <StepEditorAccordion.Row label="Date">
          <Stack justifyContent="space-between">
            <Stack>
              <Icon name="calendar" color={theme.palette.foreground.subdued} />
              <Title variant="bold">{formatDate(start.date, DateFormats.date.long)}</Title>
            </Stack>
            <Tooltip tooltip={`Step cut-off will cancel the step ${cutoffDate}`}>
              <Icon name="targetBefore" dimmed />
            </Tooltip>
          </Stack>
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Time">
          <Layout.Group>
            <Icon name="time" color={theme.palette.foreground.subdued} />
            <Title variant="bold">
              {allDay ? "all day" : `${start.time}`}
              {!allDay && end ? ` - ${end.time}` : ""}
            </Title>
            <Tag colorVariant="outlined">{start.timezone}</Tag>
          </Layout.Group>
        </StepEditorAccordion.Row>
        {resolveNextActionBlock() && (
          <StepEditorAccordion.Row label="">{resolveNextActionBlock()}</StepEditorAccordion.Row>
        )}
        {conditionalStep && conditionalStep.condition && hasConditionalStepFlag && (
          <Layout.Group vertical>
            <ExistingRulesList
              small
              readOnly
              expression={conditionalStep.condition}
              employeeAttributes={attributes ?? []}
              createTitle={isAnd => {
                const mode = conditionalStep.conditionMode ?? "cancelif";

                const prefix =
                  mode === "executeif" ? "Step is executed only if" : "Step is canceled if";
                const suffix = isAnd ? "all of the rules match" : "any of the rules match";

                return `${prefix} ${suffix}`;
              }}
            />
          </Layout.Group>
        )}
      </StepEditorAccordion>
    </Block>
  );
};
