import { ReactElement } from "react";

export const Loading = ({
  data,
  loading,
  empty,
  children
}: {
  data?: any | boolean;
  loading: ReactElement;
  empty?: ReactElement;
  children: ReactElement;
}) => {
  if (!data) return loading;
  if (data && Array.isArray(data) && data.length === 0) return empty ?? <div />;
  return children;
};
