import styled from "styled-components";
import { Layout } from "@introist/react-foundation/v2";

export const StatusButton = styled(Layout.Group).attrs({ as: "button" })<{
  $active?: boolean;
}>`
  background-color: transparent;
  border: none;
  border-radius: var(--rounding-medium);
  padding: 0.25rem var(--spacing-medium);
  transition: border-color 140ms ease;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: ${({ $active }) =>
    $active ? "var(--palette-surface-subdued)" : "transparent"};

  :hover {
    background-color: var(--palette-surface-subdued);
  }
`;
