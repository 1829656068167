import { Button, Card, IconButton, Stack, Tag, Tooltip } from "@introist/react-foundation/v2";
import { useState } from "react";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { WorkflowStep } from "../../hooks/useWorkflowSteps";
import { WorkflowStepCard } from "../../components/WorkflowStepCard";

type Props = {
  title: string;
  steps: WorkflowStep[];
  formattedTargetDate: string;
  onAddStep: VoidFunction;
  onStepClick: (stepId: string) => void;
};

const StyledWorkflowTimelineStepGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--rounding-medium);
  position: relative;

  > :first-child {
    margin-bottom: var(--spacing-large);
  }
`;

const StepGroupTag = styled(Tag)``;

const StepGroupTitle = styled(Stack)`
  margin-left: 1.625rem;

  > * {
    cursor: pointer !important;
  }

  :hover {
    > .icon-btn {
      background-color: var(--palette-surface-hovered);

      svg {
        opacity: 1;
      }
    }
  }
`;

export const WorkflowTimelineStepGroup = ({
  title,
  steps,
  formattedTargetDate,
  onStepClick,
  onAddStep
}: Props) => {
  const [measureRef, { height }] = useMeasure();
  const [collapsed, setCollapsed] = useState(false);
  const isLastStep = (stepIndex: number) => {
    return stepIndex === steps.length - 1;
  };

  const addStepButton = () => (
    <Tooltip tooltip={`Add ${title.toLowerCase()} step`} placement="left">
      <IconButton
        size="medium"
        icon="plus"
        variant="outlined"
        style={{ boxShadow: "none" }}
        onClick={onAddStep}
      />
    </Tooltip>
  );

  return (
    <StyledWorkflowTimelineStepGroup>
      <StepGroupTitle gap="small" onClick={() => setCollapsed(!collapsed)}>
        <StepGroupTag>{title}</StepGroupTag>
        <IconButton className="icon-btn" icon={collapsed ? "chevronUp" : "chevronDown"} />
      </StepGroupTitle>
      {collapsed && (
        <Tooltip tooltip="Show steps" placement="left">
          <IconButton
            icon="more"
            variant="outlined"
            size="large"
            onClick={() => setCollapsed(false)}
          />
        </Tooltip>
      )}
      <div
        style={{
          height: collapsed ? 0 : height,
          overflow: collapsed ? "hidden" : "visible",
          alignSelf: "stretch"
        }}
      >
        <Stack ref={measureRef} vertical alignSelf="stretch">
          {steps.length === 0 && (
            <StyledCard variant="blended">
              <Button variant="outlined" startIcon="plus" size="small" onClick={onAddStep}>
                Add
              </Button>
            </StyledCard>
          )}
          {steps.length > 0 &&
            steps.map((s, idx) => (
              <Stack key={`workflow-editor-timeline-item--${s.id}`} vertical alignItems="center">
                <WorkflowStepCard
                  step={s}
                  onClick={() => onStepClick(s.id!)}
                  targetDateTerm={formattedTargetDate}
                />
                {isLastStep(idx) && addStepButton()}
              </Stack>
            ))}
        </Stack>
      </div>
    </StyledWorkflowTimelineStepGroup>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 432px;
  height: 114px;
  background-color: var(--palette-surface-subdued);
`;
