import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const HiBobConnectForm = ({ values, onChange }: ConnectFormProps) => {
  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.serviceUserId}
        label="Service User ID"
        onChange={serviceUserId => {
          onChange({
            credentials: {
              // @ts-expect-error
              serviceUserToken: values?.credentials?.serviceUserToken,
              serviceUserId
            }
          });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.serviceUserToken}
        label="Service User Token"
        type="password"
        onChange={serviceUserToken =>
          onChange({
            credentials: {
              // @ts-expect-error
              serviceUserId: values?.credentials?.serviceUserId,
              serviceUserToken
            }
          })
        }
      />
    </Layout.Group>
  );
};
