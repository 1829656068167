import {
  Popover,
  Card,
  Stack,
  Icon,
  Button,
  Title,
  useTheme,
  Tooltip
} from "@introist/react-foundation/v2";
import { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";

interface ErrorCardProps {
  error: { message?: string; code?: number };
  onClick: () => Promise<void>;
  refElement: HTMLElement | null;
  clearError: () => void;
}

const StyledCard = styled(Card)`
  padding: var(--spacing-small) var(--spacing-medium);
`;

const ErrorMessage = styled(Title)`
  max-width: 480px;
`;

export const ErrorCard = ({ error, onClick, refElement, clearError }: ErrorCardProps) => {
  const { theme } = useTheme();

  const handleOnClick = useCallback(async () => {
    await onClick();
  }, [onClick]);

  useEffect(() => {
    const tm = setTimeout(() => {
      clearError();
    }, 10000);
    return () => clearTimeout(tm);
  }, [clearError, error]);

  const errorMessage = useMemo(() => {
    if (error.message?.startsWith("You exceeded your current quota")) {
      return "You exceeded your current quota. Please try again later.";
    }
    switch (error.code) {
      case 429:
        return "You have exceeded your current quota. Please try again later.";
      case 500:
        return "Something went wrong on our end. Please try again later.";
      default:
        return error.message;
    }
  }, [error]);

  return (
    <Popover placement="bottom" referenceElement={refElement} onClose={() => {}} open={!!error}>
      <StyledCard elevated>
        <Stack>
          <Icon name="warning" color={theme.palette.danger.default} />
          <Title variant="bold">Something went wrong!</Title>
          {errorMessage && (
            <Tooltip tooltip={<ErrorMessage>{errorMessage}</ErrorMessage>}>
              <Title>Read more.</Title>
            </Tooltip>
          )}
          <Button variant="blended" onClickWithLoading={handleOnClick} size="small">
            Try again
          </Button>
        </Stack>
      </StyledCard>
    </Popover>
  );
};
