import { Layout, Title, Tooltip } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

type WeekdayPickerProps = {
  onChange: (days: number[]) => void;
  selectedDays: number[];
  compact?: boolean;
  forceAtLeastOne?: boolean;
  disabled?: boolean;
};

export const WEEKDAY_MAP: Record<number, { long: string; short: string }> = {
  1: {
    long: "Monday",
    short: "Mon"
  },
  2: {
    long: "Tuesday",
    short: "Tue"
  },
  3: {
    long: "Wednesday",
    short: "Wed"
  },
  4: {
    long: "Thursday",
    short: "Thu"
  },
  5: {
    long: "Friday",
    short: "Fri"
  },
  6: {
    long: "Saturday",
    short: "Sat"
  },
  7: {
    long: "Sunday",
    short: "Sun"
  }
};

const WeekdayPickerButton = styled.button<{
  $active: boolean;
  $compact?: boolean;
  $disabled?: boolean;
}>`
  position: relative;
  padding: ${({ $compact }) =>
    $compact ? "var(--spacing-small)" : "var(--spacing-small) var(--spacing-xLarge)"};
  border-radius: var(--rounding-small);
  min-width: 3rem;
  background-color: var(--palette-backround-default);
  border: 1px solid var(--palette-border-default);
  cursor: pointer;

  ${({ $active }) =>
    $active &&
    css`
      background-color: var(--palette-primary-ghosted);
      border-color: var(--palette-primary-default);
    `}
  ${({ $disabled }) =>
    !$disabled &&
    css`
      :hover {
        border-color: var(--palette-primary-default);
      }
    `}

  > span {
    position: absolute;
    display: flex;
    left: -8px;
    top: -5px;
  }
`;

export const WeekdayPicker = ({
  selectedDays,
  compact,
  forceAtLeastOne,
  onChange,
  disabled
}: WeekdayPickerProps) => {
  const keys = Object.keys(WEEKDAY_MAP);

  const handleOnChange = (day: number) => {
    if (selectedDays.includes(day)) {
      const nextSelectedDays = selectedDays.filter(selectedDay => selectedDay !== day);
      if (forceAtLeastOne && nextSelectedDays.length === 0) {
        return; // Prevent unselecting the last one if forceAtLeastOne is true
      }
      onChange(nextSelectedDays);
      return;
    }
    const nextSelectedDays = selectedDays.concat(day).sort((a, b) => a - b);
    onChange(nextSelectedDays);
  };

  return (
    <Layout.Group gap="medium">
      {keys.map(key => {
        const active = selectedDays.includes(Number(key));

        if (forceAtLeastOne && selectedDays.length === 1 && active) {
          return (
            <Tooltip
              key={`weekday-picker-button-${key}`}
              tooltip="At least one weekday must be selected"
            >
              <WeekdayPickerButton
                $compact={compact}
                $active={active}
                disabled={disabled}
                $disabled={disabled}
              >
                <span>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1272_3634)">
                      <circle cx="8" cy="8" r="6" fill="white" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
                        fill="#105EFB"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1272_3634">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <Title>{WEEKDAY_MAP[Number(key)]}</Title>
              </WeekdayPickerButton>
            </Tooltip>
          );
        }

        return (
          <WeekdayPickerButton
            key={`weekday-picker-button-${key}`}
            $compact={compact}
            $active={active}
            onClick={() => handleOnChange(Number(key))}
            disabled={disabled}
            $disabled={disabled}
          >
            {active && (
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1272_3634)">
                    <circle cx="8" cy="8" r="6" fill="white" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
                      fill="#105EFB"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1272_3634">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            )}

            <Title>{WEEKDAY_MAP[Number(key)].short}</Title>
          </WeekdayPickerButton>
        );
      })}
    </Layout.Group>
  );
};
