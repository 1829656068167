import styled, { css } from "styled-components";

type Props = {
  dimmed?: boolean;
  subdued?: boolean;
};

const StyledDot = styled.span<{ $dimmed?: boolean; $subdued?: boolean }>`
  display: block;
  flex-shrink: 0;
  width: 3px;
  height: 3px;
  border-radius: 100%;

  ${({ $dimmed, $subdued }) => {
    if ($dimmed) {
      return css`
        background-color: var(--palette-foreground-dimmed);
      `;
    }

    if ($subdued) {
      return css`
        background-color: var(--palette-foreground-subdued);
      `;
    }

    return css`
      background-color: var(--palette-foreground-default);
    `;
  }}
`;

export const Dot = ({ dimmed, subdued = true, ...rest }: Props) => {
  return <StyledDot {...rest} $dimmed={dimmed} $subdued={subdued} />;
};
