import { Button, Field, Input, Modal, Stack, Title, useToast } from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";

type SheetModalProps = {
  open: boolean;
  onClose: () => void;
  value: string;
  onConnect: (sheet: { id: string; name: string }) => void;
};

export const SheetModal = ({ open, onClose, value, onConnect }: SheetModalProps) => {
  const toast = useToast();

  const [sheetId, setSheetId] = useState(value);

  const onConnectClick = useCallback(async () => {
    const name = "Sheet Name";
    toast.success(`Successfully connected to Sheet ${name}`);
    onConnect({ id: sheetId, name });
  }, [onConnect, sheetId, toast]);

  return (
    <Modal open={open} onClose={onClose} title="Connect Google Sheet" maxWidth={500}>
      <Stack vertical>
        <Title>
          Give Introist access to your sheet and copy the sheet ID to the field below. Finally click
          Connect to verify connection.
        </Title>
        <Field title="Sheet ID">
          <Input
            placeholder="Copy Sheet ID here"
            value={sheetId}
            onChange={val => setSheetId(val)}
            endAdornment={
              <Button variant="blended" onClickWithLoading={onConnectClick}>
                Connect
              </Button>
            }
          />
        </Field>
        <Stack justifyContent="flex-end">
          <Button variant="outlined" onClick={onClose}>
            Close
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
