import { CircularLoader, Modal, Stack, Title } from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";

export const DataSourceParamsModal = ({
  open,
  onClose,
  dataSourceId
}: {
  open: boolean;
  onClose: () => unknown;
  dataSourceId: string;
}) => {
  const { data: params } = api.employees.dataSources.params.useQuery({ id: dataSourceId });

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ width: "500px", minHeight: "50vh" }}
      title="Data source params"
    >
      <Stack vertical>
        {params === undefined && <CircularLoader fillParent />}
        {params === null && <Title>This data source has no parameters</Title>}
        {!!params && (
          <div style={{ display: "grid", gridTemplateColumns: "100px 1fr" }}>
            {Object.entries(params as object).map(([key, value]) => {
              return (
                <>
                  <Title>{key}</Title>
                  <Title variant="bold">{value}</Title>
                </>
              );
            })}
          </div>
        )}
      </Stack>
    </Modal>
  );
};
