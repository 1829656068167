import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const DocuSignConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as object) ?? {
    clientId: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />

      <TextInput
        // @ts-expect-error
        value={values?.credentials?.apiAccountId}
        label="API Account ID"
        onChange={apiAccountId => {
          onChange({ credentials: { ...credentials, apiAccountId } });
        }}
      />
      <TextInput
        // @ts-expect-error
        value={values?.credentials?.secretKey}
        label="Account Base URL"
        onChange={accountBaseUrl => {
          onChange({ credentials: { ...credentials, accountBaseUrl } });
        }}
      />
    </Layout.Group>
  );
};
