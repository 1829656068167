import { Tag, TTagColorVariant } from "@introist/react-foundation/v2";
import { capitalize } from "lodash";

type StepStatusBadgeProps = {
  status: string;
};
export const StepStatusBadge = ({ status }: StepStatusBadgeProps) => {
  let message = "";
  let colorVariant: TTagColorVariant | undefined = undefined;

  if (status === "scheduled") {
    colorVariant = "primary";
    message = "Scheduled";
  } else if (status === "completed") {
    colorVariant = "success";
    message = "Completed";
  } else if (status === "failing") {
    colorVariant = "danger";
    message = "Failing";
  } else if (status === "canceled") {
    colorVariant = "surface";
    message = "Canceled";
  } else if (status === "draft") {
    colorVariant = "outlined";
    message = "Draft";
  } else {
    colorVariant = "surface";
    message = capitalize(status);
  }

  return <Tag colorVariant={colorVariant}>{message}</Tag>;
};
