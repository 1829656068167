import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { CircularLoader, TopBar, TopBarProps } from "@introist/react-foundation/v2";

type TopBarRouteProps = Pick<
  TopBarProps,
  "navigationAction" | "onNavigationAction" | "title" | "paths" | "actions" | "sticky"
> & {
  headers?: ReactNode[];
  footer?: ReactNode;
  loading?: boolean;
  narrow?: boolean;
  medium?: boolean;
  children: ReactNode;
  withoutContentPadding?: boolean;
  background?: "white" | "surface";
};

const StyledTopBarRoute = styled.div<{
  $background?: "white" | "surface";
}>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  user-select: none;
  background-color: ${p => (p.$background === "white" ? "white" : "var(--palette-surface-dimmed)")};
`;

const Header = styled.header`
  border-bottom: 1px solid var(--palette-border-subdued);
`;

const StyledLoader = styled(CircularLoader)`
  flex: 1;
`;

const Content = styled.div<{
  $withoutContentPadding?: boolean;
  $narrow?: boolean;
  $medium?: boolean;
}>`
  padding: var(--spacing-xLarge) var(--spacing-xxLarge);
  flex: 1;
  display: flex;
  flex-direction: column;

  ${({ $narrow }) =>
    $narrow &&
    css`
      width: 100%;
      max-width: 60rem;
      margin: 0 auto;
      box-sizing: border-box;
    `}

  ${({ $medium }) =>
    $medium &&
    css`
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      box-sizing: border-box;
    `}

  ${({ $withoutContentPadding }) =>
    $withoutContentPadding &&
    css`
      padding: 0;
    `}
`;

const StyledTobBar = styled(TopBar)`
  border-bottom: 0;
`;

export const TopBarRoute = ({
  children,
  headers,
  loading,
  narrow,
  medium,
  background = "surface",
  withoutContentPadding = false,
  footer,
  ...props
}: TopBarRouteProps) => {
  return (
    <StyledTopBarRoute $background={background}>
      <StyledTobBar {...props} />
      <Header>{headers}</Header>
      <Content $withoutContentPadding={withoutContentPadding} $narrow={narrow} $medium={medium}>
        {loading ? <StyledLoader fillParent /> : children}
      </Content>
      {footer}
    </StyledTopBarRoute>
  );
};
