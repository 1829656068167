import React, { useEffect, useState } from "react";
import { Modal, Field, Input, useToast } from "@introist/react-foundation/v2";

import styles from "./GoogleCustomerIdModal.module.scss";
import { api } from "../../../../services/rpc/RpcProvider";
import { Link } from "components/atoms";

export type GoogleCustomerIdModalProps = {
  open: boolean;
  onUpdated: () => void;
  onClose: () => void;
};

export const GoogleCustomerIdModal = ({ open, onClose, onUpdated }: GoogleCustomerIdModalProps) => {
  const client = api.useContext();
  const toast = useToast();

  const [customerId, setCustomerId] = useState<string>("");

  useEffect(() => {
    client.workspaces.find
      .fetch({})
      .then(workspace => setCustomerId(workspace.googleWorkspaceCustomerId ?? ""));
  }, [client.workspaces.find]);

  const update = api.workspaces.update.useMutation({
    onSuccess: onUpdated,
    onError: () => {
      toast.error("Failed to update organisation");
    }
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Set up Rooms"
      description="Set up using Rooms by saving your Google Workspace customer ID. This is required to load
        your organisations room listing."
      primaryButton={{
        title: "Save",
        onClick: () => update.mutate({ googleWorkspaceCustomerId: customerId }),
        disabled: !customerId,
        loading: update.isLoading
      }}
      secondaryButton={{
        title: "Cancel",
        onClick: onClose
      }}
      className={styles.Root}
    >
      <div className={styles.Content}>
        <Link href="https://support.google.com/a/answer/10070793?product_name=UnuFlow&hl=en&visit_id=638016058276765362-3187537349&rd=1&src=supportwidget0&hl=en">
          How do I find my Google Workspace customer ID?
        </Link>
        <Field title="Google Workspace customer ID">
          <Input value={customerId} onChange={setCustomerId} placeholder="Copy your ID here" />
        </Field>
      </div>
    </Modal>
  );
};
