import { api } from "../../../services/rpc/RpcProvider";
import { useEmployeeFields } from "./useEmployeeFields";
import { useMemo } from "react";

export const useDataSourceMapping = (dataSourceId: string) => {
  const fields = useEmployeeFields();

  const { data: mapping } = api.employees.dataSources.mappings.list.useQuery({
    dataSourceId
  });

  const introistFields = useMemo(() => {
    if (!fields || !mapping) return undefined;
    return fields.filter(field => {
      return mapping.some(mapping => mapping.targetField === field.variable);
    });
  }, [fields, mapping]);

  return { mapping, introistFields };
};
