import { Stack } from "@introist/react-foundation/v2";
import { AIAssistant } from "modules/AIAssistant/AIAssistant";
import { ContentType } from "modules/AIAssistant/schema";
import { useWorkflowSteps } from "modules/flows/hooks/useWorkflowSteps";
import { useCallback } from "react";
import { WorkflowStepAction, api } from "services/rpc/RpcProvider";
import styled from "styled-components";

const FloatingStack = styled(Stack)`
  position: absolute;
  left: var(--spacing-medium);
  top: var(--spacing-medium);
  background: var(--palette-surface-subdued);
  z-index: 1;
  border: 1px solid var(--palette-border-default);
  border-radius: var(--rounding-medium);
  box-shadow: var(--shadow-default);
`;

interface WorkflowStepAssistantProps {
  workflowId: string;
}

export const WorkflowStepAssistant = ({ workflowId }: WorkflowStepAssistantProps) => {
  const { refetch } = useWorkflowSteps({ workflowId });
  const { mutateAsync: runAction } = api.workflows.v4.steps.action.useMutation();

  const onSubmit = useCallback(
    async (submission: WorkflowStepAction) => {
      await runAction({ action: submission, workflowId: workflowId });
      await refetch();
    },
    [refetch, runAction, workflowId]
  );
  return (
    <FloatingStack>
      <AIAssistant
        operation="stepbuilder"
        context={{ workflowId }}
        template=""
        contentType={ContentType.WorkflowSteps}
        onSubmit={submission => onSubmit(submission as WorkflowStepAction)}
        previewPlacement="bottom"
      />
    </FloatingStack>
  );
};
