import { Card, Icon, Select, Stack, Title } from "@introist/react-foundation/v2";
import { DragHandle, OrderableList } from "../../../foundation";
import { useEmployeeFields } from "../../datasources/hooks/useEmployeeFields";

type TimezoneOrderProps = {
  value: string[];
  onChange: (value: string[]) => void;
};

const TimezoneResolveOptions = [
  {
    id: "employee",
    title: "Employee",
    description: "Use employee's timezone"
  },
  {
    id: "workflow",
    title: "Workflow",
    description: "Use workflow's timezone"
  },
  {
    id: "workspace",
    title: "Workspace",
    description: "Use workspace default timezone"
  }
];

export const TimezoneOrder = ({ value, onChange }: TimezoneOrderProps) => {
  const fields = useEmployeeFields(["text"]);

  return (
    <Stack vertical>
      <OrderableList
        onReorder={items => onChange(items.map(item => item.id))}
        renderRow={item => {
          const resolveOption = () => {
            if (!item.id.startsWith("custom:")) {
              return TimezoneResolveOptions.find(opt => opt.id === item.id);
            }
            const field = fields?.find(f => f.variable === item.id.replace("custom:", ""));
            if (field) {
              return { id: item.id, title: field.name, description: "Custom employee field" };
            }
          };

          const option = resolveOption();

          return (
            <Card>
              <Stack>
                <DragHandle />
                <Title variant="bold">{option?.title ?? item.id}</Title>
                <Title>{option?.description}</Title>
              </Stack>
            </Card>
          );
        }}
        data={value.map(order => ({ id: order }))}
      />
      <Select
        searchable
        element="button"
        variant="blended"
        size="small"
        placeholder="Add custom field"
        startAdornment={<Icon name="plus" />}
        options={(fields ?? []).map(f => ({ key: f.variable, title: f.name }))}
        onSelect={field => onChange([...value, `custom:${field.key}`])}
      />
    </Stack>
  );
};
