import { ContentType } from "./schema";

export const basicTemplate = `
Current content:
{{template}}
Instructions:
{{instructions}}
Content type:
{{contenttype}}
`;

export const htmlMessageTemplate = `
${basicTemplate}

Generate and return a new piece of content based on the current content and these instructions. Content is HTML.
`;

export const stepBuilderTemplate = `
Draft and return a new step based on the current step and these instructions:
{{instructions}}
`;

const getInstructionsTemplate = (operation: string, contentType: ContentType) => {
  if (operation === "message") {
    if (contentType === ContentType.EmailMessage) return htmlMessageTemplate;
  }
  if (operation === "stepbuilder") return stepBuilderTemplate;
  return basicTemplate;
};

export const fillInstructionsTemplate = (
  operation: string,
  template: string,
  contentType: ContentType,
  instructions: string
) => {
  const instructionsTemplate = getInstructionsTemplate(operation, contentType);
  return instructionsTemplate
    .replace("{{template}}", template)
    .replace("{{contenttype}}", contentType)
    .replace("{{instructions}}", instructions);
};
