import { TitleProps } from "@introist/react-foundation/v2";
import { TooltipTitle } from "../TooltipTitle";

export interface FittedTitleProps extends Omit<TitleProps, "children"> {
  maxLength: number;
  children: string;
  ellipses?: boolean;
  showTooltip?: boolean;
}

export const FittedTitle = ({
  maxLength,
  children,
  ellipses = true,
  showTooltip = false,
  ...rest
}: FittedTitleProps) => {
  const { fittedTitle, hasBeenFitted } = fitTitle(children, maxLength, ellipses);

  return (
    <TooltipTitle tooltip={hasBeenFitted && showTooltip ? children : undefined} {...rest}>
      {fittedTitle}
    </TooltipTitle>
  );
};

export const fitTitle = (title: string, maxLength: number, useEllipses = false) => {
  if (!title) return { fittedTitle: "", hasBeenFitted: false };

  const hasBeenFitted = title.length > maxLength;
  const addEllipses = hasBeenFitted && useEllipses;

  const fittedTitle = `${title.substr(0, maxLength)}${addEllipses ? "..." : ""}`;

  return { fittedTitle, hasBeenFitted };
};
