import { OnboardingPackageRoute } from "./OnboardingPackageRoute";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/p/onboarding/dashboard", { replace: true });
  }, [navigate]);
  return null;
};

export const ONBOARDING_PACKAGE_ROUTES = {
  onboardingRoot: {
    key: "onboardingPackageRoot",
    path: "/p/onboarding",
    title: "Onboarding",
    element: <Redirect />
  },
  onboardingPackage: {
    key: "onboardingPackageSection",
    path: "/p/onboarding/:section",
    title: "Onboarding",
    element: <OnboardingPackageRoute />
  }
};
