import { UpsertFormProps } from "../../../foundation";
import { Field, Input, Stack, TextArea } from "@introist/react-foundation/v2";
import { Trigger } from "../hooks/useTriggers";

export const TriggerForm = ({ value, onChange }: UpsertFormProps<Trigger>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
      <Field title="Description">
        <TextArea
          value={value.description ?? ""}
          onChange={description => onChange({ ...value, description })}
        />
      </Field>
    </Stack>
  );
};
