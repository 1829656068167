import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { animated, config, useSpring } from "@react-spring/web";
import introistLogo from "assets/introist-default-logo.svg";

type Props = {
  children: ReactNode;
  hideLogo?: boolean;
  progressBar?: ReactNode;
  overflow?: "auto" | "hidden";
};

const StyledFullscreenView = styled.div<{
  $overflow: "auto" | "hidden";
}>`
  height: 100vh;
  position: relative;
  overflow-y: ${p => p.$overflow};
  overflow-x: hidden;
`;

const Content = styled.section<{
  $reserveProgressBarGap?: boolean;
  $overflow?: "auto" | "hidden";
}>`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 4rem 6rem;
  margin: 0 auto;
  box-sizing: border-box;
  height: ${p => (p.$reserveProgressBarGap ? "calc(100vh - 6px)" : "100vh")};
  position: relative;

  ${({ $overflow, $reserveProgressBarGap }) =>
    $overflow === "auto" &&
    css`
      height: auto;
      min-height: ${$reserveProgressBarGap ? "calc(100vh - 6px)" : "100vh"};
    `}
`;

const Fixed = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: var(--elevation-pluto);
  background-color: var(--palette-background-default);
`;

export const FullsreenView = ({ progressBar, hideLogo, overflow = "hidden", children }: Props) => {
  const appearSpring = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    config: { ...config.stiff, clamp: true }
  });

  return (
    <StyledFullscreenView $overflow={overflow}>
      <Fixed>{progressBar}</Fixed>

      <Content $reserveProgressBarGap={!!progressBar} $overflow={overflow}>
        {!hideLogo && (
          <animated.img
            style={{ ...appearSpring, width: 128 }}
            src={introistLogo}
            alt="Introist Logo"
          />
        )}
        {children}
      </Content>
    </StyledFullscreenView>
  );
};
