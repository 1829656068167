import { CircularLoader } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { TextInput } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { api } from "services/rpc/RpcProvider";

type Props = {
  formId?: string;
};

export const EmailFormResponsesBlock: React.FC<Props> = ({ formId }) => {
  const { data: form, isLoading: isLoadingForm } = api.forms.find.useQuery(
    { formId: formId! },
    {
      enabled: !!formId
    }
  );
  return (
    <Block blended compact>
      <StepEditorAccordion title="Form responses">
        {!isLoadingForm &&
          form &&
          form.fields.map((field: any) => {
            const value = form.submission?.responses[field.key] ?? "";

            return (
              <>
                <StepEditorAccordion.Row label={field.title}>
                  <TextInput value={value} placeholder="No response" readOnly readonly />
                </StepEditorAccordion.Row>
              </>
            );
          })}
        {isLoadingForm && <CircularLoader fillParent />}
      </StepEditorAccordion>
    </Block>
  );
};
