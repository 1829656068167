import "firebase/auth";

import firebase from "firebase/app";
import { config, firebaseConfig } from "../../Config";

const EMAIL_SIGNIN_KEY = "fir.efsi";

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();

export const signOut = () => {
  auth.signOut();
};

export const loginWithProvider = (providerName: "google" | "microsoft") => {
  const provider = {
    google: new firebase.auth.GoogleAuthProvider(),
    microsoft: new firebase.auth.OAuthProvider("microsoft.com")
  }[providerName];

  return auth.signInWithPopup(provider).then(res => res.user);
};

export const logout = () => {
  return auth.signOut();
};

export const waitForInitialIDToken = (): Promise<string | undefined> => {
  return new Promise(resolve => {
    const unsubscribe = app.auth().onAuthStateChanged(() => {
      resolve(getIDToken());
      unsubscribe();
    });
  });
};

export const getIDToken = (): Promise<string | undefined> => {
  if (!app.auth().currentUser) return Promise.resolve(undefined);
  return app.auth().currentUser!.getIdToken();
};

export const getUser = () => app.auth().currentUser;

export const verifyFirebaseLinkLogin = (): Promise<unknown> =>
  new Promise(async (resolve, reject) => {
    if (!auth.isSignInWithEmailLink(window.location.href)) reject("no-signin-started");

    const email = localStorage.getItem(EMAIL_SIGNIN_KEY);
    if (!email) reject("no-email-stored");

    try {
      await auth.signInWithEmailLink(email!, window.location.href);
      localStorage.removeItem(EMAIL_SIGNIN_KEY);
      resolve(undefined);
    } catch (e) {
      const errorMessage = e.code === "auth/invalid-action-code" ? "invalid-link" : "login-failed";
      reject(errorMessage);
    }
  });

export const loginWithEmail = async (email: string) => {
  const actionCodeSettings = {
    url: config.authLinkUrl,
    handleCodeInApp: true
  };
  return auth.sendSignInLinkToEmail(email, actionCodeSettings).then(function () {
    localStorage.setItem(EMAIL_SIGNIN_KEY, email);
  });
};

export const loginWithSsoToken = (token: string) => {
  return auth.signInWithCustomToken(token);
};
