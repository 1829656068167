import { useJourneyStepApi } from "services/api/journeys/JourneyStepApi";
import { JourneyStepV2, StepTypeV2, api } from "services/rpc/RpcProvider";

export const useUpdateStep = (
  journeyId: string,
  stepId: string,
  stepType: StepTypeV2,
  onSuccess: (updated: JourneyStepV2) => void
) => {
  const apiUtils = api.useContext();

  const journeyStepApi = useJourneyStepApi();
  const updateStepV1 = journeyStepApi.update({ onSuccess });

  const updateStepV2 = api.journeys.steps.update.useMutation({
    onSuccess: updated => onSuccess(updated as JourneyStepV2)
  });

  const updateStep = async (update: any) => {
    const doUpdate = async () => {
      if (
        [
          "slackchannelinvite",
          "freshserviceonboarding",
          "eventattend",
          "workflowtrigger",
          "slackquestion",
          "createaccount",
          "trelloboard"
        ].includes(stepType)
      ) {
        await updateStepV2.mutateAsync({ stepId, update });
      } else {
        await updateStepV1.mutateAsync({ journeyId, stepId, content: update });
      }
    };

    return doUpdate().then(async () => {
      await apiUtils.journeys.steps.find.refetch({ stepId });
      await apiUtils.journeys.steps.list.refetch({ journeyId });
    });
  };

  return updateStep;
};
