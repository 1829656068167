import {
  api,
  apiClient,
  DataSourceTestResult,
  SyncTestEmployeeData
} from "../../../../services/rpc/RpcProvider";
import {
  Button,
  Icon,
  InfoCard,
  Layout,
  Select,
  Stack,
  Title,
  Tooltip,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useEmployeeFields } from "../../hooks/useEmployeeFields";
import { CappedTitle } from "components/molecules";
import { Employee } from "../../../employees/hooks/useEmployees";

const DataSourceFieldRow = styled.div`
  padding: var(--spacing-xSmall) 0;
`;

const useEmployees = () => {
  const { data: employees } = api.employees.v4.list.useQuery({});

  const findEmployee = useCallback(async (id: string) => {
    return apiClient.employees.v4.find.query({ id });
  }, []);

  return {
    employees,
    findEmployee
  };
};

export const DataSourceFinalData = ({
  result,
  selectedEmployeeSourceId,
  onSelectEmployee
}: {
  result: DataSourceTestResult;
  selectedEmployeeSourceId?: string;
  onSelectEmployee: (sourceIdentifier: string) => void;
}) => {
  const { theme } = useTheme();
  const toast = useToast();
  const fields = useEmployeeFields();

  const { employees, findEmployee } = useEmployees();

  const createEmployee = api.employees.v4.create.useMutation();
  const updateEmployee = api.employees.v4.update.useMutation();

  const [selectedEmployeeData, setSelectedEmployeeData] = useState<
    SyncTestEmployeeData | undefined
  >();

  const [selectedEmployeeIntroistData, setSelectedEmployeeIntroistData] = useState<
    Employee | undefined
  >();

  const [error, setError] = useState(false);

  const { mutateAsync: loadEmployeeData } = api.employees.dataSources.testEmployee.useMutation();

  useEffect(() => {
    if (!selectedEmployeeSourceId) return;
    loadEmployeeData({
      testReportId: result.id,
      employeeSourceIdentifier: selectedEmployeeSourceId
    })
      .then(employee => {
        setSelectedEmployeeIntroistData(undefined);
        setSelectedEmployeeData(employee);
        if (employee && employee.introistEmployeeId) {
          findEmployee(employee.introistEmployeeId)
            .then(employee => {
              setSelectedEmployeeIntroistData(employee);
            })
            .catch(() => setSelectedEmployeeIntroistData(undefined));
        }
      })
      .catch(() => setError(true));
  }, [selectedEmployeeSourceId, result, loadEmployeeData, findEmployee]);

  const employeeOptions = useMemo(() => {
    return result.matching
      .filter(m => !!m.identifierValue)
      .map(m => {
        const employee = employees?.find(e => e.id === m.introistEmployeeId);
        return {
          key: m.identifierValue!,
          title: `${m.identifierValue} - ${employee ? employee.name : ""}`
        };
      })
      .sort();
  }, [result, employees]);

  const onImport = useCallback(async () => {
    if (!selectedEmployeeData) return;
    await createEmployee
      .mutateAsync(selectedEmployeeData.finalData!)
      .then(() => {
        toast.success("Employee imported successfully");
      })
      .catch(() => {
        toast.error("Failed to import employee");
      });
  }, [createEmployee, selectedEmployeeData, toast]);

  const onUpdate = useCallback(async () => {
    if (!selectedEmployeeData) return;
    await updateEmployee
      .mutateAsync({
        id: selectedEmployeeData.introistEmployeeId!,
        updates: selectedEmployeeData.finalData!
      })
      .then(async () => {
        await findEmployee(selectedEmployeeData.introistEmployeeId!).then(employee => {
          setSelectedEmployeeIntroistData(employee);
        });
        toast.success("Employee updated successfully");
      })
      .catch(() => {
        toast.error("Failed to update employee");
      });
  }, [selectedEmployeeData, toast, updateEmployee, findEmployee]);

  const renderContent = () => {
    if (!selectedEmployeeData) {
      return <Title>Select an employee to view data</Title>;
    }

    return (
      <>
        {selectedEmployeeData.introistEmployeeId && (
          <InfoCard
            colorVariant="success"
            title="This employee exists in Introist"
            icon="checkCircle"
          />
        )}
        {!selectedEmployeeData.introistEmployeeId && (
          <InfoCard
            colorVariant="warning"
            title="This employee does not exist in Introist"
            icon="warning"
          />
        )}
        <Layout.Group vertical gap="small">
          {Object.keys(selectedEmployeeData.finalData!).map(attribute => {
            const title = (fields ?? []).find(a => a.variable === attribute)?.name ?? attribute;
            const value = (selectedEmployeeData.finalData![attribute] as any) ?? "-";

            const resolveOldValue = () => {
              if (!selectedEmployeeIntroistData) return undefined;
              const employeeValue = selectedEmployeeIntroistData?.[attribute] ?? "-";
              if (employeeValue === value) return undefined;
              return employeeValue;
            };

            const oldValue = resolveOldValue();

            return (
              <DataSourceFieldRow key={attribute}>
                <Stack vertical gap="xSmall">
                  <Title>{title}</Title>
                  <Stack>
                    <CappedTitle
                      variant="bold"
                      color={oldValue ? theme.palette.primary.default : undefined}
                      showTooltip
                    >
                      {value.toString()}
                    </CappedTitle>
                    {oldValue && (
                      <div style={{ marginLeft: "auto" }}>
                        <Tooltip tooltip={oldValue}>
                          <Icon name="attribute" color={theme.palette.border.default} />
                        </Tooltip>
                      </div>
                    )}
                  </Stack>
                </Stack>
              </DataSourceFieldRow>
            );
          })}
        </Layout.Group>
        {selectedEmployeeData.introistEmployeeId && (
          <Button variant="blended" onClickWithLoading={onUpdate}>
            Update in Introist
          </Button>
        )}
        {!selectedEmployeeData.introistEmployeeId && (
          <Button variant="blended" onClickWithLoading={onImport}>
            Import to Introist
          </Button>
        )}
      </>
    );
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <Select
        attachToRef={false}
        searchable
        size="small"
        value={selectedEmployeeSourceId}
        options={employeeOptions}
        onSelect={opt => {
          onSelectEmployee(opt.key);
        }}
        placeholder="Search employee"
        style={{ width: "100%" }}
        startAdornment={<Icon name="search" />}
      />
      {error && (
        <InfoCard colorVariant="danger" title="Failed to load employee data" icon="crossCircle" />
      )}
      {!error && selectedEmployeeSourceId && renderContent()}
    </Layout.Group>
  );
};
