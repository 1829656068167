import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { InputProps, Input, InputHandle, ErrorMessage, Title } from "@introist/react-foundation/v2";
import { mergeRefs } from "react-merge-refs";

export type TextInputProps = InputProps & {
  label?: ReactNode;
  fullWidth?: boolean;
  inputHandleRef?: RefObject<InputHandle>;
  autoFocusDelayMs?: number;
};

const StyledTextInput = styled.div<{
  $hasError: boolean;
  $readOnly?: boolean;
  $fullWidth?: boolean;
  $focus?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xSmall);

  // Input
  > .text-input-wrapper > div {
    border-color: transparent;
    background: var(--palette-surface-subdued);
    border-radius: var(--rounding-medium);
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      && {
        width: 100%;
      }
    `}

  ${({ $focus }) =>
    $focus &&
    css`
      > .text-input-wrapper > div {
        border-color: var(--palette-primary-default);
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      :hover {
        > .text-input-wrapper > div {
          border-color: var(--palette-danger-default) !important;
        }
      }
      > .text-input-wrapper > div {
        background: var(--palette-danger-ghosted);
        border-color: var(--palette-danger-default);

        > input::placeholder {
          color: var(--palette-danger-subdued);
        }
      }
    `}

  ${({ $readOnly }) =>
    $readOnly &&
    css`
      && {
        input {
          cursor: default;
          color: var(--palette-foreground-subdued);
        }
        > .text-input-wrapper > div {
          border-color: transparent !important;
        }
      }
    `}
`;

export const TextInput = ({
  label,
  error,
  inputHandleRef,
  fullWidth,
  readOnly,
  autoFocus = false,
  autoFocusDelayMs = 0,
  ...rest
}: TextInputProps) => {
  const inputRef = useRef<InputHandle>(null);

  const [focus, setFocus] = useState(false);

  const ref = inputHandleRef ? mergeRefs([inputHandleRef, inputRef]) : inputRef;

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      const timer = autoFocusDelayMs
        ? setTimeout(() => inputRef.current?.focus(), autoFocusDelayMs)
        : setTimeout(() => inputRef.current?.focus(), 0); // No delay
      return () => clearTimeout(timer);
    }
  }, [autoFocus, autoFocusDelayMs]);

  return (
    <StyledTextInput $hasError={!!error} $readOnly={readOnly} $fullWidth={fullWidth} $focus={focus}>
      {label && <Title>{label}</Title>}
      <div className="text-input-wrapper">
        <Input
          {...rest}
          readOnly={readOnly}
          error={error}
          ref={ref}
          onFocus={() => setFocus(true)}
          onBlur={e => {
            setFocus(false);
            rest.onBlur && rest.onBlur(e);
          }}
          endAdornment={rest.endAdornment}
        />
      </div>
      {error && <ErrorMessage>Required</ErrorMessage>}
    </StyledTextInput>
  );
};
