import { Input, Layout, Select, Title } from "@introist/react-foundation/v2";
import { useCallback, useMemo } from "react";
import { parseRelativeDate, relativeDateSchema } from "@introist/introist-commons/dist";

export type RelativeDateInlineEditorProps = {
  value?: string;
  onChange: (value: string | null) => void;
  readOnly?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  locked?: {
    time?: boolean;
    unit?: boolean;
    side?: boolean;
  };
  hidden?: {
    side?: boolean;
  };
  variant?: "blended" | "outlined";
  size?: "small" | "medium";
};

const extractRelativeDateInfo = (value?: string) => {
  if (value) {
    const parsed = relativeDateSchema.safeParse(value);
    if (parsed.success) {
      return parseRelativeDate(parsed.data);
    }
  }

  return { side: "+", amount: 1, unit: "M" };
};

export const RelativeDateInlineEditor = ({
  value,
  onChange,
  readOnly,
  disabled,
  locked,
  hidden,
  variant = "blended"
}: RelativeDateInlineEditorProps) => {
  const { side, amount, unit } = useMemo(() => {
    const { amount, unit, side } = extractRelativeDateInfo(value);
    return { amount, unit, side };
  }, [value]);

  const onChanged = useCallback(
    (property: string, newValue: string) => {
      const reldate = `${property === "side" ? newValue : side}${
        property === "amount" ? newValue : amount
      }${property === "unit" ? newValue : unit}`;
      onChange(reldate);
    },
    [side, amount, unit, onChange]
  );

  if (readOnly) {
    return (
      <Title variant="bold">
        {amount} {side} {unit === "-" ? "before" : "after"}
      </Title>
    );
  }

  return (
    <Layout.Group gap="xSmall">
      <Input
        readonly={locked?.time}
        type="number"
        size="small"
        style={{ width: "40px", textAlign: "right" }}
        variant={variant}
        value={amount}
        onChange={val => onChanged("amount", val)}
        disabled={disabled}
      />
      <Select
        readonly={locked?.unit}
        size="small"
        variant={variant}
        style={{ width: "120px" }}
        options={[
          { key: "d", title: "days" },
          { key: "w", title: "weeks" },
          { key: "M", title: "months" }
        ]}
        value={unit}
        onSelect={opt => onChanged("unit", opt.key)}
        disabled={disabled}
      />
      {!hidden?.side && (
        <Select
          readonly={locked?.side}
          size="small"
          variant="blended"
          style={{ width: "110px" }}
          options={[
            { key: "-", title: "before" },
            { key: "+", title: "after" }
          ]}
          value={side}
          onSelect={opt => onChanged("side", opt.key)}
          disabled={disabled}
        />
      )}
    </Layout.Group>
  );
};
