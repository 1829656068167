import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Icon, IconName, Select, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { api } from "../../../../services/rpc/RpcProvider";
import { useMemo } from "react";
import { EmployeeFieldSelector } from "../../../employees/fields/EmployeeFieldSelector";
import { InlineOptionList } from "../../../../foundation/blocks/InlineOptionList";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { AvatarBackground } from "../slack/SlackUsersSelect";

export const TeamsChatMessageEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const { data: options } = api.workflows.v4.steps.options.useQuery({
    stepType: form.data.stepType
  });
  const { fieldTitle } = useEmployeeFields();

  const senders = useMemo(() => {
    if (!options || !options.senders) return [];

    return (options.senders as any[]).map(o => ({
      key: o.id,
      title: o.name,
      startAdornmentIcon: "person" as IconName
    }));
  }, [options]);

  const users = useMemo(() => {
    const senderId = prefixedForm.get("senderId");

    if (!options) return [];
    if (!senderId) return [];

    const users = options.senders.find((s: any) => s.id === senderId)?.users;

    if (!users) return [];
    return users.map((c: any) => ({ key: c.id, title: c.name }));
  }, [prefixedForm, options]);

  const values = useMemo(() => {
    const value = prefixedForm.get("users");
    return value.map((v: any) => {
      const user = users.find((s: any) => s.key === v);
      if (user) {
        return {
          ...user,
          startAdornment: (
            <AvatarBackground>
              <Icon name="person" />
            </AvatarBackground>
          )
        };
      }

      return {
        key: v,
        title: fieldTitle(v),
        startAdornment: (
          <AvatarBackground>
            <Icon name="attribute" />
          </AvatarBackground>
        )
      };
    });
  }, [prefixedForm, fieldTitle, users]);

  const messageError = prefixedForm.error("message");

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Chat" defaultOpen>
          <Stack vertical alignItems="stretch" gap="large">
            <StepEditorAccordion.Row label="From">
              <Select
                searchable
                size="small"
                element="button"
                variant="blended"
                options={senders}
                value={prefixedForm.get("senderId")}
                onSelect={opt => prefixedForm.set("senderId")(opt.key)}
                attachToRef={false}
                placeholder="Select sender"
                startAdornment={<Icon name="person" />}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.MultiOptionRow label="To">
              <Stack vertical>
                <InlineOptionList
                  value={values}
                  onChange={opts => {
                    prefixedForm.set("users")(opts.map(o => o.key));
                  }}
                />
                <Stack>
                  <Select
                    searchable
                    element="button"
                    size="small"
                    variant="blended"
                    options={users}
                    onSelect={opt => {
                      prefixedForm.set("users")([...prefixedForm.get("users"), opt.key]);
                    }}
                    attachToRef={false}
                    placeholder="Add user"
                    startAdornment={<Icon name="plus" />}
                  />
                  <EmployeeFieldSelector
                    fieldTypes={["email"]}
                    onSelect={path => {
                      const value = prefixedForm.get("users");
                      if (value.find((v: any) => v === path)) {
                        prefixedForm.set("users")(value.filter((v: any) => v !== path));
                      } else {
                        prefixedForm.set("users")([...value, path]);
                      }
                    }}
                    startAdornment={<Icon name="attribute" />}
                    variant="blended"
                    size="small"
                  >
                    Add Field
                  </EmployeeFieldSelector>
                </Stack>
              </Stack>
            </StepEditorAccordion.MultiOptionRow>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
              underline={false}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
