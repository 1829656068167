import { Integration } from "../../../integrations";
import { Card, ICardProps, Title, CircularLoader } from "@introist/react-foundation/v2";
import styled from "styled-components";

type HrisCardProps = ICardProps & {
  hris: Integration;
  loading?: boolean;
};

const CardContainer = styled(Card)<{ hoverable: boolean }>`
  width: 300px;
  display: flex;
  align-items: center;
  gap: var(--spacing-large);
  cursor: ${props => (props.hoverable ? "pointer" : undefined)};
`;

export const HrisCard = ({ hris, loading, ...rest }: HrisCardProps) => {
  return (
    <CardContainer hoverable {...rest}>
      <img src={hris.icon} alt={hris.name} style={{ width: "24px", height: "24px" }} />
      <Title variant="bold">{hris.name}</Title>
      {loading && <CircularLoader style={{ marginLeft: "auto" }} size="small" />}
    </CardContainer>
  );
};
