import React, { useEffect, useState } from "react";

import { updateMaterial } from "../../../../services/api/MaterialsApi";
import { Material } from "services/rpc/RpcProvider";
import styled from "styled-components";
import {
  BaseModal,
  Button,
  Input,
  Layout,
  Title,
  useActions,
  useToast
} from "@introist/react-foundation/v2";

interface Props {
  show: boolean;
  onClose: (update?: boolean) => unknown;
  material: Material;
}

const MinWidthModal = styled(BaseModal)`
  minwidth: 25rem;
`;

export const EditModal = ({ show, onClose, material }: Props) => {
  const { onAction } = useActions();
  const toast = useToast();
  const [title, setTitle] = useState<string>();
  const [data, setData] = useState<string | undefined | null>();

  useEffect(() => {
    setData(material.data);
    setTitle(material.title);
  }, [material]);

  const doUpdate = onAction(async () => {
    const resolvedData = data;
    if (!resolvedData) toast.error("No data to update");
    await updateMaterial(material.id, { title, data: resolvedData! });
    toast.success("Material updated");
    await onClose(true);
  });

  return (
    <MinWidthModal
      open={show}
      onClose={onClose}
      title="Edit material"
      style={{ width: "420px" }}
      header={<Title variant="bold">Edit material</Title>}
    >
      <Layout.Group vertical>
        <Layout.Group vertical>
          <Layout.Group vertical>
            <Title variant="bold">Name</Title>
            <Input size="small" value={title} onChange={setTitle} />
          </Layout.Group>
          {material.materialType === "link" && (
            <Layout.Group vertical>
              <Title variant="bold">Link URL</Title>
              <Input size="small" value={data ?? undefined} onChange={setData} />
            </Layout.Group>
          )}
        </Layout.Group>
        <Layout.Group justifyContent="flex-end">
          <Button onClickWithLoading={doUpdate}>Save</Button>
        </Layout.Group>
      </Layout.Group>
    </MinWidthModal>
  );
};
