import styled, { css } from "styled-components";
import { H4, Layout, Title, useResponsive } from "@introist/react-foundation/v2";
import { Integration } from "modules/integrations";

import { IntegrationCard } from "../IntegrationCard";

type IntegrationsGridProps = {
  title: string;
  description: string;
  integrations: Integration[];
  onIntegrationClick: (integration: Integration) => void;
};

const Grid = styled.div<{ $isMobileLayout: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-large);
  padding-bottom: var(--spacing-small);

  ${({ $isMobileLayout }) =>
    $isMobileLayout &&
    css`
      grid-template-columns: 1fr;
    `}
`;

export const IntegrationsGrid = ({
  title,
  description,
  integrations,
  onIntegrationClick,
  ...rest
}: IntegrationsGridProps) => {
  const { isMobileLayout } = useResponsive();
  return (
    <Layout.Group vertical gap="xLarge" as="section">
      <Layout.Group {...rest} vertical gap="xSmall">
        <H4>{title}</H4>
        <Title>{description}</Title>
      </Layout.Group>
      <Grid $isMobileLayout={isMobileLayout}>
        {integrations.map(integration => (
          <IntegrationCard
            key={integration.id}
            integration={integration}
            onClick={() => onIntegrationClick(integration)}
          />
        ))}
      </Grid>
    </Layout.Group>
  );
};
