import {
  Button,
  Card,
  CircularLoader,
  IconButton,
  Option,
  Select,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useMemo, useState } from "react";
import { api, Trigger } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { IconPickerIcon } from "../../../../components/organisms";

type Props = {
  trigger: Trigger;
};

const EmptyTriggerWorkflowCard = styled(Card)`
  display: flex;
  justify-content: center;
  background-color: var(--palette-surface-subdued);
`;

export const TriggerWorkflow = ({ trigger }: Props) => {
  const toast = useToast();
  const [edit, setEdit] = useState(false);

  const apiUtils = api.useContext();
  const workflows = api.workflows.v2.list.useQuery({});
  const workflow = api.workflows.v2.find.useQuery(
    { workflowId: trigger.workflowId! },
    { enabled: !!trigger.workflowId }
  );

  const [value, setValue] = useState<string | null>(trigger.workflowId);

  const update = api.triggers.update.useMutation();

  const options: Option[] = useMemo(() => {
    return (workflows.data || []).map(w => ({
      key: w.id,
      title: w.name
    }));
  }, [workflows.data]);

  const onSelectWorkflow = useCallback(
    async (workflowId: string) => {
      return update
        .mutateAsync({
          id: trigger.id,
          updates: { workflowId }
        })
        .then(async () => {
          await apiUtils.triggers.find.invalidate({ id: trigger.id });
          toast.success("Workflow updated");
          setValue(workflowId);
          setEdit(false);
        })
        .catch(() => toast.success("Failed to update workflow"));
    },
    [apiUtils, toast, trigger.id, update]
  );

  return (
    <Stack vertical gap="small">
      <Stack justifyContent="space-between">
        <Title variant="bold">Workflow</Title>
        {!edit && <IconButton dimmed size="large" icon="pencil" onClick={() => setEdit(true)} />}
        {edit && (
          <IconButton dimmed size="large" icon="crossSmall" onClick={() => setEdit(false)} />
        )}
      </Stack>
      {workflow.data && !edit && (
        <Card>
          <Stack>
            <IconPickerIcon icon="workflows" fallbackIcon="workflows" />
            <Title variant="bold">{workflow.data.name}</Title>
            <IconButton
              size="large"
              icon="moveTo"
              dimmed
              style={{ marginLeft: "auto" }}
              onClick={() => window.open(`/flows/edit/${workflow.data.id}`, "_blank")}
            />
          </Stack>
        </Card>
      )}
      {!workflow.data && !edit && !workflow.isFetching && (
        <EmptyTriggerWorkflowCard variant="blended" spacing="xLarge">
          <Button startIcon="plus" size="small" onClick={() => setEdit(true)}>
            Add
          </Button>
        </EmptyTriggerWorkflowCard>
      )}
      {edit && (
        <Select
          value={value ?? undefined}
          placeholder="Select a Workflow"
          searchable
          style={{ width: "100%" }}
          attachToRef={false}
          options={options}
          onSelect={opt => onSelectWorkflow(opt.key)}
          endAdornment={update.isLoading && <CircularLoader size="small" />}
        />
      )}
    </Stack>
  );
};
