import { H3, Layout } from "@introist/react-foundation/v2";
import { DataSourceConnectionForm } from "./steps/DataSourceConnectionForm";
import { AvailableDataSourcesPicker } from "./steps/AvailableDataSourcesPicker";
import {
  DataSourceConnectionWizardContextProvider,
  useDataSourceConnectionWizardContext
} from "./context";
import styled from "styled-components";

const StyledDataSourceConnectionSetupView = styled(Layout.Group)`
  max-width: 50rem;
  width: 100%;
  margin: 2rem auto;
`;

export const DataSourceConnectionSetupViewComponent = ({ ...rest }) => {
  const { step } = useDataSourceConnectionWizardContext();

  const getCurrentStep = () => {
    switch (step) {
      case "choose-data-source":
        return <AvailableDataSourcesPicker />;
      case "connect-data-source":
        return <DataSourceConnectionForm />;
    }
  };

  return (
    <StyledDataSourceConnectionSetupView {...rest} vertical gap="xxLarge">
      <H3>Connect new data source</H3>
      {getCurrentStep()}
    </StyledDataSourceConnectionSetupView>
  );
};

export const DataSourceConnectionSetupView = ({ ...rest }) => {
  return (
    <DataSourceConnectionWizardContextProvider>
      <DataSourceConnectionSetupViewComponent />
    </DataSourceConnectionWizardContextProvider>
  );
};
