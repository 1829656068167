import { memo, useRef, useState } from "react";

import { ErrorMessage, Layout, useResponsive } from "@introist/react-foundation/v2";

import { ParticipantCard } from "../ParticipantCard";
import { PortalOptionListSingleSelect, useSingleSelectOptionList } from "../PortalOptionList";
import { TOption } from "../PortalOptionList/types";
import { IconToggleButton } from "../IconToggleButton";

type EmailAddressSingleSelectProps = {
  readOnly?: boolean;
  initialSelectedOption?: TOption;
  initialOptions: TOption[];
  addButtonText?: string;
  error?: string;
  onChange: (selectedId?: string) => void;
  onEnter?: (search: string) => void;
};

export const EmailAddressSingleSelect = memo(
  ({
    initialOptions,
    initialSelectedOption,
    addButtonText = "Add",
    readOnly = false,
    error,
    onChange,
    onEnter,
    ...rest
  }: EmailAddressSingleSelectProps) => {
    const { isMobileLayout } = useResponsive();
    const anchorRef = useRef<HTMLDivElement | null>(null);

    const [optionListOpen, setOptionListOpen] = useState(false);

    const { options, selectedOption, handleOptionClick, handleRemoveOption, handleAddOption } =
      useSingleSelectOptionList({
        initialSelectedOption,
        initialOptions,
        onChange: selectedId => {
          onChange(selectedId);
          setOptionListOpen(false);
        },
        onEnter
      });

    return (
      <div {...rest} ref={anchorRef}>
        {!!selectedOption ? (
          <div onClick={() => !readOnly && setOptionListOpen(!optionListOpen)}>
            <ParticipantCard
              readOnly={readOnly}
              title={selectedOption.title || ""}
              description={selectedOption.description}
              onRemove={handleRemoveOption}
            />
          </div>
        ) : (
          <>
            <Layout.Group>
              <IconToggleButton
                startIcon="plus"
                destructive={!!error}
                active={!!optionListOpen}
                onClick={() => setOptionListOpen(!optionListOpen)}
              >
                {addButtonText}
              </IconToggleButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Layout.Group>
          </>
        )}

        <PortalOptionListSingleSelect
          open={optionListOpen}
          options={options}
          onOptionClick={handleOptionClick}
          selectedKey={selectedOption?.key}
          referenceElement={anchorRef.current}
          onEnter={handleAddOption}
          placement={isMobileLayout ? "bottom-start" : "left-start"}
          sameWidthWithReference={isMobileLayout}
          onClose={() => setOptionListOpen(false)}
        />
      </div>
    );
  }
);
