import { Stack, Select, Title, Input } from "@introist/react-foundation/v2";
import { useMemo } from "react";
import cronParser from "cron-parser";
import moment from "moment";

const cronFields = ["m", "h", "dom", "mo", "dow"] as const;

export const modifyCron = (cron: string, field: string, value: string) => {
  const items = cron.split(" ");
  items[cronFields.indexOf(field as any)] = value;
  return items.join(" ");
};

export const extractFromCron = (cron: string, field: string) => {
  const items = cron.split(" ");
  const index = cronFields.indexOf(field as any);
  if (index === -1 || items.length <= index) return undefined;
  return items[index];
};

const templates: { [index: string]: RegExp } = {
  weekly: new RegExp(/0\s8\s\*\s\*\s[1-5]/), //Weekly on weekday at 8am
  monthly: new RegExp(/0\s8\s([1-9]|[12][0-9]|3[01])\s\*\s\*/) //Weekly on any day at 8am
};

export const DEFAULT_CRON = "0 8 1 * *";

export const CronBuilder = ({
  value = DEFAULT_CRON,
  onChange
}: {
  value?: string;
  onChange: (value: string) => void;
}) => {
  const cycle = useMemo(() => {
    const template = Object.keys(templates).find(k => templates[k].test(value));
    return template ?? "custom";
  }, [value]);

  const parsed = useMemo(() => {
    try {
      return moment(cronParser.parseExpression(value).next().toISOString()).format("ddd MMM DD");
    } catch {
      return null;
    }
  }, [value]);

  return (
    <Stack vertical>
      <Stack>
        <Select
          size="small"
          style={{ width: "150px" }}
          options={[
            { key: "weekly", title: "Weekly" },
            { key: "monthly", title: "Monthly" },
            { key: "custom", title: "Custom" }
          ]}
          value={cycle}
          onSelect={opt => {
            if (opt.key === "weekly") onChange("0 8 * * 1");
            if (opt.key === "monthly") onChange("0 8 1 * *");
            if (opt.key === "custom") onChange("0 8 * * *");
          }}
        />
        {cycle === "weekly" && (
          <>
            <Title>on</Title>
            <Select
              size="small"
              options={[
                { key: "1", title: "Monday" },
                { key: "2", title: "Tuesday" },
                { key: "3", title: "Wednesday" },
                { key: "4", title: "Thursday" },
                { key: "5", title: "Friday" }
              ]}
              value={extractFromCron(value, "dow")}
              onSelect={opt => onChange(modifyCron(value, "dow", opt.key))}
            />
          </>
        )}
        {cycle === "monthly" && (
          <>
            <Title style={{ width: "50px" }}>on day</Title>
            <Select
              size="small"
              style={{ width: "80px" }}
              options={Array(31)
                .fill(null)
                .map((_, i) => ({ key: (i + 1).toString() }))}
              value={extractFromCron(value, "dom")}
              onSelect={opt => onChange(modifyCron(value, "dom", opt.key))}
            />
          </>
        )}
        {cycle === "custom" && <Input size="small" value={value} onChange={onChange} />}
      </Stack>
      <Title>
        {parsed === null && "Invalid schedule"}
        {parsed && `Next upcoming on ${parsed}`}
      </Title>
    </Stack>
  );
};
