import styled from "styled-components";
import { Card } from "@introist/react-foundation/v2";

export const PopupActionCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-large);
  background-color: var(--palette-foreground-default);
  padding: var(--spacing-small);
  padding-left: var(--spacing-medium);
  box-sizing: border-box;

  position: sticky;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  > h5 {
    color: var(--palette-surface-default);
  }

  > button {
    box-shadow: none;
  }
`;
