import { SkeletonLoader, Stack } from "@introist/react-foundation/v2";

export const Skeletons = {
  List: ({ rowCount = 5, rowHeight = 48 }: { rowCount?: number; rowHeight?: number }) => (
    <Stack vertical gap="small">
      {[...Array(rowCount - 1)].map((_, i) => (
        <SkeletonLoader key={i} height={rowHeight} />
      ))}
      <SkeletonLoader fadeOut height={rowHeight} />
    </Stack>
  ),
  Grid: ({
    height = 100,
    width = 300,
    count = 3
  }: {
    height?: number;
    width?: number;
    count?: number;
  }) => (
    <Stack>
      {new Array(count).fill(null).map(() => (
        <SkeletonLoader height={height} width={width} />
      ))}
    </Stack>
  ),
  Block: SkeletonLoader
};
