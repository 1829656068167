import {
  ColorVariants,
  IconTag,
  Layout,
  Palette,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { FittedTitle } from "components/atoms";

import {
  AlertSeverity,
  AlertType,
  ExtendedJourneyStepAlertRecord
} from "modules/issues/api/AlertApi";

import styled from "styled-components";

type IssueItemProps = {
  issue: ExtendedJourneyStepAlertRecord;
};

const StyledIssueItem = styled(Layout.Group)`
  width: 100%;
  border: 1px solid var(--palette-danger-default);
  padding: 0.375rem;
  padding-right: var(--spacing-small);
  border-radius: var(--rounding-medium);
`;

export const IssueItem = ({ issue }: IssueItemProps) => {
  const { theme } = useTheme();

  const { severity, alertType, resolvedIdentifier, identifierData } = issue;

  const title = resolvedIdentifier ?? identifierData;
  const vertical = title.length > 32;

  return (
    <StyledIssueItem vertical={vertical} lastChildToEnd={!vertical} gap="xSmall">
      <Layout.Group>
        <IconTag
          size="small"
          variant="ghosted"
          inverted={severity === "warning"}
          data-testid="issue-icon"
          color={theme.palette.danger}
          name={"warning"}
        />
        <Title variant="bold">{issueReasonMapping[alertType]}</Title>
      </Layout.Group>
      <FittedTitle showTooltip maxLength={55}>
        {title}
      </FittedTitle>
    </StyledIssueItem>
  );
};

export const issueReasonMapping: Record<AlertType, string> = {
  attributemissing: "Missing property",
  slackusernotfound: "Slack user not found",
  calendareventnotfound: "Calendar event not found",
  slackchannelnotfound: "Slack channel not found"
};

export const tagColorMapping = (palette: Palette): Record<AlertSeverity, ColorVariants> => ({
  info: palette.primary,
  error: palette.danger,
  warning: palette.warning
});
