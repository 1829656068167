import {
  Button,
  ButtonProps,
  Icon,
  IconName,
  OptionList,
  Popover,
  Select,
  SelectProps,
  useTheme
} from "@introist/react-foundation/v2";
import { EmployeeField, useEmployeeFields } from "../hooks/useEmployeeFields";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { compact } from "lodash";
import { Form, useForms } from "../../forms/useForms";
import { useFormFields } from "../../forms/useFormFields";

const IconBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: var(--rounding-small);
  background: var(--palette-purple-ghosted);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--palette-purple-default) !important;
  }
`;

export const SelectorIcon = ({ icon }: { icon: IconName }) => (
  <IconBackground>
    <Icon name={icon} />
  </IconBackground>
);

export const EmployeeFieldSelector = ({
  onSelect,
  onAdd,
  fieldTypes,
  ...rest
}: {
  onSelect: (path: string) => void;
  onAdd?: (search: string) => void;
  fieldTypes?: string[];
} & Omit<ButtonProps, "onClick">) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button ref={setRef} onClick={() => setOpen(true)} {...rest} />
      <EmployeeFieldSelectorPopover
        popoverRef={ref}
        open={open}
        setOpen={setOpen}
        onSelect={onSelect}
        onAdd={onAdd}
        fieldTypes={fieldTypes}
      />
    </>
  );
};

export const EmployeeFieldSelectorPopover = ({
  open,
  setOpen,
  onSelect,
  onAdd,
  popoverRef,
  fieldTypes,
  preSearch
}: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  popoverRef: HTMLElement | null;
  onSelect: (fieldPath: string, title: string) => void;
  onAdd?: (search: string) => void;
  fieldTypes?: string[];
  preSearch?: string;
}) => {
  const { theme } = useTheme();
  const { fields } = useEmployeeFields();

  const [parentField, setParentField] = useState<EmployeeField | null>(null);
  const [formsOpen, setFormsOpen] = useState(false);

  const options = useMemo(() => {
    if (!fields) return [];

    const available = fieldTypes
      ? fields.filter(f => [...fieldTypes, "employee"].includes(f.type))
      : fields;

    const usable = parentField ? available.filter(f => f.type !== "employee") : available;
    const all = usable
      .filter(
        f => parentField || !preSearch || f.title.toLowerCase().includes(preSearch.toLowerCase())
      )
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment:
          f.type === "employee" ? (
            <IconBackground>
              <Icon name="person" />
            </IconBackground>
          ) : (
            <IconBackground>
              <Icon name="attribute" />
            </IconBackground>
          ),
        onClick: () => {
          if (f.type === "employee") {
            setParentField(f);
          } else {
            const path = parentField ? `${parentField.key}.${f.key}` : f.key;
            const title = parentField ? `${parentField.title} - ${f.title}` : f.title;

            onSelect(path, title);
            setParentField(null);
            setOpen(false);
          }
        }
      }));

    return compact([
      ...all,
      !parentField
        ? {
            key: "forms",
            title: "Forms",
            startAdornment: (
              <IconBackground>
                <Icon name="form" />
              </IconBackground>
            ),
            onClick: () => setFormsOpen(true)
          }
        : null
    ]);
  }, [fields, parentField, onSelect, setOpen, fieldTypes, preSearch]);

  return (
    <Popover
      referenceElement={popoverRef}
      open={open}
      onClose={() => setOpen(false)}
      attachToRef={false}
    >
      {formsOpen && (
        <FormFieldSelectorList
          onBack={() => setFormsOpen(false)}
          onSelect={(path, title) => {
            onSelect(path, title);
            setFormsOpen(false);
            setOpen(false);
          }}
        />
      )}
      {!formsOpen && (
        <OptionList
          actions={compact([
            parentField
              ? {
                  key: "back",
                  title: parentField.title,
                  startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
                  onClick: () => setParentField(null)
                }
              : null
          ])}
          searchable
          onEnter={search => {
            if (onAdd) {
              onAdd(search);
              setOpen(false);
            }
          }}
          options={options}
          clearSearchOnOptionsChange
        />
      )}
    </Popover>
  );
};

export const EmployeeAsFieldSelector = (props: Omit<SelectProps, "options">) => {
  const { fields } = useEmployeeFields();

  const options = useMemo(() => {
    if (!fields) return [];
    return [{ key: "id", title: "Employee" }, ...fields.filter(f => f.type === "employee")].map(
      f => ({
        key: f.key === "id" ? "id" : `${f.key}.id`,
        title: f.title,
        startAdornment: (
          <IconBackground>
            <Icon name="person" />
          </IconBackground>
        )
      })
    );
  }, [fields]);

  return (
    <Select
      element="button"
      variant="outlined"
      placeholder="Select Employee"
      startAdornment={
        <IconBackground>
          <Icon name="person" />
        </IconBackground>
      }
      options={options}
      attachToRef={false}
      {...props}
    />
  );
};

export const FormFieldSelectorList = ({
  onBack,
  onSelect
}: {
  onBack?: () => unknown;
  onSelect: (path: string, title: string) => void;
}) => {
  const { theme } = useTheme();

  const { forms } = useForms();

  const [selectedForm, setSelectedForm] = useState<Form | null>(null);

  const { formFields } = useFormFields(selectedForm?.id ?? "", { enabled: !!selectedForm });

  const options = useMemo(() => {
    if (!selectedForm) {
      return (forms ?? []).map(f => ({
        key: f.id,
        title: f.title,
        startAdornment: <SelectorIcon icon="form" />,
        onClick: () => setSelectedForm(f)
      }));
    }

    return (formFields ?? []).map(f => ({
      key: `forms.${selectedForm.id}.${f.id}`,
      title: f.shortTitle ?? f.title,
      startAdornment: <SelectorIcon icon="attribute" />,
      onClick: () => {
        onSelect(
          `forms.${selectedForm.id}.${f.id}`,
          `${selectedForm.title} - ${f.shortTitle ?? f.title}`
        );
        setSelectedForm(null);
      }
    }));
  }, [selectedForm, onSelect, forms, formFields]);

  return (
    <OptionList
      actions={compact([
        selectedForm
          ? {
              key: "back",
              title: selectedForm.title,
              startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
              onClick: () => setSelectedForm(null)
            }
          : null,
        onBack && !selectedForm
          ? {
              key: "back",
              title: "Back",
              startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
              onClick: () => {
                setSelectedForm(null);
                onBack();
              }
            }
          : null
      ])}
      searchable
      options={options}
      clearSearchOnOptionsChange
    />
  );
};
