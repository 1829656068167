import { api } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { DatasetField, DatasetFieldCreate, DatasetFieldUpdate } from "../index";

export const useDatasetFields = (datasetId: string) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data, refetch } = api.datasets.fields.list.useQuery({ datasetId });

  const createOp = api.datasets.fields.create.useMutation();
  const updateOp = api.datasets.fields.update.useMutation();
  const archiveOp = api.datasets.fields.archive.useMutation();

  const reorderOp = api.datasets.fields.reorder.useMutation();

  const create = useCallback(
    async (entry: Partial<DatasetField>) => {
      return createOp.mutateAsync({ ...entry, datasetId } as DatasetFieldCreate).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch, datasetId]
  );

  const update = useCallback(
    async (data: DatasetFieldUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const archive = onConfirmAction(
    async id => {
      await archiveOp.mutateAsync({ id });
      await refetch();
      toast.success("Field archived");
    },
    {
      icon: "trash",
      title: "Archive field",
      description: "Are you sure you want to archive this field?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  const reorder = useCallback(
    async (items: DatasetField[]) => {
      await reorderOp.mutateAsync({ datasetId, ordering: items.map(i => i.id) });
    },
    [datasetId, reorderOp]
  );

  return {
    fields: data,
    create,
    update,
    archive,
    reorder
  };
};
