import styled from "styled-components";
import { Stack, Tag, Title, useTheme } from "@introist/react-foundation/v2";

import { ProgressBar } from "components/molecules";
import { AutomationStats } from "../hooks/useAutomations";
import { IconWithCount } from "../../../foundation/blocks/IconWithCount";

export type AutomationProgressProps = {
  stats?: AutomationStats;
  showNumbers?: boolean;
};

const StyledProgressBar = styled(ProgressBar)`
  height: 3px;
  width: 2rem;
  border-radius: 3px;

  > span {
    background-color: var(--palette-success-default);
  }
`;

export const AutomationProgress = ({ stats, showNumbers = true }: AutomationProgressProps) => {
  const { theme } = useTheme();

  if (!stats) return null;

  if (stats.draft > 0) {
    return <Tag colorVariant="outlined">Draft</Tag>;
  }
  if (stats.active === 0 && stats.canceled > 0) {
    return <Tag>Canceled</Tag>;
  }
  if (stats.active === 0 && stats.completed > 0) {
    return <Tag colorVariant="success">Completed</Tag>;
  }
  if (stats.total === 0 && stats.canceled === 0) {
    return <Tag>Empty</Tag>;
  }

  return (
    <Stack gap="small">
      {showNumbers && (
        <Title variant="bold">
          {stats.completed} of {stats.total}
        </Title>
      )}
      <StyledProgressBar value={stats.completed} total={stats.total} />
      <IconWithCount
        iconName="warning"
        hideIfZero
        count={stats.failing}
        color={theme.palette.danger.default}
      />
    </Stack>
  );
};
