import {
  ModalProps,
  BaseModal,
  Input,
  Icon,
  H4,
  Card,
  Title,
  Button,
  CircularLoader,
  useToast
} from "@introist/react-foundation/v2";
import { useCallback, useEffect, useState } from "react";
import styles from "./SearchEventModal.module.scss";
import {
  RecurringEvent,
  searchRecurringEventsForSender
} from "../../../../../../services/api/Senders";

type Props = ModalProps & {
  hostId: string;
  onEventSelected: (event: RecurringEvent) => void;
};

export const SearchEventModal = ({ hostId, onEventSelected, ...props }: Props) => {
  const toast = useToast();

  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState("");
  const [matching, setMatching] = useState<RecurringEvent[] | undefined>();

  useEffect(() => {
    setSearch("");
    setMatching(undefined);
  }, [props.open]);

  const onSearch = useCallback(async () => {
    if (search.length < 3) {
      toast.info("Write a search of at least 3 characters");
      return;
    }

    setSearching(true);
    searchRecurringEventsForSender({ hostId, search })
      .then(events => setMatching(events))
      .catch(() => toast.error("Failed to search events"))
      .finally(() => setSearching(false));
  }, [hostId, search, toast]);

  return (
    <BaseModal
      header={<H4>Search recurring event</H4>}
      style={{ width: "500px", height: "600px" }}
      {...props}
    >
      <Input
        startAdornment={<Icon name="search" />}
        placeholder="Search for a recurring event"
        onChange={setSearch}
        value={search}
        autoFocus
        onKeyUp={event => {
          if (!searching && event.key === "Enter") onSearch();
        }}
        endAdornment={searching && <CircularLoader size="small" />}
      />
      <div className={styles.Events}>
        {matching &&
          matching.map(e => (
            <Card key={e.id} className={styles.Card}>
              <Title variant="bold">{e.summary}</Title>
              <Button variant="outlined" onClick={() => onEventSelected(e)}>
                Select
              </Button>
            </Card>
          ))}
        {!matching && <Title>Write something and hit enter to search</Title>}
        {matching && matching.length === 0 && (
          <Title>No recurring events matching your search</Title>
        )}
      </div>
    </BaseModal>
  );
};
