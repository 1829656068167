import { RescheduleModal, RescheduleModalProps } from "./RescheduleModal";
import { JourneyStepV2 } from "../../../services/rpc/RpcProvider";
import { RescheduleMeetingModal } from "./RescheduleMeetingModal";
import { ReschedulStepModal } from "../../automations/containers/ReschedulStepModal";

interface RescheduleJourneyStepProps
  extends Pick<RescheduleModalProps, "open" | "onClose" | "onRescheduled"> {
  step: JourneyStepV2;
  allowCreate?: boolean;
}
export const RescheduleJourneyStep = ({
  open,
  step,
  allowCreate,
  onClose,
  onRescheduled
}: RescheduleJourneyStepProps) => {
  const getRescheduleModalPropsByStep = (): Pick<
    RescheduleModalProps,
    "start" | "end" | "title" | "description" | "useMatchingEvents" | "hostId" | "eventSummary"
  > => {
    if (step.stepType === "event") {
      const individualStep = (step as any).previewData.eventType === "individual";
      const hostId = (step as any).previewData.hostId;
      const eventSummary = (step as any).previewData.title;
      return {
        start: step.start,
        end: step.end,
        useMatchingEvents: !individualStep,
        title: individualStep ? "Reschedule meeting" : "Reinvite to another event",
        description: !individualStep
          ? "Reschedule the event by selecting from the available matching events"
          : undefined,
        hostId,
        eventSummary
      };
    }

    return { start: step.start, end: step.end };
  };

  if (step.stepType !== "event") {
    return (
      <ReschedulStepModal
        stepId={step.id}
        open={open}
        onClose={onClose}
        onRescheduled={onRescheduled}
      />
    );
  }

  if ((step as any).previewData.eventType === "individual") {
    return (
      <RescheduleMeetingModal
        open={open}
        onClose={onClose}
        stepId={step.id}
        journeyId={step.journeyId}
        onRescheduled={onRescheduled}
        start={step.start}
        end={step.end}
        eventSummary={(step as any).previewData.title}
      />
    );
  }

  return (
    <RescheduleModal
      open={open}
      onClose={onClose}
      stepId={step.id}
      allowCreate={allowCreate}
      journeyId={step.journeyId}
      onRescheduled={onRescheduled}
      {...getRescheduleModalPropsByStep()}
    />
  );
};
