import { useIntegrations } from "hooks";
import { useMemo } from "react";

interface OnboardingField {
  label: string;
  name: string;
}

export const useFreshserviceFields = (): { fields: OnboardingField[] } => {
  const { loadOptions } = useIntegrations();

  const { data: options } = loadOptions({ integrationId: "freshservice" }).query;

  const fields = useMemo(() => {
    return options?.onboardingFields;
  }, [options]);

  return {
    fields: fields
  };
};
