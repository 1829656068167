import { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  ActionMenu,
  Button,
  Card,
  Icon,
  IconButton,
  Layout,
  Option,
  Tag,
  Title,
  Tooltip
} from "@introist/react-foundation/v2";
import useMeasure from "react-use-measure";
import { a, useSpring } from "@react-spring/web";
import { capitalize, isEmpty } from "lodash";

import { EmployeeFieldGroup } from "../../EmployeeFieldsSettingsRoute";
import { EmployeeFieldRow } from "./EmployeeFieldRow";
import { EmployeeFieldListProps, useEmployeeFieldActions } from "./EmployeeFieldList";

type Props = Pick<
  EmployeeFieldListProps,
  "onAddSubField" | "onArchiveField" | "onEditField" | "onAddTransform"
> & {
  field: EmployeeFieldGroup;
  showHidden?: boolean;
  disableSort?: boolean;
  actions: Option[];
};

const StyledFieldGroupRow = styled.div`
  overflow: hidden;
  padding: var(--spacing-small) 0;

  > div {
    padding: var(--spacing-medium);
    padding-bottom: 0;
  }
`;

const FieldGroupSubFields = styled.div`
  padding: var(--spacing-small) 0;
`;

const FieldGroupRowHeader = styled(Layout.Group)`
  padding-bottom: var(--spacing-large);
`;

const FieldGroupSubField = styled(EmployeeFieldRow)`
  > div {
    padding: var(--spacing-medium);
  }
`;

const EmptyStateCard = styled(Card)`
  display: flex;
  justify-content: center;
  background-color: var(--palette-surface-subdued);
`;

export const EmployeeFieldGroupRow = ({
  field,
  actions,
  disableSort,
  onAddSubField,
  onArchiveField,
  onAddTransform,
  onEditField
}: Props) => {
  const firstRenderRef = useRef(true);
  const [contentMeasureRef, { height }] = useMeasure();

  const heightSpring = useSpring({
    height: height,
    overflow: "hidden",
    immediate: firstRenderRef.current,
    config: {
      tension: 200
    }
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      firstRenderRef.current = false;
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const { buildFieldActions } = useEmployeeFieldActions();

  return (
    <StyledFieldGroupRow>
      <Card>
        <FieldGroupRowHeader justifyContent="space-between">
          <Layout.Group>
            {!disableSort && <Icon subdued name="grab" />}

            <Title variant="bold">{field.name}</Title>
            <Tag colorVariant="outlined">{capitalize(field.type)}</Tag>
          </Layout.Group>
          <Layout.Group>
            {onAddSubField && (
              <Tooltip tooltip="Add field to group">
                <IconButton
                  variant="outlined"
                  icon="plus"
                  onClick={() => onAddSubField({ name: field.name, id: field.id })}
                />
              </Tooltip>
            )}
            {actions && actions.length > 0 && <ActionMenu options={actions} />}
          </Layout.Group>
        </FieldGroupRowHeader>

        <a.div style={heightSpring}>
          <Layout.Divider />
          <FieldGroupSubFields ref={contentMeasureRef}>
            {isEmpty(field.subFields ?? []) && onAddSubField && (
              <EmptyStateCard variant="blended">
                <Button
                  size="small"
                  onClick={() => {
                    onAddSubField({ id: field.id, name: field.name });
                  }}
                >
                  Add field
                </Button>
              </EmptyStateCard>
            )}
            {(field.subFields ?? []).map(f => (
              <FieldGroupSubField
                disableSort
                key={`field-group-sub-field-${f.id}`}
                field={f}
                actions={buildFieldActions({
                  field: f,
                  onEditField,
                  onArchiveField,
                  onAddTransform
                })}
              />
            ))}
          </FieldGroupSubFields>
        </a.div>
      </Card>
    </StyledFieldGroupRow>
  );
};
