import { Icon } from "@introist/react-foundation/v2";
import { NudgesListRoute } from "./routes/NudgesListRoute";
import { NudgeRoute } from "./routes/nudge/NudgeRoute";

export const NUDGES_ROUTES = {
  nudges: {
    key: "route-nudges",
    title: "Nudges",
    icon: <Icon name="targetAfter" />,
    path: "/nudges",
    element: <NudgesListRoute />
  },
  nudge: {
    key: "route-nudge",
    title: "Batch",
    path: "/nudges/:id",
    element: <NudgeRoute />
  }
};
