import styled from "styled-components";
import {
  Card,
  IntroistLoader,
  IconTag,
  useTheme,
  Title,
  Layout,
  H4,
  Button
} from "@introist/react-foundation/v2";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import {
  completePublicAccountConnect,
  PublicAccountConnectDetails,
  startPublicAccountConnect
} from "./ConnectedAccountsApi";
import { useSearchParams } from "react-router-dom";

const Logo = styled.div<{ logo?: string }>`
  width: 40px;
  height: 40px;
  border-radius: var(--rounding-small);
  background-image: ${props => (props.logo ? `url(${props.logo})` : undefined)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 99vh;
`;

export const StartPublicConnectRoute = () => {
  const { theme } = useTheme();

  const { workspaceToken } = useParams();
  const [search] = useSearchParams();

  const [details, setDetails] = useState<PublicAccountConnectDetails | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    startPublicAccountConnect(workspaceToken!, search.get("provider")!)
      .then(setDetails)
      .catch(() => setError("Something failed"));
  }, [search, workspaceToken]);

  if (!details && !error) return <IntroistLoader globalCenter />;

  const renderReadyToConnect = () => {
    return (
      <Card elevated style={{ width: "400px" }}>
        <Layout.Group
          vertical
          alignItems="center"
          justifyContent="center"
          gap="xLarge"
          style={{ padding: theme.spacing.xLarge }}
        >
          <Logo logo={details?.workspace.logo} />
          <H4>Connect your account to {details?.workspace.name}</H4>
          <Title style={{ textAlign: "center" }}>
            Connecting your account to this workspace authorizes Introist to send emails and
            schedule calendar events on behalf of you.
          </Title>
          <Button
            onClick={() => {
              window.location.replace(details?.authorizationUri!);
            }}
          >
            Connect your account
          </Button>
        </Layout.Group>
      </Card>
    );
  };

  return (
    <CenteredContent>
      {error && <ErrorCard title={error} />}
      {!error && renderReadyToConnect()}
    </CenteredContent>
  );
};

export const CompletePublicConnectRoute = () => {
  const { theme } = useTheme();

  const [search] = useSearchParams();
  const [error, setError] = useState<string | undefined>();
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    const code = search.get("code");
    const state = search.get("state");

    if (!code || !state) setError("Invalid request, please try again");

    completePublicAccountConnect(code!, state!)
      .then(() => setCompleted(true))
      .catch(() => setError("Failed to complete connecting account"));
  }, [search]);

  if (!completed && !error) return <IntroistLoader globalCenter />;

  if (error) {
    return (
      <CenteredContent>
        <ErrorCard title={error} />
      </CenteredContent>
    );
  }

  return (
    <CenteredContent>
      <Card elevated style={{ width: "400px" }}>
        <Layout.Group
          vertical
          alignItems="center"
          justifyContent="center"
          gap="xLarge"
          style={{ padding: theme.spacing.xLarge }}
        >
          <IconTag name="checkCircle" color={theme.palette.success} />
          <H4>Your account is now connected</H4>
          <Title style={{ textAlign: "center" }}>You can close this window.</Title>
        </Layout.Group>
      </Card>
    </CenteredContent>
  );
};

const ErrorCard = ({ title }: { title: string }) => {
  const { theme } = useTheme();

  return (
    <Card elevated style={{ width: "400px", height: "300px" }}>
      <Layout.Group
        vertical
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
        gap="xLarge"
      >
        <IconTag color={theme.palette.danger} name="crossCircle" />
        <Title variant="bold">{title}</Title>
      </Layout.Group>
    </Card>
  );
};
