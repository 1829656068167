import { useTheme } from "@introist/react-foundation/v2";
import OgFeatherIcon from "feather-icons-react";

type FeatherIconProps = {
  icon: string;
  size: number;
  color?: string;
};

export const IntroistFeatherIcon = ({ icon, size, color }: FeatherIconProps) => {
  const { theme } = useTheme();

  const appliedColor = color ? theme.palette[color]?.default ?? color : color;

  return (
    <span style={{ color: appliedColor, width: `${size}px`, height: `${size}px` }}>
      <OgFeatherIcon icon={icon} size={size} />
    </span>
  );
};
