import { useMemo, useState } from "react";
import { Table, Stack, TableCard } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";
import { omit } from "lodash";

import { Skeletons } from "components/molecules";
import { useStoredWorkspaceState } from "hooks/use-storage-state";
import { EmployeeGroupSelector } from "../components/EmployeeGroupSelector";
import { ColumnCustomize } from "./ColumnCustomize";
import { ExportData } from "../../../containers/ExportData";
import { useHasRole, UserRole } from "../../../../../IdentityProvider";
import { useEmployeeFields } from "../../../hooks/useEmployeeFields";
import { useEmployees } from "../../../hooks/useEmployees";
import { Empty, ListView, Loading, useSearch } from "../../../../../foundation";
import partition from "lodash.partition";
import { formatColumns } from "./columns";
import { useAutomations } from "../../../../automations/hooks/useAutomations";

export const EmployeesTable = () => {
  const navigate = useNavigate();
  const isOwner = useHasRole(UserRole.Owner);

  // Init states
  const [employeeGroup, setEmployeeGroup] = useState<string | undefined>();
  const [customColumns, setCustomColumns] = useStoredWorkspaceState<string[]>(
    "intr.list.emp.cols",
    []
  );

  const { fields: employeeFields } = useEmployeeFields();
  const { employees } = useEmployees({
    groupId: employeeGroup,
    fields: ["name", ...customColumns]
  });

  const { filtered, search, setSearch } = useSearch(employees, {
    fields: ["name", ...customColumns]
  });

  const { automations } = useAutomations({ stages: ["active"] });

  const sortedEmployees = useMemo(() => {
    if (!filtered) return undefined;
    const [failing, rest] = partition(filtered, e => {
      return (automations ?? []).some(a => a.employeeId === e.id && a.status === "failing");
    });

    return [...failing, ...rest];
  }, [filtered, automations]);

  return (
    <Stack vertical gap="large" justifyContent="flex-start" style={{ flex: 1 }}>
      <ListView.Header
        title="Employees"
        count={filtered?.length}
        left={
          <Stack>
            <EmployeeGroupSelector value={employeeGroup} onGroupSelected={setEmployeeGroup} />
          </Stack>
        }
        onCreate={() => navigate("new", { relative: "path", state: { from: "employees" } })}
        search={{ search, setSearch }}
        right={
          <>
            <ColumnCustomize maxValues={3} values={customColumns} onChange={setCustomColumns} />
            {isOwner && sortedEmployees && (
              <ExportData data={sortedEmployees.map(e => omit(e, ["id", "automations"]))} />
            )}
          </>
        }
      />
      <Loading
        data={sortedEmployees}
        loading={<Skeletons.List />}
        empty={
          search || employeeGroup ? (
            <Empty title="No matching employees" />
          ) : (
            <Empty
              title="No employees"
              icon="persons"
              message="Get started by creating an employee or set up an integration"
            />
          )
        }
      >
        <TableCard>
          <Table
            rowHeightRem={4}
            columns={formatColumns(
              ["name", ...customColumns, "automations"],
              employeeFields || [],
              automations || []
            )}
            data={sortedEmployees}
            onClick={({ id }, event) => {
              if (event?.ctrlKey || event?.metaKey) {
                window.open(`/employees/${id}`, "_blank");
              } else {
                navigate(`/employees/${id}`);
              }
            }}
          />
        </TableCard>
      </Loading>
    </Stack>
  );
};
