import { Card, H4, Icon, IconButton, Popover, Stack, Tooltip } from "@introist/react-foundation/v2";
import { fitTitle } from "components/atoms";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FolderIcon } from "../../assets";

export type Breadcrumb = {
  key: string;
  title: string;
  to?: string;
};

type Props = {
  breadcrumbs: Breadcrumb[];
};

const StyledBreadcrumbs = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--spacing-xSmall);

  li {
    display: flex;
    align-items: center;
    gap: var(--spacing-xSmall);
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  > h4 {
    opacity: 0.7;

    :hover {
      opacity: 1;
    }
  }
`;

const ConcatedBreadcrumbs = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-medium);
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Breadcrumbs = ({ breadcrumbs, ...rest }: Props) => {
  const getFittedTitle = (title: string) => {
    const { fittedTitle, hasBeenFitted } = fitTitle(title, 20, true);
    if (!hasBeenFitted) {
      return <H4>{title}</H4>;
    }
    return (
      <Tooltip tooltip={title}>
        <H4>{fittedTitle}</H4>
      </Tooltip>
    );
  };

  const renderBreadcrumb = (b: Breadcrumb, index: number, icon?: boolean) => {
    if (index === breadcrumbs.length - 1) {
      return (
        <li key={b.key}>
          <H4>{b.title}</H4>
        </li>
      );
    }

    if (!b.to) {
      return (
        <li key={b.key}>
          {getFittedTitle(b.title)}
          {icon && <Icon dimmed name="chevronRight" />}
        </li>
      );
    }

    return (
      <li key={b.key}>
        <StyledLink to={b.to!}>{getFittedTitle(b.title)}</StyledLink>
        {icon && <Icon dimmed name="chevronRight" />}
      </li>
    );
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const concatenatedCrumbsWrapperRef = useRef<HTMLLIElement | null>(null);

  return (
    <StyledBreadcrumbs {...rest}>
      {breadcrumbs.length > 3 ? (
        <>
          {/* Always render the first breadcrumb */}
          {renderBreadcrumb(breadcrumbs[0], 0)}
          <li ref={concatenatedCrumbsWrapperRef}>
            <Icon dimmed name="chevronRight" />
            <div onClick={() => setDropdownOpen(!dropdownOpen)}>
              <IconButton size="large" icon="more" />
            </div>
            {dropdownOpen && (
              <Popover
                closeOnContentClick
                referenceElement={concatenatedCrumbsWrapperRef.current}
                open={dropdownOpen}
                onClose={() => setDropdownOpen(false)}
              >
                <Card elevated>
                  <ConcatedBreadcrumbs>
                    {breadcrumbs.slice(1, -1).map((b, idx) => (
                      <Stack key={b.key} gap="small">
                        <FolderIcon width={16} />
                        {renderBreadcrumb(b, idx)}
                      </Stack>
                    ))}
                  </ConcatedBreadcrumbs>
                </Card>
              </Popover>
            )}
            <Icon dimmed name="chevronRight" />
          </li>
          {renderBreadcrumb(breadcrumbs[breadcrumbs.length - 1], breadcrumbs.length - 1)}
        </>
      ) : (
        breadcrumbs.map((b, idx) => renderBreadcrumb(b, idx, true))
      )}
    </StyledBreadcrumbs>
  );
};
