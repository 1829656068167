import { useState } from "react";
import { BaseModal, Button, H4, Layout, useToast } from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";

import { api } from "services/rpc/RpcProvider";
import { EmployeeFieldForm, EmployeeFieldFormValues } from "../components";

type Props = {
  onCreated: VoidFunction;
  group: { name: string; id: string } | null;
};

export const CreateCustomEmployeeField = ({ group, onCreated, ...rest }: Props) => {
  const toast = useToast();
  const { mutateAsync, isLoading } = api.employees.fields.create.useMutation({});

  const [values, setValues] = useState<EmployeeFieldFormValues>({
    name: ""
  });

  const isSelect = values.type === "select";

  const isCreateDisabled = () => {
    const isNameOrTypeEmpty = !values.name || !values.type;
    const isOptionsEmptyWhenSelect = isSelect && isEmpty(values.options);

    return isLoading || isNameOrTypeEmpty || isOptionsEmptyWhenSelect;
  };

  const onCreate = async () => {
    if (!values.name || !values.type) return;
    if (isSelect && isEmpty(values.options)) return;

    await mutateAsync(
      {
        groupId: group?.id,
        name: values.name,
        type: values.type,
        options: values.options
      },
      {
        onError: () => toast.error("Failed to create field"),
        onSuccess: () => {
          onCreated();
          toast.success("Field created");
          setValues({ name: "" });
        }
      }
    );
  };

  return (
    <Layout.Group vertical gap="xLarge" lastChildToEnd {...rest}>
      <EmployeeFieldForm values={values} onChange={setValues} />

      <Button disabled={isCreateDisabled()} onClickWithLoading={onCreate}>
        Create
      </Button>
    </Layout.Group>
  );
};

export const CreateCustomEmployeeFieldModal = ({
  open,
  onClose,
  ...rest
}: Props & {
  open: boolean;
  onClose: VoidFunction;
}) => {
  return (
    <BaseModal
      open={open}
      header={
        <H4>
          {!!rest.group
            ? `Add employee field to group: ${rest.group.name}`
            : "Create custom employee field"}
        </H4>
      }
      onClose={onClose}
      style={{ width: "100%", maxWidth: 640 }}
      closeOnOutsideClick
    >
      <CreateCustomEmployeeField {...rest} />
    </BaseModal>
  );
};
