import { useToast } from "@introist/react-foundation/v2";
import { useEventData } from "../routes/hooks";
import { api } from "services/rpc/RpcProvider";

export const useRescheduleCompanyEventAttendee = (companyEventId: string) => {
  const toast = useToast();
  const { mutateAsync: reschedule, isLoading: rescheduleAttendeeLoading } =
    api.companyEvents.attendees.reschedule.useMutation({});
  const { reload } = useEventData(companyEventId);

  const reinviteOp = api.companyEvents.attendees.reinvite.useMutation();

  const rescheduleAttendee = async (attendeeId: string, instanceId?: string) => {
    await reschedule(
      { attendeeId, instanceId },
      {
        onSuccess: () => {
          toast.success("Reschedule completed");
          reload();
        },
        onError: () => toast.error("Failed to reschedule event attendee")
      }
    );
  };

  const reinviteAttendee = async (attendeeId: string) => {
    await reinviteOp
      .mutateAsync({ attendeeId })
      .then(() => {
        toast.success("Invitation resent");
      })
      .catch(() => {
        toast.error("Failed to resend invitation");
      });
  };

  return {
    rescheduleAttendeeLoading,
    rescheduleAttendee,
    reinviteAttendee
  };
};
