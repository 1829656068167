import { Icon } from "@introist/react-foundation/v2";
import { FormsListRoute } from "./routes/FormsListRoute";
import { FormRoute } from "./routes/FormRoute";
import { PublicFormRoute } from "./routes/public/PublicFormRoute";

export const FORMS_ROUTES = {
  forms: {
    key: "route-forms",
    title: "Forms",
    icon: <Icon name="form" />,
    path: "/forms",
    element: <FormsListRoute />
  },
  form: {
    key: "route-form",
    title: "Form",
    path: "/forms/:id",
    element: <FormRoute />
  },
  formField: {
    key: "route-form-field",
    title: "Form",
    path: "/forms/:id/:fieldId",
    element: <FormRoute />
  },
  formResponse: {
    key: "route-form-response",
    title: "Form",
    path: "/forms/:id/responses/:responseId",
    element: <FormRoute />
  },
  publicForm: {
    key: "route-public-form",
    title: "Form",
    path: "/f/:id",
    element: <PublicFormRoute />,
    public: true
  }
};
