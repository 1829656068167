import { Icon, Select, SelectProps } from "@introist/react-foundation/v2";
import { useAttributes } from "hooks/use-attributes";
import { useMemo } from "react";

type Props = Omit<SelectProps, "options">;

export const TargetDateSelect = (props: Props) => {
  const attributes = useAttributes();

  const targetDateOptions = useMemo(() => {
    const attributeOptions = attributes
      .filter(a => a.type === "date")
      .map(a => ({ key: a.variable, title: a.name }));

    return [{ key: "immediately", title: "When enrolled" }, ...attributeOptions];
  }, [attributes]);

  return (
    <Select
      element="button"
      variant="outlined"
      startAdornment={<Icon name="calendarTarget" />}
      options={targetDateOptions}
      {...props}
    />
  );
};
