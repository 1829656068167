import { ReadOnlyStepProps } from "../useStepEditor";
import { Field, Input, TextArea, Title } from "@introist/react-foundation/v2";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";

export const JiraIssueStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const { datasourceName, projectName, issueTypeName, resolvedSummary, resolvedDescription } =
    step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Target" defaultOpen={false} description={projectName}>
          <StepEditorAccordion.Row label="Integration">
            <Title variant="bold">{datasourceName}</Title>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Project">
            <Title variant="bold">{projectName}</Title>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Issue type">
            <Title variant="bold">{issueTypeName}</Title>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Issue">
          <Field title="Summary">
            <Input
              value={resolvedSummary}
              size="small"
              variant="blended"
              readOnly
              style={{ background: "var(--palette-surface-subdued)" }}
            />
          </Field>
          <Field title="Description">
            <TextArea
              value={resolvedDescription}
              rows={10}
              variant="blended"
              style={{ background: "var(--palette-surface-subdued)" }}
              readOnly
            />
          </Field>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
