import { Button, Modal, Stack } from "@introist/react-foundation/v2";
import { FieldMappingBuilder } from "./FieldMappingBuilder";
import { FieldMappingProps } from "./index";

export const FieldMappingModal = ({
  open,
  onClose,
  ...rest
}: { open: boolean; onClose: () => void } & FieldMappingProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Map fields"
      maxWidth="700px"
      style={{ width: "700px" }}
    >
      {open && <FieldMappingBuilder {...rest} />}
      <Stack justifyContent="flex-end">
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Modal>
  );
};
