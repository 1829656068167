import { Nudge } from "../../useNudges";
import { Card, Stack, Title } from "@introist/react-foundation/v2";
import { api } from "../../../../services/rpc/RpcProvider";
import { useMemo } from "react";
import { Skeletons } from "../../../../components/molecules";

export const NudgePreview = ({ nudge }: { nudge: Nudge }) => {
  const { data: preview } = api.triggers.preview.useQuery({ id: nudge.triggerId });

  const pending = useMemo(() => {
    if (!preview) return undefined;
    return preview.filter(p => p.matchStatus === "pending");
  }, [preview]);

  return (
    <Stack vertical style={{ width: "400px", minHeight: "300px" }}>
      <Title>This nudge will run for the following employees when activated.</Title>
      <Stack vertical gap="small">
        {pending?.map(i => (
          <Card onClick={() => window.open(`/employees/${i.employee.id}#fields`, "_blank")}>
            <Title variant="bold">{i.employee.name}</Title>
          </Card>
        ))}
      </Stack>

      {pending && pending.length === 0 && (
        <Card variant="blended">
          <Stack justifyContent="center">
            <Title variant="bold">No matching employees</Title>
          </Stack>
        </Card>
      )}
      {!pending && <Skeletons.List rowCount={3} />}
    </Stack>
  );
};
