import React, { useState } from "react";

import { ReactComponent as GifIcon } from "./GifIcon.svg";
import { Card, Input, Popover, useTheme } from "@introist/react-foundation/v2";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { config } from "../../../Config";

export const GifSelector = ({ onInsert }: { onInsert: (item: any) => boolean | undefined }) => {
  const { theme } = useTheme();

  const [giphyRef, setGiphyRef] = useState<HTMLElement | null>(null);
  const [giphyOpen, setGiphyOpen] = useState(false);

  return (
    <>
      <button ref={setGiphyRef} className="ql-giphy" onClick={() => setGiphyOpen(!giphyOpen)}>
        <GifIcon />
      </button>
      <Popover
        referenceElement={giphyRef}
        open={giphyOpen}
        onClose={() => setGiphyOpen(false)}
        closeOnContentClick={false}
      >
        <Card style={{ padding: 0 }}>
          <ReactGiphySearchbox
            apiKey={config.giphyApiKey}
            wrapperClassName="giphy-select"
            onSelect={(item: any) => {
              const close = onInsert(item);
              if (close) setGiphyOpen(false);
            }}
          />
          <Input
            type="text"
            size="small"
            placeholder="Add custom gif by url"
            style={{ margin: theme.spacing.small }}
            onKeyUp={event => {
              if (event.key === "Enter") {
                const close = onInsert(event.currentTarget.value);
                if (close) setGiphyOpen(false);
              }
            }}
          />
        </Card>
      </Popover>
    </>
  );
};
