import { IconName, CircularLoader, Icon, Stack } from "@introist/react-foundation/v2";
import { AccountsActions } from "./AccountsActions";
import { AccountsHeader } from "./AccountsHeader";
import { AccountsList } from "./AccountsList";
import { ConnectedAccount } from "services/rpc/RpcProvider";
import { ListSection } from "components/organisms";
import { ReactNode } from "react";

interface Props {
  accounts: ConnectedAccount[];
  iconName?: IconName;
  icon?: ReactNode;
  title: string;
  description: string;
  provider: string;
  disableConnectionLink?: boolean;
  isLoading?: boolean;
}

export const AccountsRoot = ({
  accounts,
  iconName,
  icon,
  title,
  description,
  provider,
  disableConnectionLink,
  isLoading = false
}: Props) => {
  return (
    <Stack vertical gap="xxLarge">
      <AccountsHeader description={description} />
      {isLoading ? (
        <Stack justifyContent="center">
          <CircularLoader />
        </Stack>
      ) : (
        <ListSection
          title={
            <Stack>
              {iconName && <Icon name={iconName} />}
              {icon}
              <span>{title}</span>
            </Stack>
          }
          endActions={
            <AccountsActions provider={provider} disableConnectionLink={disableConnectionLink} />
          }
        >
          <AccountsList accounts={accounts} />
        </ListSection>
      )}
    </Stack>
  );
};
