import { useEffect } from "react";

import {
  Modal,
  Title,
  Select,
  useToast,
  useForm,
  Card,
  Layout
} from "@introist/react-foundation/v2";

import { SlackSender, findSlackSender, useSlackSenderApi } from "services/api/SlackSenderApi";
import { TextInput } from "components/atoms";
import styled from "styled-components";
import { ImageUploadArea } from "components/molecules";

export interface SlackBotModalProps {
  senderId?: string;
  open: boolean;
  onCreated: (createdBot: SlackSender) => unknown;
  onUpdated: (updatedBot: SlackSender) => unknown;
  onClose: () => unknown;
}

const SendingAccountCard = styled(Card)`
  && {
    padding: var(--spacing-xLarge);
    background-color: var(--palette-surface-ghosted);
  }

  > :first-child {
    margin-bottom: var(--spacing-xSmall);
  }

  > :last-child {
    margin-top: var(--spacing-xLarge);
  }
`;

export const SlackBotModalV2 = ({
  open,
  onCreated,
  onUpdated,
  onClose,
  senderId
}: SlackBotModalProps) => {
  const toast = useToast();

  const slackSenderApi = useSlackSenderApi();
  const { data: senders } = slackSenderApi.list().query;

  const createBot = slackSenderApi.createBot();
  const updateBot = slackSenderApi.updateSender();

  const { setValue, edited, ...form } = useForm();

  const senderOptions = senders
    ?.filter(sender => !sender.isBot)
    .map(sender => ({ key: sender.id, title: sender.name }));

  useEffect(() => {
    if (senderId) {
      findSlackSender({ id: senderId })
        .then(sender =>
          setValue({ name: sender.name, iconUrl: sender.iconUrl, ownerId: sender.ownerId })
        )
        .catch(() => toast.error("Failed to load slack bot"));
    } else setValue({});
  }, [senderId, setValue, toast]);

  const onCreateBot = async () => {
    await createBot.mutate(form.data, {
      onSuccess: created => {
        onCreated(created);
        toast.success("New bot created!");
        onClose();
      },
      onError: error => {
        toast.error("Bot creation failed");
      }
    });
  };

  const onUpdateBot = async () => {
    const { name, iconUrl, ownerId } = form.data;

    await updateBot.mutate(
      { senderId: senderId!, update: { name, iconUrl, ownerId } },
      {
        onSuccess: updated => {
          onUpdated(updated);
          toast.success("Slack bot updated");
          onClose();
        },
        onError: () => {
          toast.error("Update failed");
        }
      }
    );
  };

  const onSave = async () => {
    if (!senderId) await onCreateBot();
    else await onUpdateBot();
  };

  return (
    <Modal
      open={open}
      primaryButton={{ title: "Save bot", onClickWithLoading: onSave }}
      title={senderId ? "Edit Slack bot" : "Add new Slack bot"}
      onClose={onClose}
      onCloseWithOutsideClick={!edited ? onClose : undefined}
      backdropElevation="jupiter"
      maxWidth={540}
    >
      <Layout.Group vertical gap="xLarge">
        <Layout.Group gap="xxLarge">
          <ImageUploadArea
            icon="robot"
            onImageUploaded={form.set("iconUrl")}
            imageUrl={form.get("iconUrl")}
          />
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">Add your bot icon</Title>
            <Title>We recommend 248x248px size.</Title>
          </Layout.Group>
        </Layout.Group>
        <TextInput
          label="Bot name"
          value={form.get("name")}
          onChange={form.set("name")}
          placeholder="e.g. Introist Bot"
        />
        <SendingAccountCard>
          <Title variant="bold">Sending account</Title>
          <Title>
            The bot must be linked to an existing Slack user in order to send messages. Only the bot
            will be visible to recipients.
          </Title>
          <Select
            style={{ width: "100%" }}
            options={senderOptions || []}
            value={form.get("ownerId")}
            onSelect={option => form.set("ownerId")(option.key)}
            placeholder="Select account"
          />
        </SendingAccountCard>
      </Layout.Group>
    </Modal>
  );
};
