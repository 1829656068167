import { H3, Stack } from "@introist/react-foundation/v2";
import { Avatar } from "../../../../components/molecules";
import { Employee } from "../../hooks/useEmployees";
import { useMemo } from "react";

type EmployeeHeaderProps = {
  employee: Employee;
};

export const EmployeeHeader = ({ employee }: EmployeeHeaderProps) => {
  const name = useMemo(() => {
    return employee.name ?? `${employee.firstname ?? ""} ${employee.lastname ?? ""}`;
  }, [employee]);

  return (
    <Stack>
      <Avatar nameOrEmail={name} size="huge" />
      <H3>{name}</H3>
    </Stack>
  );
};
