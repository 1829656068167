import { useEffect, useState } from "react";
import styled from "styled-components";
import { Card, Layout } from "@introist/react-foundation/v2";

import { IntroistUser } from "IdentityProvider";
import { loadUser } from "services/api/UserApi";
import { api } from "services/rpc/RpcProvider";

import { NotificationFrequency, NotifyAbout } from "./components";
import { NotificationSettingsContextProvider } from "./context";

// @ts-ignore
const StyledCard = styled(Card)`
  padding: var(--spacing-xLarge);
`;

export const NotificationSettingsRoute = ({ ...rest }) => {
  const [user, setUser] = useState<IntroistUser | null>(null);

  const { data: schemes, refetch: refetchSchemes } = api.notifications.schemes.list.useQuery({});

  useEffect(() => {
    loadUser().then(setUser);
  }, []);

  const { data: organisation } = api.workspaces.find.useQuery({});

  if (!user) return null;
  if (!schemes) return null;
  if (!organisation) return null;

  return (
    <Layout.Group {...rest} vertical gap="xLarge" style={{ maxWidth: "760px", margin: "0 auto" }}>
      <NotificationSettingsContextProvider
        schemes={schemes}
        refetchSchemes={refetchSchemes}
        user={user}
        organisation={organisation}
      >
        <StyledCard>
          <NotifyAbout />
        </StyledCard>

        <StyledCard>
          <Layout.Group vertical gap="xLarge">
            <NotificationFrequency />
          </Layout.Group>
        </StyledCard>
      </NotificationSettingsContextProvider>
    </Layout.Group>
  );
};
