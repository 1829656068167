import { OptionList, Popover, buildOptions, useResponsive } from "@introist/react-foundation/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { useRef, useState } from "react";
import styled from "styled-components";

type TimePickerProps = {
  value: string;
  buttonText: string;
  onChange: ({ time }: { time: string }) => void;
};

const StyledTimePicker = styled.div``;

const TIME_PICKER_OPTIONS = buildOptions({ start: "00:00", end: "23:59" }, 15);

export const TimePicker = ({ value, buttonText, onChange, ...rest }: TimePickerProps) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { isMobileLayout } = useResponsive();

  const selectedOption = TIME_PICKER_OPTIONS.find(option => option.key === value);

  return (
    <StyledTimePicker {...rest}>
      <IconToggleButton
        startIcon="time"
        active={open}
        ref={buttonRef}
        onClick={() => setOpen(!open)}
      >
        {buttonText}
      </IconToggleButton>
      <Popover
        open={open}
        referenceElement={buttonRef.current}
        placement={isMobileLayout ? "bottom-start" : "left-start"}
        sameWidthWithReference={isMobileLayout}
        onClose={() => setOpen(false)}
      >
        <OptionList
          searchable
          scrollToSelected
          searchPlaceholder="Search"
          selected={selectedOption?.key}
          options={TIME_PICKER_OPTIONS}
          onItemClick={({ key }) => {
            onChange({ time: key });
            setOpen(false);
          }}
          style={{
            maxWidth: 120
          }}
        />
      </Popover>
    </StyledTimePicker>
  );
};
