import { memo, useRef, useState } from "react";
import styled from "styled-components";

import {
  ErrorMessage,
  OptionListProps,
  Tooltip,
  Layout,
  useResponsive
} from "@introist/react-foundation/v2";

import { ParticipantCard } from "../ParticipantCard";
import { IconToggleButton } from "../IconToggleButton";
import { PortalOptionListMultiSelect, useMultiSelectOptionList } from "../PortalOptionList";
import { TOption } from "../PortalOptionList/types";

type EmailAddressMultiSelectProps = Pick<OptionListProps, "actions"> & {
  readOnly?: boolean;
  initialOptions: TOption[];
  initialSelectedOptions?: TOption[];
  addButtonText?: string;
  addButtonReadOnlyTooltip?: string;
  error?: string;
  width?: number;
  onChange?: (selectedIds: string[]) => void;
  onEnter?: (search: string) => void;
  isJourneyEditor?: boolean;
};

export const ButtonRow = styled(Layout.Group)`
  position: relative;
`;

export const ClickBox = styled.div`
  opacity: 0;
  width: calc(100% - 8.875rem);
  top: 0;
  left: 8.875rem;
  bottom: 0;
  position: absolute;
`;

export const EmailAddressMultiSelect = memo(
  ({
    initialOptions,
    initialSelectedOptions,
    addButtonText = "Add",
    error,
    readOnly = false,
    addButtonReadOnlyTooltip,
    isJourneyEditor,
    actions,
    width,
    onChange,
    onEnter,
    ...rest
  }: EmailAddressMultiSelectProps) => {
    const [optionListOpen, setOptionListOpen] = useState(false);

    const { isMobileLayout } = useResponsive();

    const anchorRef = useRef<HTMLDivElement | null>(null);

    const { options, selectedOptions, handleAddOption, handleOptionClick, handleRemoveOption } =
      useMultiSelectOptionList({
        initialOptions,
        initialSelectedOptions,
        onChange: selectedIds => {
          onChange!(selectedIds);
        },
        onEnter
      });

    const toggleOptionListOpen = () => {
      setOptionListOpen(!optionListOpen);
    };

    const getAddButton = () => (
      <IconToggleButton
        disabled={readOnly}
        startIcon="plus"
        active={!!optionListOpen}
        destructive={!!error}
        onClick={() => setOptionListOpen(!optionListOpen)}
      >
        {addButtonText}
      </IconToggleButton>
    );

    return (
      <Layout.Group {...rest} vertical gap="small" ref={anchorRef}>
        {selectedOptions.length > 0 &&
          selectedOptions.map(({ key, title, description }) => (
            <ParticipantCard
              isJourneyEditor={isJourneyEditor}
              readOnly={readOnly}
              key={key}
              onClick={toggleOptionListOpen}
              title={title}
              description={description}
              onRemove={() => {
                handleRemoveOption(key);
                if (optionListOpen) {
                  setOptionListOpen(false);
                }
              }}
            />
          ))}

        {!readOnly && !!onChange && (
          <>
            <ButtonRow>
              {addButtonReadOnlyTooltip ? (
                <Tooltip tooltip={addButtonReadOnlyTooltip}>{getAddButton()}</Tooltip>
              ) : (
                getAddButton()
              )}

              {error && <ErrorMessage>{error}</ErrorMessage>}
              <ClickBox onClick={() => setOptionListOpen(false)} />
            </ButtonRow>
            <PortalOptionListMultiSelect
              open={optionListOpen}
              referenceElement={anchorRef.current}
              options={options}
              actions={actions}
              width={width}
              onOptionClick={handleOptionClick}
              selectedKeys={selectedOptions.map(({ key }) => key)}
              onEnter={handleAddOption}
              onClose={() => setOptionListOpen(false)}
              placement={isMobileLayout ? "bottom-start" : "left-start"}
              sameWidthWithReference={isMobileLayout}
            />
          </>
        )}
      </Layout.Group>
    );
  }
);
