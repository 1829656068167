import { useCallback, useEffect, useState } from "react";
import { Title, Button, Layout, useToast } from "@introist/react-foundation/v2";

import { TextInput } from "components/atoms";
import { updateIntegrationConfiguration, useIntegrationsApi } from "services/api/IntegrationsApi";

type JiraConfigurationContainerProps = {
  integrationId: string;
  integrationDomainSuffix?: string;
};
type Configuration = {
  domain: string;
  userEmail: string;
};

export const JiraConfigurationContainer = ({ integrationId }: JiraConfigurationContainerProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });
  const { data: integration } = findIntegration.query;

  const [domain, setDomain] = useState("");
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    setDomain((integration?.configuration as Configuration)?.domain ?? "");
    setUserEmail((integration?.configuration as Configuration)?.userEmail ?? "");
  }, [integration]);

  const onSave = useCallback(async () => {
    await updateIntegrationConfiguration({ integrationId, configuration: { domain, userEmail } })
      .then(async () => {
        await findIntegration.refetch();
        toast.success("Configuration updated");
      })
      .catch(() => toast.error("Failed to update configuration"));
  }, [integrationId, domain, findIntegration, toast, userEmail]);

  return (
    <Layout.Group vertical lastChildToEnd gap="xLarge">
      <TextInput
        label="API token user email"
        type="text"
        value={userEmail}
        onChange={setUserEmail}
      />
      <TextInput
        label={`${integration?.name} domain`}
        type="text"
        value={domain}
        onChange={setDomain}
        endAdornment={<Title>.atlassian.net</Title>}
      />

      <Button onClickWithLoading={onSave}>Save</Button>
    </Layout.Group>
  );
};
