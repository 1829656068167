import { useState } from "react";
import { Input, Layout, Title } from "@introist/react-foundation/v2";
import { ToggleSettingCard } from "components/organisms";
import { getEventSettingsMetadata } from "../util";
import { useUpdateCompanyEventSetting } from "../hooks";
import { EventSettingProps } from ".";

export const RescheduleAttendeeOnWaiting = ({ setting, readOnly }: EventSettingProps) => {
  const metadata = getEventSettingsMetadata(setting.setting);
  const [active, setActive] = useState(setting.active);
  // @ts-expect-error
  const [daysBefore, setDaysBefore] = useState(setting.conf.daysBefore);
  const { updateEventSetting } = useUpdateCompanyEventSetting({
    setting,
    config: { enabled: !readOnly }
  });

  return (
    <ToggleSettingCard
      autoCollapse
      readOnly={readOnly}
      key={setting.setting}
      title={metadata.title}
      description={metadata.description}
      active={active}
      onToggleActive={active => {
        setActive(active);
        updateEventSetting({ active }, () => setActive(setting.active));
      }}
    >
      <Layout.Group>
        <Input
          type="number"
          value={daysBefore}
          min={0}
          size="small"
          style={{ maxWidth: 48 }}
          onChange={value => {
            setDaysBefore(Number(value));
            updateEventSetting({ conf: { daysBefore: Number(value) } }, () =>
              // @ts-expect-error
              setDaysBefore(setting.conf.daysBefore)
            );
          }}
        />
        <Title>days before event date</Title>
      </Layout.Group>
    </ToggleSettingCard>
  );
};
