import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { IconName } from "@introist/react-foundation/v2";
import { a, useSpring } from "@react-spring/web";

import { Tab } from ".";

export type TabBarTab = {
  id: string;
  title?: ReactNode;
  iconName?: IconName;
};

export interface TabBarProps {
  tabs: string[] | TabBarTab[];
  activeTab?: string;
  onTabChange?: (tab: string) => unknown;
  dottedTabs?: string[];
  small?: boolean;
}

const TabHighlight = styled(a.div)`
  position: absolute;
  top: 0.25rem;
  bottom: 0.25rem;
  background-color: white;
`;

const StyledTabBar = styled.div<{ $tabCount: number; $small?: boolean }>`
  display: grid;
  grid-template-columns: ${({ $tabCount }) => `repeat(${$tabCount}, 1fr)`};
  background-color: var(--palette-surface-default);
  border-radius: var(--rounding-medium);
  transition: none;
  position: relative;
  height: ${p => (p.$small ? "30px" : "2.5rem")};
  padding: ${p => (p.$small ? "0 0.25rem" : "0 0.25rem")};
  align-items: center;
  overflow: hidden;

  ${TabHighlight} {
    border-radius: ${p => (p.$small ? "var(--rounding-small)" : "inherit")};
  }
`;

const TabWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  cursor: pointer;
  text-align: center;
`;

export const TabBar = ({
  tabs,
  small,
  activeTab,
  dottedTabs,
  onTabChange,
  ...rest
}: TabBarProps) => {
  const [activeTabWidth, setActiveTabWidth] = useState(0);
  const [activeTabLeft, setActiveTabLeft] = useState(0);
  const tabRefs = useRef(new Map<string, HTMLDivElement>());
  const immediateRef = useRef(true);

  useEffect(() => {
    if (!activeTab) {
      return;
    }
    const activeTabRef = tabRefs.current.get(activeTab);
    if (activeTabRef) {
      setActiveTabWidth(activeTabRef.offsetWidth);
      setActiveTabLeft(activeTabRef.offsetLeft);
    }
  }, [activeTab, tabRefs, setActiveTabWidth]);

  const normalizedTabs: TabBarTab[] = useMemo(() => {
    // @ts-ignore
    return tabs.map((tab: string | TabBarTab) => {
      if (typeof tab === "string") {
        return { id: tab, title: tab } as TabBarTab;
      }
      return tab as TabBarTab;
    });
  }, [tabs]);

  const spring = useSpring({
    width: activeTabWidth,
    left: activeTabLeft,
    config: {
      tension: 240,
      precision: 0.053
    },
    immediate: immediateRef.current
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      immediateRef.current = false;
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <StyledTabBar {...rest} $tabCount={normalizedTabs.length} $small={small}>
      {normalizedTabs.map(tab => (
        <TabWrapper
          key={`tab-bar-tab-${tab.id}`}
          ref={el => el && tabRefs.current.set(tab.id, el)}
          onClick={() => onTabChange && onTabChange(tab.id)}
        >
          <Tab
            dotted={dottedTabs?.includes(tab.id)}
            active={activeTab === tab.id}
            title={tab.title}
            iconName={tab.iconName}
          />
        </TabWrapper>
      ))}
      <TabHighlight style={spring} />
    </StyledTabBar>
  );
};
