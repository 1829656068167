import { DatasetsRoute } from "./routes/DatasetsRoute";
import { DatasetRoute } from "./routes/DatasetRoute";
import { RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";

export const DATASET_ROUTES = {
  datasets: {
    key: "datasets",
    path: "/datasets",
    title: "Datasets",
    element: <DatasetsRoute />
  },
  dataset: {
    key: "dataset",
    path: "/datasets/:id",
    title: "Dataset",
    element: <DatasetRoute />
  }
};

export type DatasetField = RouterOutput["datasets"]["fields"]["find"];
export type DatasetFieldCreate = RouterInput["datasets"]["fields"]["create"];
export type DatasetFieldUpdate = RouterInput["datasets"]["fields"]["update"];
