import { Stack, Card, Title, ActionMenu, useActions } from "@introist/react-foundation/v2";
import { BatchCreator, useBatchCreatorActions, useBatchCreators } from "../../useBatchCreators";
import { IconPickerIcon } from "../../../../components/organisms";
import { UpsertModal } from "../../../../foundation";
import { BatchCreatorForm } from "./CreatorForm";
import { useState } from "react";
import { FittedTitle } from "../../../../components/atoms";
import { CreatorHistory } from "./CreatorHistory";

export const BatchCreatorsList = () => {
  const { onConfirmAction } = useActions();
  const { creators } = useBatchCreators();

  const { update, remove } = useBatchCreatorActions();

  const [edit, setEdit] = useState<BatchCreator | undefined>();

  const [selectedCreator, setSelectedCreator] = useState<string | undefined>();

  if (!creators || creators.length === 0) return null;

  const onRemove = onConfirmAction(
    async (id: string) => {
      await remove(id);
    },
    {
      title: "Confirm delete",
      description: "Are you sure to delete this batch creator?"
    }
  );

  return (
    <Stack>
      {creators.map(c => (
        <Card key={c.id} style={{ width: "300px" }}>
          <Stack>
            <IconPickerIcon fallbackIcon="featureRoutines" />
            <Stack vertical gap="xSmall">
              <Stack>
                <Title variant="bold">{c.title}</Title>
              </Stack>
              {c.description && (
                <FittedTitle maxLength={20} showTooltip>
                  {c.description}
                </FittedTitle>
              )}
            </Stack>
            <ActionMenu
              style={{ marginLeft: "auto" }}
              options={[
                {
                  key: "edit",
                  title: "Edit",
                  startAdornmentIcon: "pencil",
                  onClick: () => setEdit(c)
                },
                {
                  key: "history",
                  title: "History",
                  startAdornmentIcon: "time",
                  onClick: () => setSelectedCreator(c.id)
                },
                {
                  key: "delete",
                  title: "Delete",
                  startAdornmentIcon: "trash",
                  onClick: () => onRemove(c.id)
                }
              ]}
            />
          </Stack>
        </Card>
      ))}
      <UpsertModal
        open={!!edit}
        entry={edit}
        onClose={() => setEdit(undefined)}
        onCreate={() => {
          throw new Error("Not implemented");
        }}
        onUpdate={async entry => {
          await update({ id: edit!.id, update: entry }).then(() => setEdit(undefined));
        }}
        Form={BatchCreatorForm}
        entryName="Batch Creator"
      />
      <CreatorHistory
        creatorId={selectedCreator}
        open={!!selectedCreator}
        onClose={() => setSelectedCreator(undefined)}
      />
    </Stack>
  );
};
