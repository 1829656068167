import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import styles from "./HtmlEditorPowerUps.module.scss";
import { AttributeSelector } from "modules/workflows/routes/WorkflowEditor/StepEditor/AttributeSelector";

interface Props {
  onAttributeSelected: (attribute: EmployeeAttribute) => unknown;
  disableAttributeSelection?: boolean;
  readonly?: boolean;
}

export const HtmlEditorPowerUps = ({
  onAttributeSelected,
  disableAttributeSelection = false,
  readonly = false
}: Props) => {
  return (
    <div className={styles.HtmlEditorPowerUps}>
      {!disableAttributeSelection && !readonly && (
        <AttributeSelector onAttributeSelected={onAttributeSelected} />
      )}
    </div>
  );
};
