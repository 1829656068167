import { IconToggleButton } from "../../../workflows/components/IconToggleButton";
import { CardPopover } from "../../../workflows/routes/WorkflowEditor/CardPopover";
import { TriggerDayPicker } from "../../../../components/molecules";
import { useRef, useState } from "react";
import { stringifyTrigger } from "../../../../utils/Text";
import { StepTrigger } from "../../../../services/api/WorkflowApi";

export type TriggerPickerProps = {
  value: StepTrigger;
  onChange: (trigger: StepTrigger) => void;
  suffix: string;
};

export const TriggerPicker = ({ value, onChange, suffix }: TriggerPickerProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconToggleButton
        startIcon="calendar"
        ref={buttonRef}
        active={open}
        onClick={() => setOpen(!open)}
      >
        {stringifyTrigger(value, suffix !== undefined, false, suffix)}
      </IconToggleButton>
      <CardPopover
        open={open}
        referenceElement={buttonRef.current}
        onClose={() => {
          setOpen(false);
        }}
      >
        <TriggerDayPicker
          vertical
          onChange={onChange}
          trigger={value}
          suffix={suffix}
          immediatelyOnly={suffix === "enroll date"}
        />
      </CardPopover>
    </>
  );
};
