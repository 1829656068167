import { useEmailSenderApi } from "../services/api/Senders";
import sortBy from "lodash.sortby";
import { useMemo } from "react";

export type Sender = {
  id: string;
  name: string;
  email?: string;
  // emailProvider
  description?: string;
};
export interface EmailSenderHook {
  senders: Sender[];
  isLoading: boolean;
}

export const useEmailSenders = (filterCalendarOnlySenders = true): EmailSenderHook => {
  const emailSenderApi = useEmailSenderApi();
  const { data: senders, isLoading: sendersLoading } = emailSenderApi.list({
    filterCalendarOnlySenders
  }).query;

  const senderOptions = useMemo(() => {
    return (senders || []).map(sender => ({
      id: sender.id,
      name: sender.name,
      description: sender.emailProvider,
      email: sender.email
    }));
  }, [senders]);

  return {
    senders: sortBy(senderOptions, "name"),
    isLoading: sendersLoading
  };
};
