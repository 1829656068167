import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

export type BatchCreator = RouterOutput["batches"]["creators"]["find"];

export type BatchCreatorCreate = RouterInput["batches"]["creators"]["create"];
export type BatchCreatorUpdate = RouterInput["batches"]["creators"]["update"];

export const useBatchCreators = () => {
  const { data: creators } = api.batches.creators.list.useQuery({});

  return { creators };
};

export const useBatchCreator = (id: string, options?: any) => {
  const { data: history } = api.batches.creators.history.useQuery({ id }, options);

  return { history };
};

export const useBatchCreatorActions = () => {
  const toast = useToast();
  const apiUtils = api.useContext();

  const listOp = apiUtils.batches.creators.list;

  const createOp = api.batches.creators.create.useMutation();
  const updateOp = api.batches.creators.update.useMutation();
  const deleteOp = api.batches.creators.delete.useMutation();

  const create = useCallback(
    async (req: BatchCreatorCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch();
          return created;
        })
        .catch(e => {
          toast.error("Failed to create Batch Creator");
          throw e;
        });
    },
    [createOp, listOp, toast]
  );

  const update = useCallback(
    async (req: BatchCreatorUpdate) => {
      return updateOp
        .mutateAsync(req)
        .then(async updated => {
          await listOp.refetch();
          return updated;
        })
        .catch(e => {
          toast.error("Failed to update Batch Creator");
          throw e;
        });
    },
    [updateOp, listOp, toast]
  );

  const remove = useCallback(
    async (id: string) => {
      await deleteOp
        .mutateAsync({ id })
        .then(async () => {
          await listOp.refetch();
          toast.success("Batch Creator deleted");
        })
        .catch(() => {
          toast.error("Failed to delete Batch Creator");
        });
    },
    [deleteOp, toast, listOp]
  );

  return { create, update, remove };
};
