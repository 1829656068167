const WORKSPACE_ID_SESSION_KEY = "intr.ws";
export const clearSelectedWorkspace = () => {
  localStorage.removeItem(WORKSPACE_ID_SESSION_KEY);
};

export const setSelectedWorkspace = (workspaceId: string) => {
  localStorage.setItem(WORKSPACE_ID_SESSION_KEY, workspaceId);
};

export const getSelectedWorkspace = () => {
  return localStorage.getItem(WORKSPACE_ID_SESSION_KEY);
};
