import styled, { css } from "styled-components";

import { IconTagVariants, IconTag, useTheme } from "@introist/react-foundation/v2";
import {
  SlackIcon,
  ZendeskIcon,
  FreshServiceIcon,
  TrelloIcon,
  WebhookActionIcon,
  JiraIcon
} from "assets";

import BambooLogo from "assets/bamboohr.png";
import { useStepType } from "../../../../flows/stepTypes";

type StepTypeIconProps = {
  stepType: string;
  variant?: IconTagVariants;
  compact?: boolean;
};

const StyledStepTypeIcon = styled.span<{ compact: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ compact }) => (compact ? "auto" : "2.25rem")};
  height: ${({ compact }) => (compact ? "auto" : "2.25rem")};

  ${({ compact }) =>
    compact &&
    css`
      // IconTag div
      > div {
        width: auto;
        height: auto;
      }
    `}

  > img {
    width: 1rem;
  }
`;

const useStepTypeIcon = (stepType: string, variant?: IconTagVariants) => {
  const { theme } = useTheme();

  switch (stepType) {
    case "individual-event":
      return <IconTag color={theme.palette.success} name="calendarAdd" variant={variant} />;
    case "event":
    case "common-event":
      return <IconTag color={theme.palette.success} name="calendarShared" variant={variant} />;
    case "meeting":
      return <IconTag color={theme.palette.success} name="calendarAdd" variant={variant} />;
    case "webhook":
      return <WebhookActionIcon />;
    case "slack":
      return <SlackIcon />;
    case "enroll":
      return <IconTag name="trigger" color={theme.palette.primary} variant={variant} />;
    case "email":
      return <IconTag name="email" color={theme.palette.primary} variant={variant} />;
    case "invite":
      return <IconTag name="calendarCheck" color={theme.palette.success} variant={variant} />;
    case "zendesk":
      return <ZendeskIcon />;
    case "freshservice":
    case "freshserviceonboarding":
      return <FreshServiceIcon />;
    case "trelloboard":
      return <TrelloIcon />;
    case "creategoogleaccount":
      return <IconTag name="google" color={theme.palette.primary} variant={variant} />;
    case "creategoogle":
      return <IconTag name="google" color={theme.palette.primary} variant={variant} />;
    case "emailform":
      return <IconTag name="stepType" color={theme.palette.primary} variant={variant} />;
    case "emailtask":
      return <IconTag name="checkRectangle" color={theme.palette.primary} variant={variant} />;
    case "slacktask":
      return <SlackIcon />;
    case "slackchannelinvite":
      return <SlackIcon />;
    case "bamboohrcreate":
      return <img src={BambooLogo} alt="BambooHR" />;
    case "jira":
      return <JiraIcon />;
    case "eventparticipation":
      return <IconTag color={theme.palette.primary} name="calendarAttribute" variant={variant} />;
    case "eventattend":
      return <IconTag color={theme.palette.purple} name="events" variant={variant} />;
    case "slackquestion":
      return <SlackIcon />;
    case "employeeselect":
      return <IconTag name="personSync" color={theme.palette.primary} variant={variant} />;
    case "workflowtrigger":
      return <IconTag name="trigger" color={theme.palette.primary} variant={variant} />;
    case "createaccount":
      return <IconTag name="personAdd" color={theme.palette.primary} variant={variant} />;
    default:
      return <IconTag name="questionMark" color={theme.palette.surface} variant={variant} />;
  }
};

export const StepTypeIcon = ({
  stepType,
  variant,
  compact = false,
  ...rest
}: StepTypeIconProps) => {
  const icon = useStepTypeIcon(stepType, variant);
  const wfStepType = useStepType(stepType);

  if (wfStepType && wfStepType.icon) return wfStepType.icon;

  return (
    <StyledStepTypeIcon {...rest} compact={compact}>
      {icon}
    </StyledStepTypeIcon>
  );
};
