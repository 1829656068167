import { ReadOnlyStepProps } from "../useStepEditor";
import { Block, FittedTitle } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Stack, Title } from "@introist/react-foundation/v2";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Fragment } from "react";

export const CompletedGoogleSheetRow = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Result">
          <Title>
            Added a row with the following data to sheet <strong>{resolvedData.sheetName}</strong>{" "}
            of file <strong>{resolvedData.fileName}</strong>.
          </Title>
          <Stack vertical style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
            {resolvedData.resolvedFields.map((f: any) => (
              <Fragment key={f.id}>
                <FittedTitle maxLength={40} showTooltip style={{ flex: 1 }}>
                  {f.targetField}
                </FittedTitle>
                <FittedTitle maxLength={40} showTooltip variant="bold" style={{ flex: 2 }}>
                  {f.value}
                </FittedTitle>
              </Fragment>
            ))}
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
