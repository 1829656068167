import styled from "styled-components";
import { EmployeeFieldListProps } from "./EmployeeFieldList";
import { useRef, useState } from "react";
import {
  Button,
  Icon,
  Layout,
  ListSearchInput,
  Option,
  Stack,
  Tag,
  Title
} from "@introist/react-foundation/v2";
import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";
import { CategorisedOption, CategorySelect } from "components/organisms";
import { capitalize } from "lodash";

type Props = Pick<EmployeeFieldListProps, "title" | "tooltip" | "titleStartAdornment"> & {
  search?: {
    search?: string;
    onSearchChange: (search: string) => void;
    placeholder?: string;
    onEnter?: (search: string) => void;
  };
  filter?: {
    filters: CategorisedOption[];
    categories: Option[];
    options: CategorisedOption[];
    onFilterChange: (filters: CategorisedOption[]) => unknown;
  };
};

const CreateFieldOption = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xLarge);
  justify-content: space-between;
  box-sizing: border-box;
  padding: var(--spacing-large);
  user-select: none;

  :hover {
    cursor: pointer;
    background-color: var(--palette-surface-subdued);
  }
`;

export const EmployeeFieldListHeader = ({
  filter,
  search,
  onCreateField,
  onCreateGroup,
  ...rest
}: Props & {
  onCreateField: VoidFunction;
  onCreateGroup: VoidFunction;
}) => {
  const createAnchorRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  const onRemoveFilter = (filterToRemove: CategorisedOption) => {
    if (!filter) return;

    filter.onFilterChange(
      filter.filters.filter((f: CategorisedOption) => f.key !== filterToRemove.key)
    );
  };

  return (
    <Stack {...rest} vertical>
      <Stack justifyContent="space-between">
        <Stack gap="small">
          {filter && (
            <CategorySelect
              categories={filter.categories}
              options={filter.options}
              value={filter.filters}
              onChange={filter.onFilterChange}
              startIcon="filter"
              variant="outlined"
              size="small"
            >
              Filter
            </CategorySelect>
          )}
          <div ref={createAnchorRef}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setOpen(!open)}
              endIcon="chevronDown"
            >
              New
            </Button>
            <CardPopover
              withoutContentPadding
              referenceElement={createAnchorRef.current}
              open={open}
              onClose={() => setOpen(false)}
              placement="bottom-start"
              widthPreset="ultraWide"
            >
              <CreateFieldOption
                onClick={() => {
                  setOpen(false);
                  onCreateField();
                }}
              >
                <Stack vertical gap="xSmall">
                  <Stack gap="small">
                    <Icon subdued name="attributeFilled" />
                    <Title variant="bold">Field</Title>
                  </Stack>
                  <Title>Single employee field</Title>
                </Stack>
                <Icon subdued name="chevronRight" />
              </CreateFieldOption>
              <Layout.Divider />
              <CreateFieldOption
                onClick={() => {
                  setOpen(false);
                  onCreateGroup();
                }}
              >
                <Stack vertical gap="xSmall">
                  <Stack gap="small">
                    <Icon subdued name="attributeGroup" />
                    <Title variant="bold">Field group</Title>
                  </Stack>
                  <Title>Create a grouped set of fields, e.g. "Buddy" group</Title>
                </Stack>
                <Icon subdued name="chevronRight" />
              </CreateFieldOption>
              <Layout.Divider />
            </CardPopover>
          </div>
        </Stack>
        {search && (
          <ListSearchInput
            value={search.search}
            onChange={val => search.onSearchChange(val || "")}
          />
        )}
      </Stack>

      {filter && filter.filters.length > 0 && (
        <Stack gap="xSmall">
          {filter.filters.map(filter => (
            <Tag.Filter
              key={`employee-fields-${filter.category}-${filter.key}`}
              onRemove={() => onRemoveFilter(filter)}
              filter={{
                filterName: capitalize(filter.category),
                method: "is",
                value: [filter.title || filter.key]
              }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
