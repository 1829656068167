import { OptionList, Popover } from "@introist/react-foundation/v2";

export type Variable = { key: string; title: string };

interface VariablePopoverProps {
  popoverRef: HTMLElement | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelected: (variable: Variable) => void;
  searchable?: boolean;
  presearch?: string;
  variables: Variable[];
}

export const VariablePopover = ({
  popoverRef,
  open,
  setOpen,
  onSelected,
  searchable,
  presearch,
  variables
}: VariablePopoverProps) => (
  <Popover referenceElement={popoverRef} open={open} onClose={() => setOpen(false)}>
    <OptionList
      searchable={searchable}
      searchPlaceholder="Search..."
      options={variables
        .filter(attr => {
          if (!presearch) return true;
          const lowerCasedName = attr.title.toLowerCase();
          const lowerCasedVariable = attr.key.toLowerCase();
          const lowerCasedPresearchWords = presearch.toLowerCase().split(" ");
          return lowerCasedPresearchWords.every((word: string) => {
            return lowerCasedName.includes(word) || lowerCasedVariable.includes(word);
          });
        })
        .map(attr => ({
          key: attr.key,
          title: attr.title,
          onClick: () => onSelected(attr)
        }))}
    />
  </Popover>
);
