import styled from "styled-components";
import { Icon, Tooltip, TooltipProps } from "@introist/react-foundation/v2";

const IconWrapper = styled.div`
  display: grid;

  > svg {
    fill: var(--palette-foreground-dimmed) !important;
  }
`;

export const InfoTooltip = (props: TooltipProps) => (
  <Tooltip {...props}>
    <IconWrapper>
      <Icon name="infoFilled" />
    </IconWrapper>
  </Tooltip>
);
