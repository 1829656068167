import { useCallback } from "react";
import { Select } from "@introist/react-foundation/v2";
import { RelativeDateFieldValue } from "@introist/introist-commons/dist";

import { ConditionWithId, RelativeDateEditor } from "../../ExpressionEditor";
import { TextInput } from "components/atoms";
import { ExpressionField } from "../RecursiveExpressionEditor";

export const useExpressionValueInputV2 = (
  onConditionChange: (condition: Partial<ConditionWithId> & { id: string }) => void
) => {
  const getExpressionValueInput = useCallback(
    (
      field: ExpressionField,
      condition: ConditionWithId,
      onChange?: (id: string, condition: Partial<ConditionWithId>) => void
    ) => {
      if (condition?.comparator === "exists" || condition?.comparator === "notexists") return null;
      if (field.type === "select" && field.options) {
        return (
          <Select
            attachToRef={false}
            size="small"
            options={field.options.map((o: string) => ({ key: o }))}
            value={condition?.value as string}
            onSelect={value => {
              if (!condition?.id) return;
              onChange && onChange(condition.id, { ...condition, value: value.key });
              onConditionChange({ ...condition, value: value.key });
            }}
          />
        );
      }

      if (field.type === "date") {
        return (
          <RelativeDateEditor
            value={condition?.value as RelativeDateFieldValue}
            onChange={value => {
              if (!condition?.id) return;
              onChange && onChange(condition.id, { ...condition, value });
              onConditionChange({ ...condition, value });
            }}
          />
        );
      }

      return (
        <TextInput
          size="small"
          placeholder={field.name}
          value={condition?.value.toString() || ""}
          onChange={value => {
            if (!condition?.id) return;

            onChange && onChange(condition.id, { ...condition, value });
            onConditionChange({ ...condition, value });
          }}
        />
      );
    },
    [onConditionChange]
  );

  return {
    getExpressionValueInput
  };
};
