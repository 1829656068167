import { CopyableReadOnlyInput } from "components/molecules";

import { useIntegrationsApi } from "services/api/IntegrationsApi";

type TeamTailorConfigurationContainerProps = {
  integrationId: string;
};

type TeamTailorConfiguration = {
  apiKey: string;
};

export const TeamTailorConfigurationContainer = ({
  integrationId
}: TeamTailorConfigurationContainerProps) => {
  const integrationApi = useIntegrationsApi();
  const findIntegration = integrationApi.find({ integrationId });
  const { data: integration } = findIntegration.query;

  return (
    <CopyableReadOnlyInput
      label="Introist API key"
      value={(integration?.configuration as TeamTailorConfiguration)?.apiKey || ""}
    />
  );
};
