import { ListView, TopBarRoute } from "components/templates";
import { UpsertFormProps, UpsertModal, useSearch } from "../../../foundation";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { useNavigate, useParams } from "react-router";
import {
  ActionMenu,
  Field,
  Icon,
  Input,
  Select,
  Stack,
  TextArea,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { Trigger, useTriggers } from "../hooks/useTriggers";
import { TriggerFolder, useTriggerFolders } from "../hooks/useTriggerFolders";
import { FolderGrid } from "../components/FolderGrid";
import { BreadcrumbPath } from "@introist/react-foundation/v2/02-blocks";
import { api } from "../../../services/rpc/RpcProvider";
import { MoveTriggerModal } from "../containers/MoveTrigger";
import { PlanAwareRoute } from "../../../foundation/templates/PlanAwareRoute";

export const TriggersListRoute = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { folderId } = useParams();
  const { triggers, create } = useTriggers({ folder: folderId ?? null });

  const { data: folder } = api.triggers.folders.find.useQuery(
    { id: folderId! },
    { enabled: !!folderId }
  );

  const { folders, create: createFolder, update, remove } = useTriggerFolders(folderId ?? null);

  const { filtered, search, setSearch } = useSearch(triggers, { fields: ["name"] });

  const [createOpen, setCreateOpen] = useState(false);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);

  const [moveTriggerId, setMoveTriggerId] = useState<string | undefined>(undefined);

  const paths = useMemo(() => {
    const breadcrumbs: BreadcrumbPath[] = [
      {
        key: "triggers-root",
        title: "Triggers",
        onClick: () => navigate("/triggers")
      }
    ];

    const addFolderRecursive = (folder: any) => {
      if (folder.parent) {
        addFolderRecursive(folder.parent);
      }
      breadcrumbs.push({
        key: `triggers-folder-${folder.id}`,
        title: folder.name,
        onClick: () => navigate(`/triggers/folder/${folder.id}`)
      });
    };

    if (folder) {
      addFolderRecursive(folder);
    }

    return breadcrumbs;
  }, [folder, navigate]);

  return (
    <PlanAwareRoute
      title="Upgrade to use Triggers"
      icon="trigger"
      description="With triggers you can automatically start workflows based on changes in employees data."
    >
      <TopBarRoute paths={paths} medium>
        <Stack vertical gap="xLarge">
          <FolderGrid
            parentId={folderId ?? null}
            folders={folders}
            createOpen={createFolderOpen}
            onCreate={createFolder}
            onEdit={f => update(f as TriggerFolder)}
            onDelete={remove}
            onClose={() => setCreateFolderOpen(false)}
            rootPath="/triggers"
          />
          <ListView.Header
            title="Triggers"
            count={triggers?.length}
            search={{ search, setSearch }}
            create={
              <Select
                element="button"
                variant="outlined"
                placeholder="New"
                size="small"
                startAdornment={<Icon name="plus" />}
                endAdornment={<Icon name="chevronDown" />}
                options={[
                  {
                    key: "trigger",
                    title: "Trigger",
                    startAdornmentIcon: "trigger",
                    onClick: () => setCreateOpen(true)
                  },
                  {
                    key: "folder",
                    title: "Folder",
                    startAdornmentIcon: "folder",
                    onClick: () => {
                      setCreateFolderOpen(true);
                    }
                  }
                ]}
              />
            }
          />
          <ListView.List
            data={filtered}
            empty={{ icon: "trigger" }}
            renderRow={trigger => (
              <ListRow
                gridTemplateColumns="1fr auto"
                clickable
                onClick={() => navigate(`/triggers/${trigger.id}`)}
              >
                <Stack>
                  <IconPickerIcon icon="trigger" fallbackIcon="trigger" />
                  <Title variant="bold">{trigger.name}</Title>
                </Stack>
                <Stack
                  gap="xLarge"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {trigger.failingCount > 0 && (
                    <Stack gap="small">
                      <Icon name="warning" color={theme.palette.danger.default} />
                      <Title>{trigger.failingCount}</Title>
                    </Stack>
                  )}
                  {trigger.activeCount > 0 && (
                    <Stack gap="small">
                      <Icon name="person" />
                      <Title>{trigger.activeCount}</Title>
                    </Stack>
                  )}
                  <ActionMenu
                    options={[
                      {
                        key: "move",
                        title: "Move",
                        startAdornmentIcon: "folderMoveTo",
                        onClick: () => setMoveTriggerId(trigger.id)
                      }
                    ]}
                  />
                </Stack>
              </ListRow>
            )}
          />
        </Stack>
        <UpsertModal
          open={createOpen}
          template={{ folder: folderId ?? null }}
          onClose={() => setCreateOpen(false)}
          onCreate={async entry => {
            await create(entry).then(created => navigate(`/triggers/${created.id}`));
          }}
          onUpdate={() => {
            throw new Error("Not implemented");
          }}
          Form={CreateTriggerForm}
          entryName="trigger"
        />
        {moveTriggerId && (
          <MoveTriggerModal
            open={!!moveTriggerId}
            onClose={() => setMoveTriggerId(undefined)}
            triggerId={moveTriggerId}
          />
        )}
      </TopBarRoute>
    </PlanAwareRoute>
  );
};

const CreateTriggerForm = ({ value, onChange }: UpsertFormProps<Trigger>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>

      <Field title="Description">
        <TextArea
          value={value.description ?? ""}
          onChange={description => onChange({ ...value, description })}
        />
      </Field>
    </Stack>
  );
};
