import {
  Button,
  Card,
  CircularLoader,
  Icon,
  Input,
  ModalV2,
  Stack,
  Tag,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import { formatDate } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";

type DataSourceFieldsModalProps = {
  dataSourceId?: string;
  open: boolean;
  onClose: () => void;
};

export const DataSourceFieldsModal = ({
  dataSourceId,
  open,
  onClose
}: DataSourceFieldsModalProps) => {
  return (
    <ModalV2 title="Data source fields" onClose={onClose} open={open} maxWidth={700}>
      {open && dataSourceId && <DataSourceFieldsList dataSourceId={dataSourceId} />}
    </ModalV2>
  );
};

const DataSourceFieldsList = ({ dataSourceId }: { dataSourceId: string }) => {
  const toast = useToast();
  const { data: fieldsEntry } = api.employees.dataSources.fields.entry.useQuery({
    sourceId: dataSourceId
  });
  const reloadFields = api.employees.dataSources.fields.reload.useMutation();

  const [search, setSearch] = useState("");

  const filteredFields = useMemo(() => {
    if (!fieldsEntry) return [];
    return fieldsEntry.fields.filter(
      field =>
        field.name?.toLowerCase().includes(search.toLowerCase()) ||
        field.jsonPath.toLowerCase().includes(search.toLowerCase())
    );
  }, [fieldsEntry, search]);

  if (fieldsEntry === undefined) return <CircularLoader fillParent style={{ height: "70vh" }} />;

  return (
    <Stack vertical style={{ height: "70vh" }}>
      <Stack>
        <Input
          size="small"
          endAdornment={<Icon name="search" />}
          value={search}
          onChange={setSearch}
          placeholder={"Search fields"}
          style={{ width: "300px" }}
        />
        <Stack style={{ marginLeft: "auto" }}>
          {fieldsEntry && (
            <Title small>
              Updated at {formatDate(fieldsEntry.updatedAt, DateFormats.datetime.short)}
            </Title>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              reloadFields
                .mutateAsync({ sourceId: dataSourceId })
                .then(() => toast.info("Reload started. It may take a few minutes to complete."))
                .catch(() => toast.error("Failed to start field reload."));
            }}
          >
            Update fields
          </Button>
        </Stack>
      </Stack>
      <Stack vertical gap="small" style={{ overflowY: "auto" }}>
        {filteredFields.length === 0 && (
          <Title style={{ textAlign: "center", margin: "2rem" }}>No fields found</Title>
        )}
        {filteredFields.map(field => (
          <Card>
            <Stack>
              <Stack vertical gap="xSmall">
                <Title variant="bold">{field.name ?? field.jsonPath}</Title>
                <Title small>{field.jsonPath}</Title>
              </Stack>
              <div style={{ marginLeft: "auto" }}>
                <Tag>{field.type}</Tag>
              </div>
            </Stack>
          </Card>
        ))}
      </Stack>
    </Stack>
  );
};
