import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  ActionMenu,
  Icon,
  Layout,
  Stack,
  Tag,
  Title,
  Tooltip,
  useTheme
} from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";

import { CollapseList, ListRow } from "components/organisms";
import {
  CompanyEventInstance,
  ManagedEventAttendee,
  UnmanagedEventAttendee
} from "services/rpc/RpcProvider";
import { formatDate, formatDateTime } from "utils/DatesUtils";
import { useEventAttendeeActions } from "./use-event-attendee-actions";
import { EventAttendeeListRow } from "./EventAttendeeListRow";
import { UnmanagedAttendeeListRow } from "./UnmanagedAttendeeListRow";
import { Dot } from "components/atoms";
import { CancelSessionModal } from "../../containers/CancelSessionModal";
import { Info } from "../../../../foundation";
import moment from "moment";

type Props = {
  instance: CompanyEventInstance;
  unmanagedAttendees: UnmanagedEventAttendee[];
  search?: string;
  onShowAttendeeHistory: (attendeeId: string) => void;
  onImportAttendee: (attendee: UnmanagedEventAttendee) => Promise<void>;
  onCanceled: () => void;
};

const StyledListRow = styled(ListRow)`
  margin-top: -1px;
`;

export const CalendarEventAttendeeList = ({
  instance,
  unmanagedAttendees,
  search,
  onShowAttendeeHistory,
  onImportAttendee,
  onCanceled
}: Props) => {
  const { theme } = useTheme();
  const completed = moment(instance.endTime).isBefore(new Date());

  const { getEventAttendeeActions } = useEventAttendeeActions(instance.companyEventId);

  const [open, setOpen] = useState(!isEmpty(instance.attendees) || !isEmpty(unmanagedAttendees));

  const [cancelOpen, setCancelOpen] = useState(false);

  const attendees = useMemo(() => {
    return [...instance.attendees, ...unmanagedAttendees];
  }, [instance.attendees, unmanagedAttendees]);

  const header = useMemo(() => {
    return (
      <>
        <Icon
          name={completed ? "calendarCheck" : "calendar"}
          color={theme.palette.foreground.subdued}
        />
        <Title variant="bold" color={theme.palette.foreground.subdued}>
          {formatDate(instance.startTime, "dddd MMM D, YYYY")}
        </Title>
        <Dot />
        {instance.status === "active" && (
          <>
            <Title>
              {formatDateTime(instance.startTime, "HH:mm")} -{" "}
              {formatDateTime(instance.endTime, "HH:mm")}
            </Title>

            <Dot />
            <Layout.Group>
              <Title>
                {attendees.length === 1 ? `1 attendee` : `${attendees.length} attendees`}{" "}
              </Title>
            </Layout.Group>
            <Stack
              style={{ marginLeft: "auto" }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {instance.errors && instance.errors.length > 0 && (
                <Tooltip tooltip={instance.errors.join(". ")}>
                  <Icon name="warning" color={theme.palette.danger.default} />
                </Tooltip>
              )}
              <ActionMenu
                attachToRef={false}
                options={[
                  {
                    key: "cancel",
                    startAdornment: (
                      <Icon name="crossCircle" color={theme.palette.danger.default} />
                    ),
                    title: "Cancel session",
                    titleStyles: {
                      color: theme.palette.danger.default
                    },
                    onClick: () => setCancelOpen(true)
                  }
                ]}
              />
            </Stack>
          </>
        )}

        {instance.status === "cancelled" && (
          <>
            <Tag>Cancelled</Tag>
            {instance.cancelReason && <Info message={instance.cancelReason} />}
          </>
        )}
      </>
    );
  }, [
    completed,
    theme,
    attendees.length,
    instance.startTime,
    instance.endTime,
    instance.status,
    instance.errors,
    instance.cancelReason
  ]);

  useEffect(() => {
    if (!isEmpty(instance.attendees)) {
      setOpen(true);
    }
  }, [instance.attendees, setOpen]);

  useEffect(() => {
    if (!search) return;

    const match = search
      ? !!instance.attendees.some(a => a.name.toLowerCase().includes(search.toLowerCase()))
      : false;

    if (match) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]); // Only re-run the effect if `search` changes

  const filteredAttendees = useMemo(() => {
    return instance.attendees.filter(a => {
      return a.name.toLowerCase().includes(search?.toLowerCase() ?? "");
    });
  }, [instance.attendees, search]);

  const filteredUnmanagedAttendees = useMemo(() => {
    return unmanagedAttendees.filter(a => {
      return a.email?.toLowerCase().includes(search?.toLowerCase() ?? "");
    });
  }, [unmanagedAttendees, search]);

  return (
    <CollapseList
      open={open}
      header={header}
      onToggleOpen={instance.status === "active" ? () => setOpen(!open) : undefined}
      emptyState={
        isEmpty(attendees) ? (
          <StyledListRow gridTemplateColumns="1fr">
            <Title>No attendees</Title>
          </StyledListRow>
        ) : undefined
      }
    >
      {filteredAttendees.map(attendee => (
        <EventAttendeeListRow
          search={search}
          key={`event-attendee-list-row-${attendee.id}`}
          attendee={attendee}
          actions={getEventAttendeeActions(attendee, onShowAttendeeHistory)}
        />
      ))}
      {filteredUnmanagedAttendees.map(attendee => (
        <UnmanagedAttendeeListRow
          key={`event-attendee-list-row-unmanaged-${attendee.email}`}
          attendee={attendee}
          onAdd={() => onImportAttendee(attendee)}
        />
      ))}
      <CancelSessionModal
        instanceId={instance.id}
        instanceDate={instance.startTime}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onCanceled={onCanceled}
      />
    </CollapseList>
  );
};

export const ManagedAttendeeList = ({
  search,
  managedAttendees,
  eventId,
  onShowAttendeeHistory
}: {
  search?: string;
  eventId: string;
  managedAttendees: ManagedEventAttendee[];
  onShowAttendeeHistory: (attendeeId: string) => void;
}) => {
  const { theme } = useTheme();

  const { activeAttendees, otherAttendees } = managedAttendees.reduce(
    (acc, attendee) => {
      if (attendee.status === "active") {
        acc.activeAttendees.push(attendee);
      } else {
        acc.otherAttendees.push(attendee);
      }
      return acc;
    },
    {
      activeAttendees: [] as ManagedEventAttendee[],
      otherAttendees: [] as ManagedEventAttendee[]
    }
  );

  const [activeOpen, setActiveOpen] = useState(true);
  const [otherOpen, setOtherOpen] = useState(false);

  const { getEventAttendeeActions } = useEventAttendeeActions(eventId);

  useEffect(() => {
    if (!search) return;

    const searchLower = search.toLowerCase();

    const activeMatch = activeAttendees.some(a => a.name.toLowerCase().includes(searchLower));
    const otherMatch = otherAttendees.some(a => a.name.toLowerCase().includes(searchLower));

    if (activeMatch) {
      setActiveOpen(true);
    }

    if (otherMatch) {
      setOtherOpen(true);
    }

    // If there is a match in either, we want to make sure to close the other group
    if (activeMatch && !otherMatch) {
      setOtherOpen(false);
    }

    if (otherMatch && !activeMatch) {
      setActiveOpen(false);
    }

    // If there is no match at all, consider whether you want to close both groups
    if (!activeMatch && !otherMatch) {
      setActiveOpen(false);
      setOtherOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]); // Only re-run the effect if `search` changes

  return (
    <Layout.Group vertical gap="xLarge">
      <CollapseList
        open={activeOpen}
        onToggleOpen={() => setActiveOpen(!activeOpen)}
        header={
          <>
            <Icon name="checkCircle" color={theme.palette.success.default} />
            <Title variant="bold" color={theme.palette.success.default}>
              Managed
            </Title>
            <Dot />
            <Title>
              {activeAttendees.length === 1 ? `1 attendee` : `${activeAttendees.length} attendees`}
            </Title>
          </>
        }
        emptyState={
          isEmpty(activeAttendees) ? (
            <StyledListRow gridTemplateColumns="1fr">
              <Title>No attendees</Title>
            </StyledListRow>
          ) : undefined
        }
      >
        {activeAttendees.map(attendee => (
          <EventAttendeeListRow
            search={search}
            key={`event-active-attendee-list-row-${attendee.id}`}
            attendee={attendee}
            actions={getEventAttendeeActions(attendee, onShowAttendeeHistory)}
          >
            <Stack>
              {
                //@ts-ignore
                attendee.eventInstance.errors?.some(
                  (e: any) => e === "Calendar event is removed"
                ) && (
                  <Tooltip tooltip="Session is is removed from calendar">
                    <Icon name="warning" color={theme.palette.danger.default} />
                  </Tooltip>
                )
              }
              <Title>{formatDate(attendee.eventInstance.startTime, "MMM D, YYYY")}</Title>
            </Stack>
          </EventAttendeeListRow>
        ))}
      </CollapseList>
      <CollapseList
        open={otherOpen}
        onToggleOpen={() => setOtherOpen(!otherOpen)}
        header={
          <>
            <Icon subdued name="progress" />
            <Title variant="bold" color={theme.palette.foreground.subdued}>
              History
            </Title>
            <Dot />
            <Title>
              {otherAttendees.length === 1 ? `1 attendee` : `${otherAttendees.length} attendees`}{" "}
            </Title>
          </>
        }
        emptyState={
          isEmpty(otherAttendees) ? (
            <StyledListRow gridTemplateColumns="1fr">
              <Title>No attendees</Title>
            </StyledListRow>
          ) : undefined
        }
      >
        {otherAttendees.map(attendee => (
          <EventAttendeeListRow
            search={search}
            key={`event-other-attendee-list-row-${attendee.id}`}
            attendee={attendee}
            actions={getEventAttendeeActions(attendee, onShowAttendeeHistory)}
          />
        ))}
      </CollapseList>
    </Layout.Group>
  );
};
