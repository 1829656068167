import { ActionMenu, Field, Input, Option, Stack, Card } from "@introist/react-foundation/v2";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useState } from "react";
import { FittedTitle } from "components/atoms";
import { WorkflowFolder } from "../index";
import { useWorkflowFolders } from "../hooks/useWorkflowFolders";
import { Skeletons } from "../../../components/molecules";
import { UpsertFormProps, UpsertModal } from "../../../foundation";
import { FolderIcon } from "../../../assets";

type Props = {
  parentFolderId?: string;
  create?: {
    open: boolean;
    onClose: () => void;
  };
};

const StyledWorkflowFoldersGrid = styled.div`
  display: grid;
  grid-gap: var(--spacing-small);
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const WorkflowFoldersGrid = ({ parentFolderId, create: createProps, ...rest }: Props) => {
  const { folders, create, update, remove } = useWorkflowFolders(parentFolderId || null);

  const [editOpen, setEditOpen] = useState(false);
  const [editFolder, setEditFolder] = useState<WorkflowFolder | undefined>();

  const buildFolderActions = (folder: WorkflowFolder) => {
    const actions: Option[] = [
      {
        key: "edit",
        title: "Edit",
        startAdornmentIcon: "pencil",
        onClick: () => {
          setEditFolder(folder);
          setEditOpen(true);
        }
      },
      {
        key: "delete",
        title: "Delete",
        startAdornmentIcon: "trash",
        onClick: () => remove(folder.id)
      }
    ];

    return actions;
  };

  if (folders === undefined) return <Skeletons.Grid height={100} width={300} count={4} />;

  return (
    <StyledWorkflowFoldersGrid {...rest}>
      {(folders || []).map(f => (
        <FolderCard key={f.id} folder={f} actions={buildFolderActions(f)} />
      ))}

      <UpsertModal
        open={editOpen || !!createProps?.open}
        onClose={() => {
          setEditOpen(false);
          createProps?.onClose();
        }}
        onCreate={entry =>
          create({ ...entry, parentId: parentFolderId || null }).then(() => createProps!.onClose())
        }
        entry={editFolder}
        onUpdate={update}
        Form={FolderForm}
        entryName="folder"
      />
    </StyledWorkflowFoldersGrid>
  );
};

const FolderCard = ({ folder, actions }: { folder: WorkflowFolder; actions: Option[] }) => (
  <StyledLink to={`/flows/folder/${folder.id}`}>
    <Card>
      <Stack vertical gap="xSmall">
        <Stack justifyContent="space-between">
          <Stack gap="small">
            <FolderIcon width="16px" />
          </Stack>
          <div onClick={e => e.preventDefault()}>
            <ActionMenu options={actions} />
          </div>
        </Stack>
        <FittedTitle showTooltip maxLength={24} variant="bold">
          {folder.name}
        </FittedTitle>
      </Stack>
    </Card>
  </StyledLink>
);

const FolderForm = ({ value, onChange, isEdit }: UpsertFormProps<WorkflowFolder>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
    </Stack>
  );
};
