import { Accordion, DataForm, Field, Input, Title } from "@introist/react-foundation/v2";
import { prefixForm } from "../../../../../../utils/FormUtils";

interface WebhookStepEditorProps {
  form: DataForm<any>;
  formPrefix: string;
  readonly?: boolean;
}

export const WebhookStepEditor = ({ form, formPrefix, readonly }: WebhookStepEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const { get, set, isError } = prefixedForm;
  return (
    <Accordion title="Action details">
      <Field title="Hook URL">
        {readonly ? (
          <Title>{get("hookUrl")}</Title>
        ) : (
          <Input
            size="small"
            placeholder="https://www.example.com"
            value={get("hookUrl")}
            onChange={set("hookUrl")}
            error={isError("hookUrl")}
            readonly={readonly}
          />
        )}
      </Field>
      {!readonly && (
        <Field title="API Key">
          <Input
            size="small"
            placeholder="Your API Key"
            value={get("apiKey")}
            onChange={set("apiKey")}
            error={isError("apiKey")}
            type="password"
          />
        </Field>
      )}
    </Accordion>
  );
};
