import { useRef, useState, useMemo } from "react";
import { RelativeDateFieldValue } from "@introist/introist-commons/dist";
import { RelativeDateEditorPopover } from "components/molecules";
import { getStringifiedRelativeDateValue } from "components/organisms";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { IconButton, Stack } from "@introist/react-foundation/v2";

interface AfterSelectorProps {
  value?: RelativeDateFieldValue;
  onChange: (value: RelativeDateFieldValue) => unknown;
  targetDateTerm?: string;
}

export const AfterSelector = ({ value, onChange, targetDateTerm }: AfterSelectorProps) => {
  const popoverRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const buttonContent = useMemo(() => {
    if (!value) return "Select earliest";
    return getStringifiedRelativeDateValue(value, targetDateTerm);
  }, [value, targetDateTerm]);
  return (
    <>
      <IconToggleButton
        startIcon="calendarTarget"
        ref={popoverRef}
        active={open}
        onClick={() => setOpen(!open)}
      >
        {buttonContent}
      </IconToggleButton>
      <RelativeDateEditorPopover
        popoverRef={popoverRef.current}
        open={open}
        setOpen={setOpen}
        onChange={onChange}
        value={value}
        targetDateTerm={targetDateTerm}
      />
    </>
  );
};

interface BeforeSelectorProps {
  value?: RelativeDateFieldValue;
  onChange: (value: RelativeDateFieldValue | null) => unknown;
  targetDateTerm?: string;
}

export const BeforeSelector = ({ value, onChange, targetDateTerm }: BeforeSelectorProps) => {
  const popoverRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const buttonContent = useMemo(() => {
    console.log(value);
    if (!value) return "Select latest";
    return getStringifiedRelativeDateValue(value, targetDateTerm);
  }, [value, targetDateTerm]);
  return (
    <Stack>
      <IconToggleButton
        startIcon="calendarTarget"
        ref={popoverRef}
        active={open}
        onClick={() => setOpen(!open)}
      >
        {buttonContent}
      </IconToggleButton>
      {open && (
        <RelativeDateEditorPopover
          popoverRef={popoverRef.current}
          open={open}
          setOpen={setOpen}
          onChange={onChange}
          value={value}
          targetDateTerm={targetDateTerm}
        />
      )}
      {value && <IconButton icon="crossSmall" onClick={() => onChange(null)} />}
    </Stack>
  );
};
