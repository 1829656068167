import { AutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { EmailMembers } from "./EmailMembers";
import { useEmailSenders } from "../../../../hooks";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { isEmpty } from "lodash";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { AttachmentItem } from "../../../workflows/blocks/Attachments/components/File";
import { Stack } from "@introist/react-foundation/v2";

export const LegacyEmailViewer = ({ step }: { step: AutomationStep }) => {
  const { resolvedData, attachments } = step as any;

  const { senders } = useEmailSenders(false);

  const sender = senders.find(s => s.id === resolvedData.emailSenderId);

  return (
    <>
      <Block blended compact>
        <EmailMembers
          {...resolvedData}
          to={Array.isArray(resolvedData.to) ? resolvedData.to : [resolvedData.to]}
          from={sender?.email ?? "Archived sender"}
        />
      </Block>
      <Block blended compact>
        <ReadOnlyMessage subject={resolvedData.subject} message={resolvedData.message} />
      </Block>
      {!isEmpty(attachments) && (
        <Block blended compact>
          <StepEditorAccordion title="Attachments">
            <Stack vertical gap="small">
              {attachments.map((attachment: any) => (
                <AttachmentItem id={attachment} readOnly />
              ))}
            </Stack>
          </StepEditorAccordion>
        </Block>
      )}
    </>
  );
};
