import { PrimitiveFieldValue } from "@introist/introist-commons/dist";
import { ConditionWithId } from "components/organisms/ExpressionEditor";
import { ExpressionField } from "../../../RecursiveExpressionEditor";
import { ConditionGroupLevel1, ConditionGroupLevel2 } from "../../../types";
import _ from "lodash";
import { parseConditionValue } from "./ConditionValueParser";
import { parseConditionComparator } from "./ConditionComparatorParser";
import { parseConditionAttribute } from "./ConditionAttributeParser";

export interface ParsedCondition {
  id: string;
  title: string;
  comparatorTitle: string;
  value: PrimitiveFieldValue[];
}

export const parseCondition = (
  condition: ConditionWithId,
  fields: ExpressionField[]
): ParsedCondition => {
  const field = parseConditionAttribute(condition.attribute, fields);
  const comparator = parseConditionComparator(condition.comparator, field?.type || "text");
  const value = parseConditionValue(condition.value);

  const isNoValueComparator = comparator && ["exists", "notexists"].includes(comparator?.key);
  return {
    id: condition.id,
    title: field?.name || "Deleted Employee field",
    comparatorTitle: comparator?.title || "Unknown Comparator",
    value: isNoValueComparator ? [] : [value]
  };
};

const isConditionGroup = (condition: ConditionWithId | ConditionGroupLevel1) => {
  return "conditions" in condition;
};

const mergeParsedConditions = (parsedConditions: ParsedCondition[]) => {
  return parsedConditions.reduce((acc, condition) => {
    const existingCondition = acc.find(c => c.id === condition.id);
    if (existingCondition) {
      return acc.map(c =>
        c.id === condition.id ? { ...c, value: [...c.value, ...condition.value] } : c
      );
    }
    return [...acc, condition];
  }, [] as ParsedCondition[]);
};

export const useConditionParser = (
  conditionGroup: ConditionGroupLevel2,
  fields: ExpressionField[]
): ParsedCondition[] => {
  const { conditions } = conditionGroup;

  const parsedConditions = conditions.map(
    (conditionOrConditionGroup: ConditionWithId | ConditionGroupLevel1) => {
      // if it is condition, throw error
      if (isConditionGroup(conditionOrConditionGroup)) {
        const parsedConditions = (conditionOrConditionGroup as ConditionGroupLevel1).conditions.map(
          condition => ({ ...parseCondition(condition, fields), id: conditionOrConditionGroup.id })
        );
        return mergeParsedConditions(parsedConditions);
      }
      const condition = conditionOrConditionGroup as ConditionWithId;
      return parseCondition(condition, fields);
    }
  );
  return _.flatten(parsedConditions);
};
