import {
  addStepToJourney,
  JourneyWithSteps,
  StepDetails,
  useJourneyApi
} from "services/api/journeys/JourneyApi";

import {
  H4,
  IconButton,
  Title,
  Button,
  useToast,
  InfoCard,
  Tag,
  Accordion
} from "@introist/react-foundation/v2";
import styles from "./ActiveEmployees.module.scss";
import { DateFormats, formatIntroistDateTime } from "../../../../../../utils/dates";
import { flatten, partition, sortBy } from "lodash";
import { IntroistDateTime } from "@introist/introist-commons/dist";
import { useCallback } from "react";
import { Block } from "components/atoms";
import { useWorkflowStep } from "../../../../../flows/hooks/useWorkflowSteps";

type Props = {
  stepId: string;
  onClose: () => void;
};

export const WorkflowStepActiveEmployees = ({ stepId, onClose }: Props) => {
  const toast = useToast();

  const journeyApi = useJourneyApi();
  const listJourneysWithSteps = journeyApi.listByWorkflowStep({ stepId });

  const { data: journeys } = listJourneysWithSteps.query;

  const { step: workflowStep } = useWorkflowStep(stepId);

  const addStep = useCallback(
    (journeyId: string) => {
      return addStepToJourney({
        journeyId,
        workflowId: workflowStep!.workflowId,
        workflowStepId: stepId
      })
        .then(() => listJourneysWithSteps.refetch())
        .then(() => toast.success("Step added"))
        .catch(() => toast.error("Failed to add step"));
    },
    [listJourneysWithSteps, toast, workflowStep, stepId]
  );

  const renderRow = (journey: JourneyWithSteps, step?: StepDetails) => {
    const name = `${journey.firstname} ${journey.lastname}`;
    let description = "";

    if (!step) {
      description = `Target date of the journey is ${formatIntroistDateTime(
        { date: journey.targetdate, time: "00:00", timezone: "UTC" },
        DateFormats.date.long
      )}`;
    } else if (step.status === "completed") {
      description = `Step completed at ${formatIntroistDateTime(
        step.start,
        DateFormats.date.long
      )}`;
    } else {
      description = `Step scheduled at ${formatIntroistDateTime(
        step.start,
        DateFormats.date.long
      )}`;
    }

    const resolveStepAction = (step: StepDetails) => {
      if (step.status === "completed") {
        return <Tag colorVariant="success">Completed</Tag>;
      }
      if (step.status === "canceled") {
        return <Tag>Canceled</Tag>;
      }
      if (step.workflowStepVersion === workflowStep?.version) {
        return <Tag colorVariant="primary">Up to date</Tag>;
      }

      return <Tag colorVariant="danger">Out of date</Tag>;
    };

    return (
      <div className={styles.StepRow}>
        <div>
          <Title variant="bold">{name}</Title>
          <Title>{description}</Title>
        </div>

        {workflowStep && step && resolveStepAction(step)}
        {workflowStep && !step && (
          <Button variant="outlined" onClickWithLoading={() => addStep(journey.id)}>
            Add
          </Button>
        )}
      </div>
    );
  };

  const groupJourneys = (journeys?: JourneyWithSteps[] | undefined) => {
    if (!journeys) return [];
    const journeysAndSteps: { journey: JourneyWithSteps; step?: StepDetails }[] = flatten(
      journeys.map(journey => {
        const activeSteps = (journey.steps ?? []).filter(step => step.status !== "canceled");

        if (activeSteps.length === 0) return { journey };
        return activeSteps.map(step => ({ journey, step }));
      })
    );

    const [withoutSteps, withSteps] = partition(journeysAndSteps, item => !item.step);

    const sorted = sortBy(withSteps, ({ step }) =>
      IntroistDateTime.fromObject(step!.start).asDate().getTime()
    );

    const sortByName = (journeys: { journey: JourneyWithSteps; step?: StepDetails }[]) =>
      sortBy(journeys, ({ journey }) => `${journey.firstname} ${journey.lastname}`);

    const [completed, active] = partition(sorted, ({ step }) => step!.status === "completed");
    return [sortByName(active), sortByName(withoutSteps), sortByName(completed)];
  };

  const [active, withoutSteps, completed] = groupJourneys(journeys);

  return (
    <div className={styles.Root}>
      <Block className={styles.Header}>
        <IconButton icon="arrowLeft" onClick={onClose} />
        <H4>Active employees</H4>
      </Block>

      {workflowStep?.stepType === "event" && (
        <Block>
          <InfoCard title="Updating existing calendar events">
            If you need to update any calendar events, please reach out to your dedicated Customer
            Support team for assistance.
          </InfoCard>
        </Block>
      )}
      <Block blended>
        {active.length > 0 && (
          <Accordion title="Active" defaultOpen>
            <div className={styles.StepContainer}>
              {active.map(({ journey, step }) => renderRow(journey, step))}
            </div>
          </Accordion>
        )}
        {completed.length > 0 && (
          <Accordion title="Step completed" defaultOpen={false}>
            <div className={styles.StepContainer}>
              {completed.map(({ journey, step }) => renderRow(journey, step))}
            </div>
          </Accordion>
        )}
        {withoutSteps.length > 0 && (
          <Accordion title="No active step" defaultOpen={false}>
            <div className={styles.StepContainer}>
              {withoutSteps.map(({ journey, step }) => renderRow(journey, step))}
            </div>
          </Accordion>
        )}
      </Block>
    </div>
  );
};
