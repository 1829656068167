import { z } from "zod";

export const SentBySchema = z.object({
  name: z.string(),
  iconUrl: z.string().optional()
});
export type SentBy = z.infer<typeof SentBySchema>;

export const MarkdownBlockSchema = z.object({
  type: z.literal("section"),
  text: z.object({
    type: z.literal("mrkdwn"),
    text: z.string()
  })
});
export type MarkdownBlock = z.infer<typeof MarkdownBlockSchema>;

export const SlackAction = z.object({
  type: z.literal("button"),
  value: z.string(),
  url: z.string().optional(),
  text: z.object({
    type: z.literal("plain_text"),
    emoji: z.boolean().optional(),
    text: z.string()
  })
});

export const ActionsBlockSchema = z.object({
  type: z.literal("actions"),
  elements: SlackAction.array()
});
export type ActionsBlock = z.infer<typeof ActionsBlockSchema>;

export const ImageBlockSchema = z.object({
  type: z.literal("image"),
  imageUrl: z.string(),
  altText: z.string()
});
export type ImageBlock = z.infer<typeof ImageBlockSchema>;

export const SlackBlockMessageSchema = z
  .union([MarkdownBlockSchema, ActionsBlockSchema, ImageBlockSchema])
  .array();
export type SlackBlockMessage = z.infer<typeof SlackBlockMessageSchema>;
