import { Stack, Title } from "@introist/react-foundation/v2";
import { ListView, UpsertModal, useSearch } from "../../../../foundation";
import { EmployeeGroupCreate, useEmployeeGroups } from "../../hooks/useEmployeeGroups";
import { IconPickerIcon, ListRow } from "../../../../components/organisms";
import { EmployeeGroupDrawer, EmployeeGroupForm } from "../../containers/EmployeeGroupDrawer";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import { pick } from "lodash";

export const EmployeeGroups = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { groups, create } = useEmployeeGroups();

  const { filtered, search, setSearch } = useSearch(groups, { fields: ["title"] });

  const [createOpen, setCreateOpen] = useState(false);

  return (
    <Stack vertical>
      <ListView.Header
        title="Employee Groups"
        count={filtered?.length}
        search={{ search, setSearch }}
        onCreate={() => setCreateOpen(true)}
      />
      <ListView.List
        data={filtered}
        renderRow={group => (
          <ListRow
            key={group.id}
            gridTemplateColumns="1fr auto"
            clickable
            onClick={() => navigate(`/employees/groups/${group.id}`)}
          >
            <Stack>
              <IconPickerIcon icon="persons" fallbackIcon="persons" />
              <Title variant="bold">{group.title}</Title>
            </Stack>
          </ListRow>
        )}
      />
      <EmployeeGroupDrawer
        open={!!groupId}
        onClose={() => navigate("/employees/groups")}
        id={groupId!}
      />
      <UpsertModal
        open={createOpen}
        template={{ condition: { and: [] } }}
        onCreate={async entry => {
          await create(pick(entry, ["title", "condition"]) as EmployeeGroupCreate).then(created => {
            navigate(`/employees/groups/${(created as any).id}`);
          });
        }}
        onUpdate={entry => {
          throw new Error("Not implemented");
        }}
        onClose={() => {
          setCreateOpen(false);
        }}
        Form={EmployeeGroupForm}
        entryName="employee group"
      />
    </Stack>
  );
};
