import {
  RelativeDateFieldValue,
  parseRelativeDate,
  relativeDateFieldSchema
} from "@introist/introist-commons/dist";
import { Popover, Card, Input, Select, Layout } from "@introist/react-foundation/v2";
import { useCallback, useMemo } from "react";

interface RelativeDateEditorPopoverProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  popoverRef: HTMLElement | null;
  value?: RelativeDateFieldValue;
  onChange: (value: RelativeDateFieldValue) => void;
  targetDateTerm?: string;
}

const extractRelativeDateInfo = (value?: RelativeDateFieldValue) => {
  if (value) {
    if (relativeDateFieldSchema.safeParse(value).success) {
      return parseRelativeDate(value.reldate);
    }
  }

  return { side: "+", amount: 1, unit: "M" };
};

export const RelativeDateEditorPopover = ({
  open,
  setOpen,
  popoverRef,
  value,
  onChange,
  targetDateTerm = "today"
}: RelativeDateEditorPopoverProps) => {
  const { side, amount, unit } = useMemo(() => {
    const { amount, unit, side } = extractRelativeDateInfo(value);
    if (value) {
      return { amount, unit, side };
    }

    onChange({ reldate: "+1d" });
    return { amount, unit, side };
  }, [value, onChange]);

  const onChanged = useCallback(
    (property: string, newValue: string) => {
      const reldate = `${property === "side" ? newValue : side}${
        property === "amount" ? newValue : amount
      }${property === "unit" ? newValue : unit}`;
      onChange({ reldate });
    },
    [side, amount, unit, onChange]
  );

  return (
    <Popover
      referenceElement={popoverRef}
      open={open}
      onClose={() => setOpen(false)}
      closeOnContentClick={false}
    >
      <Card elevated>
        <Layout.Group>
          <Input
            type="number"
            size="small"
            style={{ width: "30px" }}
            variant="blended"
            value={amount}
            onChange={val => onChanged("amount", val)}
          />
          <Select
            size="small"
            variant="blended"
            style={{ width: "120px" }}
            options={[
              { key: "d", title: "days" },
              { key: "w", title: "weeks" },
              { key: "M", title: "months" }
            ]}
            value={unit}
            onSelect={opt => onChanged("unit", opt.key)}
          />
          <Select
            size="small"
            variant="blended"
            style={{ width: "200px" }}
            options={[
              { key: "-", title: `before ${targetDateTerm}` },
              { key: "+", title: `after ${targetDateTerm}` }
            ]}
            value={side}
            onSelect={opt => onChanged("side", opt.key)}
          />
        </Layout.Group>
      </Card>
    </Popover>
  );
};
