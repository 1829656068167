import { useMemo } from "react";
import styled, { css } from "styled-components";
import { Expression, FieldValue, RelativeDateFieldValue } from "@introist/introist-commons/dist";
import { isEmpty } from "lodash";
import { Card, IconTag, Layout, Title, useTheme, TextButton } from "@introist/react-foundation/v2";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";

import { ComparatorOptions, getStringifiedRelativeDateValue } from "components/organisms";
import { isExistsComparison } from "components/organisms/ExpressionEditor/comparators";
import { expressionToConditionGroup } from "components/organisms/RecursiveExpressionEditor/types";

type ExistingRulesListProps = {
  expression: Expression;
  employeeAttributes: EmployeeAttribute[];
  readOnly?: boolean;
  small?: boolean;
  createTitle?: (isAnd: boolean) => string;
  onEdit?: () => void;
  onDelete?: () => void;
  showChangeAttributes?: boolean;
};

const isRelativeDate = (value: any): value is RelativeDateFieldValue => {
  return typeof value === "object" && "reldate" in value;
};

export const parseConditionValue = (value: FieldValue) => {
  if (isRelativeDate(value)) {
    return getStringifiedRelativeDateValue(value);
  }

  return value;
};

const StyledExistingRulesList = styled(Card)`
  background: var(--palette-surface-subdued);
  border-color: transparent;
`;

const RuleCard = styled(Card)`
  && {
    padding: 0.5rem;
  }
`;

const StyledTitle = styled(Title)<{ $small: boolean }>`
  ${({ $small }) =>
    $small &&
    css`
      font-size: 0.75rem;
    `}
`;

export const ExistingRulesList = ({
  expression,
  employeeAttributes,
  readOnly,
  small = false,
  createTitle,
  onDelete,
  onEdit,
  showChangeAttributes,
  ...rest
}: ExistingRulesListProps) => {
  const { theme } = useTheme();
  const conditionGroup = useMemo(() => {
    const conditionGroup = expressionToConditionGroup(expression);
    return conditionGroup;
  }, [expression]);

  const isAnd = conditionGroup.mode === "and";
  const conditions = conditionGroup.conditions;
  const showButtons = !!onEdit || !!onDelete;

  const title = createTitle
    ? createTitle(isAnd)
    : `${showChangeAttributes ? "and" : "When"} ${isAnd ? "all" : "any"} of the rules match`;

  if (conditions.length === 0 && readOnly) return null;

  const buildListOfAttributes = () => {
    return conditions.map(condition => {
      const employeeAttribute = employeeAttributes.find(x => x.variable === condition.attribute);
      return employeeAttribute?.name || "[NO VALUE]";
    });
  };

  return (
    <StyledExistingRulesList {...rest}>
      <Layout.Group vertical gap="large">
        {isEmpty(conditions) ? (
          <>
            <Title variant="bold">No rules</Title>
            <TextButton startIcon="plusSmall" colorVariant="primary" onClick={onEdit}>
              Add rules
            </TextButton>
          </>
        ) : (
          <>
            <Layout.Group vertical alignItems="stretch" gap="xSmall">
              {showChangeAttributes && <Title>When any of the attributes changes</Title>}
              {showChangeAttributes && (
                <Layout.Group vertical gap="xSmall">
                  <RuleCard key={`change-attributes`}>
                    <Layout.Group gap="xSmall">
                      <StyledTitle $small={small} variant="bold">
                        {buildListOfAttributes().join(", ")}
                      </StyledTitle>
                    </Layout.Group>
                  </RuleCard>
                </Layout.Group>
              )}
              {title && <Title>{title}</Title>}
              <Layout.Group vertical gap="xSmall">
                {conditions.map((condition, index) => {
                  const parsedValue = parseConditionValue(condition.value);
                  const employeeAttribute = employeeAttributes.find(
                    x => x.variable === condition.attribute
                  );
                  const attributeCategory = employeeAttribute?.type === "date" ? "date" : undefined;
                  const comparator = ComparatorOptions.find(
                    o => o.key === condition.comparator && attributeCategory === o.category
                  )?.title;

                  const missingValues = !employeeAttribute?.name || !condition.comparator;

                  return (
                    <RuleCard key={`condition-${index}`}>
                      <Layout.Group gap="xSmall">
                        <StyledTitle $small={small} variant="bold">
                          {employeeAttribute ? employeeAttribute.name : "[NO VALUE]"}
                        </StyledTitle>
                        <StyledTitle $small={small}>{comparator}</StyledTitle>
                        {!isExistsComparison(condition.comparator) && (
                          <StyledTitle $small={small} variant="bold">
                            {parsedValue ? parsedValue : "[NO VALUE]"}
                          </StyledTitle>
                        )}
                        {missingValues && (
                          <IconTag size="small" name="warning" color={theme.palette.warning} />
                        )}
                      </Layout.Group>
                    </RuleCard>
                  );
                })}
              </Layout.Group>
            </Layout.Group>
            {showButtons && (
              <Layout.Group justifyContent="flex-end">
                {onEdit && <TextButton onClick={onEdit}>Edit</TextButton>}
                {onDelete && <TextButton onClick={onDelete}>Delete all</TextButton>}
              </Layout.Group>
            )}
          </>
        )}
      </Layout.Group>
    </StyledExistingRulesList>
  );
};
