import { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Button, Layout, Modal } from "@introist/react-foundation/v2";

import { api } from "services/rpc/RpcProvider";
import { RadioCard } from "components/molecules";
import { formatDate, formatDateTime } from "utils/DatesUtils";

import { useRescheduleCompanyEventAttendee } from "../hooks";

type Props = {
  companyEventId: string;
  attendeeId: string;
  onRescheduled?: VoidFunction;
};

const InstanceList = styled(Layout.Group)`
  max-height: 20rem;
  overflow: auto;
`;

export const RescheduleCompanyEventAttendee = ({
  attendeeId,
  companyEventId,
  onRescheduled,
  ...rest
}: Props) => {
  const [instanceId, setInstanceId] = useState<string>();
  const { rescheduleAttendee, rescheduleAttendeeLoading } =
    useRescheduleCompanyEventAttendee(companyEventId);
  const { data: instances } = api.companyEvents.instances.list.useQuery({ companyEventId });

  return (
    <Layout.Group {...rest} vertical lastChildToEnd gap="xxLarge">
      <InstanceList vertical gap="small">
        {instances &&
          instances.length > 0 &&
          instances.map(instance => (
            <RadioCard
              active={instanceId === instance.id}
              key={instance.id}
              title={formatDate(instance.startTime, "dddd MMM D, YYYY")}
              description={`${formatDateTime(instance.startTime, "HH:mm")} - ${formatDateTime(
                instance.endTime,
                "HH:mm"
              )}`}
              onClick={() => setInstanceId(instance.id)}
            />
          ))}
      </InstanceList>
      <Button
        disabled={!instanceId || rescheduleAttendeeLoading}
        onClickWithLoading={async () => {
          await rescheduleAttendee(attendeeId, instanceId);
          onRescheduled && onRescheduled();
        }}
      >
        Reschedule
      </Button>
    </Layout.Group>
  );
};

const StyledModal = styled(Modal)`
  > :first-child > :last-child {
    overflow: initial;
  }
`;

export const RescheduleCompanyEventAttendeeModal = ({
  attendeeId: attendeeIdProp,
  companyEventId,
  ...rest
}: Pick<Props, "companyEventId"> & {
  attendeeId?: string;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const location = useLocation();
  const attendeeIdFromHash = location.hash.split("=")[1];
  const attendeeId = attendeeIdProp || attendeeIdFromHash;

  return (
    <StyledModal {...rest} title="Reschedule attendee to selected session">
      <RescheduleCompanyEventAttendee
        companyEventId={companyEventId}
        attendeeId={attendeeId}
        onRescheduled={rest.onClose}
      />
    </StyledModal>
  );
};
