import { ComparatorOptions, ConditionWithId } from "../../ExpressionEditor";
import { useMemo } from "react";
import { hasConditionWithValue } from "../util";
import { parseConditionValue } from "modules/workflows/blocks/StepSettingsBlock/settings/ConditionalStepSetting/components/ExistingRulesList";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";

export const useConditionDisplay = (
  condition?: ConditionWithId,
  attributes?: EmployeeAttribute[]
) => {
  const withValue = useMemo(() => {
    if (!condition) return false;
    return hasConditionWithValue([condition]);
  }, [condition]);

  const attributeName = useMemo(() => {
    return attributes?.find(x => x.variable === condition?.attribute)?.name;
  }, [condition, attributes]);

  const value = useMemo(() => {
    if (!condition) return undefined;
    return parseConditionValue(condition.value);
  }, [condition]);

  return {
    title: `${attributeName} ${
      ComparatorOptions.find(x => x.key === condition?.comparator)?.title
    }`,
    value: withValue ? value : undefined
  };
};
