import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { getUser, loginWithSsoToken, waitForInitialIDToken } from "../services/firebase";
import { appRoutes } from "../AppRoutes";
import { loadSsoInfo } from "../services/api/UserApi";
import { IntroistLoader } from "@introist/react-foundation/v2";
import { config } from "../Config";

export const SSOLogin = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    loginWithSsoToken(token ?? "")
      .then(() => (window.location.href = "/"))
      .catch(() => navigate(appRoutes.login.path, { replace: true }));
  }, [navigate, token]);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
    >
      <IntroistLoader />
    </div>
  );
};

export const InitSSORoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    waitForInitialIDToken().then(() => {
      const email = getUser()?.email;

      if (email) {
        loadSsoInfo(email)
          .then(info => window.open(config.authSsoUrl + info.tenant, "_self"))
          .catch(err => {
            navigate(appRoutes.login.path);
          });
      } else {
        navigate(appRoutes.login.path);
      }
    });
  }, [navigate]);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
    >
      <IntroistLoader />
    </div>
  );
};
