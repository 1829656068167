import { Button, CircularLoader, Layout, ModalV2, useToast } from "@introist/react-foundation/v2";
import { RadioCard } from "components/molecules";
import { useMemo, useState } from "react";
import { api } from "services/rpc/RpcProvider";

type Props = {
  companyEventId: string;
  onEventLinked?: VoidFunction;
  open: boolean;
  onClose: VoidFunction;
};

export const LinkCompanyEventModal = ({
  open,
  onClose,
  companyEventId,
  onEventLinked,
  ...rest
}: Props) => {
  const toast = useToast();
  const { data: companyEvent, isLoading: companyEventLoading } = api.companyEvents.find.useQuery({
    id: companyEventId
  });
  const { data: calendarEvents, isLoading: calendarEventsLoading } =
    api.companyEvents.calendars.listEvents.useQuery({});
  const { mutateAsync: linkEvent, isLoading: linkEventLoading } =
    api.companyEvents.link.useMutation({});

  const [selectedEventId, setSelectedEventId] = useState<string>();

  const events = useMemo(() => {
    if (!calendarEvents || !companyEvent) return [];
    return calendarEvents.events
      .filter(e => e.hostId === companyEvent.hostId)
      .filter(e => e.recurringEventId !== companyEvent.recurringEventId)
      .filter(e => !companyEvent.linkedEventIds.includes(e.recurringEventId));
  }, [companyEvent, calendarEvents]);

  return (
    <ModalV2
      open={open}
      onClose={onClose}
      title="Link another event"
      maxHeight={60}
      footer={
        <Layout.Group justifyContent="flex-end">
          <Button
            disabled={
              !selectedEventId || calendarEventsLoading || companyEventLoading || linkEventLoading
            }
            onClickWithLoading={async () => {
              if (!selectedEventId) return;

              await linkEvent(
                { companyEventId: companyEvent!.id, linkedCalendarEventId: selectedEventId },
                {
                  onSuccess: () => {
                    toast.success("Event linked");
                    onEventLinked && onEventLinked();
                  },
                  onError: () => toast.error("Failed to link event")
                }
              );
            }}
          >
            Link
          </Button>
        </Layout.Group>
      }
    >
      <Layout.Group vertical gap="small" {...rest}>
        {(companyEventLoading || calendarEventsLoading) && (
          <CircularLoader
            style={{ padding: "24px 0 8px 0" }}
            fillParent
            size="small"
            colorVariant="primary"
          />
        )}
        {events.map((event, idx) => (
          <RadioCard
            titleMaxLength={35}
            key={`linkable-event-list-item-${event.recurringEventId}-${idx}`}
            active={selectedEventId === event.recurringEventId}
            startIcon="calendar"
            title={event.title}
            info={event.description}
            onClick={() => setSelectedEventId(event.recurringEventId)}
          />
        ))}
      </Layout.Group>
    </ModalV2>
  );
};
