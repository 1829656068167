import { useCallback } from "react";
import styled from "styled-components";
import { Layout, TextButton, Title } from "@introist/react-foundation/v2";
import { useLocation, useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import { api } from "services/rpc/RpcProvider";
import { AnimatedBlobH2 } from "components/atoms";
import { FullsreenView } from "components/templates";
import { ProgressBar } from "components/molecules";
import { CalendarCardButton, CalendarConnectionStatus } from "./components";
import { appRoutes } from "AppRoutes";
import { config } from "Config";

const Content = styled(Layout.Group)`
  flex: 1;
  width: 25rem;
  margin: 0 auto;

  > :first-child {
    margin-bottom: var(--spacing-xxLarge);
  }
`;

const StyledAnimatedBlobH2 = styled(AnimatedBlobH2)`
  > H2 {
    width: auto;
  }
`;

export const ConnectCalendarButton = ({ provider }: { provider: "google" | "outlook" }) => {
  const getUrl = api.companyEvents.connect.start.useMutation();

  const startConnect = useCallback(async () => {
    return getUrl
      .mutateAsync({
        provider,
        redirectUrl: `${config.appBaseUrl}/events/calendars/connect/complete/${provider}`
      })
      .then((url: string) => (window.location.href = url));
  }, [getUrl, provider]);
  return <CalendarCardButton connected={false} onClick={startConnect} emailProvider={provider} />;
};

export const StartCalendarConnectRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const appearSpring = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    delay: 300
  });

  const skip = () => {
    navigate(appRoutes.events.path);
  };

  const fromOnboarding = location.state?.fromOnboarding;

  return (
    <FullsreenView
      progressBar={
        <ProgressBar
          animate
          height={6}
          value={fromOnboarding ? 3 : 1}
          total={fromOnboarding ? 4 : 2}
          backgroundColor="transparent"
        />
      }
    >
      <Content vertical justifyContent="center" gap="xxLarge">
        <Layout.Group justifyContent="center">
          <CalendarConnectionStatus />
        </Layout.Group>
        <Layout.Group vertical gap="large">
          <Layout.Group vertical gap="xLarge">
            <Layout.Group justifyContent="center">
              <StyledAnimatedBlobH2 delay={300}>Connect account</StyledAnimatedBlobH2>
            </Layout.Group>
            <animated.div style={appearSpring}>
              <Title style={{ textAlign: "center" }}>
                Link your account to manage your calendar events directly in Introist
              </Title>
            </animated.div>
          </Layout.Group>
        </Layout.Group>

        <Layout.Group vertical gap="large">
          <animated.div style={{ ...appearSpring, width: "100%" }}>
            <Layout.Group vertical>
              <ConnectCalendarButton provider="google" />
              <ConnectCalendarButton provider="outlook" />
            </Layout.Group>
          </animated.div>
          <animated.div style={{ ...appearSpring, width: "100%" }}>
            <Layout.Group justifyContent="center">
              <TextButton onClick={skip}>Skip for now</TextButton>
            </Layout.Group>
          </animated.div>
        </Layout.Group>
      </Content>
    </FullsreenView>
  );
};
