import { Button, Card, Checkbox, H4, Layout, P, Title } from "@introist/react-foundation/v2";
import { useState } from "react";
import { animated, config, useTransition } from "@react-spring/web";
import styled from "styled-components";
import {
  MaxAttendees,
  RescheduleAttendeeOnDecline,
  RescheduleAttendeeOnWaiting
} from "../CompanyEventSettings/settings";
import { Avatar } from "components/molecules";
import { TextInput } from "components/atoms";
import { AccordionCard, RadioCard } from "components/molecules";
import { useAppearSpings } from "hooks";
import { useNavigate } from "react-router";

const StyledEventsGuide = styled(Layout.Group)``;

const Grid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4.5rem;

  > :first-child {
    position: relative;
    z-index: 2;
  }
`;

const Visual = styled.div`
  max-height: 35rem;
  box-sizing: border-box;
  position: relative;

  > section {
    height: 100%;
    padding: var(--spacing-xxLarge);
    box-sizing: border-box;
    border-top-left-radius: var(--rounding-large);
    border-top-right-radius: var(--rounding-large);
    box-shadow: var(--shadow-default);

    > div {
      position: relative;
      > div {
        width: 100%;
      }
    }
  }
`;

const VerticalFadeSmall = styled.div`
  position: absolute;
  top: 0;
  left: -3rem;
  bottom: -2rem;
  right: -2rem;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 10%,
    var(--palette-surface-subdued) 100%
  );
`;

const Paragraph = styled(P)`
  color: var(--palette-foreground-subdued);
`;

const StepAddEvent = () => {
  const items = [
    "IT Onboarding",
    "Intro with legal",
    "Intro with CEO",
    "Pizza friday",
    "Monday breakfast"
  ];
  const springs = useAppearSpings(items.length);
  return (
    <Layout.Group vertical gap="small">
      {items.map((item, index) => (
        <animated.div key={`mock-event-list-item-${index}`} style={springs[index]}>
          <RadioCard
            active={index === 0}
            title={item}
            onClick={() => {}}
            style={{ backgroundColor: "white" }}
          />
        </animated.div>
      ))}
    </Layout.Group>
  );
};

const StyledStepSync = styled(Card)`
  padding: 0;
  pointer-events: none;
  user-select: none;

  > header {
    display: flex;
    align-items: center;
    gap: var(--spacing-large);
    padding: var(--spacing-large) var(--spacing-xLarge);
    border-bottom: 1px solid var(--palette-border-subdued);
  }

  > section {
    > div {
      padding: var(--spacing-medium) var(--spacing-xLarge);
    }
    > :not(:last-child) {
      border-bottom: 1px solid var(--palette-border-subdued);
    }
  }
`;

const StepSync = () => {
  const people = [
    {
      name: "Annika Rosser",
      date: "Nov 6, 2023",
      time: "10:00 - 11:00"
    },
    {
      name: 'Kaylynn O"Conner',
      date: "Nov 6, 2023",
      time: "10:00 - 11:00"
    },
    {
      name: "Allison Donin",
      date: "Nov 6, 2023",
      time: "10:00 - 11:00"
    },
    {
      name: "Kevin Roland",
      date: "Nov 6, 2023",
      time: "10:00 - 11:00"
    },
    {
      name: "Levi Dias",
      date: "Nov 6, 2023",
      time: "10:00 - 11:00"
    }
  ];

  const springs = useAppearSpings(people.length + 1);

  return (
    <animated.div style={springs[0]}>
      <StyledStepSync>
        <header>
          <Checkbox checked={false} onChange={() => {}} />
        </header>
        <section>
          {people.map((person, index) => (
            <animated.div style={springs[index + 1]}>
              <Layout.Group justifyContent="space-between">
                <Layout.Group gap="large">
                  <Checkbox checked={false} onChange={() => {}} />
                  <Avatar nameOrEmail={person.name} />
                  <Title variant="bold">{person.name}</Title>
                </Layout.Group>
                <Layout.Group vertical gap="xSmall">
                  <Title variant="bold">{person.date}</Title>
                  <Title>{person.time}</Title>
                </Layout.Group>
              </Layout.Group>
            </animated.div>
          ))}
        </section>
      </StyledStepSync>
    </animated.div>
  );
};

const StyledStepEnroll = styled(Card)`
  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-xLarge);
  }
`;

const StepEnroll = () => {
  const springs = useAppearSpings(4);
  return (
    <animated.div style={springs[0]}>
      <StyledStepEnroll>
        <Layout.Group vertical gap="xLarge">
          <animated.div style={springs[1]}>
            <Title variant="bold">Enroll attendee</Title>
          </animated.div>
          <animated.div style={springs[2]}>
            <section>
              <TextInput value="levi.dias@introist.com" label="Email address" size="small" />
              <TextInput value="Levi Dias" label="Name" size="small" />
            </section>
          </animated.div>
          <animated.div style={springs[3]}>
            <Layout.Group justifyContent="flex-end">
              <Button size="small">Enroll</Button>
            </Layout.Group>
          </animated.div>
        </Layout.Group>
      </StyledStepEnroll>
    </animated.div>
  );
};

const StepDefineRules = () => {
  const springs = useAppearSpings(3);
  return (
    <Layout.Group vertical gap="small" style={{ pointerEvents: "none" }}>
      <animated.div style={springs[0]}>
        <RescheduleAttendeeOnDecline
          readOnly
          setting={{
            active: true,
            setting: "RescheduleAttendeeOnDecline",
            companyEventId: "mock",
            conf: {
              rescheduleAttendeeOnDecline: true
            }
          }}
        />
      </animated.div>
      <animated.div style={springs[1]}>
        <RescheduleAttendeeOnWaiting
          readOnly
          setting={{
            active: true,
            setting: "RescheduleAttendeeOnWaiting",
            companyEventId: "mock",
            conf: {
              daysBefore: 1
            }
          }}
        />
      </animated.div>
      <animated.div style={springs[2]}>
        <MaxAttendees
          readOnly
          setting={{
            active: true,
            setting: "MaxAttendees",
            companyEventId: "mock",
            conf: {
              maxAttendees: 1
            }
          }}
        />
      </animated.div>
    </Layout.Group>
  );
};

export const EventsGuide = ({ ...rest }) => {
  const navigate = useNavigate();
  const [currentOpen, setCurrentOpen] = useState(1);

  const visualTransitions = useTransition(currentOpen, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0,
      position: "absolute"
    },
    config: { ...config.stiff, clamp: true }
  });

  const getVisual = (order: number) => {
    switch (order) {
      default:
      case 1:
        return <StepAddEvent />;
      case 2:
        return <StepDefineRules />;
      case 3:
        return <StepSync />;
      case 4:
        return <StepEnroll />;
    }
  };

  return (
    <StyledEventsGuide {...rest} vertical gap="large">
      <H4>Getting started</H4>
      <Grid>
        <Layout.Group vertical>
          <AccordionCard
            open={currentOpen === 1}
            icon="calendarAdd"
            colorVariant="purple"
            title="Set up event"
            order={1}
            onClick={setCurrentOpen}
          >
            <Layout.Group vertical alignItems="flex-start" gap="large">
              <Paragraph>Import event from connected calendar or create new</Paragraph>
              <Button
                {...rest}
                startIcon="calendarAdd"
                onClick={() => navigate("#add-existing-event")}
              >
                New event
              </Button>
            </Layout.Group>
          </AccordionCard>
          <AccordionCard
            open={currentOpen === 2}
            icon="settings"
            colorVariant="purple"
            title="Select automation rules"
            order={2}
            onClick={setCurrentOpen}
          >
            <Layout.Group vertical alignItems="flex-start" gap="large">
              <Paragraph>Customize the scheduling and management of the event</Paragraph>
            </Layout.Group>
          </AccordionCard>
          <AccordionCard
            open={currentOpen === 3}
            icon="personSync"
            colorVariant="purple"
            title="Import attendees"
            order={3}
            onClick={setCurrentOpen}
          >
            <Layout.Group vertical alignItems="flex-start" gap="large">
              <Paragraph>Import existing attendees from calendar</Paragraph>
            </Layout.Group>
          </AccordionCard>

          <AccordionCard
            icon="plus"
            colorVariant="purple"
            title="Add new attendees"
            order={4}
            open={currentOpen === 4}
            onClick={setCurrentOpen}
          >
            <Layout.Group vertical alignItems="flex-start" gap="large">
              <Paragraph>Schedule the event for new attendees</Paragraph>
            </Layout.Group>
          </AccordionCard>
        </Layout.Group>
        <Visual>
          <section>
            <div>
              {visualTransitions((style, tCurrentOpen) => (
                <animated.div style={{ ...style }}>{getVisual(tCurrentOpen)}</animated.div>
              ))}
            </div>
          </section>
          <VerticalFadeSmall />
        </Visual>
      </Grid>
    </StyledEventsGuide>
  );
};
