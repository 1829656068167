import { Button, H3, Stack } from "@introist/react-foundation/v2";

import {
  ExpressionField,
  FullscreenModal,
  RecursiveExpressionEditor,
  useEmployeeFieldsToExpressionFields
} from "../../../components/organisms";
import { Expression } from "@introist/introist-commons/dist";
import { EmployeeField } from "../hooks/useEmployeeFields";

type RuleEditModalProps = {
  expression: Expression;
  open: boolean;
  onClose: () => void;
  onChange: (expression: Expression) => void;
  onSave: () => Promise<unknown>;
};

export const asExpressionFields = (fields?: EmployeeField[]): ExpressionField[] => {
  return (fields ?? []).map(f => ({ variable: f.key, name: f.title, type: "text" }));
};

export const RuleEditModal = ({
  open,
  onClose,
  onSave,
  expression,
  onChange
}: RuleEditModalProps) => {
  const { expressionFields } = useEmployeeFieldsToExpressionFields();
  return (
    <FullscreenModal.Default
      header={{
        title: "Edit rules",
        bottomBorder: true,
        onClose
      }}
      footer={{
        children: (
          <Stack justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button onClickWithLoading={onSave}>Save</Button>
          </Stack>
        ),
        topBorder: true
      }}
      open={open}
      onClose={onClose}
    >
      {open && (
        <RecursiveExpressionEditor
          expression={expression}
          fields={expressionFields}
          onChange={onChange}
          config={{
            attributeListTitle: "Create rule based on...",
            placeholderConditionCardText: "Create rule based on...",
            placeholderConditionCardDescription: (
              <Stack gap="small">
                <span>Start by selecting the employee field on the right</span>
                <H3>👉</H3>
              </Stack>
            )
          }}
        />
      )}
    </FullscreenModal.Default>
  );
};
