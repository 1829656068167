import { Icon, Input, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import { IconToggleButton } from "../../workflows/components/IconToggleButton";
import { useRef, useState } from "react";
import { CardPopover } from "../../workflows/routes/WorkflowEditor/CardPopover";

type StepCutoffEditorProps = {
  value: number;
  onChange: (value: number) => void;
};

export const StepCutoffEditor = ({ value, onChange }: StepCutoffEditorProps) => {
  const { theme } = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconToggleButton
        active={open}
        ref={buttonRef}
        endAdornment={<Icon name="targetBefore" color={theme.palette.foreground.subdued} />}
        onClick={() => setOpen(!open)}
      >
        <Title style={{ fontWeight: 500 }}>+{value} days</Title>
      </IconToggleButton>
      <CardPopover
        open={open}
        onClose={() => setOpen(false)}
        referenceElement={buttonRef.current}
        widthPreset="wide"
      >
        <Stack vertical>
          <Title variant="bold">Step cut-off</Title>
          <Title>The step is cancelled if not completed</Title>
          <Stack>
            <Input
              type="number"
              min={1}
              value={value}
              onChange={val => onChange(parseInt(val))}
              size="small"
              style={{ width: "60px" }}
            />
            <Title>days after planned date</Title>
          </Stack>
        </Stack>
      </CardPopover>
    </>
  );
};
