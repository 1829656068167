import { useCallback, useState } from "react";
import { Modal, Select, useToast } from "@introist/react-foundation/v2";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";
import { useWorkflowStep, useWorkflowStepOps } from "../../../flows/hooks/useWorkflowSteps";

type CopyStepModalProps = {
  onClose: () => unknown;
  stepIdToCopy: string;
  open: boolean;
};

export const CopyStepModal = ({ stepIdToCopy, onClose, open }: CopyStepModalProps) => {
  const toast = useToast();

  const { workflows } = useWorkflows({});
  const { create } = useWorkflowStepOps();

  const { step } = useWorkflowStep(stepIdToCopy);

  const [workflowId, setWorkflowId] = useState<string | undefined>();

  const onCopy = useCallback(async () => {
    if (!workflowId) {
      return toast.info("Select a workflow to copy");
    }
    const { id, ...data } = step!;

    await create({ ...data, workflowId: workflowId! })
      .then(() => {
        const workflow = workflows!.find(_ => _.id === workflowId);
        setWorkflowId(undefined);
        toast.success(`Step copied to ${workflow!.title}`);
        onClose();
      })
      .catch(() => {
        toast.error("Failed to copy step");
      });
  }, [create, onClose, step, toast, workflowId, workflows]);

  const options = workflows
    ? workflows
        .filter(wf => wf.id !== step?.workflowId)
        .map(wf => ({ key: wf.id, title: wf.title }))
    : [];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Copy to workflow"
      description="Select which workflow you want to copy this step"
      mobile="fullscreen"
      primaryButton={{ title: "Copy", onClickWithLoading: onCopy }}
      secondaryButton={{ title: "Cancel", onClick: onClose }}
    >
      <Select
        style={{ width: "100%" }}
        placeholder="Select target workflow"
        options={options}
        onSelect={opt => setWorkflowId(opt?.key)}
        value={workflowId}
        searchable
      />
    </Modal>
  );
};
