import { useQuery, useQueryClient } from "@tanstack/react-query";

import { deleteJson, getJson, postJson, putJson } from "./ApiBase";

export type MaterialType = "file" | "link" | "handbook";

export interface MaterialOverview {
  id: string;
  title: string;
  data: string;
  materialType: MaterialType;
}

type UpdateMaterialRequest = Pick<MaterialOverview, "title" | "data">;

interface CreateMaterialRequest {
  title: string;
  data?: string;
  materialType: MaterialType;
}

export const useListMaterials = () => {
  const queryClient = useQueryClient();
  const keySet = ["materials"];
  const apiFunc = () => listMaterials();
  return {
    query: useQuery(keySet, apiFunc),
    refetch: () => queryClient.refetchQueries(keySet),
    invalidate: () => queryClient.invalidateQueries(keySet)
  };
};

export const listMaterials = (): Promise<MaterialOverview[]> => {
  return getJson("/v1/materials");
};

export const archiveMaterial = (id: string): Promise<unknown> => {
  return deleteJson(`/v1/materials/${id}`);
};

export const updateMaterial = (
  id: string,
  request: Partial<UpdateMaterialRequest>
): Promise<unknown> => {
  return putJson(`/v1/materials/${id}`, { ...request });
};

export const createMaterial = (request: CreateMaterialRequest): Promise<string> => {
  return postJson("/v1/materials", { ...request });
};

export const getPreviewUrl = (id: string): Promise<{ url: string }> => {
  return getJson(`/v1/materials/${id}/preview`);
};

export const getMaterial = (id: string): Promise<MaterialOverview> => {
  return getJson(`/v1/materials/${id}`);
};
