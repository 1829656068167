import { ListView, TopBarRoute } from "../../../components/templates";
import {
  ActionMenu,
  Card,
  Icon,
  IconButton,
  Option,
  Stack,
  Title,
  Tooltip
} from "@introist/react-foundation/v2";
import {
  EmployeeField,
  EmployeeFieldCreate,
  groupUpdatedAtFields,
  TrackableEmployeeField,
  useEmployeeFields
} from "../hooks/useEmployeeFields";
import { DragHandle, UpsertModal, useSearch } from "../../../foundation";
import { pick } from "lodash";
import { useMemo, useState } from "react";
import { ArchivedFieldsModal } from "./ArchivedFieldsModal";
import { api } from "../../../services/rpc/RpcProvider";
import { FieldTransformationModal } from "./FieldTransformationModal";
import { EmployeeFieldForm } from "./EmployeeFieldForm";
import FeatherIcon from "feather-icons-react";
import { CategoriesModal } from "./CategoriesModal";
import { FieldCategorySelector } from "../components/FieldCategorySelector";

export const EmployeeFieldsRoute = () => {
  const { fields, create, update, reorder, archive, enableUpdateTracking } = useEmployeeFields();
  const { data: computed } = api.employees.v4.fields.computed.list.useQuery({});

  const [activeCategory, setActiveCategory] = useState("General");

  const processedFields = useMemo(() => {
    return groupUpdatedAtFields(
      (fields ?? []).filter(f => (f.category ?? "General") === activeCategory)
    );
  }, [fields, activeCategory]);

  const { filtered, search, setSearch } = useSearch(processedFields, {
    fields: ["title", "key"]
  });

  const [editOpen, setEditOpen] = useState(false);
  const [editEntry, setEditEntry] = useState<EmployeeField | undefined>();

  const [transformField, setTransformField] = useState<string | null>(null);

  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [archivedOpen, setArchivedOpen] = useState(false);

  return (
    <TopBarRoute paths={[{ key: "fields", title: "Employee fields" }]} medium>
      <Card style={{ maxWidth: "750px", width: "750px", margin: "0 auto" }}>
        <Stack vertical gap="xLarge" style={{ flex: 1 }}>
          <ListView.Header
            title="Employee Fields"
            iconName="attributeFilled"
            onCreate={() => {
              setEditOpen(true);
              setEditEntry(undefined);
            }}
            actions={[
              {
                key: "categories",
                title: "Manage categories",
                startAdornmentIcon: "list",
                onClick: () => setCategoriesOpen(true)
              },
              {
                key: "showarchived",
                title: "Archived fields",
                startAdornmentIcon: "archive",
                onClick: () => setArchivedOpen(true)
              }
            ]}
            search={{
              search,
              setSearch
            }}
          />
          <Stack>
            <FieldCategorySelector value={activeCategory} onChange={setActiveCategory} />
          </Stack>
          <ListView.OrderableList
            data={filtered}
            renderRow={(f: TrackableEmployeeField) => {
              const computedField = computed?.find(c => c.field === f.key);

              const options: Option[] = [
                {
                  key: "edit",
                  title: "Edit",
                  startAdornmentIcon: "pencil",
                  onClick: () => {
                    setEditEntry(f);
                    setEditOpen(true);
                  }
                },
                {
                  key: "transformation",
                  title: !!computedField ? "Edit transformation" : "Add transformation",
                  startAdornmentIcon: "workflowEditor",
                  onClick: () => setTransformField(f.key)
                }
              ];

              if (!f.updatedAtField) {
                options.push({
                  key: "enableupdatedat",
                  title: "Enable update tracking",
                  startAdornmentIcon: "time",
                  onClick: () => enableUpdateTracking(f.key)
                });
              }

              options.push({
                key: "archive",
                title: "Archive",
                startAdornmentIcon: "trash",
                onClick: () => archive(f.id)
              });

              return (
                <Card>
                  <Stack>
                    <DragHandle />
                    <Stack>
                      <Title variant="bold">{f.title}</Title>
                      <Title>{f.key}</Title>
                      {f.isRequired && (
                        <Tooltip tooltip="This field is required">
                          <Icon name="exclamationCircle" dimmed />
                        </Tooltip>
                      )}
                    </Stack>
                    <Stack
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      style={{ marginLeft: "auto" }}
                    >
                      {!!f.updatedAtField && (
                        <Tooltip tooltip="Update time is tracked for this field">
                          <Icon name="time" dimmed />
                        </Tooltip>
                      )}
                      {!!f.options && (
                        <Tooltip tooltip="This field has a set of options">
                          <FeatherIcon icon="list" size="16px" />
                        </Tooltip>
                      )}
                      {!!computedField && (
                        <Tooltip tooltip="This field has a transformation">
                          <IconButton
                            icon={<FeatherIcon icon="codesandbox" size="16px" />}
                            onClick={() => setTransformField(f.key)}
                            dimmed
                          />
                        </Tooltip>
                      )}
                      <ActionMenu options={options} />
                    </Stack>
                  </Stack>
                </Card>
              );
            }}
            //@ts-ignore
            onReorder={!search ? reorder : undefined}
          />
        </Stack>
      </Card>
      <CategoriesModal open={categoriesOpen} onClose={() => setCategoriesOpen(false)} />
      <UpsertModal
        open={editOpen}
        entry={editEntry}
        onCreate={async entry => {
          await create(entry as EmployeeFieldCreate);
        }}
        onUpdate={entry => {
          return update({
            id: entry.id,
            updates: pick(entry, "title", "options", "defaultValue", "category", "isRequired")
          });
        }}
        onClose={() => {
          setEditOpen(false);
          setEditEntry(undefined);
        }}
        Form={EmployeeFieldForm}
        entryName="field"
        maxContentHeight="90vh"
      />
      <ArchivedFieldsModal open={archivedOpen} onClose={() => setArchivedOpen(false)} />
      <FieldTransformationModal
        open={!!transformField}
        onClose={() => setTransformField(null)}
        fieldKey={transformField!}
      />
    </TopBarRoute>
  );
};
