import { Icon, Layout, Title, Tooltip, useTheme } from "@introist/react-foundation/v2";

import { Block, Dot } from "components/atoms";
import { Avatar } from "components/molecules";
import { EventAttendeeAttendanceStatusTag } from "modules/events/components";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import moment from "moment-timezone";
import { useMemo } from "react";
import { DateFormats } from "utils/dates";
import { HostCalendarIcon } from "components/atoms/HostCalendarIcon";
import { api } from "services/rpc/RpcProvider";
import { isEmpty } from "lodash";
import { useAutomation } from "../../../../../automations/hooks/useAutomations";

interface AttendaceProps {
  attendeeId: string;
  journeyId: string;
}

export const AttendanceBlock = ({ attendeeId, journeyId }: AttendaceProps) => {
  const { theme } = useTheme();

  const { automation } = useAutomation({ id: journeyId });
  const { data: attendee, isLoading } = api.companyEvents.attendees.find.useQuery({ attendeeId });

  const { eventDate, eventStartTime, eventEndTime } = useMemo(() => {
    const timezone = automation?.timezone;
    if (!attendee || !timezone) return {};
    const { startTime, endTime } = attendee.eventInstance;
    const eventDate = moment(startTime).format("YYYY-MM-DD");
    const eventStartTime = moment(startTime).tz(timezone).format("HH:mm");
    const eventEndTime = moment(endTime).tz(timezone).format("HH:mm");
    return { eventDate, eventStartTime, eventEndTime };
  }, [attendee, automation]);

  if (isLoading || !attendee) return <></>;

  const { host, companyEvent } = attendee;
  return (
    <Block blended compact>
      <StepEditorAccordion title="Attendance">
        <StepEditorAccordion.Row label="Event">
          <Layout.Group>
            <HostCalendarIcon emailProvider={host.emailProvider} />
            <Layout.Group vertical gap="xSmall">
              <Title variant="bold">{companyEvent.title}</Title>
              <Layout.Group gap="small">
                <Title>{moment(eventDate).format(DateFormats.date.long)}</Title>
                <Dot />
                <Title>
                  {eventStartTime} - {eventEndTime}
                </Title>
                <Tooltip tooltip={automation?.timezone ?? ""}>
                  <Icon color={theme.palette.foreground.subdued} name="globe" />
                </Tooltip>
              </Layout.Group>
            </Layout.Group>
          </Layout.Group>
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Attendee">
          <Layout.Group justifyContent="space-between">
            <Layout.Group>
              <Avatar nameOrEmail={!isEmpty(attendee.name) ? attendee.name : attendee.email} />
              <Layout.Group vertical gap="xSmall">
                <Title variant="bold">
                  {!isEmpty(attendee.name) ? attendee.name : attendee.email}
                </Title>
              </Layout.Group>
            </Layout.Group>
            <EventAttendeeAttendanceStatusTag status={attendee?.attendanceStatus} />
          </Layout.Group>
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
