import { CircularLoader, Icon, Modal, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import { useBatchCreator } from "../../useBatchCreators";
import { ListView } from "../../../../foundation";
import { ListRow } from "../../../../components/organisms";
import { formatDate } from "../../../../utils/DatesUtils";
import { DateFormats } from "../../../../utils/dates";

export const CreatorHistory = ({
  creatorId,
  open,
  onClose
}: {
  creatorId?: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { theme } = useTheme();
  const { history } = useBatchCreator(creatorId!, { enabled: !!creatorId });

  return (
    <Modal title="Batch creator history" open={open} onClose={onClose} style={{ width: "400px" }}>
      {!history && <CircularLoader />}
      {history && (
        <ListView.List
          data={history}
          renderRow={entry => (
            <ListRow gridTemplateColumns="auto auto auto">
              <Title>{formatDate(entry.timestamp, DateFormats.datetime.short)}</Title>
              {entry.error ? <Title>{entry.error}</Title> : <div />}
              <Stack style={{ marginLeft: "auto" }}>
                {entry.success && <Icon name="checkCircle" color={theme.palette.success.default} />}
                {!entry.success && <Icon name="warning" color={theme.palette.danger.default} />}
              </Stack>
            </ListRow>
          )}
        />
      )}
    </Modal>
  );
};
