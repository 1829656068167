import { IconButton, Layout, Tooltip } from "@introist/react-foundation/v2";
import { EmployeeFieldType } from "services/rpc/RpcProvider";
import { FieldNameInput, FieldTypeSelect, SelectFieldOptions } from "./EmployeeFieldInputs";

export type EmployeeFieldFormValues = {
  name: string;
  type?: EmployeeFieldType;
  options?: string[];
};

type Props = {
  onDelete?: VoidFunction;
  values: EmployeeFieldFormValues;
  onChange: (values: EmployeeFieldFormValues) => void;
};

export const EmployeeFieldForm = ({ values, onChange, onDelete, ...rest }: Props) => {
  return (
    <Layout.Group vertical gap="xLarge" {...rest}>
      <FieldNameInput value={values.name} onChange={name => onChange({ ...values, name })} />
      <Layout.Group justifyContent="space-between" alignItems="flex-end">
        <FieldTypeSelect value={values.type} onSelect={type => onChange({ ...values, type })} />
        {onDelete && (
          <Tooltip tooltip="Delete field">
            <IconButton icon="trash" onClick={onDelete} />
          </Tooltip>
        )}
      </Layout.Group>

      {values.type === "select" && (
        <SelectFieldOptions
          options={values.options || []}
          onChange={options => onChange({ ...values, options })}
        />
      )}
    </Layout.Group>
  );
};
