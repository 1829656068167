import { DataForm, Stack } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { prefixForm } from "utils/FormUtils";

import { MeetingHostSelect } from "./MeetingHostSelect";
import { MeetingAttendeeSelect } from "./MeetingAttendeeSelect";

type MeetingParticipantsEditorProps = {
  form: DataForm<any>;
  formPrefix?: string;
};

export const MeetingParticipantsEditor = ({ form, formPrefix }: MeetingParticipantsEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);

  return (
    <StepEditorAccordion title="Host & Attendees">
      <Stack vertical gap="large">
        <StepEditorAccordion.Row label="Host">
          <MeetingHostSelect
            value={prefixedForm.get("hostId")}
            onChange={prefixedForm.set("hostId")}
          />
        </StepEditorAccordion.Row>

        <StepEditorAccordion.MultiOptionRow label="Attendees">
          <MeetingAttendeeSelect
            value={prefixedForm.get("attendees")}
            onChange={prefixedForm.set("attendees")}
          />
        </StepEditorAccordion.MultiOptionRow>
      </Stack>
    </StepEditorAccordion>
  );
};
