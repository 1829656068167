import { useState } from "react";
import styled from "styled-components";

import {
  BaseModal,
  Button,
  Checkbox,
  H4,
  Icon,
  Layout,
  SkeletonLoader,
  Stack,
  Tag,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";
import { AttributeIcon, FittedTitle, HighlightText, TextInput } from "components/atoms";
import { useDebouncedSearch } from "hooks";
import { ListEmptyState } from "components/organisms";
import { GroupedEmployeeField, HrisDetails, HrisField, api } from "services/rpc/RpcProvider";

type Props = {
  hris: HrisDetails;
  hrisFields: GroupedEmployeeField[];
  onImported: VoidFunction;
};

const List = styled.div`
  max-height: 50vh;
  overflow: hidden;

  :hover {
    overflow-y: auto;
  }
`;

const AvailableFieldRow = styled(Stack)`
  cursor: pointer;
  padding: var(--spacing-small);
  border: 1px solid transparent;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);
  }
`;

const EmptyState = () => (
  <ListEmptyState padding="xLarge" animate={false} maxWidthRem={35}>
    <ListEmptyState.Title>No fields found</ListEmptyState.Title>
    <Title style={{ textAlign: "center" }}>
      Try searching for something else or add a custom field
    </Title>
  </ListEmptyState>
);

export const ImportHrisEmployeeFields = ({ hris, hrisFields, onImported, ...rest }: Props) => {
  const toast = useToast();
  const { data: allHrisFields, isLoading: allHrisFieldsLoading } = api.hris.fields.useQuery({});
  const { mutateAsync: createFields, isLoading: createFieldsLoading } =
    api.employees.fields.createMany.useMutation({});

  const [selected, setSelected] = useState<HrisField[]>([]);

  const { inputValue, query, handleInputChange } = useDebouncedSearch();

  const getFilteredFields = () => {
    if (!allHrisFields || isEmpty(allHrisFields)) return [];

    // filter out already imported fields using hrisFields
    const importableHrisFields = allHrisFields.filter(f => {
      const alreadyImported = hrisFields.some(hf => hf.variable === f.name);
      return !alreadyImported;
    });

    return importableHrisFields.filter(f => {
      return f.name.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  const filteredallHrisFields = getFilteredFields();

  const onImportFields = async () => {
    if (isEmpty(selected)) return;

    const variables = selected.map(f => ({
      type: f.type,
      name: f.name,
      source: hris.integrationId,
      sourcePath: f.jsonPath
    }));

    await createFields(variables, {
      onSuccess: () => {
        toast.success("Fields imported");
        setSelected([]);
        onImported();
      },
      onError: () => toast.error("Failed to import fields")
    });
  };

  return (
    <Stack vertical lastChildToEnd {...rest}>
      <TextInput
        size="small"
        placeholder="Search field"
        value={inputValue}
        onChange={handleInputChange}
        endAdornment={<Icon subdued name="search" />}
      />

      <List>
        {allHrisFieldsLoading && (
          <Stack vertical gap="small">
            <SkeletonLoader height={58} />
            <SkeletonLoader height={58} />
            <SkeletonLoader fadeOut height={58} />
          </Stack>
        )}
        {query && isEmpty(filteredallHrisFields) && <EmptyState />}
        {filteredallHrisFields?.map((f, index) => (
          <AvailableFieldRow
            key={`available-field-${f.jsonPath}-${index}`}
            onClick={() => {
              if (selected.includes(f)) {
                setSelected(selected.filter(sf => sf !== f));
              } else {
                setSelected([...selected, f]);
              }
            }}
          >
            <Checkbox
              checked={selected.includes(f)}
              onChange={checked => {
                if (checked) {
                  setSelected([...selected, f]);
                } else {
                  setSelected(selected.filter(sf => sf !== f));
                }
              }}
            />
            <AttributeIcon ghosted />
            <Stack gap="none" vertical>
              <Title variant="bold" style={{ flexShrink: 0 }}>
                <HighlightText search={query} text={f.name} />
              </Title>
              <FittedTitle showTooltip maxLength={63}>
                {f.jsonPath}
              </FittedTitle>
            </Stack>
          </AvailableFieldRow>
        ))}
      </List>
      <Layout.Divider />

      <Button
        disabled={isEmpty(selected) || createFieldsLoading}
        variant="outlined"
        endAdornment={<Tag>{selected.length.toString()}</Tag>}
        onClickWithLoading={onImportFields}
      >
        Add selected
      </Button>
    </Stack>
  );
};

export const ImportHrisEmployeeFieldsModal = ({
  open,
  hris,
  onClose,
  ...rest
}: Props & {
  open: boolean;
  onClose: VoidFunction;
}) => {
  return (
    <BaseModal
      open={open}
      header={<H4>Import fields from data sources</H4>}
      onClose={onClose}
      style={{ width: "100%", maxWidth: 640 }}
      closeOnOutsideClick
    >
      <ImportHrisEmployeeFields hris={hris} {...rest} />
    </BaseModal>
  );
};
