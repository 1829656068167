import { TextInput } from "components/atoms";

export type ApiTokenConfig = {
  apiToken: string;
};

type ApiTokenConfigFieldsProps = {
  config: ApiTokenConfig;
  onChange: (config: object) => void;
};

export const ApiTokenConfigFields = ({ config, onChange }: ApiTokenConfigFieldsProps) => {
  return (
    <div>
      <TextInput
        type="password"
        label="API token"
        placeholder="Enter your API token"
        value={config.apiToken}
        onChange={apiToken => onChange({ apiToken })}
      />
    </div>
  );
};
