import { ComponentPropsWithoutRef, forwardRef } from "react";
import styles from "./Block.module.scss";
import cx from "classnames";

type Props = ComponentPropsWithoutRef<"div"> & {
  blended?: boolean;
  compact?: boolean;
};

export const Block = forwardRef<HTMLDivElement, Props>(
  ({ blended, compact, ...props }: Props, ref) => {
    const className = cx(
      styles.Block,
      { [styles.Blended]: blended, [styles.Compact]: compact },
      props.className
    );

    return <div {...props} className={className} ref={ref} />;
  }
);
