import {
  EQ,
  eqSchema,
  GT,
  gtSchema,
  GTE,
  gteSchema,
  LT,
  ltSchema,
  LTE,
  lteSchema,
  IN,
  inSchema,
  REGEX,
  regexSchema,
  includesSchema
} from "@introist/introist-commons/dist/object-filter";
import { z } from "zod";
import { Comparator } from "./types";

export const buildComparatorSchema = (value: string, key: Comparator): ComparatorTransform => {
  switch (key) {
    case "in":
      return { in: value.split(",").map(v => v.trimStart().trimEnd()) };
    case "nin":
      return { not: { in: value.split(",").map(v => v.trimStart().trimEnd()) } };
    case "eq":
      return { eq: value };
    case "gt":
      return { gt: value };
    case "ngt":
      return { not: { gt: value } };
    case "gte":
      return { gte: value };
    case "ngte":
      return { not: { gte: value } };
    case "lt":
      return { lt: value };
    case "nlt":
      return { not: { lt: value } };
    case "lte":
      return { lte: value };
    case "nlte":
      return { not: { lte: value } };
    case "ne":
      return { not: { eq: value } };
    case "startswith":
      return { regex: `^${value}`, options: "i" };
    case "endswith":
      return { regex: `${value}$`, options: "i" };
    case "notstartswith":
      return { not: { regex: `^${value}`, options: "i" } };
    case "notendswith":
      return { not: { regex: `${value}$`, options: "i" } };
    case "includes":
      return { includes: value };
    case "notincludes":
      return { not: { includes: value } };
    case "exists":
      return { exists: true };
    case "notexists":
      return { exists: false };
    default:
      throw new Error(`Unknown comparator ${key}`);
  }
};

export const eqTransformSchema = eqSchema;
export const gtTransformSchema = gtSchema;
export const notGtTransformSchema = z.object({
  not: gtSchema
});
export const gteTransformSchema = gteSchema;
export const notGteTransformSchema = z.object({
  not: gteSchema
});
export const ltTransformSchema = ltSchema;
export const notLtTransformSchema = z.object({
  not: ltSchema
});
export const lteTransformSchema = lteSchema;
export const notLteTransformSchema = z.object({
  not: lteSchema
});
export const neTransformSchema = z.object({
  not: eqSchema
});
export const inTransformSchema = inSchema;
export const ninTransformSchema = z.object({
  not: inSchema
});
export const swTransformSchema = regexSchema.refine(regexp => regexp.regex.startsWith("^"));
export const ewTransformSchema = regexSchema.refine(regexp => regexp.regex.endsWith("$"));
export const nswTransformSchema = z.object({
  not: swTransformSchema
});
export const newTransformSchema = z.object({
  not: ewTransformSchema
});
export const includesTransformSchema = includesSchema;
export const notIncludesTransformSchema = z.object({
  not: includesSchema
});
export const existsTransformSchema = z.object({
  exists: z.boolean()
});

export type NE = z.infer<typeof neTransformSchema>;
export type NGT = z.infer<typeof notGtTransformSchema>;
export type NGTE = z.infer<typeof notGteTransformSchema>;
export type NLT = z.infer<typeof notLtTransformSchema>;
export type NLTE = z.infer<typeof notLteTransformSchema>;
export type NIN = z.infer<typeof ninTransformSchema>;
export type NSW = z.infer<typeof nswTransformSchema>;
export type NEW = z.infer<typeof newTransformSchema>;
export type INC = z.infer<typeof includesTransformSchema>;
export type NINC = z.infer<typeof notIncludesTransformSchema>;
export type EXISTS = z.infer<typeof existsTransformSchema>;

export type ComparatorTransform =
  | NE
  | GT
  | NGT
  | NGTE
  | NLT
  | NLTE
  | GTE
  | LT
  | LTE
  | EQ
  | IN
  | NIN
  | REGEX
  | NSW
  | NEW
  | INC
  | NINC
  | EXISTS;

export const isExistsComparison = (comparator: string) =>
  ["exists", "notexists"].includes(comparator);
