import { Stack } from "@introist/react-foundation/v2";
import { useParams } from "react-router-dom";

import { JourneyStepV2, api } from "services/rpc/RpcProvider";
import { ListView, TopBarRoute } from "components/templates";
import { useMemo } from "react";
import { useNavigate } from "react-router";
import { JourneyStepTable } from "../../../journeys/JourneyStepTable";
import { useSearch } from "../../../../foundation";
import { AutomationHeader } from "../../components/AutomationHeader";
import { AutomationStepStatuses } from "./AutomationStepStatuses";
import { Skeletons } from "../../../../components/molecules";
import { AUTOMATION_ROUTES } from "../index";
import { AutomationStepDrawer } from "../../containers/AutomationStepDrawer";

export const AutomationRoute = () => {
  const navigate = useNavigate();
  const { stepId, automationId } = useParams();

  const { data: automation, refetch: refetchAutomation } = api.automations.find.useQuery({
    id: automationId!
  });

  const { data: journeySteps, refetch: refetchSteps } = api.journeys.steps.list.useQuery(
    { journeyId: automationId! },
    {
      enabled: !!automationId
    }
  );

  const refetch = async () => {
    await refetchSteps();
    await refetchAutomation();
  };

  const {
    filtered: filteredJourneySteps,
    search,
    setSearch
  } = useSearch(journeySteps, {
    fields: ["title", "stepType"]
  });

  const paths = useMemo(() => {
    const paths = [];
    paths.push({
      key: "automations",
      title: `Automations`,
      onClick: () => navigate("/automations")
    });

    paths.push({
      key: "employee",
      title: automation?.employeeName ?? "",
      onClick: () => navigate(`/employees/${automation?.employeeId}`)
    });

    paths.push({
      key: "automation",
      title: automation?.title ?? ""
    });

    return paths;
  }, [automation?.employeeId, automation?.employeeName, automation?.title, navigate]);

  return (
    <TopBarRoute paths={paths} medium>
      <Stack vertical gap="large" style={{ flex: 1 }}>
        {automation && <AutomationHeader automation={automation} onUpdate={() => refetch()} />}

        <ListView.Header
          title="Automation steps"
          search={{
            search,
            setSearch
          }}
          left={<AutomationStepStatuses steps={filteredJourneySteps} />}
        />
        {automation && (
          <JourneyStepTable
            automation={automation}
            journeySteps={filteredJourneySteps as JourneyStepV2[]}
          />
        )}
        {automation && !journeySteps && <Skeletons.List />}
      </Stack>
      {journeySteps && (
        <AutomationStepDrawer
          open={!!stepId}
          onClose={() =>
            navigate(AUTOMATION_ROUTES.automation.path.replace(":automationId", automationId!))
          }
          stepId={stepId}
        />
      )}
    </TopBarRoute>
  );
};
