import { useMemo } from "react";
import styled, { css } from "styled-components";
import { Card, Title, P, Button, useTheme } from "@introist/react-foundation/v2";
import ReactMarkdown from "react-markdown";

import {
  ActionsBlock,
  MarkdownBlockSchema,
  MarkdownBlock,
  SentBy,
  SlackBlockMessage,
  ActionsBlockSchema,
  ImageBlockSchema,
  ImageBlock
} from "./schema";
import { convertSlackLinksToMarkdown } from "./SlackLinkReplacer";

type Props = {
  sender?: SentBy;
  users?: string[];
  channel?: string;
  message: SlackBlockMessage;
  showRecipients?: boolean;
};

const ContentGrid = styled.div<{ $titleWidth?: number }>`
  display: grid;
  grid-template-columns: ${props => props.$titleWidth ?? "90px"} 1fr;
  gap: var(--spacing-small);
  row-gap: var(--spacing-large);
  align-items: center;
  justify-content: flex-start;
`;

const SlackCard = styled(Card)`
  padding: var(--spacing-xLarge);
  display: flex;
  gap: var(--spacing-xLarge);
`;

const MessageContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    max-width: 360px;
    word-wrap: break-word;
  }
`;

const SlackActions = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

export const ReadOnlySlackMessage = ({
  sender,
  message,
  channel,
  users,
  showRecipients = true
}: Props) => {
  const { theme } = useTheme();

  const renderedBlocks = useMemo(() => {
    return message.map(block => {
      if (MarkdownBlockSchema.safeParse(block).success) {
        const mrkdwn = block as MarkdownBlock;
        return (
          <P>
            <ReactMarkdown>{convertSlackLinksToMarkdown(mrkdwn.text.text)}</ReactMarkdown>
          </P>
        );
      }
      if (ActionsBlockSchema.safeParse(block).success) {
        const actions = block as ActionsBlock;
        return (
          <SlackActions>
            {actions.elements.map(action => (
              <Button variant="outlined" disabled>
                {action.text.text}
              </Button>
            ))}
          </SlackActions>
        );
      }
      if (ImageBlockSchema.safeParse(block).success) {
        const image = block as ImageBlock;
        return <img src={image.imageUrl} alt={image.altText} style={{ maxWidth: "250px" }} />;
      }

      return null;
    });
  }, [message]);

  const to = useMemo(() => {
    if (channel) return `#${channel}`;
    if (users) return users.join(", ");
    return "Unknown recipients";
  }, [channel, users]);

  return (
    <>
      {showRecipients && (
        <ContentGrid style={{ marginBottom: theme.spacing.xLarge }}>
          <Title>To</Title>
          <Title variant="bold">{to}</Title>
        </ContentGrid>
      )}
      <SlackCard>
        <SenderIcon sender={sender} />
        <MessageContent>
          <Title variant="bold">{sender?.name ?? "Unknown sender"}</Title>
          {renderedBlocks}
        </MessageContent>
      </SlackCard>
    </>
  );
};

const iconBase = css`
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: var(--rounding-small);
`;

const SenderImg = styled.img`
  ${iconBase};
`;

const SenderInitials = styled.div`
  ${iconBase};
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--palette-primary-default);
`;

type SenderIconProps = {
  sender?: SentBy;
};

const SenderIcon = ({ sender }: SenderIconProps) => {
  const initials =
    sender?.name
      .split(" ")
      .map(_ => _.charAt(0))
      .join("")
      .toUpperCase() ?? "";

  if (sender?.iconUrl) return <SenderImg src={sender.iconUrl} alt="sender" />;
  return (
    <SenderInitials>
      <Title variant="bold" color="white">
        {initials}
      </Title>
    </SenderInitials>
  );
};
