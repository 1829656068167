import { ReadOnlyStepProps } from "../useStepEditor";

import { Block } from "../../../../components/atoms";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { useAutomationStep } from "modules/automations/hooks/useAutomationStep";
import { ReadOnlyMessage } from "modules/flows/components/ReadOnlyMessage";
import { GoodyProductsOptions } from "./GoodyProducts";

export const GoodySendGiftViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  const product = GoodyProductsOptions.find(_ => _.key === resolvedData.productId);

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Gift Details" defaultOpen={true}>
          <StepEditorAccordion.Row label="Recipient">
            <Stack>
              {<Icon name="person" />}
              <Title variant="bold">
                {resolvedData.resolvedRecipient.firstName} {resolvedData.resolvedRecipient.lastName}
              </Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Product">
            <Stack>
              {<Icon name="workflowEditor" />}
              <Title variant="bold">{product?.title}</Title>
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen={true}>
          <StepEditorAccordion.Row label="From">
            <Stack>
              <Title variant="bold">{resolvedData.resolvedFromName}</Title>
            </Stack>
          </StepEditorAccordion.Row>

          <ReadOnlyMessage message={resolvedData.resolvedMessage} />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
