import { api, DataSourceMappingEntry } from "../../../../services/rpc/RpcProvider";
import { Field, ModalV2, Select, Stack, useToast } from "@introist/react-foundation/v2";
import { useCallback, useMemo, useState } from "react";

import { useEmployeeFields } from "../../hooks/useEmployeeFields";
import { FormFooter } from "../../components/FormFooter";

type MappingEditorFormProps = {
  dataSourceId: string;
  defaultValue?: DataSourceMappingEntry;
  onSubmit: (value: DataSourceMappingEntry) => Promise<void>;
  onCancel: () => void;
};

export const MappingEditorForm = ({
  dataSourceId,
  defaultValue,
  onSubmit,
  onCancel
}: MappingEditorFormProps) => {
  const { data: dataSource } = api.employees.dataSources.find.useQuery({ dataSourceId });
  const { data: sourceFields } = api.employees.dataSources.fields.list.useQuery({
    sourceId: dataSourceId
  });
  const targetFields = useEmployeeFields();

  const [value, setValue] = useState<DataSourceMappingEntry>(
    defaultValue ?? ({} as DataSourceMappingEntry)
  );

  const sourceFieldOptions = useMemo(() => {
    return (sourceFields ?? []).map(f => ({
      key: f.jsonPath,
      title: f.name ?? f.jsonPath
    }));
  }, [sourceFields]);

  const targetFieldOptions = useMemo(() => {
    return (targetFields ?? []).map(f => ({
      key: f.variable,
      title: f.name
    }));
  }, [targetFields]);

  return (
    <Stack vertical gap="xLarge">
      <Field title={`${dataSource?.name ?? "Source"} field`}>
        <Select
          style={{ width: "100%" }}
          placeholder="Select source field"
          searchable
          attachToRef={false}
          options={sourceFieldOptions}
          value={value.sourceField}
          onSelect={opt => setValue({ ...value, sourceField: opt.key })}
          onEnter={sourceField => setValue({ ...value, sourceField })}
        />
      </Field>
      <Field title="Introist field">
        <Select
          style={{ width: "100%" }}
          placeholder="Select employee field"
          searchable
          attachToRef={false}
          options={targetFieldOptions}
          value={value.targetField}
          onSelect={opt => setValue({ ...value, targetField: opt.key })}
        />
      </Field>
      <FormFooter onCancel={onCancel} onSubmit={() => onSubmit(value as DataSourceMappingEntry)} />
    </Stack>
  );
};

export const MappingEditorModal = ({
  dataSourceId,
  defaultValue,
  open,
  onClose
}: {
  dataSourceId: string;
  defaultValue?: DataSourceMappingEntry;
  open: boolean;
  onClose: () => void;
}) => {
  const toast = useToast();

  const create = api.employees.dataSources.mappings.create.useMutation();
  const update = api.employees.dataSources.mappings.update.useMutation();
  const listMappings = api.useContext().employees.dataSources.mappings.list;

  const onSubmit = useCallback(
    async (value: DataSourceMappingEntry) => {
      if (!value.id) {
        return create
          .mutateAsync({ ...value, dataSourceId })
          .then(async () => {
            await listMappings.refetch();
            toast.success("Mapping created");
            onClose();
          })
          .catch(() => {
            toast.error("Failed to create mapping");
          });
      } else {
        const { id, ...updates } = value;

        return update
          .mutateAsync({ id, updates })
          .then(async () => {
            await listMappings.refetch();
            toast.success("Mapping updated");
            onClose();
          })
          .catch(() => {
            toast.error("Failed to update mapping");
          });
      }
    },
    [create, dataSourceId, listMappings, onClose, toast, update]
  );

  return (
    <ModalV2 open={open} onClose={onClose} title={defaultValue ? "Edit mapping" : "Create mapping"}>
      <MappingEditorForm
        dataSourceId={dataSourceId}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </ModalV2>
  );
};
