import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";

import { EmailMembers } from "./EmailMembers";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, IconButton, Stack, Title } from "@introist/react-foundation/v2";

export const EmailFormStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const resolved = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Form">
          <StepEditorAccordion.Row label="Form">
            <Stack>
              <Title variant="bold">{resolved.resolvedFormTitle}</Title>
              <IconButton
                size="small"
                variant="blended"
                icon="moveTo"
                onClick={() => window.open(`/forms/${resolved.form}`, "_blank")}
              ></IconButton>
            </Stack>
          </StepEditorAccordion.Row>
          {resolved.resolvedLinkedEmployee && (
            <StepEditorAccordion.Row label="Employee">
              <Title variant="bold">{resolved.resolvedLinkedEmployee}</Title>
            </StepEditorAccordion.Row>
          )}
          <StepEditorAccordion.Row label="Submission">
            {(step.executionData as any)?.submitted ? (
              <Stack>
                <Stack gap="xSmall">
                  <Icon name="check"></Icon>
                  <Title variant="bold">Completed</Title>
                </Stack>

                <IconButton
                  size="small"
                  variant="blended"
                  icon="moveTo"
                  onClick={() =>
                    window.open(
                      `/forms/${resolved.form}/responses/${resolved.resolvedFormResponseId}`,
                      "_blank"
                    )
                  }
                ></IconButton>
              </Stack>
            ) : (
              <Title>No submission</Title>
            )}
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <EmailMembers to={resolved.resolvedTo} from={resolved.resolvedFrom} replyTo="" />
      </Block>
      <Block blended compact>
        <ReadOnlyMessage subject={resolved.resolvedSubject} message={resolved.resolvedMessage} />
      </Block>
    </>
  );
};
