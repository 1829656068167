import { api } from "../../../services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import groupBy from "lodash.groupby";
import { ListView, useSearch } from "../../../foundation";
import { Stack, Title, Button, Icon } from "@introist/react-foundation/v2";
import { Avatar } from "../../../components/molecules";
import { EmployeeJourneySelector } from "../../../modules/employees/routes/EmployeesRoute/components/EmployeeJourneySelector";
import { ListRow } from "../../../components/organisms";
import { useNavigate } from "react-router";
import { formatDate } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";
import { orderBy } from "lodash";
import { StartAutomationModal } from "../../../modules/automations/containers/StartAutomationModal";

type EmployeeData = {
  employeeId: string;
  employeeName: string;
  journeys: any[];
  [index: string]: any;
};

export const OnboardingAutomationsView = () => {
  const navigate = useNavigate();

  const { data: workflows } = api.workflows.v4.list.useQuery({ module: "onboarding" });

  const { data: employees } = api.employees.v4.list.useQuery({ fields: ["startdate"] });

  const { data: journeys, refetch } = api.automations.list.useQuery(
    {
      stages: ["draft", "active"]
    },
    {
      enabled: !!workflows
    }
  );

  const [enrollOpen, setEnrollOpen] = useState(false);

  const { search, setSearch } = useSearch();

  const activeEmployees: EmployeeData[] | undefined = useMemo(() => {
    if (!journeys) return undefined;
    const grouped = groupBy(journeys, j => j.employeeId);
    const mapped: EmployeeData[] = Object.keys(grouped).map(employeeId => {
      const employee = employees?.find(e => e.id === employeeId);
      const journeys = grouped[employeeId];
      return {
        employeeId,
        employeeName: journeys[0].employeeName ?? "",
        ...(employee ?? {}),
        journeys
      };
    });

    const filtered = mapped.filter(e => {
      if (!search) return true;
      return e.employeeName.toLowerCase().includes(search.toLowerCase());
    });

    return orderBy(filtered, [e => e.startDate, e => e.employeeName.toLowerCase()], "asc");
  }, [journeys, employees, search]);

  return (
    <Stack vertical gap="xLarge">
      <ListView.Header
        title="Automations"
        count={Object.keys(activeEmployees ?? []).length}
        create={
          <Button
            variant="outlined"
            size="small"
            startAdornment={<Icon name="plus" />}
            onClick={() => setEnrollOpen(true)}
          >
            Enroll employee
          </Button>
        }
        search={{ search, setSearch }}
      />
      <ListView.List
        data={activeEmployees}
        renderRow={e => (
          <ListRow
            key={e.employeeId}
            gridTemplateColumns="1fr auto"
            clickable
            onClick={c => {
              const isBlank = () => {
                if (c.ctrlKey || c.metaKey) return { target: "_blank" };
              };

              if (isBlank()) {
                window.open(`/employees/${e.employeeId}`, "_blank");
              } else navigate(`/employees/${e.employeeId}`);
            }}
          >
            <Stack>
              <Avatar nameOrEmail={e.employeeName ?? ""} />
              <Title variant="bold">{e.employeeName}</Title>
              {e.startdate && (
                <Title>Start date {formatDate(e.startdate, DateFormats.date.withDay.long)}</Title>
              )}
            </Stack>
            <EmployeeJourneySelector journeys={e.journeys} />
          </ListRow>
        )}
        empty={
          search
            ? undefined
            : {
                title: "No active onboarding employees",
                message: "Check your triggers or enroll employees manually.",
                icon: "hourglass"
              }
        }
      />
      <StartAutomationModal
        open={enrollOpen}
        module="onboarding"
        onClose={() => setEnrollOpen(false)}
        onStart={async (employeeId: string, journeyId: string) => {
          await refetch();
          setEnrollOpen(false);
          window.open(`/employees/${employeeId}/journeys/${journeyId}`, "_blank");
        }}
      />
    </Stack>
  );
};
