import { Stack, Title } from "@introist/react-foundation/v2";
import { StageIcon } from "../../automations/components/StageIcon";
import { Batch } from "../useBatches";
import styled from "styled-components";

const StageButton = styled(Stack).attrs({ as: "button" })<{
  $active?: boolean;
}>`
  background-color: transparent;
  border: none;
  border-radius: var(--rounding-medium);
  padding: 0.25rem var(--spacing-medium);
  transition: border-color 140ms ease;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: ${({ $active }) =>
    $active ? "var(--palette-surface-default)" : "transparent"};
`;

export const StageFilter = ({
  value,
  onChange,
  batches
}: {
  value: string[];
  onChange: (value: string[]) => void;
  batches?: Batch[];
}) => {
  const toggleValue = (val: string) => {
    if (value.includes(val)) onChange(value.filter(v => v !== val));
    else onChange([...value, val]);
  };

  const stageStatusButton = (stage: string) => (
    <StageButton gap="small" onClick={() => toggleValue(stage)} $active={value.includes(stage)}>
      <StageIcon active={value.includes(stage)} stage={stage} />
      {batches && <Title variant="bold">{batches.filter(b => b.stage === stage).length}</Title>}
      <Title>{stage.charAt(0).toUpperCase() + stage.slice(1)}</Title>
    </StageButton>
  );

  return (
    <Stack>
      {stageStatusButton("draft")}
      {stageStatusButton("active")}
      {stageStatusButton("completed")}
    </Stack>
  );
};
