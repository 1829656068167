import styled from "styled-components";
import { Card, H4, Icon, IconName, Stack, Title, useTheme } from "@introist/react-foundation/v2";
import { ReactNode } from "react";

export type EmptyListProps = {
  title?: ReactNode;
  message?: string | ReactNode;
  icon?: IconName;
  border?: boolean;
  dark?: boolean;
  action?: ReactNode;
};

const StyledNoResults = styled(Stack)`
  flex: 1;
  user-select: none;
  padding: var(--spacing-xxLarge);
`;

const StyledTitle = styled(Title)`
  text-align: center;
  max-width: 700px;
`;

export const Empty = ({
  title = "Nothing here",
  message = "Create something or try a different search",
  icon = "search",
  border = true,
  dark = false,
  action,
  ...rest
}: EmptyListProps) => {
  const { theme } = useTheme();

  return (
    <Card
      style={{
        borderWidth: border ? "1px" : "0",
        background: dark ? theme.palette.surface.dimmed : undefined
      }}
      {...rest}
    >
      <StyledNoResults vertical justifyContent="center" alignItems="center" gap="xLarge">
        <Icon
          name={icon}
          color={theme.palette.foreground.dimmed}
          style={{ width: `2rem`, height: `2rem` }}
        />
        <Stack vertical gap="xxLarge" alignItems="center">
          <Stack vertical alignItems="center" gap="xLarge">
            <H4>{title}</H4>
            <StyledTitle>{message}</StyledTitle>
            {action}
          </Stack>
        </Stack>
      </StyledNoResults>
    </Card>
  );
};
