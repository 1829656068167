import { api } from "services/rpc/RpcProvider";
import { ExpressionField, ExpressionFieldType } from "../RecursiveExpressionEditor";

export const useEmployeeFieldsToExpressionFields = () => {
  const employeeFields = api.employees.fields.list.useQuery({});

  const withoutGroup = (employeeFields.data || []).filter(f => f.type !== "group");

  const expressionFields: ExpressionField[] = withoutGroup.map(f => {
    return {
      variable: f.variable,
      name: f.name,
      type: f.type as ExpressionFieldType,
      options: f.options ? f.options : undefined
    };
  });

  return {
    isLoading: employeeFields.isLoading,
    expressionFields
  };
};
