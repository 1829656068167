import styled from "styled-components";
import { Card, Icon, P, Stack, Tag } from "@introist/react-foundation/v2";

import { capitalizeFirstLetter } from "utils/Text";
import { AttributeIconBackground } from "components/atoms";

type Props = {
  workflowId: string;
  targetDate: string;
  loading?: boolean;
};

export const StyledTargetDateCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-small);
  border-color: var(--palette-purple-dimmed);
`;

export const CapitalizedTitle = styled(P)`
  font-weight: 500;
  margin-left: -28px;
`;

export const TargetDateCard = ({ targetDate, workflowId, loading, ...rest }: Props) => {
  return (
    <StyledTargetDateCard {...rest}>
      <AttributeIconBackground $size="large" $ghosted>
        <Icon name="calendarTarget" />
      </AttributeIconBackground>

      <Stack justifyContent="center" style={{ flex: 1 }}>
        {targetDate === "target date" && !loading && <Tag>Missing target date</Tag>}

        {targetDate !== "target date" && (
          <CapitalizedTitle>{capitalizeFirstLetter(targetDate)}</CapitalizedTitle>
        )}
      </Stack>
    </StyledTargetDateCard>
  );
};
