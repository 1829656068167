import { useActions, useToast } from "@introist/react-foundation/v2";
import { EmployeeGroup, EmployeeGroupInput, api } from "services/rpc/RpcProvider";

export const useEmployeeGroups = (groupId?: string) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const {
    data: employeeGroupsData,
    isLoading: employeeGroupsLoading,
    refetch: refetchGroups
  } = api.employees.groups.list.useQuery({});

  const employeeGroups = {
    data: employeeGroupsData,
    loading: employeeGroupsLoading,
    refetch: refetchGroups
  };

  const { data: employeeGroup, refetch: refetchGroup } = api.employees.groups.find.useQuery(
    { groupId: groupId || "" },
    { enabled: !!groupId }
  );

  const { mutateAsync: createMutation, isLoading: createLoading } =
    api.employees.groups.create.useMutation({});

  const { mutateAsync: updateMutation, isLoading: updateLoading } =
    api.employees.groups.update.useMutation({});

  const getEmployeeGroup = (employeeGroupId: string) => {
    if (!employeeGroupsData) return null;
    return employeeGroupsData.find(g => g.id === employeeGroupId);
  };

  const create = async (
    employeeGroup: EmployeeGroupInput,
    callback?: (newGroup: EmployeeGroup) => void
  ) => {
    await createMutation(employeeGroup, {
      onError: () => toast.error("Failed to create employee group"),
      onSuccess: newGroup => {
        toast.success("Employee group created");
        callback?.(newGroup);
      }
    });
  };

  const update = async (
    groupId: string,
    updates: EmployeeGroupInput,
    callback: (updatedGroup: EmployeeGroup) => void
  ) => {
    await updateMutation(
      {
        groupId,
        updates
      },
      {
        onError: () => toast.error("Failed to update employee group"),
        onSuccess: updatedGroup => {
          toast.success("Employee group updated");
          callback?.(updatedGroup);
        }
      }
    );
  };

  const deleteMutation = onConfirmAction(
    async (groupId: string, callback?: VoidFunction) => {
      await updateMutation(
        {
          groupId,
          updates: {
            archived: true
          }
        },
        {
          onError: () => toast.error("Failed to update employee group"),
          onSuccess: updatedGroup => {
            toast.success("Employee group updated");
            callback?.();
          }
        }
      );
    },
    {
      title: "Delete Employee Group",
      description:
        "Are you sure you want to delete this employee group? This action cannot be undone.",
      confirmTitle: "Delete"
    }
  );

  const createEmployeeGroup = {
    mutateAsync: create,
    loading: createLoading
  };

  const updateEmployeeGroup = {
    mutateAsync: update,
    loading: updateLoading
  };

  const deleteEmployeeGroup = {
    mutateAsync: deleteMutation,
    loading: updateLoading
  };

  return {
    employeeGroups,
    employeeGroup,
    refetchGroup,
    refetchGroups,
    employeeGroupsLoading,
    getEmployeeGroup,
    createEmployeeGroup,
    deleteEmployeeGroup,
    updateEmployeeGroup
  };
};
