import { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { IconButton, Layout } from "@introist/react-foundation/v2";
import { a, useSpring } from "@react-spring/web";
import useMeasure from "react-use-measure";

import { List, ListRow } from "components/organisms";

type Props = {
  header: ReactNode;
  onToggleOpen?: VoidFunction;
  open?: boolean;
  emptyState?: ReactNode;
  children: ReactNode;
};

const Content = styled.div`
  background-color: var(--palette-background-default);

  > :last-child {
    border-bottom: none;
  }
`;

const Header = styled(ListRow)<{ $open: boolean }>`
  background-color: transparent;
  border-bottom: ${props => (props.$open ? `1px solid var(--palette-border-subdued)` : "none")};
  position: relative;
  z-index: 1;

  :hover {
    background: var(--palette-background-default) !important;
  }
`;

const StyledIconButton = styled(IconButton)<{ $open?: boolean }>`
  svg {
    transition: transform 140ms linear;
    transform-origin: center;
  }
  svg path {
    fill: var(--palette-foreground-subdued);
  }

  ${({ $open }) =>
    $open &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

export const CollapseList = ({ open, header, onToggleOpen, emptyState, children }: Props) => {
  const firstRenderRef = useRef(true);
  const [childrenMeasureRef, { height }] = useMeasure();

  const heightSpring = useSpring({
    height: open ? height : 0,
    immediate: firstRenderRef.current,
    config: {
      tension: 200
    }
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      firstRenderRef.current = false;
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <List>
      <Header onClick={onToggleOpen} gridTemplateColumns="1fr auto" $open={!!open}>
        <Layout.Group>{header}</Layout.Group>
        {onToggleOpen && (
          <StyledIconButton icon="chevronDown" onClick={onToggleOpen} $open={open} />
        )}
      </Header>
      <a.div style={heightSpring}>
        <Content ref={childrenMeasureRef}>
          {emptyState && emptyState}
          {children}
        </Content>
      </a.div>
    </List>
  );
};
