import { Icon, IconName, useTheme } from "@introist/react-foundation/v2";

type StageIconProps = {
  stage: string;
  active?: boolean;
};

export const StageIcon = ({ stage, active, ...rest }: StageIconProps) => {
  const { theme } = useTheme();

  const defaultColor = theme.palette.foreground.subdued;

  const resolveIcon = (stage: string): { name: IconName | null; color: string | null } => {
    switch (stage) {
      case "draft":
        return {
          name: "progress",
          color: active ? theme.palette.warning.default : defaultColor
        };
      case "inprogress":
      case "active":
        return { name: "time", color: active ? theme.palette.primary.default : defaultColor };
      case "ended":
      case "completed":
        return {
          name: "checkCircle",
          color: active ? theme.palette.success.default : defaultColor
        };
      default:
        return {
          name: null,
          color: null
        };
    }
  };

  const { name, color } = resolveIcon(stage);

  if (!name) return null;

  return <Icon {...rest} name={name} color={color || undefined} />;
};
