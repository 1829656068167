import { Button, Card, IconButton, Modal, Select, Stack } from "@introist/react-foundation/v2";
import { Empty, OrderableList } from "../blocks";

import { useCallback, useEffect, useState } from "react";
import { FormFooter } from "../../modules/datasources/components/FormFooter";
import { ShortUuid } from "../../utils/Utils";

type Field = {
  key: string;
  title?: string;
};

type FieldTransformSpec = {
  id: string;
  sourceField?: string | null;
  code?: string | null;
  targetField: string;
};

type DataTransformEditorProps = {
  sourceFields?: Field[];
  targetFields?: Field[];
  spec: FieldTransformSpec[];
  onChange: (spec: FieldTransformSpec[]) => unknown;
};

export const DataTransformEditorModal = ({
  open,
  onClose,
  title,
  description,
  onSave,
  initialSpec,
  ...rest
}: Omit<DataTransformEditorProps, "spec" | "onChange"> & {
  open: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  onSave: (spec: FieldTransformSpec[]) => Promise<unknown>;
  initialSpec: FieldTransformSpec[];
}) => {
  const [spec, setSpec] = useState(initialSpec);

  useEffect(() => {
    setSpec(initialSpec);
  }, [initialSpec]);

  return (
    <Modal
      title={title}
      description={description}
      open={open}
      onClose={onClose}
      maxContentHeight="80vh"
    >
      <Stack vertical style={{ height: "80vh" }}>
        <div style={{ flex: 1, overflowY: "auto" }}>
          <DataTransformEditor spec={spec} onChange={setSpec} {...rest} />
        </div>
        <div>
          <FormFooter
            onCancel={onClose}
            onSubmit={() => onSave(spec.filter(f => f.targetField !== "")).then(onClose)}
            submit="Save changes"
          />
        </div>
      </Stack>
    </Modal>
  );
};

export const DataTransformEditor = ({
  spec,
  onChange,
  sourceFields,
  targetFields
}: DataTransformEditorProps) => {
  const addNew = useCallback(() => {
    onChange([...spec, { id: ShortUuid.generate(), targetField: "" }]);
  }, [spec, onChange]);

  return (
    <Stack vertical gap="large" style={{ width: "600px" }}>
      {spec.length === 0 && (
        <Empty
          title="No transforms yet"
          message="Start by adding your first transform."
          icon="arrowsLeftRight"
          action={<Button onClick={addNew}>Add transform</Button>}
        />
      )}
      <OrderableList
        data={spec}
        onReorder={items => onChange(items)}
        renderRow={field => (
          <FieldCard
            field={field}
            sourceFields={sourceFields}
            targetFields={targetFields}
            onChange={field =>
              onChange(
                spec.map(f => {
                  if (f.id === field.id) return field;
                  return f;
                })
              )
            }
            onRemove={() => onChange(spec.filter(f => f.id !== field.id))}
          />
        )}
      />
      {spec.length > 0 && (
        <Button startIcon="plus" variant="outlined" style={{ margin: "auto" }} onClick={addNew}>
          Add Transform
        </Button>
      )}
    </Stack>
  );
};

const FieldCard = ({
  field,
  onChange,
  onRemove,
  sourceFields,
  targetFields
}: {
  field: FieldTransformSpec;
  onChange: (field: FieldTransformSpec) => void;
  onRemove: () => void;
  sourceFields?: Field[];
  targetFields?: Field[];
}) => (
  <Card>
    <Stack style={{ display: "grid", gridTemplateColumns: "1fr auto 1fr" }}>
      <Select
        searchable
        variant="blended"
        size="small"
        options={sourceFields ?? []}
        value={field.sourceField ?? undefined}
        placeholder={"source"}
        showAdornment={false}
        onSelect={opt => onChange({ ...field, sourceField: opt.key })}
        onEnter={text => onChange({ ...field, sourceField: text })}
      />
      {<IconButton icon={field.code ? "workflowEditor" : "arrowRight"} />}
      <Stack justifyContent="flex-end">
        <Select
          searchable
          variant="blended"
          size="small"
          options={targetFields ?? []}
          value={field.targetField}
          placeholder={"target"}
          showAdornment={false}
          onClear={onRemove}
          onSelect={opt => onChange({ ...field, targetField: opt.key })}
          onEnter={text => onChange({ ...field, targetField: text })}
        />
      </Stack>
    </Stack>
  </Card>
);
