import { useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";
import { GroupedEmployeeField, api } from "services/rpc/RpcProvider";

export const useAddUpdatedAtEmployeeField = (onFieldCreated?: VoidFunction) => {
  const toast = useToast();
  const { mutateAsync: addUpdatedAt } = api.employees.fields.createUpdatedAtField.useMutation({});
  const onAddUpdatedAt = useCallback(
    async ({ field }: { field: GroupedEmployeeField }) => {
      await addUpdatedAt(
        {
          variable: field.variable
        },
        {
          onSuccess: () => {
            toast.success("Updated At field created");
            onFieldCreated?.();
          },
          onError: () => {
            toast.error("Failed to create field");
          }
        }
      );
    },
    [toast, addUpdatedAt, onFieldCreated]
  );

  return {
    onAddUpdatedAt
  };
};
