import { useMemo } from "react";
import { IconName, useActions, useToast } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router";
import { api } from "../../../services/rpc/RpcProvider";
import { appRoutes } from "../../../AppRoutes";
import { useEventData } from "../routes/hooks";

export const useCompanyEventActions = (eventId: string) => {
  const navigate = useNavigate();
  const toast = useToast();
  const { onConfirmAction } = useActions();

  const { reload } = useEventData(eventId);
  const sync = api.companyEvents.sync.useMutation({});
  const archive = api.companyEvents.archive.useMutation({});
  const { refetch } = api.companyEvents.list.useQuery({});

  const onArchive = onConfirmAction(
    async () => {
      await archive
        .mutateAsync({ id: eventId })
        .then(() => {
          refetch();
          navigate(appRoutes.events.path);
        })
        .catch(() => {
          toast.error("Failed to archive event");
        });
    },
    {
      title: "Archive event",
      description: "Are you sure you want to archive this event?",
      confirmTitle: "Archive"
    }
  );

  const actions = useMemo(() => {
    return [
      {
        key: "sync",
        title: "Sync event with calendar",
        startAdornmentIcon: "arrowsLeftRight" as IconName,
        onClick: async () => {
          await sync
            .mutateAsync({ companyEventId: eventId })
            .then(async () => {
              await reload();
              toast.success("Event synced successfully");
            })
            .catch(() => {
              toast.error("Failed to sync event");
            });
        }
      },
      {
        key: "link",
        title: "Link another event",
        startAdornmentIcon: "link" as IconName,
        onClick: () => {
          navigate("#link-event", {
            relative: "path"
          });
        }
      },
      {
        key: "archive",
        title: "Archive event",
        startAdornmentIcon: "archive" as IconName,
        onClick: onArchive
      }
    ];
  }, [navigate, onArchive, eventId, reload, sync, toast]);

  return actions;
};
