import { useCallback, useState } from "react";
import { Icon, useToast, CircularLoader, IconName } from "@introist/react-foundation/v2";
import { useDropzone } from "react-dropzone";
import cx from "classnames";

import { uploadAsset } from "services/api/AssetsApi";

import styles from "./ImageUploadArea.module.scss";
import styled from "styled-components";

type ImageUploadAreaProps = {
  imageUrl?: string | null;
  alt?: string;
  icon?: IconName;
  onImageUploaded: (url: string) => unknown;
};

const SubduedIcon = styled(Icon)`
  fill: var(--palette-foreground-subdued);
`;

export const ImageUploadArea = ({ imageUrl, alt, icon, onImageUploaded }: ImageUploadAreaProps) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async files => {
      setLoading(true);
      try {
        const acceptedFile = files[0];
        const assetUrl = await uploadAsset(acceptedFile);
        onImageUploaded(assetUrl);
      } catch (e) {
        toast.error("Failed to upload image");
      } finally {
        setTimeout(() => setLoading(false), 500);
      }
    },
    [onImageUploaded, toast]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".svg"]
    },
    onDrop
  });

  return (
    <div {...getRootProps({ className: cx(styles.Root, { [styles.Dropzone]: !imageUrl }) })}>
      <input {...getInputProps()} />
      {!imageUrl && !loading && (
        <SubduedIcon
          name={icon || "logOut"}
          style={{ transform: !icon ? "rotate(90deg)" : undefined }}
        />
      )}
      {imageUrl && !loading && <img src={imageUrl} alt={alt || ""} />}
      {loading && <CircularLoader />}
    </div>
  );
};
