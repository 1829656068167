import { CircularLoader, H3, Layout, Tag, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { ReactNode } from "react";
import { DataSourceTestResult } from "../../../../services/rpc/RpcProvider";

type DataSourceTestOverviewProps = {
  result: DataSourceTestResult;
};

const StyledResultRow = styled(Layout.Group)`
  height: 2.25rem;

  > :first-child,
  > :last-child {
    float: left;
    flex-shrink: 0;
  }
`;

const Dot = styled.span`
  border-bottom: dotted 2px var(--palette-border-subdued);
  width: 100%;
  float: left;
  position: relative;
  display: block;
  height: 8px;
`;

const ResultRow = ({
  title,
  children,
  loading
}: {
  title: string;
  children: ReactNode;
  loading: boolean;
}) => {
  return (
    <StyledResultRow justifyContent="space-between" gap="large">
      <Title variant="bold">{title}</Title>
      <Dot />
      {loading && <CircularLoader colorVariant="primary" size="tiny" />}
      {!loading && children}
    </StyledResultRow>
  );
};
export const DataSourceTestOverview = ({ result }: DataSourceTestOverviewProps) => {
  return (
    <Layout.Group vertical>
      <ResultRow title="Connection" loading={false}>
        <Tag colorVariant={result?.connection ? "success" : "danger"}>
          {result?.connection ? "Connected" : "Failed"}
        </Tag>
      </ResultRow>
      <ResultRow title="Employees loaded" loading={false}>
        <H3>{result?.sourceEmployees}</H3>
      </ResultRow>
      <ResultRow title="Employees matched" loading={false}>
        <Layout.Group>
          <H3>{result?.matched}</H3>
        </Layout.Group>
      </ResultRow>
    </Layout.Group>
  );
};
