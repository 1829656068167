import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title, Tooltip } from "@introist/react-foundation/v2";
import { Avatar } from "../../../../components/molecules";
import { OptionalAttendeeButton } from "./OptionalAttendeeButton";

type MeetingAttendee = {
  email: string;
  timezone: string;
  optional?: boolean;
};

type MeetingAttendeesProps = {
  host: string;
  attendees: MeetingAttendee[];
};

export const MeetingAttendees = ({ host, attendees }: MeetingAttendeesProps) => {
  return (
    <StepEditorAccordion title="Host & Attendees">
      <StepEditorAccordion.Row label="Host">
        <Stack>
          <Icon name="person" />
          <Title variant="bold">{host}</Title>
        </Stack>
      </StepEditorAccordion.Row>
      <StepEditorAccordion.MultiOptionRow label="Attendees">
        <Stack vertical gap="small" style={{ padding: "8px 0" }}>
          {attendees.map(attendee => (
            <AttendeeViewer key={attendee.email} attendee={attendee} />
          ))}
        </Stack>
      </StepEditorAccordion.MultiOptionRow>
    </StepEditorAccordion>
  );
};

const AttendeeViewer = ({ attendee }: { attendee: MeetingAttendee }) => {
  return (
    <Stack alignItems="center" justifyContent="space-between">
      <Stack>
        <Avatar nameOrEmail={attendee.email} />
        <Title variant="bold">{attendee.email}</Title>
      </Stack>
      <Stack>
        <OptionalAttendeeButton onClick={() => {}} readOnly optional={attendee.optional} />
        <Tooltip tooltip={attendee.timezone} placement="left">
          <Icon name="globe" dimmed style={{ width: "12px", height: "12px" }} />
        </Tooltip>
      </Stack>
    </Stack>
  );
};
