import { DataForm } from "@introist/react-foundation/v2";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { FreshserviceFields } from "./blocks/FreshserviceFields";
import { prefixForm } from "utils/FormUtils";

interface Props {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix: string;
  readOnly?: boolean;
}

export const FreshserviceOnboardingEditor = ({ form, formPrefix, readOnly }: Props) => {
  const prefixedForm = prefixForm(form, formPrefix);

  return (
    <>
      <FreshserviceFields
        readOnly={readOnly}
        fields={prefixedForm.get(readOnly ? "resolvedFields" : "fields")}
        setFields={prefixedForm.set("fields")}
      />
    </>
  );
};
