import { IntroistDate, IntroistDateTime } from "../../../utils/dates";
import {
  deleteJson,
  generateMutation,
  generateQueryWithParameters,
  getJson,
  postJson,
  putJson
} from "../ApiBase";
import { JourneyStepStatusV2 } from "services/rpc/RpcProvider";

export interface JourneyOverview {
  id: string;
  properties: { [index: string]: string };
  workflowId: string;
  status: string;
  employeeId?: string;
  timezone: string;
  totalCalendarEventCount: number;
  completedCalendarEventCount: number;
  totalMessageCount: number;
  completedMessageCount: number;
  totalScheduledStepCount: number;
  completedScheduledStepCount: number;
  workflowName: string;
  workflowLabel?: string;
  progress?: {
    draft: number;
    scheduled: number;
    delivered: number;
    failing: number;
    completed: number;
    canceled: number;
  };
}

export interface StepDetails {
  id: string;
  start: IntroistDateTime;
  status: JourneyStepStatusV2;
  workflowStepVersion: number;
}

export type JourneyWithSteps = {
  id: string;
  firstname: string;
  lastname: string;
  targetdate: string;
  steps: StepDetails[];
};

export interface ListJourneysByWorkflowStep {
  stepId?: string;
}

export const listJourneysByWorkflowStep = ({
  stepId
}: ListJourneysByWorkflowStep): Promise<JourneyWithSteps[]> => {
  if (!stepId) return Promise.resolve([]);
  return getJson(`/v2/journeys/workflowstep/${stepId}`);
};

export interface CreateJourneyRequest {
  workflowId: string;
  employeeId: string;
  targetDate?: string;
  executeImmediately?: boolean;
  draft?: boolean;
}

export interface CreateJourneyResponse {
  id: string;
}

export const createJourney = (req: CreateJourneyRequest): Promise<CreateJourneyResponse> => {
  const { executeImmediately, ...data } = req;
  return postJson(`/v2/journeys?${executeImmediately ? "executeImmediately=true" : ""}`, data);
};

export interface StartJourneyRequest {
  journeyId: string;
}

export const startJourney = ({ journeyId }: StartJourneyRequest) => {
  return postJson(`/v2/journeys/${journeyId}/start`, {});
};

interface RescheduleJourneyRequest {
  journeyId: string;
  targetDate: IntroistDate;
}

export const rescheduleJourney = (req: RescheduleJourneyRequest) => {
  return postJson(`/v2/journeys/${req.journeyId}/reschedule`, { targetdate: req.targetDate });
};

export interface CancelJourneyRequest {
  journeyId: string;
}

export const cancelJourney = ({ journeyId }: CancelJourneyRequest): Promise<void> => {
  return deleteJson(`/v2/journeys/${journeyId}`);
};

interface AddStepRequest {
  journeyId: string;
  workflowId: string;
  workflowStepId: string;
}

export const addStepToJourney = (req: AddStepRequest) => {
  return putJson(`/v2/journeys/${req.journeyId}/steps/`, {
    workflowId: req.workflowId,
    stepId: req.workflowStepId
  });
};

export const useJourneyApi = () => ({
  create: generateMutation<CreateJourneyRequest, CreateJourneyResponse>(createJourney),
  start: generateMutation<StartJourneyRequest, any>(startJourney),
  reschedule: generateMutation<RescheduleJourneyRequest, unknown>(rescheduleJourney),
  cancel: generateMutation<CancelJourneyRequest, any>(cancelJourney),
  listByWorkflowStep: generateQueryWithParameters<ListJourneysByWorkflowStep, JourneyWithSteps[]>(
    ["journeys", "workflowstep"],
    listJourneysByWorkflowStep
  )
});
