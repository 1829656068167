import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";

export type TriggerFolder = RouterOutput["triggers"]["folders"]["find"];
export type TriggerFolderCreate = RouterInput["triggers"]["folders"]["create"];
export type TriggerFolderUpdate = RouterInput["triggers"]["folders"]["update"];

export const useTriggerFolders = (parentId: string | null) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: folders, refetch } = api.triggers.folders.list.useQuery(
    { parentId },
    {
      refetchOnMount: "always"
    }
  );

  const createOp = api.triggers.folders.create.useMutation();
  const updateOp = api.triggers.folders.update.useMutation();
  const deleteOp = api.triggers.folders.delete.useMutation();

  const create = useCallback(
    async (entry: Partial<TriggerFolder>) => {
      return createOp.mutateAsync(entry as TriggerFolderCreate).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (entry: TriggerFolder) => {
      const { id, name } = entry;
      const updates = { name };

      await updateOp.mutateAsync({ id, updates }).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const remove = onConfirmAction(
    async id => {
      await deleteOp.mutateAsync({ id });
      await refetch();
      toast.success("Folder deleted");
    },
    {
      icon: "trash",
      title: "Delete folder",
      description: "Are you sure you want to delete this folder?",
      confirmTitle: "Delete",
      cancelTitle: "Cancel"
    }
  );

  return {
    folders,
    create,
    update,
    remove
  };
};
