import { ReactNode } from "react";
import styled from "styled-components";
import { Layout, Title } from "@introist/react-foundation/v2";

import { StatusIcon } from "../StatusIcon";

type Props = {
  count?: number;
  hideLabel?: boolean;
  active?: boolean;
  startAdornment?: ReactNode;
  status: string;
  title?: string;
  onClick: (status: string) => void;
};

const StyledStatusButton = styled(Layout.Group).attrs({ as: "button" })<{ $active?: boolean }>`
  background-color: transparent;
  border: none;
  border-radius: var(--rounding-medium);
  padding: 0.25rem var(--spacing-medium);
  transition: border-color 140ms ease;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: ${({ $active }) =>
    $active ? "var(--palette-surface-subdued)" : "transparent"};

  :hover {
    background-color: var(--palette-surface-subdued);
  }
`;

export const StatusButton = ({
  count,
  startAdornment,
  status,
  active,
  title,
  hideLabel,
  onClick,
  ...rest
}: Props) => {
  return (
    <StyledStatusButton {...rest} gap="small" onClick={() => onClick(status)} $active={active}>
      {startAdornment || <StatusIcon active={active} status={status?.toLowerCase()} />}
      {count && <Title variant="bold">{count}</Title>}
      {!hideLabel && <Title>{title || status}</Title>}
    </StyledStatusButton>
  );
};
