import { generateQueryWithParameters, getJson } from "../../../services/api/ApiBase";

export type EmployeeAttributeType =
  | "text"
  | "number"
  | "select"
  | "date"
  | "email"
  | "datetime"
  | "group";

export interface EmployeeAttribute {
  id: string;
  organisationId: string;
  name: string;
  variable: string;
  type: EmployeeAttributeType;
  isRequired: boolean;
  data?: any;
  readonly: boolean;
  hidden: boolean;
  category?: string;
}

interface ListAttributesRequest {
  includeHidden: boolean;
}

const listAttributes = async ({
  includeHidden
}: ListAttributesRequest): Promise<EmployeeAttribute[]> => {
  const attributes = await getJson<EmployeeAttribute[]>("/v2/employees/attributes");
  if (includeHidden) return attributes;
  return attributes.filter(a => !a.hidden);
};

export const useEmployeeAttributeApi = () => ({
  list: generateQueryWithParameters<ListAttributesRequest, EmployeeAttribute[]>(
    ["employeeattributes"],
    listAttributes
  )
});
