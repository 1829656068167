import short from "short-uuid";

export interface IdentifiableObject {
  id: any;
}

export interface MappedObject<T> {
  [key: string]: T;
}

export const itemsToObject = <T extends IdentifiableObject>(array: T[]): MappedObject<T> =>
  array.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});

export const executeSequence = <T>(
  data: T[],
  executor: (data: T) => Promise<unknown>,
  continueOnError = true
): Promise<void> => {
  return data.reduce((progress, next) => {
    return progress.then(async () => {
      try {
        await executor(next);
      } catch (err) {
        if (!continueOnError) throw err;
      }
    });
  }, Promise.resolve());
};

export const ShortUuid = short();
