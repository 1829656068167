import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";

export type FormField = RouterOutput["forms"]["v2"]["fields"]["list"][0];
export type FormFieldType = RouterOutput["forms"]["v2"]["fields"]["list"][0]["type"];

export type FormFieldCreate = RouterInput["forms"]["v2"]["fields"]["create"];
export type FormFieldUpdate = RouterInput["forms"]["v2"]["fields"]["update"];

export const FormFieldOptions: { key: FormFieldType; title: string }[] = [
  {
    key: "text",
    title: "Text"
  },
  {
    key: "longtext",
    title: "Long text"
  },
  {
    key: "email",
    title: "Email"
  },
  {
    key: "date",
    title: "Date"
  },
  {
    key: "number",
    title: "Number"
  }
];

export const useFormFields = (formId: string, options?: any) => {
  const { data: formFields } = api.forms.v2.fields.list.useQuery({ formId }, options);

  return { formFields };
};

export const useFormField = (id: string, options?: any) => {
  const { data: field } = api.forms.v2.fields.find.useQuery({ id }, options);

  return { field };
};

export const useFormFieldActions = () => {
  const toast = useToast();
  const apiUtils = api.useContext();

  const listOp = apiUtils.forms.v2.fields.list;
  const findOp = apiUtils.forms.v2.fields.find;

  const createOp = api.forms.v2.fields.create.useMutation();
  const updateOp = api.forms.v2.fields.update.useMutation();
  const archiveOp = api.forms.v2.fields.archive.useMutation();
  const reorderOp = api.forms.v2.fields.reorder.useMutation();

  const create = useCallback(
    async (req: FormFieldCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch({ formId: req.formId });
          toast.success("Field created");
          return created;
        })
        .catch(e => {
          toast.error("Failed to create Form Field");
          throw e;
        });
    },
    [createOp, listOp, toast]
  );

  const update = useCallback(
    async (req: FormFieldUpdate) => {
      return updateOp
        .mutateAsync(req)
        .then(async updated => {
          await listOp.refetch();
          await findOp.refetch({ id: req.id });
          toast.success("Field updated");
          return updated;
        })
        .catch(e => {
          toast.error("Failed to update Form Field");
          throw e;
        });
    },
    [updateOp, listOp, findOp, toast]
  );

  const archive = useCallback(
    async (id: string) => {
      await archiveOp
        .mutateAsync({ id })
        .then(async () => {
          await listOp.refetch();
          toast.success("Field archived");
        })
        .catch(() => {
          toast.error("Failed to archive Field");
        });
    },
    [archiveOp, toast, listOp]
  );

  const reorder = useCallback(
    async (formId: string, ordering: string[]) => {
      await reorderOp
        .mutateAsync({ formId, ordering })
        .then(async () => {
          await listOp.refetch({ formId });
        })
        .catch(() => {
          toast.error("Failed to save Field ordering");
        });
    },
    [reorderOp, toast, listOp]
  );

  return { create, update, archive, reorder };
};
