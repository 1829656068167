import styled from "styled-components";
import { Button, Title } from "@introist/react-foundation/v2";
import { AttributeIcon } from "components/atoms";
import { ListEmptyState } from "components/organisms";

const StyledCustomFieldsEmptyState = styled(ListEmptyState)`
  > div {
    > :first-child > div {
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledAttributrIcon = styled(AttributeIcon)`
  background-color: var(--palette-foreground-dimmed);
  svg {
    fill: white !important;
  }
`;

export const CustomFieldsEmptyState = ({ ...rest }) => {
  return (
    <StyledCustomFieldsEmptyState {...rest} maxWidthRem={40}>
      <ListEmptyState.Image>
        <StyledAttributrIcon />
      </ListEmptyState.Image>
      <ListEmptyState.Title>No custom fields created</ListEmptyState.Title>
      <Title style={{ textAlign: "center" }}>
        Create custom employee fields to utilize the use of scalable automations
      </Title>
    </StyledCustomFieldsEmptyState>
  );
};

export const HrisEmployeeFieldsEmptyState = ({
  hrisName,
  onAddField
}: {
  hrisName: string;
  onAddField: VoidFunction;
}) => (
  <ListEmptyState maxWidthRem={35}>
    <ListEmptyState.Title>No fields imported from {hrisName}</ListEmptyState.Title>
    <Title style={{ textAlign: "center" }}>
      Create custom employee fields to utilize the use of scalable automations
    </Title>
    <ListEmptyState.Buttons $stretch={false}>
      <Button size="small" variant="outlined" onClick={onAddField}>
        Import fields
      </Button>
    </ListEmptyState.Buttons>
  </ListEmptyState>
);
