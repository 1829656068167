import { DataForm } from "@introist/react-foundation/v2";

export type PrefixedForm = Pick<DataForm<any>, "set" | "setMany" | "get" | "isError" | "error">;

export const prefixForm = (form: DataForm<any>, prefix?: string): PrefixedForm => {
  const prefixedKey = (key: string) => `${prefix || ""}${key}`;

  return {
    set: (key: string, silent?: boolean) => form.set(prefixedKey(key), silent),
    setMany: (values: Record<string, any>, silent?: boolean) => {
      const prefixedValues = Object.entries(values).reduce((acc, [key, value]) => {
        acc[prefixedKey(key)] = value;
        return acc;
      }, {} as Record<string, any>);
      form.setMany(prefixedValues, silent);
    },
    get: (key: string) => form.get(prefixedKey(key)),
    isError: (key: string) => form.isError(prefixedKey(key)),
    error: (key: string) => form.error(prefixedKey(key))
  };
};
