import { useNavigate, useParams } from "react-router";
import { TopBarRoute } from "../../components/templates";
import { TabBar } from "../../components/molecules";
import { Stack } from "@introist/react-foundation/v2";
import { OnboardingProgramView } from "./views/OnboardingProgramView";
import { OnboardingCalendar } from "./views/OnboardingCalendar";
import { OnboardingAutomationsView } from "./views/OnboardingAutomationsView";
import { OnboardingDashboard } from "./views/OnboardingDashboard";

export const OnboardingPackageRoute = () => {
  const navigate = useNavigate();
  const { section } = useParams();

  return (
    <TopBarRoute paths={[{ key: "onboarding", title: "Onboarding" }]} medium>
      <Stack vertical gap="xxLarge">
        <Stack justifyContent="center">
          <TabBar
            activeTab={section}
            tabs={[
              { id: "dashboard", title: "Dashboard", iconName: "templates" },
              { id: "calendar", title: "Calendar", iconName: "calendar" },
              { id: "automations", title: "Automations", iconName: "persons" },
              {
                id: "program",
                title: "Onboarding Program",
                iconName: "condition"
              }
            ]}
            onTabChange={tab => navigate(`../${tab}`, { relative: "path" })}
          />
        </Stack>
        {section === "dashboard" && <OnboardingDashboard />}
        {section === "calendar" && <OnboardingCalendar />}
        {section === "automations" && <OnboardingAutomationsView />}
        {section === "program" && <OnboardingProgramView />}
      </Stack>
    </TopBarRoute>
  );
};
