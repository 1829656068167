import { DataForm, Icon, Layout, Tag, Title, useTheme } from "@introist/react-foundation/v2";
import { Block } from "components/atoms";
import { WorkflowTriggerStepEditorV2 } from "modules/workflows/routes/WorkflowEditor/StepEditor/WorkflowTriggerStepEditorV2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useMemo } from "react";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";

type Props = {
  form: DataForm<JourneyStepV2>;
  readOnly?: boolean;
};

export const WorkflowTriggerEditor = ({ form, readOnly }: Props) => {
  if (readOnly) return <ReadOnlyJourneyEditor step={form.data} />;
  return <WorkflowTriggerStepEditorV2 form={form} formPrefix="previewData." />;
};

type ReadOnlyProps = {
  step: JourneyStepV2;
};

const ReadOnlyJourneyEditor = ({ step }: ReadOnlyProps) => {
  const { theme } = useTheme();

  const { workflows } = useWorkflows({});
  const workflow = useMemo(() => {
    return workflows?.find(w => w.id === step.journeyData?.workflowId);
  }, [workflows, step.journeyData?.workflowId]);

  return (
    <Block>
      <StepEditorAccordion title="Triggered workflow details">
        <StepEditorAccordion.Row label="Workflow">
          <Layout.Group>
            <Icon name="workflows" color={theme.palette.foreground.subdued} />
            <Title variant="bold">{workflow?.title}</Title>
          </Layout.Group>
        </StepEditorAccordion.Row>
        {step.journeyData?.draft && <Tag>Started as draft</Tag>}
      </StepEditorAccordion>
    </Block>
  );
};
