import { PopupModal } from "react-calendly";
import { Button } from "@introist/react-foundation/v2";
import { useState } from "react";

type Props = {
  title: string;
};

export const CalendlyButton = ({ title }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)} startIcon="calendar">
        {title}
      </Button>
      <PopupModal
        url="https://calendly.com/d/37n-6xn-sr7/product-tour"
        onModalClose={() => setOpen(false)}
        open={open}
        rootElement={document.getElementById("root")!}
      />
    </>
  );
};
