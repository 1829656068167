import { ListView, TopBarRoute } from "components/templates";
import { MoveWorkflowModal, WorkflowFoldersGrid } from "../containers";
import { ActionMenu, Icon, Select, Stack, Title } from "@introist/react-foundation/v2";
import { useParams } from "react-router-dom";
import { api } from "services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import { useWorkflows } from "../hooks/useWorkflows";
import { UpsertModal, useSearch } from "../../../foundation";
import { BreadcrumbPath } from "@introist/react-foundation/v2/02-blocks";
import { useNavigate } from "react-router";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { CreateWorkflowForm } from "../components/CreateWorkflowForm";

export const WorkflowsRoute = () => {
  const navigate = useNavigate();
  const { folderId } = useParams();

  const { data: folder } = api.workflows.v4.folders.find.useQuery(
    { id: folderId! },
    { enabled: !!folderId }
  );

  const { workflows, create, archive } = useWorkflows({ folderId: folderId ?? null });

  const { search, setSearch, filtered } = useSearch(workflows, {
    fields: ["title"]
  });

  const [createOpen, setCreateOpen] = useState(false);
  const [createFolderOpen, setCreateFolderOpen] = useState(false);

  const [moveWorkflowId, setMoveWorkflowId] = useState<string | undefined>(undefined);

  const paths = useMemo(() => {
    const breadcrumbs: BreadcrumbPath[] = [
      {
        key: "workflows-root",
        title: "Workflows",
        onClick: () => navigate("/flows")
      }
    ];

    const addFolderRecursive = (folder: any) => {
      if (folder.parent) {
        addFolderRecursive(folder.parent);
      }
      breadcrumbs.push({
        key: `workflows-folder-${folder.id}`,
        title: folder.name,
        onClick: () => navigate(`/flows/folder/${folder.id}`)
      });
    };

    if (folder) {
      addFolderRecursive(folder);
    }

    return breadcrumbs;
  }, [folder, navigate]);

  return (
    <TopBarRoute paths={paths} medium>
      <Stack vertical gap="xLarge">
        <WorkflowFoldersGrid
          parentFolderId={folderId || undefined}
          create={{
            open: createFolderOpen,
            onClose: () => setCreateFolderOpen(false)
          }}
        />
        <ListView.Header
          title="Workflows"
          count={filtered?.length}
          create={
            <Select
              element="button"
              variant="outlined"
              placeholder="New"
              size="small"
              startAdornment={<Icon name="plus" />}
              endAdornment={<Icon name="chevronDown" />}
              options={[
                {
                  key: "workflow",
                  title: "Workflow",
                  startAdornmentIcon: "workflows",
                  onClick: () => setCreateOpen(true)
                },
                {
                  key: "folder",
                  title: "Folder",
                  startAdornmentIcon: "folder",
                  onClick: () => {
                    setCreateFolderOpen(true);
                  }
                }
              ]}
            />
          }
          search={{ search, setSearch }}
        />
        <ListView.List
          data={filtered}
          renderRow={wf => (
            <ListRow
              gridTemplateColumns="1fr auto"
              clickable
              onClick={() => navigate(`/flows/edit/${wf.id}`)}
            >
              <Stack>
                <IconPickerIcon icon="workflows" fallbackIcon="workflows" />
                <Title variant="bold">{wf.title}</Title>
              </Stack>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <ActionMenu
                  options={[
                    {
                      key: "move",
                      title: "Move",
                      startAdornmentIcon: "folderMoveTo",
                      onClick: () => setMoveWorkflowId(wf.id)
                    },
                    {
                      key: "archive",
                      title: "Archive",
                      startAdornmentIcon: "archive",
                      onClick: () => archive(wf.id)
                    }
                  ]}
                />
              </div>
            </ListRow>
          )}
        />
      </Stack>
      <UpsertModal
        open={createOpen}
        template={{ description: "", targetDateAttribute: "immediately" }}
        onClose={() => setCreateOpen(false)}
        onCreate={entry =>
          create({ ...entry, folderId: folderId ?? null }).then(res => {
            navigate(`/flows/edit/${res.id}`);
          })
        }
        onUpdate={() => Promise.reject("Not implemented")}
        Form={CreateWorkflowForm}
        entryName="workflow"
      />
      {moveWorkflowId && (
        <MoveWorkflowModal
          open={!!moveWorkflowId}
          onClose={() => setMoveWorkflowId(undefined)}
          workflowId={moveWorkflowId}
        />
      )}
    </TopBarRoute>
  );
};
