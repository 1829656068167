import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";

export type ApiClientConfig = {
  clientId: string;
  clientSecret: string;
};

type ApiClientConfigFieldsProps = {
  config: ApiClientConfig;
  onChange: (config: object) => void;
};

export const ApiClientConfigFields = ({ config, onChange }: ApiClientConfigFieldsProps) => {
  return (
    <Layout.Group vertical>
      <TextInput
        type="password"
        label="API client id"
        placeholder="Enter your API client id"
        value={config.clientId}
        onChange={clientId => onChange({ ...config, clientId })}
      />
      <TextInput
        type="password"
        label="API client secret"
        placeholder="Enter your API client secret"
        value={config.clientSecret}
        onChange={clientSecret => onChange({ ...config, clientSecret })}
      />
    </Layout.Group>
  );
};
