import {
  FieldValue,
  RelativeDateFieldValue,
  parseRelativeDate,
  relativeDateFieldSchema
} from "@introist/introist-commons/dist";

export const stringifyUnit = (unit: string) => {
  if (unit === "d") return "days";
  if (unit === "w") return "weeks";
  if (unit === "M") return "months";
  return "???";
};

export const extractRelativeDateInfo = (value?: RelativeDateFieldValue) => {
  if (value) {
    if (relativeDateFieldSchema.safeParse(value).success) {
      return parseRelativeDate(value.reldate);
    }
  }

  return { side: "+", amount: 1, unit: "M" };
};

export const getStringifiedRelativeDateValue = (
  value?: RelativeDateFieldValue,
  targetDateTerm?: string
) => {
  const { amount, unit, side } = extractRelativeDateInfo(value);
  const resolvedTerm = targetDateTerm ?? "today";
  if (amount === 0) return resolvedTerm ?? "today";
  return `${amount} ${stringifyUnit(unit)} ${
    side === "+" ? `after ${resolvedTerm}` : `before ${resolvedTerm}`
  }`;
};

const isRelativeDate = (value: any): value is RelativeDateFieldValue => {
  return typeof value === "object" && "reldate" in value;
};

export const parseConditionValue = (value: FieldValue) => {
  if (isRelativeDate(value)) {
    return getStringifiedRelativeDateValue(value);
  }

  return value;
};
