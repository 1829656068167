export const EnrollStepSchemaFields = {
  workflowId: {
    type: "string",
    required: true
  },
  targetTrigger: {
    amount: {
      type: "number",
      required: true,
      match: /^\d+$/
    },
    period: {
      type: "string",
      required: true,
      enum: ["days", "weeks", "months"]
    },
    side: {
      type: "string",
      required: true,
      enum: ["before", "after", "on", "immediately"]
    }
  }
};
