import { useToast } from "@introist/react-foundation/v2";
import { useQueryClient } from "@tanstack/react-query";
import { UserRole, isAuthorizedAs, useIdentity } from "IdentityProvider";
import { useUserApi } from "services/api/UserApi";
import { api } from "services/rpc/RpcProvider";
import { setSelectedWorkspace } from "../../../../../services/workspace";

export const useWorkspaces = () => {
  const toast = useToast();
  const { user } = useIdentity();
  const isOwner = isAuthorizedAs(user, UserRole.Owner);

  const queryClient = useQueryClient();
  const userApi = useUserApi();
  const { data: workspaces } = userApi.listWorkspaces().query;

  const selectWorkspace = (workspaceId: string) => {
    queryClient.clear();
    setSelectedWorkspace(workspaceId);
    window.location.href = "/";
  };

  const { mutate: createWorkspace } = api.workspaces.create.useMutation({
    onSuccess: data => {
      selectWorkspace(data.id);
    },
    onError: () => {
      toast.error("Failed to create workspace");
    }
  });

  return {
    workspaces,
    selectWorkspace,
    createWorkspace: isOwner ? createWorkspace : undefined
  };
};
