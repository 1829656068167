import { useCallback, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  InfoCard,
  Layout,
  Title,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { NotificationScheme, NotificationTopic, api } from "services/rpc/RpcProvider";

import { useNoficiationSettingsContext } from "../../context";
import { USER_ROUTES } from "AppRoutes";

const TOPIC_TITLE: Record<NotificationTopic, string> = {
  stepfailures: "Failing steps",
  outdatedemailsenders: "Expired email senders",
  draftjourneys: "Draft journeys",
  targetdateattributevaluechanged: "Automation target date changes",
  // @ts-ignore
  triggerfailures: "Failing trigger activations"
};

const TopicRow = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 2.5rem 2.5rem;

  > :not(:first-child) {
    justify-self: center;
  }
`;

const ConnectButton = styled(Button)`
  && {
    padding: 0 0.5rem;
  }
`;

const Row = ({
  emailScheme,
  slackScheme,
  topic,
  onUpsert
}: {
  emailScheme?: NotificationScheme;
  slackScheme?: NotificationScheme;
  onUpsert: (scheme: NotificationScheme) => Promise<void>;
  topic: NotificationTopic;
}) => {
  const { isSlackConnected, defaultScheme } = useNoficiationSettingsContext();
  const [emailActive, setEmailActive] = useState(!!emailScheme?.active);
  const [slackActive, setSlackActive] = useState(!!slackScheme?.active);

  const handleSchemeUpdate = useCallback(
    async (channel: "email" | "slack", active: boolean) => {
      const currentScheme = channel === "email" ? emailScheme : slackScheme;
      const setActive = channel === "email" ? setEmailActive : setSlackActive;

      setActive(active);

      const schemeToUpsert = currentScheme
        ? { ...currentScheme, active }
        : {
            ...defaultScheme,
            topic,
            channel
          };

      try {
        await onUpsert(schemeToUpsert);
      } catch (error) {
        setActive(!active);
      }
    },
    [defaultScheme, emailScheme, slackScheme, topic, onUpsert]
  );

  return (
    <TopicRow>
      <Title>{TOPIC_TITLE[topic]}</Title>
      <Checkbox checked={emailActive} onChange={active => handleSchemeUpdate("email", active)} />

      <Checkbox
        disabled={!isSlackConnected}
        checked={slackActive}
        onChange={active => handleSchemeUpdate("slack", active)}
      />
    </TopicRow>
  );
};

export const NotifyAbout = ({ ...rest }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const toast = useToast();
  const mutation = api.notifications.schemes.save.useMutation();
  const { isSlackConnected, schemes, refetchSchemes } = useNoficiationSettingsContext();

  const upsertScheme = useCallback(
    async (scheme: NotificationScheme) => {
      await mutation.mutateAsync(scheme, {
        onSuccess: () => {
          refetchSchemes();
          toast.success("Notification settings updated");
        },
        onError: () => toast.error("Failed to update notification settings")
      });
    },
    [mutation, toast, refetchSchemes]
  );

  return (
    <>
      <Layout.Group {...rest} vertical gap="xLarge">
        {!isSlackConnected && (
          <InfoCard
            icon="slack"
            title={
              <Layout.Group justifyContent="space-between">
                <span>Connect account to receive Slack notifications</span>
                <ConnectButton
                  variant="blended"
                  size="small"
                  onClick={() => navigate(USER_ROUTES.userConnectSlack.path)}
                >
                  Connect
                </ConnectButton>
              </Layout.Group>
            }
          ></InfoCard>
        )}
        <TopicRow>
          <Title variant="bold">Notify about</Title>
          <Title>Email</Title>
          <Title style={{ color: !isSlackConnected ? theme.palette.foreground.dimmed : undefined }}>
            Slack
          </Title>
        </TopicRow>
        {Object.keys(TOPIC_TITLE).map(topicKey => {
          const emailScheme = schemes.find(
            scheme => scheme.topic === topicKey && scheme.channel === "email"
          );
          const slackScheme = schemes.find(
            scheme => scheme.topic === topicKey && scheme.channel === "slack"
          );

          return (
            <Row
              key={`notification-settings-topic-row-${topicKey}`}
              topic={topicKey as NotificationTopic}
              emailScheme={emailScheme}
              slackScheme={slackScheme}
              onUpsert={upsertScheme}
            />
          );
        })}
      </Layout.Group>
    </>
  );
};
