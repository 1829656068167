import { Icon, Tooltip, useTheme } from "@introist/react-foundation/v2";

export const Info = ({ message }: { message: string }) => {
  const { theme } = useTheme();

  return (
    <Tooltip tooltip={message}>
      <Icon subdued name="infoFilled" color={theme.palette.foreground.dimmed} />
    </Tooltip>
  );
};
