import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";
import { CompanyEventSetting, api } from "services/rpc/RpcProvider";

type UseUpdateCompanyEventSetting = {
  setting: CompanyEventSetting;
  config: {
    enabled?: boolean;
  };
};

export const useUpdateCompanyEventSetting = ({ setting, config }: UseUpdateCompanyEventSetting) => {
  const toast = useToast();

  const { mutateAsync } = api.companyEvents.settings.update.useMutation({});
  const { refetch } = api.companyEvents.settings.list.useQuery(
    {
      companyEventId: setting.companyEventId
    },
    { enabled: config.enabled !== false }
  );

  const updateEventSetting = useCallback(
    async (partialSetting: Partial<CompanyEventSetting>, onError?: VoidFunction) => {
      await mutateAsync(
        // @ts-ignore
        {
          ...setting,
          ...partialSetting
        },
        {
          onSuccess: () => {
            toast.success("Setting updated");
            refetch();
          },
          onError: () => {
            onError && onError();
            toast.error("Failed to update setting");
          }
        }
      );
    },
    [mutateAsync, refetch, toast, setting]
  );

  return {
    updateEventSetting
  };
};
