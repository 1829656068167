import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useToast } from "@introist/react-foundation/v2";

export type ListParams = RouterInput["automations"]["list"];
export type FindParams = RouterInput["automations"]["find"];

export type Automation = RouterOutput["automations"]["list"][0];
export type AutomationStage = Automation["stage"];

export type AutomationStats = RouterOutput["automations"]["find"]["stats"];

export const useAutomations = (params: ListParams, options = {}) => {
  const { data: automations, refetch } = api.automations.list.useQuery(params, {
    refetchOnMount: "always",
    ...options
  });

  return {
    automations,
    refetch
  };
};

export const useAutomation = (params: FindParams) => {
  const { data: automation, refetch } = api.automations.find.useQuery(params, {
    refetchOnMount: "always"
  });

  return {
    automation,
    refetch
  };
};

export type AutomationUpdate = RouterInput["automations"]["update"]["update"];

export const useAutomationActions = () => {
  const toast = useToast();

  const updateOp = api.automations.update.useMutation();
  const deleteOp = api.automations.delete.useMutation();

  const apiUtils = api.useContext();
  const find = apiUtils.automations.find;

  const update = useCallback(
    async (id: string, update: AutomationUpdate) => {
      return updateOp.mutateAsync({ id, update }).then(async () => {
        await find.refetch();
        toast.success("Automation updated");
      });
    },
    [updateOp, toast, find]
  );

  const remove = useCallback(
    async (id: string) => {
      return deleteOp.mutateAsync({ id }).then(() => {
        toast.success("Automation deleted");
      });
    },
    [deleteOp, toast]
  );

  return { update, remove };
};
