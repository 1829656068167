import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { isEmpty } from "lodash";
import { LegacyEmailViewer } from "./LegacyEmailViewer";
import { EmailMembers } from "./EmailMembers";
import { AttachmentViewer } from "modules/workflows/blocks/Attachments/AttachmentViewer";

export const EmailStepViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step) return <div />;

  const { resolved } = step.resolvedData as any;

  if (!resolved) return <LegacyEmailViewer step={step} />;

  return (
    <>
      <Block blended compact>
        <EmailMembers {...resolved} />
      </Block>
      <Block blended compact>
        <ReadOnlyMessage subject={resolved.subject} message={resolved.message} />
      </Block>
      {!isEmpty(resolved.attachments) && (
        <Block blended compact>
          <AttachmentViewer attachments={resolved.attachments} />
        </Block>
      )}
    </>
  );
};
