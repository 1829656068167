import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import {
  ActionMenu,
  BaseModal,
  Button,
  Layout,
  Option,
  Title,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import styled from "styled-components";
import { appRoutes } from "AppRoutes";
import { InfoBunnyCard } from "components/molecules";
import { Dot } from "components/atoms";
import { Material, api } from "services/rpc/RpcProvider";
import { loadSsoToken } from "services/api/UserApi";
import { config } from "../../../Config";
import { archiveMaterial, getPreviewUrl } from "services/api/MaterialsApi";
import { EditModal } from "../components/EditModal";
import { ListSection } from "components/organisms";
import { TopBarRoute } from "components/templates";
import { CreateModal } from "../components/CreateModal";
import { useFeatureFlag } from "../../../hooks";
import { EndItems, StartItems } from "../../settings/containers/Accounts/AccountsRow";

const EmptyState = styled.div`
  display: flex;
  max-width: 70rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const MaterialsRoute = () => {
  const materialsListApi = api.materials.list;
  const { data: materials, refetch } = materialsListApi.useQuery({});

  const materialsFlag = useFeatureFlag("create-materials");

  const [createOpen, setCreateOpen] = useState(false);

  const [handbooks, files, links] = useMemo(() => {
    return [
      materials?.filter(material => material.materialType === "handbook"),
      materials?.filter(material => material.materialType === "file"),
      materials?.filter(material => material.materialType === "link")
    ];
  }, [materials]);
  return (
    <TopBarRoute
      paths={[{ key: "materials", title: "Materials" }]}
      actions={
        materialsFlag.isEnabled ? (
          <Button onClick={() => setCreateOpen(true)}>Create material</Button>
        ) : undefined
      }
      headers={[]}
    >
      {materials && materials.length === 0 && (
        <EmptyState>
          <Layout.Group>
            <InfoBunnyCard title="No Materials Yet!">
              It looks like you haven't added any materials yet. Remember, a material can be a link,
              file, or a handbook. Once you create a material, you can seamlessly integrate it into
              any workflow step. This way, you ensure consistent content sharing and easy updates,
              keeping all links fresh and current. Start adding your first material to enhance your
              workflow!
            </InfoBunnyCard>
          </Layout.Group>
        </EmptyState>
      )}
      <Layout.Group vertical>
        {handbooks && handbooks.length > 0 && (
          <ListSection title="Handbooks">
            {handbooks.map((material, index) => (
              <MaterialRow
                key={`material-row-handbooks-${material.id}-${index}`}
                material={material}
                onUpdate={() => {
                  refetch();
                }}
              />
            ))}
          </ListSection>
        )}
        {files && files.length > 0 && (
          <ListSection title="Files">
            {files.map((material, index) => (
              <MaterialRow
                key={`material-row-files-${material.id}-${index}`}
                material={material}
                onUpdate={() => {
                  refetch();
                }}
              />
            ))}
          </ListSection>
        )}
        {links && links.length > 0 && (
          <ListSection title="Links">
            {links.map((material, index) => (
              <MaterialRow
                key={`material-row-links-${material.id}-${index}`}
                material={material}
                onUpdate={() => {
                  refetch();
                }}
              />
            ))}
          </ListSection>
        )}
      </Layout.Group>
      <CreateModal
        show={createOpen}
        onClose={(shouldUpdate?: boolean) => {
          if (shouldUpdate) refetch();
          setCreateOpen(false);
        }}
      />
    </TopBarRoute>
  );
};
const StyledMaterialRow = styled.div`
  display: grid;
  align-items: stretch;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: var(--spacing-xLarge);
  height: 4.25rem;
  border-bottom: 1px solid var(--palette-border-subdued);
  transition: background-color 160ms ease;
  cursor: pointer;
  :hover {
    background-color: var(--palette-surface-subdued);
  }
`;

const MinWidthModal = styled(BaseModal)`
  min-width: 25rem;
`;

const MaterialRow = ({ material, onUpdate }: { material: Material; onUpdate: () => unknown }) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { data: materialWithSteps } = api.materials.find.useQuery({ id: material.id });

  const openUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const handlePreview = async (materialId: string) => {
    const { url } = await getPreviewUrl(materialId);
    openUrl(url);
  };

  const openEditor = async (handbookId: string) => {
    const token = await loadSsoToken();
    openUrl(`${config.editorUrl}/sso/${handbookId}?token=${token}`);
  };
  const doArchive = onConfirmAction(
    async () => {
      await archiveMaterial(material.id);
      toast.success("Material archived");
      await onUpdate();
    },
    {
      title: "Confirm archive",
      description: "Are you sure you want to archive this material?"
    }
  );

  const buildActions = () => {
    const actions: Option[] = [
      { key: "preview", title: "Preview", onClick: () => handlePreview(material.id) },
      {
        key: "edit",
        title: "Edit",
        onClick: () => {
          setOpenEdit(true);
        }
      },
      { key: "archive", title: "Archive", onClick: doArchive }
    ];

    if (materialWithSteps?.materialType === "handbook") {
      actions.push({
        key: "editor",
        title: "Open editor",
        onClick: () => {
          openEditor(materialWithSteps.data!);
        }
      });
    }

    return actions;
  };
  return (
    <StyledMaterialRow>
      <StartItems
        onClick={() => {
          setOpen(true);
        }}
      >
        <Title variant="bold">{material.title}</Title>
        <Dot />
        <Title>
          Appears in {material.linkedStepsCount} step{material.linkedStepsCount > 1 ? "s" : ""}
        </Title>
      </StartItems>
      <EndItems>
        <ActionMenu options={buildActions()} />
      </EndItems>
      <MinWidthModal
        open={open}
        onClose={() => setOpen(false)}
        header={<Title variant="bold">{material.title}</Title>}
      >
        <Layout.Group vertical>
          {materialWithSteps?.steps.length === 0 && <Title>No steps</Title>}
          {materialWithSteps?.steps.map(step => (
            <StyledMaterialRow key={`material-row-${material.id}-${step.id}`}>
              <StartItems>
                <Title variant="bold">{step.stepTitle ?? "Untitled step"}</Title>
              </StartItems>
              <EndItems>
                <Button
                  onClick={() => {
                    navigate(appRoutes.workflowEditor.path.replace(":workflowId", step.workflowId));
                  }}
                >
                  Jump to
                </Button>
              </EndItems>
            </StyledMaterialRow>
          ))}
        </Layout.Group>
      </MinWidthModal>
      <EditModal
        show={openEdit}
        onClose={update => {
          update && onUpdate();
          setOpenEdit(false);
        }}
        material={material}
      />
    </StyledMaterialRow>
  );
};
