import styled from "styled-components";

export const AvatarBackground = styled.div<{ $subdued?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--rounding-medium);
  background-color: var(--palette-primary-subdued);

  > h5 {
    color: white;
  }

  ${({ $subdued }) =>
    $subdued &&
    `
  background: var(--palette-foreground-dimmed);

`}
`;
