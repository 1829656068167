import { useMemo } from "react";
import { Button, Layout, useToast, Title, Accordion } from "@introist/react-foundation/v2";
import { orderBy } from "lodash";

import { useIntegrationsApi } from "services/api/IntegrationsApi";

type IntegrationFieldsContainerProps = {
  integrationId: string;
};

export const IntegrationFieldsContainer = ({ integrationId }: IntegrationFieldsContainerProps) => {
  const toast = useToast();

  const integrationApi = useIntegrationsApi();

  const { data: fields, refetch: refetchFields } = integrationApi.listFields({
    integrationId
  }).query;

  const sortedFields = useMemo(() => {
    if (!fields) return [];
    return orderBy(fields, f => f.title);
  }, [fields]);

  const updateFields = integrationApi.updateFields({
    onSuccess: async () => {
      await refetchFields();
      toast.success("Fields updated");
    },
    onError: () => {
      toast.error("Failed to update fields");
    }
  });

  return (
    <Accordion compact title="Fields" defaultOpen={false}>
      <Layout.Group vertical gap="large" alignItems="flex-start">
        <Button
          variant="outlined"
          onClickWithLoading={() => updateFields.mutateAsync({ integrationId })}
        >
          Reload
        </Button>
        <Layout.Group vertical>
          {sortedFields.length === 0 && <Title>This integration does not have any fields</Title>}
          {sortedFields.map(field => (
            <Layout.Group key={field.fieldId} gap="xSmall" alignItems="flex-start">
              <Title variant="bold">{field.title}</Title>
              <Title>{field.type}</Title>
            </Layout.Group>
          ))}
        </Layout.Group>
      </Layout.Group>
    </Accordion>
  );
};
