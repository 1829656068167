import styled from "styled-components";
import { Layout, Stack, Title } from "@introist/react-foundation/v2";
import { useLocation, useNavigate } from "react-router-dom";
import { FeatureHeader } from "components/molecules";
import { ListView, TopBarRoute } from "components/templates";
import { useEventBreadcrumbs } from "./hooks";
import { api } from "services/rpc/RpcProvider";
import { EventsIllustration } from "../components";
import { AddEventModal } from "../containers";
import { appRoutes } from "AppRoutes";
import { PlanAwareRoute } from "../../../foundation/templates/PlanAwareRoute";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { useSearch } from "../../../foundation";
import { IconWithCount } from "../../../foundation/blocks/IconWithCount";

const StyledEventListRoute = styled(Layout.Group)`
  width: 100%;
  flex: 1;
  margin: 0 auto;
`;

export const EventListRoute = ({ ...rest }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: companyEvents,
    isLoading: companyEventsLoading,
    refetch
  } = api.companyEvents.list.useQuery({});

  const { filtered, search, setSearch } = useSearch(companyEvents, { fields: ["title"] });

  const breadcrumbs = useEventBreadcrumbs(companyEvents);
  const loading = companyEventsLoading;

  const events = companyEvents || [];

  return (
    <PlanAwareRoute
      icon="events"
      title="Upgrade to use Events"
      description="Automatically manage attendance to common events like intro sessions and trainings."
    >
      <TopBarRoute {...rest} paths={breadcrumbs} loading={loading} medium>
        <StyledEventListRoute vertical gap="xxLarge">
          <FeatureHeader
            icon={<EventsIllustration />}
            title={appRoutes.events.title}
            description={
              "There's no need to manually manage employees to attend key events. Introist Events schedules attendees automatically and ensures they show up."
            }
          ></FeatureHeader>
          <ListView.Header
            title="Events"
            count={events?.length}
            search={{ search, setSearch }}
            onCreate={() => navigate("#add-existing-event")}
          />
          <ListView.List
            data={filtered}
            empty={{ icon: "events" }}
            renderRow={event => (
              <ListRow
                gridTemplateColumns="1fr auto"
                clickable
                onClick={() => navigate(`/events/${event.id}`)}
              >
                <Stack>
                  <IconPickerIcon icon="event" fallbackIcon="events" />
                  <Title variant="bold">{event.title}</Title>
                </Stack>
                <IconWithCount
                  iconName="person"
                  count={event.attendeeCount}
                  hideIfZero
                  tooltip="Active attendees"
                />
              </ListRow>
            )}
          />
          <AddEventModal
            open={location.hash === "#add-existing-event"}
            onClose={() => navigate(appRoutes.events.path, { replace: true })}
            onCompanyEventCreated={companyEvent => {
              refetch();
              navigate(appRoutes.event.path.replace(":eventId", companyEvent.id), {
                replace: true
              });
            }}
          />
        </StyledEventListRoute>
      </TopBarRoute>
    </PlanAwareRoute>
  );
};
