import { Icon } from "@introist/react-foundation/v2";

import { WorkflowCategory } from "services/rpc/RpcProvider";
import styled from "styled-components";

type Props = {
  workflowCategory?: WorkflowCategory;
  isActiveWorkflow?: boolean;
  blended?: boolean;
  small?: boolean;
};

const StyledWorkflowIcon = styled.div<{ $blended?: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${p => (p.$blended ? null : "2rem")};
  height: ${p => (p.$blended ? null : "2rem")};
  background-color: var(--palette-background-default);
  border: ${p => (p.$blended ? null : "1px solid var(--palette-border-subdued)")};
  border-radius: var(--rounding-small);
  position: relative;
`;

export const WorkflowIcon = ({ isActiveWorkflow, blended, workflowCategory, ...rest }: Props) => {
  return (
    <StyledWorkflowIcon {...rest} $blended={blended}>
      <Icon dimmed name="workflows" />
    </StyledWorkflowIcon>
  );
};
