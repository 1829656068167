import { useColorVariant } from "@introist/react-foundation/v2";
import { RouterOutput } from "services/rpc/RpcProvider";

export const useCalendarEvents = () => {
  const publicHolidayColor = useColorVariant("subdued", { highContrast: true });
  const customHolidayColor = useColorVariant("primary", { highContrast: true });
  const startDateColor = useColorVariant("success", { highContrast: true });

  const holidaysToEvents = (holidays?: RouterOutput["holidayCalendar"]["list"]) => {
    return (holidays || []).map(h => {
      const isPublicHoliday = h.id.startsWith("default");
      return {
        title: h.title,
        date: h.date,
        extendedProps: {
          type: isPublicHoliday ? "public-holiday" : "custom-holiday"
        },
        textColor: isPublicHoliday ? publicHolidayColor.foreground : customHolidayColor.foreground,
        backgroundColor: isPublicHoliday
          ? publicHolidayColor.background
          : customHolidayColor.background,
        borderColor: isPublicHoliday ? publicHolidayColor.background : customHolidayColor.background
      };
    });
  };

  const employeesToEvents = (employees?: RouterOutput["employees"]["v4"]["list"]) => {
    return (employees || []).map(e => ({
      extendedProps: {
        employeeId: e.id,
        type: "employee-start-date"
      },
      className: "clickable",
      title: `Start date: ${e.name || "Deleted employee"}`,
      date: e.startdate || "",
      textColor: startDateColor.foreground,
      backgroundColor: startDateColor.background,
      borderColor: startDateColor.background
    }));
  };

  return {
    holidaysToEvents,
    employeesToEvents
  };
};
