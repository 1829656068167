import { Field, Stack } from "@introist/react-foundation/v2";
import { HtmlEditor } from "components/organisms";

import styled from "styled-components";
import { TextInput } from "components/atoms";
import { StepEditorAccordion } from "../../workflows/routes/WorkflowEditor/StepEditorAccordion";

interface ReadOnlyMessageProps {
  subject?: string;
  message: string;
}

const StyledHTMLEditor = styled(HtmlEditor)`
  .ql-container {
    background-color: var(--palette-surface-subdued) !important;
  }
`;

export const ReadOnlyMessage = ({ subject, message }: ReadOnlyMessageProps) => {
  return (
    <StepEditorAccordion title="Message">
      <Stack vertical>
        {subject && <TextInput readOnly value={subject} label="Subject" size="small" />}
        {subject && (
          <Field title="Message">
            <StyledHTMLEditor defaultValue={message} readonly={true} />
          </Field>
        )}
        {!subject && <StyledHTMLEditor defaultValue={message} readonly={true} />}
      </Stack>
    </StepEditorAccordion>
  );
};
