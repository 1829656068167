import { StepEditorProps } from "../useStepEditor";
import { api } from "../../../../services/rpc/RpcProvider";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Icon, Select, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { SlackSenderSelectV2 } from "../../../workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { useMemo } from "react";
import { SlackUsersSelect } from "./SlackUsersSelect";
import { SlackChannelSelect } from "./SlackChannelSelect";
import { EmployeeAsFieldSelector } from "modules/employees/fields/EmployeeFieldSelector";

export const SlackFormMessageEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { data: forms } = api.forms.v2.list.useQuery({});

  const prefixedForm = prefixForm(form, stepDataPath);

  const messageError = prefixedForm.error("message");

  const formOptions = useMemo(() => {
    return (forms ?? []).map(f => ({ key: f.id, title: f.title }));
  }, [forms]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Form">
          <StepEditorAccordion.Row label="Form">
            <Select
              element="button"
              variant="blended"
              options={formOptions}
              value={prefixedForm.get("form")}
              onSelect={opt => {
                prefixedForm.set("form")(opt.key);
              }}
              error={prefixedForm.isError("form")}
              startAdornment={<Icon name="form" />}
              placeholder="Select a form"
              attachToRef={false}
              searchable
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Link response to employee">
          <StepEditorAccordion.Row label="Employee">
            <EmployeeAsFieldSelector
              value={prefixedForm.get("linkedEmployee")}
              onSelect={opt => {
                prefixedForm.set("linkedEmployee")(opt.key);
              }}
              onClear={() => {
                prefixedForm.set("linkedEmployee")(undefined);
              }}
              variant="blended"
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Sender & Recipients" defaultOpen>
          <Stack vertical alignItems="stretch" gap="large">
            <StepEditorAccordion.Row label="From">
              <SlackSenderSelectV2
                senderId={prefixedForm.get("senderId")}
                onSelectSender={prefixedForm.set("senderId")}
                error={prefixedForm.isError("senderId")}
              />
            </StepEditorAccordion.Row>
            <StepEditorAccordion.Row label="Sending to">
              <Select
                element="button"
                variant="blended"
                options={[
                  { key: "users", title: "Users", startAdornmentIcon: "persons" },
                  { key: "channel", title: "Channel", startAdornmentIcon: "hashtag" }
                ]}
                value={prefixedForm.get("targetType")}
                onSelect={opt => prefixedForm.set("targetType")(opt.key)}
                error={prefixedForm.isError("targetType")}
                placeholder="Select users or channel"
                attachToRef={false}
                searchable
                startAdornment={
                  <Icon name={prefixedForm.get("targetType") === "users" ? "persons" : "hashtag"} />
                }
              />
            </StepEditorAccordion.Row>
            {prefixedForm.get("targetType") === "users" && (
              <StepEditorAccordion.MultiOptionRow label="">
                <SlackUsersSelect
                  senderId={prefixedForm.get("senderId")}
                  error={prefixedForm.isError("users")}
                  value={prefixedForm.get("users") ?? []}
                  onChange={users => {
                    prefixedForm.set("users")(users);
                    prefixedForm.set("channel")(undefined);
                  }}
                />
              </StepEditorAccordion.MultiOptionRow>
            )}
            {prefixedForm.get("targetType") === "channel" && (
              <StepEditorAccordion.Row label="">
                <SlackChannelSelect
                  senderId={prefixedForm.get("senderId")}
                  value={prefixedForm.get("channel")}
                  onChanged={channel => {
                    prefixedForm.set("channel")(channel);
                    prefixedForm.set("users")([]);
                  }}
                  error={prefixedForm.isError("channel")}
                />
              </StepEditorAccordion.Row>
            )}
          </Stack>
        </StepEditorAccordion>
      </Block>
      <Block compact blended>
        <StepEditorAccordion title="Message" defaultOpen>
          <Stack vertical gap="xLarge">
            <MessageEditor
              message={prefixedForm.get("message")}
              onChange={prefixedForm.set("message")}
              error={!!messageError}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
