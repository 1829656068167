import { UpsertFormProps } from "../../../foundation";
import { Workflow } from "../index";
import { useEmployeeFields } from "../../datasources/hooks/useEmployeeFields";
import { useMemo } from "react";
import { Field, Input, Select, Stack, TextArea } from "@introist/react-foundation/v2";

export const CreateWorkflowForm = ({ value, onChange, isEdit }: UpsertFormProps<Workflow>) => {
  const fields = useEmployeeFields();

  const targetDateOptions = useMemo(() => {
    const options = [
      {
        key: "immediately",
        title: "When enrolled"
      }
    ];

    const fieldOptions = (fields ?? [])
      .filter(f => f.type === "date")
      .map(f => ({ key: f.variable, title: f.name }));

    return [...options, ...fieldOptions];
  }, [fields]);

  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} autoFocus />
      </Field>
      <Field title="Description">
        <TextArea
          value={value.description ?? ""}
          onChange={description => onChange({ ...value, description })}
        />
      </Field>
      <Field title="Target date">
        <Select
          style={{ width: "100%" }}
          value={value.targetDateAttribute ?? undefined}
          options={targetDateOptions}
          onSelect={opt => onChange({ ...value, targetDateAttribute: opt.key })}
        />
      </Field>
    </Stack>
  );
};
