import {
  ActionMenu,
  CircularLoader,
  Drawer,
  Field,
  H4,
  IconButton,
  Input,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { IconPickerIcon, ListRow } from "../../../components/organisms";
import { expressionToConditionGroup } from "../../../components/organisms/RecursiveExpressionEditor";
import { useEmployeeFields } from "../hooks/useEmployeeFields";
import { ListView, UpsertFormProps, UpsertModal, useSearch } from "../../../foundation";
import { Avatar } from "../../../components/molecules";
import { isEmpty, pick } from "lodash";
import { useState } from "react";
import { asExpressionFields, RuleEditModal } from "./EmployeeGroupRuleEditModal";
import { Expression } from "@introist/introist-commons/dist";
import { EmployeeGroup, useEmployeeGroup } from "../hooks/useEmployeeGroups";
import { RuleViewer } from "components/organisms/RecursiveExpressionEditor/components/RuleViewer/RuleViewer";

export const EmployeeGroupDrawer = ({
  open,
  onClose,
  id
}: {
  open: boolean;
  onClose: () => void;
  id: string;
}) => {
  const { theme } = useTheme();
  return (
    <Drawer width="400px" open={open} onClose={onClose} style={{ padding: theme.spacing.xLarge }}>
      {id && <EmployeeGroupDetails id={id} onClose={onClose} />}
    </Drawer>
  );
};

export const EmployeeGroupDetails = ({ id, onClose }: { id: string; onClose: () => void }) => {
  const { fields } = useEmployeeFields();

  const { group, update, archive } = useEmployeeGroup({ groupId: id });
  const { data: employees, refetch: refetchEmployees } = api.employees.v4.groups.employees.useQuery(
    { id }
  );

  const [editOpen, setEditOpen] = useState(false);
  const [editedRule, setEditedRule] = useState<Expression | undefined>();

  const { filtered, search, setSearch } = useSearch(employees, { fields: ["name"] });

  return (
    <>
      {!group && <CircularLoader fillParent />}
      {group && (
        <Stack vertical gap="xLarge">
          <Stack justifyContent="space-between">
            <Stack>
              <IconPickerIcon icon="persons" fallbackIcon="persons" />
              <H4>{group.title}</H4>
            </Stack>
            <Stack>
              <ActionMenu
                options={[
                  {
                    key: "edit",
                    title: "Edit",
                    startAdornmentIcon: "pencil",
                    onClick: () => setEditOpen(true)
                  },
                  {
                    key: "archive",
                    title: "Archive",
                    startAdornmentIcon: "archive",
                    onClick: () => archive(async () => onClose())
                  }
                ]}
              />
              <IconButton icon="crossSmall" onClick={onClose} />
            </Stack>
          </Stack>
          <Stack justifyContent="space-between">
            <H4>Rules</H4>
            <IconButton icon="pencil" dimmed onClick={() => setEditedRule(group.condition)} />
          </Stack>
          <RuleViewer
            conditionGroup={expressionToConditionGroup(
              !isEmpty(group.condition) ? group.condition : { and: [] }
            )}
            fields={asExpressionFields(fields)}
          />
          <ListView.Header
            title="Employees"
            count={filtered?.length}
            search={{ search, setSearch }}
          />
          <ListView.List
            data={filtered}
            empty={{
              icon: "persons",
              message: "No matching employees"
            }}
            renderRow={employee => (
              <ListRow gridTemplateColumns="1fr auto" key={employee.id}>
                <Stack gap="small">
                  <Avatar nameOrEmail={employee.name ?? ""} />
                  <Title variant="bold">{employee.name}</Title>
                </Stack>
                <IconButton
                  icon="moveTo"
                  dimmed
                  onClick={() => window.open(`/employees/${employee.id}#fields`)}
                />
              </ListRow>
            )}
          />
        </Stack>
      )}
      {editedRule && (
        <RuleEditModal
          expression={group!.condition}
          open={!!editedRule}
          onClose={() => setEditedRule(undefined)}
          onChange={setEditedRule}
          onSave={async () =>
            await update({ groupId: id, updates: { condition: editedRule } }, true).then(
              async () => {
                await refetchEmployees();
                setEditedRule(undefined);
              }
            )
          }
        />
      )}
      <UpsertModal
        open={editOpen}
        entry={group}
        onCreate={async entry => {
          throw new Error("Not implemented");
        }}
        onUpdate={entry => {
          return update({ groupId: entry.id, updates: pick(entry, ["title"]) });
        }}
        onClose={() => {
          setEditOpen(false);
        }}
        Form={EmployeeGroupForm}
        entryName="employee group"
      />
    </>
  );
};

export const EmployeeGroupForm = ({ value, onChange, isEdit }: UpsertFormProps<EmployeeGroup>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input
          value={value.title}
          onChange={title => onChange({ ...value, title })}
          autoFocus={!isEdit}
        />
      </Field>
    </Stack>
  );
};
