import { ReactNode } from "react";
import { CompanyEventInstanceAttendee } from "services/rpc/RpcProvider";
import { ActionMenu, Layout, Option, Tag, Title, Stack } from "@introist/react-foundation/v2";
import { EventAttendeeAttendanceStatusTag } from "../EventAttendeeAttendanceStatusTag";
import { capitalize } from "lodash";
import { Avatar } from "components/molecules";
import { HighlightText } from "components/atoms";
import { StyledListRow } from "components/organisms";
import { formatDateTime } from "../../../../utils/DatesUtils";
import { DateFormats } from "../../../../utils/dates";

export const EventAttendeeListRow = ({
  attendee,
  actions,
  children,
  search
}: {
  attendee: CompanyEventInstanceAttendee;
  actions: Option[];
  children?: ReactNode;
  search?: string;
}) => {
  return (
    <StyledListRow $gridTemplateColumns="1fr 1fr" style={{ marginTop: "-1px" }}>
      <Layout.Group gap="large">
        <Avatar size="large" nameOrEmail={attendee.name || attendee.email} />
        <Layout.Group vertical gap="xSmall" alignItems="flex-start">
          <Title variant="bold">
            {search ? <HighlightText search={search} text={attendee.name} /> : attendee.name}
          </Title>
          <Stack>
            <Title>{attendee.email}</Title>
          </Stack>
        </Layout.Group>
      </Layout.Group>
      <Layout.Group justifyContent="flex-end" gap="xLarge">
        {children}
        {attendee.latest && (
          <Title>
            (latest {formatDateTime(attendee.latest, DateFormats.date.withMonth.short)})
          </Title>
        )}
        {attendee.status === "active" && (
          <EventAttendeeAttendanceStatusTag status={attendee.attendanceStatus} />
        )}
        {attendee.status !== "active" && <Tag>{capitalize(attendee.status)}</Tag>}
        <ActionMenu options={actions} />
      </Layout.Group>
    </StyledListRow>
  );
};
