import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import sortBy from "lodash.sortby";

export const SlackQuestionMessageViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  const resolveAnswer = () => {
    if (!step.executionData) return null;
    const answers = (step.executionData as any)?.answers as any[];
    if (!answers || answers.length === 0) return null;

    return sortBy(answers, "date").reverse()[0];
  };

  const answer = resolveAnswer();

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Collected Information">
          <StepEditorAccordion.Row label="Field">
            <Title variant="bold">{resolvedData.resolvedField.title}</Title>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Response">
            {!answer && <Title>No response yet</Title>}
            {answer && <Title variant="bold">{answer.value}</Title>}
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Recipients">
          <StepEditorAccordion.Row label="From">
            <Stack>
              {resolvedData.isBot ? <Icon name="robot" /> : <Icon name="person" />}
              <Title variant="bold">{resolvedData.resolvedSenderName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="To">
            <Stack vertical>
              {(resolvedData.resolvedUsers as { id: string; name: string }[]).map(recipient => (
                <Stack key={recipient.id}>
                  <Icon name="person" />
                  <Title variant="bold">{recipient.name}</Title>
                </Stack>
              ))}
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage!.html} />
      </Block>
    </>
  );
};
