import { Layout } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import styled from "styled-components";

const StyledPanel = styled.div<{ $maxHeight?: number; $v2?: boolean }>`
  padding: var(--spacing-xxLarge) var(--spacing-xLarge);
  background-color: var(--palette-background-default);
  border-left: 1px solid var(--palette-border-subdued);
  box-sizing: border-box;
  overflow: hidden;
  max-width: 25rem;
  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : "calc(100vh - 65px)")};

  :hover {
    overflow-y: auto;
  }
`;

const Panel = ({ children, maxHeight }: { children?: ReactNode; maxHeight?: number }) => (
  <StyledPanel $maxHeight={maxHeight}>{children}</StyledPanel>
);

const View = styled(Layout.Group).attrs({ vertical: true, gap: "xxLarge" })`
  flex: 1;
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 25rem;
  flex: 1;
`;

const Content = styled.div`
  display: grid;
  align-items: stretch;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 65px);
  padding: var(--spacing-xxLarge) var(--spacing-xLarge);
  box-sizing: border-box;
  overflow: hidden;
  scrollbar-gutter: stable;

  :hover {
    overflow-y: auto;
  }
`;

const PanelDivider = styled(Layout.Divider)`
  margin-left: calc(var(--spacing-xxLarge) * -1);
  margin-right: calc(var(--spacing-xxLarge) * -1);
  width: calc(100% + (var(--spacing-xxLarge) * 2));
`;

export const PanelLayout = {
  View,
  Panel,
  Content,
  Grid,
  PanelDivider
};
