import { TopBarRoute } from "../../../../components/templates";
import { ConnectedDataSourcesList } from "./DataSourceConnectionsList";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { DataSourceConnectionEditorModal } from "../../containers/DataSourceConnectionEditor";
import { DataSourceFilterEditorModal } from "modules/datasources/containers/DataSourceFilterEditor";
import { DataSourceCreateConditionModal } from "../../containers/CreateConditionModal";
import { PlanAwareRoute } from "../../../../foundation/templates/PlanAwareRoute";
import { useMemo } from "react";
import { DataSourceParamsModal } from "../../containers/DataSourceParamsModal";

export const DataSourcesRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dataSourceIdFromHash = useMemo(() => {
    return location.hash.split("=")[1];
  }, [location]);

  return (
    <PlanAwareRoute
      icon="reconnect"
      title="Upgrade to use Data Sources"
      description="Automatically sync employee data from various sources like HRIS, ATS, and Excel."
    >
      <TopBarRoute
        paths={[
          {
            key: "datasources",
            title: "Data Sources"
          }
        ]}
      >
        <ConnectedDataSourcesList onAddDataSource={() => navigate("/datasources/connect")} />
        <DataSourceConnectionEditorModal
          open={location.hash.includes("#edit")}
          onClose={() => navigate("/datasources")}
        />
        <DataSourceFilterEditorModal
          open={location.hash.includes("#filter")}
          onClose={() => navigate("/datasources")}
        />
        <DataSourceCreateConditionModal
          open={location.hash.includes("#createcondition")}
          onClose={() => navigate("/datasources")}
        />
        {!!dataSourceIdFromHash && (
          <DataSourceParamsModal
            open={location.hash.includes("#params")}
            onClose={() => navigate("/datasources")}
            dataSourceId={dataSourceIdFromHash}
          />
        )}
      </TopBarRoute>
    </PlanAwareRoute>
  );
};
