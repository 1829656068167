import { Batch, BatchAction, BatchEmployee, useBatchActions } from "../../useBatches";
import {
  ActionMenu,
  Checkbox,
  CircularLoader,
  Icon,
  Stack,
  Title,
  Tooltip
} from "@introist/react-foundation/v2";
import { Avatar } from "../../../../components/molecules";
import { BatchEmployeeStatusTag } from "../../components/BatchEmployeeStatusTag";
import { compact } from "lodash";
import { ListRow } from "../../../../components/organisms";
import { EmployeeActionsModal } from "./EmployeeActionsModal";
import { useMemo, useState } from "react";

export const BatchEmployeeRow = ({
  batch,
  emp,
  actions,
  onToggleSelect,
  selected
}: {
  batch: Batch;
  emp: BatchEmployee;
  actions: BatchAction[];
  onToggleSelect: (checked: boolean) => void;
  selected?: boolean;
}) => {
  const { removeEmployees, cancelEmployees } = useBatchActions();

  const [actionsOpen, setActionsOpen] = useState(false);

  const activeActions = useMemo(() => {
    if (!actions) return false;
    return actions.some(a => !a.completedAt);
  }, [actions]);

  return (
    <ListRow gridTemplateColumns="auto 1fr auto auto" key={emp.id}>
      <Checkbox
        disabled={batch.stage === "completed"}
        checked={!!selected}
        onChange={onToggleSelect}
      />
      <Stack
        onClick={() => window.open(`/employees/${emp.employeeId}`, "_blank")}
        style={{ cursor: "pointer" }}
      >
        <Avatar nameOrEmail={emp.name} />
        <Title variant="bold">{emp.name}</Title>
      </Stack>
      <Stack>
        {!emp.resolution && emp.error && (
          <Tooltip tooltip={emp.error}>
            <Icon name="warning" danger />
          </Tooltip>
        )}
        {batch.stage !== "draft" && <BatchEmployeeStatusTag employee={emp} />}
        {activeActions && <CircularLoader size="small" />}
      </Stack>
      {batch.stage !== "completed" && !emp.resolution && (
        <ActionMenu
          options={compact([
            {
              key: "actions",
              title: "Show actions",
              startAdornmentIcon: "list",
              onClick: () => setActionsOpen(true)
            },
            batch.stage === "draft"
              ? {
                  key: "delete",
                  title: "Remove employee",
                  startAdornmentIcon: "trash",
                  onClick: () => removeEmployees(emp.batchId, [emp.id])
                }
              : null,
            batch.stage === "active" && !emp.resolution
              ? {
                  key: "cancel",
                  title: "Cancel employee",
                  startAdornmentIcon: "crossCircle",
                  onClick: () => cancelEmployees(emp.batchId, [emp.id]),
                  disabled: activeActions
                }
              : null
          ])}
        />
      )}
      <EmployeeActionsModal
        actions={actions}
        open={actionsOpen}
        onClose={() => setActionsOpen(false)}
      />
    </ListRow>
  );
};
