import { useIntegrationsApi } from "services/api/IntegrationsApi";

export const useIntegrations = () => {
  const integrationsApi = useIntegrationsApi();
  const { data: integrations, isLoading } = integrationsApi.list({}).query;

  const loadOptions = integrationsApi.loadOptions;

  const activeHrIntegrations = (integrations ?? []).filter(
    i =>
      i.active &&
      (i.categories.includes("HRIS") ||
        i.categories.includes("ATS") ||
        i.categories.includes("custom"))
  );

  const getIntegration = (integrationId: string) => {
    return integrations?.find(i => i.id === integrationId);
  };

  const hasActiveIntegrations = (activeHrIntegrations || []).length > 0;

  return {
    integrations,
    isLoading,
    hasActiveIntegrations,
    activeHrIntegrations,
    getIntegration,
    loadOptions
  };
};
