import { useCallback, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
  InfoCard,
  Layout,
  Select,
  TimePicker,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";

import { NotificationScheme, api } from "services/rpc/RpcProvider";
import { listTimezones } from "utils/dates";

import { WeekdayPicker } from "../WeekdayPicker";
import { useNoficiationSettingsContext } from "../../context";

export type TSchemeFrequency = Pick<NotificationScheme, "days" | "time" | "timezone">;

const FormElements = styled(Layout.Group)<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
    `}
`;

const TimeGrid = styled.div`
  display: grid;
  grid-template-columns: 7rem 1fr;
  grid-column-gap: var(--spacing-xLarge);

  > :first-child {
    max-width: 100% !important;
    cursor: pointer;

    > :first-child {
      box-sizing: border-box;
    }

    > :last-child {
      width: 7rem;
    }
  }

  > :last-child {
    width: 100%;
  }
`;

export const NotificationFrequency = ({ ...rest }) => {
  const toast = useToast();
  const { defaultScheme, schemes, refetchSchemes } = useNoficiationSettingsContext();
  const disabled = isEmpty(schemes);

  const defaultFrequency: TSchemeFrequency = useMemo(
    () => ({
      days: defaultScheme.days,
      time: defaultScheme.time,
      timezone: defaultScheme.timezone
    }),
    [defaultScheme]
  );

  const [frequency, setFrequency] = useState(defaultFrequency);
  const previousFrequencyRef = useRef(frequency);

  const mutation = api.notifications.schemes.updateFrequency.useMutation();

  const onUpsertFrequency = useCallback(
    async (partialFrequency: Partial<TSchemeFrequency>) => {
      if (disabled) return;

      previousFrequencyRef.current = frequency;
      setFrequency({ ...frequency, ...partialFrequency });

      mutation.mutateAsync(
        {
          ...frequency,
          ...partialFrequency
        },
        {
          onSuccess: () => {
            refetchSchemes();
            toast.success("Notification frequency updated");
          },
          onError: () => {
            setFrequency(previousFrequencyRef.current);
            toast.error("Failed to update notification frequency");
          }
        }
      );
    },
    [mutation, toast, frequency, disabled, refetchSchemes]
  );

  return (
    <Layout.Group {...rest} vertical gap="xLarge">
      <Title variant="bold">Notification frequency</Title>
      {disabled && (
        <InfoCard
          icon="checkRectangle"
          title="At least one notification has to be active in order to edit frequency"
        />
      )}
      <FormElements vertical gap="xLarge" $disabled={disabled}>
        <WeekdayPicker
          selectedDays={frequency.days}
          onChange={selectedDays => onUpsertFrequency({ days: selectedDays })}
        />
        <TimeGrid>
          <TimePicker time={frequency.time} onSelect={time => onUpsertFrequency({ time })} />
          <Select
            searchable
            options={listTimezones().map(timezone => ({ key: timezone }))}
            value={frequency.timezone}
            onSelect={option => onUpsertFrequency({ timezone: option.key })}
          />
        </TimeGrid>
      </FormElements>
    </Layout.Group>
  );
};
