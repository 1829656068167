import { CloseIconButton, H4, Layout } from "@introist/react-foundation/v2";
import { ReactNode } from "react";
import styled from "styled-components";

type SliderModalHeaderProps = {
  onClose: () => void;
  title?: ReactNode;
  children?: ReactNode;
  blended?: boolean;
};

const StyledSliderModalHeader = styled(Layout.Group)<{ $blended: boolean }>`
  padding: var(--spacing-large) var(--spacing-xxLarge);
  border-bottom: ${({ $blended }) => ($blended ? "" : "1px solid var(--palette-border-subdued);")};
`;

export const SliderModalHeader = ({
  title,
  onClose,
  children,
  blended = false,
  ...rest
}: SliderModalHeaderProps) => {
  return (
    <StyledSliderModalHeader {...rest} justifyContent="space-between" $blended={blended}>
      {title && <H4>{title}</H4>}
      <Layout.Group>
        {children}
        <CloseIconButton onClick={onClose} />
      </Layout.Group>
    </StyledSliderModalHeader>
  );
};
