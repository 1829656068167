import { FC } from "react";
import styled from "styled-components";
import { Icon } from "@introist/react-foundation/v2";
import { animated } from "@react-spring/web";

import { HorizontalCrossLine } from "./components/HorizontalCrossLine";
import { useCrossLineSprings } from "./hooks/useCrossLineSprings";

export type AnimatedCrossLineProps = {
  length: number;
  animationDelayMs?: number;
  crossBackgroundColor?: string;
};

type Compound = {
  Horizontal: typeof HorizontalCrossLine;
};

export const StyledAnimatedCrossLine = styled.div`
  position: relative;
`;

const AnimatedLine = styled(animated.span)`
  display: block;
  width: 1px;
  transform-origin: top;
  height: inherit;
  background-color: var(--palette-border-subdued);
`;

export const AnimatedCrossIcon = styled(animated.div)<{ $backgroundColor?: string }>`
  position: absolute;
  width: 1rem;
  height: 1rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || "var(--palette-surface-subdued)"};
  transform-origin: 0rem 0rem;
`;

export const AnimatedCrossLineComponent: FC<AnimatedCrossLineProps> & Compound = ({
  length,
  animationDelayMs,
  crossBackgroundColor,
  ...rest
}) => {
  const { verticalLineSpring, crossSpring } = useCrossLineSprings(animationDelayMs);

  return (
    <StyledAnimatedCrossLine {...rest} style={{ height: `${length}rem` }}>
      <AnimatedCrossIcon style={crossSpring} $backgroundColor={crossBackgroundColor}>
        <Icon danger name="crossSmall" />
      </AnimatedCrossIcon>
      <AnimatedLine style={verticalLineSpring} />
    </StyledAnimatedCrossLine>
  );
};

AnimatedCrossLineComponent.Horizontal = HorizontalCrossLine;
export const AnimatedCrossLine = AnimatedCrossLineComponent;
