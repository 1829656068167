import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Icon,
  Layout,
  useToast,
  useActions,
  InfoCard
} from "@introist/react-foundation/v2";

import { TextInput } from "components/atoms";
import { CredentialsConfiguredInfoCard } from "../CredentialsConfiguredInfoCard";

type ApiClientCredentialFormProps = {
  apiClientId?: string;
  apiClientSecret?: string;
  onSaveClientCredentials: (clientId: string, clientSecret: string) => Promise<unknown>;
  instructionsUrl?: string;
  credentialsConfigured: boolean;
};

const IconArrowRight = styled(Icon).attrs({ name: "arrowRight" })`
  stroke: var(--palette-primary-default);
`;

const StyledApiClientCredentialForm = styled.div``;

export const ApiClientCredentialForm = ({
  apiClientId,
  apiClientSecret,
  onSaveClientCredentials,
  instructionsUrl,
  credentialsConfigured,
  ...rest
}: ApiClientCredentialFormProps) => {
  const { onAction } = useActions();
  const toast = useToast();

  const [clientId, setClientId] = useState(apiClientId || "");
  const [clientSecret, setClientSecret] = useState(apiClientSecret || "");

  const [configured, setConfigured] = useState(credentialsConfigured);

  useEffect(() => setConfigured(credentialsConfigured), [credentialsConfigured]);

  const onSubmit = onAction(
    () =>
      onSaveClientCredentials(clientId, clientSecret).then(() => {
        toast.success("Client credentials saved");
        setConfigured(true);
      }),
    "Failed to save client credentials"
  );

  const openInstructions = () => {
    window.open(instructionsUrl, "_blank");
  };

  return (
    <StyledApiClientCredentialForm {...rest}>
      {configured ? (
        <Layout.Group vertical lastChildToEnd>
          <CredentialsConfiguredInfoCard />
          <Button onClick={() => setConfigured(false)}>Reconfigure</Button>
        </Layout.Group>
      ) : (
        <Layout.Group vertical lastChildToEnd gap="xLarge">
          {instructionsUrl && (
            <InfoCard
              colorVariant="primary"
              title={
                <Layout.Group>
                  <span>Read how to find the credentials</span>
                  <IconArrowRight />
                </Layout.Group>
              }
              onClick={openInstructions}
            />
          )}
          <Layout.Group vertical>
            <TextInput
              type="text"
              label="Copy your Client ID here"
              value={clientId}
              onChange={setClientId}
            />

            <TextInput
              type="password"
              label="Copy your Client secret here"
              value={clientSecret}
              onChange={setClientSecret}
            />
          </Layout.Group>
          <Button onClickWithLoading={onSubmit} disabled={!clientId || !clientSecret}>
            Save credentials
          </Button>
        </Layout.Group>
      )}
    </StyledApiClientCredentialForm>
  );
};
