import { Block } from "components/atoms";

import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { api } from "../../../../services/rpc/RpcProvider";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import { ReadOnlyMessage } from "modules/flows/components/ReadOnlyMessage";
import { useMemo } from "react";
import { useSlackSenderApi } from "services/api/SlackSenderApi";

type SlackQuestionJourneyStepEditorProps = {
  questionId?: string;
  index?: number;
  step: any;
};
export const SlackQuestionJourneyStepEditor = ({
  questionId,
  index,
  step
}: SlackQuestionJourneyStepEditorProps) => {
  const slackSenderApi = useSlackSenderApi();

  const { data: question } = api.questions.find.useQuery(
    { id: questionId! },
    { enabled: !!questionId }
  );

  const { data: slackSenders } = api.connectedAccounts.list.useQuery({ provider: "slack" });

  const { data: slackUsers } = slackSenderApi.listUsers(
    { senderId: step.previewData.message.senderId || "" },
    { enabled: !!step.previewData.message.senderId }
  ).query;

  const resolvedData = step.journeyData as any;

  const resolvedUsers: string[] = resolvedData?.resolvedMessage?.resolvedUsers;

  const sender = useMemo(() => {
    return slackSenders?.find(_ => _.id === resolvedData?.resolvedMessage.senderId);
  }, [slackSenders, resolvedData]);

  const users = useMemo(() => {
    return resolvedUsers
      .map(_ => {
        return slackUsers?.find(slackUser => _ === slackUser.id);
      })
      .filter(_ => !!_);
  }, [slackUsers, resolvedUsers]);

  if (!questionId || !question) return null;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Answer" stepOrder={index}>
          <StepEditorAccordion.Row label="Answer">
            <Title variant="bold">{question.answer ?? "No answer yet"}</Title>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Recipients">
          <StepEditorAccordion.Row label="From">
            <Stack>
              {!!sender?.ownerId ? <Icon name="person" /> : <Icon name="robot" />}
              <Title variant="bold">{sender?.name ?? "Sender not found"}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="To">
            <Stack vertical>
              {users?.map(recipient => (
                <Stack key={recipient!.id}>
                  <Icon name="person" />
                  <Title variant="bold">{recipient!.name}</Title>
                </Stack>
              ))}
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage!.htmlMessage} />
      </Block>
    </>
  );
};
