import styled from "styled-components";
import { Card, H4, Layout, Title } from "@introist/react-foundation/v2";

import { ConnectHrisButton } from "../../containers/ConnectHris";
import { AnimatedAttributeBall } from "../AnimatedAttributeBall";

type HrisEmptyStateProps = {
  onConnected: () => void;
};

const EmptyStateCard = styled(Card)`
  border: 1px solid var(--palette-surface-default);
`;

export const HrisEmptyState = ({ onConnected }: HrisEmptyStateProps) => {
  return (
    <EmptyStateCard spacing="xLarge">
      <Layout.Group justifyContent="space-between" gap="large">
        <Layout.Group vertical gap="xxLarge" alignItems="flex-start">
          <Layout.Group vertical gap="small">
            <H4>Connect HRIS</H4>
            <Title>
              Connect your HRIS to easily utilize employee info. Attributes auto-fill, and workflows
              initiate automatically based on real-time events in your HRIS.
            </Title>
          </Layout.Group>
          <ConnectHrisButton onConnected={onConnected} />
        </Layout.Group>
        <div style={{ width: 182 }}>
          <AnimatedAttributeBall />
        </div>
      </Layout.Group>
    </EmptyStateCard>
  );
};
