import { WorkflowStepAction } from "services/rpc/RpcProvider";
import { ContentType } from "../schema";
import { AIAssistantResult, EmailMessageResult } from "../schema";
import { MessageResponsePreview } from "./MessageResultPreview";
import { StepBuilderResultPreview } from "./StepBuilderResultPreview";

export interface ResultPreviewBaseProps {
  onChange: (preview: AIAssistantResult) => void;
  confirm: (preview: AIAssistantResult) => void;
  close: () => void;
  loading?: boolean;
}

interface GenericResponsePreviewProps extends ResultPreviewBaseProps {
  contentType: ContentType;
  preview: AIAssistantResult | undefined;
}

export const ResultPreview = ({ contentType, ...rest }: GenericResponsePreviewProps) => {
  if (!rest.preview) {
    return null;
  }
  switch (contentType) {
    case ContentType.EmailMessage:
    case ContentType.EmailSubject:
    case ContentType.SlackMessage:
      const messagePreview = rest.preview as EmailMessageResult;
      return <MessageResponsePreview {...rest} preview={messagePreview} />;
    case ContentType.WorkflowSteps:
      const preview = rest.preview as WorkflowStepAction;
      return <StepBuilderResultPreview {...rest} preview={preview} />;
    default:
      return null;
  }
};
