import { useState } from "react";
import { BaseModal, Button, H4, Layout, useToast } from "@introist/react-foundation/v2";
import { api } from "services/rpc/RpcProvider";
import { FieldNameInput } from "../components";

type Props = {
  onCreated: ({ name, id }: { name: string; id: string }) => void;
};

export const CreateCustomEmployeeFieldGroup = ({ onCreated, ...rest }: Props) => {
  const toast = useToast();

  const [name, setName] = useState("");
  const [groupId, setGroupId] = useState<string | null>(null);
  const { mutateAsync } = api.employees.fields.create.useMutation({});

  return (
    <Layout.Group vertical gap="xLarge" {...rest} lastChildToEnd>
      <FieldNameInput isGroup value={name} onChange={setName} />

      {!groupId && (
        <Button
          disabled={!name}
          onClickWithLoading={async () => {
            if (!name) return;
            await mutateAsync(
              {
                name,
                type: "group"
              },
              {
                onSuccess: group => {
                  onCreated({ name: group.name, id: group.id });
                  setGroupId(group.id);
                },
                onError: () => {
                  toast.error("Failed to create group");
                }
              }
            );
          }}
        >
          Create
        </Button>
      )}
    </Layout.Group>
  );
};

export const CreateCustomEmployeeFieldGroupModal = ({
  open,
  onClose,
  ...rest
}: Props & {
  open: boolean;
  onClose: VoidFunction;
}) => {
  return (
    <BaseModal
      open={open}
      header={<H4>Create custom employee field group</H4>}
      onClose={onClose}
      style={{ width: "100%", maxWidth: 640 }}
      closeOnOutsideClick
    >
      <CreateCustomEmployeeFieldGroup {...rest} />
    </BaseModal>
  );
};
