import {
  RelativeDateInlineEditor,
  RelativeDateInlineEditorProps
} from "../RelativeDateInlineEditor";
import {
  Button,
  ButtonProps,
  Card,
  LabelledCheckbox,
  Layout,
  Popover
} from "@introist/react-foundation/v2";
import { parseRelativeDate, RelativeDate } from "@introist/introist-commons/dist";
import { useState } from "react";

type RelativeDateButtonEditorProps = RelativeDateInlineEditorProps & {
  placeholder?: string;
  value: string | null;
  buttonProps?: ButtonProps;
};

const periodMap: { [index: string]: string } = {
  d: "days",
  w: "weeks",
  m: "months",
  M: "months"
};

const stringifyRelativeDate = (value: RelativeDate) => {
  const { amount, side, unit } = parseRelativeDate(value);
  return `${amount} ${periodMap[unit]} ${side === "-" ? "before" : "after"}`;
};

export const RelativeDateButtonEditor = ({
  value,
  placeholder,
  onChange,
  ...props
}: RelativeDateButtonEditorProps) => {
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState<HTMLElement | null>(null);

  return (
    <>
      <Button ref={setRef} variant="blended" size="small" onClick={() => setOpen(true)}>
        {value ? stringifyRelativeDate(value) : placeholder ?? ""}
      </Button>
      <Popover
        referenceElement={ref}
        open={open}
        onClose={() => setOpen(false)}
        closeOnContentClick={false}
      >
        <Card elevated>
          <Layout.Group vertical>
            <RelativeDateInlineEditor
              value={value ?? "-2w"}
              onChange={onChange}
              disabled={!value}
              {...props}
            />
            <LabelledCheckbox
              label="Send when enrolled"
              checked={!value}
              onChange={checked => {
                if (checked) onChange(null);
                if (!checked) onChange("-2w");
              }}
            />
          </Layout.Group>
        </Card>
      </Popover>
    </>
  );
};
