import { useMemo } from "react";
import { safeIntroistDateTimeToMoment } from "@introist/introist-commons/dist";
import { TTagColorVariant, Tag } from "@introist/react-foundation/v2";

import { IntroistDateTime } from "utils/dates";
import { capitalizeFirstLetter } from "utils/Text";

type StatusTagProps = {
  status: string;
  timestamp?: IntroistDateTime;
  stepType?: string;
};

export const getColorVariantByStatus = (status: string): TTagColorVariant => {
  switch (status) {
    case "draft":
      return "outlined";
    case "scheduled":
    case "delivered":
      return "primary";
    case "completed":
      return "success";
    case "failing":
      return "danger";
    case "canceled":
    default:
      return "surface";
  }
};

export const StatusTag = ({ status, timestamp, stepType }: StatusTagProps) => {
  const resolvedTitle = useMemo(() => {
    if (!!stepType && stepType === "event" && status === "scheduled") return "Processing";
    if (!!stepType && stepType === "emailtask" && status === "delivered") return "In Progress";
    if (!!stepType && stepType === "slacktask" && status === "delivered") return "In Progress";
    if (stepType === "emailform" && status === "delivered") return "Waiting for response";
    if (stepType === "eventparticipation" && status === "delivered") return "Upcoming";

    if (timestamp && (status === "scheduled" || status === "delivered")) {
      const startMoment = safeIntroistDateTimeToMoment(timestamp!);
      return startMoment.isAfter(Date.now()) ? startMoment.fromNow() : "Now";
    }

    return capitalizeFirstLetter(status);
  }, [status, timestamp, stepType]);

  return (
    <Tag
      colorVariant={getColorVariantByStatus(status)}
      startIcon={status === "failing" ? "warning" : undefined}
    >
      {resolvedTitle}
    </Tag>
  );
};

export const ActiveStatusTag = () => {
  return <Tag colorVariant="success">Active</Tag>;
};
