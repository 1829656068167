import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import clamp from "clamp";

import introCat from "./introcat.json";

type IntroCatProps = {
  taskId: string;
};

type TAnimationSegment = [number, number];

type TMarker = {
  tm: number;
  cm: string;
  dr: number;
};

type TSegment = [number, number];

const getSegmentsFromMarkers = (animationData: { markers: TMarker[] }): TSegment[] => {
  let segments: TSegment[] = [];

  for (let i = 0; i < animationData.markers.length; i += 2) {
    const startMarker = animationData.markers[i];
    const endMarker = animationData.markers[i + 1];

    // Ensure we have a pair of start and end markers
    if (startMarker && endMarker && startMarker.cm === "start") {
      segments.push([startMarker.tm, endMarker.tm]);
    }
  }

  return segments;
};

const StyledIntroCat = styled.div`
  width: 300px;
`;

export const IntroCat = ({ taskId, ...rest }: IntroCatProps) => {
  const ref = useRef<LottieRefCurrentProps | null>(null);

  const segmentIndex = useMemo(() => {
    switch (taskId) {
      case "choose-template":
        return 0;
      case "connect-accounts":
        return 1;
      case "review-steps":
      case "test-workflow":
        return 2;
      default:
        return 0;
    }
  }, [taskId]);

  const segments = useMemo(() => {
    return getSegmentsFromMarkers(introCat);
  }, []);

  const currentSegmentIndex = useRef<number>(segmentIndex);

  const getMorphSegment = useCallback(
    (fromSegmentIndex: number, toSegmentIndex: number): TAnimationSegment => {
      const fromSegment = segments[fromSegmentIndex];
      const toSegment = segments[toSegmentIndex];

      if (fromSegmentIndex < toSegmentIndex) {
        return [fromSegment[1], toSegment[0]];
      }

      return [fromSegment[0], toSegment[1]];
    },
    [segments]
  );

  useEffect(() => {
    if (!ref.current || !segments) return;

    const nextSegmentIndex = clamp(0, segments.length - 1, segmentIndex);
    const nextSegment = segments[nextSegmentIndex];
    const segmentIndexDelta = Math.abs(currentSegmentIndex.current - nextSegmentIndex);

    const morph = segmentIndexDelta === 1;

    if (morph) {
      ref.current.playSegments(getMorphSegment(currentSegmentIndex.current, nextSegmentIndex));
    }

    ref.current.playSegments([[nextSegment[0], nextSegment[1]]], !morph);
    currentSegmentIndex.current = nextSegmentIndex;
  }, [ref, segments, segmentIndex, getMorphSegment, currentSegmentIndex]);

  return (
    <StyledIntroCat {...rest}>
      <Lottie lottieRef={ref} animationData={introCat} />
    </StyledIntroCat>
  );
};

export const MemoizedIntroCat = memo(IntroCat);
