import { FC, useState } from "react";
import { Icon, LabelledCheckbox, Layout, Title, useTheme } from "@introist/react-foundation/v2";

import { StepTrigger, StepTriggerPeriod, StepTriggerSide } from "services/api/WorkflowApi.js";

import { ButtonPopoverFlexRangeEditor } from "./presets/ButtonPopoverFlexRangeEditor";
import { TriggerDayPicker } from "components/molecules";

const defaultTrigger = {
  amount: 0,
  period: "days" as StepTriggerPeriod
};

export type FlexRangeEditorProps = {
  startTrigger?: StepTrigger;
  endTrigger?: StepTrigger;
  onChange: ({ flexStart, flexEnd }: { flexStart?: any; flexEnd?: any }) => void;
  title?: string;
  description?: string;
  allowToggling?: boolean;
  triggerString: string;
};

type Compound = {
  ButtonPopover: typeof ButtonPopoverFlexRangeEditor;
};

const getDefaultTrigger = (side: StepTriggerSide): StepTrigger => ({ ...defaultTrigger, side });

const FlexRangeEditorComponent: FC<FlexRangeEditorProps> & Compound = ({
  startTrigger,
  endTrigger,
  onChange,
  title = "Search for available times",
  description,
  triggerString,
  allowToggling
}) => {
  const { theme } = useTheme();
  const [active, setActive] = useState(!!startTrigger && !!endTrigger);
  return (
    <Layout.Group vertical gap="large">
      <Layout.Group vertical gap="xSmall">
        <Title variant="bold">{title}</Title>
        <Title>{description}</Title>
        {allowToggling && (
          <LabelledCheckbox
            label="Use availability search"
            checked={active}
            onChange={checked => {
              if (checked) {
                setActive(true);
                onChange({
                  flexStart: getDefaultTrigger("before"),
                  flexEnd: getDefaultTrigger("after")
                });
              } else {
                setActive(false);
                onChange({ flexStart: undefined, flexEnd: undefined });
              }
            }}
          />
        )}
      </Layout.Group>

      <TriggerDayPicker
        startIcon="minusSmall"
        onChange={nextStart => onChange({ flexStart: nextStart, flexEnd: endTrigger })}
        trigger={startTrigger || getDefaultTrigger("before")}
        disableSide
        disabled={!active}
        minAmount={0}
        supportHours
      />
      <Layout.Group gap="large">
        <Icon name="calendar" color={theme.palette.foreground.subdued} />
        <Title>{triggerString}</Title>
      </Layout.Group>
      <TriggerDayPicker
        startIcon="plusSmall"
        onChange={nextEnd => onChange({ flexStart: startTrigger, flexEnd: nextEnd })}
        trigger={endTrigger || getDefaultTrigger("after")}
        disableSide
        disabled={!active}
        minAmount={0}
        supportHours
      />
    </Layout.Group>
  );
};

FlexRangeEditorComponent.ButtonPopover = ButtonPopoverFlexRangeEditor;
export const FlexRangeEditor = FlexRangeEditorComponent;
