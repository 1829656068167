import { api } from "../../../services/rpc/RpcProvider";
import { useMemo } from "react";

export const useTestReport = (dataSourceId: string) => {
  const { data: testReport } = api.employees.dataSources.testReport.useQuery({
    dataSourceId
  });
  const { data: employees } = api.employees.v4.list.useQuery({});

  const employeeOptions = useMemo(() => {
    if (!testReport) return [];

    return testReport.matching
      .filter(m => !!m.identifierValue)
      .map(m => {
        const employee = employees?.find(e => e.id === m.introistEmployeeId);
        return {
          key: m.identifierValue!,
          title: `${m.identifierValue} - ${employee ? employee.name : ""}`
        };
      })
      .sort();
  }, [testReport, employees]);

  return { testReport, employeeOptions };
};
