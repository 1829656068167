import { Icon, Layout, Select, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";
import { ComparisonMode, ComparisonModeOptions } from "./types";

type ComparisonModeSelectProps = {
  mode: ComparisonMode;
  onChange: (mode: ComparisonMode) => void;
  startText: string;
};

const ModeSelect = styled(Select)`
  > button {
    min-width: 4rem;
    > div {
      justify-content: space-between;
    }
  }
`;

export const ComparisonModeSelect = ({
  startText,
  mode,
  onChange,
  ...rest
}: ComparisonModeSelectProps) => {
  return (
    <Layout.Group {...rest} gap="small">
      <Title>{startText}</Title>
      <ModeSelect
        size="small"
        endAdornment={<Icon name="chevronDown" />}
        element="button"
        options={ComparisonModeOptions}
        value={mode}
        onSelect={option => {
          onChange(option.key as ComparisonMode);
        }}
      />
      <Title>of the rules are matched</Title>
    </Layout.Group>
  );
};
