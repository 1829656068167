import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";

export const TeamsChatMessageViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Chat">
          <StepEditorAccordion.Row label="From">
            <Stack>
              <Icon name="person" />
              <Title variant="bold">{resolvedData.senderName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="To">
            <Stack vertical>
              {(resolvedData.resolvedUsers ?? []).map((m: any) => (
                <Stack>
                  <Icon name="person" />
                  <Title variant="bold">{m.name}</Title>
                </Stack>
              ))}
            </Stack>
          </StepEditorAccordion.MultiOptionRow>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage} />
      </Block>
    </>
  );
};
