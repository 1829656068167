import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Title, Option, H4, Tag, InputV2, Stack } from "@introist/react-foundation/v2";
import { CategorisedOption, CategorySelect } from "components/organisms";
import { capitalize } from "lodash";

type ListSectionProps = {
  title: ReactNode;
  description?: string;
  children: ReactNode;
  search?: {
    search?: string;
    onSearchChange: (search: string) => unknown;
    placeholder?: string;
    onEnter?: (search: string) => unknown;
  };
  filter?: {
    filters: CategorisedOption[];
    categories: Option[];
    options: CategorisedOption[];
    onFilterChange: (filters: CategorisedOption[]) => unknown;
  };
  headerRightContent?: ReactNode;
  actions?: ReactNode;
  endActions?: ReactNode;
  stretchContent?: boolean;
};

const StyledListSection = styled(Stack).attrs({ as: "section" })`
  user-select: none;
  flex: 1;
`;

const ListHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchWrapper = styled.div`
  .text-input-wrapper > div {
    border-color: var(--palette-border-default);
  }
`;

const useSearch = (onChange?: (search: string) => unknown) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    onChange && onChange(search);
  }, [search, onChange]);
  return { search, setSearch };
};

export const ListSection = ({
  title,
  description,
  children,
  search,
  filter,
  actions,
  endActions,
  stretchContent,
  ...rest
}: ListSectionProps) => {
  const { search: textSearch, setSearch } = useSearch(search?.onSearchChange);

  const onRemoveFilter = (filterToRemove: CategorisedOption) => {
    if (!filter) return;

    filter.onFilterChange(
      filter.filters.filter((f: CategorisedOption) => f.key !== filterToRemove.key)
    );
  };

  return (
    <StyledListSection {...rest} vertical gap="large">
      <ListHeader>
        <Stack justifyContent="space-between" style={{ flex: 1 }}>
          <Stack>
            <Stack gap="small">
              <H4>{title}</H4>
              {description && <Title>{description}</Title>}
            </Stack>

            <Stack gap="small">
              {filter && (
                <CategorySelect
                  categories={filter.categories}
                  options={filter.options}
                  value={filter.filters}
                  onChange={filter.onFilterChange}
                  startIcon="filter"
                  variant="outlined"
                  size="small"
                >
                  Filter
                </CategorySelect>
              )}
              {actions}
            </Stack>
          </Stack>
        </Stack>
        <Stack gap="small">
          {endActions && endActions}

          {search && (
            <InputV2
              value={search.search || textSearch}
              onChange={e => setSearch(e.target.value)}
              placeholder={search.placeholder || "Search"}
              endIcon="search"
              // @ts-ignore
              onKeyUp={e => {
                if (e.key === "Enter") search.onEnter && search.onEnter(textSearch);
              }}
            />
          )}
        </Stack>
      </ListHeader>
      {filter && filter.filters.length > 0 && (
        <Stack gap="xSmall">
          {filter.filters.map(filter => (
            <Tag.Filter
              key={`${title}-${filter.category}-${filter.key}`}
              onRemove={() => onRemoveFilter(filter)}
              filter={{
                filterName: capitalize(filter.category),
                method: "is",
                value: [filter.title || filter.key]
              }}
            />
          ))}
        </Stack>
      )}
      {children}
    </StyledListSection>
  );
};
