import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { DataForm } from "@introist/react-foundation/v2";
import { EmailTaskEditorV2 } from "modules/workflows/routes/WorkflowEditor/StepEditor/TaskStepEditorV2";
import { TaskContentBlock } from "modules/workflows/routes/WorkflowEditor/StepEditor/TaskStepEditorV2/blocks/TaskContentBlock";
import { EmailMessageBlock } from "modules/journeys/components/EmailMessageBlock";
type Props = {
  form: DataForm<JourneyStepV2>;
  readOnly?: boolean;
};

export const EmailTaskJourneyStepEditor = ({ form, readOnly }: Props) => {
  if (readOnly) return <ReadonlyEmailTaskJourneyStepEditor step={form.data} />;
  return <EmailTaskEditorV2 form={form} formPrefix="previewData." />;
};

type ReadonlyProps = {
  step: JourneyStepV2;
};

const ReadonlyEmailTaskJourneyStepEditor = ({ step }: ReadonlyProps) => {
  const data = step.journeyData;

  return (
    <>
      <TaskContentBlock value={data.task} onChanged={() => {}} readOnly />
      <EmailMessageBlock
        title="Message details"
        message={data.resolvedMessage}
        originalMessage={data.message}
      />
    </>
  );
};
