import { useMemo } from "react";
import { Select } from "@introist/react-foundation/v2";
import { isEmpty, uniq } from "lodash";

import { ExpressionField } from "components/organisms/RecursiveExpressionEditor";
import { Comparator, ComparatorOptions } from "components/organisms/ExpressionEditor";

type ComparatorSelectProps = {
  field: ExpressionField;
  value?: Comparator;
  onSelect: (comparator: Comparator) => void;
};

export const buildAttributeTypeComparatorOptions = (type: string) => {
  const resolveTypeComparators = () => {
    if (type === "select") {
      return ComparatorOptions.filter(o => ["eq", "ne"].includes(o.key));
    }
    if (type === "date") {
      return ComparatorOptions.filter(o => o.category === "date");
    }

    return ComparatorOptions.filter(o => o.category !== "date");
  };

  return uniq([
    ...resolveTypeComparators(),
    ...ComparatorOptions.filter(o => ["exists", "notexists"].includes(o.key))
  ]);
};

export const ComparatorSelect = ({ field, value, onSelect }: ComparatorSelectProps) => {
  const comparatorOptions = useMemo(() => {
    if (!field) return [];
    return buildAttributeTypeComparatorOptions(field.type);
  }, [field]);

  const defaultValue = !isEmpty(comparatorOptions) ? comparatorOptions[0].key : undefined;

  return (
    <Select
      attachToRef={false}
      size="small"
      sameWidthWithReference
      options={comparatorOptions || defaultValue}
      value={value}
      onSelect={({ key }) => onSelect(key as Comparator)}
    />
  );
};
