import { IntroistDate } from "../../utils/dates";
import { postJson } from "./ApiBase";
import { TimeRange } from "./types";
import { IntroistDateTime } from "./WorkflowApi";

export interface MatchingEvent {
  title: string;
  calendarEventId?: string;
  eventId?: string;
  start: IntroistDateTime;
  end: IntroistDateTime;
}

export interface FindAvailabilityRequest {
  from: IntroistDate;
  to: IntroistDate;
  hostId: string;
  targetEmails: string[];
  eventLengthMinutes: number;
  timezone: string;
}

export const findAvailability = (req: FindAvailabilityRequest): Promise<TimeRange[]> => {
  return postJson(`/v1/availability`, req);
};

export interface MatchingEventsRequest {
  from: IntroistDate;
  to: IntroistDate;
  timezone: string;
  workflowStepId: string;
  hostId?: string;
  eventSummary?: string;
}

export const findMatchingEvents = (req: MatchingEventsRequest): Promise<MatchingEvent[]> => {
  return postJson(`/v1/matchingevent`, req);
};
