import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "components/atoms";

export type SympaHRConfig = {
  apiKey: string;
  apiSecret: string;
};

type SympaHRConfigFieldsProps = {
  config: SympaHRConfig;
  onChange: (config: object) => void;
};

export const SympaHRConfigFields = ({ config, onChange }: SympaHRConfigFieldsProps) => {
  return (
    <Layout.Group vertical>
      <TextInput
        type="password"
        label="API key"
        placeholder="Enter your SympaHR API key"
        value={config.apiKey}
        onChange={apiKey => onChange({ ...config, apiKey })}
      />
      <TextInput
        type="password"
        label="API secret"
        placeholder="Enter your SympaHR API secret"
        value={config.apiSecret}
        onChange={apiSecret => onChange({ ...config, apiSecret })}
      />
    </Layout.Group>
  );
};
