import { ReactNode, useCallback, useEffect, useState } from "react";
import { Option, Stack, useActions, useToast } from "@introist/react-foundation/v2";
import { isEmpty } from "lodash";

import {
  api,
  ComputedEmployeeField,
  GroupedEmployeeField,
  HrisDetails
} from "services/rpc/RpcProvider";

import { EmployeeFieldGroup } from "../../EmployeeFieldsSettingsRoute";
import { EmployeeFieldRow } from "./EmployeeFieldRow";
import { EmployeeFieldGroupRow } from "./EmployeeFieldGroupRow";
import { DraggableList } from "../DraggableList";

export type EmployeeFieldTransformActions = {
  onEditTransform?: (field: GroupedEmployeeField) => void;
  onDeleteTransform?: (field: GroupedEmployeeField) => void;
  onRunTransformForAllEmployees?: (field: GroupedEmployeeField) => void;
};

export type EmployeeFieldActions = {
  onAddTransform?: (field: GroupedEmployeeField) => void;
  onArchiveField?: (field: GroupedEmployeeField) => void;
  onEditField?: (field: GroupedEmployeeField) => void;
  onAddSubField?: ({ name, id }: { name: string; id: string }) => void;
  onAddUpdatedAt?: (field: GroupedEmployeeField) => void;
};

export type EmployeeFieldListProps = EmployeeFieldActions &
  EmployeeFieldTransformActions & {
    title?: string;
    titleStartAdornment?: ReactNode;
    employeeFields: GroupedEmployeeField[];
    tooltip?: string;
    emptyState?: ReactNode;
    hris?: HrisDetails | null;
    disableOrdering?: boolean;
    computedFields: ComputedEmployeeField[];
  };

export const useEmployeeFieldActions = () => {
  const buildFieldActions = ({
    field,
    onArchiveField,
    onAddTransform,
    onEditField,
    onAddUpdatedAt
  }: {
    field: GroupedEmployeeField;
  } & EmployeeFieldActions): Option[] => {
    const actions: Option[] = [];

    if (onEditField) {
      actions.push({
        key: "edit-employee-field",
        title: "Edit",
        startAdornmentIcon: "pencil",
        onClick: () => onEditField(field)
      });
    }

    if (onAddTransform) {
      actions.push({
        key: "employee-field-add-transform",
        title: "Add transform",
        startAdornmentIcon: "workflowEditor",
        onClick: () => onAddTransform(field)
      });
    }

    if (onAddUpdatedAt) {
      actions.push({
        key: "employee-field-add-updated-at",
        title: "Add updated at field",
        startAdornmentIcon: "history",
        onClick: () => onAddUpdatedAt(field)
      });
    }

    if (onArchiveField && !field.hidden) {
      actions.push({
        key: "archive-employee-field",
        title: "Archive field",
        startAdornmentIcon: "archive",
        onClick: () => onArchiveField(field)
      });
    }

    return actions;
  };

  return { buildFieldActions };
};

export const useFieldTransformActions = () => {
  const { onConfirmAction } = useActions();

  const buildFieldTransformActions = ({
    field,
    onEditTransform,
    onDeleteTransform,
    onRunTransformForAllEmployees
  }: { field: GroupedEmployeeField } & Pick<
    EmployeeFieldListProps,
    "onEditTransform" | "onDeleteTransform" | "onRunTransformForAllEmployees"
  >) => {
    const actions: Option[] = [];

    if (onEditTransform) {
      actions.push({
        key: "employee-field-edit-transform",
        title: "Edit transform",
        startAdornmentIcon: "pencil",
        onClick: () => onEditTransform(field)
      });
    }

    if (onRunTransformForAllEmployees) {
      actions.push({
        key: "employee-field-update-transform-for-all-employees",
        title: "Update for all employees",
        startAdornmentIcon: "recurring",
        onClick: onConfirmAction(() => onRunTransformForAllEmployees(field), {
          title: "Update for all employees",
          description:
            "This will run the transform function for all employees. Are you sure you want to proceed?",
          confirmTitle: "Update"
        })
      });
    }

    if (onDeleteTransform) {
      actions.push({
        key: "employee-field-remove-transform",
        title: "Delete transform",
        startAdornmentIcon: "trash",
        onClick: onConfirmAction(() => onDeleteTransform(field), {
          title: "Delete transform",
          description:
            "Are you sure you want to Delete this transform? This action cannot be undone.",
          confirmTitle: "Delete"
        })
      });
    }

    return actions.length ? actions : undefined;
  };

  return {
    buildFieldTransformActions
  };
};

export const EmployeeFieldList = ({
  employeeFields,
  computedFields,
  header,
  hris,
  emptyState,
  disableOrdering,
  onArchiveField,
  onAddSubField,
  onEditField,
  onAddTransform,
  onEditTransform,
  onDeleteTransform,
  onRunTransformForAllEmployees,
  onAddUpdatedAt,
  ...rest
}: Omit<EmployeeFieldListProps, "title" | "tooltip"> & {
  header: ReactNode;
}) => {
  const toast = useToast();

  const [fields, setFields] = useState(employeeFields);

  useEffect(() => {
    setFields(employeeFields);
  }, [employeeFields, setFields]);

  const updateOrder = api.employees.fields.order.useMutation();

  const { buildFieldActions } = useEmployeeFieldActions();
  const { buildFieldTransformActions } = useFieldTransformActions();

  const onReordered = useCallback(
    async (items: GroupedEmployeeField[], newIndex: number) => {
      setFields(items);
      const previous = newIndex > 0 ? items[newIndex - 1].id : null;
      const moved = items[newIndex].id;

      await updateOrder
        .mutateAsync({ nextTo: previous, fieldId: moved })
        .catch(() => toast.error("Failed to save order. Please refresh the page."));
    },
    [updateOrder, toast]
  );

  const renderField = (field: GroupedEmployeeField) => {
    if (field.type === "group") {
      return (
        <EmployeeFieldGroupRow
          field={field as EmployeeFieldGroup}
          actions={buildFieldActions({ field, onEditField, onArchiveField })}
          onAddSubField={onAddSubField}
          onArchiveField={onArchiveField}
          onAddTransform={onAddTransform}
          onEditField={onEditField}
          disableSort={disableOrdering}
        />
      );
    }

    const computedField = computedFields.find(f => f.field === field.variable);
    const transformActions = computedField
      ? buildFieldTransformActions({
          field: field,
          onEditTransform,
          onDeleteTransform,
          onRunTransformForAllEmployees
        })
      : undefined;

    const actions = buildFieldActions({
      field,
      onEditField,
      onArchiveField,
      onAddTransform: !computedField ? onAddTransform : undefined,
      onAddUpdatedAt
    });

    return (
      <EmployeeFieldRow
        field={field}
        transformActions={transformActions}
        actions={actions}
        disableSort={disableOrdering}
      />
    );
  };

  return (
    <Stack vertical gap="large" {...rest}>
      {header}

      {isEmpty(employeeFields) && emptyState}

      {!isEmpty(employeeFields) && (
        <DraggableList
          items={fields}
          onReordered={disableOrdering ? undefined : onReordered}
          renderItem={renderField}
        />
      )}
    </Stack>
  );
};
