import { DataForm, InfoCard } from "@introist/react-foundation/v2";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { TaskContentBlock } from "modules/workflows/routes/WorkflowEditor/StepEditor/TaskStepEditorV2/blocks/TaskContentBlock";
import { SlackTaskEditor } from "modules/workflows/routes/WorkflowEditor/StepEditor/TaskStepEditorV2";
import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ParticipantCard } from "modules/workflows/components/ParticipantCard";
import { SlackSenderSelectV2 } from "modules/workflows/routes/WorkflowEditor/StepEditor/SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { useAttributes } from "hooks/use-attributes";
import { useMemo } from "react";
import { ReadOnlyEmailMessage } from "components/organisms";

type Props = {
  form: DataForm<JourneyStepV2>;
  readOnly?: boolean;
};

export const SlackTaskJourneyStepEditor = ({ form, readOnly }: Props) => {
  if (readOnly) return <ReadOnlyEditor journeyData={form.data.journeyData} />;
  return <SlackTaskEditor form={form} formPrefix="previewData." />;
};

type ReadonlyProps = {
  journeyData?: JourneyStepV2["journeyData"];
};

const ReadOnlyEditor = ({ journeyData }: ReadonlyProps) => {
  const attributes = useAttributes(false, ["email"]);

  const recipients = useMemo(() => {
    if (!journeyData) return [];
    const resolvedRecipients: any[] = journeyData.resolvedMessage.recipients;
    return resolvedRecipients.map((recipient: any) => {
      const attribute = attributes.find(a => a.variable === recipient.value);
      if (attribute) {
        return {
          name: attribute.name,
          description: recipient.resolvedName
        };
      } else {
        return {
          name: recipient.resolvedName
        };
      }
    });
  }, [attributes, journeyData]);

  if (!journeyData) {
    return (
      <Block compact blended>
        <InfoCard title="No step data">Step was canceled before execution</InfoCard>
      </Block>
    );
  }

  return (
    <>
      <TaskContentBlock value={journeyData.task} readOnly onChanged={() => {}} />
      <Block compact blended>
        <StepEditorAccordion title="Message details">
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={journeyData.message.senderId}
              onSelectSender={() => {}}
              readOnly
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="To">
            {recipients.map(recipient => (
              <ParticipantCard
                readOnly
                title={recipient.name}
                description={recipient.description}
              />
            ))}
          </StepEditorAccordion.Row>
          <ReadOnlyEmailMessage message={journeyData.message.message} />
        </StepEditorAccordion>
      </Block>
    </>
  );
};
