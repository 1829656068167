import { ComponentProps, useMemo } from "react";
import styled from "styled-components";
import { Tooltip, useTheme } from "@introist/react-foundation/v2";

type ImportantGuestButtonProps = Pick<ComponentProps<"button">, "onClick"> & {
  selected: boolean;
  readOnly?: boolean;
};

const StyledImportantGuestButton = styled.button`
  border: none;
  padding: 0.25rem;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: var(--rounding-medium);
  transition: background-color 160ms ease;
  background-color: transparent;
  margin-right: 0.25rem;
`;

export const ImportantGuestButton = ({
  selected,
  readOnly,
  onClick,
  ...rest
}: ImportantGuestButtonProps) => {
  const { theme } = useTheme();

  const tooltip = useMemo(() => {
    if (selected && readOnly) {
      return "Important guest";
    }

    return selected ? "Mark optional" : "Mark important";
  }, [selected, readOnly]);

  const backgroundFill = useMemo(() => {
    if (selected && readOnly) {
      return theme.palette.foreground.subdued;
    }

    return selected ? theme.palette.primary.default : "none";
  }, [selected, readOnly, theme.palette]);

  const outline = useMemo(() => {
    if (selected && readOnly) {
      return theme.palette.foreground.subdued;
    }
    return selected ? theme.palette.primary.default : theme.palette.foreground.dimmed;
  }, [selected, readOnly, theme.palette]);

  return (
    <Tooltip tooltip={tooltip} placement="left">
      <StyledImportantGuestButton
        {...rest}
        onClick={event => !readOnly && onClick && onClick(event)}
        style={{
          cursor: readOnly ? "default" : "pointer"
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
            stroke="none"
            fill={backgroundFill}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <rect
            fill={selected ? "white" : "none"}
            x={5}
            y={13}
            width={6}
            height={2}
            stroke="none"
          />

          <g clipPath="url(#clip0_1259_3574)">
            <path
              d="M4.1665 13.8116V12.9299C4.1665 11.9132 4.57039 10.9381 5.28931 10.2192C6.00824 9.50026 6.98331 9.09637 8.00002 9.09637V9.09637C9.01673 9.09637 9.9918 9.50026 10.7107 10.2192C11.4296 10.9381 11.8335 11.9132 11.8335 12.9299V13.8116"
              stroke={selected ? "white" : theme.palette.foreground.dimmed}
              fill={selected ? "white" : "none"}
              strokeWidth={selected ? 0 : 1.5}
              strokeLinejoin="round"
            />
            <path
              d="M8.00012 9.09643C9.30431 9.09643 10.3616 8.03917 10.3616 6.73498C10.3616 5.43079 9.30431 4.37354 8.00012 4.37354C6.69593 4.37354 5.63867 5.43079 5.63867 6.73498C5.63867 8.03917 6.69593 9.09643 8.00012 9.09643Z"
              stroke={selected ? "white" : theme.palette.foreground.dimmed}
              fill={selected ? "white" : "none"}
              strokeWidth={selected ? 0 : 1.5}
              strokeLinejoin="round"
            />
          </g>
          <path
            d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
            stroke={outline}
            fill="none"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />

          <defs>
            <clipPath id="clip0_1259_3574">
              <rect width="16" height="16" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </StyledImportantGuestButton>
    </Tooltip>
  );
};
