import { useEffect, useMemo, useState } from "react";
import {
  useActions,
  Title,
  Button,
  DateInput,
  Layout,
  BaseModal,
  H4,
  IconButton
} from "@introist/react-foundation/v2";

import { MediumTag } from "components/atoms";
import { MatchingEvent } from "services/api/AvailabilityApi";
import { CalendarEventJourneyStep, useJourneyStepApi } from "services/api/journeys/JourneyStepApi";

import { DateFormats, IntroistDateTime } from "../../../utils/dates";

import { MatchingEventCalendar } from "./MatchingEventCalendar";
import { JourneyStepV2, StepTypeWithEventTypeV2, api } from "services/rpc/RpcProvider";
import { StepTypeIcon } from "modules/workflows/components/steps";
import { DateTimePicker, DateTimeRangePicker } from "components/molecules";

export type RescheduleModalProps = {
  open: boolean;
  onClose: () => unknown;
  start: IntroistDateTime;
  end?: IntroistDateTime;
  stepId: string;
  journeyId: string;
  allowCreate?: boolean;
  onRescheduled: () => Promise<unknown>;
  useMatchingEvents?: boolean;
  title?: string;
  description?: string;
  hostId?: string;
  eventSummary?: string;
};

export type InnerTime = {
  start: IntroistDateTime;
  end?: IntroistDateTime;
};
type EventEditorProps = {
  step: JourneyStepV2;
  timeframe: {
    start: IntroistDateTime;
    end?: IntroistDateTime;
  };
  onInnerTimesChange: (innerTime: InnerTime) => void;
};

export const EventEditor = ({ timeframe, step, onInnerTimesChange }: EventEditorProps) => {
  if (isAllDayEvent(timeframe, step)) {
    return (
      <DateInput
        value={timeframe.start?.date}
        displayFormat={DateFormats.date.long}
        onChange={date => {
          if (!date) return;

          onInnerTimesChange({
            start: { ...timeframe.start, date },
            end: { ...timeframe.end!, date }
          });
        }}
      />
    );
  }

  if (isEvent(step) && timeframe.end) {
    return (
      <DateTimeRangePicker
        start={timeframe.start}
        end={timeframe.end}
        onChange={(start, end) => {
          onInnerTimesChange({ start, end });
        }}
      />
    );
  }

  return (
    <DateTimePicker
      dateTime={timeframe.start}
      onChange={start => {
        onInnerTimesChange({ start, end: timeframe.end ? start : undefined });
      }}
    />
  );
};

export const isAllDayEvent = (timeframe: InnerTime, step: JourneyStepV2) => {
  return timeframe.start.time === timeframe.end?.time && step.stepType === "event";
};

export const isEvent = (step: JourneyStepV2) => step.stepType === "event";

export const RescheduleModal = ({
  open,
  onClose,
  start,
  stepId,
  journeyId,
  onRescheduled,
  end,
  allowCreate,
  useMatchingEvents,
  title = "Reschedule step",
  description,
  hostId,
  eventSummary
}: RescheduleModalProps) => {
  const { onAction } = useActions();
  const [showEditor, setShowEditor] = useState<boolean>(!useMatchingEvents);

  const { data: step } = api.journeys.steps.find.useQuery({ stepId });

  const journeyStepApi = useJourneyStepApi();
  const reschedule = journeyStepApi.reschedule();

  const [innerTimes, setInnerTimes] = useState<{ start: IntroistDateTime; end?: IntroistDateTime }>(
    {
      start,
      end
    }
  );
  const [matchingEvent, setMatchingEvent] = useState<MatchingEvent | undefined>();

  useEffect(() => {
    setInnerTimes(times => ({ ...times, start }));
  }, [start, setInnerTimes]);

  useEffect(() => {
    setInnerTimes(times => ({ ...times, end }));
  }, [end, setInnerTimes]);

  const isEvent = useMemo(() => step?.stepType === "event", [step?.stepType]);

  const handleSubmit = onAction(async () => {
    const createRequest = () => {
      if (matchingEvent?.eventId) {
        return {
          eventId: matchingEvent.eventId
        };
      } else if (matchingEvent?.calendarEventId) {
        return {
          calendarEventId: matchingEvent.calendarEventId
        };
      } else {
        return innerTimes;
      }
    };

    await reschedule.mutateAsync({
      stepId,
      journeyId,
      ...createRequest()
    });
    await onRescheduled();
  }, "Cannot reschedule the step");

  const getEditor = () => {
    if (isEvent && innerTimes.end) {
      return (
        <DateTimeRangePicker
          start={innerTimes.start}
          end={innerTimes.end}
          onChange={(start, end) => {
            setInnerTimes({ start, end });
            setMatchingEvent(undefined);
          }}
        />
      );
    }

    return (
      <DateTimePicker
        dateTime={innerTimes.start}
        onChange={start =>
          setInnerTimes(times => ({ ...times, start, end: times.end ? start : undefined }))
        }
      />
    );
  };

  const getMatchingEventCalendar = () => (
    <MatchingEventCalendar
      onInnerTimesChange={setInnerTimes}
      timezone={innerTimes.start.timezone}
      journeyId={journeyId}
      stepId={stepId}
      allowCreate={allowCreate}
      initialMonth={innerTimes.start!.date}
      onSelect={me => {
        setMatchingEvent(me);
        setShowEditor(false);
      }}
      onCreateNewEvent={() => {
        setShowEditor(true);
        setMatchingEvent(undefined);
      }}
      selected={matchingEvent}
      hostId={hostId}
      eventSummary={eventSummary}
      timeframe={innerTimes}
      selfEventId={(step as unknown as CalendarEventJourneyStep)?.eventId}
    />
  );

  return (
    <BaseModal open={open} maxWidth={512}>
      <Layout.Group vertical gap="xLarge">
        <Layout.Group vertical gap="small">
          <Layout.Group justifyContent="space-between">
            <H4>{title}</H4>
            <IconButton icon="crossLarge" onClick={onClose} />
          </Layout.Group>
          {description && <Title>{description}</Title>}
        </Layout.Group>
        <Layout.Divider />

        {step && (
          <Layout.Group justifyContent="center">
            <MediumTag
              backgroundColor="transparent"
              startAdornment={
                <StepTypeIcon
                  compact
                  variant="blended"
                  stepType={step?.stepType as StepTypeWithEventTypeV2}
                />
              }
            >
              <Title variant="bold">{step.title}</Title>
            </MediumTag>
          </Layout.Group>
        )}

        {useMatchingEvents && innerTimes.end && <>{getMatchingEventCalendar()}</>}

        {showEditor && getEditor()}

        <Layout.Divider />

        <Layout.Group justifyContent="flex-end">
          <Button onClickWithLoading={handleSubmit}>Reschedule</Button>
        </Layout.Group>
      </Layout.Group>
    </BaseModal>
  );
};
