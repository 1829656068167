import { Tag } from "@introist/react-foundation/v2";
import { CompanyEventInstanceAttendee } from "services/rpc/RpcProvider";

type EventAttendeeAttendanceStatusTagProps = {
  status: CompanyEventInstanceAttendee["attendanceStatus"] | "unmanaged";
};

export const EventAttendeeAttendanceStatusTag = ({
  status,
  ...rest
}: EventAttendeeAttendanceStatusTagProps) => {
  switch (status) {
    case "accepted":
      return (
        <Tag {...rest} colorVariant="success">
          Accepted
        </Tag>
      );
    case "declined":
      return <Tag {...rest}>Declined</Tag>;
    case "waiting":
      return <Tag {...rest}>Waiting</Tag>;
    case "unmanaged":
      return <Tag {...rest}>Not managed</Tag>;
    case "notfound":
      return <Tag {...rest}>Not Found</Tag>;
    default:
      return (
        <Tag {...rest} colorVariant="outlined">
          Unknown
        </Tag>
      );
  }
};
