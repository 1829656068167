import {
  Accordion,
  Card,
  Icon,
  IconButton,
  Option,
  Select,
  Stack,
  Tag,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { AttributeAvatar } from "components/molecules";
import { ConditionWithId } from "components/organisms";
import {
  expressionToConditionGroup,
  useConditionDisplay
} from "components/organisms/RecursiveExpressionEditor";
import { useEmployeeGroups } from "hooks";
import { useState } from "react";
import { EmployeeField, RouterOutput, Trigger, api } from "services/rpc/RpcProvider";
import styled from "styled-components";

type Props = {
  trigger: Trigger;
};

const StyledConditionCard = styled(Card)`
  padding: 0.5rem;
`;

const ConditionCard = ({
  condition,
  employeeFields,
  onClick
}: {
  condition: ConditionWithId;
  employeeFields?: EmployeeField[];
  onClick?: VoidFunction;
}) => {
  // @ts-ignore
  const { title, value } = useConditionDisplay(condition, employeeFields || []);

  return (
    <StyledConditionCard onClick={onClick}>
      <Stack gap="small">
        <AttributeAvatar />
        <Title variant="bold">{title}</Title>
        {value && <Tag>{value}</Tag>}
      </Stack>
    </StyledConditionCard>
  );
};

const StyledAccordion = styled(Accordion)`
  header {
    padding: var(--spacing-large);
  }

  > div > div {
    padding: var(--spacing-medium);
    padding-top: 0;
  }
`;

export const GroupRuleCard = ({
  employeeGroup,
  employeeCount,
  employeeFields,
  loading
}: {
  loading?: boolean;
  employeeCount?: number;
  employeeFields: EmployeeField[];
  employeeGroup?: RouterOutput["employees"]["groups"]["list"][0];
}) => {
  const group = expressionToConditionGroup(employeeGroup?.condition || { and: [] });

  return (
    <Stack vertical gap="large">
      {loading && (
        <Card>
          <Title style={{ opacity: 0 }}>loading</Title>
        </Card>
      )}
      {!loading && (
        <StyledAccordion
          defaultOpen={false}
          toggleOpenOnHeaderClick
          title={
            <Stack gap="small">
              <Icon name="persons" />
              <span>{employeeGroup?.title || ""}</span>
              {employeeCount && <Title small>{employeeCount}</Title>}
            </Stack>
          }
        >
          <Stack vertical>
            {group.conditions.map((condition, index) => (
              <ConditionCard
                key={`monitor-condition-${condition.id}-${index}`}
                condition={condition}
                employeeFields={employeeFields}
              />
            ))}
          </Stack>
        </StyledAccordion>
      )}
    </Stack>
  );
};

export const TriggerEmployeeGroup = ({ trigger, ...rest }: Props) => {
  const toast = useToast();
  const { employeeGroups, getEmployeeGroup } = useEmployeeGroups();
  const apiUtils = api.useContext();
  const employeeFields = api.employees.fields.list.useQuery({});
  const updateTriggerApi = api.triggers.update.useMutation({});
  const [selectedGroupId, setSelectedGroupId] = useState<string>(trigger.employeeGroupId || "all");

  const [edit, setEdit] = useState(false);

  const buildEmployeeGroupOptions = () => {
    const allEmployeesOption = {
      key: "all",
      title: "All Employees"
    };

    const groupOptions: Option[] = (employeeGroups.data || []).map(g => ({
      key: g.id,
      title: g.title
    }));

    return [allEmployeesOption, ...groupOptions];
  };

  const updateTriggerEmployeeGroup = async (employeeGroupId: string | null) => {
    setSelectedGroupId(employeeGroupId || "all");
    await updateTriggerApi.mutateAsync(
      {
        id: trigger.id,
        updates: {
          employeeGroupId
        }
      },
      {
        onError: () => {
          setSelectedGroupId(trigger.employeeGroupId || "all");
          toast.error("Failed to update employee group");
        },
        onSuccess: () => {
          apiUtils.triggers.preview.invalidate({ id: trigger.id });
          toast.success("Employee group updated");
          setEdit(false);
        }
      }
    );
  };

  const employeeGroup = getEmployeeGroup(selectedGroupId);

  return (
    <Stack {...rest} vertical gap="small">
      <Stack justifyContent="space-between">
        <Title variant="bold">Employee Group</Title>
        {edit ? (
          <IconButton dimmed size="large" icon="crossSmall" onClick={() => setEdit(false)} />
        ) : (
          <IconButton dimmed size="large" icon="pencil" onClick={() => setEdit(true)} />
        )}
      </Stack>

      {edit && (
        <Select
          style={{ width: "100%" }}
          value={selectedGroupId}
          options={buildEmployeeGroupOptions()}
          onSelect={o => updateTriggerEmployeeGroup(o.key)}
        />
      )}
      {employeeGroup && !edit && (
        <GroupRuleCard employeeGroup={employeeGroup} employeeFields={employeeFields.data || []} />
      )}
      {!edit && !employeeGroup && (
        <Card>
          <Stack gap="small">
            <Icon name="persons" />

            <Title variant="bold">All employees</Title>
          </Stack>
        </Card>
      )}
    </Stack>
  );
};
