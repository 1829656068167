import { forwardRef } from "react";
import styles from "./HtmlContentEditor.module.scss";
import { Editor, HtmlEditor, HtmlEditorProps } from "../HtmlEditor";

interface Props extends HtmlEditorProps {
  onGenerated: (text: string) => void;
  channel: string;
}
export const HtmlContentEditor = forwardRef<Editor, Props>(
  ({ onGenerated, channel, ...props }: Props, ref) => {
    return (
      <div className={styles.HtmlContentEditor}>
        <div className={styles.Editor}>
          <HtmlEditor ref={ref} {...props} />
        </div>
      </div>
    );
  }
);
