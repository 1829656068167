import { useRef } from "react";
import { DataForm, useTheme } from "@introist/react-foundation/v2";

import {
  Editor,
  ReadOnlyEmailMessage,
  HtmlContentEditor,
  HtmlEditorPowerUps
} from "components/organisms";
import { Block } from "components/atoms";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";

import { prefixForm } from "utils/FormUtils";
import { WorkflowStep } from "services/api/WorkflowApi";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

type SlackMessageEditorProps = {
  form: DataForm<WorkflowStep>;
  formPrefix?: string;
  readOnly?: boolean;
};

export const SlackMessageEditor = ({ form, formPrefix, readOnly }: SlackMessageEditorProps) => {
  const messageRef = useRef<Editor>(null);
  const { theme } = useTheme();

  const prefixedForm = prefixForm(form, formPrefix);

  const addVariable = (variable: EmployeeAttribute) => {
    if (messageRef.current !== null && messageRef.current.hasFocus()) {
      messageRef.current.insertUserPropertyToCursorPosition(variable);
      messageRef.current.insertTextToCursorPosition(" ");
    }
  };

  return (
    <Block blended compact>
      <StepEditorAccordion title="Message details" defaultOpen={true} stepOrder={3}>
        <div style={{ marginBottom: theme.spacing.xLarge }}>
          {readOnly && (
            <ReadOnlyEmailMessage
              message={
                formPrefix === "journeyData."
                  ? prefixedForm.get("htmlMessage")
                  : prefixedForm.get("message")
              }
            />
          )}
          {!readOnly && (
            <HtmlContentEditor
              ref={messageRef}
              defaultValue={
                formPrefix === "journeyData."
                  ? prefixedForm.get("htmlMessage")
                  : prefixedForm.get("message")
              }
              onChange={prefixedForm.set("message")}
              readonly={readOnly}
              onGenerated={text => messageRef.current?.replaceHTML(text)}
              channel="slack"
            />
          )}
        </div>
        <HtmlEditorPowerUps onAttributeSelected={addVariable} readonly={readOnly} />
      </StepEditorAccordion>
    </Block>
  );
};
