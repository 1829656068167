import styled, { css } from "styled-components";
import { Layout, Title } from "@introist/react-foundation/v2";
import { Check, HostCalendarIcon } from "components/atoms";

type Props = {
  connected: boolean;
  emailProvider: string;
  onClick?: () => void;
};

const StyledCalendarCardButton = styled.button<{ $active?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  overflow: hidden;
  border: none;
  border-radius: var(--rounding-medium);
  border: ${p =>
    p.$active
      ? "2px solid var(--palette-primary-default)"
      : "1px solid var(--palette-border-subdued)"};
  cursor: ${p => (p.$active ? "default" : "pointer")};
  position: relative;
  background-color: transparent;

  :after {
    content: "";
    position: absolute;
    width: 64px;
    top: 0;
    bottom: 0;
    background-color: var(--palette-surface-subdued);
    z-index: -1;
    transition: width 160ms ease-out;
  }

  > span {
    display: flex;
    padding: var(--spacing-xLarge);

    > svg {
      transition: transform 80ms linear;
    }
  }

  > div {
    padding: var(--spacing-large);
  }

  ${({ $active }) =>
    !$active &&
    css`
      :hover {
        > span > svg {
          transform: scale(1.2);
        }
        :after {
          width: 100%;
        }
      }
    `}
`;

export const CalendarCardButton = ({ connected, emailProvider, ...rest }: Props) => {
  return (
    <StyledCalendarCardButton {...rest} $active={connected}>
      <span>
        <HostCalendarIcon emailProvider={emailProvider} />
      </span>
      <Layout.Group justifyContent="space-between" style={{ flex: 1 }}>
        <Title variant="bold">
          {emailProvider === "google" ? " Connect Google Calendar" : "Connect Outlook Calendar"}
        </Title>
        {connected && <Check checked />}
      </Layout.Group>
    </StyledCalendarCardButton>
  );
};
