import { Button, ButtonProps, useToast } from "@introist/react-foundation/v2";

type CopyButtonProps = ButtonProps & {
  textToCopy: string;
  successMessage?: string;
};

export const CopyButton = ({
  textToCopy,
  successMessage,
  style,
  variant,
  children,
  ...rest
}: CopyButtonProps) => {
  const toast = useToast();

  const copyToClipboard = () => {
    try {
      const el = document.createElement("textarea");
      el.value = textToCopy;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      toast.success(successMessage || "Text copied!");
    } catch (e) {
      toast.error("Could not copy text");
    }
  };
  return (
    <Button onClick={copyToClipboard} size="small" variant={variant || "blended"} {...rest}>
      {children || "Copy"}
    </Button>
  );
};
