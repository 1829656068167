import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";
import {
  Title,
  useTheme,
  CloseIconButton,
  Button,
  Input,
  Icon,
  InputHandle
} from "@introist/react-foundation/v2";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from "react-beautiful-dnd";

import ReactDOM from "react-dom";
import styled from "styled-components";

type Props = {
  value: string[];
  onChange: (value: string[]) => void;
  error?: string;
};

const Root = styled.div<{ $error?: boolean }>`
  border-radius: var(--rounding-medium);
  padding: var(--spacing-large);
  background: ${p =>
    p.$error ? " var(--palette-danger-ghosted)" : "var(--palette-surface-subdued)"};
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-medium);
  padding: var(--spacing-xSmall) 0;

  .Title {
    background: var(--palette-background-default);
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: var(--rounding-small);
    border: 1px solid var(--palette-border-subdued);
    flex: 1;
  }
`;

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const OrderableOptionList = ({ value, onChange, error, ...rest }: Props) => {
  const { theme } = useTheme();

  const prevValueRef = useRef<string[]>();
  useEffect(() => {
    prevValueRef.current = value;
  });

  const isOptionNew = useCallback(
    (index: number) => {
      const prevValue = prevValueRef.current;
      return prevValue
        ? value.length !== prevValue.length && index === value.length - 1
        : index === 0;
    },
    [value]
  );

  const items = useMemo(() => {
    if (!value) return [];
    return value.map((val, index) => {
      const isNew = isOptionNew(index);
      return (
        <OptionItem
          value={val}
          isNew={isNew}
          onChange={newValue => {
            const newValues = [...value];
            newValues[index] = newValue;
            onChange(newValues);
          }}
          onRemove={() => onChange(value.filter(_ => _ !== val))}
        />
      );
    });
  }, [value, onChange, isOptionNew]);

  const onDrag = ({ source, destination }: any) => {
    if (!destination) return;
    onChange(reorder(value, source.index, destination.index));
  };

  return (
    <Root {...rest} $error={!!error}>
      <DragDropContext onDragEnd={onDrag}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <PortalDraggable child={item} provided={provided} snapshot={snapshot} />
                  )}
                </Draggable>
              ))}
              {items.length > 0 && provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ marginTop: items.length > 0 ? theme.spacing.large : undefined }}>
        <Button
          variant="outlined"
          startIcon="plus"
          size="small"
          onClick={() => onChange([...value, ""])}
        >
          Add option
        </Button>
      </div>
      {error && (
        <Title color={theme.palette.danger.default} style={{ marginTop: theme.spacing.large }}>
          {error}
        </Title>
      )}
    </Root>
  );
};

const OptionItem = ({
  value,
  isNew,
  onRemove,
  onChange
}: {
  value: string;
  isNew?: boolean;
  onRemove: () => void;
  onChange: (value: string) => void;
}) => {
  const inputRef = useRef<InputHandle>(null);

  useEffect(() => {
    // Only autofocus if isNew is true
    if (isNew && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isNew]);

  const { theme } = useTheme();
  return (
    <Item>
      <Icon subdued name="grab" />
      <div style={{ flex: 1 }}>
        <Input
          ref={inputRef}
          placeholder="New option"
          size="small"
          color={theme.palette.foreground.default}
          value={value}
          onChange={onChange}
        />
      </div>

      <CloseIconButton onClick={onRemove} />
    </Item>
  );
};

export const PortalDraggable = ({
  child,
  provided,
  snapshot
}: {
  child: ReactNode;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const usePortal: boolean = snapshot.isDragging;

  const elem = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      {child}
    </div>
  );

  if (!usePortal) {
    return elem;
  }

  // if dragging - put the item in a portal
  // this needs to be done so that items do not appear when this whole component is in Drawer (in portal)
  return ReactDOM.createPortal(elem, document.getElementById("root")!);
};
