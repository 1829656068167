import { api, RouterInput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { Workflow, WorkflowCreate, WorkflowUpdate } from "../index";

export type WorkflowParams = RouterInput["workflows"]["v4"]["list"];

export const useWorkflows = (params: WorkflowParams) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: workflows, refetch } = api.workflows.v4.list.useQuery(params, {
    refetchOnMount: "always"
  });

  const createOp = api.workflows.v4.create.useMutation();
  const updateOp = api.workflows.v4.update.useMutation();

  const create = useCallback(
    async (entry: Partial<Workflow>) => {
      return createOp.mutateAsync(entry as WorkflowCreate).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (data: WorkflowUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const archive = onConfirmAction(
    async id => {
      await updateOp.mutateAsync({ id, updates: { archived: true } });
      await refetch();
      toast.success("Workflow archived");
    },
    {
      icon: "archive",
      title: "Archive workflow",
      description: "Are you sure you want to archive this workflow?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  return {
    workflows,
    create,
    update,
    archive
  };
};

export const useWorkflow = (id: string, options?: any) => {
  const { data: workflow } = api.workflows.v4.find.useQuery({ id }, options);

  return {
    workflow
  };
};
