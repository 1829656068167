import { ActionMenu, Option } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { useWorkflowStep } from "../../hooks/useWorkflowSteps";
import { CopyStepModal } from "../../../workflows/components/CopyStepModal/CopyStepModal";
import FeatherIcon from "feather-icons-react";
import { api } from "../../../../services/rpc/RpcProvider";

type UseWorkflowStepActions = {
  isSaved: boolean;
  hasChanges: boolean;
  stepId?: string;
  onCloseStep: () => void;
};
export const useWorkflowStepActions = ({
  isSaved,
  hasChanges,
  stepId,
  onCloseStep
}: UseWorkflowStepActions) => {
  const [copyModalOpen, setCopyModalOpen] = useState(false);

  const { step, create, archive, activate, deactivate } = useWorkflowStep(stepId);

  const apiUtils = api.useContext();

  const actions = useMemo(() => {
    const actions: Option[] = [];

    if (step?.active) {
      actions.push({
        key: "Deactivate",
        startAdornmentIcon: "pause",
        onClick: deactivate,
        disabled: hasChanges
      });
    }
    if (step?.active === false) {
      actions.push({
        key: "Activate",
        startAdornmentIcon: "playCircle",
        onClick: activate,
        disabled: hasChanges
      });
    }

    if (isSaved && step) {
      actions.push({
        key: "Duplicate",
        startAdornment: <FeatherIcon icon="copy" size={16} />,
        onClick: async () => {
          await create(step).then(async () => {
            await apiUtils.workflows.v4.steps.list.refetch();
            onCloseStep();
          });
        },
        disabled: hasChanges
      });
      actions.push({
        key: "Copy to another workflow",
        startAdornment: <FeatherIcon icon="corner-up-right" size={16} />,
        onClick: () => {
          setCopyModalOpen(true);
        },
        disabled: hasChanges
      });
    }

    actions.push({
      key: "Archive",
      startAdornmentIcon: "archive",
      onClick: () =>
        archive(stepId!, async () => {
          await apiUtils.workflows.v4.steps.list.refetch();
          onCloseStep();
        }),
      disabled: hasChanges
    });

    return actions;
  }, [
    hasChanges,
    isSaved,
    apiUtils.workflows.v4.steps.list,
    onCloseStep,
    setCopyModalOpen,
    stepId,
    archive,
    step,
    create,
    activate,
    deactivate
  ]);

  if (actions.length === 0) return null;

  return (
    <>
      <ActionMenu options={actions} />
      {step && (
        <CopyStepModal
          key={stepId}
          open={copyModalOpen}
          stepIdToCopy={step!.id}
          onClose={() => setCopyModalOpen(false)}
        />
      )}
    </>
  );
};
