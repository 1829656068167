import { api } from "../../../../services/rpc/RpcProvider";
import { useMemo, useState } from "react";
import { StepOverview } from "./JourneyStepRow";
import { Icon, Input, Stack, Title } from "@introist/react-foundation/v2";
import moment, { Moment } from "moment";
import { StepList } from "./StepList";
import { TabBar } from "../../../../components/molecules";
import { partition } from "lodash";
import { pendingPreconditionCount } from "../../utils";
import { AutomationStepDrawer } from "../../containers/AutomationStepDrawer";

const filterSteps = (steps: StepOverview[], after?: Moment, before?: Moment) => {
  return steps.filter(step => {
    const scheduledAt = moment(step.scheduledAt);
    const isAfter = after ? scheduledAt.isAfter(after) : true;
    const isBefore = before ? scheduledAt.isBefore(before) : true;
    return isAfter && isBefore;
  });
};

export const UpcomingSteps = () => {
  const steps = api.journeys.steps.upcoming.useQuery({});

  const [tab, setTab] = useState("upcoming");
  const [search, setSearch] = useState("");

  const [openStep, setOpenStep] = useState<string | undefined>();

  const filteredSteps = useMemo(() => {
    if (!steps.data) return [] as StepOverview[];

    const [paused, upcoming] = partition(
      steps.data,
      step => pendingPreconditionCount(step as StepOverview) > 0
    );

    const failing = upcoming.filter(step => step.status === "failing");

    const tabSteps = tab === "upcoming" ? upcoming : tab === "failing" ? failing : paused;

    if (!search) return tabSteps as StepOverview[];

    return tabSteps.filter(step => {
      return (
        step.title.toLowerCase().includes(search.toLowerCase()) ||
        step.employee?.name.toLowerCase().includes(search.toLowerCase())
      );
    }) as StepOverview[];
  }, [steps.data, tab, search]);

  const thisWeek = useMemo(() => {
    return filterSteps(filteredSteps, undefined, moment().endOf("isoWeek"));
  }, [filteredSteps]);

  const nextWeek = useMemo(() => {
    return filterSteps(
      filteredSteps,
      moment().endOf("isoWeek"),
      moment().add(1, "week").endOf("isoWeek")
    );
  }, [filteredSteps]);

  const later = useMemo(() => {
    return filterSteps(filteredSteps, moment().add(1, "week").endOf("isoWeek"), undefined);
  }, [filteredSteps]);

  return (
    <Stack vertical>
      <Stack justifyContent="space-between">
        <TabBar
          tabs={[
            { id: "upcoming", title: "Upcoming" },
            { id: "failing", title: "Failing" },
            { id: "paused", title: "Paused" }
          ]}
          activeTab={tab}
          onTabChange={setTab}
        />
        <Stack>
          <Title small>{steps.data ? `${filteredSteps.length} steps` : ""}</Title>
          <Input
            size="small"
            placeholder="Search"
            endAdornment={<Icon name="search" subdued />}
            style={{ width: "250px" }}
            value={search}
            onChange={setSearch}
          />
        </Stack>
      </Stack>

      <StepList
        loading={!steps.data || steps.isLoading}
        sections={[
          { title: "This week", steps: thisWeek },
          { title: "Next week", steps: nextWeek },
          { title: "After next week", steps: later }
        ]}
        onClick={stepId => setOpenStep(stepId)}
      />
      <AutomationStepDrawer
        open={!!openStep}
        stepId={openStep}
        onClose={() => setOpenStep(undefined)}
        enableJourneyNavigate
      />
    </Stack>
  );
};
