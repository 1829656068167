import { useMemo } from "react";

import { AccountsRow } from "./AccountsRow";
import { ConnectedAccount } from "services/rpc/RpcProvider";
import { List } from "components/organisms";

interface Props {
  accounts: ConnectedAccount[];
}

export const AccountsList = ({ accounts }: Props) => {
  const withSubAccounts = useMemo(() => {
    const primaryAccounts = accounts.filter(account => !account.ownerId);

    return primaryAccounts.map(account => ({
      ...account,
      subAccounts: accounts.filter(subAccount => subAccount.ownerId === account.id)
    }));
  }, [accounts]);
  return (
    <List>
      {withSubAccounts.map((account, index) => (
        <AccountsRow key={`workspace-member-list-item-${index}`} account={account} />
      ))}
    </List>
  );
};
