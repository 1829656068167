import { SlackBot } from "./types";
import { Expression } from "@introist/introist-commons/dist";
import { JourneyStepV2, StepTypeV2, StepTypeWithEventTypeV2 } from "services/rpc/RpcProvider";

export type StepTriggerPeriod = "days" | "weeks" | "months";
export type StepTriggerSide = "before" | "after" | "on" | "immediately";

export type CalendarEventType = "individual" | "common";

export interface StepBase {
  id?: string;
  workflowId: string;
  trigger: StepTrigger;
  triggerReference?: string;
  stepType: StepTypeV2;
  title?: string;
  stepTitle?: string;
  attributesUsed: string[];
  version: number;
  condition?: Expression;
  conditionMode?: string;
  preconditionSteps?: string[];
  preconditionAttributes?: string[];
  attachments?: string[];
  active: boolean;
  allowedWeekdays: string[];
  fallbackDirection: string;
  deadline?: string;
}

export interface RelativeDay {
  amount: number;
  period: StepTriggerPeriod;
  side: StepTriggerSide;
}

export interface StepTrigger extends RelativeDay {
  reference?: "enroll" | "targetdate";
  time?: string;
  flexStart?: StepTrigger;
  flexEnd?: StepTrigger;
}

export interface EventParticipationAttendee {
  attendee: string;
  optional?: boolean;
}

export interface EventParticipation extends StepBase {
  stepData: {
    companyEventId: string;
    after: string;
    before: string;
    attendees: EventParticipationAttendee[];
  };
}

export type WorkflowStep =
  | EventStep
  | EmailStep
  | WebhookStep
  | WorkflowSlackStep
  | EnrollTriggerStep
  | EventParticipation;

export const getJourneyStepTypeWithEventType = (step: JourneyStepV2): StepTypeWithEventTypeV2 => {
  if (step.stepType === "event") {
    if (step.previewData.eventType === "common") return "common-event";
    else return "individual-event";
  }

  return step.stepType as StepTypeWithEventTypeV2;
};

export type IntroistDate = string;
export type IntroistTime = string;

export interface IntroistDateTime {
  date: IntroistDate;
  time: IntroistTime;
  timezone: string;
}

export interface EmailStep extends StepBase {
  stepData: {
    emailSenderId: string;
    subject: string;
    message: string;
    to?: string[];
    cc?: string[];
  };
}

export interface EventStep extends StepBase {
  stepData: {
    hostId: string;
    eventType: CalendarEventType;
    title: string;
    description: string;
    participants: string[];
    fromTime: IntroistTime;
    toTime: IntroistTime;
    private: boolean;
  };
}

export interface WebhookStep extends StepBase {
  stepData: {
    title: string;
    provider?: string;
    hookUrl?: string;
  };
}

interface EnrollStepData {
  workflowId: string;
  targetTrigger: StepTrigger;
}

export interface EnrollTriggerStep extends StepBase {
  stepData: EnrollStepData;
}

export type SlackMessageType = "channel" | "direct";
export type SlackSenderType = "bot" | "user";
export type SlackRecipientType = "user" | "channel";

export interface SlackRecipient {
  key: SlackRecipientType;
  value: string;
  resolvedValue?: string;
  resolvedName?: string;
  group?: string;
}

export interface SlackStepData {
  senderId: string;
  bot?: SlackBot;
  recipients?: SlackRecipient[];
  senderType: SlackSenderType;
  messageType?: SlackMessageType;
  message?: string;
  htmlMessage?: string;
  finalRecipients?: SlackRecipient[];
}

export interface WorkflowSlackStep extends StepBase {
  stepData: SlackStepData;
}
