import { useCallback } from "react";
import styled from "styled-components";
import { ActionMenu, Icon, Stack, Title, useTheme, useToast } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";
import { isEmpty, partition } from "lodash";

import { Check, StepTypeTag } from "components/atoms";

import { api, JourneyStepV2 } from "services/rpc/RpcProvider";
import { useJourneyStepActions } from "modules/journeys/hooks";
import { getJourneyStepTypeWithEventType } from "services/api/WorkflowApi";
import { List, ListRow } from "components/organisms";
import { getTitleForStepType } from "modules/workflows/components/steps";
import { RescheduleJourneyStep } from "modules/journeys/components/RescheduleJourneyStep";
import { StepStatusBadge } from "modules/journeys/components/StepStatusBadge";
import { Automation } from "../../automations";
import { TargetDateIndicator } from "../../automations/components/TargetDateIndicator";
import {
  DateFormats,
  formatIntroistDateTime,
  introistDateTimeToMoment
} from "../../../utils/dates";
import { Skeletons } from "../../../components/molecules";

type JourneyStepListProps = {
  automation: Automation;
  journeySteps?: JourneyStepV2[];
};

export const JourneyStepStatusIcon = ({ status }: { status: string }) => {
  const { theme } = useTheme();
  switch (status) {
    case "completed":
      return <Check checked size={1} />;
    case "canceled":
      return <Icon name="crossCircle" color={theme.palette.foreground.subdued} />;
    case "failing":
      return <Icon name="warning" color={theme.palette.danger.default} />;
    default:
      return <Check checked={false} size={1} />;
  }
};

const StepName = styled(Title)<{ $strokeThrough: boolean }>`
  position: relative;
  text-decoration: ${({ $strokeThrough }) => ($strokeThrough ? "line-through" : "none")};
`;

const StepRow = ({ step, onClick }: { step: JourneyStepV2; onClick: (stepId: string) => void }) => {
  const toast = useToast();
  const apiUtils = api.useContext();

  const { refetch: reloadStep } = api.journeys.steps.find.useQuery({
    stepId: step.id
  });

  const { actions, rescheduleOpen, setRescheduleOpen } = useJourneyStepActions(step);

  const updateAll = useCallback(async () => {
    await reloadStep();
    await apiUtils.journeys.steps.list.refetch({ journeyId: step.journeyId });
    await apiUtils.automations.find.refetch({ id: step.journeyId });
  }, [reloadStep, apiUtils, step.journeyId]);

  return (
    <ListRow gridTemplateColumns="auto auto 150px 20px" onClick={() => onClick(step.id)}>
      <Stack>
        <JourneyStepStatusIcon status={step.status} />
        <StepTypeTag compact stepType={getJourneyStepTypeWithEventType(step)} />
        <StepName variant="bold" $strokeThrough={step.status === "canceled"}>
          {step.title ?? getTitleForStepType(getJourneyStepTypeWithEventType(step))}
        </StepName>
      </Stack>
      <Stack justifyContent="flex-end" style={{ paddingRight: 16 }}>
        <StepStatusBadge
          status={step.status}
          scheduledAt={introistDateTimeToMoment(step.start).toISOString()}
          stepType={step.stepType}
          pendingPreconditions={step.pendingPreconditions}
        />
      </Stack>
      <Title small>{formatIntroistDateTime(step.start, DateFormats.datetime.withMonth.long)}</Title>
      <div onClick={event => event.stopPropagation()}>
        {!isEmpty(actions) && step.status !== "canceled" ? (
          <>
            <ActionMenu options={actions} />
            <RescheduleJourneyStep
              allowCreate={!step.journeyData?.doNotCreate}
              open={rescheduleOpen}
              onClose={() => setRescheduleOpen(false)}
              step={step}
              onRescheduled={async () => {
                await updateAll();
                setRescheduleOpen(false);
                toast.success("Step rescheduled!");
              }}
            />
          </>
        ) : (
          <div />
        )}
      </div>
    </ListRow>
  );
};

const TargetDateRow = styled(ListRow)`
  background-color: var(--palette-surface-dimmed);
  padding-right: 1.75rem;
`;

export const JourneyStepTable = ({ automation, journeySteps, ...rest }: JourneyStepListProps) => {
  const navigate = useNavigate();

  const [beforeTarget, afterTarget] = partition(
    journeySteps,
    step => new Date(step.start.date) < new Date(automation.targetDate)
  );

  const onRowClick = (stepId: string) => {
    navigate(`steps/${stepId}`, { relative: "path", state: { from: "journey" } });
  };

  if (!journeySteps) return <Skeletons.List />;

  return (
    <List {...rest}>
      {beforeTarget.map(step => (
        <StepRow key={step.id} step={step} onClick={onRowClick} />
      ))}

      <TargetDateRow gridTemplateColumns="1fr auto">
        <TargetDateIndicator targetDate={automation.targetDate} />
      </TargetDateRow>

      {afterTarget.map(step => (
        <StepRow key={step.id} step={step} onClick={onRowClick} />
      ))}
    </List>
  );
};
