import { useEmailSenderApi } from "../../../../services/api/Senders";
import { useMemo } from "react";
import { Icon, Select } from "@introist/react-foundation/v2";

export const MeetingHostSelect = ({
  value,
  onChange
}: {
  value?: string;
  onChange: (value: string) => void;
}) => {
  const emailSenderApi = useEmailSenderApi();
  const { data: hosts } = emailSenderApi.list({ filterEmailOnlySenders: true }, {}).query;

  const hostOptions = useMemo(() => {
    return (hosts ?? []).map(host => ({
      key: host.id,
      title: host.name,
      description: host.email
    }));
  }, [hosts]);

  return (
    <Select
      element="button"
      variant="blended"
      startAdornment={<Icon name="person" />}
      placeholder="Select a host"
      options={hostOptions}
      value={value}
      onSelect={opt => onChange(opt.key)}
      attachToRef={false}
      searchable
    />
  );
};
