import { Field, Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

type OracleHcmConfig = {
  domain: string;
  username: string;
  password: string;
};

export const OracleHcmConnectForm = ({ values, onChange }: ConnectFormProps) => {
  const credentials = (values?.credentials as OracleHcmConfig) ?? {
    domain: "",
    username: "",
    apiKey: ""
  };

  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
      <Field title="Domain">
        <TextInput
          placeholder="Domain"
          value={credentials.domain}
          onChange={domain =>
            onChange({
              credentials: { ...credentials, domain }
            })
          }
        />
      </Field>
      <Field title="User">
        <TextInput
          placeholder="Username"
          value={credentials.username}
          onChange={username =>
            onChange({
              credentials: { ...credentials, username }
            })
          }
        />
      </Field>
      <Field title="Password">
        <TextInput
          placeholder="Password"
          value={credentials.password}
          type="password"
          onChange={password =>
            onChange({
              credentials: { ...credentials, password }
            })
          }
        />
      </Field>
    </Layout.Group>
  );
};
