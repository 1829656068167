import styled from "styled-components";
import {
  Card,
  Field,
  Icon,
  Input,
  Layout,
  Option,
  Select,
  Title
} from "@introist/react-foundation/v2";
import { capitalize } from "lodash";

import { TextInput } from "components/atoms";
import { OrderableOptionList } from "components/organisms";
import { EmployeeFieldType } from "services/rpc/RpcProvider";

export const FieldNameInput = ({
  onChange,
  isGroup,
  value,
  ...rest
}: {
  isGroup?: boolean;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <TextInput
      {...rest}
      size="small"
      label={isGroup ? "Group name" : "Field name"}
      value={value}
      onChange={onChange}
    />
  );
};

const CUSTOM_FIELD_TYPE_OPTIONS: Option[] = [
  {
    key: "text",
    title: "Text",
    startAdornment: (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 5.5V3.5C3 3.22386 3.22386 3 3.5 3L12.5 3C12.7761 3 13 3.22386 13 3.5V5.5"
          stroke="#80898D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 3L8 13"
          stroke="#80898D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 13H9.5"
          stroke="#80898D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    key: "date",
    title: "Date",
    startAdornment: <Icon subdued name="calendar" />
  },
  {
    key: "email",
    title: "Email",
    startAdornment: <Icon subdued name="email" />
  },
  {
    key: "select",
    title: "Select",
    startAdornment: <Icon subdued name="form" />
  }
];

const InputWrapper = styled.div`
  max-width: 50%;

  > div {
    border-color: var(--palette-border-subdued) !important;
  }
`;

export const FieldTypeSelect = ({
  value,
  onSelect
}: {
  value?: EmployeeFieldType;
  onSelect?: (type: EmployeeFieldType) => void;
}) => {
  if (!onSelect) {
    return (
      <Layout.Group vertical>
        <Field title="Field type">
          <InputWrapper>
            <Input
              readOnly
              size="small"
              value={capitalize(value)}
              startAdornment={CUSTOM_FIELD_TYPE_OPTIONS.find(o => o.key === value)?.startAdornment}
              endAdornment={<Icon dimmed name="lock" />}
            />
          </InputWrapper>
        </Field>
        <Title>Once created, the field type cannot be changed</Title>
      </Layout.Group>
    );
  }

  return (
    <Select
      sameWidthWithReference
      size="small"
      value={value}
      showAdornment
      placeholder="Select field type"
      options={CUSTOM_FIELD_TYPE_OPTIONS}
      onSelect={({ key }) => onSelect(key as EmployeeFieldType)}
    />
  );
};

const SelectFields = styled(Card)`
  display: grid;
  grid-row-gap: var(--spacing-large);
  background-color: var(--palette-surface-subdued);
  border: none;
`;

const StyledOrderableOptionList = styled(OrderableOptionList)`
  padding: 0;
`;

export const SelectFieldOptions = ({
  options,
  onChange
}: {
  options: string[];
  onChange: (options: string[]) => void;
}) => {
  return (
    <SelectFields>
      <Title>Selectable options</Title>
      <StyledOrderableOptionList value={options} onChange={onChange} />
    </SelectFields>
  );
};
