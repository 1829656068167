import { useEmployeeAttributeApi } from "../modules/employees/api/EmployeeAttributeApi";

export const useAttributes = (includeHidden = false, attributeTypes?: string[]) => {
  const attributeApi = useEmployeeAttributeApi();
  const { data: attributes } = attributeApi.list({ includeHidden }).query;

  return (attributes || []).filter(attribute => {
    if (!attributeTypes) return true;
    return attributeTypes.includes(attribute.type);
  });
};
