import { ExtendedJourneyStepAlertRecord } from "../../../issues/api/AlertApi";

import { Layout, Title, useTheme } from "@introist/react-foundation/v2";
import { IssueIndicator } from "../../components/IssueIndicator";
import { IssueItem } from "../../../issues/components/IssueItem";

type IssueListProps = {
  issues: ExtendedJourneyStepAlertRecord[];
  limit?: number;
  indicateNoIssues?: boolean;
};

export const IssuesList = ({ issues, limit, indicateNoIssues }: IssueListProps) => {
  const { theme } = useTheme();

  const limited = issues.slice(0, limit);

  return (
    <Layout.Group vertical gap="small">
      {limited.map(i => (
        <IssueItem issue={i} key={`issue-list-item-${i.id}`} />
      ))}
      {limit && issues.length > limit && (
        <Title color={theme.palette.danger.default}>+{issues.length - limit} more</Title>
      )}
      {issues.length === 0 && indicateNoIssues && (
        <IssueIndicator issues={issues} indicateNoIssues />
      )}
    </Layout.Group>
  );
};
