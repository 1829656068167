import { PrimitiveFieldValue } from "@introist/introist-commons/dist";
import { Card, IconButton, Stack, Tag, Title } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

interface RuleCardProps {
  active?: boolean;
  title: string;
  comparatorTitle: string;
  value: PrimitiveFieldValue[];
  selectCondition?: () => void;
  deleteCondition?: () => void;
}

const DeleteIconButton = styled(IconButton)`
  opacity: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    opacity: 0.5;
  }

  :hover svg {
    opacity: 1;
  }
`;

const EditIconButton = styled(DeleteIconButton)`
  right: 3rem;
`;

const StyledConditionCard = styled(Card)<{ $active?: boolean }>`
  padding: var(--spacing-large);
  position: relative;

  ${({ $active }) =>
    $active &&
    css`
      border-color: var(--palette-primary-default);
    `}

  :hover {
    ${DeleteIconButton} {
      opacity: 1;
    }
  }
`;

export const RuleCard = ({
  title,
  comparatorTitle,
  value,
  active,
  selectCondition,
  deleteCondition
}: RuleCardProps) => {
  const multiRow = value.length > 1 || title.length + comparatorTitle.length > 30;
  return (
    <StyledConditionCard $active={active} onClick={selectCondition}>
      <Stack vertical={multiRow} gap="small">
        <Title variant="bold">
          {title} {comparatorTitle}
          {multiRow && value.length > 0 && "..."}
        </Title>
        {value.length > 0 && (
          <Stack gap="small">
            {value.map((v, idx) => (
              <Tag key={`condition-value-tag--${idx}`}>{v}</Tag>
            ))}
          </Stack>
        )}
      </Stack>
      {selectCondition && <EditIconButton icon="pencil" />}
      {deleteCondition && (
        <DeleteIconButton
          icon="trash"
          onClick={e => {
            e.stopPropagation();
            deleteCondition();
          }}
        />
      )}
    </StyledConditionCard>
  );
};
