import { InfoCard, useTheme } from "@introist/react-foundation/v2";

type InstructionsProps = {
  title: string;
  url: string;
};

export const Instructions = ({ url, title }: InstructionsProps) => {
  const { theme } = useTheme();

  return (
    <InfoCard
      title={title}
      colorVariant="primary"
      onClick={() => window.open(url, "_blank")}
      style={{ textDecoration: "underline", color: theme.palette.primary.default }}
    />
  );
};
