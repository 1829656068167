import { InformationCard } from "./InformationCard";
import { GlobalView } from "./ViewFrame";

export const LoadingForm = () => {
  return (
    <GlobalView>
      <InformationCard
        iconName="checkCircle"
        title="We're loading your form!"
        description="Please hold tight for a moment while we get everything ready for you."
        loader
      />
    </GlobalView>
  );
};

export const FormSubmitted = () => {
  return (
    <GlobalView>
      <InformationCard
        iconName="checkCircle"
        title={`Thank you for your submission!`}
        description="Your form has been successfully submitted. You can now close the tab."
      />
    </GlobalView>
  );
};

export const AlreadySubmitted = () => {
  return (
    <GlobalView>
      <InformationCard
        iconName="info"
        title={`This form has already been submitted.`}
        description="It looks like this form has been completed and can’t be filled out again. "
      />
    </GlobalView>
  );
};

export const ErrorForm = () => {
  return (
    <GlobalView>
      <InformationCard
        state="error"
        iconName="warning"
        title="Oops! We couldn't load the form."
        description="The form you're trying to access might have been moved or is no longer available."
      />
    </GlobalView>
  );
};
