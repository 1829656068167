import { IconName, Tag, Icon, useTheme, TTagColorVariant } from "@introist/react-foundation/v2";
import moment from "moment";
import { ComponentProps } from "react";

import { useStepType } from "../../flows/stepTypes";

interface StepStatusBadgeProps extends Omit<ComponentProps<"div">, "color"> {
  status: string;
  stepType: string;
  scheduledAt: string;
  pendingPreconditions?: string[];
}

const statusDescriptions: { [index: string]: string } = {
  draft: "Draft",
  scheduled: "Scheduled",
  delivered: "Pending",
  completed: "Completed",
  failing: "Failing",
  canceled: "Canceled"
};

export const StepStatusBadge = ({
  status,
  scheduledAt,
  stepType,
  pendingPreconditions,
  ...rest
}: StepStatusBadgeProps) => {
  const { theme } = useTheme();

  let icon: IconName | undefined = undefined;
  let message = "";
  let color: string | undefined = undefined;
  let colorVariant: TTagColorVariant | undefined = undefined;

  const stepTypeData = useStepType(stepType);
  message = (stepTypeData.statusDescription ?? {})[status] ?? statusDescriptions[status];

  if (status === "scheduled") {
    color = theme.palette.primary.default;
    colorVariant = "primary";
    if (scheduledAt) {
      const sendingTime = moment().isAfter(scheduledAt) ? "now" : moment().to(scheduledAt);
      message = message + ` ${sendingTime}`;
    }
  } else if (status === "delivered") {
    color = theme.palette.purple.default;
    colorVariant = "primary";
  } else if (status === "completed") {
    color = theme.palette.success.default;
    colorVariant = "success";
  } else if (status === "failing") {
    color = theme.palette.danger.default;
    colorVariant = "danger";
  } else if (status === "canceled") {
    color = theme.palette.foreground.subdued;
    colorVariant = "surface";
  } else if (status === "draft") {
    color = theme.palette.foreground.subdued;
    colorVariant = "outlined";
  }

  if (pendingPreconditions && pendingPreconditions.length > 0) {
    color = theme.palette.warning.default;
    colorVariant = "primary";
    message = "Paused";
  }

  return (
    <Tag
      startAdornment={icon ? <Icon name={icon} color={color} /> : undefined}
      colorVariant={colorVariant}
      {...rest}
    >
      {message}
    </Tag>
  );
};
