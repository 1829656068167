import { ListView, TopBarRoute } from "../../../components/templates";
import { useNavigate, useParams } from "react-router";
import { api } from "../../../services/rpc/RpcProvider";
import {
  ActionMenu,
  Card,
  Field,
  Input,
  LabelledCheckbox,
  Select,
  Stack,
  Title
} from "@introist/react-foundation/v2";

import { DragHandle, UpsertFormProps, UpsertModal, useSearch } from "foundation";
import { useState } from "react";
import { omit } from "lodash";
import { KeyInput, simplifyKeyValue } from "../components/KeyInput";
import { DatasetField } from "../index";
import { useDatasetFields } from "../hooks/useDatasetFields";
import { ArchivedFieldsModal } from "../containers/ArchivedFieldsModal";

export const DatasetRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: dataset } = api.datasets.defs.find.useQuery({ id });

  const { fields, create, update, archive, reorder } = useDatasetFields(id!);

  const { filtered, search, setSearch } = useSearch(fields, { fields: ["title", "key"] });

  const [editOpen, setEditOpen] = useState(false);
  const [editEntry, setEditEntry] = useState<DatasetField | undefined>();

  const [archivedOpen, setArchivedOpen] = useState(false);

  return (
    <TopBarRoute
      paths={[
        { key: "datasets", title: "Datasets", onClick: () => navigate("/datasets") },
        { key: "dataset", title: dataset?.plural || "" }
      ]}
    >
      <Card style={{ maxWidth: "750px", width: "750px", margin: "0 auto" }}>
        <Stack vertical gap="xLarge" style={{ flex: 1 }}>
          <ListView.Header
            title="Dataset Fields"
            iconName="attributeFilled"
            count={filtered?.length}
            onCreate={() => {
              setEditOpen(true);
              setEditEntry(undefined);
            }}
            actions={[
              {
                key: "showarchived",
                title: "Archived fields",
                onClick: () => setArchivedOpen(true)
              }
            ]}
            search={{
              search,
              setSearch
            }}
          />
          <ListView.OrderableList
            data={filtered}
            //@ts-ignore
            onReorder={!search ? reorder : undefined}
            renderRow={(f: DatasetField) => (
              <Card>
                <Stack>
                  <DragHandle />
                  <Stack>
                    <Title variant="bold">{f.title}</Title>
                    <Title>{f.key}</Title>
                  </Stack>
                  <div
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    <ActionMenu
                      options={[
                        {
                          key: "edit",
                          title: "Edit",
                          startAdornmentIcon: "pencil",
                          onClick: () => {
                            setEditOpen(true);
                            setEditEntry(f);
                          }
                        },
                        {
                          key: "archive",
                          title: "Archive",
                          startAdornmentIcon: "trash",
                          onClick: () => archive(f.id)
                        }
                      ]}
                    />
                  </div>
                </Stack>
              </Card>
            )}
          />
        </Stack>
      </Card>

      <UpsertModal
        open={editOpen}
        entry={editEntry}
        onCreate={async entry => {
          await create(entry);
        }}
        onUpdate={entry => {
          return update({
            id: entry.id,
            updates: omit(entry, "id", "workspaceId", "datasetId", "key")
          });
        }}
        onClose={() => {
          setEditOpen(false);
          setEditEntry(undefined);
        }}
        Form={FieldForm}
        entryName="field"
      />
      <ArchivedFieldsModal
        open={archivedOpen}
        onClose={() => setArchivedOpen(false)}
        datasetId={id!}
      />
    </TopBarRoute>
  );
};

const FieldForm = ({ value, onChange, isEdit }: UpsertFormProps<DatasetField>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input
          value={value.title}
          onChange={title => onChange({ ...value, title })}
          onBlur={() => {
            onChange({ ...value, key: simplifyKeyValue(value.title) });
          }}
          autoFocus
        />
      </Field>
      <Field title="Key">
        <KeyInput
          size="small"
          value={value.key}
          onChange={key => onChange({ ...value, key })}
          disabled={isEdit}
        />
      </Field>
      <Field title="Type">
        <Select
          size="small"
          value={value.type}
          onSelect={opt => onChange({ ...value, type: opt.key })}
          options={[
            { key: "text", title: "Text" },
            { key: "email", title: "Email" },
            { key: "number", title: "Number" },
            { key: "date", title: "Date" },
            { key: "datetime", title: "Datetime" }
          ]}
          disabled={isEdit}
        />
      </Field>
      <LabelledCheckbox
        label="Is required"
        checked={value.isRequired}
        onChange={isRequired => onChange({ ...value, isRequired })}
      />
      <LabelledCheckbox
        label="Is unique"
        checked={value.isUnique}
        onChange={isUnique => onChange({ ...value, isUnique })}
      />
    </Stack>
  );
};
