import { Modal, useToast, Stack, Title, H4 } from "@introist/react-foundation/v2";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getDataSourceSpec } from "../datasources";
import { api, DataSourceTestResult, EmployeeDataSource } from "../../../services/rpc/RpcProvider";
import { FormFooter } from "../components/FormFooter";

type SyncPreviewModalProps = {
  open: boolean;
  onClose: () => void;
  dataSource?: EmployeeDataSource;
};

export const SyncPreviewModal = ({ open, onClose, dataSource }: SyncPreviewModalProps) => {
  const toast = useToast();

  const [preview, setPreview] = useState<DataSourceTestResult | undefined>();

  const doPreview = api.employees.dataSources.preview.useMutation();
  const commit = api.employees.dataSources.commit.useMutation();

  useEffect(() => {
    setPreview(undefined);
  }, [open]);

  const DsPreviewBuilder = useMemo(() => {
    if (!dataSource) return null;
    return getDataSourceSpec(dataSource.sourceType).PreviewBuilder;
  }, [dataSource]);

  const onPreview = useCallback(
    async (data?: any) => {
      await doPreview
        .mutateAsync({ dataSourceId: dataSource!.id, data })
        .then(preview => setPreview(preview))
        .catch(() => {
          toast.error("Failed to generate preview");
        });
    },
    [dataSource, doPreview, toast]
  );

  const onCommit = useCallback(async () => {
    await commit
      .mutateAsync({ dataSourceId: dataSource!.id, data: preview!.matching })
      .then(() => {
        toast.success("Sync completed!");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to sync all employees");
      });
  }, [commit, preview, onClose, toast, dataSource]);

  return (
    <Modal title="Preview Data Source sync" open={open} onClose={onClose}>
      {!!DsPreviewBuilder && !preview && (
        <DsPreviewBuilder sourceId={dataSource!.id} onPreview={onPreview} onCancel={onClose} />
      )}
      {preview && (
        <>
          <SyncPreview preview={preview} />
          <FormFooter onCancel={onClose} onSubmit={onCommit} submit="Sync" />
        </>
      )}
    </Modal>
  );
};

export const SyncPreview = ({ preview }: { preview: DataSourceTestResult }) => {
  return (
    <Stack vertical>
      <Stack>
        <H4>{preview.matching.length - preview.matched}</H4>
        <Title>new employees</Title>
      </Stack>
      <Stack>
        <H4>{preview.matched}</H4>
        <Title>employees to update</Title>
      </Stack>
    </Stack>
  );
};
