import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";

import { CrossLineProps } from "../../CrossLine";
import { useCrossLineSprings } from "../../hooks/useCrossLineSprings";
import { StyledCrossLine } from "../..";
import { Icon, Layout } from "@introist/react-foundation/v2";
import { AnimatedCrossIcon } from "../../../AnimatedCrossLine";

type THorizontalCrossIconFrom = "left" | "right" | "center";

type HorizontalCrossLineProps = CrossLineProps & {
  from?: THorizontalCrossIconFrom;
};

const AnimatedHorizonalLine = styled(animated(Layout.Divider))<{
  $from: THorizontalCrossIconFrom;
}>`
  ${({ $from }) => {
    switch ($from) {
      case "left":
        return css`
          transform-origin: left;
        `;
      case "right":
        return css`
          transform-origin: right;
        `;
      case "center":
      default:
        return css`
          transform-origin: center;
        `;
    }
  }}
`;

export const HorizontalCrossLine = ({
  length,
  from = "left",
  animationDelayMs = 0,
  thickness = 1,
  crossBackgroundColor,
  ...rest
}: HorizontalCrossLineProps) => {
  const { horizontalLineSpring, crossSpring } = useCrossLineSprings(animationDelayMs);

  return (
    <StyledCrossLine {...rest} style={{ width: `${length}rem` }}>
      <AnimatedCrossIcon $backgroundColor={crossBackgroundColor} style={crossSpring}>
        <Icon danger name="crossSmall" />
      </AnimatedCrossIcon>
      <AnimatedHorizonalLine style={{ ...horizontalLineSpring, height: thickness }} $from={from} />
    </StyledCrossLine>
  );
};
