import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import {
  Button,
  DataForm,
  Icon,
  Select,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { prefixForm } from "../../../../utils/FormUtils";
import { SelectionCard } from "./SelectionCard";
import { useMemo } from "react";
import { Avatar } from "../../../../components/molecules";
import { api } from "../../../../services/rpc/RpcProvider";
import { FittedTitle } from "../../../../components/atoms";

type MeetingLocationEditorProps = {
  form: DataForm<any>;
  formPrefix?: string;
};

export const MeetingLocationEditor = ({ form, formPrefix }: MeetingLocationEditorProps) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, formPrefix);

  const { data: rooms } = api.workspaces.rooms.list.useQuery({});

  const meetingRooms = useMemo(() => {
    const value = prefixedForm.get("meetingRooms");
    return Array.isArray(value) ? value : [];
  }, [prefixedForm]);

  return (
    <StepEditorAccordion title="Location" defaultOpen={meetingRooms.length > 0}>
      <StepEditorAccordion.MultiOptionRow label="Meeting room">
        <Stack vertical gap="medium">
          {rooms && rooms.length === 0 && (
            <div>
              <Button variant="blended" onClick={() => window.open("/settings/rooms", "_blank")}>
                <Title color={theme.palette.primary.default}>Set up Meeting rooms</Title>
              </Button>
            </div>
          )}
          {rooms && rooms.length > 0 && (
            <Select
              element="button"
              variant="blended"
              options={[
                { key: "none", title: "Do not book a room" },
                { key: "book", title: "Book meeting room(s)" },
                { key: "select", title: "Pick available room" }
              ]}
              value={prefixedForm.get("meetingRoomType")}
              onSelect={opt => {
                if (opt.key === "none") {
                  prefixedForm.setMany({
                    meetingRoomType: opt.key,
                    meetingRooms: []
                  });
                } else prefixedForm.set("meetingRoomType")(opt.key);
              }}
              attachToRef={false}
            />
          )}

          {prefixedForm.get("meetingRoomType") !== "none" && (
            <Stack vertical gap="xSmall">
              {(meetingRooms ?? []).length === 0 && (
                <Title style={{ marginLeft: "18px" }}>No rooms selected</Title>
              )}
              {(meetingRooms ?? []).map(roomEmail => {
                const room = rooms?.find(r => r.email === roomEmail);

                return (
                  <SelectionCard
                    content={
                      <Stack>
                        <Avatar nameOrEmail={room?.title ?? roomEmail} />
                        <FittedTitle maxLength={30} variant="bold" showTooltip>
                          {room?.title ?? roomEmail}
                        </FittedTitle>
                      </Stack>
                    }
                    onRemove={() => {
                      prefixedForm.set("meetingRooms")(meetingRooms.filter(r => r !== roomEmail));
                    }}
                  />
                );
              })}
            </Stack>
          )}
          {prefixedForm.get("meetingRoomType") !== "none" && (
            <Select
              element="button"
              variant="blended"
              placeholder="Add room"
              startAdornment={<Icon name="plus" />}
              options={(rooms ?? [])
                .filter(r => !meetingRooms.includes(r.email))
                .map(r => ({ key: r.email, title: r.title }))}
              onSelect={opt => prefixedForm.set("meetingRooms")([...meetingRooms, opt.key])}
              attachToRef={false}
            />
          )}
        </Stack>
      </StepEditorAccordion.MultiOptionRow>
    </StepEditorAccordion>
  );
};
