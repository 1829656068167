import { Icon } from "@introist/react-foundation/v2";
import { BatchesListRoute } from "./routes/BatchesListRoute";
import { BatchRoute } from "./routes/batch/BatchRoute";

export const BATCHES_ROUTES = {
  batches: {
    key: "route-batches",
    title: "Batches",
    icon: <Icon name="duplicate" />,
    path: "/batches",
    element: <BatchesListRoute />
  },
  batch: {
    key: "route-batch",
    title: "Batch",
    path: "/batches/:id",
    element: <BatchRoute />
  }
};
