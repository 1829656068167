import sanitizeHTML from "sanitize-html";

export const stringifyStepDate = (
  side: any,
  reference: any,
  amount: any,
  period: any,
  time: any,
  includeSuffix = true,
  includeTime = false,
  customSuffix?: string
) => {
  if (side === "immediately") return "when enrolled";
  if (side === "on") return "on " + customSuffix ?? "target date";

  const suffix = reference === "enroll" ? "enroll" : customSuffix ?? "target date";

  const triggerperiod = amount === 1 ? period.slice(0, -1) : period;

  return `${amount} ${triggerperiod} ${side} ${includeSuffix ? ` ${suffix}` : ""} ${
    includeTime ? ` at ${time}` : ""
  }`;
};

export const stringifyTrigger = (
  trigger: any,
  includeSuffix = true,
  includeTime = false,
  customSuffix?: string
) => {
  return stringifyStepDate(
    trigger.side,
    trigger.reference,
    trigger.amount,
    trigger.period,
    trigger.time,
    includeSuffix,
    includeTime,
    customSuffix
  );
};

export const sanitize = (text: string) => sanitizeHTML(text);

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
