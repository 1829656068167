import { api } from "../../../services/rpc/RpcProvider";

export const useStepOptions = (stepType: string, params?: { [k: string]: string }) => {
  const { data: options } = api.workflows.v4.steps.options.useQuery({
    stepType,
    params
  });

  return { options };
};
