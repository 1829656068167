import { Drawer, Icon, Stack, Title, Tooltip, useTheme } from "@introist/react-foundation/v2";
import { ListView, useSearch } from "../../../foundation";
import { ListRow } from "../../../components/organisms";
import { formatDateTime } from "../../../utils/DatesUtils";
import { FormResponse, useFormResponses } from "../useFormResponses";
import { DateFormats } from "../../../utils/dates";
import { FormResponseViewer } from "./FormResponseViewer";
import { useNavigate, useParams } from "react-router";
import { SelectorIcon } from "../../employees/fields/EmployeeFieldSelector";
import { IconWithCount } from "../../../foundation/blocks/IconWithCount";

export const FormResponses = ({ formId }: { formId: string }) => {
  const navigate = useNavigate();
  const { id, responseId } = useParams();
  const { responses } = useFormResponses(formId);

  const { filtered, search, setSearch } = useSearch(responses, {
    fields: []
  });

  return (
    <Stack vertical>
      <ListView.Header title="Responses" count={filtered?.length} search={{ search, setSearch }} />
      <ListView.List
        data={filtered}
        empty={{
          icon: !search ? "form" : undefined,
          title: search ? "No responses" : "This Form has no responses yet",
          message: search
            ? "Try a different search"
            : "Create Fields and share the Form to start collecting responses."
        }}
        renderRow={response => (
          <ListRow
            key={response.id}
            gridTemplateColumns="120px 1fr auto"
            onClick={() => navigate(`responses/${response.id}`)}
          >
            <Title>
              {formatDateTime(response.submittedAt!, DateFormats.datetime.withDay.short)}
            </Title>
            <Stack>
              {response.employeeId ? <SelectorIcon icon="person" /> : <div />}
              <Title variant="bold">{response.employee}</Title>
            </Stack>
            <Stack>
              {response.transformResult && (
                <TransformResultOverview result={response.transformResult} />
              )}
            </Stack>
          </ListRow>
        )}
      />
      <Drawer width="500px" open={!!responseId} onClose={() => navigate(`/forms/${id}`)}>
        {!!responseId && (
          <FormResponseViewer responseId={responseId} onClose={() => navigate(`/forms/${id}`)} />
        )}
      </Drawer>
    </Stack>
  );
};

const TransformResultOverview = ({ result }: { result: FormResponse["transformResult"] }) => {
  const { theme } = useTheme();

  if (result!.success) {
    const fieldCount = result!.transformLog.filter(f => f.targetValue).length;

    return (
      <Tooltip tooltip={`Updated ${fieldCount} fields to Employee`}>
        <IconWithCount
          iconName="arrowsLeftRight"
          count={fieldCount}
          color={theme.palette.success.default}
        />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip tooltip={`Failed to update fields to Employee`}>
        <Icon name="warning" color={theme.palette.danger.default} />
      </Tooltip>
    );
  }
};
