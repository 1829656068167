import { useState } from "react";
import { Stack } from "@introist/react-foundation/v2";

import { TopBarRoute } from "components/templates";
import { TabBar } from "components/molecules";
import { EmployeesTable } from "./EmployeesTable";
import { useNavigate } from "react-router";
import { NewEmployeeDrawer } from "../../containers/EmployeeProfile";
import { EmployeeGroups } from "./EmployeeGroups";

export interface EmployeesProps {
  section?: string;
}

export const EmployeesRoute = ({ section }: EmployeesProps) => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(section ?? "employees");

  return (
    <TopBarRoute paths={[{ key: "employees", title: "Employees" }]}>
      <Stack vertical gap="xLarge">
        <Stack>
          <TabBar
            tabs={[
              {
                id: "employees",
                title: "Employees",
                iconName: "person"
              },
              {
                id: "groups",
                title: "Groups",
                iconName: "persons"
              }
            ]}
            activeTab={tab}
            onTabChange={tab => setTab(tab)}
          />
        </Stack>
        {tab === "employees" && (
          <>
            <EmployeesTable />
            <NewEmployeeDrawer
              open={section === "new"}
              onClose={() => navigate("/employees", { replace: true })}
            />
          </>
        )}
        {tab === "groups" && <EmployeeGroups />}
      </Stack>
    </TopBarRoute>
  );
};
