import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, IconButton, Stack, Title } from "@introist/react-foundation/v2";

export const SlackFormnMessageViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Form">
          <StepEditorAccordion.Row label="Form">
            <Stack>
              <Title variant="bold">{resolvedData.resolvedFormTitle}</Title>
              <IconButton
                size="small"
                variant="blended"
                icon="moveTo"
                onClick={() => window.open(`/forms/${resolvedData.form}`, "_blank")}
              ></IconButton>
            </Stack>
          </StepEditorAccordion.Row>
          {resolvedData.resolvedLinkedEmployee && (
            <StepEditorAccordion.Row label="Employee">
              <Title variant="bold">{resolvedData.resolvedLinkedEmployee}</Title>
            </StepEditorAccordion.Row>
          )}
          <StepEditorAccordion.Row label="Submission">
            {(step.executionData as any)?.submitted ? (
              <Stack>
                <Stack gap="xSmall">
                  <Icon name="check"></Icon>
                  <Title variant="bold">Completed</Title>
                </Stack>

                <IconButton
                  size="small"
                  variant="blended"
                  icon="moveTo"
                  onClick={() =>
                    window.open(
                      `/forms/${resolvedData.form}/responses/${resolvedData.resolvedFormResponseId}`,
                      "_blank"
                    )
                  }
                ></IconButton>
              </Stack>
            ) : (
              <Title>No submission</Title>
            )}
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Sender & Recipients">
          <StepEditorAccordion.Row label="From">
            <Stack>
              {resolvedData.isBot ? <Icon name="robot" /> : <Icon name="person" />}
              <Title variant="bold">{resolvedData.resolvedSenderName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="To">
            <Stack vertical>
              {resolvedData.targetType === "users" &&
                (resolvedData.resolvedUsers as { id: string; name: string }[]).map(recipient => (
                  <Stack key={recipient.id}>
                    <Icon name="person" />
                    <Title variant="bold">{recipient.name}</Title>
                  </Stack>
                ))}
              {resolvedData.targetType === "channel" && (
                <Stack key={resolvedData.channel}>
                  <Icon name="hashtag" />
                  <Title variant="bold">{resolvedData.channel}</Title>
                </Stack>
              )}
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage!.html} />
      </Block>
    </>
  );
};
