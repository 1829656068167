import { useEffect, useState } from "react";
import { DateInput, Layout, TimePicker, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";
import {
  DateFormats,
  IntroistDateTime,
  IntroistDateTimeFormats,
  introistDateTimeToMoment
} from "utils/dates";

interface Props {
  start: IntroistDateTime;
  end: IntroistDateTime;
  onChange: (start: IntroistDateTime, end: IntroistDateTime) => unknown;
}

const StyledTimePicker = styled(TimePicker)`
  max-width: initial !important;
`;

export const DateTimeRangePicker = ({ start, end, onChange }: Props) => {
  const [diffInMinutes, setDiff] = useState<number>(0);

  useEffect(() => {
    setDiff(introistDateTimeToMoment(end).diff(introistDateTimeToMoment(start), "minutes"));
  }, [start, end]);

  const changeStart = (update: Partial<IntroistDateTime>) => {
    const newStart = { ...start, ...update };
    const newEnd = introistDateTimeToMoment({ ...start, ...update }).add(diffInMinutes, "minutes");
    onChange(newStart, {
      ...end,
      date: newEnd.format(IntroistDateTimeFormats.date),
      time: newEnd.format(IntroistDateTimeFormats.time)
    });
  };

  return (
    <Layout.Group vertical style={{ maxWidth: 316 }}>
      <DateInput
        size="small"
        displayFormat={DateFormats.date.long}
        value={start.date}
        onChange={value => {
          changeStart({
            date: value!
          });
        }}
        clearable={false}
      />

      <Layout.Group>
        <StyledTimePicker
          size="small"
          time={start.time}
          onSelect={time => changeStart({ time })}
          scrollToSelected
        />
        <Title>-</Title>
        {start.date !== end.date && (
          <DateInput
            displayFormat={DateFormats.date.long}
            value={end.date}
            onChange={value =>
              onChange(start, {
                ...end,
                date: value!
              })
            }
            style={{ width: "100%" }}
            clearable={false}
          />
        )}
        <StyledTimePicker
          size="small"
          time={end.time}
          timeWindow={{
            start: start.time,
            end: "23:45"
          }}
          onSelect={time => {
            onChange(start, { ...end, time });
          }}
          scrollToSelected
        />
      </Layout.Group>
    </Layout.Group>
  );
};
