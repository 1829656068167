import { IconButton, Stack, TimeRange, TimeRangePicker } from "@introist/react-foundation/v2";

export const TimeIntervalSelector = ({
  disabled,
  onChange,
  allowedTimes
}: {
  disabled: boolean;
  onChange: (allowedTimes: TimeRange[]) => unknown;
  allowedTimes: TimeRange[];
}) => {
  return (
    <Stack vertical gap="small">
      {allowedTimes.map((allowedTime, idx) => (
        <Stack gap="xSmall">
          <TimeIntervalEditor
            key={idx}
            value={allowedTime}
            onChange={newRange => {
              onChange(
                allowedTimes.map((times, timeIdx) => {
                  if (timeIdx === idx) return newRange;
                  return times;
                })
              );
            }}
            disabled={disabled}
          />
          {allowedTimes.length > 1 && !disabled && (
            <IconButton
              disabled={disabled}
              icon="crossSmall"
              onClick={e => {
                onChange(
                  allowedTimes.filter((_, timeIdx) => {
                    return timeIdx !== idx;
                  })
                );
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          )}
        </Stack>
      ))}
      {!disabled && (
        <IconButton
          disabled={disabled}
          icon="plus"
          onClick={e => {
            const start = allowedTimes[allowedTimes.length - 1]["end"]!;
            const [startH, startM] = start.split(":");
            const end = `${(parseInt(startH, 10) + 1).toString()}:${startM}`;

            onChange([...allowedTimes, { start, end }]);
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      )}
    </Stack>
  );
};

const TimeIntervalEditor = ({
  value,
  onChange,
  disabled
}: {
  value: TimeRange;
  onChange: (range: TimeRange) => unknown;
  disabled?: boolean;
}) => {
  return (
    <Stack>
      <Stack gap="xSmall">
        <TimeRangePicker
          timeWindow={{ start: "06:00", end: "21:00" }}
          disabled={disabled}
          value={value}
          onSelect={onChange}
          separator="-"
          scrollToSelected
          maintainDuration={false}
        />
      </Stack>
    </Stack>
  );
};
