import { DateFormat, IntroistDateTimeFormats } from "./dateformats";
import moment, { Moment } from "moment-timezone";

export type IntroistDate = string;
export type IntroistTime = string;

export interface IntroistDateTime {
  date: IntroistDate;
  time: IntroistTime;
  timezone: string;
}

export const introistDateNow = (timezone: string) => {
  const nowMoment = moment().tz(timezone, true);
  return nowMoment.format(IntroistDateTimeFormats.date);
};

export const introistDateTimeToMoment = (datetime: IntroistDateTime): Moment =>
  moment(
    `${datetime.date} ${datetime.time}`,
    `${IntroistDateTimeFormats.date} ${IntroistDateTimeFormats.time}`
  ).tz(datetime.timezone, true);

export const introistDateToMoment = (date: IntroistDate): Moment =>
  moment(date, IntroistDateTimeFormats.date);

export const formatIntroistDateTime = (date: IntroistDateTime, format: DateFormat) =>
  introistDateTimeToMoment(date).format(format);

export const isSame = (date1: IntroistDateTime, date2: IntroistDateTime): boolean => {
  const date1UTC = introistDateTimeToMoment(date1).toISOString();
  const date2UTC = introistDateTimeToMoment(date2).toISOString();
  return date1UTC === date2UTC;
};

export const getDifference = (
  start: IntroistDateTime,
  end: IntroistDateTime,
  unitOfTime?: moment.unitOfTime.Diff
) => introistDateTimeToMoment(start).diff(introistDateTimeToMoment(end), unitOfTime);

export const addDays = (date: IntroistDate, days: number): IntroistDate => {
  return moment(date, IntroistDateTimeFormats.date)
    .add(days, "days")
    .format(IntroistDateTimeFormats.date);
};

export const subtractDays = (date: IntroistDate, days: number): IntroistDate => {
  return moment(date, IntroistDateTimeFormats.date)
    .subtract(days, "days")
    .format(IntroistDateTimeFormats.date);
};
