import { memo } from "react";
import { Option, OptionList, Popover } from "@introist/react-foundation/v2";

import { IBaseOptionListProps } from "../../types";

type PortalOptionListSingleSelectProps = IBaseOptionListProps & {
  selectedKey?: string;
  options: Option[];
  onOptionClick: (option: Option) => void;
};

export const PortalOptionListSingleSelect = memo(
  ({
    options,
    selectedKey,
    onOptionClick,
    onEnter,
    ...rest
  }: PortalOptionListSingleSelectProps) => {
    return (
      <Popover
        {...rest}
        style={{
          maxWidth: 240
        }}
      >
        <OptionList
          searchable
          highlightSearchMatch
          searchPlaceholder="Search"
          selected={selectedKey}
          options={options}
          onItemClick={onOptionClick}
          onEnter={onEnter}
        />
      </Popover>
    );
  }
);
