import { DataSourceRoute } from "./DataSourceRoute";
import { DataSourcesRoute } from "./DataSourcesRoute";
import { DataSourceConnectionSetupView } from "../views/DataSourceConnectionSetupView/DataSourceConnectionSetupView";
import { DataSourceOAuthRoute } from "./OAuthRoute";

export * from "./DataSourceRoute";
export * from "./DataSourcesRoute";

export const DATA_SOURCE_ROUTES = {
  datasources: {
    key: "datasources",
    path: "datasources",
    element: <DataSourcesRoute />
  },
  connectDataSource: {
    key: "connectDataSource",
    path: "datasources/connect",
    element: <DataSourceConnectionSetupView />
  },
  datasourceOauth: {
    key: "datasource-oauth",
    path: "datasources/oauth/complete",
    element: <DataSourceOAuthRoute />
  },
  datasource: {
    key: "datasource",
    path: "datasources/:id",
    element: <DataSourceRoute />
  }
};
