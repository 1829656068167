import { TriggerEvent, useTrigger, useTriggerActions } from "../../../triggers/hooks/useTrigger";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Field,
  IconButton,
  Modal,
  Select,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { FormFooter } from "../../../datasources/components/FormFooter";
import { useDataSources } from "../../../datasources/hooks/useDataSources";
import { DataSourceIcon } from "../../../datasources/components/DataSourceIcon";
import { HoverableCard } from "../../../../foundation/blocks/HoverableCard";
import { DataSourceEventNames } from "../../../datasources/datasources";
import { api } from "../../../../services/rpc/RpcProvider";

export const TriggerEventConfEditor = ({ triggerId }: { triggerId: string }) => {
  const toast = useToast();

  const { trigger } = useTrigger({ id: triggerId ?? "" }, { enabled: !!triggerId });
  const { update } = useTriggerActions();

  const { dataSources } = useDataSources();
  const { data: availableEvents } = api.employees.dataSources.availableEvents.useQuery({});

  const [open, setOpen] = useState(false);

  const [data, setData] = useState<Partial<TriggerEvent>>({});

  useEffect(() => {
    setData(trigger?.triggerEvent ?? {});
  }, [trigger]);

  const eventOptions = useMemo(() => {
    if (!availableEvents) return [];
    const ds = dataSources?.find(ds => ds.id === data.datasourceId);
    if (!ds) return [];

    return (
      availableEvents
        .find(e => e.source === ds.sourceType)
        ?.events.map(e => ({ key: e, title: DataSourceEventNames[e] ?? e })) ?? []
    );
  }, [data, availableEvents, dataSources]);

  const onSave = useCallback(async () => {
    await update(trigger.id, { triggerEvent: data as TriggerEvent, type: "event" }).then(() => {
      toast.success("Event configured");
      setOpen(false);
    });
  }, [data, trigger, toast, update]);

  const onRemove = useCallback(async () => {
    await update(trigger.id, { triggerEvent: null, type: "rule" }).then(() => {
      toast.success("Event removed");
    });
  }, [trigger, toast, update]);

  if (trigger === undefined) return null;

  return (
    <>
      {!trigger?.triggerEvent && (
        <Button variant="outlined" onClick={() => setOpen(true)} style={{ width: "200px" }}>
          Set up event
        </Button>
      )}
      {trigger.triggerEvent && (
        <TriggerEventCard
          triggerEvent={trigger.triggerEvent}
          onClick={() => setOpen(true)}
          onRemove={onRemove}
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Edit event"
        style={{ width: "350px" }}
      >
        <Stack vertical>
          <Field title="Data source">
            <Select
              size="small"
              options={(dataSources ?? []).map(ds => ({
                key: ds.id,
                title: ds.name,
                startAdornment: <DataSourceIcon dataSource={ds.sourceType} size="small" />
              }))}
              value={data?.datasourceId}
              onSelect={opt => setData({ ...data, datasourceId: opt.key, event: undefined })}
              placeholder="Select data source"
              style={{ width: "100%" }}
            />
          </Field>
          <Field title="Event">
            <Select
              size="small"
              options={eventOptions}
              placeholder="Select event"
              disabled={!data?.datasourceId}
              style={{ width: "100%" }}
              value={data?.event}
              onSelect={opt => setData({ ...data, event: opt.key })}
            />
          </Field>
          <FormFooter onCancel={() => setOpen(false)} onSubmit={onSave} />
        </Stack>
      </Modal>
    </>
  );
};

const TriggerEventCard = ({
  triggerEvent,
  onClick,
  onRemove
}: {
  triggerEvent: TriggerEvent;
  onClick: () => void;
  onRemove?: () => void;
}) => {
  const { dataSources } = useDataSources();

  const ds = useMemo(() => {
    return (dataSources ?? []).find(ds => ds.id === triggerEvent.datasourceId);
  }, [triggerEvent, dataSources]);

  return (
    <HoverableCard onClick={onClick}>
      <Stack>
        {ds && <DataSourceIcon dataSource={ds.sourceType} />}
        <Stack>
          <Title variant="bold">
            {DataSourceEventNames[triggerEvent.event] ?? triggerEvent.event}
          </Title>
          <Title>in</Title> <Title variant="bold">{ds?.name ?? "Unknown data source"}</Title>
        </Stack>
        {onRemove && (
          <IconButton
            style={{ marginLeft: "auto", visibility: "hidden" }}
            icon="crossSmall"
            onClick={e => {
              onRemove();
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </Stack>
    </HoverableCard>
  );
};
