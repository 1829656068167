import { ListView, TopBarRoute } from "components/templates";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  Card,
  CircularLoader,
  H3,
  Icon,
  Layout,
  Select,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { useBatch, useBatchActions } from "../../useBatches";
import { ProgressBar, Skeletons } from "../../../../components/molecules";
import { IconPickerIcon } from "../../../../components/organisms";
import { useWorkflows } from "../../../flows/hooks/useWorkflows";
import { useEffect, useMemo, useState } from "react";
import { useSearch } from "../../../../foundation";
import { AddEmployeesModal } from "./AddEmployeesModal";
import { PopupActionCard } from "../../../../foundation/templates/PopupActionCard";
import { AddGroupModal } from "./AddGroupModal";
import { BatchStatus } from "./BatchStatus";
import useInterval from "use-interval";
import { BatchEmployeeRow } from "./BatchEmployeeRow";
import { BatchActionMenu } from "./BatchActionMenu";

export const BatchRoute = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const { id } = useParams();

  const [employeeAddOpen, setEmployeeAddOpen] = useState(false);
  const [groupAddOpen, setGroupAddOpen] = useState(false);

  const [pollAction, setPollAction] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const { workflows } = useWorkflows({});

  const { batch, employees, actions, refetch: refetchBatchData } = useBatch(id!);
  const { removeEmployees, cancelEmployees, retry } = useBatchActions();

  const { search, setSearch, filtered } = useSearch(employees, { fields: ["name"] });

  useInterval(
    async () => {
      await refetchBatchData();
    },
    pollAction ? 10000 : null
  );

  useEffect(() => {
    if (actions) {
      setPollAction(actions.some(a => !a.completedAt));
    }
  }, [actions]);

  const workflow = useMemo(() => {
    if (!batch || !workflows) return undefined;
    return workflows.find(wf => wf.id === batch.workflowId);
  }, [batch, workflows]);

  const failingEmployees = useMemo(() => {
    if (!employees) return [];
    return employees.filter(e => !e.automationId && e.error);
  }, [employees]);

  const pendingActions = useMemo(() => {
    return (actions ?? []).filter(a => !a.completedAt);
  }, [actions]);

  return (
    <TopBarRoute
      paths={[
        { key: "batches", title: "Batches", onClick: () => navigate("/batches") },
        { key: "batch", title: batch?.title || "" }
      ]}
      footer={
        selected.length > 0 && (
          <PopupActionCard>
            <Title>{selected.length} employees selected</Title>
            {batch?.stage === "draft" && (
              <Button
                onClickWithLoading={async () => {
                  await removeEmployees(id!, selected).then(() => {
                    setSelected([]);
                  });
                }}
              >
                Remove
              </Button>
            )}
            {batch?.stage === "active" && (
              <Button
                onClickWithLoading={async () => {
                  await cancelEmployees(id!, selected).then(() => {
                    setSelected([]);
                  });
                }}
              >
                Cancel
              </Button>
            )}
            <div onClick={() => setSelected([])} style={{ cursor: "pointer" }}>
              <Title style={{ color: "white" }}>Clear</Title>
            </div>
          </PopupActionCard>
        )
      }
    >
      <Stack vertical>
        {(!batch || !workflows) && <BatchLoading />}
        {batch && (
          <>
            <Card style={{ padding: "var(--spacing-xLarge)" }}>
              <Stack vertical gap="xLarge">
                <Stack>
                  <IconPickerIcon icon="duplicate" fallbackIcon="duplicate" />
                  <H3>{batch.title}</H3>
                  <Stack style={{ marginLeft: "auto" }}>
                    {pendingActions.length > 0 && (
                      <Stack>
                        <CircularLoader size="small" />
                        <Title>Running {pendingActions.length} actions</Title>
                      </Stack>
                    )}
                    {batch.stage === "active" && failingEmployees.length > 0 && (
                      <Stack>
                        <Icon name="warning" danger />
                        <Title color={theme.palette.danger.default}>
                          {failingEmployees.length} employees failed to start
                        </Title>
                        {pendingActions.length === 0 && (
                          <Button startIcon="playCircle" onClickWithLoading={() => retry(batch.id)}>
                            Retry start
                          </Button>
                        )}
                      </Stack>
                    )}
                    {batch.stage !== "completed" && <BatchActionMenu batch={batch} />}
                  </Stack>
                </Stack>
                <Layout.Divider />
                <Stack justifyContent="space-between">
                  <Stack
                    onClick={() => window.open(`/flows/edit/${batch.workflowId}`, "_blank")}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon name="workflows" dimmed />
                    <Title variant="bold">{workflow?.title ?? "Unknown workflow"}</Title>
                  </Stack>
                  {batch.stage === "active" && batch.stats && (
                    <Stack style={{ width: "250px" }}>
                      <Title variant="bold" style={{ width: "70px" }}>
                        {batch.stats.completed} of {batch.stats.completed + batch.stats.active}
                      </Title>
                      <ProgressBar
                        value={batch.stats.completed}
                        total={batch.stats.completed + batch.stats.active}
                        height={4}
                        rounded
                        fillConfig={{ color: theme.palette.success.default }}
                      />
                    </Stack>
                  )}
                  <BatchStatus batch={batch} />
                </Stack>
              </Stack>
            </Card>

            <ListView.Header
              title="Employees"
              search={{ search, setSearch }}
              count={filtered?.length}
              create={
                <Select
                  size="small"
                  element="button"
                  variant="outlined"
                  disabled={batch.stage === "completed"}
                  options={[
                    {
                      key: "employees",
                      title: "Employees",
                      onClick: () => setEmployeeAddOpen(true),
                      startAdornmentIcon: "person"
                    },
                    {
                      key: "Group",
                      title: "Group",
                      onClick: () => setGroupAddOpen(true),
                      startAdornmentIcon: "persons"
                    }
                  ]}
                  startAdornment={<Icon name="plus" />}
                  placeholder="Add"
                />
              }
            />

            <ListView.List
              data={filtered}
              empty={{
                title: "No employees in this batch",
                message: "Start by adding employees or a group of employees.",
                icon: "persons",
                action: <Button onClick={() => setEmployeeAddOpen(true)}>Add employees</Button>
              }}
              renderRow={emp => (
                <BatchEmployeeRow
                  key={emp.id}
                  batch={batch}
                  emp={emp}
                  actions={(actions ?? []).filter(a => a.batchEmployeeId === emp.id)}
                  selected={selected.includes(emp.id)}
                  onToggleSelect={checked => {
                    if (checked) setSelected([...selected, emp.id]);
                    else setSelected(selected.filter(e => e !== emp.id));
                  }}
                />
              )}
            />

            <AddEmployeesModal
              batchId={id!}
              open={employeeAddOpen}
              onClose={() => setEmployeeAddOpen(false)}
            />
            <AddGroupModal
              batchId={id!}
              open={groupAddOpen}
              onClose={() => setGroupAddOpen(false)}
            />
          </>
        )}
      </Stack>
    </TopBarRoute>
  );
};

const BatchLoading = () => (
  <>
    <Skeletons.Block height={100} />
  </>
);
