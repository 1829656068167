import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Icon,
  InfoCard,
  Layout,
  useActions,
  useToast
} from "@introist/react-foundation/v2";

import { TextInput } from "components/atoms";
import { CredentialsConfiguredInfoCard } from "../CredentialsConfiguredInfoCard";

type ApiTokenCredentialFormProps = {
  apiToken?: string;
  onSaveToken: (token: string) => Promise<unknown>;
  instructionsUrl?: string;
  credentialsConfigured: boolean;
};

const IconArrowRight = styled(Icon).attrs({ name: "arrowRight" })`
  stroke: var(--palette-primary-default);
`;

export const ApiTokenCredentialForm = ({
  apiToken,
  onSaveToken,
  instructionsUrl,
  credentialsConfigured,
  ...rest
}: ApiTokenCredentialFormProps) => {
  const { onAction } = useActions();
  const toast = useToast();

  const [token, setToken] = useState(apiToken || "");

  const [configured, setConfigured] = useState(credentialsConfigured);

  useEffect(() => setConfigured(credentialsConfigured), [credentialsConfigured]);

  const onSubmit = onAction(
    () =>
      onSaveToken(token).then(() => {
        toast.success("API token saved");
        setConfigured(true);
      }),
    "Failed to save token"
  );

  const openInstructions = () => {
    window.open(instructionsUrl, "_blank");
  };

  return (
    <Layout.Group {...rest} vertical>
      {configured ? (
        <Layout.Group vertical lastChildToEnd>
          <CredentialsConfiguredInfoCard />
          <Button variant="outlined" onClick={() => setConfigured(false)}>
            Reconfigure
          </Button>
        </Layout.Group>
      ) : (
        <Layout.Group vertical lastChildToEnd gap="xLarge">
          <div>
            {instructionsUrl && (
              <InfoCard
                colorVariant="primary"
                title={
                  <Layout.Group lastChildToEnd>
                    <span>Read how to find the token</span>
                    <IconArrowRight />
                  </Layout.Group>
                }
                onClick={openInstructions}
              />
            )}
          </div>
          <TextInput type="password" label="API token" value={token} onChange={setToken} />
          <Button onClickWithLoading={onSubmit}>Save</Button>
        </Layout.Group>
      )}
    </Layout.Group>
  );
};
