import constate from "constate";
import { api } from "services/rpc/RpcProvider";

export const [CompanyEventsContextProvider, useCompanyEventsContext] = constate(() => {
  const { data: companyEvents, isLoading: companyEventsLoading } = api.companyEvents.list.useQuery(
    {}
  );

  return {
    companyEvents,
    companyEventsLoading
  };
});
