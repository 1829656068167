import { Stack, Title } from "@introist/react-foundation/v2";

interface Props {
  description: string;
}

export const AccountsHeader = ({ description }: Props) => {
  return (
    <Stack vertical gap="xLarge">
      <Title>{description}</Title>
    </Stack>
  );
};
