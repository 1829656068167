import { useEmployees } from "../../../employees/hooks/useEmployees";
import { Checkbox, CircularLoader, Modal, Stack, Title } from "@introist/react-foundation/v2";
import { SearchInput } from "../../../../components/molecules";
import { ListView, useSearch } from "../../../../foundation";
import { ListRow } from "../../../../components/organisms";
import { useEffect, useState } from "react";
import { useBatchActions } from "../../useBatches";

export const AddEmployeesModal = ({
  batchId,
  open,
  onClose
}: {
  batchId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { employees } = useEmployees({});
  const { addEmployees } = useBatchActions();

  const { search, setSearch, filtered } = useSearch(employees, { fields: ["name"] });

  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add employees"
      style={{ width: "500px", height: "70vh" }}
      primaryButton={{
        title: `Add ${selected.length} employees`,
        disabled: selected.length === 0,
        onClickWithLoading: () => addEmployees(batchId, selected).then(onClose)
      }}
      secondaryButton={{
        title: "Cancel",
        onClick: onClose
      }}
    >
      <Stack vertical>
        <SearchInput value={search} onChange={setSearch} />
        {!employees && <CircularLoader />}
        {employees && (
          <ListView.List
            data={filtered}
            renderRow={emp => (
              <ListRow
                gridTemplateColumns="auto 1fr"
                onClick={() => {
                  if (selected.find(id => id === emp.id)) {
                    setSelected(selected.filter(id => id !== emp.id));
                  } else setSelected([...selected, emp.id!]);
                }}
              >
                <Checkbox checked={selected.includes(emp.id!)} onChange={() => {}} />
                <Title variant="bold">{emp.name}</Title>
              </ListRow>
            )}
          />
        )}
      </Stack>
    </Modal>
  );
};
