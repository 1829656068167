import { useState } from "react";
import { ToggleSettingCard } from "components/organisms";

import { useUpdateCompanyEventSetting } from "../hooks";
import { getEventSettingsMetadata } from "../util";
import { EventSettingProps } from ".";
import { Title } from "@introist/react-foundation/v2";

export const RescheduleAttendeeOnDecline = ({ setting, readOnly }: EventSettingProps) => {
  const metadata = getEventSettingsMetadata(setting.setting);
  const [active, setActive] = useState(setting.active);
  const { updateEventSetting } = useUpdateCompanyEventSetting({
    setting,
    config: { enabled: !readOnly }
  });

  return (
    <ToggleSettingCard
      autoCollapse
      readOnly={readOnly}
      key={setting.setting}
      title={metadata.title}
      description={metadata.description}
      active={active}
      onToggleActive={active => {
        setActive(active);
        updateEventSetting({ active }, () => setActive(setting.active));
      }}
    >
      <Title>Declining attendees will be rescheduled</Title>
    </ToggleSettingCard>
  );
};
