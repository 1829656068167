import { DataForm } from "@introist/react-foundation/v2";

import { useEmployeeAttributeApi } from "modules/employees/api/EmployeeAttributeApi";
import { EmailMessageBuilderV2 } from "modules/workflows/components/EmailMessageBuilder/v2/EmailMessageBuilderV2";
import { useEmailSenderApi } from "services/api/Senders";
import { prefixForm } from "utils/FormUtils";

import { FormBuilderV2 } from "../Forms/v2/FormBuilderV2";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { WorkflowEditorBlock } from "modules/workflows/blocks";

type EmailFormEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
};

export const EmailFormEditorV2 = ({ form, formPrefix = "stepData." }: EmailFormEditorProps) => {
  const attributeApi = useEmployeeAttributeApi();
  const { data: attributes } = attributeApi.list({
    includeHidden: false
  }).query;

  const prefixedForm = prefixForm(form, formPrefix);

  const senderApi = useEmailSenderApi();
  const { data: senders } = senderApi.list({
    filterCalendarOnlySenders: true
  }).query;

  return (
    <>
      <FormBuilderV2
        value={prefixedForm.get("form")}
        onChanged={prefixedForm.set("form")}
        attributes={attributes ?? []}
        hasError={{
          title: prefixedForm.isError("form.title"),
          description: prefixedForm.isError("form.description")
        }}
      />

      <EmailMessageBuilderV2
        title="Form introductory email"
        value={prefixedForm.get("message")}
        onChanged={prefixedForm.set("message")}
        senders={senders ?? []}
        attributes={(attributes ?? []).filter(attr => attr.type === "email")}
        hasError={{
          senderId: prefixedForm.isError("message.senderId"),
          to: prefixedForm.isError("message.to"),
          subject: prefixedForm.isError("message.subject")
        }}
      />
      <WorkflowEditorBlock.Attachments form={form} />
    </>
  );
};
