import { useState } from "react";
import { Button, ButtonProps } from "@introist/react-foundation/v2";
import { ConnectHrisModal } from "./ConnectHrisModal";

type ConnectHrisButtonProps = ButtonProps & {
  onConnected: () => void;
};

export const ConnectHrisButton = ({ onConnected, ...props }: ConnectHrisButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button startIcon="arrowsLeftRight" onClick={() => setOpen(true)} {...props}>
        Connect HRIS
      </Button>
      <ConnectHrisModal
        open={open}
        onClose={() => setOpen(false)}
        onConnected={async () => {
          setOpen(false);
          await onConnected();
        }}
      />
    </>
  );
};
