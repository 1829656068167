import {
  Button,
  Field,
  Icon,
  IconButton,
  Input,
  Modal,
  Select,
  Stack,
  useToast
} from "@introist/react-foundation/v2";
import { useEmployees } from "../../employees/hooks/useEmployees";
import { FormShareResponse, useFormActions } from "../useForms";
import { useCallback, useState } from "react";
import { FormFooter } from "../../datasources/components/FormFooter";
import { SlackIcon } from "../../../assets";
import { useCopyToClipboard } from "usehooks-ts";
import { RelativeDateInlineEditor } from "../../../components/molecules";

export const ShareModal = ({
  formId,
  open,
  onClose
}: {
  formId: string;
  open: boolean;
  onClose: () => unknown;
}) => {
  return (
    <Modal title="Share Form" open={open} onClose={onClose}>
      <ShareForm formId={formId} onClose={onClose} />
    </Modal>
  );
};

const ShareForm = ({ formId, onClose }: { formId: string; onClose: () => unknown }) => {
  const toast = useToast();
  const { employees } = useEmployees({});

  // eslint-disable-next-line
  const [text, copyToClipboard] = useCopyToClipboard();

  const [employee, setEmployee] = useState<string | undefined>();
  const [expiration, setExpiration] = useState("+1w");

  const [response, setResponse] = useState<FormShareResponse | undefined>();

  const { share } = useFormActions();

  const onShare = useCallback(async () => {
    await share({ formId, employeeId: employee, expiration }).then(res => setResponse(res));
  }, [formId, employee, share, expiration]);

  return (
    <>
      {!response && (
        <Stack vertical style={{ width: "400px" }}>
          <Field title="Link response to Employee">
            <Select
              style={{ width: "100%" }}
              searchable
              placeholder="Select Employee"
              options={(employees ?? []).map(e => ({ key: e.id!, title: e.name! }))}
              onSelect={opt => setEmployee(opt.key)}
              value={employee}
            />
          </Field>
          <Field title="Link expires in">
            <RelativeDateInlineEditor
              variant="outlined"
              onChange={exp => setExpiration(exp ?? "+1w")}
              value={expiration}
              hidden={{ side: true }}
            />
          </Field>
          <FormFooter onCancel={onClose} onSubmit={onShare} submit="Share" />
        </Stack>
      )}
      {response && (
        <Stack vertical style={{ width: "600px" }}>
          <Field title="Share in Slack">
            <Input
              value={response.slackCommand}
              startAdornment={<SlackIcon />}
              endAdornment={
                <IconButton
                  icon="duplicate"
                  onClick={() =>
                    copyToClipboard(response.slackCommand).then(() =>
                      toast.info("Slack command copied to clipboard")
                    )
                  }
                />
              }
              readOnly
            />
          </Field>
          <Field title="Share Anywhere">
            <Input
              value={response.webUrl}
              startAdornment={<Icon name="globe" />}
              endAdornment={
                <IconButton
                  icon="duplicate"
                  onClick={() =>
                    copyToClipboard(response.webUrl).then(() =>
                      toast.info("URL copied to clipboard")
                    )
                  }
                />
              }
              readOnly
            />
          </Field>
          <Stack justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};
