import { Tag } from "@introist/react-foundation/v2";
import { TriggerPreview } from "services/rpc/RpcProvider";

type Props = {
  matchStatus: TriggerPreview["matchStatus"];
};

export const TriggerPreviewMatchStatusTag = ({ matchStatus }: Props) => {
  switch (matchStatus) {
    case "active":
      return <Tag colorVariant="success">Active</Tag>;
    case "cooldown":
      return <Tag colorVariant="outlined">Cooldown</Tag>;
    case "inactive":
      return <Tag>Inactive</Tag>;
    case "pending":
      return <Tag colorVariant="primary">Pending</Tag>;
    case "failing":
      return <Tag colorVariant="danger">Failing</Tag>;
    default:
      return <Tag>Unknown</Tag>;
  }
};
