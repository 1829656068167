import { MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { BaseModal, Button, ButtonProps, IconName, Layout } from "@introist/react-foundation/v2";
import { animated, useTransition } from "@react-spring/web";
import { SliderModalHeader } from "./SliderModalHeader";
import { SliderModalSidebar } from "./SliderModalSidebar";

type SliderModalButton = Pick<
  ButtonProps,
  "disabled" | "endIcon" | "startIcon" | "onClick" | "onClickWithLoading" | "variant" | "children"
>;

export type SliderModalSlide = {
  slide: ReactNode;
  title: ReactNode;
  icon?: IconName;
  nextButtonProps?: SliderModalButton;
  prevButtonProps?: SliderModalButton;
};

export type SliderModalProps = {
  open: boolean;
  onClose: () => void;
  onSlideChange?: (index: number) => void;
  title?: ReactNode;
  slides: SliderModalSlide[];
  horizontal?: boolean;
  blended?: boolean;
  sidebar?: boolean;
  currentSlide?: number;
};

const StyledSliderModal = styled(BaseModal)`
  width: 60rem;
  height: 47.5rem;
  overflow: hidden;
  > div {
    padding: 0;
  }
`;

const Content = styled.div<{ $hasSidebar: boolean }>`
  flex: 1;
  overflow: hidden;
  ${({ $hasSidebar }) =>
    $hasSidebar &&
    css`
      display: grid;
      grid-template-columns: 10rem 1fr;
    `}
`;
const Footer = styled(Layout.Group)<{ $blended: boolean }>`
  position: relative;
  padding: var(--spacing-large) var(--spacing-xxLarge);
  border-top: ${({ $blended }) => ($blended ? "" : "1px solid var(--palette-border-subdued);")};

  > :first-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Slider = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%;
  background-color: var(--palette-background-default);
`;

const AnimatedSlide = styled(animated.div)`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > :first-child {
    flex: 1;
  }
`;

const SlideIndicator = styled.span<{ $active: boolean }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: ${({ $active }) =>
    $active ? "var(--palette-primary-default)" : "var(--palette-border-subdued)"};
`;

const useSliderModal = (open: boolean, horizontal = false) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const openRef = useRef(false);

  const [direction, setDirection] = useState(1);

  const verticalTransitions = useTransition(currentSlide, {
    from: {
      transform: `translate3d(0,${direction * 100}%,0)`
    },
    enter: {
      transform: "translate3d(0,0%,0)"
    },
    leave: {
      transform: `translate3d(0,${direction * -100}%,0)`,
      position: "absolute"
    },
    immediate: !openRef.current
  });

  const horizontalTransitions = useTransition(currentSlide, {
    from: {
      transform: `translateX(${direction * 100}%)`
    },
    enter: {
      transform: "translateX(0%)"
    },
    leave: {
      transform: `translateX(${direction * -100}%)`,
      position: "absolute"
    },
    immediate: !openRef.current
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      openRef.current = open;
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const nextSlide = () => {
    setCurrentSlide(currentSlide + 1);
    setDirection(1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide - 1);
    setDirection(-1);
  };

  const jumpToSlide = (index: number) => {
    if (index === currentSlide) return;
    const direction = index > currentSlide ? 1 : -1;
    setCurrentSlide(index);
    setDirection(direction);
  };

  return {
    currentSlide,
    transitions: horizontal ? horizontalTransitions : verticalTransitions,
    nextSlide,
    prevSlide,
    jumpToSlide
  };
};

export const SliderModal = ({
  open,
  sidebar,
  title,
  slides,
  blended = false,
  horizontal = false,
  onSlideChange,
  currentSlide: currentSlideProp,
  onClose,
  ...rest
}: SliderModalProps) => {
  const { currentSlide, transitions, nextSlide, jumpToSlide } = useSliderModal(open, horizontal);

  const hasJumped = useRef(false);

  const nextButtonProps = useMemo(() => {
    const lastSlide = currentSlide === slides.length - 1;

    let props: SliderModalButton = {};
    const nextButtonProps = slides[currentSlide].nextButtonProps;

    props = slides[currentSlide].nextButtonProps || {};

    if (!nextButtonProps?.children) {
      props.children = lastSlide ? "Close" : "Next";
    }

    if (!nextButtonProps?.endIcon) {
      props.endIcon = lastSlide ? undefined : "arrowRight";
    }

    if (!nextButtonProps?.onClick) {
      props.onClick = lastSlide ? onClose : nextSlide;
    }

    const onClick = props.onClick;
    const onClickWithLoading = props.onClickWithLoading;
    return {
      ...props,
      onClick: (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        // @ts-expect-error
        onClick && onClick(event);
        lastSlide ? onClose() : nextSlide();
      },
      onClickWithLoading: async (event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        // @ts-expect-error
        onClickWithLoading && (await onClickWithLoading(event));
        lastSlide ? onClose() : nextSlide();
      }
    };
  }, [currentSlide, slides, nextSlide, onClose]);

  useEffect(() => {
    if (currentSlideProp === undefined || hasJumped.current) return;

    jumpToSlide(currentSlideProp);
    hasJumped.current = true;
  }, [currentSlideProp, jumpToSlide]);

  useEffect(() => {
    onSlideChange && onSlideChange(currentSlide);
  }, [currentSlide, onSlideChange]);

  const currentTitle = title ? title : slides[currentSlide].title;

  useEffect(() => {
    if (!open && currentSlide > 0) {
      jumpToSlide(0);
    }
  }, [open, currentSlide, jumpToSlide]);

  return (
    <StyledSliderModal open={open} closeOnOutsideClick {...rest}>
      <SliderModalHeader blended={blended} title={currentTitle} onClose={onClose} />
      <Content $hasSidebar={!!sidebar}>
        {sidebar && (
          <SliderModalSidebar
            slides={slides}
            currentSlide={currentSlide}
            jumpToSlide={jumpToSlide}
          />
        )}
        <Slider>
          {transitions((style, index) => (
            <AnimatedSlide style={style} key={`slider-modal-slide-${index}`}>
              {slides[index].slide}
            </AnimatedSlide>
          ))}
        </Slider>
      </Content>
      <Footer justifyContent="flex-end" $blended={blended}>
        <Layout.Group gap="small">
          {slides.map((_, index) => (
            <SlideIndicator
              key={`slider-modal-slide-indicator-${index}`}
              $active={index === currentSlide}
            />
          ))}
        </Layout.Group>
        <Button {...nextButtonProps} />
      </Footer>
    </StyledSliderModal>
  );
};
