import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionMenu,
  Button,
  Card,
  CircularLoader,
  Field,
  H4,
  LabelledCheckbox,
  Layout,
  Title,
  useActions,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";

import { api, SsoConfig, SsoConfigUpdate } from "services/rpc/RpcProvider";
import { CopyButton, TextInput } from "../../../../components/atoms";
import { isEqual } from "lodash";
import { PlanAwareRoute } from "../../../../foundation/templates/PlanAwareRoute";

export const SsoSettingsRoute = () => {
  const toast = useToast();
  const { onConfirmAction } = useActions();

  const { data: ssoConfig, refetch: reloadSsoConfig } = api.workspaces.sso.find.useQuery({});

  const createSsoConfig = api.workspaces.sso.create.useMutation();
  const updateSsoConfig = api.workspaces.sso.update.useMutation();
  const deleteSsoConfig = api.workspaces.sso.delete.useMutation();

  const [fields, setFields] = useState<(SsoConfig & SsoConfigUpdate) | null | undefined>();

  useEffect(() => {
    setFields(ssoConfig);
  }, [ssoConfig]);

  const hasChanges = useMemo(() => !isEqual(ssoConfig, fields), [ssoConfig, fields]);

  const onCreate = useCallback(async () => {
    await createSsoConfig
      .mutateAsync({})
      .then(async () => {
        await reloadSsoConfig();
        toast.success("SSO enabled");
      })
      .catch(() => toast.error("Failed to enable SSO"));
  }, [createSsoConfig, reloadSsoConfig, toast]);

  const onDisable = onConfirmAction(
    async () => {
      await deleteSsoConfig
        .mutateAsync({})
        .then(async () => {
          await reloadSsoConfig();
          toast.success("SSO disabled");
        })
        .catch(() => toast.error("Failed to disable SSO"));
    },
    {
      title: "Disable Single sign-on",
      description: "Are you sure you want to disable Single sign-on? Configuration will be removed."
    }
  );

  const onSave = useCallback(async () => {
    await updateSsoConfig
      .mutateAsync(fields!)
      .then(async () => {
        await reloadSsoConfig();
        toast.success("Settings updated");
      })
      .catch(() => toast.error("Failed to update settings"));
  }, [fields, toast, updateSsoConfig, reloadSsoConfig]);

  if (fields === undefined) return <CircularLoader fillParent />;

  return (
    <PlanAwareRoute title="Upgrade to use Single sign-on">
      <Layout.Group vertical gap="xLarge">
        <Card spacing="xLarge">
          <Layout.Group vertical gap="large">
            <Layout.Group vertical gap="small">
              <Layout.Group gap="small" justifyContent="space-between">
                <H4>Single Sign-On</H4>
                <ActionMenu
                  options={[{ key: "disable", title: "Disable SSO", onClick: onDisable }]}
                />
              </Layout.Group>
              <Title>Enable users to sign in with your Single sign-on provider like Okta.</Title>
            </Layout.Group>
            {fields === null && (
              <Layout.Group vertical alignItems="center">
                <Button onClickWithLoading={onCreate}>Enable Single sign-on</Button>
              </Layout.Group>
            )}
            {!!fields && (
              <>
                <SsoConfigEditor config={fields!} onChanged={setFields} />
                <Button
                  style={{ marginLeft: "auto" }}
                  disabled={!hasChanges}
                  onClickWithLoading={onSave}
                >
                  Save changes
                </Button>
              </>
            )}
          </Layout.Group>
        </Card>
      </Layout.Group>
    </PlanAwareRoute>
  );
};

type SsoConfigEditorProps = {
  config: SsoConfig & SsoConfigUpdate;
  onChanged: (config: SsoConfig & SsoConfigUpdate) => void;
};

const SsoConfigEditor = ({ config, onChanged }: SsoConfigEditorProps) => {
  const { theme } = useTheme();

  return (
    <Layout.Group vertical gap="large">
      <Field title="Single sign-on URL">
        <TextInput
          autoComplete="off"
          size="small"
          value={config.ssoUrl}
          readOnly
          endAdornment={<CopyButton textToCopy={config!.ssoUrl} />}
        />
      </Field>
      <Field title="Audience">
        <TextInput
          autoComplete="off"
          size="small"
          value={config!.audience}
          readOnly
          endAdornment={<CopyButton textToCopy={config!.audience} />}
        />
      </Field>
      <Field title="Identity Provider sign-on URL">
        <TextInput
          autoComplete="off"
          size="small"
          value={config.idpSsoUrl ?? ""}
          onChange={idpSsoUrl => onChanged({ ...config, idpSsoUrl })}
        />
      </Field>
      <Field title="Identity Provider Issuer">
        <TextInput
          autoComplete="off"
          size="small"
          value={config.idpIssuer ?? ""}
          onChange={idpIssuer => onChanged({ ...config, idpIssuer })}
        />
      </Field>
      <Field title="Signing Certificate">
        <TextInput
          autoComplete="off"
          size="small"
          type="password"
          placeholder="Certificate not shown. Paste here to update."
          value={config.idpCert ?? ""}
          onChange={idpCert => onChanged({ ...config, idpCert })}
        />
      </Field>
      <LabelledCheckbox
        style={{ marginTop: theme.spacing.medium }}
        checked={config.forceTenant}
        label="Allow users to sign in only with Single sign-on provider"
        onChange={forceTenant => onChanged({ ...config, forceTenant })}
      />
      <LabelledCheckbox
        style={{ marginTop: theme.spacing.medium }}
        checked={config.autoCreateUsers}
        label="Enable auto-creating user accounts (Just-in-time provisioning)"
        onChange={autoCreateUsers => onChanged({ ...config, autoCreateUsers })}
      />
    </Layout.Group>
  );
};
