export const WebhookStepSchemaFields = {
  title: {
    type: "string"
  },
  zapId: {
    type: "string"
  },
  hookUrl: {
    type: "string"
  }
};
