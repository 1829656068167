import { api } from "../../services/rpc/RpcProvider";

export const useReports = () => {
  const { data: reports } = api.reports.list.useQuery({});
  return { reports };
};

export const useReport = (id: string, options?: any) => {
  const { data: report, error } = api.reports.find.useQuery({ id }, options);
  return { report, error };
};
