import { useEffect, useMemo, useRef, useState } from "react";
import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { SlackBotModalV2 } from "../SlackBotModalV2";
import {
  Card,
  ErrorMessage,
  Icon,
  IconName,
  Layout,
  Option,
  OptionList,
  Popover,
  Title,
  useResponsive,
  TextButton
} from "@introist/react-foundation/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { ParticipantCard } from "modules/workflows/components/ParticipantCard";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { getConnectAccountRoute } from "AppRoutes";

type SlackSenderSelectProps = {
  senderId: string;
  onSelectSender?: (senderId: string | undefined) => unknown;
  error?: boolean;
  readOnly?: boolean;
  placeholder?: string;
};

const StyledCard = styled(Card)`
  && {
    padding: 0;
  }
`;

const StyledHeaderExample = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-small) var(--spacing-large);
  border-bottom: 1px solid var(--palette-border-subdued);
`;

const TabButtonTitle = styled(Title).attrs({ variant: "bold" })``;

const TabButton = styled.button<{ $active: boolean }>`
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 2px 6px;
  cursor: pointer;

  > ${TabButtonTitle} {
    position: relative;
    z-index: 1;
    color: ${({ $active }) =>
      $active ? "var(--palette-primary-default)" : "var(--palette-foreground-ghosted"};
  }
`;

const AnimatedTagButtonBackground = styled(animated.span)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--palette-primary-ghosted);
  border-radius: var(--rounding-small);
  width: 1rem;
  height: 1rem;
`;

const Content = styled.div`
  padding: var(--spacing-small) var(--spacing-small);
`;

export const SlackSenderSelectV2 = ({
  senderId,
  onSelectSender,
  error,
  placeholder,
  readOnly
}: SlackSenderSelectProps) => {
  const navigate = useNavigate();
  const { isMobileLayout } = useResponsive();

  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [optionListOpen, setOptionListOpen] = useState(false);
  const [botModalOpen, setBotModalOpen] = useState(false);
  const [botToEdit, setBotToEdit] = useState<string | undefined>();

  const slackSenderApi = useSlackSenderApi();
  const listSenders = slackSenderApi.list();
  const { data: senders, isLoading: isSendersLoading } = listSenders.query;

  const selectedSender = useMemo(() => {
    if (!senderId || isSendersLoading) return null;
    return senders?.find(({ id }) => id === senderId);
  }, [senderId, senders, isSendersLoading]);

  const botSenderOptions: Option[] = useMemo(() => {
    if (!senders || isSendersLoading) return [];
    const bots = senders.filter(({ isBot }) => isBot);

    const options: Option[] = bots.map(bot => ({
      key: bot.id,
      startAdornment: <SlackBotIcon iconUrl={bot.iconUrl!} />,
      title: bot.name,
      onEdit: () => {
        setBotToEdit(bot.id);
        setBotModalOpen(true);
      }
    }));

    return options;
  }, [senders, isSendersLoading]);

  const userSenderOptions: Option[] = useMemo(() => {
    if (!senders || isSendersLoading) return [];
    const users = senders.filter(({ isBot }) => !isBot);

    const options: Option[] = users.map(user => ({
      key: user.id,
      title: user.name
    }));

    return options;
  }, [senders, isSendersLoading]);

  const [currentTab, setCurrentTab] = useState<"user" | "bot">("user");

  const openCreateBotModal = () => {
    setBotToEdit(undefined);
    setOptionListOpen(false);
    setBotModalOpen(true);
  };

  // --- Animations ---
  const [tabButtonsWrapperRef, tabButtonsWrapperBounds] = useMeasure();
  const [userTabButtonRef, userTabButtonBounds] = useMeasure();
  const [botTabButtonRef, botTabButtonBounds] = useMeasure();

  const immediateRef = useRef(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (immediateRef.current === false || !optionListOpen) return;
      immediateRef.current = false;
    }, 500);
    return () => {
      immediateRef.current = true;
      clearTimeout(timeout);
    };
  }, [optionListOpen, immediateRef]);

  const tabButtonActiveBackgroundSpring = useSpring({
    width: currentTab === "user" ? userTabButtonBounds.width : botTabButtonBounds.width,
    height: currentTab === "user" ? userTabButtonBounds.height : botTabButtonBounds.height,
    left:
      currentTab === "user"
        ? userTabButtonBounds.left - tabButtonsWrapperBounds.left
        : botTabButtonBounds.left - tabButtonsWrapperBounds.left,
    config: { tension: 200 },
    immediate: immediateRef.current
  });

  return (
    <>
      <div ref={anchorRef}>
        {selectedSender ? (
          <ParticipantCard
            title={selectedSender.name}
            description={""} // Ensure sender is not rendered as an attribute
            avatar={
              selectedSender.isBot && selectedSender.iconUrl ? (
                <SlackBotIcon iconUrl={selectedSender.iconUrl!} />
              ) : undefined
            }
            onClick={() => setOptionListOpen(!optionListOpen)}
            onRemove={() => onSelectSender && onSelectSender(undefined)}
            readOnly={readOnly}
          />
        ) : (
          <Layout.Group>
            <IconToggleButton
              active={optionListOpen}
              startIcon="plusSmall"
              onClick={() => setOptionListOpen(!optionListOpen)}
              destructive={!!error}
              disabled={readOnly}
            >
              {placeholder ?? "Add sender"}
            </IconToggleButton>
            {error && <ErrorMessage>Required field</ErrorMessage>}
          </Layout.Group>
        )}
        <Popover
          open={optionListOpen}
          closeOnContentClick={false}
          referenceElement={anchorRef.current}
          onClose={() => setOptionListOpen(false)}
          placement={isMobileLayout ? "bottom-start" : "left-start"}
          style={{ width: "18rem" }}
        >
          <StyledCard>
            <StyledHeaderExample>
              <Title>Sender</Title>
              <Layout.Group
                ref={tabButtonsWrapperRef}
                gap="xSmall"
                style={{ position: "relative" }}
              >
                <TabButton
                  ref={userTabButtonRef}
                  onClick={() => setCurrentTab("user")}
                  $active={currentTab === "user"}
                >
                  <TabButtonTitle variant="bold">User</TabButtonTitle>
                </TabButton>
                <TabButton
                  ref={botTabButtonRef}
                  onClick={() => setCurrentTab("bot")}
                  $active={currentTab === "bot"}
                >
                  <TabButtonTitle variant="bold">Slack bot</TabButtonTitle>
                </TabButton>
                <AnimatedTagButtonBackground style={tabButtonActiveBackgroundSpring} />
              </Layout.Group>
            </StyledHeaderExample>
            <Content>
              {/* USER */}
              {currentTab === "user" && (
                <>
                  {!isEmpty(userSenderOptions) ? (
                    <OptionList
                      variant="blended"
                      selected={senderId}
                      options={userSenderOptions}
                      onItemClick={({ key }) => {
                        onSelectSender && onSelectSender(key);
                        setOptionListOpen(false);
                      }}
                      actions={[
                        {
                          key: "action-connect-slack-user",
                          title: "Connect Slack account",
                          onClick: () => {
                            navigate(getConnectAccountRoute("slack"));
                          },
                          startAdornmentIcon: "plusSmall"
                        }
                      ]}
                    />
                  ) : (
                    <ConnectAccount
                      icon="arrowsLeftRight"
                      title="No Slack accounts connected"
                      buttonText="Connect Slack account"
                      onButtonClick={() => navigate(getConnectAccountRoute("slack"))}
                    />
                  )}
                </>
              )}
              {/* BOT */}
              {currentTab === "bot" && (
                <>
                  {!isEmpty(botSenderOptions) ? (
                    <OptionList
                      variant="blended"
                      selected={senderId}
                      options={botSenderOptions}
                      onItemClick={({ key }) => {
                        onSelectSender && onSelectSender(key);
                        setOptionListOpen(false);
                      }}
                      actions={[
                        {
                          key: "action-add-slack-bot",
                          title: "Add new bot",
                          onClick: openCreateBotModal,
                          startAdornmentIcon: "plusSmall"
                        }
                      ]}
                    />
                  ) : (
                    <ConnectAccount
                      icon="robot"
                      title="No Slack bots created yet"
                      buttonText="Create new bot"
                      onButtonClick={openCreateBotModal}
                    />
                  )}
                </>
              )}
            </Content>
          </StyledCard>
        </Popover>
      </div>
      <SlackBotModalV2
        open={botModalOpen}
        senderId={botToEdit}
        onCreated={async bot => {
          await listSenders.refetch();
          onSelectSender && onSelectSender(bot.id);
        }}
        onUpdated={async () => {
          await listSenders.refetch();
        }}
        onClose={() => setBotModalOpen(false)}
      />
    </>
  );
};

const StyledConnectAccount = styled(Layout.Group).attrs({
  vertical: true,
  gap: "small",
  alignItems: "center"
})`
  padding: var(--spacing-large) 0;
`;

const ConnectAccountIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: var(--rounding-small);
  background-color: var(--palette-surface-default);
`;

const ConnectAccount = ({
  icon,
  title,
  buttonText,
  onButtonClick
}: {
  title: string;
  icon: IconName;
  buttonText: string;
  onButtonClick: () => void;
}) => {
  return (
    <StyledConnectAccount>
      <ConnectAccountIcon>
        <Icon name={icon} />
      </ConnectAccountIcon>
      <Title variant="bold">{title}</Title>
      <TextButton startIcon="plusSmall" colorVariant="primary" onClick={onButtonClick}>
        {buttonText}
      </TextButton>
    </StyledConnectAccount>
  );
};

const SlackBotIcon = ({ iconUrl }: { iconUrl: string }) => (
  <img
    alt="bot icon"
    src={iconUrl}
    style={{
      maxWidth: "24px",
      maxHeight: "24px",
      borderRadius: 6,
      overflow: "hidden",
      width: "100%",
      height: "100%"
    }}
  />
);
