import { IntroistDateTime } from "../../../utils/dates";
import { deleteJson, generateMutation, putJson } from "../ApiBase";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

interface JourneyStepRequest {
  journeyId: string;
  stepId: string;
}

export interface CalendarEventJourneyStep extends JourneyStepV2 {
  eventId?: string;
}

interface UpdateStepContentRequest extends JourneyStepRequest {
  content: object;
}

const updateStepContent = ({
  journeyId,
  stepId,
  content
}: UpdateStepContentRequest): Promise<JourneyStepV2> => {
  return putJson(`/v2/journeys/${journeyId}/steps/${stepId}/content`, content);
};

interface RescheduleStepRequest extends JourneyStepRequest {
  start?: IntroistDateTime;
  end?: IntroistDateTime;
  eventId?: string;
  calendarEventId?: string;
}
const rescheduleStep = ({ journeyId, stepId, ...body }: RescheduleStepRequest) => {
  return putJson(`/v2/journeys/${journeyId}/steps/${stepId}/reschedule`, body);
};

export const cancelStep = ({ journeyId, stepId }: JourneyStepRequest) => {
  return deleteJson(`/v2/journeys/${journeyId}/steps/${stepId}`);
};

export const useJourneyStepApi = () => ({
  update: generateMutation<UpdateStepContentRequest, JourneyStepV2>(updateStepContent),
  reschedule: generateMutation<RescheduleStepRequest, unknown>(rescheduleStep)
});
