import { StepTypeV2, StepTypeWithEventTypeV2, WorkflowCategory } from "services/rpc/RpcProvider";
import { WorkflowStepTypes } from "../../../modules/flows/stepTypes";
import { WorkflowStep } from "../../../modules/flows/hooks/useWorkflowSteps";
import { cloneDeep } from "lodash";

export type TriggerMode = "after-enroll" | "before-targetdate" | "after-targetdate";

export type AddParams = {
  trunkItemId?: string;
  triggerMode?: TriggerMode;
};

type buildInitialStepDataParams = {
  extendedStepType: StepTypeWithEventTypeV2;
  params: AddParams;
  workflowCategory?: WorkflowCategory;
  referenceStep?: WorkflowStep;
};

export const buildInitialStepData = ({
  extendedStepType
}: buildInitialStepDataParams): WorkflowStep => {
  let stepData = {};
  let stepType: StepTypeV2 = extendedStepType as StepTypeV2;

  const typeDef = WorkflowStepTypes.find(type => type.stepType === extendedStepType);

  if (typeDef && typeDef.template) {
    stepData = cloneDeep(typeDef.template);
  } else if (extendedStepType === "common-event") {
    stepType = "event";
    stepData = { eventType: "common", fromTime: "10:00", toTime: "11:00", participants: [] };
  } else if (extendedStepType === "individual-event") {
    stepType = "event";
    stepData = {
      eventType: "individual",
      fromTime: "10:00",
      toTime: "11:00",
      participants: []
    };
  } else if (extendedStepType === "meeting") {
    stepData = {
      fromTime: "10:00",
      toTime: "11:00",
      participants: []
    };
  } else if (extendedStepType === "enroll") {
    stepData = { targetTrigger: { amount: 0, period: "days", side: "on" } };
  } else if (extendedStepType === "webhook") {
    stepType = "webhook";
    stepData = {};
  } else if (extendedStepType === "emailform") {
    stepType = "emailform";
    stepData = {
      form: {
        title: "",
        description: "",
        fields: []
      },
      message: {
        subject: "",
        message: "Hello!<br/><br/>Please fill in the following form: {{link}}</br></br>Thanks!"
      }
    };
  } else if (extendedStepType === "emailtask") {
    stepType = "emailtask";
    stepData = {
      task: {
        confirmText: "Mark done"
      },
      message: {
        subject: "You have a new task assigned: {{task}}",
        message:
          "Hello, <br/><br/>You have a new task assigned: {{task}}<br/><br/>Please confirm that you have completed the task by clicking this {{confirmText}}."
      }
    };
  } else if (extendedStepType === "slacktask") {
    stepType = "slacktask";
    stepData = {
      task: {
        confirmText: "Mark done"
      },
      message: {
        message:
          "Hello, <br/><br/>You have a new task assigned: {{task}}<br/><br/>Check in out and click here to mark it done."
      }
    };
  } else if (extendedStepType === "freshserviceonboarding") {
    stepData = {
      fields: []
    };
  } else if (extendedStepType === "slackquestion") {
    stepData = {
      message: {
        message: ""
      },
      options: []
    };
  } else if (extendedStepType === "workflowtrigger") {
    stepData = {
      startAsDraft: false
    };
  } else if (extendedStepType === "eventattend") {
    stepData = {
      after: "+0d"
    };
  } else if (extendedStepType === "createaccount") {
    stepData = {
      fields: []
    };
  }

  const trigger = {
    amount: 0,
    period: "days",
    side: "immediately",
    time: "10:00"
  };

  if (extendedStepType === "common-event") {
    // @ts-ignore
    trigger.flexStart = trigger.flexStart || { amount: 0, side: "before", period: "days" };
    // @ts-ignore
    trigger.flexEnd = trigger.flexEnd || { amount: 0, side: "after", period: "days" };
  }

  const step = {
    trigger,
    stepType,
    stepData,
    conditionMode: "executeif",
    preconditionSteps: [],
    preconditionAttributes: [],
    active: true,
    allowedWeekdays: [1, 2, 3, 4, 5],
    fallbackDirection: "forward",
    cutoffDays: 30
  } as unknown as WorkflowStep;

  return step;
};
