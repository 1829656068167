import {
  ApiIdentityProvider,
  createIdentityContext,
  IdentityContextDef
} from "@introist/react-foundation/v2";
import { ComponentProps, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { config } from "./Config";
import { waitForInitialIDToken } from "./services/firebase";
import { loadUser } from "./services/api/UserApi";

export enum UserRole {
  User = "user",
  Admin = "admin",
  Owner = "owner",
  SuperAdmin = "superadmin"
}

export const UserLevel = {
  user: 0,
  admin: 1,
  owner: 2,
  superadmin: 3
};

export const isAuthorizedAs = (user: IntroistUser, role: UserRole) => {
  const userLevel = UserLevel[user.role];
  const roleLevel = UserLevel[role];
  return userLevel >= roleLevel;
};

export const useWorkspace = () => {
  const { user } = useIdentity();
  return user.workspace;
};

export const useHasRole = (role: UserRole) => {
  const { user } = useIdentity();
  return isAuthorizedAs(user, role);
};

export interface IntroistUser {
  id: string;
  email: string;
  featureFlags?: string[];
  role: UserRole;
  slackWorkspaceName?: string;
  workspace?: {
    id: string;
    name: string;
    logo?: string | null;
    plan: "basic" | "full";
  };
}

type InstroistContextDef = IdentityContextDef<IntroistUser>;
const IntroistIdentityContext = createIdentityContext<InstroistContextDef>();
export const useIdentity = () => useContext(IntroistIdentityContext)!;

export const Identified = (props: ComponentProps<"div">) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const getToken = () => waitForInitialIDToken().then(token => `Bearer ${token}`);

  return (
    <ApiIdentityProvider
      IdentityContext={IntroistIdentityContext}
      loadIdentity={() => {
        return getToken()
          .then(loadUser)
          .catch(err => {
            if (err.code === 404) {
              navigate("/onboarding");
              return null;
            }
            if (err.code === 403) {
              window.location.href = "/login#sso";
              throw err;
            } else throw err;
          });
      }}
      identityEndpoint={`${config.apiUrl}/v1/user`}
      getToken={getToken}
      onAccessDenied={status => {
        if (status === 404) navigate("/noaccount");
        if (status === 403) navigate("/login/sso");
        else navigate("/login");
      }}
      onUserLoaded={async (user: IntroistUser | null) => {
        await queryClient.invalidateQueries();
        if (!user) {
          navigate("/onboarding");
          return null;
        } else {
          return user;
        }
      }}
    >
      {props.children}
    </ApiIdentityProvider>
  );
};
