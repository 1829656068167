import { useMemo, useState } from "react";
import styled from "styled-components";
import {
  Drawer,
  Layout,
  H4,
  useToast,
  useActions,
  Title,
  CircularLoader,
  IconName
} from "@introist/react-foundation/v2";

import { useIntegrationsApi } from "services/api/IntegrationsApi";

import { useHasRole, UserRole } from "IdentityProvider";
import { HrisEmptyState, ConnectedHrisCard } from "modules/hris/components";
import { api } from "services/rpc/RpcProvider";

import { IntegrationEditor } from "../IntegrationEditor";
import { IntegrationsGrid } from "../../components";
import { Integration } from "../..";

const StyledDrawer = styled(Drawer)`
  padding: var(--spacing-xxLarge) var(--spacing-xLarge);
`;

export const IntegrationsContainer = () => {
  const isOwner = useHasRole(UserRole.Owner);
  const toast = useToast();
  const { onConfirmAction } = useActions();

  const disconnectHris = api.hris.disconnect.useMutation();

  const [activeIntegration, setActiveIntegration] = useState<Integration>();
  const [open, setOpen] = useState<boolean>(false);
  const integrationApi = useIntegrationsApi();
  const { data, isLoading: integrationsLoading } = integrationApi.list().query;

  const { data: hris, refetch: reloadHris, isLoading: hrisLoading } = api.hris.details.useQuery({});

  const ats = useMemo(() => {
    return (data ?? []).filter(i => (i.categories ?? []).includes("ATS"));
  }, [data]);
  const other = useMemo(() => {
    return (data ?? []).filter(i => (i.categories ?? []).includes("other"));
  }, [data]);

  const startHrisDisconnect = onConfirmAction(
    async () => {
      return disconnectHris
        .mutateAsync({})
        .then(async () => {
          await reloadHris();
          toast.info("HRIS disconnected");
        })
        .catch(() => toast.error("Failed to disconnect HRIS"));
    },
    {
      title: "Disconnect HRIS",
      description: `Are you sure you want to disconnect ${hris?.name}? Employees will no longer be synced or imported.`
    }
  );

  const hrisActions = useMemo(() => {
    if (isOwner) {
      return [
        {
          key: "disconnect",
          title: "Disconnect",
          onClick: startHrisDisconnect,
          startAdornmentIcon: "trash" as IconName
        }
      ];
    }

    return [];
  }, [isOwner, startHrisDisconnect]);

  if (integrationsLoading || hrisLoading) {
    return <CircularLoader fillParent style={{ flex: 1 }} />;
  }

  return (
    <Layout.Group vertical gap="xxLarge">
      <Layout.Group vertical gap="xLarge">
        <Layout.Group vertical gap="xSmall">
          <H4>HRIS</H4>
          <Title>Trigger workflows in response to real-time events in your HR data</Title>
        </Layout.Group>
        {hris ? (
          <ConnectedHrisCard hris={hris} showConnected actions={hrisActions} />
        ) : (
          <HrisEmptyState onConnected={reloadHris} />
        )}
      </Layout.Group>

      <IntegrationsGrid
        title="ATS"
        description='Automatically start workflows when the candidate status is "Hired"'
        integrations={ats}
        onIntegrationClick={integration => {
          setActiveIntegration(integration);
          setOpen(true);
        }}
      />

      <IntegrationsGrid
        title="Productivity"
        description="Boost your productivity by automating tasks like ticketing and account setup"
        integrations={other}
        onIntegrationClick={integration => {
          setActiveIntegration(integration);
          setOpen(true);
        }}
      />

      <StyledDrawer open={open} onClose={() => setOpen(false)} width="540px">
        {activeIntegration && (
          <IntegrationEditor integrationId={activeIntegration?.id} onClose={() => setOpen(false)} />
        )}
      </StyledDrawer>
    </Layout.Group>
  );
};
