import { Icon, IconName, useTheme } from "@introist/react-foundation/v2";

type StatusIconProps = {
  status: string;
  active?: boolean;
};

export const StatusIcon = ({ status, active, ...rest }: StatusIconProps) => {
  const { theme } = useTheme();

  const defaultColor = theme.palette.foreground.subdued;

  const getIconByStatus = (status: string): { name: IconName | null; color: string | null } => {
    switch (status) {
      case "failing":
        return { name: "warning", color: active ? theme.palette.danger.default : defaultColor };
      case "scheduled":
        return { name: "time", color: active ? theme.palette.primary.default : defaultColor };
      case "draft":
        return {
          name: "progress",
          color: active ? theme.palette.warning.default : defaultColor
        };
      case "completed":
        return {
          name: "checkCircle",
          color: active ? theme.palette.success.default : defaultColor
        };
      case "canceled":
        return {
          name: "crossCircle",
          color: active ? theme.palette.foreground.default : defaultColor
        };
      default:
        return {
          name: null,
          color: null
        };
    }
  };

  const { name, color } = getIconByStatus(status);

  if (!name) return null;

  return <Icon {...rest} name={name} color={color || undefined} />;
};
