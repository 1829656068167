import styled from "styled-components";
import { Card, H4, Icon, IconName, Stack, Title, useTheme } from "@introist/react-foundation/v2";

import { ReactNode } from "react";

type NoResultsProps = {
  title?: ReactNode;
  message?: string | ReactNode;
  icon?: IconName;
  iconSizeRem?: number;
  small?: boolean;
  children?: ReactNode;
  card?: boolean;
};

const StyledNoResults = styled(Stack)`
  flex: 1;
  user-select: none;
  padding: var(--spacing-xxLarge);
`;

const StyledTitle = styled(Title)`
  text-align: center;
`;

const StyledCard = styled(Card)`
  border-color: transparent;
  padding: 0;
  background-color: var(--palette-surface-subdued);
`;

export const NoResults = ({
  title = "No results found",
  icon = "search",
  small,
  iconSizeRem = 2,
  card,
  children,
  message = "We couldn't find any items matching your search",
  ...rest
}: NoResultsProps) => {
  const { theme } = useTheme();

  const getContent = () => (
    <StyledNoResults vertical justifyContent="center" alignItems="center" gap="xLarge">
      <Icon
        name={icon}
        color={theme.palette.foreground.dimmed}
        style={{ width: `${iconSizeRem}rem`, height: `${iconSizeRem}rem` }}
      />
      <Stack vertical gap="xxLarge" alignItems="center">
        <Stack vertical alignItems="center" gap="large">
          {typeof title === "string" ? <H4>{title}</H4> : title}{" "}
          {typeof message === "string" ? <StyledTitle>{message}</StyledTitle> : message}
        </Stack>
        {children}
      </Stack>
    </StyledNoResults>
  );

  if (card) {
    return (
      <StyledCard {...rest}>
        <StyledNoResults vertical justifyContent="center" alignItems="center" gap="xLarge">
          {getContent()}
        </StyledNoResults>
      </StyledCard>
    );
  }

  return (
    <StyledNoResults {...rest} vertical justifyContent="center" alignItems="center" gap="xLarge">
      {getContent()}
    </StyledNoResults>
  );
};
