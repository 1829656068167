import { StepEditorProps } from "../useStepEditor";
import { prefixForm } from "../../../../utils/FormUtils";
import { Block } from "../../../../components/atoms";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Select, SelectProps, Stack, Title, Toggle } from "@introist/react-foundation/v2";
import { useMemo } from "react";
import styled from "styled-components";
import { InfoTooltip } from "../../../../foundation";

const Setting = styled(Stack).attrs({ justifyContent: "space-between", alignItems: "center" })`
  // Toggle
  > :last-child {
    flex-shrink: 0;
  }
`;

export const MeetingSettings = ({ form, stepDataPath }: StepEditorProps) => {
  const guestSettingOptions = { null: "Use Calendar default", true: "Allow", false: "Deny" };

  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <Block compact blended>
      <StepEditorAccordion title="Settings" defaultOpen={false}>
        <Setting key="attendee-decline">
          <Stack gap="small">
            <Title>When important attendee declines</Title>
            <InfoTooltip tooltip="Control what happens if attendee marked important declines the invitation." />
          </Stack>
          <Stack style={{ width: "165px" }}>
            <Select
              element="button"
              variant="blended"
              size="small"
              attachToRef={false}
              options={[
                { key: "fail", title: "Mark failing" },
                { key: "none", title: "Do nothing" }
              ]}
              value={prefixedForm.get("attendeeDeclineAction")}
              onSelect={opt => prefixedForm.set("attendeeDeclineAction")(opt.key)}
              style={{ width: "100%" }}
            />
          </Stack>
        </Setting>
        <Setting key="sync-content">
          <Stack gap="small">
            <Title>Keep content in sync</Title>
            <InfoTooltip tooltip="When employees fields change, update meeting title and description in calendar. Notice that this will override any manual changes made in the calendar event." />
          </Stack>
          <Stack style={{ width: "155px" }}>
            <Toggle
              checked={prefixedForm.get("syncContent")}
              onChange={prefixedForm.set("syncContent")}
            />
          </Stack>
        </Setting>
        <Setting key="sync-attendees">
          <Stack gap="small">
            <Title>Keep attendees in sync</Title>
            <InfoTooltip tooltip="When employees fields change, update meeting attendees in calendar. Notice that this will override any manual changes made in the calendar event." />
          </Stack>

          <Stack style={{ width: "155px" }}>
            <Toggle
              checked={prefixedForm.get("syncAttendees")}
              onChange={prefixedForm.set("syncAttendees")}
            />
          </Stack>
        </Setting>
        <Setting key={`step-editor-event-setting-guests-can-see`}>
          <Title>Guests can see guest list</Title>
          <TriStateBooleanSelect
            size="small"
            element="button"
            variant="blended"
            labels={guestSettingOptions}
            value={prefixedForm.get(`guestsCanSeeGuestList`)}
            onSelect={prefixedForm.set(`guestsCanSeeGuestList`)}
          />
        </Setting>
        <Setting key={`step-editor-event-setting-guests-can-invite`}>
          <Title>Guests can invite others</Title>
          <TriStateBooleanSelect
            size="small"
            element="button"
            variant="blended"
            labels={guestSettingOptions}
            value={prefixedForm.get(`guestsCanInviteOthers`)}
            onSelect={prefixedForm.set(`guestsCanInviteOthers`)}
          />
        </Setting>
        <Setting key={`step-editor-event-setting-guests-can-modify`}>
          <Title>Guests can modify event</Title>
          <TriStateBooleanSelect
            size="small"
            element="button"
            variant="blended"
            labels={guestSettingOptions}
            value={prefixedForm.get(`guestsCanModifyEvent`)}
            onSelect={prefixedForm.set(`guestsCanModifyEvent`)}
          />
        </Setting>
        <Setting key="private event">
          <Stack gap="small">
            <Title>Private Event</Title>
            <InfoTooltip tooltip="Create this event as private so only guests can see summary and description of the event." />
          </Stack>

          <Stack style={{ width: "155px" }}>
            <Toggle
              checked={prefixedForm.get("privateEvent")}
              onChange={prefixedForm.set("privateEvent")}
            />
          </Stack>
        </Setting>
      </StepEditorAccordion>
    </Block>
  );
};

type TriStateBooleanOptions = {
  null: string;
  true: string;
  false: string;
};

type TriStateBooleanSelectProps = Omit<SelectProps, "options" | "onSelect"> & {
  labels: TriStateBooleanOptions;
  onSelect: (value: boolean | null) => void;
};

const TriStateBooleanSelect = ({
  labels,
  value,
  onSelect,
  ...props
}: TriStateBooleanSelectProps) => {
  const options = useMemo(() => {
    return Object.entries(labels).map(([key, title]) => ({ key, title }));
  }, [labels]);

  const innerValue = useMemo(() => {
    if (value === null || value === undefined || value === "") return "null";
    if (value === "true") return "true";
    if (value === "false") return "false";
    return value ? "true" : "false";
  }, [value]);

  return (
    <Select
      {...props}
      attachToRef={false}
      options={options}
      value={innerValue}
      onSelect={opt => {
        if (opt.key === "null") return onSelect(null);
        if (opt.key === "true") return onSelect(true);
        if (opt.key === "false") return onSelect(false);
      }}
    />
  );
};
