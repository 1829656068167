import styled from "styled-components";
import {
  ActionProvider as ActionProviderV2,
  AppMenuContextProvider,
  H3,
  Layout,
  P,
  SidebarAppLayout,
  ThemeProvider as ThemeProviderV2,
  ToastProvider as ToastProviderV2,
  useResponsive
} from "@introist/react-foundation/v2";
import { BrowserRouter as Router, Route, Routes, Outlet } from "react-router-dom";

import { Identified } from "IdentityProvider";
import { InfoBunnyCard } from "components/molecules";
import { RpcProvider } from "services/rpc/RpcProvider";

import { NewAppRoute, getPublicRoutes, getPrivateRoutes, RootRoute } from "../../AppRoutes";

import { AppMenu } from "routes/App/AppMenu";
import { MultiCommander, MultiCommanderAnnouncementModal } from "components/organisms";

const MobileView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  background-color: var(--palette-surface-subdued);
  padding: var(--spacing-large);
  box-sizing: border-box;

  > :first-child {
    box-sizing: border-box;
    padding: var(--spacing-xLarge) var(--spacing-xxLarge);
    max-width: 496px;
    text-align: center;

    p {
      color: var(--palette-foreground-subdued);
    }
  }
`;

export const AppArea = ({ fullscreen }: { fullscreen?: boolean }) => {
  const { isMobileLayout } = useResponsive();

  if (isMobileLayout) {
    return (
      <MobileView>
        <InfoBunnyCard>
          <Layout.Group vertical gap="xLarge">
            <H3>Pardon Our Pixels!</H3>
            <P>
              We're busy perfecting our mobile site. For now, please enjoy our desktop version for
              the best experience.
            </P>
          </Layout.Group>
        </InfoBunnyCard>
      </MobileView>
    );
  }

  if (fullscreen) {
    return <Outlet />;
  }

  return (
    <SidebarAppLayout appMenu={<AppMenu />} breakpoint="50px">
      <Outlet /> {/* This Outlet is key for nested routing */}
      <MultiCommander />
      <MultiCommanderAnnouncementModal />
    </SidebarAppLayout>
  );
};

const PrivateRoutes = ({ fullscreen }: { fullscreen?: boolean }) => {
  return (
    <Identified>
      <AppMenuContextProvider localStorageKey="intr.am.c">
        <AppArea fullscreen={fullscreen} />
      </AppMenuContextProvider>
    </Identified>
  );
};

const renderRoutes = (routes: NewAppRoute[]): React.ReactElement[] => {
  return routes.map(route => (
    <Route key={route.key} path={route.path} element={route.element}>
      {route.children && renderRoutes(route.children)}
    </Route>
  ));
};

export const Application = () => {
  const privateRoutes = getPrivateRoutes();
  const publicRoutes = getPublicRoutes();
  const privateFullscreenRoutes = privateRoutes.filter(route => route.fullscreen);
  const privateSidebarRoutes = privateRoutes.filter(route => !route.fullscreen);

  return (
    <RpcProvider>
      <ThemeProviderV2>
        <ToastProviderV2 defaultPlacement="bottom-center">
          <ActionProviderV2 defaultErrorMessage="Something failed!">
            <Router>
              <Routes>
                {/* PRIVATE FULLSCREEN ROUTES */}
                <Route element={<PrivateRoutes fullscreen />}>
                  {renderRoutes(privateFullscreenRoutes)}
                </Route>
                {/* PRIVATE ROUTES */}
                <Route element={<PrivateRoutes />}>
                  <Route index element={<RootRoute />} />
                  {renderRoutes(privateSidebarRoutes)}
                </Route>
                {/* PUBLIC ROUTES */}
                {renderRoutes(publicRoutes)}
              </Routes>
            </Router>
          </ActionProviderV2>
        </ToastProviderV2>
      </ThemeProviderV2>
    </RpcProvider>
  );
};
