import { ActionMenu, Field, Input, Option, Stack, Card } from "@introist/react-foundation/v2";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useState } from "react";
import { FittedTitle } from "components/atoms";
import { Skeletons } from "../../../components/molecules";
import { UpsertFormProps, UpsertModal } from "../../../foundation";
import { FolderIcon } from "../../../assets";

type Folder = {
  id: string;
  name: string;
  parentId: string | null;
};

type Props = {
  parentId: string | null;
  folders?: Folder[];
  folderActions?: Option[];
  onCreate: (entry: Partial<Folder>) => Promise<unknown>;
  onEdit?: (entry: Folder) => Promise<unknown>;
  onDelete?: (id: string) => void;
  onClose?: () => void;
  createOpen?: boolean;
  rootPath: string;
};

const StyledFoldersGrid = styled.div`
  display: grid;
  grid-gap: var(--spacing-small);
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const FolderGrid = ({
  parentId,
  folders,
  onCreate,
  onEdit,
  onDelete,
  createOpen,
  onClose,
  rootPath
}: Props) => {
  const [editOpen, setEditOpen] = useState(false);
  const [editFolder, setEditFolder] = useState<Folder | undefined>();

  const buildFolderActions = (folder: Folder) => {
    const actions: Option[] = [];

    if (onEdit) {
      actions.push({
        key: "edit",
        title: "Edit",
        startAdornmentIcon: "pencil",
        onClick: () => {
          setEditFolder(folder);
          setEditOpen(true);
        }
      });
    }

    if (onDelete) {
      actions.push({
        key: "delete",
        title: "Delete",
        startAdornmentIcon: "trash",
        onClick: () => onDelete(folder.id)
      });
    }

    return actions;
  };

  if (folders === undefined) return <Skeletons.Grid height={100} width={300} count={4} />;

  return (
    <StyledFoldersGrid>
      {(folders || []).map(f => (
        <FolderCard key={f.id} folder={f} actions={buildFolderActions(f)} rootPath={rootPath} />
      ))}
      <UpsertModal
        open={editOpen || !!createOpen}
        onClose={() => {
          setEditOpen(false);
          onClose && onClose();
        }}
        template={{ parentId }}
        onCreate={onCreate}
        entry={editFolder}
        onUpdate={onEdit ? onEdit : () => Promise.resolve()}
        Form={FolderForm}
        entryName="folder"
      />
    </StyledFoldersGrid>
  );
};

const FolderCard = ({
  folder,
  actions,
  rootPath
}: {
  folder: Folder;
  actions: Option[];
  rootPath: string;
}) => (
  <StyledLink to={`${rootPath}/folder/${folder.id}`}>
    <Card>
      <Stack vertical gap="xSmall">
        <Stack justifyContent="space-between">
          <Stack gap="small">
            <FolderIcon width="16px" />
          </Stack>
          <div onClick={e => e.preventDefault()}>
            <ActionMenu options={actions} />
          </div>
        </Stack>
        <FittedTitle showTooltip maxLength={24} variant="bold">
          {folder.name}
        </FittedTitle>
      </Stack>
    </Card>
  </StyledLink>
);

export const FolderForm = ({ value, onChange }: UpsertFormProps<Folder>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.name} onChange={name => onChange({ ...value, name })} autoFocus />
      </Field>
    </Stack>
  );
};
