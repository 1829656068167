import styled from "styled-components";

export const IntegrationIcon = styled.div<{ iconUrl: string }>`
  width: 20px;
  height: 20px;
  background-image: ${props => `url(${props.iconUrl})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--rounding-small);
`;
