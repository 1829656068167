import { ReactNode } from "react";
import { Card, IPopoverProps, Popover, useResponsive } from "@introist/react-foundation/v2";

type WidthPreset = "narrow" | "medium" | "wide" | "ultraWide";

type CardPopoverProps = IPopoverProps & {
  widthPreset?: WidthPreset;
  children: ReactNode;
  fullWidth?: boolean;
  withoutContentPadding?: boolean;
};

const CARD_POPOVER_WIDTH_PX_MAP: Record<WidthPreset, number> = {
  medium: 288,
  narrow: 192 + 16,
  wide: 320,
  ultraWide: 400
};

export const CardPopover = ({
  children,
  placement: placementProp,
  fullWidth,
  widthPreset = "medium",
  withoutContentPadding,
  ...rest
}: CardPopoverProps) => {
  const { isMobileLayout } = useResponsive();

  const placement = placementProp ? placementProp : isMobileLayout ? "bottom" : "left-start";

  return (
    <Popover
      {...rest}
      closeOnContentClick={false}
      placement={placement}
      style={{
        width: fullWidth ? "100%" : "auto",
        maxWidth: CARD_POPOVER_WIDTH_PX_MAP[widthPreset]
      }}
    >
      <Card
        elevated
        style={{
          width: fullWidth ? "100%" : "auto",
          maxWidth: CARD_POPOVER_WIDTH_PX_MAP[widthPreset],
          padding: withoutContentPadding ? 0 : undefined,
          ...rest.style
        }}
      >
        {children}
      </Card>
    </Popover>
  );
};
