import { ReactNode } from "react";
import styled, { css } from "styled-components";
import {
  Icon,
  IconButton,
  Title,
  useTheme,
  useResponsive,
  Layout
} from "@introist/react-foundation/v2";

import { FittedTitle } from "components/atoms";
import { isEmail } from "utils/string-templater";
import { EventAttendeeStatus } from "services/api/types";
import { ImportantGuestButton } from "modules/workflows/routes/WorkflowEditor/StepEditor/EventStepEditor/components/GuestsSelect/components/ImportantGuestButton";
import { JourneyEventStepEditorProps } from "modules/workflows/routes/WorkflowEditor/StepEditor/EventStepEditor";
import {
  AttendeeStatusAvatar,
  AttendeeStatusTag
} from "../../../journeys/components/AttendeeStatusAvatar";

type ParticipantCardProps = Pick<JourneyEventStepEditorProps, "isJourneyEditor"> & {
  title: string;
  icon?: ReactNode;
  description?: string;
  maxLength?: number;
  attendeeStatus?: EventAttendeeStatus;
  avatar?: ReactNode;
  isImportantGuest?: boolean;
  endAdornment?: ReactNode;
  readOnly?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
};

const StyledParticipantCard = styled.div<{
  $hasDescription?: boolean;
  $readOnly?: boolean;
  $error?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-small);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  ${({ $hasDescription }) =>
    $hasDescription &&
    css`
      padding: 0.25rem var(--spacing-small);
    `}

  ${({ $readOnly }) =>
    !$readOnly &&
    css`
      :hover {
        border-color: var(--palette-border-subdued);
        cursor: pointer;

        .remove-btn {
          opacity: 1;
        }
      }
    `}

    ${({ $error }) =>
    $error &&
    css`
      border-color: var(--palette-danger-default);
    `}
`;

export const AvatarBackground = styled.span<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--rounding-small);
  background: ${({ background }) => background};
`;

const RemoveButton = styled(IconButton)<{ $isMobile: boolean }>`
  opacity: 0;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      opacity: 1;
    `}
`;

export const ParticipantCard = ({
  avatar,
  title,
  attendeeStatus,
  description,
  maxLength = 30,
  readOnly,
  isJourneyEditor,
  isImportantGuest,
  endAdornment,
  icon: iconProp,
  onRemove,
  onClick
}: ParticipantCardProps) => {
  const { theme } = useTheme();
  const { isMobileLayout } = useResponsive();

  const isCustomEmail = !!isEmail(title);
  const isChannel = description === "channel";
  const isPrivateChannel = description === "privateChannel";
  const isAttribute = description === undefined && !isCustomEmail;
  const attributeMissing = isJourneyEditor && !description;

  const backgroundColor = isAttribute
    ? theme.palette.purple.ghosted
    : theme.palette.primary.ghosted;

  const showDescription = !!description && !isCustomEmail && !isChannel && !isPrivateChannel;

  const attributeMissingIcon = attributeMissing && (
    <AvatarBackground background={theme.palette.danger.ghosted}>
      <Icon name="warning" color={theme.palette.danger.default} />
    </AvatarBackground>
  );

  const attendeeStatusIcon = attendeeStatus && <AttendeeStatusAvatar status={attendeeStatus} />;
  const icon = iconProp || attributeMissingIcon || attendeeStatusIcon || undefined;

  const importantGuestDeclined = isImportantGuest && attendeeStatus === "declined";
  const showErrorState = attributeMissing || importantGuestDeclined;

  return (
    <StyledParticipantCard
      onClick={() => onClick && !readOnly && onClick()}
      $hasDescription={!!description && !isCustomEmail && !isChannel}
      $readOnly={readOnly}
      $error={showErrorState}
    >
      <Layout.Group justifyContent="space-between" style={{ flex: 1 }}>
        <Layout.Group>
          {icon ? (
            icon
          ) : (
            <AvatarBackground background={avatar ? "none" : backgroundColor}>
              {avatar ? (
                avatar
              ) : (
                <>
                  {isAttribute && (
                    <Icon
                      style={{
                        width: 14
                      }}
                      name="attribute"
                      color={theme.palette.purple.default}
                    />
                  )}
                  {isChannel && (
                    <Icon
                      style={{
                        width: 14
                      }}
                      name="hashtag"
                      color={theme.palette.primary.default}
                    />
                  )}
                  {isPrivateChannel && (
                    <Icon
                      style={{
                        width: 14
                      }}
                      name="lock"
                      color={theme.palette.primary.default}
                    />
                  )}
                  {!isAttribute && !isChannel && !isPrivateChannel && !icon && (
                    <Title color={theme.palette.primary.default} variant="bold">
                      {title?.substring(0, 1).toUpperCase()}
                    </Title>
                  )}
                </>
              )}
            </AvatarBackground>
          )}

          <div>
            <Layout.Group gap="xSmall">
              <FittedTitle maxLength={maxLength} variant="bold" showTooltip>
                {title}
              </FittedTitle>
              {isImportantGuest && readOnly && <ImportantGuestButton selected readOnly />}
            </Layout.Group>
            {showDescription && (
              <FittedTitle maxLength={maxLength} small showTooltip>
                {description || ""}
              </FittedTitle>
            )}
            {attributeMissing && (
              <FittedTitle maxLength={maxLength} small showTooltip>
                Missing property
              </FittedTitle>
            )}
          </div>
        </Layout.Group>
      </Layout.Group>
      {attendeeStatus && <AttendeeStatusTag status={attendeeStatus} />}

      {endAdornment && <div style={{ marginLeft: "auto" }}>{endAdornment}</div>}
      {onRemove && !readOnly && (
        <RemoveButton
          $isMobile={isMobileLayout}
          className="remove-btn"
          icon="crossSmall"
          onClick={event => {
            event.stopPropagation(); // Prevent StyledParticipantCard's onClick from running
            onRemove();
          }}
        />
      )}
    </StyledParticipantCard>
  );
};
