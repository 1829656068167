import { CircularLoader } from "@introist/react-foundation/v2";
import { PanelLayout, TopBarRoute } from "../../../../components/templates";
import { useNavigate, useParams } from "react-router";
import { api } from "../../../../services/rpc/RpcProvider";
import { DataSourceMappingView } from "../../views/DataSourceMappingView/DataSourceMappingView";
import { DataSourceTest } from "../../containers/DataSourceTest/DataSourceTest";

export const DataSourceRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: dataSource } = api.employees.dataSources.find.useQuery({ dataSourceId: id! });

  if (!dataSource) return <CircularLoader fillParent />;

  return (
    <TopBarRoute
      paths={[
        {
          key: "datasources",
          title: "Data Sources",
          onClick: () => navigate("/datasources")
        },
        {
          key: dataSource.id,
          title: dataSource.name
        }
      ]}
      withoutContentPadding
    >
      <PanelLayout.View>
        <PanelLayout.Grid>
          <PanelLayout.Content>
            <DataSourceMappingView dataSourceId={dataSource.id} />
          </PanelLayout.Content>
          <PanelLayout.Panel>
            <DataSourceTest dataSourceId={dataSource.id} />
          </PanelLayout.Panel>
        </PanelLayout.Grid>
      </PanelLayout.View>
    </TopBarRoute>
  );
};
