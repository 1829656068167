import {
  ActionMenu,
  H4,
  SkeletonLoader,
  Stack,
  Title,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { IconPickerIcon } from "components/organisms";
import { api, Trigger } from "services/rpc/RpcProvider";
import { TriggerStatusCard } from "./TriggerStatusCard";
import { PanelLayoutV2 } from "components/templates/PanelLayout";
import { TriggerEmployeeGroup } from "./TriggerEmployeeGroup";
import { TriggerRules } from "./TriggerRules";
import { TriggerWorkflow } from "./TriggerWorkflow";
import { TriggerConfig } from "./TriggerConfig";
import { useCallback, useState } from "react";
import { TriggerSettings } from "./TriggerSettings";
import { useNavigate } from "react-router";

type Props = {
  trigger?: Trigger;
  loading: boolean;
};
export const TriggerPanel = ({ trigger, loading }: Props) => {
  const navigate = useNavigate();
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const apiUtils = api.useContext();
  const evaluate = api.triggers.evaluate.useMutation();
  const archive = api.triggers.archive.useMutation();

  const [edit, setEdit] = useState(false);

  const onEvaluate = useCallback(async () => {
    await evaluate
      .mutateAsync({ id: trigger!.id })
      .then(() => {
        toast.success("Evaluation started. It might take a minute or two to complete.");
      })
      .catch(() => {
        toast.error("Failed to start evaluation");
      });
  }, [evaluate, toast, trigger]);

  const onArchive = onConfirmAction(
    async () => {
      await archive
        .mutateAsync({ id: trigger!.id })
        .then(async () => {
          await apiUtils.triggers.list.invalidate();
          toast.success("Trigger archived");
          navigate("/triggers");
        })
        .catch(() => {
          toast.error("Failed to archive trigger");
        });
    },
    {
      title: "Archive trigger",
      description: "Are you sure you want to archive this trigger?",
      confirmTitle: "Archive"
    }
  );

  if (loading) {
    return <TriggerPanelSkeletonLoader />;
  }

  if (!trigger) {
    return null;
  }

  return (
    <Stack vertical gap="xLarge">
      {edit && <TriggerSettings trigger={trigger} onClose={() => setEdit(false)} />}
      {!edit && (
        <>
          <Stack vertical>
            <Stack justifyContent="space-between">
              <Stack>
                <IconPickerIcon icon={trigger.icon} fallbackIcon="trigger" color={trigger.color} />

                <H4>{trigger.name}</H4>
              </Stack>
              <ActionMenu
                options={[
                  {
                    key: "edit",
                    title: "Edit trigger",
                    onClick: () => setEdit(true),
                    startAdornmentIcon: "pencil"
                  },
                  {
                    key: "evaluate",
                    title: "Evaluate now",
                    onClick: onEvaluate,
                    startAdornmentIcon: "collapseDrawer"
                  },
                  {
                    key: "remove",
                    title: "Archive trigger",
                    onClick: onArchive,
                    startAdornmentIcon: "trash"
                  }
                ]}
              />
            </Stack>
            {trigger.description && <Title>{trigger.description}</Title>}
            <TriggerStatusCard trigger={trigger} />
          </Stack>
          <TriggerEmployeeGroup trigger={trigger} />
          <TriggerRules trigger={trigger} />
          <TriggerWorkflow trigger={trigger} />
          <TriggerConfig trigger={trigger} />
        </>
      )}
    </Stack>
  );
};

const TriggerPanelSkeletonLoader = () => (
  <Stack vertical>
    <Stack justifyContent="space-between">
      <Stack gap="small">
        <SkeletonLoader width={33} height={33} />
        <SkeletonLoader element="h4" />
      </Stack>
      <SkeletonLoader width={30} height={30} />
    </Stack>
    <PanelLayoutV2.PanelDivider />
    <Stack vertical gap="small">
      <SkeletonLoader height={128} />
      <SkeletonLoader height={128} />
      <SkeletonLoader fadeOut height={128} />
    </Stack>
  </Stack>
);
