import { IntroistLoader, useToast } from "@introist/react-foundation/v2";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { verifyFirebaseLinkLogin } from "../services/firebase";

export const AuthLink = () => {
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    verifyFirebaseLinkLogin()
      .then(() => {
        window.location.href = "/";
      })
      .catch(err => {
        const message = err === "invalid-link" ? "Link expired" : "Login failed";
        toast.error(message);

        navigate("/login", { replace: true });
      });
  }, [toast, navigate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh"
      }}
    >
      <IntroistLoader />
    </div>
  );
};
