import { BatchAction } from "../../useBatches";
import { Modal, Tag, Title } from "@introist/react-foundation/v2";
import { ListView } from "../../../../foundation";
import { ListRow } from "../../../../components/organisms";
import { formatDateTime } from "../../../../utils/DatesUtils";

export const EmployeeActionsModal = ({
  actions,
  open,
  onClose
}: {
  actions?: BatchAction[];
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal title="Actions" open={open} onClose={onClose} style={{ width: "450px" }}>
      <ListView.List
        data={actions}
        renderRow={action => (
          <ListRow gridTemplateColumns="1fr 1fr auto">
            <Title>{formatDateTime(action.createdAt)}</Title>
            <Title variant="bold">{action.type}</Title>
            {!action.status && <Tag>Pending</Tag>}
            {action.status === "success" && <Tag colorVariant="success">Success</Tag>}
            {action.status === "failed" && <Tag colorVariant="danger">Failed</Tag>}
            {action.status === "timeout" && <Tag colorVariant="surface">Timeout</Tag>}
          </ListRow>
        )}
      />
    </Modal>
  );
};
