import { Button, IconButton, Input, Stack, Title } from "@introist/react-foundation/v2";
import { useMemo } from "react";

export const PriorityEditor = ({
  value,
  onChange
}: {
  value?: number | null;
  onChange: (value: number | null) => void;
}) => {
  const hasValue = useMemo(() => value !== null && value !== undefined, [value]);

  return (
    <Stack>
      {!hasValue && (
        <Button
          size="small"
          variant="blended"
          onClick={e => {
            onChange(1);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Title>Set priority</Title>
        </Button>
      )}
      {hasValue && (
        <Stack>
          <Input
            size="small"
            type="number"
            value={value ?? 0}
            style={{ width: "165px" }}
            onChange={val => onChange(Number(val))}
            endAdornment={
              <>
                <Title>priority</Title>
                <IconButton
                  icon="crossSmall"
                  style={{ marginLeft: "12px" }}
                  onClick={e => {
                    onChange(null);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </>
            }
          />
        </Stack>
      )}
    </Stack>
  );
};
