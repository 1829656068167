import { ComponentPropsWithoutRef, MouseEvent, useState } from "react";
import { CircularLoader } from "@introist/react-foundation/v2";

export type NakedButtonProps = ComponentPropsWithoutRef<"button"> & {
  loading?: boolean;
  onClickWithLoading?: (e: MouseEvent) => Promise<unknown>;
};

export const NakedButton = ({ children, onClickWithLoading, ...props }: NakedButtonProps) => {
  const [innerLoading, setInnerLoading] = useState(false);

  return (
    <button
      {...props}
      style={{
        border: "none",
        background: "none",
        outline: "none",
        cursor: "pointer",
        ...props.style
      }}
      onClick={async e => {
        if (onClickWithLoading) {
          setInnerLoading(true);
          await onClickWithLoading(e).finally(() => setInnerLoading(false));
        } else if (props.onClick) props.onClick(e);
      }}
    >
      {innerLoading ? <CircularLoader size="small" /> : children}
    </button>
  );
};
