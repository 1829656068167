import { Employee, useEmployees } from "../../hooks/useEmployees";
import { SearchInput } from "../../../../components/molecules";
import { Icon, Stack, Title, Tooltip, useTheme } from "@introist/react-foundation/v2";
import { useEmployeeFields } from "../../hooks/useEmployeeFields";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { FieldCategorySelector } from "../../components/FieldCategorySelector";
import { formatDate } from "../../../../utils/DatesUtils";
import { DateFormats } from "../../../../utils/dates";
import { EmployeeProfileField } from "../../components/EmployeeProfileField";

const LimitTitle = styled.div`
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const FieldRow = styled(Stack)`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: transparent;

  :hover {
    border-color: var(--palette-border-dimmed);
  }
`;

export type EmployeeProfileFormProps = {
  employee: Employee;
  onChange: (field: string, value: any) => void;
  errors?: any[];
};

export const EmployeeProfileForm = ({ employee, onChange, errors }: EmployeeProfileFormProps) => {
  const { theme } = useTheme();

  const { fields } = useEmployeeFields();

  const [category, setCategory] = useState("General");
  const [search, setSearch] = useState("");

  const employeeFields = useMemo(() => {
    if (!fields) return [];
    return fields.filter(f => f.type === "employee");
  }, [fields]);

  const updatedAtFields = useMemo(() => {
    if (!fields) return [];
    return fields.filter(f => f.key.endsWith("updatedat"));
  }, [fields]);

  const { employees } = useEmployees({ fields: (employeeFields ?? []).map(f => f.linkingField!) });

  const filteredFields = useMemo(() => {
    if (!employee) return undefined;

    return (fields ?? [])
      .filter(f => (f.category ?? "General") === category)
      .filter(f => !updatedAtFields.map(uf => uf.key).includes(f.key))
      .filter(
        field =>
          field.title.toLowerCase().includes(search.toLowerCase()) ||
          employee[field.key]?.toLowerCase().includes(search.toLowerCase())
      );
  }, [employee, search, fields, category, updatedAtFields]);

  return (
    <Stack vertical>
      <Stack justifyContent="space-between">
        <FieldCategorySelector value={category} onChange={setCategory} />
        <SearchInput value={search} onChange={setSearch} />
      </Stack>
      <Stack vertical gap="none">
        {(filteredFields ?? [])!.map((field, i) => {
          const updatedAtField = updatedAtFields.find(f => f.key === field.key + "updatedat");
          const updatedAt = updatedAtField ? employee[updatedAtField.key] : undefined;

          return (
            <FieldRow
              justifyContent="center"
              style={{
                padding: theme.spacing.large
              }}
            >
              <LimitTitle style={{ width: "30%" }}>
                <Tooltip tooltip={field.title}>
                  <Title>{field.title}</Title>
                </Tooltip>
              </LimitTitle>

              <Stack style={{ flex: 1 }}>
                <EmployeeProfileField
                  field={field}
                  value={employee[field.key] ?? null}
                  onChange={newVal => {
                    onChange(field.key, newVal);
                  }}
                  error={(errors ?? []).find(e => e.attribute === field.key)?.error}
                  employees={employees ?? []}
                />
                <div style={{ width: "20px" }}>
                  {updatedAtField && (
                    <Tooltip
                      tooltip={
                        updatedAt
                          ? `This field was last updated ${formatDate(
                              updatedAt,
                              DateFormats.date.long
                            )}`
                          : "This field has not been updated"
                      }
                    >
                      <Icon name="time" dimmed />
                    </Tooltip>
                  )}
                </div>
              </Stack>
            </FieldRow>
          );
        })}
      </Stack>
    </Stack>
  );
};
