import {
  Button,
  Card,
  IconTag,
  Stack,
  Title,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { Block } from "../../../components/atoms";
import { StepEditorAccordion } from "../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useCallback, useMemo } from "react";
import { api } from "../../../services/rpc/RpcProvider";

export type AutomationStepErrorsProps = {
  stepId: string;
  errors: string[];
  block?: boolean;
};

export const AutomationStepErrors = ({ stepId, errors, block }: AutomationStepErrorsProps) => {
  if (!errors || errors.length === 0) return null;

  const list = (
    <Stack vertical gap="small">
      {errors.map((error, index) => (
        <ErrorCard key={index} stepId={stepId} error={error} />
      ))}
    </Stack>
  );

  if (block) {
    return (
      <Block blended compact>
        <StepEditorAccordion title="Errors">{list}</StepEditorAccordion>
      </Block>
    );
  } else return list;
};

const ErrorCard = ({ error, stepId }: { error: string; stepId: string }) => {
  const toast = useToast();
  const { theme } = useTheme();

  const apiUtils = api.useContext();
  const stepAction = api.automations.steps.action.useMutation();

  const onAction = useCallback(
    (actionKey: string) => async () => {
      await stepAction
        .mutateAsync({ id: stepId, actionKey })
        .then(async () => {
          await apiUtils.automations.steps.find.refetch({ id: stepId });
          await apiUtils.journeys.steps.find.refetch({ stepId });
          toast.success("Action completed");
        })
        .catch(() => toast.error("Action failed"));
    },
    [stepId, stepAction, apiUtils, toast]
  );

  const errorAction = useMemo(() => {
    if (error === "Calendar event is cancelled") {
      return (
        <Button variant="blended" onClickWithLoading={onAction("recreate")}>
          Recreate
        </Button>
      );
    }

    if (error.endsWith("declined the meeting")) {
      return (
        <Button variant="blended" onClickWithLoading={onAction("reschedule")}>
          Reschedule
        </Button>
      );
    }
  }, [error, onAction]);

  return (
    <Card style={{ padding: theme.spacing.small }}>
      <Stack justifyContent="space-between">
        <Stack>
          <IconTag
            inverted
            size="small"
            variant="ghosted"
            name="warning"
            color={theme.palette.danger}
          />
          <Title variant="bold">{error || "Step has been canceled"}</Title>
        </Stack>
        {errorAction}
      </Stack>
    </Card>
  );
};
