import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";

export type Params = RouterInput["automations"]["steps"]["find"];

export type AutomationStep = RouterOutput["automations"]["steps"]["find"];

export const useAutomationStep = (params: Params) => {
  const { data: step, refetch } = api.automations.steps.find.useQuery(params, {
    refetchOnMount: "always"
  });

  return {
    step,
    refetch
  };
};
