import { WorkflowStep } from "../../flows/hooks/useWorkflowSteps";

export const getWorkflowStepTypeWithEventType = (step: WorkflowStep) => {
  if (step.stepType === "event") {
    if ((step.stepData as any)!.eventType === "common") return "common-event";
    else return "individual-event";
  }

  return step.stepType as string;
};
