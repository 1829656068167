import { IconTag, Layout, Title, useTheme } from "@introist/react-foundation/v2";
import { useAttributes } from "../../../../hooks";
import { ReasonCard } from "./JourneyStepStatusBlock";

type PendingAttributesCardProps = {
  pendingAttributes: string[];
};

export const PendingAttributesCard = ({ pendingAttributes }: PendingAttributesCardProps) => {
  const { theme } = useTheme();

  const attributes = useAttributes();
  return (
    <ReasonCard>
      <Layout.Group vertical>
        <Layout.Group>
          <IconTag
            inverted
            size="small"
            variant="ghosted"
            name="targetBefore"
            color={theme.palette.primary}
            style={{ rotate: "90deg" }}
          />
          <Title variant="bold">Waiting for the following attributes to have value</Title>
        </Layout.Group>
        <Title style={{ marginLeft: 36 }}>
          {pendingAttributes
            .map(a => {
              const attribute = attributes.find(attr => attr.variable === a);
              return attribute?.name ?? a;
            })
            .join(", ")}
        </Title>
      </Layout.Group>
    </ReasonCard>
  );
};
