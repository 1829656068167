import {
  Button,
  Card,
  CircularLoader,
  Modal,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { orderBy } from "lodash";
import { useCallback } from "react";
import { useEmployeeFields } from "../hooks/useEmployeeFields";

type ArchivedFieldsModalProps = {
  open: boolean;
  onClose: () => void;
};

export const ArchivedFieldsModal = ({ open, onClose }: ArchivedFieldsModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Archived Fields"
      maxWidth={500}
      style={{ width: "700px" }}
    >
      {open && <ArchivedFields />}
    </Modal>
  );
};

const ArchivedFields = () => {
  const toast = useToast();

  const { fields, update, refetch } = useEmployeeFields({ archived: true });

  const apiUtils = api.useContext();
  const refetchActive = apiUtils.employees.v4.fields.list.refetch;

  const unarchive = useCallback(
    async (fieldId: string) => {
      return update({ id: fieldId, updates: { archived: false } })
        .then(async () => {
          await Promise.all([refetch(), refetchActive()]);
          toast.success("Field unarchived");
        })
        .catch(() => toast.error("Failed to unarchive field"));
    },
    [refetch, refetchActive, toast, update]
  );

  if (!fields) return <CircularLoader fillParent />;

  return (
    <Stack vertical>
      {orderBy(fields ?? [], "title").map(field => (
        <Card>
          <Stack>
            <Title variant="bold">{field.title}</Title>
            <Title>{field.key}</Title>

            <Button
              size="small"
              variant="blended"
              style={{ marginLeft: "auto" }}
              onClickWithLoading={() => unarchive(field.id)}
            >
              Unarchive
            </Button>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};
