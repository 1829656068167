import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Field, Icon, Select, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";
import { EmailStepAddressBlock } from "./EmailStepAddressBlock";
import { EmployeeAsFieldSelector } from "../../../employees/fields/EmployeeFieldSelector";
import { api } from "services/rpc/RpcProvider";
import { useMemo } from "react";

export const EmailFormStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);
  const { data: forms } = api.forms.v2.list.useQuery({});

  const formOptions = useMemo(() => {
    return (forms ?? []).map(f => ({ key: f.id, title: f.title }));
  }, [forms]);

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Form">
          <StepEditorAccordion.Row label="Form">
            <Select
              element="button"
              variant="blended"
              options={formOptions}
              value={prefixedForm.get("form")}
              onSelect={opt => {
                prefixedForm.set("form")(opt.key);
              }}
              error={prefixedForm.isError("form")}
              startAdornment={<Icon name="form" />}
              placeholder="Select a form"
              attachToRef={false}
              searchable
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <StepEditorAccordion title="Link response to employee">
          <StepEditorAccordion.Row label="Employee">
            <EmployeeAsFieldSelector
              value={prefixedForm.get("linkedEmployee")}
              onSelect={opt => {
                prefixedForm.set("linkedEmployee")(opt.key);
              }}
              onClear={() => {
                prefixedForm.set("linkedEmployee")(undefined);
              }}
              variant="blended"
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <EmailStepAddressBlock
        form={form}
        formKeyPrefix={stepDataPath}
        disableBcc
        disableCc
        disableReplyTo
      />
      <Block compact blended>
        <StepEditorAccordion
          error={
            prefixedForm.isError("subject") || prefixedForm.isError("message")
              ? "Invalid fields"
              : undefined
          }
          title="Message"
          defaultOpen={true}
        >
          <Stack vertical gap="xLarge">
            <Field title="Subject">
              <FieldAwareInput
                value={prefixedForm.get("subject")}
                onChange={prefixedForm.set("subject")}
                error={prefixedForm.isError("subject")}
                placeholder="Email subject"
                size="small"
              />
            </Field>
            <Field title="Message">
              <MessageEditor
                message={prefixedForm.get("message")}
                onChange={prefixedForm.set("message")}
              />
              {prefixedForm.error("message") && (
                <ErrorMessage>{prefixedForm.error("message")}</ErrorMessage>
              )}
            </Field>
          </Stack>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
