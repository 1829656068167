import { Tag } from "@introist/react-foundation/v2";
import styled, { css } from "styled-components";

export const MediumTag = styled(Tag)<{ $active?: boolean }>`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  box-sizing: border-box;
  border-color: var(--palette-border-subdued);

  ${({ $active }) =>
    $active &&
    css`
      border-color: var(--palette-primary-default);
    `}
`;
