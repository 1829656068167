import { useMemo, useState } from "react";
import { Layout, Title, Tag } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { api } from "services/rpc/RpcProvider";
import { appRoutes, EVENTS_ROUTES } from "AppRoutes";
import moment from "moment";
import { AnnouncementModal } from "../AnnouncementModal";
import { Commander, CommanderCommand } from ".";
import { useKeyDownListener } from "./hooks";
import { useForms } from "../../../modules/forms/useForms";
import { FORMS_ROUTES } from "../../../modules/forms";

export const MultiCommander = ({ ...rest }) => {
  const navigate = useNavigate();
  const employees = api.employees.v4.list.useQuery({});
  const workflows = api.workflows.v2.list.useQuery({});
  const triggers = api.triggers.list.useQuery({});

  const events = api.companyEvents.list.useQuery({});
  const { forms, loading: formsLoading } = useForms();

  const loading =
    employees.isLoading ||
    workflows.isLoading ||
    triggers.isLoading ||
    formsLoading ||
    events.isLoading;

  const commands: CommanderCommand[] = useMemo(() => {
    if (loading || !employees) {
      return [];
    }

    const employeeCommands: CommanderCommand[] = (employees.data || []).map(employee => ({
      id: employee.id!,
      name: employee.name!,
      category: "Employees",
      categoryIcon: "persons",
      action: () => {
        navigate(appRoutes.employee.path.replace(":employeeId", employee.id!));
      }
    }));

    const workflowCommands: CommanderCommand[] = (workflows.data || []).map(workflow => ({
      id: workflow.id,
      name: workflow.name,
      category: "Workflows",
      categoryIcon: "workflows",
      action: () => navigate(appRoutes.workflowEditor.path.replace(":workflowId", workflow.id))
    }));

    const triggerCommands: CommanderCommand[] = (triggers.data || []).map(trigger => ({
      id: trigger.id,
      name: trigger.name,
      category: "Triggers",
      categoryIcon: "trigger",
      action: () => navigate(appRoutes.trigger.path.replace(":triggerId", trigger.id))
    }));

    const eventCommands: CommanderCommand[] = (events.data || []).map(event => ({
      id: event.id,
      name: event.title,
      category: "Events",
      categoryIcon: "events",
      action: () => navigate(EVENTS_ROUTES.event.path.replace(":eventId", event.id))
    }));

    const formCommands: CommanderCommand[] = (forms || []).map(form => ({
      id: form.id,
      name: form.title,
      category: "Forms",
      categoryIcon: "form",
      action: () => navigate(FORMS_ROUTES.form.path.replace(":id", form.id))
    }));

    return [
      ...employeeCommands,
      ...workflowCommands,
      ...triggerCommands,
      ...eventCommands,
      ...formCommands
    ];
  }, [employees, workflows.data, triggers.data, loading, navigate, forms, events.data]);

  return <Commander {...rest} commands={commands} searchPlaceholder="Jump to" />;
};

export const MultiCommanderAnnouncementModal = () => {
  const [seen, setSeen] = useState(false);

  useKeyDownListener([
    {
      key: "K",
      isCommand: true,
      callback: () => setSeen(true)
    }
  ]);

  return (
    <AnnouncementModal
      seen={seen}
      title="Introducing the Commander"
      storageKey="intr.emp.cmdr.seen"
      releaseDate={moment("2023-12-20T00:00:00.000Z")}
    >
      <AnnouncementModal.ContentBlock>
        <Layout.Group vertical gap="xLarge">
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">
              <span style={{ fontSize: 16 }}>⚡</span> Quick access with keyboard shortcut
            </Title>
            <Title style={{ display: "flex", alignItems: "center", gap: 4, flexWrap: "wrap" }}>
              Simply press <Tag>Ctrl+Shift+K</Tag> (Windows) or <Tag>Cmd+Shift+K</Tag> (Mac)
              anywhere in Introist to open the Commander
            </Title>
          </Layout.Group>
          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">
              <span style={{ fontSize: 16 }}>🕵️</span> Effortless employee search
            </Title>
            <Title>
              Instantly find and access employee profiles, simplifying your information retrieval
              process
            </Title>
          </Layout.Group>
        </Layout.Group>
      </AnnouncementModal.ContentBlock>
    </AnnouncementModal>
  );
};
