import { Expression } from "@introist/introist-commons/dist";
import { Button, CircularLoader, H3, Stack, useToast } from "@introist/react-foundation/v2";
import {
  ExpressionField,
  ExpressionFieldType,
  FullscreenModal,
  RecursiveExpressionEditor
} from "components/organisms";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/rpc/RpcProvider";
import { isEqual } from "lodash";

const FilterExpressionEditor = ({
  setExpression,
  expression,
  fields
}: {
  setExpression: (expr: Expression) => unknown;
  expression?: Expression;
  fields: ExpressionField[];
}) => {
  return (
    <RecursiveExpressionEditor
      onChange={setExpression}
      fields={fields}
      expression={expression}
      config={{
        attributeListTitle: "Create filter based on...",
        placeholderConditionCardText: "Create filter based on...",
        placeholderConditionCardDescription: (
          <Stack gap="small">
            <span>Start by selecting the field on the right</span>
            <H3>👉</H3>
          </Stack>
        )
      }}
    />
  );
};

const DataSourceFilterEditor = ({
  dataSourceId,
  onClose,
  open
}: {
  dataSourceId: string;
  onClose: () => unknown;
  open: boolean;
}) => {
  const toast = useToast();
  const { data: dataSource, isLoading: loadingDataSource } =
    api.employees.dataSources.find.useQuery({ dataSourceId });
  const { data: dataSourceFields, isLoading: loadingFields } =
    api.employees.dataSources.fields.list.useQuery({ sourceId: dataSourceId });

  const fields =
    dataSourceFields?.map(field => ({
      variable: field.jsonPath,
      name: field.name ?? field.jsonPath,
      type: field.type as ExpressionFieldType
    })) ?? [];
  const { mutateAsync: updateDataSource } = api.employees.dataSources.update.useMutation();
  const [expression, setExpression] = useState<Expression>();

  useEffect(() => {
    if (!!dataSource?.filterCondition) {
      if (!isEqual(dataSource.filterCondition, {})) {
        setExpression(dataSource.filterCondition);
      } else {
        setExpression({ and: [] });
      }
    }
  }, [dataSource]);

  const onUpdate = useCallback(async () => {
    await updateDataSource({ dataSourceId, updates: { filterCondition: expression } });
    toast.success("Data source filter updated");
  }, [dataSourceId, updateDataSource, expression, toast]);

  const dataLoaded = !loadingDataSource && !loadingFields && !!expression;

  return (
    <FullscreenModal.Default
      header={{
        title: "Edit data source filter",
        bottomBorder: true,
        onClose: onClose
      }}
      footer={{
        children: (
          <Stack justifyContent="flex-end">
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={loadingDataSource} onClickWithLoading={onUpdate}>
              Save
            </Button>
          </Stack>
        ),
        topBorder: true
      }}
      open={open}
      onClose={onClose}
    >
      {!dataLoaded && <CircularLoader fillParent />}
      {dataLoaded && expression && (
        <FilterExpressionEditor
          setExpression={setExpression}
          expression={expression}
          fields={fields}
        />
      )}
    </FullscreenModal.Default>
  );
};

export const DataSourceFilterEditorModal = ({
  open,
  onClose,
  dataSourceId: dataSourceIdProp,
  ...rest
}: {
  dataSourceId?: string;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const location = useLocation();
  const dataSourceIdFromHash = location.hash.split("=")[1];
  const dataSourceId = dataSourceIdProp || dataSourceIdFromHash;

  if (!dataSourceId || !open) return null;

  return <DataSourceFilterEditor open={open} onClose={onClose} dataSourceId={dataSourceId!} />;
};
