import {
  Button,
  Icon,
  IconButton,
  Layout,
  ModalV2,
  Stack,
  Tag,
  Title,
  Tooltip,
  useToast
} from "@introist/react-foundation/v2";
import { useEffect, useState } from "react";
import { api } from "services/rpc/RpcProvider";
import styled, { css } from "styled-components";
import { TriggerFolder } from "../hooks/useTriggerFolders";

type Props = {
  open: boolean;
  onClose: VoidFunction;
  triggerId: string | null;
};

const StyledFolderRow = styled(Stack)<{ $selected: boolean }>`
  padding: 0.5rem;
  padding-left: 0rem;
  padding-right: 1rem;
  position: relative;

  > svg {
    flex-shrink: 0;

    path {
      fill: var(--palette-border-default);
    }
  }

  .actions {
    display: none;
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: -1rem;
    z-index: -1;
    border-top-right-radius: var(--rounding-medium);
    border-bottom-right-radius: var(--rounding-medium);
  }

  :hover {
    cursor: pointer;
    > svg path {
      fill: #6698fc;
    }

    .actions {
      display: block;
    }

    ::after {
      background-color: var(--palette-surface-default);
    }
  }

  ${({ $selected }) =>
    $selected &&
    css`
      ::after {
        background-color: var(--palette-primary-ghosted);
      }

      > svg path {
        fill: #6698fc;
      }

      :hover {
        ::after {
          background-color: var(--palette-primary-ghosted);
        }
      }
    `}
`;

const FolderRow = ({
  folder,
  selected,
  onClick,
  onShowContents
}: {
  folder: TriggerFolder;
  selected: boolean;
  onClick: (id: string) => void;
  onShowContents?: (id: string) => void;
}) => {
  return (
    <StyledFolderRow
      gap="small"
      justifyContent="space-between"
      $selected={selected}
      onClick={() => onClick(folder.id)}
    >
      <Stack gap="small">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 4.8V12.15C15 12.9232 14.3732 13.55 13.6 13.55H2.4C1.6268 13.55 1 12.9232 1 12.15V3.4C1 2.6268 1.6268 2 2.4 2H5.6701C6.04141 2 6.3975 2.1475 6.66005 2.41005L7.23995 2.98995C7.5025 3.2525 7.85859 3.4 8.22988 3.4H13.6C14.3732 3.4 15 4.0268 15 4.8Z"
            fill="#6698FC"
          />
        </svg>

        <Title variant="bold">{folder.name}</Title>
      </Stack>
      <Stack className="actions">
        {onShowContents && (
          <Tooltip tooltip="Show contents">
            <IconButton
              icon="chevronRight"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onShowContents?.(folder.id);
              }}
            />
          </Tooltip>
        )}
      </Stack>
    </StyledFolderRow>
  );
};

const BackButton = ({ folderId, onClick }: { folderId: string | null; onClick: VoidFunction }) => {
  const folder = api.triggers.folders.find.useQuery(
    { id: folderId || "" },
    { enabled: !!folderId }
  );

  return (
    <Stack gap="small">
      <IconButton icon="arrowLeft" onClick={onClick} />
      <Title variant="bold">{folder.data?.name || "Root"}</Title>
    </Stack>
  );
};

const FoldersView = ({
  initialParentFolderId,
  showRootOption,
  onSelectedFolderIdChange
}: {
  initialParentFolderId: string | null;
  showRootOption: boolean;
  onSelectedFolderIdChange: (id: string | null) => void;
}) => {
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(initialParentFolderId);

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const folders = api.triggers.folders.list.useQuery({ parentId: currentFolderId });

  const navigateToFolder = (folderId: string | null) => {
    setCurrentFolderId(folderId);
  };

  if (folders.isLoading) {
    return null;
  }

  return (
    <Stack vertical gap="small">
      <Stack vertical gap="small">
        {currentFolderId ? (
          <BackButton
            folderId={currentFolderId}
            onClick={() => navigateToFolder(initialParentFolderId)}
          />
        ) : (
          <Stack gap="small">
            <Icon name="folder" />
            <Title variant="bold">All folders</Title>
          </Stack>
        )}
      </Stack>
      <Layout.Divider />

      {showRootOption && (
        <StyledFolderRow
          $selected={selectedFolderId === "root"}
          onClick={() => {
            setSelectedFolderId("root");
            onSelectedFolderIdChange("root");
          }}
        >
          <Stack gap="small">
            <Icon name="trigger" />
            <Title variant="bold">Triggers</Title>
          </Stack>
        </StyledFolderRow>
      )}
      {(folders.data || []).map(f => (
        <FolderRow
          key={f.id}
          folder={f}
          selected={f.id === selectedFolderId}
          onClick={() => {
            if (f.id === selectedFolderId) {
              setSelectedFolderId(null);
              onSelectedFolderIdChange(null);
            } else {
              setSelectedFolderId(f.id);
              onSelectedFolderIdChange(f.id);
            }
          }}
          onShowContents={() => navigateToFolder(f.id)}
        />
      ))}
    </Stack>
  );
};

const StyledModalV2 = styled(ModalV2)`
  > div {
    overflow: visible;
  }
`;

const CurrentFolder = ({ currentFolderId }: { currentFolderId?: string | null }) => {
  const [folderName, setFolderName] = useState("Root");

  const folder = api.triggers.folders.find.useQuery(
    { id: currentFolderId || "" },
    {
      enabled: !!currentFolderId
    }
  );

  useEffect(() => {
    if (folderName === "Root" && folder.data?.name) {
      setFolderName(folder.data?.name);
    }
  }, [folder.data?.name, folderName, setFolderName]);

  return (
    <Stack gap="xSmall">
      <Title small>Current folder:</Title>
      {folder.isLoading ? null : (
        <Tag startIcon={folderName === "Root" ? "trigger" : "folder"} colorVariant="outlined">
          {folderName}
        </Tag>
      )}
    </Stack>
  );
};

export const MoveTriggerModal = ({ open, onClose, triggerId, ...rest }: Props) => {
  const toast = useToast();
  const trigger = api.triggers.find.useQuery({ id: triggerId ?? "" }, { enabled: !!triggerId });

  const apiUtils = api.useContext();
  const moveOp = api.triggers.update.useMutation();

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const triggerFolderId = trigger.data?.folder;

  const move = async (workflowId: string, folderId: string) => {
    const folder = folderId === "root" ? null : folderId;
    await moveOp.mutateAsync(
      { id: workflowId, updates: { folder } },
      {
        onError: () => toast.error("Failed to move trigger"),
        onSuccess: () => {
          toast.success("Trigger moved");
          apiUtils.triggers.folders.list.invalidate();
          apiUtils.triggers.list.invalidate();
          onClose();
        }
      }
    );
  };

  return (
    <StyledModalV2
      {...rest}
      open={open}
      onClose={onClose}
      title="Move trigger"
      footer={
        <Stack justifyContent="flex-end" gap="small">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!selectedFolderId || moveOp.isLoading}
            onClickWithLoading={async () => {
              if (!triggerId || !selectedFolderId) return;
              await move(triggerId, selectedFolderId);
            }}
          >
            Move
          </Button>
        </Stack>
      }
    >
      {trigger.data && (
        <Stack vertical style={{ minHeight: 164 }}>
          <CurrentFolder currentFolderId={triggerFolderId} />

          <FoldersView
            showRootOption={!!triggerFolderId}
            initialParentFolderId={null}
            onSelectedFolderIdChange={setSelectedFolderId}
          />
        </Stack>
      )}
    </StyledModalV2>
  );
};
