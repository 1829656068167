import { useState } from "react";

import { Input, Modal, ModalProps, Stack } from "@introist/react-foundation/v2";

import { FormFooter } from "../../../../../datasources/components/FormFooter";

type InviteUserModalProps = ModalProps & {
  onAddUser: (email: string) => Promise<unknown>;
  organisationLogo?: string | null;
};

export const InviteUserModal = ({
  onAddUser,
  organisationLogo,
  ...props
}: InviteUserModalProps) => {
  return (
    <Modal
      title="Invite User to your workspace"
      style={{
        width: 512
      }}
      {...props}
    >
      {props.open && (
        <InviteForm onCancel={() => props.onClose && props.onClose()} onSubmit={onAddUser} />
      )}
    </Modal>
  );
};

const InviteForm = ({
  onSubmit,
  onCancel
}: {
  onSubmit: (value: string) => Promise<unknown>;
  onCancel: () => void;
}) => {
  const [email, setEmail] = useState("");

  return (
    <Stack vertical>
      <Input type="text" placeholder="email@company.com" value={email} onChange={setEmail} />
      <FormFooter
        onCancel={onCancel}
        onSubmit={async () => {
          await onSubmit(email);
        }}
        submit="Invite"
      />
    </Stack>
  );
};
