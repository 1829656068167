import React, { useState } from "react";

import { ReactComponent as EmojiIcon } from "./EmojiIcon.svg";
import { EmojiData, Picker } from "emoji-mart";
import { Popover } from "@introist/react-foundation/v2";

export const EmojiSelector = ({ onInsert }: { onInsert: (emoji: EmojiData) => boolean | void }) => {
  const [emojiButtonRef, setEmojiButtonRef] = useState<HTMLElement | null>(null);
  const [emojiOpen, setEmojiOpen] = useState(false);

  return (
    <>
      <button ref={setEmojiButtonRef} className="ql-emoji" onClick={() => setEmojiOpen(!emojiOpen)}>
        <EmojiIcon />
      </button>
      <Popover
        referenceElement={emojiButtonRef}
        open={emojiOpen}
        onClose={() => setEmojiOpen(false)}
        closeOnContentClick={false}
      >
        <Picker onSelect={onInsert} />
      </Popover>
    </>
  );
};
