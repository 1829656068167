import styled from "styled-components";
import { ReactNode } from "react";
import { IconButton } from "@introist/react-foundation/v2";

const SelectionCardContainer = styled.div<{}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 36px;
  box-sizing: border-box;
  padding: 4px var(--spacing-small);
  border: 1px solid transparent;
  transition: border-color 160ms ease;
  border-radius: var(--rounding-medium);

  :hover {
    border-color: var(--palette-border-subdued);
    cursor: pointer;

    .remove-btn {
      opacity: 1;
    }
  }
`;

const RemoveButton = styled(IconButton)`
  opacity: 0;
`;

export const SelectionCard = ({
  content,
  onRemove
}: {
  content: ReactNode;
  onRemove?: () => void;
}) => (
  <SelectionCardContainer>
    {content}
    {onRemove && <RemoveButton className="remove-btn" icon="crossSmall" onClick={onRemove} />}
  </SelectionCardContainer>
);
