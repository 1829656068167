import { StepTrigger } from "services/api/WorkflowApi";

export const createFlexRangeEditorButtonText = (
  startTrigger?: StepTrigger,
  endTrigger?: StepTrigger
) => {
  if (!startTrigger && !endTrigger) return "Set availability checker";
  const getText = (trigger: StepTrigger | undefined, prefix: string, zeroCopy: string) => {
    if (!trigger) return undefined;
    if (trigger.amount === 0) return zeroCopy;
    return `${prefix}${trigger.amount} ${trigger.period}`;
  };
  const end = getText(endTrigger, "+", "-");
  const start = getText(startTrigger, "-", "-");
  const dates = [start || "", end || ""];
  return dates.filter(_ => !!_).join(" / ");
};
