import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";

export type EmployeeListParams = RouterInput["employees"]["v4"]["list"];
export type EmployeeCreate = RouterInput["employees"]["v4"]["create"];
export type EmployeeUpdate = RouterInput["employees"]["v4"]["update"];

export type Employee = RouterOutput["employees"]["v4"]["list"][0];

export const useEmployees = (params: EmployeeListParams) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: employees, refetch } = api.employees.v4.list.useQuery(params, {
    refetchOnMount: "always"
  });

  const createOp = api.employees.v4.create.useMutation();
  const updateOp = api.employees.v4.update.useMutation();
  const deleteOp = api.employees.v4.delete.useMutation();

  const create = useCallback(
    async (entry: EmployeeCreate) => {
      return createOp.mutateAsync(entry).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (data: EmployeeUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const remove = onConfirmAction(
    async id => {
      await deleteOp.mutateAsync({ id });
      await refetch();
      toast.success("Employee deleted");
    },
    {
      icon: "trash",
      title: "Delete employee",
      description:
        "Are you sure you want to delete this employee? The data will be permanently deleted and can not be restored.",
      confirmTitle: "Delete",
      cancelTitle: "Cancel"
    }
  );

  return {
    employees,
    create,
    update,
    remove
  };
};

export type EmployeeFindParams = RouterInput["employees"]["v4"]["find"];

export const useEmployee = (params?: EmployeeFindParams, options?: any) => {
  const toast = useToast();

  const apiUtils = api.useContext();
  const { data: employee } = api.employees.v4.find.useQuery(
    params!,
    options ?? {
      enabled: !!params
    }
  );

  const createOp = api.employees.v4.create.useMutation();
  const updateOp = api.employees.v4.update.useMutation();

  const create = useCallback(
    async (entry: EmployeeCreate) => {
      return createOp.mutateAsync(entry).catch(e => {
        toast.error("Failed to create employee");
        throw e;
      });
    },
    [createOp, toast]
  );

  const update = useCallback(
    async (data: EmployeeUpdate) => {
      await updateOp
        .mutateAsync(data)
        .then(async () => {
          await apiUtils.employees.v4.list.invalidate();
          await apiUtils.employees.v4.find.refetch();
          toast.success("Employee updated");
        })
        .catch(e => {
          toast.error("Failed to update employee");
          throw e;
        });
    },
    [updateOp, apiUtils.employees.v4.list, apiUtils.employees.v4.find, toast]
  );

  return {
    employee,
    create,
    update
  };
};
