import {
  Button,
  CircularLoader,
  ModalV2,
  Stack,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { CodeEditor } from "../../../components/templates/CodeEditor";
import { FormFooter } from "../../datasources/components/FormFooter";
import { useEffect, useState } from "react";

type TransformModalProps = {
  open: boolean;
  onClose: () => void;
  fieldKey: string;
};

const TransformEditor = ({ fieldKey, onClose }: TransformModalProps) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: computed } = api.employees.v4.fields.computed.find.useQuery(
    {
      field: fieldKey
    },
    {
      refetchOnMount: "always"
    }
  );

  const { data: employees } = api.employees.v4.list.useQuery({});

  const apiUtils = api.useContext();

  const test = api.employees.v4.fields.computed.test.useMutation();
  const update = api.employees.v4.fields.computed.update.useMutation();
  const remove = api.employees.v4.fields.computed.delete.useMutation();

  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (computed && computed.code) setValue(computed.code);
  }, [computed]);

  const onRemove = onConfirmAction(
    async () => {
      await remove
        .mutateAsync({ field: fieldKey })
        .then(async () => {
          await apiUtils.employees.v4.fields.computed.list.refetch();
          toast.success("Field transformation removed");
          onClose();
        })
        .catch(() => toast.error("Failed to remove field transformation"));
    },
    {
      title: "Remove field transformation",
      description: "Are you sure you want to remove this field transformation?",
      confirmTitle: "Remove",
      cancelTitle: "Cancel"
    }
  );

  if (!computed)
    return (
      <div style={{ height: "600px" }}>
        <CircularLoader fillParent />
      </div>
    );

  return (
    <>
      <Stack vertical gap="large">
        <CodeEditor
          value={value}
          onChange={setValue}
          testDataOptions={employees?.map(e => ({ key: e.id!, title: e.name ?? undefined }))}
          onTest={employeeIdentifier =>
            test.mutateAsync({ code: value, employeeId: employeeIdentifier! })
          }
        />
        <FormFooter
          left={
            <Button variant="outlined" onClick={onRemove}>
              Remove
            </Button>
          }
          onCancel={onClose}
          onSubmit={async () => {
            await update.mutateAsync({ field: fieldKey, code: value });
            await apiUtils.employees.v4.fields.computed.list.refetch();
            toast.success("Field transformation updated");
            onClose();
          }}
        />
      </Stack>
    </>
  );
};

export const FieldTransformationModal = (props: TransformModalProps) => {
  return (
    <ModalV2
      title="Edit field transformation"
      open={props.open}
      onClose={props.onClose}
      maxWidth={1200}
    >
      {props.open && <TransformEditor {...props} />}
    </ModalV2>
  );
};
