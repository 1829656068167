import { postJson } from "./ApiBase";

interface AssetUrls {
  uploadUrl: string;
  fileUrl: string;
}

export interface File {
  name: string;
  type: string;
  uploadUrl: string;
  uploadKey: string;
}

const toBlob = (dataURI: string) => {
  const [prefix, data] = dataURI.split(",");
  const isBase64 = prefix.indexOf("base64") >= 0;
  const byteString = isBase64 ? atob(data) : unescape(data);
  const mimeString = prefix.split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length).map((_: any, index: number) => {
    return byteString.charCodeAt(index);
  });

  return new Blob([ia], { type: mimeString });
};

export const getAssetUrl = async (data: string): Promise<string> => {
  const blob = toBlob(data);
  const assetUrls: AssetUrls = await postJson("/v1/assets", {
    contentType: blob.type
  });

  await fetch(assetUrls.uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type": blob.type
    },
    body: blob
  });
  return assetUrls.fileUrl;
};

export interface UploadInfo {
  uploadUrl: string;
  fileUrl: string;
}

export const getAssetUploadUrl = (contentType: string): Promise<UploadInfo> => {
  return postJson("/v1/assets", { contentType });
};

export const uploadAsset = async (file: File): Promise<string> => {
  const urls = await getAssetUploadUrl(file.type);
  file.uploadUrl = urls.uploadUrl;
  await uploadFile(file);
  return urls.fileUrl;
};

const uploadFile = async (file: File) => {
  const { type, uploadUrl } = file;

  return fetch(uploadUrl, {
    method: "put",
    headers: {
      "content-type": type
    },
    //@ts-ignore
    body: file
  });
};
