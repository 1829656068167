import {
  ActionMenu,
  Layout,
  Title,
  Option,
  useToast,
  useActions,
  Icon
} from "@introist/react-foundation/v2";
import { useConnectAccount } from "modules/connectedaccounts/hooks/use-connect-account";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ConnectedAccount } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { AddCalendarContainer } from "../AddCalendarContainer";
import { AvatarBackground } from "modules/settings/components/AvatarBackground";
import { getConnectAccountRoute } from "AppRoutes";
import { getInitials } from "../../../../components/molecules";

interface ConnectedAccountWithSubAccounts extends ConnectedAccount {
  subAccounts: ConnectedAccount[];
}

interface RowProps {
  account: ConnectedAccountWithSubAccounts;
}

export const EndItems = styled(Layout.Group)`
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const StartItems = styled(Layout.Group)<{
  $disableClick?: boolean;
}>`
  box-sizing: border-box;

  ${({ $disableClick }) =>
    $disableClick &&
    `
  cursor: default

`}
`;

const Row = styled(Layout.Group)`
  padding: var(--spacing-small) 0;
  border-bottom: 1px solid var(--palette-border-subdued);
  padding: var(--spacing-medium) var(--spacing-large);
`;

export const AccountsRow = ({ account }: RowProps) => {
  const { onConfirmAction } = useActions();
  const navigate = useNavigate();
  const { deleteConnectedAccount, refetchConnectedAccounts } = useConnectAccount();
  const toast = useToast();

  const { mutateAsync: onArchive } = deleteConnectedAccount({
    onSuccess: () => {
      toast.success("Account archived");
      refetchConnectedAccounts();
    },
    onError: () => {
      toast.error("Failed to archive account");
    }
  });

  const [calendarAccountId, setCalendarAccountId] = useState<string | undefined>();

  const archiveAccount = onConfirmAction(
    async (accountId: string) => {
      await onArchive({ id: accountId });
    },
    { title: "Archive account", description: "Are you sure you want to archive this account?" },
    e => {
      toast.error("Failed to archive account");
    }
  );

  const buildActionOptions = () => {
    const options: Option[] = [];

    if (account.calendarId === "primary" && account.emailProvider === "google") {
      options.push({
        key: "addcalendar",
        title: "Add another calendar",
        onClick: () => setCalendarAccountId(account.id),
        startAdornmentIcon: "calendarAdd"
      });
    }

    options.push({
      key: "reconnect",
      title: "Reconnect account",
      onClick: () => navigate(getConnectAccountRoute(account.emailProvider)),
      startAdornmentIcon: "reconnect"
    });

    options.push({
      key: "archive",
      title: "Archive account",
      onClick: () => archiveAccount(account.id),
      startAdornmentIcon: "archive"
    });

    return options;
  };

  const buildProfileNames = () => {
    const primary = account.name;
    const secondary = account.email ?? account.providerTeamName;
    return [primary, secondary];
  };

  return (
    <Row vertical>
      <Layout.Group justifyContent="space-between">
        <StartItems $disableClick>
          <AvatarBackground>
            <Title>{getInitials(account.name)}</Title>
          </AvatarBackground>

          <Layout.Group vertical gap="xSmall">
            <Title variant="bold">{buildProfileNames()[0]}</Title>
            <Title>{buildProfileNames()[1]}</Title>
          </Layout.Group>
        </StartItems>
        <EndItems>
          <ActionMenu attachToRef={false} options={buildActionOptions()} />
        </EndItems>
      </Layout.Group>
      {account.subAccounts.length > 0 && (
        <Layout.Group vertical gap="small">
          {account.subAccounts.map(_ => (
            <SubAccount subAccount={_} />
          ))}
        </Layout.Group>
      )}

      {calendarAccountId && (
        <AddCalendarContainer
          open={!!calendarAccountId}
          parentSenderId={calendarAccountId}
          onClose={() => setCalendarAccountId(undefined)}
        />
      )}
    </Row>
  );
};

interface SubAccountProps {
  subAccount: ConnectedAccount;
}

const SubAccountContainer = styled(Layout.Group)`
  background: var(--palette-surface-dimmed);
  border-radius: var(--rounding-medium);
  margin-left: var(--spacing-xxLarge);
  padding: var(--spacing-small);
  padding-right: 0;
`;

const SubAccount = ({ subAccount }: SubAccountProps) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { deleteConnectedAccount, refetchConnectedAccounts } = useConnectAccount();
  const { mutateAsync: onArchive } = deleteConnectedAccount({
    onSuccess: () => {
      toast.success("Account archived");
      refetchConnectedAccounts();
    },
    onError: () => {
      toast.error("Failed to archive account");
    }
  });

  const archiveAccount = onConfirmAction(
    async (accountId: string) => {
      await onArchive({ id: accountId });
    },
    { title: "Archive account", description: "Are you sure you want to archive this account?" },
    e => {
      toast.error("Failed to archive account");
    }
  );
  return (
    <SubAccountContainer justifyContent="space-between">
      <StartItems $disableClick>
        <Icon name="googleCalendar" />
        <Title variant="bold">{subAccount.name}</Title>
        <Title>Shared calendar</Title>
      </StartItems>
      <EndItems>
        <ActionMenu
          options={[
            {
              key: "archive",
              title: "Archive account",
              onClick: () => archiveAccount(subAccount.id),
              startAdornmentIcon: "archive"
            }
          ]}
          attachToRef={false}
        />
      </EndItems>
    </SubAccountContainer>
  );
};
