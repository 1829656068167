import { Icon, IconButton, Select, Stack, Title } from "@introist/react-foundation/v2";
import { v4 as uuid } from "uuid";
import { FieldMapping, FieldMappingProps } from "./index";

export const FieldMappingBuilder = ({
  sourceFields,
  targetFields = [],
  value,
  onChange,
  enableAdd,
  showHeader = true
}: FieldMappingProps) => {
  return (
    <Stack vertical>
      {showHeader && (
        <Stack
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 20px 1fr 20px"
          }}
        >
          <Title style={{ textAlign: "center" }}>Source field</Title>
          <Icon name="arrowRight" />
          <Title style={{ textAlign: "center" }}>Target field</Title>
          <div />
        </Stack>
      )}
      {value.map((field: FieldMapping) => (
        <Stack
          key={field.id}
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "1fr 20px 1fr 20px"
          }}
        >
          <Title
            variant="bold"
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "center"
            }}
          >
            {sourceFields.find((f: any) => f.key === field.sourceField)?.title ?? field.sourceField}
          </Title>
          <Icon name="arrowRight" />
          <Select
            searchable
            attachToRef={false}
            element="button"
            variant="blended"
            placeholder="Select field"
            options={targetFields}
            value={field.targetField}
            style={{ width: "100%", textAlign: "center" }}
            onEnter={
              enableAdd
                ? search => {
                    const opt = targetFields.find(opt => opt.title === search);
                    const newFields = value.map((f: any) => {
                      if (f.id === field.id) {
                        return { ...f, targetField: opt?.key ?? search };
                      }
                      return f;
                    });
                    onChange(newFields);
                  }
                : undefined
            }
            onSelect={opt => {
              const newFields = value.map((f: any) => {
                if (f.id === field.id) {
                  return { ...f, targetField: opt.key };
                }
                return f;
              });
              onChange(newFields);
            }}
          />
          <IconButton
            icon="crossSmall"
            size="small"
            onClick={() => onChange(value.filter((f: any) => f.id !== field.id))}
          />
        </Stack>
      ))}
      <Select
        searchable
        attachToRef={false}
        element="button"
        variant="outlined"
        placeholder="Add field"
        options={sourceFields}
        startAdornment={<Icon name="plus" />}
        style={{ marginTop: "32px" }}
        onSelect={opt => {
          onChange && onChange([...value, { id: uuid(), sourceField: opt.key, targetField: "" }]);
        }}
      />
    </Stack>
  );
};
