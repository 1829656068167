import { EmployeeAttribute } from "../../../employees/api/EmployeeAttributeApi";
import { ReactNode, useMemo } from "react";
import { Title, useTheme, Icon, Select, CloseIconButton } from "@introist/react-foundation/v2";

import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from "react-beautiful-dnd";

import styles from "./OrderableAttributeList.module.scss";

import { ReactComponent as GrabIcon } from "./grab.svg";
import ReactDOM from "react-dom";
import cx from "classnames";

type Props = {
  attributes: EmployeeAttribute[];
  value: string[];
  onChange: (value: string[]) => void;
  enableAdd?: boolean;
  error?: string;
};

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const OrderableAttributeList = ({
  attributes,
  value,
  onChange,
  enableAdd,
  error
}: Props) => {
  const { theme } = useTheme();

  const items = useMemo(() => {
    if (!value) return [];
    return value.map(val => {
      const attribute = attributes.find(a => a.variable === val);
      return (
        <AttributeItem
          attribute={attribute}
          onRemove={() => onChange(value.filter(_ => _ !== val))}
        />
      );
    });
  }, [value, attributes, onChange]);

  const addOptions = useMemo(() => {
    return attributes
      .filter(a => !value.includes(a.variable))
      .map(attr => ({ key: attr.variable, title: attr.name }));
  }, [attributes, value]);

  const onDrag = ({ source, destination }: any) => {
    if (!destination) return;
    onChange(reorder(value, source.index, destination.index));
  };

  return (
    <div className={cx(styles.Root, { [styles.error]: !!error })}>
      <DragDropContext onDragEnd={onDrag}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <PortalDraggable child={item} provided={provided} snapshot={snapshot} />
                  )}
                </Draggable>
              ))}
              {items.length > 0 && provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {enableAdd && (
        <div
          className={styles.Add}
          style={{ marginTop: items.length > 0 ? theme.spacing.large : undefined }}
        >
          <Select
            size="small"
            variant="outlined"
            element="button"
            startAdornment={<Icon name="plus" />}
            options={addOptions}
            onSelect={opt => onChange([...value, opt.key])}
            placeholder="New field"
            searchable
          />
        </div>
      )}
      {error && (
        <Title color={theme.palette.danger.default} style={{ marginTop: theme.spacing.large }}>
          {error}
        </Title>
      )}
    </div>
  );
};

const AttributeItem = ({
  attribute,
  onRemove
}: {
  attribute?: EmployeeAttribute;
  onRemove: () => void;
}) => {
  const { theme } = useTheme();
  return (
    <div className={styles.Item}>
      <GrabIcon />
      <div style={{ flex: 1 }}>
        <Title color={theme.palette.foreground.default} className={styles.Title}>
          {attribute?.name ?? "Unknown field"}
        </Title>
      </div>

      <CloseIconButton onClick={onRemove} />
    </div>
  );
};

const PortalDraggable = ({
  child,
  provided,
  snapshot
}: {
  child: ReactNode;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const usePortal: boolean = snapshot.isDragging;

  const elem = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      {child}
    </div>
  );

  if (!usePortal) {
    return elem;
  }

  // if dragging - put the item in a portal
  // this needs to be done so that items do not appear when this whole component is in Drawer (in portal)
  return ReactDOM.createPortal(elem, document.getElementById("root")!);
};
