import { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import {
  H4,
  Icon,
  IconName,
  Layout,
  TTagColorVariant,
  Title,
  useColorVariant
} from "@introist/react-foundation/v2";
import { animated, useSpring, useTransition } from "@react-spring/web";
import useMeasure from "react-use-measure";

type Props = {
  open?: boolean;
  title: string;
  colorVariant?: TTagColorVariant;
  icon: IconName;
  children?: ReactNode;
  order: number;
  highlightOpen?: boolean;
  onClick: (order: number) => void;
};

const StyledAccordionCard = styled.div<{ $highlightOpen?: boolean }>`
  box-shadow: var(--shadow-default);
  border: 2px solid transparent;
  border-radius: var(--rounding-medium);
  transition: border-color 140ms ease;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--palette-background-default);

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-xLarge);
  }

  ${({ $highlightOpen }) =>
    $highlightOpen &&
    css`
      border-color: var(--palette-primary-default) !important;
    `}

  :hover {
    border-color: var(--palette-border-default);
  }
`;

const AnimatedContent = styled(animated.section)`
  overflow: hidden;
  > div {
    padding-left: 3.5rem;
    padding-right: var(--spacing-xLarge);
    padding-bottom: var(--spacing-xLarge);
  }
`;

const AnimatedAdornment = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
`;

export const AccordionCard = ({
  open,
  title,
  colorVariant,
  order,
  icon,
  children,
  onClick,
  ...rest
}: Props) => {
  const { foreground } = useColorVariant(colorVariant);

  const immediateRef = useRef(true);

  const [contentRef, { height: contentHeight }] = useMeasure();

  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: "scale(0)"
    },
    enter: {
      opacity: 1,
      transform: "scale(1)"
    },
    leave: {
      opacity: 0,
      transform: "scale(0)",
      position: "absolute"
    },
    config: { tension: 400, clamp: true },
    immediate: immediateRef.current
  });

  const heightSpring = useSpring({
    height: open ? contentHeight : 0,
    opacity: open ? 1 : 0,
    marginTop: open ? -16 : 0,
    config: { clamp: true, tension: 200 },
    immediate: immediateRef.current
  });

  const caretSpring = useSpring({
    transform: open ? "rotate(180deg)" : "rotate(0deg)",
    display: "flex",
    immediate: true
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      immediateRef.current = false;
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledAccordionCard {...rest} onClick={() => onClick(order)} $highlightOpen={open}>
      <header>
        <Layout.Group gap="large">
          {transitions((style, tOpenInternal) => (
            <AnimatedAdornment style={style}>
              {tOpenInternal ? <Icon name={icon} color={foreground} /> : <Title>{order}</Title>}
            </AnimatedAdornment>
          ))}

          <H4>{title}</H4>
        </Layout.Group>
        <animated.div style={caretSpring}>
          <Icon subdued name="chevronDown" />
        </animated.div>
      </header>
      <AnimatedContent style={heightSpring}>
        <div ref={contentRef}>{children}</div>
      </AnimatedContent>
    </StyledAccordionCard>
  );
};
