import { ReactNode } from "react";
import { Button, H4, Layout, Title } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { steps } from "../context";

type Props = {
  title: string;
  order: number;
  children: ReactNode;
  nextButtonText?: string;
  nextButtonDisabled?: boolean;
  onNextButtonClick: any;
};

const StyledH4 = styled(Title)`
  color: var(--palette-foreground-subdued);
`;

export const StepFrame = ({
  title,
  order,
  children,
  nextButtonText,
  nextButtonDisabled,
  onNextButtonClick
}: Props) => {
  const navigate = useNavigate();
  return (
    <Layout.Group vertical gap="large">
      <Layout.Group>
        <H4>{title}</H4>
        <StyledH4>
          Step {order.toString()} of {steps.length}
        </StyledH4>
      </Layout.Group>
      {children}
      <Layout.Group justifyContent="space-between">
        <Button variant="outlined" onClick={() => navigate("/datasources")}>
          Cancel
        </Button>
        <Button
          onClickWithLoading={async () => await onNextButtonClick()}
          disabled={nextButtonDisabled}
        >
          {nextButtonText}
        </Button>
      </Layout.Group>
    </Layout.Group>
  );
};
