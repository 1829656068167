import {
  Button,
  CircularLoader,
  Icon,
  Modal,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { api } from "../../../services/rpc/RpcProvider";
import { Empty, ListView } from "../../../foundation";
import { isEmpty } from "lodash";
import { ListRow } from "../../../components/organisms";
import { formatDateTime } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";

export const DataSourceEventHistoryModal = ({
  dataSourceId,
  open,
  onClose
}: {
  dataSourceId?: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { theme } = useTheme();

  const { data: events, refetch } = api.datasources.eventHistory.useQuery(
    { id: dataSourceId ?? "" },
    { enabled: !!dataSourceId }
  );

  return (
    <Modal title="Event History" open={open} onClose={onClose}>
      <Stack vertical style={{ width: "700px" }}>
        {!events && <CircularLoader fillParent />}
        {isEmpty(events) && <Empty title="No Events" message="" />}
        {!isEmpty(events) && (
          <>
            <Button
              variant="outlined"
              startIcon="reconnect"
              onClickWithLoading={() => refetch()}
              style={{ marginLeft: "auto" }}
            >
              Reload
            </Button>
            <ListView.List
              data={events}
              renderRow={e => (
                <ListRow gridTemplateColumns="100px 40px 1fr">
                  <Title>{formatDateTime(e.timestamp, DateFormats.datetime.short)}</Title>
                  {e.success ? (
                    <Icon name="checkCircle" color={theme.palette.success.default} />
                  ) : (
                    <Icon name="crossCircle" color={theme.palette.danger.default} />
                  )}
                  <Title>{e.result ?? e.error}</Title>
                </ListRow>
              )}
            />
          </>
        )}
      </Stack>
    </Modal>
  );
};
