import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import Quill from "quill";
import sanitize from "sanitize-html";
import { v4 as uuid } from "uuid";

const Embed = Quill.import("blots/embed");

interface PropertyElement {
  "data-property-id": string;
  "data-property-type": string;
  "data-property-variable": string;
  "data-property-name": string;
  contenteditable: string;
  readonly: string;
  class: string;
}

export class PropertyBlock extends Embed {
  static blotName = "property";
  static tagName = "tag";

  static get ATTRIBUTES() {
    return [
      "data-property-id",
      "data-property-variable",
      "data-property-type",
      "data-property-name",
      "readonly",
      "contenteditable",
      "class"
    ];
  }

  static formats(domNode: any) {
    return this.ATTRIBUTES.reduce(function (formats: any, attribute: any) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static value(domNode: any) {
    return { ...this.formats(domNode) };
  }

  static create(value: EmployeeAttribute | PropertyElement | boolean) {
    const isElement = Object.keys(value).includes("data-property-id");
    const isPropertyOption = Object.keys(value).includes("variable");

    if (isElement) return this.createBasedOnElement(value as PropertyElement);
    if (isPropertyOption) return this.createBasedOnOption(value as EmployeeAttribute);
    return this.createDeprecatedTag();
  }

  static createDeprecatedTag() {
    const node = super.create();
    node.setAttribute("class", `ql-custom-userproperty`);
    node.innerHTML = "unknown property";
    return node;
  }

  static createBasedOnElement(element: PropertyElement) {
    const node = super.create(element["data-property-id"]);

    node.setAttribute("data-property-id", element["data-property-id"]);
    node.setAttribute("data-property-variable", element["data-property-variable"]);
    node.setAttribute("data-property-type", element["data-property-type"]);
    node.setAttribute("readonly", true);
    node.setAttribute("contenteditable", false);
    node.setAttribute("class", `ql-custom-${element["data-property-type"]}`);
    node.setAttribute("data-property-name", element["data-property-name"]);
    node.innerHTML = sanitize(element["data-property-name"]);
    return node;
  }

  static createBasedOnOption(option: EmployeeAttribute) {
    const id = uuid();
    const node = super.create(id);

    node.setAttribute("class", `ql-custom-${option.type}`);
    node.setAttribute("data-property-id", id);
    node.setAttribute("data-property-type", option.type);
    node.setAttribute("data-property-variable", option.variable);
    node.setAttribute("data-property-name", option.name);
    node.setAttribute("readonly", true);
    node.setAttribute("contenteditable", false);
    node.size = Math.max(1, option.name.length + 2);
    node.innerHTML = sanitize(option.name);
    return node;
  }
}
