import { FormFieldType } from "../../useFormFields";
import { IconPickerIcon } from "../../../../components/organisms";

export const FormFieldIcon = ({ fieldType }: { fieldType: FormFieldType }) => {
  const getIconName = () => {
    if (fieldType === "text" || fieldType === "longtext") {
      return "description";
    }

    if (fieldType === "date") return "calendar";
    if (fieldType === "email") return "email";
    if (fieldType === "number") return "urgencyMid";

    return "progress";
  };

  return <IconPickerIcon fallbackIcon={getIconName()} />;
};
