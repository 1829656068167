import { api } from "../../../../services/rpc/RpcProvider";
import { Button, ConfirmationModal, ModalV2, Stack, useToast } from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { FormFooter } from "../../components/FormFooter";
import { useTestReport } from "../../hooks/useTestReport";
import { CodeEditor } from "../../../../components/templates/CodeEditor";

type TransformEditorModalProps = {
  dataSourceId: string;
  mappingEntryId: string;
  defaultValue?: string;
  open: boolean;
  onClose: () => void;
};

const template = `function transform(mapped, raw) {
  // Return only value for the field
  return undefined;
}
`;

export const TransformEditorModal = ({
  dataSourceId,
  mappingEntryId,
  defaultValue,
  open,
  onClose
}: TransformEditorModalProps) => {
  const toast = useToast();

  const { employeeOptions } = useTestReport(dataSourceId);

  const [value, setValue] = useState(defaultValue ?? template);

  const [remove, setRemove] = useState(false);

  const test = api.employees.dataSources.testTransform.useMutation();
  const update = api.employees.dataSources.mappings.update.useMutation();
  const listMappings = api.useContext().employees.dataSources.mappings.list;

  const onSubmit = useCallback(async () => {
    return update
      .mutateAsync({ id: mappingEntryId, updates: { transform: value } })
      .then(async () => {
        await listMappings.refetch();
        toast.success("Transformation updated");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to update transformation");
      });
  }, [value, toast, update, mappingEntryId, onClose, listMappings]);

  const onRemove = useCallback(async () => {
    return update
      .mutateAsync({ id: mappingEntryId, updates: { transform: null } })
      .then(async () => {
        await listMappings.refetch();
        toast.success("Transformation removed");
        onClose();
      })
      .catch(() => {
        toast.error("Failed to remove transformation");
      });
  }, [listMappings, mappingEntryId, onClose, toast, update]);

  return (
    <ModalV2 open={open} onClose={onClose} title="Edit transformation" maxWidth={1200}>
      <Stack vertical gap="large">
        <CodeEditor
          value={value}
          onChange={setValue}
          testDataOptions={employeeOptions}
          onTest={employeeIdentifier =>
            test.mutateAsync({
              dataSourceId,
              employeeIdentifier: employeeIdentifier!,
              transform: value
            })
          }
        />
        <FormFooter
          left={
            <Button variant="outlined" onClick={() => setRemove(true)}>
              Remove
            </Button>
          }
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      </Stack>
      <ConfirmationModal
        show={remove}
        onCancel={() => setRemove(false)}
        onConfirm={onRemove}
        config={{
          title: "Remove transformation",
          description: "Are you sure you want to remove the transformation?",
          confirmTitle: "Remove",
          cancelTitle: "Cancel"
        }}
      />
    </ModalV2>
  );
};
