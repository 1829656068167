import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { z } from "zod";

const getStorageValue = (key: string, defaultValue: any, schema: z.ZodType<any>) => {
  const reset = () => {
    localStorage.setItem(key, JSON.stringify(defaultValue));
    return defaultValue;
  };

  try {
    const saved = localStorage.getItem(key);
    const initial = saved && JSON.parse(saved);

    const parsed = schema.safeParse(initial);
    if (parsed.success) return parsed.data;
    else return reset();
  } catch {
    return reset();
  }
};

export const useStoredState = <Value>(
  key: string,
  defaultValue: any,
  schema: z.ZodType<any>
): [Value, Dispatch<SetStateAction<Value>>] => {
  const [value, setValue] = useState<Value>(() => {
    return getStorageValue(key, defaultValue, schema);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
