import { ReactNode, useCallback } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";

export type DraggableItem = { id: string };

type DraggableListProps<T extends DraggableItem> = {
  items: T[];
  onReordered?: (items: T[], newIndex: number) => void;
  renderItem: (item: T, index: number) => ReactNode;
};

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
export const DraggableList = <T extends DraggableItem>({
  items,
  onReordered,
  renderItem
}: DraggableListProps<T>) => {
  const onDragEnd = useCallback(
    ({ source, destination }: any) => {
      if (!destination) return;
      const reordered = reorder(items, source.index, destination.index);
      onReordered && onReordered(reordered, destination.index);
    },
    [items, onReordered]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-employee-fields">
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={!onReordered}
              >
                {(provided, snapshot) => (
                  <PortalDraggable
                    child={renderItem(item, index)}
                    provided={provided}
                    snapshot={snapshot}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const PortalDraggable = ({
  child,
  provided,
  snapshot
}: {
  child: ReactNode;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const usePortal: boolean = snapshot.isDragging;

  const elem = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={provided.draggableProps.style}
    >
      {child}
    </div>
  );

  if (!usePortal) {
    return elem;
  }

  // if dragging - put the item in a portal
  // this needs to be done so that items do not appear when this whole component is in Drawer (in portal)
  return ReactDOM.createPortal(elem, document.getElementById("root")!);
};
