import { Expression } from "@introist/introist-commons/dist";
import { CircularLoader, H3, Modal, Stack, useToast } from "@introist/react-foundation/v2";
import { ExpressionField, RecursiveExpressionEditor } from "components/organisms";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/rpc/RpcProvider";
import { isEqual } from "lodash";
import { useDataSourceMapping } from "../hooks/useDataSourceMapping";
import { FormFooter } from "../components/FormFooter";

const FilterExpressionEditor = ({
  setExpression,
  fields,
  expression
}: {
  setExpression: (expr: Expression) => unknown;
  fields: ExpressionField[];
  expression?: Expression;
}) => {
  return (
    <RecursiveExpressionEditor
      onChange={setExpression}
      fields={fields}
      expression={expression}
      config={{
        attributeListTitle: "Create condition based on...",
        placeholderConditionCardText: "Create condition based on...",
        placeholderConditionCardDescription: (
          <Stack gap="small">
            <span>Start by selecting the field on the right</span>
            <H3>👉</H3>
          </Stack>
        )
      }}
    />
  );
};

const DataSourceCreateConditionEditor = ({
  dataSourceId,
  onClose
}: {
  dataSourceId: string;
  onClose: () => unknown;
}) => {
  const toast = useToast();

  const { data: dataSource } = api.employees.dataSources.find.useQuery({ dataSourceId });
  const { introistFields } = useDataSourceMapping(dataSourceId);

  const { mutateAsync: updateDataSource } = api.employees.dataSources.update.useMutation();
  const [expression, setExpression] = useState<Expression | undefined>(undefined);

  useEffect(() => {
    if (!!dataSource?.createCondition) {
      if (!isEqual(dataSource.createCondition, {})) {
        setExpression(dataSource.createCondition);
      } else {
        setExpression({ and: [] });
      }
    }
  }, [dataSource]);

  const onUpdate = useCallback(async () => {
    await updateDataSource({ dataSourceId, updates: { createCondition: expression } });
    toast.success("Data source updated");
    onClose();
  }, [dataSourceId, updateDataSource, expression, toast, onClose]);

  const loading = !dataSource || !introistFields || !expression;

  return (
    <Stack vertical>
      {loading && <CircularLoader fillParent />}
      {!loading && (
        <FilterExpressionEditor
          setExpression={setExpression}
          fields={(introistFields ?? []).filter(f => f.type !== "group") as ExpressionField[]}
          expression={expression}
        />
      )}
      <FormFooter onCancel={onClose} onSubmit={onUpdate} />
    </Stack>
  );
};

export const DataSourceCreateConditionModal = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: VoidFunction;
}) => {
  const location = useLocation();
  const dataSourceId = location.hash.split("=")[1];

  return (
    <Modal
      title="Edit data source create condition"
      onClose={onClose}
      open={open}
      style={{ width: "1000px", height: "80vh" }}
    >
      {open && <DataSourceCreateConditionEditor onClose={onClose} dataSourceId={dataSourceId!} />}
    </Modal>
  );
};
