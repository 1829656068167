import styled from "styled-components";
import {
  Button,
  Card,
  CircularLoader,
  Layout,
  Modal,
  ModalProps,
  Title
} from "@introist/react-foundation/v2";
import {
  CompanyEventAttendeeHistory,
  CompanyEventAttendeeHistoryProps
} from "./CompanyEventAttendeeHistoryModal";
import { api } from "services/rpc/RpcProvider";
import { EventAttendeeAttendanceStatusTag } from "modules/events/components";
import { Avatar } from "components/molecules";

type Props = Omit<CompanyEventAttendeeHistoryProps, "attendeeId"> &
  Pick<ModalProps, "onClose" | "open"> & {
    attendeeId?: string;
  };

const StyledModal = styled(Modal)`
  width: 100%;
  > :first-child > :last-child {
    max-height: initial;
    overflow: visible;
  }
`;

export const CompanyEventAttendeeHistoryModal = ({ attendeeId, ...rest }: Props) => {
  const { data: attendee, isLoading } = api.companyEvents.attendees.find.useQuery(
    { attendeeId: attendeeId || "" },
    { enabled: !!attendeeId }
  );

  return (
    <StyledModal {...rest} title="Attendee history" maxWidth={640}>
      <Layout.Group vertical gap="xLarge">
        <Layout.Group vertical gap="large">
          <Card style={{ minHeight: 78 }}>
            {isLoading && <CircularLoader size="small" fillParent />}
            {!isLoading && attendee && (
              <Layout.Group justifyContent="space-between">
                <Layout.Group gap="large">
                  <Avatar nameOrEmail={attendee.name || attendee.email} />
                  <Layout.Group vertical gap="xSmall">
                    <Title variant="bold">{attendee.name}</Title>
                    <Title>{attendee.email}</Title>
                  </Layout.Group>
                </Layout.Group>
                <EventAttendeeAttendanceStatusTag status={attendee.attendanceStatus} />
              </Layout.Group>
            )}
          </Card>

          {attendeeId && (
            <Card>
              <CompanyEventAttendeeHistory attendeeId={attendeeId} />
            </Card>
          )}
        </Layout.Group>
        <Layout.Group justifyContent="flex-end">
          <Button variant="outlined" onClick={rest.onClose}>
            Close
          </Button>
        </Layout.Group>
      </Layout.Group>
    </StyledModal>
  );
};
