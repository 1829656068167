import { Card, Icon, IconButton, Layout, Select, Title } from "@introist/react-foundation/v2";
import { useAttributes } from "../../../../../../hooks";
import { useMemo } from "react";
import { BackgroundCard } from "../ScheduleBlock/components/PreconditionStepsEditor";

type RecordCompletionToAttributeSettingProps = {
  value?: string;
  onChange: (value: string | null) => void;
  readOnly?: boolean;
};

export const RecordCompletionToAttributeSetting = ({
  value,
  onChange,
  readOnly
}: RecordCompletionToAttributeSettingProps) => {
  const attributes = useAttributes(false, ["text"]);

  const options = useMemo(() => {
    return (attributes ?? []).map(a => ({ key: a.variable, title: a.name }));
  }, [attributes]);

  const content = (
    <Layout.Group vertical gap="large">
      <Layout.Group vertical gap="xSmall">
        <Layout.Group>
          <Icon name="check" />
          <Title variant="bold">Record step completion to attribute</Title>
        </Layout.Group>
        <Title style={{ marginLeft: 28 }}>
          Mark completion of this step to the selected attribute of the employee.
        </Title>
      </Layout.Group>
      <Layout.Group>
        <Select
          element="button"
          placeholder="Select attribute"
          startAdornment={<Icon name={!!value ? "attribute" : "plusSmall"} />}
          variant="blended"
          size="small"
          options={options}
          value={value}
          onSelect={opt => onChange(opt?.key)}
          disabled={readOnly}
        />
        {!!value && !readOnly && <IconButton icon="crossSmall" onClick={() => onChange(null)} />}
      </Layout.Group>
    </Layout.Group>
  );

  if (readOnly) return <BackgroundCard>{content}</BackgroundCard>;
  return <Card>{content}</Card>;
};
