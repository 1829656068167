import { Layout } from "@introist/react-foundation/v2";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { TaskConfig } from "../../types";
import { Block } from "components/atoms";
import { AttributeInput } from "components/molecules";

type TaskContentBlockProps = {
  value: TaskConfig;
  onChanged: (value: TaskConfig) => void;
  readOnly?: boolean;
  accordionTitlePrefix?: string;
  completed?: boolean;
};

export const TaskContentBlock = ({
  accordionTitlePrefix,
  value,
  onChanged,
  readOnly
}: TaskContentBlockProps) => {
  return (
    <Block blended compact>
      <StepEditorAccordion title="Task details" stepOrder={accordionTitlePrefix}>
        <Layout.Group vertical gap="xLarge">
          <AttributeInput
            readOnly={readOnly}
            size="small"
            title="Task title"
            value={value.description}
            onChange={description => onChanged({ ...value, description })}
          />
          <AttributeInput
            readOnly={readOnly}
            size="small"
            title="Complete text"
            value={value.confirmText}
            onChange={confirmText => onChanged({ ...value, confirmText })}
          />
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
