import "./index.css";
import "@introist/react-foundation/v2/index.css";

import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";

import { Application } from "./routes/App/App";

declare global {
  interface Window {
    $crisp: any;
  }
}

moment.locale("en");

ReactDOM.render(
  //@ts-ignore
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById("root")
);
