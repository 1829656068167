export const SlackStepSchemaFields = {
  senderId: {
    type: "string",
    required: true
  },
  recipients: {
    type: "array",
    required: true,
    each: {
      key: {
        type: "string",
        required: true,
        enum: ["user", "channel"]
      },
      value: {
        type: "string",
        required: true
      },
      group: {
        type: "string",
        required: false
      }
    }
  },
  bot: {
    id: {
      type: "string"
    },
    senderId: {
      type: "string"
    },
    name: {
      type: "string"
    },
    iconUrl: {
      type: "string"
    },
    iconEmoji: {
      type: "string"
    }
  },
  messageType: {
    type: "string",
    enum: ["channel", "direct"]
  },
  senderType: {
    type: "string",
    enum: ["user", "bot"]
  },
  message: {
    type: "string",
    required: true
  }
};
