import { IntroistDateTime } from "@introist/introist-commons/dist";
import { generateQuery, generateQueryWithParameters, getJson } from "../../../services/api/ApiBase";
import uniq from "lodash.uniq";
import { JourneyStepStatusV2 } from "services/rpc/RpcProvider";

export type AlertSeverity = "error" | "warning" | "info";

export type AlertType =
  | "attributemissing"
  | "slackusernotfound"
  | "slackchannelnotfound"
  | "calendareventnotfound";

export type JourneyStepAlertRecord = {
  id: string;
  journeyStepId: string;
  alertType: AlertType;
  severity: AlertSeverity;
  identifierData: string;
  createdAt: string;
  resolvedAt?: string;
  resolveDetails?: string;
};

export type StepType = "email" | "event" | "webhook" | "slack" | "enroll";

export type ExtendedJourneyStepAlertRecord = JourneyStepAlertRecord & {
  resolvedIdentifier: string;
  journeyId: string;
  employeeId: string;
  employee: {
    id: string;
    email: string;
    lastname: string;
    firstname: string;
    targetdate: string;
    timezone: string;
  };
  stepHtmlTitleGuess: string;
  stepStartAt: IntroistDateTime;
  stepScheduledAt: string;
  stepCompletedAt: string | null;
  stepDeliveredAt: string | null;
  stepStatus: JourneyStepStatusV2;
  stepTitle: string | null;
  stepType: StepType;
  workflowName: string;
  workflowLabel: string | null;
};

export type AlertCount = Record<AlertSeverity, number>;

export type AlertMetadata = {
  count: AlertCount;
};

export type AlertsFilter = {
  journeyId?: string;
  stepId?: string;
  employeeId?: string;
  uniqueBy?: AlertUniqueByValues[];
};

export type AlertUniqueByValues = "journeyId" | "alertType" | "alertDetails";

const listAlerts = async ({
  journeyId,
  stepId,
  employeeId,
  uniqueBy
}: AlertsFilter): Promise<ExtendedJourneyStepAlertRecord[]> => {
  const searchParams = new URLSearchParams();
  if (journeyId) searchParams.append("journeyId", journeyId);
  if (uniqueBy) searchParams.append("uniqueBy", uniqueBy.join(","));

  const issues = await getJson<ExtendedJourneyStepAlertRecord[]>(
    `/v1/alerts?${searchParams.toString()}`
  );

  const byEmployee = employeeId ? issues.filter(i => i.employeeId === employeeId) : issues;
  const byStepId = stepId ? byEmployee.filter(i => i.journeyStepId === stepId) : byEmployee;

  return byStepId;
};

export const getAttributesWithAlerts = (alerts: ExtendedJourneyStepAlertRecord[]) => {
  const attributeAlerts = alerts.filter(a => a.alertType === "attributemissing");
  return uniq(attributeAlerts.map(a => a.identifierData));
};

const getAlertsMetadata = (): Promise<AlertMetadata> => {
  return getJson(`/v1/alerts/metadata`);
};

export const useAlertApi = () => ({
  list: generateQueryWithParameters<AlertsFilter, ExtendedJourneyStepAlertRecord[]>(
    ["listAlerts"],
    listAlerts
  ),
  metadata: generateQuery<AlertMetadata>(["alertsMetadata"], getAlertsMetadata)
});
