import { ReadOnlyStepProps } from "../useStepEditor";
import { useAutomationStep } from "../../../automations/hooks/useAutomationStep";
import { Block } from "../../../../components/atoms";
import { ReadOnlyMessage } from "../../components/ReadOnlyMessage";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";

export const TeamsChannelMessageViewer = ({ stepId }: ReadOnlyStepProps) => {
  const { step } = useAutomationStep({ id: stepId });

  if (!step || !step.resolvedData) return <div />;

  const resolvedData = step.resolvedData as any;

  return (
    <>
      <Block blended compact>
        <StepEditorAccordion title="Channel">
          <StepEditorAccordion.Row label="From">
            <Stack>
              <Icon name="person" />
              <Title variant="bold">{resolvedData.senderName}</Title>
            </Stack>
          </StepEditorAccordion.Row>
          <StepEditorAccordion.Row label="Channel">
            <Stack>
              <Icon name="hashtag" />
              <Title variant="bold">{`${resolvedData.channel.name} - ${resolvedData.channel.teamName}`}</Title>
            </Stack>
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
      <Block blended compact>
        <ReadOnlyMessage message={resolvedData.resolvedMessage} />
      </Block>
    </>
  );
};
