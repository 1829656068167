import { Layout } from "@introist/react-foundation/v2";
import { Routes, Route, Navigate, useNavigate, useParams } from "react-router-dom";
import { SectionMenu, SectionMenuItem } from "../../components";
import { AccountsRoot } from "modules/settings/containers/Accounts/AccountsRoot";

import { useConnectAccount } from "modules/connectedaccounts/hooks/use-connect-account";
import { TeamsIcon } from "../../../../assets";

export const AccountsRoute = () => {
  const { "*": section } = useParams();
  const navigate = useNavigate();

  const {
    googleAccounts,
    microsoftAccounts,
    slackAccounts,
    teamsAccounts,
    isLoadingConnectedAccounts
  } = useConnectAccount();

  return (
    <Layout.Group vertical>
      <SectionMenu onItemClick={id => navigate(`../${id}`, { relative: "path" })}>
        <SectionMenuItem
          id="google"
          title="Google Workspace"
          amount={googleAccounts.length}
          active={section === "google"}
          iconName="google"
        />
        <SectionMenuItem
          id="outlook"
          title="Microsoft Outlook"
          amount={microsoftAccounts.length}
          active={section === "outlook"}
          iconName="outlook"
        />
        <SectionMenuItem
          id="slack"
          title="Slack"
          amount={slackAccounts.length}
          iconName="slack"
          active={section === "slack"}
        />
        <SectionMenuItem
          id="teams"
          title="Teams"
          amount={teamsAccounts.length}
          icon={<TeamsIcon />}
          active={section === "teams"}
        />
      </SectionMenu>
      <Routes>
        <Route path="*" element={<Navigate to="google" replace />} />
        <Route
          path="google"
          element={
            <AccountsRoot
              accounts={googleAccounts}
              iconName="google"
              provider="google"
              title="Google Workspace accounts"
              description="Connect Introist to Google Workspace to send and schedule calendar invites and email messages."
              isLoading={isLoadingConnectedAccounts}
            />
          }
        />
        <Route
          path="outlook"
          element={
            <AccountsRoot
              accounts={microsoftAccounts}
              iconName="outlook"
              provider="outlook"
              title="Microsoft Outlook accounts"
              description="Connect Introist to Microsoft Outlook to send email messages and schedule calendar events."
              isLoading={isLoadingConnectedAccounts}
            />
          }
        />
        <Route
          path="slack"
          element={
            <AccountsRoot
              accounts={slackAccounts}
              iconName="slack"
              provider="slack"
              title="Slack accounts"
              description="Connect Introist to Slack to send channel and personal messages."
              disableConnectionLink
              isLoading={isLoadingConnectedAccounts}
            />
          }
        />
        <Route
          path="teams"
          element={
            <AccountsRoot
              accounts={teamsAccounts}
              icon={<TeamsIcon />}
              provider="teams"
              title="Teams accounts"
              description="Connect Introist to Teams to send channel and chat messages."
              isLoading={isLoadingConnectedAccounts}
              disableConnectionLink
            />
          }
        />
      </Routes>
    </Layout.Group>
  );
};
