import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Icon, Field, InputHandle } from "@introist/react-foundation/v2";

import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { AttributeListPopover } from "modules/workflows/routes/WorkflowEditor/StepEditor/AttributeSelector";
import { TextInput, TextInputProps } from "../../atoms";

type AttributeInputProps = Omit<TextInputProps, "onChange"> & {
  onChange: (value: string) => void;
};

const AttributeIcon = styled(Icon).attrs({ name: "attribute" })`
  fill: var(--palette-purple-default);
  cursor: pointer;
`;

export const AttributeInput = ({
  onChange,
  value,
  title,
  readOnly,
  placeholder,
  ...rest
}: AttributeInputProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputHandle>(null);
  const [attributesOpen, setAttributesOpen] = useState(false);

  // This function will be triggered when the "Escape" key is pressed
  const escFunction = useRef<(event: KeyboardEvent) => void>(event => {
    if (event.key === "Escape") {
      setAttributesOpen(false);
    }
  });

  const addVariable = useCallback(
    (variable: EmployeeAttribute) => {
      if (!inputRef.current) return;

      const definedVariable = `{{${variable.variable}}}`;
      const newValue = inputRef.current.input?.value.slice(0, -1); // trim the last character

      onChange(newValue || "");

      inputRef.current.insertTextToCursorPosition(definedVariable);
      inputRef.current.insertTextToCursorPosition(" ");
    },
    [inputRef, onChange]
  );

  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => {
      if (escFunction.current) escFunction.current(event);
    };

    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  }, []);

  return (
    <>
      <AttributeListPopover
        popoverRef={ref.current}
        open={attributesOpen}
        setOpen={setAttributesOpen}
        onAttributeSelected={addVariable}
        searchable
      />
      <Field title={title}>
        <div ref={ref}>
          <TextInput
            {...rest}
            inputHandleRef={inputRef}
            placeholder={placeholder}
            value={value}
            endAdornment={<AttributeIcon onClick={() => setAttributesOpen(!attributesOpen)} />}
            onChange={value => {
              onChange(value);
            }}
            readOnly={readOnly}
            readonly={readOnly}
          />
        </div>
      </Field>
    </>
  );
};
