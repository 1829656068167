import { Navigate, RouteProps } from "react-router-dom";
import { AuthLink } from "./routes/AuthLink";
import { Login } from "./routes/Login";
import { NoAccountRoute } from "./routes/NoAccount";
import { InitSSORoute, SSOLogin } from "./routes/SSOLogin";
import { ConnectCredentialsRoute } from "./modules/integrations/routes/ConnectCredentialsRoute";
import { MaterialsRoute } from "./modules/materials";
import { ReactNode } from "react";
import { Icon, AppMenuItem } from "@introist/react-foundation/v2";
import { EMPLOYEE_ROUTES } from "modules/employees";
import {
  AccountsRoute,
  IntegrationsSettingsRoute,
  EmployeeFieldsSettingsRoute,
  SettingsRoute,
  WorkspaceSettingsRoute,
  SsoSettingsRoute
} from "modules/settings";
import { CompleteConnectUserSlackAccountRoute, ConnectUserSlackAccountRoute } from "modules/user";
import {
  CompleteConnectAccountRoute,
  ConnectAccountRoute,
  CompletePublicConnectRoute,
  StartPublicConnectRoute
} from "modules/connectedaccounts";
import { EventRoute, EventListRoute } from "modules/events";
import { ONBOARDING_ROUTES } from "./modules/onboarding";
import { StartCalendarConnectRoute } from "./modules/events/routes/connect/StartCalendarConnectRoute";
import { CompleteCalendarConnectRoute } from "./modules/events/routes/connect/CompleteCalendarConnectRoute";
import { WORKFLOWS_ROUTES } from "modules/flows";
import { DATA_SOURCE_ROUTES } from "./modules/datasources";
import { TRIGGER_ROUTES } from "./modules/triggers";
import { AUTOMATION_ROUTES } from "./modules/automations/routes";
import { NotificationsRoute } from "./modules/notifications/NotificationsRoute";
import { HOLIDAY_CALENDAR_ROUTES } from "./modules/holiday-calendar";
import { DATASET_ROUTES } from "./modules/datasets";
import { ONBOARDING_PACKAGE_ROUTES } from "./packages/onboarding";
import { AiSettingsRoute } from "./modules/settings/routes/AiSettingsRoute";
import { Playground } from "./Playground";
import { RoomsRoute } from "./modules/settings/routes/RoomsRoute/RoomsRoute";
import { ROOM_ROUTES } from "./modules/settings/routes/RoomsRoute";
import { BATCHES_ROUTES } from "./modules/batches";
import { NUDGES_ROUTES } from "./modules/nudges";
import { FORMS_ROUTES } from "./modules/forms";
import { REPORTS_ROUTES } from "./modules/reports";

export type NewAppRoute = Omit<RouteProps, "path"> & {
  key: string;
  public?: boolean;
  path: string;
  title?: string;
  icon?: ReactNode;
  fullscreen?: boolean;
  children?: NewAppRoute[];
  param?: string;
};

export type Routes = { [index: string]: NewAppRoute };

export const SETTINGS_APP_MENU_ITEMS: AppMenuItem = {
  title: "Settings",
  icon: <Icon name="settings" />,
  subItems: [
    {
      title: "Workspace",
      path: "workspace"
    },
    {
      title: "Linked accounts",
      path: "accounts"
    },
    {
      title: "Integrations",
      path: "integrations"
    },
    {
      title: "Employee fields",
      path: "employee-fields"
    },
    { title: "Single sign-on", path: "sso" },
    { title: "AI", path: "ai" },
    { title: "Meeting rooms", path: "rooms" }
  ]
};

const CONNECT_ACCOUNT_ROOT_ROUTE = {
  key: "connect-account-start",
  path: "/connectedaccounts/connect/:provider",
  element: <ConnectAccountRoute />
};

export const getConnectAccountRoute = (provider: string) =>
  CONNECT_ACCOUNT_ROOT_ROUTE.path.replace(":provider", provider);

export const CONNECTED_ACCOUNTS_ROUTES = {
  connectAccount: CONNECT_ACCOUNT_ROOT_ROUTE,
  completeAccountConnection: {
    key: "connect-account-callback",
    path: "/connectedaccounts/connect/:provider/callback",
    element: <CompleteConnectAccountRoute />
  },
  publicConnectStart: {
    key: "public-connect-start",
    path: "/connectedaccounts/accountconnect/:workspaceToken",
    element: <StartPublicConnectRoute />,
    public: true,
    noLayout: true
  },
  publicConnectComplete: {
    key: "public-connect-complete",
    path: "/connectedaccounts/accountconnect/complete",
    element: <CompletePublicConnectRoute />,
    public: true,
    noLayout: true
  }
};

export const RootRoute = () => {
  return <Navigate to="/employees" />;
};

const PUBLIC_ROUTES: { [index: string]: NewAppRoute } = {
  authLink: {
    key: "authLink",
    path: "/auth/link/*",
    element: <AuthLink />,
    public: true
  },
  login: {
    key: "login",
    path: "/login",
    element: <Login />,
    public: true
  },

  initSSO: {
    key: "initsso",
    path: "/login/sso",
    element: <InitSSORoute />,
    public: true
  },
  loginSso: {
    key: "loginsso",
    path: "/login/sso/:token",
    element: <SSOLogin />,
    public: true
  },
  noAccount: {
    key: "noAccount",
    path: "/noaccount/*",
    element: <NoAccountRoute />,
    public: true
  }
};

export const SETTINGS_ROUTES: { [index: string]: NewAppRoute } = {
  root: {
    key: "settings",
    path: "/settings",
    element: <SettingsRoute />,
    children: [
      {
        key: "workspace-settings",
        path: "/settings/workspace",
        element: <WorkspaceSettingsRoute />
      },
      {
        key: "accounts-settings",
        path: "/settings/accounts/*",
        element: <AccountsRoute />,
        title: "Settings"
      },
      {
        key: "integrations-settings",
        path: "/settings/integrations",
        element: <IntegrationsSettingsRoute />
      },
      {
        key: "employee-fields-settings",
        path: "/settings/employee-fields",
        element: <EmployeeFieldsSettingsRoute />
      },
      {
        key: "sso",
        path: "/settings/sso",
        element: <SsoSettingsRoute />
      },
      {
        key: "ai",
        path: "/settings/ai",
        element: <AiSettingsRoute />
      },
      { key: "rooms", path: "/settings/rooms", element: <RoomsRoute /> }
    ]
  }
};

export const USER_ROUTES = {
  userConnectSlack: {
    key: "user-connect-slack",
    path: "/user/slack/connect",
    element: <ConnectUserSlackAccountRoute />
  },
  userConnectSlackComplete: {
    key: "user-connect-slack-complete",
    path: "/user/slack/connect/complete",
    element: <CompleteConnectUserSlackAccountRoute />
  }
};

export const EVENTS_ROUTES: Routes = {
  events: {
    key: "events-route",
    title: "Events",
    path: "/events",
    icon: <Icon name="events" />,
    element: <EventListRoute />
  },
  event: {
    key: "event-route",
    path: "/events/:eventId",
    element: <EventRoute />
  },
  connectStart: {
    key: "event-calendar-connect-start",
    fullscreen: true,
    path: "/events/calendars/connect/start",
    element: <StartCalendarConnectRoute />
  },
  connectComplete: {
    key: "event-calendar-connect-complete",
    fullscreen: true,
    path: "/events/calendars/connect/complete/:provider",
    element: <CompleteCalendarConnectRoute />
  }
};

export const appRoutes: { [index: string]: NewAppRoute } = {
  root: {
    key: "root",
    path: "/",
    element: <RootRoute />
  },
  initiateConnectIntegration: {
    key: "initiateconnectintegration",
    path: "/integrations/:integrationId/initiate",
    element: <ConnectCredentialsRoute mode="initiate" />
  },
  completeConnectIntegration: {
    key: "completeconnectintegration",
    path: "/integrations/:integrationId/connect",
    element: <ConnectCredentialsRoute mode="complete" />
  },
  materials: {
    key: "materials",
    path: "/materials",
    title: "Materials",
    icon: <Icon name="book" />,
    element: <MaterialsRoute />
  },
  notifications: {
    key: "notifications",
    path: "/notifications",
    element: <NotificationsRoute />
  },
  ...WORKFLOWS_ROUTES,
  ...USER_ROUTES,
  ...SETTINGS_ROUTES,
  ...PUBLIC_ROUTES,
  ...EMPLOYEE_ROUTES,
  ...CONNECTED_ACCOUNTS_ROUTES,
  ...EVENTS_ROUTES,
  ...ONBOARDING_ROUTES,
  ...DATA_SOURCE_ROUTES,
  ...TRIGGER_ROUTES,
  ...AUTOMATION_ROUTES,
  ...HOLIDAY_CALENDAR_ROUTES,
  ...DATASET_ROUTES,
  ...ONBOARDING_PACKAGE_ROUTES,
  ...ROOM_ROUTES,
  ...BATCHES_ROUTES,
  ...NUDGES_ROUTES,
  ...FORMS_ROUTES,
  ...REPORTS_ROUTES,
  playground: {
    key: "playground",
    path: "/playground",
    element: <Playground />
  }
};

export const getPrivateRoutes = () => {
  return Object.values(appRoutes).filter(route => !route.public);
};

export const getPublicRoutes = () => {
  return Object.values(appRoutes).filter(route => route.public);
};

export const buildSubRoute = (rootPath: string, value: string) => {
  // Using a regex pattern to match the slug
  const slugPattern = /(:tab)/;
  return `/${rootPath}/:tab/*`.replace(slugPattern, value).slice(0, -1);
};
