import {
  ActionMenu,
  Title,
  Stack,
  Option,
  Card,
  Icon,
  H4,
  ListSearchInput,
  Button,
  Field,
  Input,
  DateInput,
  SkeletonLoader,
  Tag
} from "@introist/react-foundation/v2";

import { List, ListRow, NoResults } from "components/organisms";

import moment from "moment";
import { useMemo, useState } from "react";
import { Holiday } from "services/rpc/RpcProvider";
import { YearSelect } from "../../components/YearSelect";
import { CountrySelect } from "../../components/CountrySelect";
import { UpsertFormProps, UpsertModal } from "../../../../foundation/templates/UpsertModal";
import { useHolidays } from "../../hooks/useHolidays";
import styled from "styled-components";

const EllipsisTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HolidayList = ({ defaultCountry }: { defaultCountry: string }) => {
  const [country, setCountry] = useState<string>(defaultCountry);
  const [year, setYear] = useState<string>(moment().format("YYYY"));

  const [search, setSearch] = useState<string | undefined>("");

  const { holidays, createHoliday, updateHoliday, deleteHoliday } = useHolidays(country, year);

  const [editOpen, setEditOpen] = useState(false);
  const [editEntry, setEditEntry] = useState<Holiday | undefined>(undefined);

  const filteredHolidays = useMemo(() => {
    if (!search) return holidays ?? [];
    return (holidays ?? []).filter(h => h.title.toLowerCase().includes(search.toLowerCase()));
  }, [search, holidays]);

  const buildActions = (holiday: Holiday) => {
    const actions: Option[] = [];

    if (!holiday.id.startsWith("default")) {
      actions.push({
        key: "edit",
        title: "Edit",
        startAdornmentIcon: "pencil",
        onClick: () => {
          setEditEntry(holiday);
          setEditOpen(true);
        }
      });
      actions.push({
        key: "delete",
        title: "Delete",
        startAdornmentIcon: "trash",
        onClick: () => deleteHoliday(holiday.id)
      });
    }

    if (actions.length > 0) return <ActionMenu options={actions} />;
    return null;
  };

  const noResults = !!search && filteredHolidays?.length === 0;

  return (
    <Card style={{ maxWidth: "750px", width: "750px", margin: "0 auto" }}>
      <Stack vertical gap="xLarge">
        <Stack>
          <Icon name="calendar" />
          <H4>Holidays</H4>
          <Button
            variant="outlined"
            size="small"
            startAdornment={<Icon name="plus" />}
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setEditEntry(undefined);
              setEditOpen(true);
            }}
          >
            Add holiday
          </Button>
        </Stack>
        <Stack gap="small">
          <YearSelect year={year} setYear={setYear} />
          <CountrySelect country={country} setCountry={setCountry} />

          <div style={{ marginLeft: "auto" }}>
            <ListSearchInput value={search} onChange={setSearch} />
          </div>
        </Stack>
        {holidays && (
          <List>
            {noResults && <NoResults />}
            {filteredHolidays.map(holiday => (
              <ListRow key={holiday.id} gridTemplateColumns="130px 1fr auto 20px">
                <Title>{moment(holiday.date).format("ddd DD.MM.YYYY")}</Title>
                <EllipsisTitle variant="bold">{holiday.title}</EllipsisTitle>
                {holiday.id.startsWith("default") ? (
                  <Tag>Public holiday</Tag>
                ) : (
                  <Tag colorVariant="primary">Custom</Tag>
                )}
                {buildActions(holiday) ?? <div />}
              </ListRow>
            ))}
          </List>
        )}
        {holidays === undefined && (
          <div>
            <Stack vertical gap="small">
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader height={48} />
              <SkeletonLoader fadeOut height={48} />
            </Stack>
          </div>
        )}
        <UpsertModal
          open={editOpen}
          entry={editEntry}
          onCreate={createHoliday}
          onUpdate={updateHoliday}
          onClose={() => {
            setEditOpen(false);
            setEditEntry(undefined);
          }}
          Form={HolidayForm}
          entryName="holiday"
        />
      </Stack>
    </Card>
  );
};

const HolidayForm = ({ value, onChange }: UpsertFormProps<Holiday>) => {
  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Name">
        <Input value={value.title} onChange={title => onChange({ ...value, title })} />
      </Field>
      <Field title="Date">
        <DateInput value={value.date} onChange={date => onChange({ ...value, date: date! })} />
      </Field>
    </Stack>
  );
};
