import { useRef, useState } from "react";
import { Icon, Layout, useTheme } from "@introist/react-foundation/v2";

import { IconToggleButton } from "modules/workflows/components/IconToggleButton";

import { createFlexRangeEditorButtonText } from "../../lib";
import { FlexRangeEditor, FlexRangeEditorProps } from "../../FlexRangeEditor";
import { CardPopover } from "modules/workflows/routes/WorkflowEditor/CardPopover";

export const ButtonPopoverFlexRangeEditor = ({
  startTrigger,
  endTrigger,
  ...rest
}: FlexRangeEditorProps) => {
  const { theme } = useTheme();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const isActive = !!startTrigger || !!endTrigger;

  return (
    <div>
      <IconToggleButton
        active={open}
        ref={buttonRef}
        onClick={() => setOpen(!open)}
        startAdornment={
          <Icon
            name="searchCircle"
            color={isActive ? theme.palette.primary.default : theme.palette.foreground.subdued}
          />
        }
      >
        <Layout.Group gap="xSmall">
          {!isActive && <Icon name="plusSmall" />}
          <span>{createFlexRangeEditorButtonText(startTrigger, endTrigger)}</span>
        </Layout.Group>
      </IconToggleButton>
      <CardPopover open={open} referenceElement={buttonRef.current} onClose={() => setOpen(false)}>
        <FlexRangeEditor {...rest} startTrigger={startTrigger} endTrigger={endTrigger} />
      </CardPopover>
    </div>
  );
};
