import { useState } from "react";
import {
  Card,
  Drawer,
  H4,
  Icon,
  IconButton,
  Title,
  useTheme,
  Layout
} from "@introist/react-foundation/v2";
import styled from "styled-components";

type SupportDrawerProps = {
  open: boolean;
  userEmail: string;
  onClose: () => void;
};

const StyledSupportDrawer = styled(Drawer)`
  box-sizing: border-box;
  padding: var(--spacing-xxLarge) var(--spacing-xLarge);
`;

const SupportForm = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
`;

export const SupportDrawer = ({ open, userEmail, onClose, ...rest }: SupportDrawerProps) => {
  const { theme } = useTheme();

  const [contactSupportOpen, setContactSupportOpen] = useState(false);

  const handleClose = () => {
    onClose();
    contactSupportOpen && setContactSupportOpen(false);
  };

  return (
    <StyledSupportDrawer {...rest} open={open} onClose={handleClose} width="540px">
      <Layout.Group vertical>
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            {contactSupportOpen && (
              <IconButton
                variant="blended"
                icon="chevronLeft"
                onClick={() => setContactSupportOpen(false)}
              />
            )}
            <H4>{contactSupportOpen ? "Contact support" : "Help"}</H4>
          </Layout.Group>
          <IconButton variant="blended" icon="crossLarge" onClick={handleClose} />
        </Layout.Group>

        {contactSupportOpen ? (
          <SupportForm>
            <iframe
              src={`https://docs.google.com/forms/d/e/1FAIpQLSfDjiYHFAOQzXNJEmSA1tO5l9RDXEGbq4oIgFGAcFFp7krLrw/viewform?usp=pp_url&entry.1431480853=${
                userEmail ?? ""
              }&embedded=true`}
              frameBorder="0"
              width="100%"
              height={1400}
              onSubmit={() => alert("submitted")}
              title="Introist Support Form"
            >
              Loading…
            </iframe>
          </SupportForm>
        ) : (
          <>
            <Card hoverable onClick={() => window.open("https://docs.introist.com/")}>
              <Layout.Group justifyContent="space-between">
                <Layout.Group>
                  <Icon name="bulb" color={theme.palette.primary.default} />
                  <Title variant="bold">Introist Help Center</Title>
                </Layout.Group>
                <Icon name="link" color={theme.palette.foreground.subdued} />
              </Layout.Group>
            </Card>
            <Card hoverable onClick={() => setContactSupportOpen(true)}>
              <Layout.Group vertical gap="xSmall">
                <Layout.Group>
                  <Icon name="questionMark" color={theme.palette.primary.default} />
                  <Title variant="bold">Contact support</Title>
                </Layout.Group>
                <Title>Support request, ask questions or give feedback</Title>
              </Layout.Group>
            </Card>
          </>
        )}
      </Layout.Group>
    </StyledSupportDrawer>
  );
};
