import { useMemo } from "react";
import { api } from "services/rpc/RpcProvider";
import { Select } from "@introist/react-foundation/v2";

type EmployeeGroupSelectorProps = {
  value?: string;
  onGroupSelected: (groupId: string | undefined) => void;
};

export const EmployeeGroupSelector = ({ value, onGroupSelected }: EmployeeGroupSelectorProps) => {
  const { data: employeeGroups } = api.employees.groups.list.useQuery({});

  const options = useMemo(() => {
    if (!employeeGroups) return [];
    const groups = employeeGroups.map(group => ({
      key: group.id,
      title: group.title
    }));
    return [{ key: "all", title: "All employees" }, ...groups];
  }, [employeeGroups]);

  return (
    <Select
      popoverBackdrop
      element="button"
      variant="outlined"
      size="small"
      options={options}
      value={value ?? "all"}
      placeholder="Select Group"
      onSelect={opt => {
        if (opt.key === "all") onGroupSelected(undefined);
        else onGroupSelected(opt.key);
      }}
    >
      Select Group
    </Select>
  );
};
