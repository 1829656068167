import { useEmployees } from "../../employees/hooks/useEmployees";
import { CircularLoader, Select } from "@introist/react-foundation/v2";
import { useFormResponseActions } from "../useFormResponses";

export const LinkEmployeeSelect = ({
  responseId,
  employeeId
}: {
  responseId: string;
  employeeId?: string | null;
}) => {
  const { employees } = useEmployees({});

  const { update, loading } = useFormResponseActions();

  return (
    <Select
      searchable
      size="small"
      style={{ width: "100%" }}
      options={(employees ?? []).map(e => ({ key: e.id!, title: e.name! }))}
      placeholder="Link to Employee"
      onSelect={opt => update(responseId, { employeeId: opt.key })}
      endAdornment={loading ? <CircularLoader size="small" /> : undefined}
      value={employeeId ?? undefined}
      onClear={() => update(responseId, { employeeId: null })}
      readOnly={!!employeeId}
      readonly={!!employeeId}
    />
  );
};
