import { ReactNode, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import {
  Button,
  Checkbox,
  CircularLoader,
  Icon,
  Layout,
  Tag,
  Title
} from "@introist/react-foundation/v2";
import { animated, useSpring, config } from "@react-spring/web";
import { debounce, isEmpty } from "lodash";
import useMeasure from "react-use-measure";

import { Dot, TextInput } from "components/atoms";

import { List, ListRow } from "components/organisms";
import { Avatar } from "components/molecules";

export type EmployeeSelectEmployee = {
  email: string | null | undefined;
  name: string;
  employeeId: string | null;
  hrisId: string | null;
  rowEndItems?: ReactNode;
};

type EmployeeSelectListProps = {
  employees: EmployeeSelectEmployee[];
  loading?: boolean;
  addLoading?: boolean;
  animate?: boolean;
  immediate?: boolean;
  addButtonText?: string;
  stackNameAndEmail?: boolean;
  searchActive?: boolean;
  maxHeight?: number;
  onChange?: (selectedEmployees: EmployeeSelectEmployee[]) => void;
  onAdd: (selectedEmployees: EmployeeSelectEmployee[]) => void;
};

const StyledList = styled(List)`
  width: 100%;
  flex-grow: 1;
`;

const EmployeeSelectListHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xxLarge);
  padding: var(--spacing-small) var(--spacing-large);
  user-select: none;
  border-bottom: 1px solid var(--palette-border-subdued);
`;

const Empty = styled(Layout.Group)`
  box-sizing: border-box;
  padding: var(--spacing-xxLarge);
  height: 100%;
`;

const OverflowWrapper = styled.div`
  overflow: hidden;

  > :last-child {
    border-bottom: none;
  }

  :hover {
    overflow-y: auto;
  }
`;

const AnimatedList = styled(animated.div)`
  position: relative;
`;

const SearchWrapper = styled.div`
  width: 16rem;
  input {
    height: 32px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

const StyledListRow = styled(ListRow)`
  cursor: pointer;
  padding: var(--spacing-large);
`;

const SelectAllCheckbox = styled(Checkbox)<{ $checked: boolean }>`
  // Hide checkmark
  span::after {
    display: none !important;
  }

  ${({ $checked }) =>
  $checked &&
  css`
      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span::before {
        content: "";
        display: block;
        height: 2px;
        width: 0.5rem;
        background-color: white;
      }
    `}
`;

const StyledButton = styled(Button)`
  min-width: 158px;
`;

const isSelected = (
  selectedEmployees: EmployeeSelectEmployee[],
  employee: EmployeeSelectEmployee
) => {
  return selectedEmployees.some(sE => {
    if (employee.hrisId) {
      return sE.hrisId === employee.hrisId;
    } else {
      return sE.employeeId === employee.employeeId;
    }
  });
};

export const useSearchEmployees = (employees: EmployeeSelectEmployee[]) => {
  const [query, setQuery] = useState("");
  const [inputValue, setInputValue] = useState("");

  const debouncedSetQuery = debounce(setQuery, 300);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debouncedSetQuery(value);
  };

  const filteredEmployees = useMemo(() => {
    if (!query) return employees;

    return employees.filter(emp => {
      return emp.name.toLowerCase().includes(query.toLowerCase());
    });
  }, [query, employees]);

  return { inputValue, handleInputChange, filteredEmployees };
};

export const EmployeeSelectList = ({
                                     employees,
                                     loading,
                                     addLoading,
                                     immediate,
                                     // About 6.5 rows
                                     maxHeight = 292,
                                     animate = false,
                                     stackNameAndEmail = false,
                                     searchActive,
                                     addButtonText = "Add employees",
                                     onChange,
                                     onAdd,
                                     ...rest
                                   }: EmployeeSelectListProps) => {
  const { filteredEmployees, inputValue, handleInputChange } = useSearchEmployees(employees);

  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeSelectEmployee[]>([]);
  const [measureRef, { height }] = useMeasure();

  useEffect(() => {
    onChange && onChange(selectedEmployees);
  }, [selectedEmployees, onChange]);

  const allSelected = !isEmpty(employees) && selectedEmployees.length === employees.length;

  const heightSpring = useSpring({
    height,
    transformOrigin: "top",
    config: { ...config.stiff, clamp: true },
    immediate: immediate
  });

  const onToggleEmployeeSelection = (employee: EmployeeSelectEmployee, selected: boolean) => {
    if (selected) {
      // Remove from selectedEmployees
      setSelectedEmployees(
        selectedEmployees.filter(sE => {
          if (employee.hrisId) {
            return sE.hrisId !== employee.hrisId;
          } else {
            return sE.employeeId !== employee.employeeId;
          }
        })
      );
    } else {
      // Add to selectedEmployees
      setSelectedEmployees([...selectedEmployees, employee]);
    }
  };

  return (
    <StyledList {...rest}>
      <EmployeeSelectListHeader>
        <Layout.Group>
          <SelectAllCheckbox
            disabled={isEmpty(employees)}
            $checked={allSelected}
            checked={allSelected}
            onChange={checked => setSelectedEmployees(checked ? filteredEmployees : [])}
          />
          <Layout.Group gap="small">
            <div
              style={{ cursor: isEmpty(employees) ? "default" : "pointer" }}
              onClick={() => {
                if (isEmpty(employees)) return;
                allSelected ? setSelectedEmployees([]) : setSelectedEmployees(employees);
              }}
            >
              <Title variant="bold">Select all</Title>
            </div>
            <Title>{selectedEmployees.length}</Title>
          </Layout.Group>
        </Layout.Group>
        <Layout.Group>
          {searchActive && (
            <SearchWrapper>
              <TextInput
                size="small"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search by name"
                endAdornment={<Icon name="search" />}
              />
            </SearchWrapper>
          )}
          <StyledButton
            variant="outlined"
            size="small"
            loading={addLoading}
            disabled={selectedEmployees.length === 0}
            endAdornment={
              <Tag colorVariant={selectedEmployees.length === 0 ? "surface" : "primary"}>
                {selectedEmployees.length.toString()}
              </Tag>
            }
            onClick={async () => {
              await onAdd(selectedEmployees);
              setSelectedEmployees([]);
            }}
          >
            {addButtonText}
          </StyledButton>
        </Layout.Group>
      </EmployeeSelectListHeader>

      <AnimatedList style={animate ? heightSpring : undefined}>
        <OverflowWrapper
          ref={measureRef}
          style={{
            maxHeight: animate ? maxHeight - 53 : undefined
          }}
        >
          {loading && isEmpty(filteredEmployees) && (
            <Empty>
              <CircularLoader size="small" fillParent />
            </Empty>
          )}
          {!loading && isEmpty(filteredEmployees) ? (
            <Empty justifyContent="center">
              <Title variant="bold">No employees found</Title>
            </Empty>
          ) : (
            filteredEmployees.map((e, index) => (
              <StyledListRow
                key={`employee-select-list-${e?.hrisId || e.employeeId}-${index}`}
                gridTemplateColumns={e.rowEndItems ? "auto 1fr auto" : "auto 1fr"}
                onClick={() => onToggleEmployeeSelection(e, isSelected(selectedEmployees, e))}
              >
                <Checkbox
                  checked={isSelected(selectedEmployees, e)}
                  onChange={checked => onToggleEmployeeSelection(e, checked)}
                />
                <Layout.Group>
                  <Avatar nameOrEmail={e.name} />
                  {stackNameAndEmail && (
                    <Layout.Group vertical gap="xSmall">
                      <Title variant="bold">{e.name}</Title>
                      {e.email && <Title>{e.email}</Title>}
                    </Layout.Group>
                  )}
                  {!stackNameAndEmail && (
                    <>
                      <Title variant="bold">{e.name}</Title>
                      {e.email && (
                        <>
                          <Dot />
                          <Title>{e.email}</Title>
                        </>
                      )}
                    </>
                  )}
                </Layout.Group>

                {e.rowEndItems}
              </StyledListRow>
            ))
          )}
        </OverflowWrapper>
      </AnimatedList>
    </StyledList>
  );
};