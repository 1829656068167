import { Button, H3, Stack, Title } from "@introist/react-foundation/v2";
import { GlobalView } from "../../components/PublicForm/ViewFrame";
import { SmoothCard } from "../../components/PublicForm/SmoothCard";
import { usePublicFormApi } from "services/api/PublicFormApi";
import { useParams } from "react-router";
import { useObjectForm } from "modules/forms/components/FormFields/useObjectForm";
import { useState } from "react";
import { Field } from "../../components/PublicForm/Field";
import {
  AlreadySubmitted,
  ErrorForm,
  FormSubmitted,
  LoadingForm
} from "modules/forms/components/PublicForm/StateViews";

export const PublicFormRoute = () => {
  const { id } = useParams();
  const api = usePublicFormApi();
  const { data: form, isLoading, isError, error } = api.find({ responseId: id! }).query;

  const doSubmit = api.submit();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { value, onChange, hasChanges, errors, submit } = useObjectForm({}, undefined, {
    submitErrorMessage: "Submission failed"
  });

  const onSubmit = submit(async values => {
    return doSubmit
      .mutateAsync({ responseId: id!, submission: values })
      .then(() => setIsSubmitted(true));
  });

  if (isLoading) {
    return <LoadingForm />;
  }

  if (form && isSubmitted) {
    return <FormSubmitted />;
  }

  if (isError || !form) {
    const isAlreadySubmitted = (error as any)?.code === 409;

    if (isAlreadySubmitted) {
      return <AlreadySubmitted />;
    }

    return <ErrorForm />;
  }

  return (
    <GlobalView>
      <Stack>
        <SmoothCard>
          <Stack vertical style={{ width: "100%" }}>
            <H3>{form?.title}</H3>
            <Title>{form?.description}</Title>
          </Stack>
          <Stack vertical gap="xLarge" style={{ width: "100%" }}>
            {form?.fields.map(field => (
              <Field
                key={field.id}
                field={field}
                value={value[field.id]}
                onChange={onChange(field.id)}
                error={errors[field.id]}
              ></Field>
            ))}
          </Stack>
          <Stack
            justifyContent="center"
            style={{ width: "100%", marginTop: "var(--spacing-xLarge)" }}
          >
            <Button disabled={!hasChanges} onClickWithLoading={onSubmit} startIcon="check">
              Submit
            </Button>
          </Stack>
        </SmoothCard>
      </Stack>
    </GlobalView>
  );
};
