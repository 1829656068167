import { useMemo } from "react";
import styled from "styled-components";
import {
  Button,
  CloseIconButton,
  H4,
  Layout,
  Stack,
  Tag,
  useActions,
  useToast
} from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { getUser } from "services/firebase";
import { testStep } from "services/api/workflows/WorkflowStepApi";

import { ActiveEmployeesButton } from "../components/ActiveEmployees";

import { getTitleForStepType, StepTypeIcon } from "../../../components/steps";
import { getWorkflowStepTypeWithEventType } from "../../../types";
import { useWorkflowStepActions } from "../../../../flows/containers/WorkflowStepEditor/useWorkflowStepActions";
import { WorkflowStep } from "../../../../flows/hooks/useWorkflowSteps";
import { useStepType } from "../../../../flows/stepTypes";
import { StepEditorMode } from "../../../../flows/containers/WorkflowStepEditor/WorkflowStepEditorDrawer";

type HeaderBlockProps = {
  onClose: () => unknown;
  hasChanges: boolean;
  step: WorkflowStep;
  onOpenActiveEmployees: () => void;
  active: boolean;
  mode: StepEditorMode;
};

const StyledHeaderBlock = styled.header`
  padding: 0 var(--spacing-xLarge);
`;

const StyledBlock = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-xxLarge) 0 var(--spacing-large) 0 !important;
`;

const ALLOWED_TEST_STEP_TYPES: string[] = [
  "email",
  "slack",
  "event",
  "creategoogleaccount",
  "emailform",
  "emailtask",
  "slacktask"
];

export const HeaderBlockV2 = ({
  step,
  onClose,
  hasChanges,
  onOpenActiveEmployees,
  active,
  mode,
  ...rest
}: HeaderBlockProps) => {
  const toast = useToast();

  const { onAction } = useActions();

  const stepType = useStepType(step.stepType);

  const actions = useWorkflowStepActions({
    stepId: step.id,
    isSaved: !!step.id,
    hasChanges,
    onCloseStep: onClose
  });

  const isTestableStep = useMemo(() => {
    return !!step.id && ALLOWED_TEST_STEP_TYPES.includes(step.stepType);
  }, [step.id, step.stepType]);

  const handleSendTest = onAction(async () => {
    await testStep({ workflowId: step.workflowId, stepId: step.id! });
    const userEmail = getUser()?.email;
    toast.success(`Test sent to ${userEmail}`);
  }, "Failed to send test!");

  return (
    <StyledHeaderBlock {...rest}>
      <StyledBlock blended>
        <Layout.Group>
          <Layout.Group gap="large">
            {stepType?.icon ?? <StepTypeIcon compact variant="blended" stepType={step.stepType} />}
            <H4>{getTitleForStepType(getWorkflowStepTypeWithEventType(step))}</H4>
            {mode === "workflow" && active !== undefined && (
              <Tag colorVariant={active ? "primary" : "outlined"}>
                {active ? "Active" : "Draft"}
              </Tag>
            )}
          </Layout.Group>
        </Layout.Group>

        <Stack gap="large">
          {mode === "workflow" && isTestableStep && (
            <Button
              size="small"
              variant="outlined"
              onClickWithLoading={handleSendTest}
              disabled={hasChanges}
            >
              Test
            </Button>
          )}
          {mode === "workflow" && active && step.id && (
            <ActiveEmployeesButton
              onOpenActiveEmployees={onOpenActiveEmployees}
              stepId={step.id}
              workflowId={step.workflowId}
            />
          )}
          {mode === "workflow" && actions}
          <CloseIconButton onClick={onClose} />
        </Stack>
      </StyledBlock>
      <Layout.Divider />
    </StyledHeaderBlock>
  );
};
