import {
  Expression,
  Value,
  OR,
  AND,
  FieldValue,
  EQ,
  LT,
  LTE,
  GT,
  GTE,
  orSchema,
  andSchema,
  IN,
  REGEX,
  includesSchema,
  EXISTS
} from "@introist/introist-commons/dist/object-filter";
import { v4 as uuidv4 } from "uuid";
import {
  buildComparatorSchema,
  ewTransformSchema,
  eqTransformSchema,
  gtTransformSchema,
  inTransformSchema,
  ltTransformSchema,
  NE,
  neTransformSchema,
  NIN,
  ninTransformSchema,
  swTransformSchema,
  newTransformSchema,
  NEW,
  nswTransformSchema,
  NSW,
  notIncludesTransformSchema,
  INC,
  NINC,
  existsTransformSchema,
  gteTransformSchema,
  lteTransformSchema,
  notGtTransformSchema,
  NGT,
  notGteTransformSchema,
  NGTE,
  notLtTransformSchema,
  NLT,
  NLTE
} from "./comparators";
import uniq from "lodash.uniq";

export type Comparator =
  | "eq"
  | "gt"
  | "ngt"
  | "ngte"
  | "nlt"
  | "nlte"
  | "gte"
  | "lt"
  | "lte"
  | "ne"
  | "in"
  | "nin"
  | "startswith"
  | "endswith"
  | "notstartswith"
  | "notendswith"
  | "includes"
  | "notincludes"
  | "exists"
  | "notexists";

export const buildAttributeTypeComparatorOptions = (type: string) => {
  const resolveTypeComparators = () => {
    if (type === "select") {
      return ComparatorOptions.filter(o => ["eq", "ne"].includes(o.key));
    }
    if (type === "date") {
      return ComparatorOptions.filter(o => o.category === "date");
    }

    return ComparatorOptions.filter(o => o.category !== "date");
  };

  return uniq([
    ...resolveTypeComparators(),
    ...ComparatorOptions.filter(o => ["exists", "notexists"].includes(o.key))
  ]);
};

export const ComparatorOptions = [
  { key: "eq", title: "is" },
  { key: "ne", title: "is not" },
  { key: "gt", title: "is after", category: "date" },
  { key: "ngt", title: "is not after", category: "date" },
  { key: "lt", title: "is before", category: "date" },
  { key: "nlt", title: "is not before", category: "date" },
  { key: "gte", title: "is same or after", category: "date" },
  { key: "lte", title: "is same or before", category: "date" },
  { key: "gt", title: "greater than" },
  { key: "lt", title: "less than" },
  { key: "gte", title: "greater than or equal" },
  { key: "lte", title: "less than or equal" },
  { key: "startswith", title: "starts with" },
  { key: "notstartswith", title: "does not start with" },
  { key: "endswith", title: "ends with" },
  { key: "notendswith", title: "does not end with" },
  { key: "includes", title: "includes" },
  { key: "notincludes", title: "does not include" },
  { key: "exists", title: "has any value" },
  { key: "notexists", title: "does not have value" }
];

export type ComparisonMode = "or" | "and";

export const ComparisonModeOptions = [
  { key: "and", title: "All" },
  { key: "or", title: "Any" }
];

export type Condition = {
  attribute: string;
  comparator: Comparator;
  value: FieldValue;
};

export const EmptyCondition: Condition = {
  attribute: "",
  comparator: "eq",
  value: ""
};

export type ConditionGroup = {
  mode: ComparisonMode;
  conditions: Condition[];
};

export type ConditionWithId = Condition & { id: string };

export type ConditionGroupWithConditionId = {
  mode: ComparisonMode;
  conditions: ConditionWithId[];
};

export const conditionGroupToExpression = (group: ConditionGroup): Expression => {
  const conditions = group.conditions.map(conditionToValue);
  return { [group.mode]: conditions } as unknown as Expression;
};

export const expressionToConditionWithIdGroup = (
  expr: Expression
): ConditionGroupWithConditionId => {
  if (orSchema.safeParse(expr).success) {
    const { or } = expr as OR;
    return {
      mode: "or",
      // @ts-ignore
      conditions: or.map(val => valueToCondition(val as Value), true)
    };
  }
  if (andSchema.safeParse(expr).success) {
    const { and } = expr as AND;
    return {
      mode: "and",
      // @ts-ignore
      conditions: and.map(val => valueToCondition(val as Value), true)
    };
  }
  throw new Error("Expression is not a valid ConditionGroup");
};

// export const expressionToConditionGroup = (expr: Expression): ConditionGroup => {
//   if (orSchema.safeParse(expr).success) {
//     const { or } = expr as OR;
//     return {
//       mode: "or",
//       conditions: or.map(val => valueToCondition(val as Value))
//     };
//   }
//   if (andSchema.safeParse(expr).success) {
//     const { and } = expr as AND;
//     return {
//       mode: "and",
//       conditions: and.map(val => valueToCondition(val as Value))
//     };
//   }
//   throw new Error("Expression is not a valid ConditionGroup");
// };

export const conditionToValue = (condition: Condition): Value => {
  return {
    [condition.attribute]: buildComparatorSchema(condition.value as string, condition.comparator)
  } as Value;
};

export const valueToCondition = (exprValue: Value, withId = true): Condition | ConditionWithId => {
  const attribute = Object.keys(exprValue)[0];
  const data = exprValue[attribute];

  let comparator: Comparator;
  let value: FieldValue;

  if (neTransformSchema.safeParse(data).success) {
    comparator = "ne";

    const {
      not: { eq }
    } = data as NE;

    value = eq;
  } else if (eqTransformSchema.safeParse(data).success) {
    comparator = "eq";

    const { eq } = data as EQ;

    value = eq;
  } else if (ltTransformSchema.safeParse(data).success) {
    comparator = "lt";

    const { lt } = data as LT;

    value = lt;
  } else if (lteTransformSchema.safeParse(data).success) {
    comparator = "lte";
    const { lte } = data as LTE;
    value = lte;
  } else if (gtTransformSchema.safeParse(data).success) {
    comparator = "gt";

    const { gt } = data as GT;

    value = gt;
  } else if (notGtTransformSchema.safeParse(data).success) {
    comparator = "ngt";

    const {
      not: { gt }
    } = data as NGT;

    value = gt;
  } else if (gteTransformSchema.safeParse(data).success) {
    comparator = "gte";
    const { gte } = data as GTE;
    value = gte;
  } else if (notGteTransformSchema.safeParse(data).success) {
    comparator = "ngte";

    const {
      not: { gte }
    } = data as NGTE;

    value = gte;
  } else if (notLtTransformSchema.safeParse(data).success) {
    comparator = "nlt";

    const {
      not: { lt }
    } = data as NLT;

    value = lt;
  } else if (notLtTransformSchema.safeParse(data).success) {
    comparator = "nlte";

    const {
      not: { lte }
    } = data as NLTE;

    value = lte;
  } else if (inTransformSchema.safeParse(data).success) {
    comparator = "in";

    const { in: inValue } = data as IN;

    value = inValue.join(",");
  } else if (ninTransformSchema.safeParse(data).success) {
    comparator = "nin";

    const {
      not: { in: inValue }
    } = data as NIN;

    value = inValue.join(",");
  } else if (swTransformSchema.safeParse(data).success) {
    comparator = "startswith";

    const { regex } = data as REGEX;

    value = regex.slice(1);
  } else if (nswTransformSchema.safeParse(data).success) {
    comparator = "notstartswith";

    const {
      not: { regex }
    } = data as NSW;

    value = regex.slice(1);
  } else if (ewTransformSchema.safeParse(data).success) {
    comparator = "endswith";

    const { regex } = data as REGEX;

    value = regex.slice(0, -1);
  } else if (newTransformSchema.safeParse(data).success) {
    comparator = "notendswith";

    const {
      not: { regex }
    } = data as NEW;

    value = regex.slice(0, -1);
  } else if (includesSchema.safeParse(data).success) {
    comparator = "includes";

    const { includes } = data as INC;

    value = includes;
  } else if (notIncludesTransformSchema.safeParse(data).success) {
    comparator = "notincludes";

    const {
      not: { includes }
    } = data as NINC;

    value = includes;
  } else if (existsTransformSchema.safeParse(data).success) {
    const { exists } = data as EXISTS;

    comparator = exists ? "exists" : "notexists";
    value = "";
  } else {
    throw new Error(`Unknown expression ${JSON.stringify(data)}`);
  }

  if (withId) {
    return {
      id: uuidv4(),
      attribute,
      comparator,
      value
    };
  }

  return {
    attribute,
    comparator,
    value
  };
};
