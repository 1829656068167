import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { useFreshserviceFields } from "../../hooks/use-freshservice-fields";
import { Layout, Select, Title } from "@introist/react-foundation/v2";
import { useAttributes } from "hooks";
import { useMemo } from "react";
import styled from "styled-components";

interface FreshserviceField {
  fieldName: string;
  attribute: string;
  value?: string;
}

interface Props {
  fields: FreshserviceField[];
  setFields: (fields: FreshserviceField[]) => void;
  readOnly?: boolean;
}

const FullWidthTitle = styled(Title)`
  flex: 1;
`;

export const FreshserviceFields = ({ fields, setFields, readOnly }: Props) => {
  const { fields: freshserviceFields } = useFreshserviceFields();

  return (
    <Block blended compact>
      <StepEditorAccordion title="Freshservice Fields" defaultOpen={true} stepOrder={2}>
        {freshserviceFields?.map(freshserviceField => {
          const field = fields.find(_ => _.fieldName === freshserviceField.name);
          return (
            <Layout.Group>
              <FullWidthTitle variant="bold">{freshserviceField.label}</FullWidthTitle>
              {readOnly && <Title variant="bold">{field?.value ?? ""}</Title>}
              {!readOnly && (
                <Field
                  selectedAttribute={field?.attribute ?? ""}
                  onSelect={value => {
                    return setFields([
                      ...fields.filter(_ => _.fieldName !== freshserviceField.name),
                      { fieldName: freshserviceField.name, attribute: value }
                    ]);
                  }}
                  onClear={() =>
                    setFields(fields.filter(_ => _.fieldName !== freshserviceField.name))
                  }
                  readOnly={readOnly}
                />
              )}
            </Layout.Group>
          );
        })}
      </StepEditorAccordion>
    </Block>
  );
};

const Field = ({
  selectedAttribute,
  onSelect,
  readOnly,
  onClear
}: {
  selectedAttribute: string;
  onSelect: (attribute: string) => unknown;
  readOnly?: boolean;
  onClear: () => unknown;
}) => {
  const attributes = useAttributes();
  const attributeOptions = useMemo(() => {
    return attributes.map(_ => ({ key: _.variable, title: _.name }));
  }, [attributes]);
  return (
    <Select
      options={attributeOptions}
      attachToRef={false}
      value={selectedAttribute}
      onSelect={option => onSelect(option.key)}
      searchable
      size="small"
      readOnly={readOnly}
      readonly={readOnly}
      onClear={onClear}
    />
  );
};
