import { Button, Icon, Layout } from "@introist/react-foundation/v2";
import { getConnectAccountRoute } from "AppRoutes";
import { CopyButton } from "components/atoms";
import { useConnectAccount } from "modules/connectedaccounts/hooks/use-connect-account";
import { useNavigate } from "react-router";

interface Props {
  provider: string;
  disableConnectionLink?: boolean;
}

export const AccountsActions = ({ provider, disableConnectionLink = false }: Props) => {
  const navigate = useNavigate();
  const { getPublicAccountConnectUrl } = useConnectAccount();
  return (
    <Layout.Group>
      <Button
        size="small"
        variant="outlined"
        onClick={() => navigate(getConnectAccountRoute(provider))}
        startAdornment={<Icon name="plus" />}
      >
        Add account
      </Button>
      {!disableConnectionLink && (
        <CopyButton
          textToCopy={getPublicAccountConnectUrl(provider)}
          variant="outlined"
          size="small"
          successMessage="Link copied to clipboard!"
          startAdornment={<Icon name="link" />}
        >
          Copy connection link
        </CopyButton>
      )}
    </Layout.Group>
  );
};
