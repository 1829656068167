import { Batch, useBatchActions } from "../../useBatches";
import { Button, Icon, Stack, Title, useTheme } from "@introist/react-foundation/v2";

export const BatchStatus = ({ batch }: { batch: Batch }) => {
  const { theme } = useTheme();
  const { start } = useBatchActions();

  if (batch.stage === "draft") {
    return (
      <Stack>
        <Title>This batch is a draft</Title>
        <Button startIcon="playCircle" onClickWithLoading={() => start(batch.id)}>
          Start
        </Button>
      </Stack>
    );
  }
  if (batch.stage === "active") {
    return (
      <Stack>
        <Icon name="hourglass" color={theme.palette.primary.default} />
        <Title color={theme.palette.primary.default}>This batch is in progress</Title>
      </Stack>
    );
  }

  if (batch.stage === "completed") {
    return (
      <Stack>
        <Icon name="checkCircle" color={theme.palette.success.default} />
        <Title color={theme.palette.success.default}>This batch is completed</Title>
      </Stack>
    );
  }

  return null;
};
