import styled, { css, keyframes } from "styled-components";
import { Icon } from "@introist/react-foundation/v2";
import { ExplodingLines } from "./components/ExplodingLines";

type Props = {
  checked: boolean;
  animate?: boolean;
  size?: number;
  style?: React.CSSProperties;
};

export const checkBubbleAnimation = keyframes`
    0% {
    transform: scale(0);
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
`;

const StyledCheck = styled.div<{ $checked: boolean; $size?: number; $animate?: boolean }>`
  width: ${({ $size }) => $size || 1.25}rem;
  height: ${({ $size }) => $size || 1.25}rem;
  border-radius: ${({ $size }) => $size || 1.25}rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;

  ::after,
  ::before {
    display: block;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    width: ${({ $size }) => `calc(${$size || 1.25} + 1.5px)`}rem;
    height: ${({ $size }) => `calc(${$size || 1.25} + 1.5px)`}rem;
  }

  ${({ $checked, $animate }) => {
    if ($checked && $animate) {
      return css`
        ::after {
          content: "";
          background-color: var(--palette-primary-default);
          animation-name: ${checkBubbleAnimation};
          animation-duration: 160ms;
          animation-timing-function: ease-out;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }
      `;
    } else if ($checked && !$animate) {
      return css`
        ::after {
          content: "";
          background-color: var(--palette-primary-default);
        }
      `;
    } else {
      return css`
        ::before {
          content: "";
          border: 1.5px solid var(--palette-border-subdued);
        }
      `;
    }
  }}
`;

const CheckIcon = styled(Icon)`
  position: relative;
  z-index: 1;
`;

export const Check = ({ checked, size, animate = false, ...rest }: Props) => {
  return (
    <StyledCheck {...rest} $checked={checked} $size={size} $animate={animate}>
      <CheckIcon name="check" className="check" color="white" />
      {checked && animate && <ExplodingLines color="#0E181D" />}
    </StyledCheck>
  );
};
