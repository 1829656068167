import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";

export type TriggerParams = RouterInput["triggers"]["list"];
export type Trigger = RouterOutput["triggers"]["find"];

export type TriggerCreate = RouterInput["triggers"]["create"];
export type TriggerUpdate = RouterInput["triggers"]["update"];

export const useTriggers = (params: TriggerParams) => {
  const { onConfirmAction } = useActions();
  const toast = useToast();

  const { data: triggers, refetch } = api.triggers.list.useQuery(params, {
    refetchOnMount: "always"
  });

  const createOp = api.triggers.create.useMutation();
  const updateOp = api.triggers.update.useMutation();
  const archiveOp = api.triggers.archive.useMutation();

  const create = useCallback(
    async (entry: Partial<Trigger>) => {
      return createOp.mutateAsync(entry as TriggerCreate).then(async res => {
        await refetch();
        return res;
      });
    },
    [createOp, refetch]
  );

  const update = useCallback(
    async (data: TriggerUpdate) => {
      await updateOp.mutateAsync(data).then(async () => {
        await refetch();
      });
    },
    [updateOp, refetch]
  );

  const archive = onConfirmAction(
    async id => {
      await archiveOp.mutateAsync({ id });
      await refetch();
      toast.success("Trigger archived");
    },
    {
      icon: "archive",
      title: "Archive trigger",
      description: "Are you sure you want to archive this trigger?",
      confirmTitle: "Archive",
      cancelTitle: "Cancel"
    }
  );

  return {
    triggers,
    create,
    update,
    archive
  };
};
