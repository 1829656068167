import "./HtmlEditor.scss";
import "emoji-mart/css/emoji-mart.css";
import "react-quill/dist/quill.snow.css";

import { useTheme } from "@introist/react-foundation/v2";
import { BaseEmoji } from "emoji-mart";
import Quill, { Delta } from "quill";
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import ReactQuill from "react-quill";

import styles from "./HtmlEditor.module.scss";
import { PropertyBlock } from "./Property";
import { VariableSelector } from "../VariableSelector/VariableSelector";
import { VariablePopover } from "../VariableSelector/VariablePopover";
import { EmojiSelector } from "./EmojiSelector";
import { GifSelector } from "./GifSelector";
import { MaterialSelector } from "./MaterialSelector";
import cx from "classnames";

import ImageUploader from "quill-image-uploader";

import "quill-image-uploader/dist/quill.imageUploader.min.css";
import { uploadAsset } from "../../../services/api/AssetsApi";
import { FieldEmbedBlock } from "./FieldEmbed";
import { FieldSelector } from "../FieldSelector";
import { EmployeeFieldSelectorPopover } from "../../../modules/employees/fields/EmployeeFieldSelector";

Quill.register("modules/imageUploader", ImageUploader);

Quill.register(PropertyBlock);
Quill.register(FieldEmbedBlock);

export interface HtmlEditorProps {
  defaultValue?: string;
  onChange?: (value: string) => unknown;
  placeholder?: string;
  materials?: { id: string; title: string }[];
  variables?: { key: string; title: string }[];
  style?: CSSProperties;
  hideToolbar?: boolean;
  error?: boolean;
  underline?: boolean;
  fields?: boolean;
}

export type EditorHandle = {
  replaceHTML: (html: string) => void;
};

const modules = {
  toolbar: {
    container: "#html-editor-toolbar",
    handlers: {
      emoji: () => {},
      giphy: () => {},
      materials: () => {},
      variables: () => {}
    }
  },
  clipboard: {
    matchVisual: false
  },
  imageUploader: {
    upload: (file: any) => uploadAsset(file)
  }
};

export const HtmlEditor = React.forwardRef<EditorHandle, HtmlEditorProps>(
  (
    {
      defaultValue,
      onChange,
      placeholder,
      materials,
      variables,
      style,
      hideToolbar,
      error,
      underline = true,
      fields = false
    }: HtmlEditorProps,
    ref
  ) => {
    const { theme } = useTheme();
    const editorRef = useRef<ReactQuill>(null);
    const [silentMode, setSilentMode] = useState(true);
    const [attributeSearch, setAttributeSearch] = useState<string>();

    useImperativeHandle(
      ref,
      () => ({
        replaceHTML: (html: string) => {
          const htmlEditor = editorRef.current?.getEditor();
          const delta = htmlEditor?.clipboard.convert(html);
          delta && htmlEditor?.setContents(delta);
        }
      }),
      []
    );

    useEffect(() => {
      setTimeout(() => {
        setSilentMode(false);
      }, 50);
    }, []);

    const [attributeListRef, setAttributeListRef] = useState<HTMLElement | null>(null);
    const [attributeListOpen, setAttributeListOpen] = useState(false);

    const activateAttributeSelection = () => {
      setAttributeSearch("");
      setAttributeListOpen(true);
    };

    const deactivateAttributeSelection = () => {
      setAttributeSearch(undefined);
      setAttributeListOpen(false);
    };

    const handleEditorDelta = (delta: Delta) => {
      const insertDelta = delta.ops?.find(_ => Object.keys(_).includes("insert"));
      const deleteDelta = delta.ops?.find(_ => Object.keys(_).includes("delete"));

      if (insertDelta) {
        const insertData = insertDelta.insert;
        if (insertData === "/") {
          activateAttributeSelection();
        } else {
          const currentAttributeSearch = attributeSearch ?? "";
          setAttributeSearch(currentAttributeSearch + insertData);
        }
      } else if (deleteDelta) {
        if (attributeSearch === "") {
          return deactivateAttributeSelection();
        }
        const currentAttributeSearch = attributeSearch ?? "";
        setAttributeSearch(currentAttributeSearch.slice(0, -1));
      } else {
        deactivateAttributeSelection();
      }
    };

    const onAddAttribute = useCallback(
      (attribute: any) => {
        const htmlEditor = editorRef.current?.getEditor();
        const htmlSelector = htmlEditor?.getSelection(true);
        const deleteTextLength = (attributeSearch?.length ?? 0) + 1;
        if (htmlEditor && htmlSelector) {
          const currentSelectorIndex = htmlSelector.index - deleteTextLength;
          htmlEditor.deleteText(currentSelectorIndex, deleteTextLength);
          htmlEditor.insertEmbed(currentSelectorIndex, "property", {
            ...attribute,
            type: "userproperty"
          });
          htmlEditor.insertText(currentSelectorIndex + 1, " ");
          htmlEditor.setSelection(currentSelectorIndex + 2, 0);
        }
      },
      [attributeSearch]
    );

    const onAddField = useCallback(
      (field: string, title: string, replace = false): boolean => {
        const htmlEditor = editorRef.current?.getEditor();
        const htmlSelector = htmlEditor?.getSelection(true);
        const deleteTextLength = replace ? (attributeSearch?.length ?? 0) + 1 : 0;
        if (htmlEditor && htmlSelector) {
          const currentSelectorIndex = htmlSelector.index - deleteTextLength;
          htmlEditor.deleteText(currentSelectorIndex, deleteTextLength);
          htmlEditor.insertEmbed(currentSelectorIndex, "field", {
            "data-property-field": field,
            "data-property-title": title
          });
          htmlEditor.insertText(currentSelectorIndex + 1, " ");
          htmlEditor.setSelection(currentSelectorIndex + 2, 0);
          return true;
        }
        return false;
      },
      [attributeSearch]
    );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "250px",
          maxHeight: "350px",
          ...style
        }}
      >
        {!hideToolbar && (
          <div ref={setAttributeListRef} id="html-editor-toolbar">
            <select className="ql-size" defaultValue="normal">
              <option value="small" />
              <option value="normal" />
              <option value="large" />
              <option value="huge" />
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            {underline && (
              <button className="ql-underline" style={{ marginRight: theme.spacing.xSmall }} />
            )}

            <button className="ql-list" value="ordered" />
            <button
              className="ql-list"
              value="bullet"
              style={{ marginRight: theme.spacing.xSmall }}
            />

            <button className="ql-link" />
            <button className="ql-image" />

            <EmojiSelector
              onInsert={emoji => {
                const htmlEditor = editorRef.current?.getEditor();
                const htmlSelector = htmlEditor?.getSelection(true);
                if (htmlEditor && htmlSelector) {
                  htmlEditor.insertText(htmlSelector.index, (emoji as BaseEmoji).native);
                }
                return true;
              }}
            />
            <GifSelector
              onInsert={item => {
                const htmlEditor = editorRef.current?.getEditor();
                const htmlSelector = htmlEditor?.getSelection(true);
                if (htmlEditor && htmlSelector) {
                  htmlEditor.insertEmbed(htmlSelector.index, "image", item.images.fixed_width.url);
                }
                return true;
              }}
            />
            {materials && (
              <MaterialSelector
                materials={materials}
                onInsert={material => {
                  const htmlEditor = editorRef.current?.getEditor();
                  const htmlSelector = htmlEditor?.getSelection(true);
                  if (htmlEditor && htmlSelector) {
                    const endOfPropertyTag = htmlSelector.index + 1;
                    htmlEditor.insertEmbed(htmlSelector.index, "property", {
                      id: material.id,
                      name: material.title,
                      variable: `material:${material.id}`,
                      type: "material"
                    });
                    htmlEditor.insertText(endOfPropertyTag, " ");
                    htmlEditor.setSelection(endOfPropertyTag + 1, 0);
                    return true;
                  }
                }}
              />
            )}
            {variables && variables.length > 0 && (
              <VariableSelector
                variables={variables}
                onSelected={variable => {
                  onAddAttribute({ variable: variable.key, name: variable.title });
                  return true;
                }}
              />
            )}
            {fields && <FieldSelector onSelect={onAddField} />}
            <button className="ql-clean" style={{ float: "right" }} />
          </div>
        )}

        <ReactQuill
          ref={editorRef}
          className={cx(styles.Quill, { error })}
          theme="snow"
          placeholder={placeholder}
          onChange={(value, delta) => {
            handleEditorDelta(delta);
            const fixed = value
              .replace(/<p>/g, '<p style="margin: 0;">')
              .replace(/<ul>/g, '<ul style="margin: 0;">')
              .replace(/<li>/g, '<li style="margin: 0;">')
              .replace(/<ol>/g, '<ol style="margin: 0;">')
              .replace(/(<ul>.*?<\/ul>)(?!<\/p>)/g, "<p>$1</p>");

            !silentMode && onChange && onChange(fixed);
          }}
          onKeyDown={e => setAttributeListRef(e.target)}
          onKeyUp={event => {
            if (event.key === "Escape") {
              deactivateAttributeSelection();
            }
          }}
          modules={modules}
          defaultValue={defaultValue}
        />
        {variables && (
          <VariablePopover
            variables={variables ?? []}
            presearch={attributeSearch}
            searchable
            popoverRef={attributeListRef}
            open={attributeListOpen}
            setOpen={open => {
              if (open) activateAttributeSelection();
              else deactivateAttributeSelection();
            }}
            onSelected={variable => {
              onAddAttribute({ variable: variable.key, name: variable.title });
              deactivateAttributeSelection();
            }}
          />
        )}
        <EmployeeFieldSelectorPopover
          popoverRef={attributeListRef}
          open={attributeListOpen}
          onSelect={(path, title) => {
            onAddField(path, title, true);
          }}
          setOpen={setAttributeListOpen}
          preSearch={attributeSearch}
        />
      </div>
    );
  }
);
