import { Stack, Tag } from "@introist/react-foundation/v2";

export const WeekdaySelector = ({
  weekdays,
  onChange,
  disabled = false
}: {
  weekdays: string[];
  onChange: (weekdays: string[]) => unknown;
  disabled?: boolean;
}) => {
  const onClick = (weekday: string) => {
    if (weekdays.includes(weekday)) {
      onChange(weekdays.filter(_ => _ !== weekday));
    } else {
      onChange([...weekdays, weekday]);
    }
  };

  const weekdaysOptions = [
    {
      title: "Mon",
      key: "1"
    },
    {
      title: "Tue",
      key: "2"
    },
    {
      title: "Wed",
      key: "3"
    },
    {
      title: "Thu",
      key: "4"
    },
    {
      title: "Fri",
      key: "5"
    }
  ];
  const getColorVariant = (weekday: string) => {
    if (disabled) return "outlined";
    if (weekdays.includes(weekday)) return "primary";
    return undefined;
  };
  return (
    <Stack gap="xSmall">
      {weekdaysOptions.map(option => (
        <Tag
          key={`weekday-${option.key}`}
          colorVariant={getColorVariant(option.key)}
          onClick={() => {
            if (disabled) return;
            onClick(option.key);
          }}
        >
          {option.title}
        </Tag>
      ))}
    </Stack>
  );
};
