import { Layout } from "@introist/react-foundation/v2";
import { TextInput } from "../../../components/atoms";
import { ConnectFormProps } from "../views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";

export const EmailDataSourceConnectForm = ({ values, onChange }: ConnectFormProps) => {
  return (
    <Layout.Group vertical gap="xLarge">
      <TextInput label="Name" value={values?.name} onChange={name => onChange({ name })} />
    </Layout.Group>
  );
};
