import { useRef, useState } from "react";
import { DataForm, Select, LabelledCheckbox } from "@introist/react-foundation/v2";

import { WorkflowStep } from "services/api/WorkflowApi";
import { Block } from "components/atoms";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { stringifyTrigger } from "utils/Text";
import { prefixForm } from "utils/FormUtils";

import { StepEditorAccordion } from "../../StepEditorAccordion";
import { CardPopover } from "../../CardPopover";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { TriggerDayPicker } from "components/molecules";
import { useWorkflows } from "../../../../../flows/hooks/useWorkflows";

type WorkflowTriggerStepEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
};

const TRIGGER_DATE_SUFFIX = "target date";

export const WorkflowTriggerStepEditorV2 = ({
  form,
  formPrefix
}: WorkflowTriggerStepEditorProps) => {

  const prefixedForm = prefixForm(form, formPrefix);
  const targetTrigger = prefixedForm.get("targetTrigger");

  const { workflows } = useWorkflows({});

  const triggerPickerAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [triggerPickerOpen, setTriggerPickerOpen] = useState(false);

  return (
    <Block blended compact>
      <StepEditorAccordion title="Worfklow details" stepOrder={2}>
        <StepEditorAccordion.Row label="Workflow">
          <Select
            attachToRef={false}
            element="button"
            variant="blended"
            searchable
            searchPlaceholder="Find workflow"
            placeholder="Select workflow to trigger"
            value={prefixedForm.get("workflowId")}
            options={workflows?.map(wf => ({ key: wf.id, title: wf.title })) || []}
            onSelect={option => prefixedForm.set("workflowId")(option.key)}
            error={prefixedForm.isError("workflowId")}
          />
        </StepEditorAccordion.Row>
        <StepEditorAccordion.Row label="Target date">
          <div>
            <IconToggleButton
              startIcon="calendar"
              active={triggerPickerOpen}
              ref={triggerPickerAnchorRef}
              onClick={() => setTriggerPickerOpen(!triggerPickerOpen)}
            >
              {stringifyTrigger(
                targetTrigger,
                TRIGGER_DATE_SUFFIX !== undefined,
                false,
                TRIGGER_DATE_SUFFIX
              )}
            </IconToggleButton>
            <CardPopover
              open={triggerPickerOpen}
              referenceElement={triggerPickerAnchorRef.current}
              onClose={() => setTriggerPickerOpen(false)}
            >
              <TriggerDayPicker
                vertical
                trigger={targetTrigger}
                onChange={prefixedForm.set("targetTrigger")}
                suffix={TRIGGER_DATE_SUFFIX}
              />
            </CardPopover>
          </div>
        </StepEditorAccordion.Row>
        <LabelledCheckbox
          label="Start as draft"
          checked={prefixedForm.get("draft")}
          onChange={prefixedForm.set("draft")}
        />
      </StepEditorAccordion>
    </Block>
  );
};
