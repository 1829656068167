import { useCallback, useRef, useState } from "react";
import { isEqual } from "lodash";
import { Expression } from "@introist/introist-commons/dist";
import { v4 as uuidv4 } from "uuid";
import {
  ComparisonMode,
  ConditionGroupWithConditionId,
  ConditionWithId,
  EmptyCondition,
  expressionToConditionWithIdGroup
} from "../types";

type UseExpressionEditorArgs = {
  initialExpression?: Expression;
  initEmpty?: boolean;
  onChange?: (changed: ConditionGroupWithConditionId) => void;
};

export const useExpressionEditor = ({
  initialExpression,
  initEmpty = true,
  onChange
}: UseExpressionEditorArgs) => {
  const [conditionToEdit, setConditionToEdit] = useState<ConditionWithId>();
  const conditionRef = useRef<ConditionWithId>();
  const initialConditionGroup = expressionToConditionWithIdGroup(initialExpression ?? { and: [] });

  const [conditionGroup, setConditionGroup] = useState<ConditionGroupWithConditionId>(() => {
    if (initialConditionGroup.conditions.length === 0 && initEmpty) {
      initialConditionGroup.conditions.push({ ...EmptyCondition, id: uuidv4() });
    }

    const conditionsWithIds = initialConditionGroup.conditions.map(condition => ({
      ...condition,
      id: uuidv4()
    }));

    return { ...initialConditionGroup, conditions: conditionsWithIds };
  });

  const isChanged = !isEqual(conditionGroup, initialConditionGroup);

  // CREATE
  const onCreateCondition = useCallback(
    (partialCondition?: Partial<ConditionWithId>) => {
      const newCondition = partialCondition
        ? { ...EmptyCondition, ...partialCondition, id: uuidv4() }
        : { ...EmptyCondition, id: uuidv4() };

      const nextConditionGroup = {
        ...conditionGroup,
        conditions: [...conditionGroup.conditions, newCondition]
      } as ConditionGroupWithConditionId;

      setConditionGroup(nextConditionGroup);
      setConditionToEdit(newCondition);
      onChange && onChange(nextConditionGroup);

      return newCondition;
    },
    [conditionGroup, setConditionGroup, setConditionToEdit, onChange]
  );

  // UPDATE
  const onUpdateCondition = useCallback(
    (changedCondition: Partial<ConditionWithId>) => {
      const conditionToUpdate = conditionGroup.conditions.find(c => c.id === changedCondition.id);

      if (!conditionToUpdate) {
        return;
      }

      const updatedCondition = { ...conditionToUpdate, ...changedCondition };

      setConditionToEdit(updatedCondition);

      const nextConditions = conditionGroup.conditions.map(condition => {
        return condition.id === changedCondition.id ? updatedCondition : condition;
      });

      const nextConditionGroup = { ...conditionGroup, conditions: nextConditions };
      setConditionGroup(nextConditionGroup);
      onChange && onChange(nextConditionGroup);
    },
    [conditionGroup, setConditionGroup, setConditionToEdit, onChange]
  );

  const onSelectCondition = useCallback(
    (condition: ConditionWithId) => {
      setConditionToEdit(condition);
      conditionRef.current = condition;
    },
    [setConditionToEdit]
  );

  // DELETE
  const onDeleteCondition = useCallback(
    (id: string) => {
      const nextConditions = [...conditionGroup.conditions].filter(
        condition => condition.id !== id
      );

      const nextConditionGroup = { ...conditionGroup, conditions: nextConditions };
      setConditionGroup(nextConditionGroup);
      setConditionToEdit(undefined);
      onChange && onChange(nextConditionGroup);
    },
    [conditionGroup, setConditionGroup, setConditionToEdit, onChange]
  );

  const onCancelEditCondition = useCallback(
    (condition: ConditionWithId) => {
      const rollbackCondition = { condition, ...conditionRef.current };
      onUpdateCondition(rollbackCondition);
      setConditionToEdit(undefined);
    },
    [conditionRef, onUpdateCondition, setConditionToEdit]
  );

  // MODE
  const onModeChange = useCallback(
    (mode: ComparisonMode) => {
      const nextConditionGroup = { ...conditionGroup, mode };
      setConditionGroup(nextConditionGroup);

      onChange && onChange(nextConditionGroup);
    },
    [conditionGroup, setConditionGroup, onChange]
  );

  return {
    conditionGroup,
    isChanged,
    conditionToEdit,
    setConditionToEdit,
    onCreateCondition,
    onUpdateCondition,
    onDeleteCondition,
    onModeChange,
    onCancelEditCondition,
    onSelectCondition
  };
};
