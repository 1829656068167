import React, { useState } from "react";
import { Icon, OptionList, Popover } from "@introist/react-foundation/v2";

export type Material = { id: string; title: string };

export const MaterialSelector = ({
  materials,
  onInsert
}: {
  materials: Material[];
  onInsert: (material: Material) => boolean | void;
}) => {
  const [materialRef, setMaterialRef] = useState<HTMLElement | null>(null);
  const [materialsOpen, setMaterialsOpen] = useState(false);

  return (
    <>
      <button
        ref={setMaterialRef}
        className="ql-materials"
        onClick={() => {
          setMaterialsOpen(!materialsOpen);
        }}
      >
        <Icon name="book" />
      </button>
      <Popover
        referenceElement={materialRef}
        onClose={() => setMaterialsOpen(false)}
        open={materialsOpen}
      >
        <OptionList
          key="materials-list"
          onItemClick={option => {
            const material = materials?.find(m => m.id === option.key)!;
            const close = onInsert(material);
            if (close) setMaterialsOpen(false);
          }}
          options={materials.map(m => ({
            key: m.id,
            title: m.title
          }))}
        />
      </Popover>
    </>
  );
};
