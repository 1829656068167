import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useToast } from "@introist/react-foundation/v2";
import { useCallback } from "react";

export type FormResponse = RouterOutput["forms"]["v2"]["responses"]["find"];

export type FormResponseUpdate = RouterInput["forms"]["v2"]["responses"]["update"]["update"];

export const useFormResponses = (formId: string) => {
  const { data: responses } = api.forms.v2.responses.list.useQuery({ formId });

  return { responses };
};

export const useFormResponse = (responseId: string) => {
  const { data: response } = api.forms.v2.responses.find.useQuery({ id: responseId });

  return { response };
};

export const useFormResponseActions = () => {
  const toast = useToast();
  const apiUtils = api.useContext();

  const listOp = apiUtils.forms.v2.responses.list;
  const findOp = apiUtils.forms.v2.responses.find;

  const updateOp = api.forms.v2.responses.update.useMutation();
  const transformOp = api.forms.v2.responses.transform.useMutation();

  const update = useCallback(
    async (responseId: string, update: FormResponseUpdate) => {
      await updateOp
        .mutateAsync({ id: responseId, update })
        .then(async response => {
          await listOp.refetch({ formId: response.formId });
          await findOp.refetch({ id: response.id });
          toast.success("Response updated");
        })
        .catch(() => {
          toast.error("Failed to update Response");
        });
    },
    [listOp, findOp, updateOp, toast]
  );

  const transform = useCallback(
    async (responseId: string) => {
      await transformOp
        .mutateAsync({ id: responseId })
        .then(async () => {
          await findOp.refetch({ id: responseId });
          toast.success("Response updated");
        })
        .catch(() => {
          toast.error("Failed to update Response");
        });
    },
    [transformOp, findOp, toast]
  );

  return { update, transform, loading: updateOp.isLoading };
};
