import { Icon, IconName } from "@introist/react-foundation/v2";

type Props = {
  emailProvider?: string;
};

export const HostCalendarIcon = ({ emailProvider, ...rest }: Props) => {
  const getCalendarIcon = (emailProvider?: string): IconName => {
    switch (emailProvider) {
      case "google":
        return "googleCalendar";
      case "outlook":
        return "outlook";
      default:
        return "calendar";
    }
  };

  return <Icon {...rest} name={getCalendarIcon(emailProvider)} />;
};
