import { DataForm } from "@introist/react-foundation/v2";
import { WorkflowStep } from "services/api/WorkflowApi";

import { EventMessageBlock } from "./blocks/EventMessageBlock";
import { Block } from "components/atoms";
import { EventParticipantsBlockV2 } from "./blocks/EventParticipantsBlock";
import { EventSettingsBlock } from "./blocks/EventSettingsBlock";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { Attendee } from "services/api/types";

export type JourneyEventStepEditorProps = {
  attendees?: Attendee[];
  isJourneyEditor?: boolean;
  journeyAttributes?: { [index: string]: string };
};

type EventStepEditorProps = JourneyEventStepEditorProps & {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
  enableSyncStatus?: boolean;
  enableMatchingParticipants?: boolean;
  readOnly?: boolean;
  onUpdate?: () => Promise<void>;
};

export const EventStepEditor = ({
  form,
  formPrefix,
  readOnly,
  journeyAttributes,
  isJourneyEditor,
  attendees,
  enableMatchingParticipants
}: EventStepEditorProps) => {
  const journeyProps = { journeyAttributes, isJourneyEditor, attendees };

  return (
    <>
      <Block blended compact>
        <EventParticipantsBlockV2
          form={form}
          formPrefix={formPrefix}
          readOnly={readOnly}
          {...journeyProps}
        />
      </Block>
      <EventMessageBlock form={form} formPrefix={formPrefix} readonly={readOnly} />
      <EventSettingsBlock form={form} readOnly={readOnly} formPrefix={formPrefix} />
    </>
  );
};
