import { Stack } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { AttachmentItem } from "./components/File";

interface Props {
  attachments: { fileId: string; name: string }[];
}

export const AttachmentViewer = ({ attachments }: Props) => {
  return (
    <StepEditorAccordion title="Attachments">
      <Stack vertical gap="small">
        {attachments.map((attachment: any) => (
          <AttachmentItem
            key={attachment.fileId}
            id={attachment.fileId}
            name={attachment.name}
            readOnly
          />
        ))}
      </Stack>
    </StepEditorAccordion>
  );
};
