import { api, RouterInput, RouterOutput } from "../../services/rpc/RpcProvider";
import { useCallback } from "react";
import { useActions, useToast } from "@introist/react-foundation/v2";
import { useNavigate } from "react-router";

export type Nudge = RouterOutput["nudges"]["find"];

export type NudgeCreate = RouterInput["nudges"]["create"];
export type NudgeUpdate = RouterInput["nudges"]["update"];

export const useNudges = () => {
  const { data: nudges } = api.nudges.list.useQuery({});

  return { nudges };
};

export const useNudge = (id: string) => {
  const { data: nudge } = api.nudges.find.useQuery({ id });
  return { nudge };
};

export const useNudgeActions = () => {
  const toast = useToast();
  const { onConfirmAction } = useActions();
  const navigate = useNavigate();

  const apiUtils = api.useContext();

  const listOp = apiUtils.nudges.list;
  const findOp = apiUtils.nudges.find;

  const createOp = api.nudges.create.useMutation();
  const updateOp = api.nudges.update.useMutation();
  const deleteOp = api.nudges.delete.useMutation();

  const create = useCallback(
    async (req: NudgeCreate) => {
      return createOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch();
          return created;
        })
        .catch(e => {
          toast.error("Failed to create Nudge");
          throw e;
        });
    },
    [createOp, listOp, toast]
  );

  const update = useCallback(
    async (req: NudgeUpdate) => {
      return updateOp
        .mutateAsync(req)
        .then(async created => {
          await listOp.refetch();
          await findOp.refetch();
          return created;
        })
        .catch(e => {
          toast.error("Failed to update nudge");
          throw e;
        });
    },
    [updateOp, findOp, listOp, toast]
  );

  const remove = onConfirmAction(
    async (id: string, navigateTo?: string) => {
      await deleteOp
        .mutateAsync({ id })
        .then(async () => {
          await listOp.refetch();
          toast.success("Nudge deleted");
          if (navigateTo) navigate(navigateTo);
        })
        .catch(e => {
          toast.error("Failed to delete nudge");
        });
    },
    {
      title: "Delete Nudge",
      description: "Are you sure to delete this nudge. This can not be undone."
    }
  );

  return { create, update, remove };
};
