import { postJson } from "../../../../services/api/ApiBase";

interface InitiateConnectResponse {
  url: string;
}

export const loadConnectUrl = (integrationId: string, redirectUrl: string) => {
  return postJson<InitiateConnectResponse>(
    `/v1/integrations/${integrationId}/credentials?redirectUrl=${redirectUrl}`,
    {}
  );
};
