import { DataForm } from "@introist/react-foundation/v2";

import { useEmployeeAttributeApi } from "modules/employees/api/EmployeeAttributeApi";
import { EmailMessageBuilderV2 } from "modules/workflows/components/EmailMessageBuilder/v2/EmailMessageBuilderV2";

import { useEmailSenderApi } from "services/api/Senders";
import { WorkflowStep } from "services/api/WorkflowApi";

import { prefixForm } from "utils/FormUtils";

import { TaskContentBlock } from "../blocks/TaskContentBlock";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

type EmailTaskStepEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
};

export const EmailTaskEditorV2 = ({ form, formPrefix = "stepData." }: EmailTaskStepEditorProps) => {
  const attributeApi = useEmployeeAttributeApi();
  const { data: attributes } = attributeApi.list({ includeHidden: false }).query;

  const prefixedForm = prefixForm(form, formPrefix);

  const senderApi = useEmailSenderApi();
  const { data: senders } = senderApi.list({ filterCalendarOnlySenders: true }).query;

  return (
    <>
      <TaskContentBlock value={prefixedForm.get(`task`)} onChanged={prefixedForm.set("task")} />
      <EmailMessageBuilderV2
        title="Email details"
        value={prefixedForm.get("message")}
        onChanged={prefixedForm.set("message")}
        senders={senders ?? []}
        attributes={(attributes ?? []).filter(attr => attr.type === "email")}
      />
    </>
  );
};
