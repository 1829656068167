import styled, { css } from "styled-components";
import { Icon, Layout } from "@introist/react-foundation/v2";
import { animated } from "@react-spring/web";

import { AnimatedCrossLineProps } from "../../AnimatedCrossLine";
import { useCrossLineSprings } from "../../hooks/useCrossLineSprings";
import { AnimatedCrossIcon, StyledAnimatedCrossLine } from "../..";

type HorizontalCrossIconFrom = "left" | "right" | "center";

type HorizontalCrossLineProps = AnimatedCrossLineProps & {
  from?: HorizontalCrossIconFrom;
};

const AnimatedHorizonalLine = styled(animated(Layout.Divider))<{
  $from: HorizontalCrossIconFrom;
}>`
  ${({ $from }) => {
    switch ($from) {
      case "left":
        return css`
          transform-origin: left;
        `;
      case "right":
        return css`
          transform-origin: right;
        `;
      case "center":
      default:
        return css`
          transform-origin: center;
        `;
    }
  }}
`;

export const HorizontalCrossLine = ({
  length,
  from = "left",
  animationDelayMs = 0,
  crossBackgroundColor,
  ...rest
}: HorizontalCrossLineProps) => {
  const { horizontalLineSpring, crossSpring } = useCrossLineSprings(animationDelayMs);

  return (
    <StyledAnimatedCrossLine {...rest} style={{ width: `${length}rem` }}>
      <AnimatedCrossIcon $backgroundColor={crossBackgroundColor} style={crossSpring}>
        <Icon danger name="crossSmall" />
      </AnimatedCrossIcon>
      <AnimatedHorizonalLine style={horizontalLineSpring} $from={from} />
    </StyledAnimatedCrossLine>
  );
};
