import { useMemo } from "react";
import { IconButton, Tooltip } from "@introist/react-foundation/v2";

type OptionalAttendeeButtonProps = {
  optional?: boolean;
  readOnly?: boolean;
  onClick: () => void;
};

export const OptionalAttendeeButton = ({
  optional,
  readOnly,
  onClick
}: OptionalAttendeeButtonProps) => {
  const tooltip = useMemo(() => {
    if (readOnly) {
      return optional ? "Optional attendee" : "Important attendee";
    }

    return optional ? "Mark attendee important" : "Mark attendee optional";
  }, [optional, readOnly]);

  return (
    <Tooltip tooltip={tooltip} placement="left">
      <IconButton icon="personCircle" dimmed={optional} onClick={onClick} />
    </Tooltip>
  );
};
