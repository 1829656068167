import { ListView, TopBarRoute } from "components/templates";
import { useNavigate, useParams } from "react-router-dom";
import { api, StepTypeWithEventTypeV2 } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { WorkflowTimeline, WorkflowTimelineSkeletonLoader } from "../containers";
import { Button, Drawer } from "@introist/react-foundation/v2";
import { useMemo, useState } from "react";
import { buildInitialStepData } from "services/api/workflows/StepDataBuilder";

import { useSearch } from "../../../foundation";
import { WorkflowSettingsDrawer } from "../containers/WorkflowSettings";
import { StepTypeSelector } from "../components/StepTypeSelector";
import { useWorkflow } from "../hooks/useWorkflows";
import { WorkflowStepEditorDrawer } from "../containers/WorkflowStepEditor/WorkflowStepEditorDrawer";
import { useWorkflowStep, useWorkflowSteps } from "../hooks/useWorkflowSteps";
import { BreadcrumbPath } from "@introist/react-foundation/v2/02-blocks";

const Wrapper = styled.section`
  display: grid;
  grid-row-gap: 1rem;
  grid-template-rows: auto 1fr;
  min-height: 88vh;
`;

export const WorkflowEditorRoute = () => {
  const navigate = useNavigate();
  const { workflowId, stepId } = useParams();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [stepSelectorOpen, setStepSelectorOpen] = useState(false);

  const [newStepType, setNewStepType] = useState<string | undefined>(undefined);

  const { workflow } = useWorkflow(workflowId!);
  const { steps } = useWorkflowSteps({ workflowId: workflowId! });

  const { step } = useWorkflowStep(stepId);

  const { data: folder } = api.workflows.v4.folders.find.useQuery(
    { id: workflow?.folderId! },
    { enabled: !!workflow && !!workflow.folderId }
  );

  const {
    filtered: filteredSteps,
    search,
    setSearch
  } = useSearch(steps, { fields: ["stepTitle"] });

  const paths = useMemo(() => {
    const breadcrumbs: BreadcrumbPath[] = [
      {
        key: "workflows-root",
        title: "Workflows",
        onClick: () => navigate("/flows")
      }
    ];

    const addFolderRecursive = (folder: any) => {
      if (folder.parent) {
        addFolderRecursive(folder.parent);
      }
      breadcrumbs.push({
        key: `workflows-folder-${folder.id}`,
        title: folder.name,
        onClick: () => navigate(`/flows/folder/${folder.id}`)
      });
    };

    if (folder) {
      addFolderRecursive(folder);
    }

    return breadcrumbs;
  }, [folder, navigate]);

  const template = useMemo(() => {
    if (!newStepType) return null;

    return buildInitialStepData({
      extendedStepType: newStepType as StepTypeWithEventTypeV2,
      params: {}
    });
  }, [newStepType]);

  return (
    <TopBarRoute
      paths={[
        ...paths,
        {
          key: "workflow",
          title: workflow?.title ?? ""
        }
      ]}
      actions={
        <Button
          startIcon="settings"
          size="small"
          variant="outlined"
          onClick={() => setSettingsOpen(true)}
        >
          Settings
        </Button>
      }
    >
      <Wrapper>
        <ListView.Header
          title="Steps"
          count={filteredSteps?.length}
          onCreate={() => setStepSelectorOpen(true)}
          search={{ search, setSearch }}
        />

        {(!workflow || !steps) && <WorkflowTimelineSkeletonLoader />}
        {workflow && steps && (
          <WorkflowTimeline
            steps={filteredSteps ?? []}
            workflow={workflow!}
            onStepClick={async stepId => navigate(stepId, { relative: "path" })}
            onAddStep={() => setStepSelectorOpen(true)}
          />
        )}
      </Wrapper>

      <WorkflowStepEditorDrawer
        open={!!step || !!template}
        onClose={() => {
          setNewStepType(undefined);
          navigate(`/flows/edit/${workflowId}`, { replace: true });
        }}
        workflowId={workflowId!}
        step={template ?? step!}
      />
      <Drawer width="540px" open={stepSelectorOpen} onClose={() => setStepSelectorOpen(false)}>
        <StepTypeSelector
          onClose={() => setStepSelectorOpen(false)}
          onSelect={stepType => {
            setStepSelectorOpen(false);
            setNewStepType(stepType);
          }}
        />
      </Drawer>
      <WorkflowSettingsDrawer
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        workflowId={workflowId}
      />
    </TopBarRoute>
  );
};
