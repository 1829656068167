import { Button, Stack } from "@introist/react-foundation/v2";
import { ConditionGroupLevel2 } from "../../types";
import { ExpressionField } from "../../RecursiveExpressionEditor";
import { useConditionParser } from "./ConditionParser/ConditionParser";
import React, { useState } from "react";
import { RuleCard } from "./RuleCard";
import { ConditionCardSeparator } from "./ConditionSeparator";
import { Skeletons } from "components/molecules";

interface RuleViewerProps {
  conditionGroup: ConditionGroupLevel2;
  fields: ExpressionField[];
  collapsedCount?: number;
  selectedConditionId?: string;
  selectCondition?: (conditionId: string) => void;
  deleteCondition?: (conditionId: string) => void;
  isLoading?: boolean;
}

export const RuleViewer = ({
  conditionGroup,
  fields,
  collapsedCount,
  selectedConditionId,
  selectCondition,
  deleteCondition,
  isLoading
}: RuleViewerProps) => {
  const { mode } = conditionGroup;
  const readOnlyConditions = useConditionParser(conditionGroup, fields);
  const [showAll, setShowAll] = useState(false);

  const shouldCollapse = !!collapsedCount && readOnlyConditions.length > collapsedCount && !showAll;

  const visibleConditions = shouldCollapse
    ? readOnlyConditions.slice(0, collapsedCount)
    : readOnlyConditions;

  return (
    <Stack vertical gap="none">
      {visibleConditions.map((c, idx) => {
        const { id, ...condition } = c;

        return (
          <React.Fragment key={`rule-card-fragment-${idx}`}>
            {isLoading && <Skeletons.Block height={50} />}
            {!isLoading && (
              <RuleCard
                active={id === selectedConditionId}
                selectCondition={selectCondition ? () => selectCondition(id) : undefined}
                deleteCondition={deleteCondition ? () => deleteCondition(id) : undefined}
                {...condition}
              />
            )}

            {idx !== readOnlyConditions.length - 1 && <ConditionCardSeparator mode={mode} />}
          </React.Fragment>
        );
      })}
      {collapsedCount && readOnlyConditions.length > collapsedCount && (
        <Button
          size="small"
          variant="blended"
          style={{ marginTop: 16 }}
          onClick={e => {
            e.stopPropagation(); // Prevent the event from bubbling up
            setShowAll(!showAll);
          }}
        >
          {showAll ? "Show Less" : "Show All"}
        </Button>
      )}
    </Stack>
  );
};
