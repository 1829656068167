import constate from "constate";
import { isEmpty } from "lodash";

import { IntroistUser } from "IdentityProvider";
import { NotificationScheme, Organisation } from "services/rpc/RpcProvider";

type TNotificationSettingsContextProviderArgs = {
  user: IntroistUser;
  schemes: NotificationScheme[];
  organisation: Organisation;
  refetchSchemes: (...args: any[]) => any;
};

export const [NotificationSettingsContextProvider, useNoficiationSettingsContext] = constate(
  ({ schemes, refetchSchemes, user, organisation }: TNotificationSettingsContextProviderArgs) => {
    const firstScheme = !isEmpty(schemes) ? schemes[0] : null;

    const defaultScheme: Omit<NotificationScheme, "channel" | "topic"> = {
      userId: user.id,
      workspaceId: organisation.id,
      days: firstScheme?.days || [],
      time: firstScheme?.time || "10:00",
      timezone: firstScheme?.timezone || organisation.defaultTimezone,
      active: true
    };

    return {
      isSlackConnected: !!user.slackWorkspaceName,
      schemes,
      defaultScheme,
      defaultTimezone: organisation.defaultTimezone,
      refetchSchemes
    };
  }
);
