import { StepEditorProps } from "../useStepEditor";
import { AttachmentsBlock } from "../../../workflows/blocks/Attachments";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { ErrorMessage, Field, Stack } from "@introist/react-foundation/v2";
import { MessageEditor } from "../../../../components/editors/MessageEditor";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { FieldAwareInput } from "../../../employees/fields/FieldAwareInput";
import { EmailStepAddressBlock } from "./EmailStepAddressBlock";

export const EmailStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <>
      <EmailStepAddressBlock form={form} formKeyPrefix={stepDataPath} />
      <Block compact blended>
        <StepEditorAccordion
          error={
            prefixedForm.isError("subject") || prefixedForm.isError("message")
              ? "Invalid fields"
              : undefined
          }
          title="Message"
          defaultOpen={true}
        >
          <Stack vertical gap="xLarge">
            <Field title="Subject">
              <FieldAwareInput
                value={prefixedForm.get("subject")}
                onChange={prefixedForm.set("subject")}
                error={prefixedForm.isError("subject")}
                placeholder="Email subject"
                size="small"
              />
            </Field>
            <Field title="Message">
              <MessageEditor
                message={prefixedForm.get("message")}
                onChange={prefixedForm.set("message")}
              />
              {prefixedForm.error("message") && (
                <ErrorMessage>{prefixedForm.error("message")}</ErrorMessage>
              )}
            </Field>
          </Stack>
        </StepEditorAccordion>
      </Block>
      <AttachmentsBlock form={form} />
    </>
  );
};
