import {
  Icon,
  InfoCard,
  Option,
  useActions,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";
import { executeStep, reExecuteStep } from "services/api/execution/ExecutionApi";

import { api, JourneyStepV2 } from "services/rpc/RpcProvider";
import {
  isCompletableJourneyStep,
  isExecutableJourneyStep,
  isResendableJourneyStep
} from "../util";
import { cancelStep } from "services/api/journeys/JourneyStepApi";
import { useCallback, useState } from "react";

const isReschedulableJourneyStep = (step: JourneyStepV2) => {
  if (step.deliveredAt && !["completed", "canceled"].includes(step.status)) {
    return ["event", "eventattend", "meeting"].includes(step.stepType);
  }

  return ["scheduled", "failing", "draft"].includes(step.status);
};

export const useJourneyStepActions = (step: JourneyStepV2) => {
  const toast = useToast();

  const apiUtils = api.useContext();

  const actionOp = api.automations.steps.action.useMutation();
  const refreshStepOp = api.automations.steps.refresh.useMutation();
  const cancelStepOp = api.automations.steps.cancel.useMutation();
  const completeStepOp = api.automations.steps.complete.useMutation();

  const { onConfirmAction } = useActions();

  const [rescheduleOpen, setRescheduleOpen] = useState(false);

  const reloadStep = async () => {
    await apiUtils.journeys.steps.list.refetch({ journeyId: step.journeyId });
    await apiUtils.journeys.steps.find.refetch({ stepId: step.id });

    await apiUtils.automations.steps.find.refetch({ id: step.id });
  };

  const onAction = useCallback(
    async (actionKey: string) => {
      await actionOp
        .mutateAsync({ id: step.id, actionKey })
        .then(async () => {
          await apiUtils.automations.steps.find.refetch({ id: step.id });
          await apiUtils.journeys.steps.find.refetch({ stepId: step.id });
          toast.success("Action completed");
        })
        .catch(() => toast.error("Action failed"));
    },
    [step, apiUtils, toast, actionOp]
  );

  const actions: Option[] = [];

  if (isReschedulableJourneyStep(step)) {
    actions.push({
      key: "reschedule",
      title: "Reschedule step",
      startAdornment: <Icon name="calendar" />,
      onClick: () => setRescheduleOpen(true)
    });
  }

  if (
    (step.stepType as any) === "meeting" &&
    step.deliveredAt &&
    ["delivered", "failing"].includes(step.status)
  ) {
    actions.push({
      key: "autoreschedule",
      title: "Reschedule automatically",
      startAdornment: <Icon name="calendar" />,
      onClick: () => onAction("reschedule")
    });
  }

  if (isResendableJourneyStep(step)) {
    actions.push({
      key: "resend",
      title: "Resend step",
      startAdornment: <Icon name="reconnect" />,
      onClick: onConfirmAction(
        async () => {
          await reExecuteStep(step.id);
          await reloadStep();
          toast.success("Step has been resent");
        },
        {
          title: "Confirm resending step",
          description: "Are you sure you want to resend the step immediately?"
        },
        "Failed to resend step"
      )
    });
  }

  if (isExecutableJourneyStep(step)) {
    actions.push({
      key: "execute",
      title: "Execute step now",
      startAdornment: <Icon name="trigger" />,
      onClick: onConfirmAction(
        async () => {
          await executeStep(step.id);
          await reloadStep();
        },
        {
          title: "Confirm step execution",
          description: "Are you sure you want to execute the step immediately?"
        },
        "Failed to execute step"
      )
    });
  }

  const { theme } = useTheme();

  if (isCompletableJourneyStep(step)) {
    actions.push({
      key: "complete",
      title: "Mark completed",
      startAdornment: <Icon name="check" />,
      onClick: onConfirmAction(
        async () => {
          await completeStepOp.mutateAsync({ id: step.id });
          await reloadStep();
        },
        {
          title: "Confirm marking step completed",
          description: "Are you sure you want to complete the step immediately?"
        },
        "Failed to complete step"
      )
    });
  }

  if (!["completed", "canceled"].includes(step.status)) {
    actions.push({
      key: "refresh",
      title: "Refresh step",
      startAdornment: <Icon name="reconnect" />,
      onClick: async () => {
        await refreshStepOp.mutateAsync({ id: step.id }).then(async () => {
          await reloadStep();
          toast.success("Step has been refreshed");
        });
      }
    });
    actions.push({
      key: "cancel",
      title: "Cancel step",
      titleStyles: {
        color: theme.palette.danger.default
      },
      startAdornment: <Icon name="crossCircle" color={theme.palette.danger.default} />,
      onClick: onConfirmAction(
        async () => {
          if (step.stepType === "event") {
            await cancelStep({ journeyId: step.journeyId, stepId: step.id });
          } else {
            await cancelStepOp.mutateAsync({ id: step.id });
          }

          await reloadStep();
        },
        {
          title: "Cancel step",
          description: (
            <InfoCard icon="crossCircle" title="Cancel step" colorVariant="danger">
              Are you sure you want to cancel this step? This can not be undone.
            </InfoCard>
          )
        },
        "Failed to cancel step"
      )
    });
  }

  return {
    actions,
    rescheduleOpen,
    setRescheduleOpen
  };
};
