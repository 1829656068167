import { UpsertFormProps } from "../../../foundation";
import { EmployeeField, useEmployeeFields } from "../hooks/useEmployeeFields";
import {
  Button,
  Field,
  IconButton,
  Input,
  LabelledCheckbox,
  Select,
  Stack
} from "@introist/react-foundation/v2";
import { KeyInput, simplifyKeyValue } from "../../datasets/components/KeyInput";
import { useMemo } from "react";
import { useFieldCategories } from "../hooks/useFieldCategories";

export const EmployeeFieldForm = ({ value, onChange, isEdit }: UpsertFormProps<EmployeeField>) => {
  const { fields } = useEmployeeFields();
  const { categories } = useFieldCategories();

  const employeeLinkOptions = useMemo(() => {
    if (!fields) return [];
    return fields.map(f => ({ key: f.key, title: f.title }));
  }, [fields]);

  const categoryOptions = useMemo(() => {
    return ["General", ...(categories ?? []).map(c => c.title)].map(c => ({ key: c, title: c }));
  }, [categories]);

  return (
    <Stack vertical style={{ width: "300px" }}>
      <Field title="Title">
        <Input
          value={value.title}
          onChange={title => onChange({ ...value, title })}
          onBlur={() => {
            onChange({ ...value, key: simplifyKeyValue(value.title) });
          }}
          autoFocus
        />
      </Field>
      <Field title="Type">
        <Select
          style={{ width: "100%" }}
          size="small"
          value={value.type}
          onSelect={opt => onChange({ ...value, type: opt.key as EmployeeField["type"] })}
          options={[
            { key: "text", title: "Text" },
            { key: "email", title: "Email" },
            { key: "date", title: "Date" },
            { key: "employee", title: "Employee" }
          ]}
          disabled={isEdit}
        />
      </Field>
      <Field title="Key">
        <KeyInput
          size="small"
          value={value.key}
          onChange={key => onChange({ ...value, key })}
          disabled={isEdit}
        />
      </Field>
      {value.type === "employee" && (
        <Field title="Linking field">
          <Select
            size="small"
            searchable
            style={{ width: "100%" }}
            options={employeeLinkOptions}
            value={value.linkingField ?? undefined}
            placeholder="Select linking field"
            onSelect={opt => onChange({ ...value, linkingField: opt.key })}
            disabled={isEdit}
          />
        </Field>
      )}
      <LabelledCheckbox
        label="Field is required"
        checked={value.isRequired}
        onChange={isRequired => onChange({ ...value, isRequired })}
      />
      <Field title="Category">
        <Select
          style={{ width: "100%" }}
          size="small"
          value={value.category ?? "General"}
          onSelect={opt => onChange({ ...value, category: opt.key })}
          options={categoryOptions}
        />
      </Field>
      {value.type !== "employee" && (
        <Field title="Default value">
          <Input
            size="small"
            value={value.defaultValue ?? ""}
            onChange={defaultValue => {
              if (defaultValue === "") onChange({ ...value, defaultValue: null });
              else onChange({ ...value, defaultValue });
            }}
            placeholder="No default"
          />
        </Field>
      )}
      {value.type !== "employee" && (
        <OptionField value={value} onChange={onChange} isEdit={isEdit} />
      )}
    </Stack>
  );
};

const OptionField = ({ value, onChange, isEdit }: UpsertFormProps<EmployeeField>) => (
  <Field title="Options">
    {(value.options ?? []).map((option, index) => (
      <Stack>
        <Input
          size="small"
          value={option}
          onChange={newVal => {
            const newOptions = value.options!.map((o, i) => (index === i ? newVal : o));
            onChange({ ...value, options: newOptions });
          }}
          style={{ flex: 1 }}
          placeholder="Option value"
        />
        <IconButton
          icon="crossSmall"
          onClick={() => {
            if (value.options!.length === 1) onChange({ ...value, options: null });
            else onChange({ ...value, options: value.options!.filter((_, i) => i !== index) });
          }}
        />
      </Stack>
    ))}
    <Button
      variant="blended"
      startIcon="plus"
      onClick={() => onChange({ ...value, options: [...(value.options ?? []), ""] })}
    >
      Add option
    </Button>
  </Field>
);
