import {
  DateInput,
  Icon,
  IconButton,
  Input,
  Select,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { EmployeeField } from "../hooks/useEmployeeFields";
import { Employee } from "../hooks/useEmployees";

type EmployeeProfileFieldProps = {
  field: EmployeeField;
  value: string | null;
  onChange: (val: string | null) => void;
  hasChanges?: boolean;
  error?: string;
  employees: Employee[];
  onClear?: () => void;
};

export const EmployeeProfileField = ({
  field,
  value,
  onChange,
  hasChanges,
  error,
  employees,
  onClear
}: EmployeeProfileFieldProps) => {
  const { theme } = useTheme();

  const resolveField = () => {
    const fieldStyles = {
      flex: 1,
      background: hasChanges && !error ? theme.palette.surface.subdued : undefined
    };

    if (field.type === "date") {
      return (
        <DateInput
          size="small"
          variant="blended"
          style={fieldStyles}
          inputStyles={{ color: theme.palette.foreground.default }}
          endAdornment={<Icon name="calendar" color={theme.palette.foreground.dimmed} />}
          value={value ?? undefined}
          displayFormat={"DD.MM.yyyy"}
          onChange={val => onChange(val ?? null)}
          error={!!error}
          placeholder="-"
        />
      );
    }
    if (field.type === "employee") {
      const employee = employees.find(e => e[field.linkingField!] === value);
      const options = employees.map(e => ({
        key: e[field.linkingField!] ?? "",
        title: e.name!
      }));

      return (
        <Stack>
          <Select
            searchable
            size="small"
            variant="blended"
            startAdornment={<Icon name="person" />}
            style={{ ...fieldStyles, width: "100%" }}
            inputStyles={{ color: theme.palette.foreground.default }}
            options={options}
            value={value ?? undefined}
            onSelect={opt => onChange(opt.key)}
            error={!!error}
            onClear={() => onChange(null)}
            placeholder="-"
          />
          {employee && (
            <IconButton
              icon="moveTo"
              onClick={() => window.open(`/employees/${employee.id}#fields`, "_blank")}
              dimmed
            />
          )}
        </Stack>
      );
    }
    if (field.options) {
      return (
        <Select
          size="small"
          variant="blended"
          style={{ ...fieldStyles, width: "100%" }}
          inputStyles={{ color: theme.palette.foreground.default }}
          options={field.options.map(o => ({ key: o }))}
          value={value ?? undefined}
          onSelect={opt => onChange(opt.key)}
          error={!!error}
          placeholder="-"
        />
      );
    }
    return (
      <Input
        size="small"
        variant="blended"
        style={fieldStyles}
        inputStyles={{
          color: theme.palette.foreground.default
        }}
        value={value ?? ""}
        onChange={val => onChange(val)}
        error={!!error}
        placeholder="-"
      />
    );
  };

  return (
    <div style={{ flex: 3 }}>
      <Stack>
        <div style={{ flex: 1 }}>{resolveField()}</div>
        {onClear && <IconButton icon="crossSmall" onClick={onClear} dimmed />}
      </Stack>
      {error && (
        <Title color={theme.palette.danger.default} style={{ marginTop: theme.spacing.small }}>
          {error}
        </Title>
      )}
    </div>
  );
};
