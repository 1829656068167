import { ReactElement, ReactNode, useMemo } from "react";
import { IconName, Layout, Title } from "@introist/react-foundation/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import styled, { css } from "styled-components";

type MenuProps = {
  children: ReactElement<MenuItemProps> | ReactElement<MenuItemProps>[];
  onItemClick?: (id: string) => void;
};

export const SectionMenu = ({ children, onItemClick }: MenuProps) => {
  const normalizedChildren = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  return (
    <Layout.Group vertical gap="xxLarge">
      <SectionMenuItemList onItemClick={onItemClick}>{normalizedChildren}</SectionMenuItemList>
      <Layout.Group>{normalizedChildren.find(c => c.props.active)}</Layout.Group>
    </Layout.Group>
  );
};

const MenuList = styled(Layout.Group)``;

const Count = styled(Layout.Group)<{ $active?: boolean }>`
  width: 24px;
  height: 24px;
  background: var(--palette-surface-default);
  border-radius: var(--rounding-medium);
  h5 {
    color: var(--palette-foreground-subdued);
  }
  ${({ $active }) =>
    $active &&
    `
    background: var(--palette-primary-default);
    h5 {
      color: white;
    }
  `}
`;

const AccountToggleButton = styled(IconToggleButton)`
  && {
    border-color: var(--palette-border-subdued);
  }
  border-radius: var(--rounding-medium);

  ${({ active }) => {
    if (active) {
      return css`
        && {
          color: var(--palette-foreground-default);
          border-color: var(--palette-primary-default);
        }
      `;
    }
  }}
`;

const SectionMenuItemList = ({
  children,
  onItemClick
}: {
  children: React.ReactElement<MenuItemProps>[];
  onItemClick?: (id: string) => void;
}) => {
  return (
    <MenuList>
      {children.map(c => (
        <AccountToggleButton
          active={c.props.active}
          startIcon={c.props.iconName ? c.props.iconName : undefined}
          startAdornment={c.props.icon}
          onClick={() => onItemClick && onItemClick(c.props.id)}
          endAdornment={
            <Count justifyContent="center" alignItems="center" $active={c.props.active}>
              <Title variant="bold">{c.props.amount}</Title>
            </Count>
          }
        >
          {c.props.title}
        </AccountToggleButton>
      ))}
    </MenuList>
  );
};

type MenuItemProps = {
  id: string;
  title: string;
  amount: number;
  iconName?: IconName;
  icon?: ReactNode;
  active?: boolean;
  children?: ReactNode;
};

export const SectionMenuItem = ({ id, children }: MenuItemProps) => {
  return <Layout.Group key={id}>{children}</Layout.Group>;
};
