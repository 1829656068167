import { Button, Option, useToast } from "@introist/react-foundation/v2";
import { PortalOptionListMultiSelect } from "modules/workflows/components/PortalOptionList";
import { useMemo, useRef, useState } from "react";
import { useEmployeeFields } from "../../../hooks/useEmployeeFields";

type Props = {
  maxValues?: number;
  values?: string[];
  onChange: (values: string[]) => void;
};
export const ColumnCustomize = ({ values = [], maxValues, onChange }: Props) => {
  const toast = useToast();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState<string[]>(values || []);

  const { fields } = useEmployeeFields();

  const options: Option[] = useMemo(() => {
    if (!fields) return [];

    return fields.map(f => ({
      key: f.key,
      title: f.title
    }));
  }, [fields]);

  const handleOptionClick = (o: Option) => {
    if (selectedValues?.includes(o.key)) {
      setSelectedValues(selectedValues.filter(v => v !== o.key));
    } else {
      if (maxValues && selectedValues.length >= maxValues) {
        toast.info(`You can only select ${maxValues} fields`);
        return;
      }
      setSelectedValues(current => [...(current || []), o.key]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onChange(selectedValues);
  };

  return (
    <div ref={ref}>
      <Button
        variant="outlined"
        size="small"
        startIcon="configure"
        onClick={() => {
          open ? handleClose() : setOpen(true);
        }}
      >
        Customize
      </Button>

      <PortalOptionListMultiSelect
        backdrop
        selectedKeys={selectedValues}
        referenceElement={ref.current}
        open={open}
        onClose={handleClose}
        options={options}
        onOptionClick={handleOptionClick}
      />
    </div>
  );
};
