import styled from "styled-components";
import { Button, Card, Title } from "@introist/react-foundation/v2";
import { CSSProperties, ReactNode } from "react";

const StyledUnsavedChanges = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-large);
  background-color: var(--palette-foreground-default);
  padding: var(--spacing-small);
  padding-left: var(--spacing-medium);
  box-sizing: border-box;

  > h5 {
    color: var(--palette-surface-default);
  }

  > button {
    box-shadow: none;
  }
`;

export const UnsavedChanges = ({
  onSave,
  show,
  style,
  saveTitle = "Save",
  secondary
}: {
  onSave: () => Promise<unknown>;
  show: boolean;
  style?: CSSProperties;
  saveTitle?: string;
  secondary?: ReactNode;
}) => {
  if (!show) return null;
  return (
    <StyledUnsavedChanges mode="dark" style={style}>
      <Title>Unsaved changes</Title>
      <Button onClickWithLoading={onSave}>{saveTitle}</Button>
      {secondary}
    </StyledUnsavedChanges>
  );
};
