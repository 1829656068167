import styled from "styled-components";
import { Card, Title, Layout } from "@introist/react-foundation/v2";

import { StepTrigger } from "services/api/WorkflowApi";
import { FlexRangeEditor } from "../FlexRangeEditor";

type TimeFinderEditorProps = {
  isEvent: boolean;
  triggerString: string;
  trigger: StepTrigger;
  onTriggerChange: (trigger: StepTrigger) => void;
};

const StyledCard = styled(Card)`
  width: 100%;
  background: var(--palette-surface-dimmed);
`;

export const TimeFinderEditor = ({
  trigger,
  isEvent,
  triggerString,
  onTriggerChange,
  ...rest
}: TimeFinderEditorProps) => {
  return (
    <StyledCard {...rest}>
      <Layout.Group vertical>
        <Layout.Group vertical gap="xSmall">
          <Title variant="bold">{isEvent ? "Search for existing event" : "Find a time"}</Title>
          <Title>
            {isEvent
              ? "The system will find existing event within the given date range"
              : "Introist will automatically find a time that accommodates all attendees."}
          </Title>
        </Layout.Group>
        <FlexRangeEditor.ButtonPopover
          triggerString={triggerString}
          title={isEvent ? "Search for existing event" : undefined}
          startTrigger={trigger.flexStart as unknown as StepTrigger}
          endTrigger={trigger.flexEnd as unknown as StepTrigger}
          onChange={({ flexEnd, flexStart }) => onTriggerChange({ ...trigger, flexEnd, flexStart })}
          allowToggling={!isEvent}
        />
      </Layout.Group>
    </StyledCard>
  );
};
