import { useCallback, useState } from "react";
import {
  CircularLoader,
  DateInput,
  InfoCard,
  Modal,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { useJourneyApi } from "services/api/journeys/JourneyApi";
import { DateFormats, IntroistDate } from "utils/dates";
import { FormFooter } from "../../datasources/components/FormFooter";
import { api } from "../../../services/rpc/RpcProvider";
import { useEmployeeFields } from "../../datasources/hooks/useEmployeeFields";

interface RescheduleAutomationProps {
  automationId: string;
  onClose: () => void;
}

export const RescheduleAutomation = ({ automationId, onClose }: RescheduleAutomationProps) => {
  const [targetDate, setTargetDate] = useState<IntroistDate>();
  const toast = useToast();

  const employeeFields = useEmployeeFields();
  const { data: automation } = api.automations.find.useQuery({ id: automationId });
  const { data: workflow } = api.workflows.v4.find.useQuery(
    { id: automation!.workflowId },
    {
      enabled: !!automation
    }
  );

  const apiUtils = api.useContext();

  const journeyApi = useJourneyApi();
  const rescheduleMutation = journeyApi.reschedule();

  const doReschedule = useCallback(async () => {
    await rescheduleMutation.mutateAsync({ journeyId: automationId, targetDate: targetDate! });
    await apiUtils.journeys.steps.list.refetch({ journeyId: automationId! });
    await apiUtils.automations.find.refetch({ id: automationId });
    toast.success("Automation rescheduled");
    onClose();
  }, [automationId, targetDate, rescheduleMutation, apiUtils, toast, onClose]);

  if (!automation || !workflow) return <CircularLoader fillParent />;

  return (
    <Stack vertical>
      {workflow.targetDateChangeMode === "reschedule" && (
        <InfoCard icon="person" title="Change employees field" colorVariant="primary">
          <Title>
            This automation is set to reschedule based on the employee field. To reschedule, update
            employees field{" "}
            <strong>
              {employeeFields.find(f => f.variable === automation.targetDateAttribute)?.name ?? ""}.
            </strong>
          </Title>
        </InfoCard>
      )}
      {workflow.targetDateChangeMode === "cancel" && (
        <InfoCard icon="person" title="Cancel automation" colorVariant="primary">
          <Title>
            This automation is set to cancel if target date changes. If you want to reschedule,
            cancel this automation and create a new one.
          </Title>
        </InfoCard>
      )}
      {!["reschedule", "cancel"].includes(workflow.targetDateChangeMode) && (
        <>
          <InfoCard icon="calendar" title="New target date" colorVariant="primary">
            Select new target date for the automation. All active steps will be rescheduled
            accordingly.
          </InfoCard>
          <DateInput
            onChange={setTargetDate}
            value={targetDate}
            displayFormat={DateFormats.date.long}
          />
          <FormFooter onCancel={onClose} onSubmit={doReschedule} submit="Reschedule" />
        </>
      )}
    </Stack>
  );
};

export const RescheduleAutomationModal = ({
  open,
  onClose,
  ...props
}: RescheduleAutomationProps & { open: boolean }) => {
  return (
    <Modal open={open} onClose={onClose} title="Reschedule automation" maxWidth="512px">
      {open && <RescheduleAutomation {...props} onClose={onClose} />}
    </Modal>
  );
};
