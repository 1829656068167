import { Field, Layout } from "@introist/react-foundation/v2";
import { HtmlEditor, PersonListItem, PersonValue } from "components/organisms";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import styled from "styled-components";
import { TextInput } from "components/atoms";

interface ReadonlyHtmlMessageProps {
  from?: PersonValue;
  to?: PersonValue;
  subject?: string;
  subjectTitle?: string;
  message: string;
}

const StyledHTMLEditor = styled(HtmlEditor)`
  .ql-container {
    background-color: var(--palette-surface-subdued) !important;
  }
`;

export const ReadOnlyEmailMessage = ({
  subject,
  subjectTitle,
  message,
  from,
  to
}: ReadonlyHtmlMessageProps) => {
  return (
    <Layout.Group vertical>
      {(from || to) && (
        <Layout.Group vertical>
          {from && (
            <StepEditorAccordion.Row label="From">
              <PersonListItem value={from} maxLength={30} />
            </StepEditorAccordion.Row>
          )}
          {to && (
            <StepEditorAccordion.Row label="To">
              <PersonListItem value={to} maxLength={30} />
            </StepEditorAccordion.Row>
          )}
        </Layout.Group>
      )}

      {subject && (
        <TextInput readOnly value={subject} label={subjectTitle || "Subject"} size="small" />
      )}
      <Field title="Message">
        <StyledHTMLEditor defaultValue={message} readonly={true} />
      </Field>
    </Layout.Group>
  );
};
