import { DataForm, Field, Select, Title } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "../../StepEditorAccordion";
import { SlackMessageBuilder } from "../TaskStepEditorV2/slack/SlackMessageBuilder";
import { prefixForm } from "../../../../../../utils/FormUtils";
import { useAttributes } from "../../../../../../hooks";
import { Block } from "components/atoms";
import { OrderableOptionList } from "components/organisms";

type SlackQuestionEditorProps = {
  form: DataForm<any>;
  formPrefix: string;
};

export const SlackQuestionEditor = ({ form, formPrefix }: SlackQuestionEditorProps) => {
  const prefixedForm = prefixForm(form, formPrefix);

  const attributes = useAttributes();

  return (
    <>
      <SlackMessageBuilder
        value={prefixedForm.get("message")}
        onChanged={prefixedForm.set("message")}
        title="Message"
        stepOrder={2}
      />
      <Block blended compact>
        <StepEditorAccordion title="Answer" stepOrder={3}>
          <Title>Set the options to answer.</Title>

          <OrderableOptionList
            value={prefixedForm.get("options") ?? []}
            onChange={val => prefixedForm.set("options")(val)}
          />

          <Field title="Save answer">
            <Title style={{ margin: ".5rem 0" }}>
              Select the employee field where the answer is saved.
            </Title>
            <Select
              style={{ width: "100%" }}
              onClear={() => prefixedForm.set("answerAttribute")(undefined)}
              searchable
              options={(attributes ?? []).map(a => ({ key: a.variable, title: a.name }))}
              value={prefixedForm.get("answerAttribute")}
              onSelect={opt => prefixedForm.set("answerAttribute")(opt.key)}
              attachToRef={false}
              placeholder="Select a field"
              size="small"
            />
          </Field>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
