import { generateQuery, getJson, postJson } from "./ApiBase";
import { IntroistUser } from "../../IdentityProvider";
import { config } from "../../Config";

export const loadUser = (): Promise<IntroistUser> => {
  return getJson("/v1/user");
};

export const getUserSlackConnectUrl = async () => {
  const res = await getJson<{ url: string }>("/v1/user/slack");
  return res.url;
};

export const completeUserSlackConnect = async (code: string, redirectUrl?: string) => {
  return postJson("/v1/user/slack", { code, redirectUrl });
};

export const loadSsoToken = async (): Promise<string> => {
  const res = await getJson<{ token: string }>(`/v1/user/sso`);
  return res.token;
};

export interface SSOInfo {
  tenant: string;
}

export const loadSsoInfo = async (email: string): Promise<SSOInfo> => {
  return postJson(`/v1/sso/info`, { email });
};

export const startSso = (tenant: string) => {
  window.location.href = `${config.apiUrl}/v1/sso/${tenant}`;
};

export interface WorkspaceOverview {
  id: string;
  name: string;
  logo?: string | null;
}

export const listUserWorkspaces = async (): Promise<WorkspaceOverview[]> => {
  return getJson(`/v1/user/workspaces`);
};

export const useUserApi = () => ({
  listWorkspaces: generateQuery<WorkspaceOverview[]>(["user-workspaces"], listUserWorkspaces)
});
